import APIClient from "../APIClient";

export class CompaniesClient {
  /**
   * * GET on /api/v1/Companies/{id}
   * @param id - Database ID associated with a company
   * @param include: To fetch additional data on this object, specify the list of elements to retrieve.
   * @returns Promise of type CompanyModel or Exception
   */
  getCompany(id: string, include?: string): Promise<CompanyModel> {
    const url = `api/v1/Companies/${id}`;
    const options = {
      params: {
        include,
      },
    };
    return APIClient.get(url, options).then((res) => res.data);
  }

  /**
   * * GET on /api/v1/Companies/query
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - To fetch additional data on this object, specify the list of elements to retrieve.
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type CompanyModelFetchResult or Exception
   */
  getCompanies(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<CompanyModelFetchResult> {
    const url = "api/v1/Companies/query";
    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };
    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  /**
   * * PATCH on /api/v1/Companies/{id}
   * @param id - Database ID associated with a company
   * @param body: The fields to change
   * @returns Promise of type CompanyModel or Exception
   */
  patchCompany(id: string, body: Partial<CompanyModel>): Promise<CompanyModel> {
    const url = `api/v1/Companies/${id}`;
    return APIClient.patch(url, body).then((res) => res.data);
  }

  /**
   * * GET on api/v1/Companies/views/details/{id}
   * @param id - Database id associated with a company
   * @returns Promise of type CustomerDetailsModel
   */
  getCompanyDetails(id: string): Promise<CustomerDetailsModel> {
    let url = "/api/v1/Companies/views/details/{id}";

    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.");
    }

    url = url.replace("{id}", encodeURIComponent("" + id));
    url = url.replace(/[?&]$/, "");

    // Process response
    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }

  /**
   * * GET on /api/v1/Companies/customer-summary
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type CustomerSummaryModelFetchResult or Exception
   */
  getCustomerSummaries(
    filter?: string,
    include?: string,
    order?: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<ConnectionsSummaryModelFetchResultInterface> {
    const url = "api/v1/Companies/views/customer-summary";

    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  /**
   * * GET on /api/v1/Companies/vendor-summary
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type VendorSummaryModelFetchResult or Exception
   */
  getVendorSummaries(
    filter?: string,
    include?: string,
    order?: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<ConnectionsSummaryModelFetchResultInterface> {
    const url = "api/v1/Companies/views/vendor-summary";

    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }
}
