import { Tooltip } from "@mui/material";
import React from "react";
import { AlertContext } from "../../../contexts/AlertContext";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import { FileAttachment, VideoAttachment, DocAttachment, OtherAttachment, CSVAttachment, PDFAttachment, ImageAttachment } from "../../library/Icons/Icons";
import "./AttachmentChip.scss";

const AttachmentChip: React.FC<{ attachment: any }> = ({ attachment }) => {
    const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;

    const onClickAttachment = (attachment: any, e: any) => {
        e.stopPropagation();
        TableUtils.downloadAll([attachment], setToastOptions);
    };
    const getAttachmentIcon = (extension: string) => {
        switch (extension) {
            case "pdf":
                return <PDFAttachment />
            case "doc":
                return <DocAttachment />
            case "zip":
                return <FileAttachment />
            case "csv": case "xlss":
                return <CSVAttachment />
            case "mp4": case "mov": case "wmv": case "avi": case "avchd": case "flv": case "f4v": case "swf": case "mkv": case "webm": case "html5": case "mpeg2": case "gif":
                return <VideoAttachment />
            case "jpg": case "jpeg": case "png": case "tiff": case "psd": case "eps": case "ai": case "indd": case "raw":
                return <ImageAttachment />
            default:
                return <OtherAttachment />
        }
    }

    return (
        <Tooltip title={attachment.file_name} arrow>
            <div className="attachment-chip" onClick={(e: any) => onClickAttachment(attachment, e)}>
                <>
                    {getAttachmentIcon(attachment.extension)} <span className="attachment-text">{attachment.file_name}</span>
                </>
            </div>
        </Tooltip>
    );
};

export default AttachmentChip;
