import React from "react";
import { Switch, Route, useRouteMatch } from "react-router";
import Navbar from "../library/Navbar/Navbar";
import "../Activities/Activities.scss";
import { ActivityContext } from "../../contexts/ActivityContext";
import { ApplicationRouteContext } from "../../contexts/ApplicationRouteContext";
import useWorkspaceConfigurations from "../../hooks/useWorkspaceConfigurations";
import { MetaContext } from "../../contexts/MetaContext";

const ActivitiesNavbar = () => {
  const { path } = useRouteMatch();
  const { configs } = useWorkspaceConfigurations();
  const { activityData } = React.useContext(ActivityContext) as ActivityType;
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const subject = activityData && activityData.subject ? activityData.subject : sessionStorage.activitySubject;
  const { metaDetailed } = React.useContext(MetaContext) as MetaType;

  return (
    <div className="activities-head">
      {/* variations for unassigned, all, spam can be handled here with conditionals */}
      <Switch>
        {/* Mine */}
        <Route
          exact
          path={[`${path}/mine`, `${path}/mine/waitingForResponse`, `${path}/mine/snoozed`, `${path}/mine/closed`]}
          component={() => {
            return <Navbar tabs={configs.activities.detail.routes.main.all} rootPath={`${path}/mine`} meta={metaDetailed} />;
          }}
        />
        <Route
          exact
          path={`${path}/mine/:activityId`}
          component={() => {
            return (
              <Navbar
                tabs={configs.activities.detail.routes.main.mine}
                backDisplayName="My Activities"
                handleBackRoute={true}
                backRoute={`${path}/mine`}
                rootPath={`${path}/mine`}
                meta={metaDetailed}
              />
            );
          }}
        />
        <Route
          path={`${path}/mine/:activityId`}
          component={() => {
            return (
              <Navbar
                tabs={configs.activities.detail.routes.main.mine}
                backDisplayName={subject ?? "Activity Details"}
                handleBackRoute={true}
                backRoute={getBaseRoute()}
                rootPath={`${path}/mine`}
                meta={metaDetailed}
              />
            );
          }}
        />
        {/* Unassigned */}
        <Route
          exact
          path={[`${path}/unassigned`, `${path}/unassigned/closed`]}
          component={() => {
            return <Navbar tabs={configs.activities.detail.routes.main.unassigned} rootPath={`${path}/unassigned`} meta={metaDetailed} />;
          }}
        />
        <Route
          exact
          path={[`${path}/unassigned/:activityId`]}
          component={() => {
            return (
              <Navbar
                tabs={configs.activities.detail.routes.main.unassigned}
                backDisplayName="Unassigned Activities"
                handleBackRoute={true}
                backRoute={`${path}/unassigned`}
                rootPath={`${path}/unassigned`}
                meta={metaDetailed}
              />
            );
          }}
        />
        <Route
          path={[`${path}/unassigned/:activityId`]}
          component={() => {
            return (
              <Navbar
                tabs={configs.activities.detail.routes.main.unassigned}
                backDisplayName={subject ?? "Activity Details"}
                handleBackRoute={true}
                backRoute={getBaseRoute()}
                rootPath={`${path}/unassigned`}
                meta={metaDetailed}
              />
            );
          }}
        />
        {/* All */}
        <Route
          exact
          path={[`${path}/all`, `${path}/all/waitingForResponse`, `${path}/all/snoozed`, `${path}/all/closed`]}
          component={() => {
            return <Navbar tabs={configs.activities.detail.routes.main.all} rootPath={`${path}/all`} meta={metaDetailed} />;
          }}
        />
        <Route
          exact
          path={[`${path}/all/:activityId`]}
          component={() => {
            return (
              <Navbar
                tabs={configs.activities.detail.routes.main.all}
                backDisplayName="All Activities"
                handleBackRoute={true}
                backRoute={`${path}/all`}
                rootPath={`${path}/all`}
                meta={metaDetailed}
              />
            );
          }}
        />
        <Route
          path={[`${path}/all/:activityId`]}
          component={() => {
            return (
              <Navbar
                tabs={configs.activities.detail.routes.main.all}
                backDisplayName={subject ?? "Activity Details"}
                handleBackRoute={true}
                backRoute={`${getBaseRoute()}`}
                rootPath={`${path}/all`}
                meta={metaDetailed}
              />
            );
          }}
        />
        {/* Spam */}
        <Route
          exact
          path={[`${path}/spam`]}
          component={() => {
            return <Navbar tabs={configs.activities.detail.routes.main.spam} rootPath={`${path}/spam`} meta={metaDetailed} />;
          }}
        />
        <Route
          exact
          path={[`${path}/spam/:activityId`]}
          component={() => {
            return (
              <Navbar
                tabs={configs.activities.detail.routes.main.spam}
                backDisplayName="Spam Activities"
                handleBackRoute={true}
                backRoute={`${path}/spam`}
                rootPath={`${path}/spam`}
                meta={metaDetailed}
              />
            );
          }}
        />
        <Route
          path={[`${path}/spam/:activityId`]}
          component={() => {
            return (
              <Navbar
                tabs={configs.activities.detail.routes.main.spam}
                backDisplayName={subject ?? "Activity Details"}
                handleBackRoute={true}
                backRoute={getBaseRoute()}
                rootPath={`${path}/spam`}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default ActivitiesNavbar;
