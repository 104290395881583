import APIClient from "../APIClient";

export class ApplicationsClient {
  /**
   * * GET on /api/v1/Applications/query
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - Specify options
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type AppModel or Exception
   */
  getApplications(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<AppModelFetchResult> {
    const url = "api/v1/Applications/query";

    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }
}
