import React from "react";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";
import { AlertContext } from "../../../../../../contexts/AlertContext";
import { ComponentConfigContext } from "../../../../../../contexts/ComponentConfigContext";
import { WorkspaceContext } from "../../../../../../contexts/WorkspaceContext";
import { companiesClient } from "../../../../../../db/accessor";
import { contactsClientV2 } from "../../../../../../db/version2Accessor";
import { WorkspaceType } from "../../../../../../types/enums";
import { ContactMoveComponent } from "./ContactMoveComponent";

type ContactMoveContainerProps = {
  moveIds: string[];
  open: boolean;
  setMoveIds: React.Dispatch<React.SetStateAction<string[]>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  softRefresh: () => Promise<TableData[] | undefined>;
};

export const ContactMoveContainer = (props: ContactMoveContainerProps) => {
  const [companyList, setCompanyList] = React.useState<Partial<CompanyModel>[]>([]);
  const [selectedCompany, setSelectedCompany] = React.useState<Partial<CompanyModel> | null>(null);
  const [isLoadingCompanyList, setLoadingCompanyList] = React.useState<boolean>(true);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;

  /**
   * Fetches the company name and ID
   */
  const fetchCompanyList = async () => {
    setLoadingCompanyList(true);
    const configs = getConfig(selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR) as any;
    try {
      const response = await companiesClient.getCompanies(configs.connections.defaultConnectionFilter);
      setCompanyList(
        response.records?.map((company: CompanyModel) => {
          return {
            companyId: company.companyId,
            companyName: company.companyName,
          };
        }) ?? []
      );
    } catch (error: unknown) {
      console.log("error:: ", error);
      setToastOptions({ open: true, severity: "error", message: "Failed to fetch companies" });
    } finally {
      setLoadingCompanyList(false);
    }
  };

  const handleMove = async () => {
    setShowLoader(true);
    const createMessage = (actionTaken: string, noOfRecords: number) => {
      return noOfRecords > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? `${noOfRecords} contacts ${actionTaken} to ${selectedCompany?.companyName} successfully`
        : `Contact is ${actionTaken} to ${selectedCompany?.companyName} successfully`;
    };

    try {
      await contactsClientV2.updateContacts(
        {
          items: props.moveIds.map((contactId: string) => ({
            contact_id: contactId,
            company_id: selectedCompany?.companyId,
            title: "",
          })),
        },
        selectedWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
      );
      setToastOptions({ severity: "success", message: createMessage("moved", props.moveIds.length), open: true });
      // Refresh to GET latest updated contacts
      props.softRefresh();
    } catch (error: unknown) {
      console.error("error:: ", error);
      setToastOptions({ severity: "error", message: "Something Went Wrong", open: true });
    } finally {
      setShowLoader(false);
      props.setOpen(false);
      props.setMoveIds([]);
    }
  };

  const onClose = () => {
    props.setOpen(false);
    setSelectedCompany(null);
    setLoadingCompanyList(false);
  };

  React.useEffect(() => {
    fetchCompanyList();
  }, []);

  return (
    <ContactMoveComponent
      ids={props.moveIds}
      handleMove={handleMove}
      companyList={companyList}
      isLoadingCompanyList={isLoadingCompanyList}
      setSelectedCompany={setSelectedCompany}
      onClose={onClose}
      open={props.open}
      isLoading={showLoader}
    />
  );
};
