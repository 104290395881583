import React from "react";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import { useParams } from "react-router-dom";
import ActivitiesTable from "../../../Activities/ActivitiesTable/ActivitiesTable";
import { StatusBackendNumberMap } from "../../../../types/enums";

/**
 * * Define the Activities Tab of the Customer Detail's page
 */
export default function Activities(): React.ReactElement {
  const { customerId } = useParams<{ customerId: string }>();
  const COLUMNS = [
    {
      Header: "From",
      accessor: "primary_connection.name",
      width: "15%",
      showFilter: true,
      showSort: true,
      searchlightField: "platform_connection_company_name",
      searchlightToken: "cont",
      alignment: "left",
      filterAnchorPosition: "right",
      Cell: (props: { row: { original: { read: boolean } } }) => TableUtils.formatActivityIndex(props.row.original, !props.row.original.read),
    },
    {
      Header: "Subject",
      accessor: "subject_and_attachments",
      width: "45%",
      showFilter: true,
      showSort: true,
      searchlightField: "subject",
      sortField: "subject",
      searchlightToken: "i_cont",
      alignment: "left",
      filterAnchorPosition: "center",
      Cell: (props: { value: { subject: string | null; attachments: any }; row: { original: { read: boolean } } }) =>
        TableUtils.formatActivitySubject(props.value, !props.row.original.read),
    },
    {
      Header: "Status",
      accessor: "status",
      width: "18%",
      showFilter: true,
      popupPlaceholder: "ex. Open, Closed, Past Due",
      showSort: true,
      searchlightField: "status",
      sortField: "status",
      searchlightToken: "eq",
      upperBoundToken: "lt",
      dropdownOptions: [
        { label: "ACTIVE", value: StatusBackendNumberMap.active, id: "active" },
        { label: "WAITING FOR RESPONSE", value: StatusBackendNumberMap.waiting_for_response, id: "waiting_for_response" },
        { label: "SNOOZED", value: StatusBackendNumberMap.snoozed, id: "snoozed" },
        { label: "CLOSED", value: StatusBackendNumberMap.closed, id: "closed" },
      ],
      alignment: "left",
      filterAnchorPosition: "center",
      Cell: (props: { value: string | null }) => TableUtils.formatActivityStatus(props.value),
    },

    {
      Header: "Assigned To",
      accessor: "assignee.name",
      width: "15%",
      showFilter: true,
      showSort: true,
      searchlightField: "assignee_name",
      sortField: "assignee_name",
      searchlightToken: "i_cont",
      alignment: "left",
      filterAnchorPosition: "center",
      Cell: (props: { value: string | null; row: { original: { read: boolean } } }) =>
        TableUtils.formatActivityCellValue(props.value, !props.row.original.read),
    },
    {
      Header: "Last Activity Date",
      accessor: "last_activity_at",
      width: "15%",
      showSort: true,
      searchlightField: "last_activity_at",
      sortField: "last_activity_at",
      searchlightToken: "=",
      alignment: "left",
      filterAnchorPosition: "left",
      Cell: (props: { value: number | null; row: { original: { read: boolean } } }) =>
        TableUtils.formatActivitySentDateEpoch(props.value, !props.row.original.read),
    },
  ];

  return (
    <ActivitiesTable
      predefinedFilter={[
        {
          route: `${customerId}`,
          searchlightFilter: `COMPANYID EQ ${customerId} AND ACTIVITYSTATUS IN ('Active', 'Not Started', 'Closed')`,
        },
      ]}
      columns={COLUMNS}
      assigneeType={"all"}
      route={"all"}
      fromCustomers={true}
    />
  );
}
