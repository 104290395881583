import React from "react";
import { CaretUp } from "../Icons/Icons";
import NavLinkWrapper from "../NavLink";
import { Accordion, AccordionDetails, AccordionSummary, AccordionProps as MuiAccordionProps } from "@mui/material";
import "./AccordionNav.scss";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";

export type AccordionNavItemType = { id: string; label: string; icon?: React.ReactNode; count?: number; exact?: boolean };

interface AccordionNavItemProps extends Omit<AccordionNavItemType, "id"> {
  className?: string;
  to: string;
}

export function AccordionNavItem(props: AccordionNavItemProps): React.ReactElement {
  return (
    <NavLinkWrapper className={props.className} exact={props.exact ?? false} strict={false} to={props.to}>
      {(isActive: boolean) => (
        <div className={`accordion-nav-item ${isActive ? "active" : ""}`}>
          <div className={`icon-wrapper ${props.icon ? "icon-wrapper-img" : "icon-wrapper-text"} ${isActive ? "active" : ""}`}>
            <div className={`icon ${isActive ? "active" : ""}`}>
              {props.icon ?? props.label.charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO).toUpperCase()}
            </div>
          </div>
          <p className={`body2 ${isActive ? "active" : ""}`}>
            {props.label.charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO).toUpperCase() + props.label.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)}
          </p>
          {props.count ? (
            <div className="count-wrapper">
              <div className="count">{props.count}</div>
            </div>
          ) : null}
        </div>
      )}
    </NavLinkWrapper>
  );
}

interface AccordionNavProps extends Omit<MuiAccordionProps, "children"> {
  className?: string;
  title: string;
  listItems: AccordionNavItemType[];
  toParser: (value: Omit<AccordionNavItemType, "icon" | "count">) => string;
}

export default function AccordionNav({ className, title, listItems, toParser, ...rest }: AccordionNavProps): React.ReactElement {
  return (
    <Accordion className={`accordion-nav-overrides ${className ?? ""}`} defaultExpanded disableGutters {...rest}>
      <AccordionSummary expandIcon={<CaretUp />}>
        <p>{title}</p>
      </AccordionSummary>
      <AccordionDetails>
        {listItems.map((value: AccordionNavItemType) => {
          return (
            <AccordionNavItem key={value.id} label={value.label} to={toParser(value)} icon={value.icon} count={value.count} exact={value.exact} />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
