import React from "react";
import { useHistory } from "react-router";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { WorkspaceContext } from "../../../../../contexts/WorkspaceContext";
import { AlertContext } from "../../../../../contexts/AlertContext";
import { ApplicationRouteContext } from "../../../../../contexts/ApplicationRouteContext";
import { AppContext } from "../../../../../contexts/AppContext";
import NewActivityUtils from "../../../../../utils/NewActivityUtils/NewActivityUtils";
import NewActivityPopup, { ActivityDropdownItem } from "../../../../library/AddNewActivityDropdown/NewActivityPopup";
import { Email, NoteFill, Phone } from "../../../../library/Icons/Icons";
import { FallbackTypes } from "../../../../../types/enums";
import { RelatedTransactionItem, RelatedTransactionContainerProps } from "../ActivityTabInterfaces";
import RelatedTransactionsComponent from "./RelatedTransactionsComponent";
import { DocumentSwitchContext } from "../../../../../contexts/DocumentSwitchContext";
import "./RelatedTransactions.scss";

const RelatedTransactionsContainer = (props: RelatedTransactionContainerProps) => {
  const history = useHistory();
  // New activity states
  const [newActivityType, setNewActivityType] = React.useState<string>("");
  const [showActivity, setShowActivity] = React.useState<boolean>(false);
  const [transactionId, setTransactionId] = React.useState<string>("");
  const [transactionType, setTransactionType] = React.useState<string>("");
  const [contactOptions, setContactOptions] = React.useState<To[]>([]);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const newActivityUtils = new NewActivityUtils(selectedWorkspace?.id || FallbackTypes.Id);
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { allContactOptions } = React.useContext(AppContext) as AppType;
  const { setEnableDocumentSwitch } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const addActivityDropdownOptions: ActivityDropdownItem[] = [
    { displayName: "Email", icon: <Email /> },
    { displayName: "Note", icon: <NoteFill /> },
    { displayName: "Phone Call", icon: <Phone /> },
  ];

  const resetShowActivityStates = () => {
    setShowActivity(false);
    setContactOptions([]);
  };

  /**
   * Function takes in the entity name and uuid for the record at the
   * time of creation and attaches as onClick handler to the item.
   *
   * @param entityName record being clicked - payments/bills/invoices
   * @param id - uuid of the record
   * @returns navigate to the record detail page
   */
  const getNavigateHandler = (entityName: string, id: UUID) => {
    return () => {
      /**
       * Temporarily disable document switcher for related transactions.
       */
      setEnableDocumentSwitch(false);
      history.push(`${getBaseRoute()}/${entityName}/${id}`);
    };
  };

  const activityProps = newActivityUtils.evaluateNewActivityProps(
    newActivityType,
    showActivity,
    contactOptions,
    allContactOptions,
    setToastOptions,
    resetShowActivityStates,
    setNewActivityType,
    addActivityDropdownOptions,
    transactionId,
    resetShowActivityStates,
    undefined,
    transactionType
  );

  const handleNewActivity = (item: RelatedTransactionItem, buttonId: string) => {
    setNewActivityType(buttonId);
    setTransactionId(item.transactionId ?? "");
    setShowActivity(true);
    setTransactionType(item.transactionType ?? "");
  };

  const menuItems = [
    {
      value: "New Email",
      id: "email",
      clickFunction: (data: any) => {
        handleNewActivity(data, "email");
      },
    },
    {
      value: "New Note",
      id: "note",
      clickFunction: (data: any) => {
        handleNewActivity(data, "note");
      },
    },
    {
      value: "New Phone Call",
      id: "phone_call",
      clickFunction: (data: any) => {
        handleNewActivity(data, "phone_call");
      },
    },
  ];

  return (
    <>
      <div className="rt-container">
        {!props.transactions.invoices?.length && !props.transactions.bills?.length && !props.transactions.payments?.length ? (
          <div className="no-data-wrapper">
            <p className="no-data">There have been no invoice or payments for this activity stream yet</p>
          </div>
        ) : (
          <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, overflowBehavior: { x: "hidden" } }}>
            <RelatedTransactionsComponent {...{ getNavigateHandler: getNavigateHandler, ellipsesMenuItems: menuItems, ...props.transactions }} />
          </OverlayScrollbarsComponent>
        )}
      </div>
      {showActivity && <NewActivityPopup {...activityProps} />}
    </>
  );
};

export default RelatedTransactionsContainer;
