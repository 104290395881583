import React from "react";
import Button from "../../../../../../library/Button/Button";
import { RequestApproval } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { ApprovalRequestProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

const Request: React.FC<ApprovalRequestProps> = ({ onClickApprove, onClickDecline, onClickNudge, enableReviewOptions, ...props }) => {
  return (
    <PrimaryActivity
      {...{
        ...props,
        icon: <RequestApproval />,
        creator: { ...props.creator, message: Labels.HEADER_APPROVAL_REQUEST },
        FLAGS: { REPLY_OPTIONS: true, TIME_SAVING_INDICATOR: true },
      }}
    >
      {enableReviewOptions && (
        <>
          <Button variant="primary" size="sm" type="button" onClick={onClickApprove}>
            {Labels.BTN_APPROVE}
          </Button>
          <Button variant="secondary" size="sm" type="button" onClick={onClickDecline}>
            {Labels.BTN_DECLINE}
          </Button>
          <Button variant="transparent" size="sm" type="button" onClick={onClickNudge}>
            {Labels.BTN_SEND_REMINDER}
          </Button>
        </>
      )}
    </PrimaryActivity>
  );
};

export default Request;
