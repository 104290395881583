import APIClient from "../APIClient";

export class StatusClient {
  /**
   * * GET on /api/v2/Status
   * @returns Promise of type StatusModel or Exception
   */
  getStatusV2(): Promise<APIResponse> {
    const url = "inbox/api/v2/status";

    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }
}
