import React from "react";
import { useRouteMatch, Switch, Route } from "react-router";
import { ApplicationRouteContext } from "../../contexts/ApplicationRouteContext";
import Navbar from "../library/Navbar/Navbar";
import "../Invoices/Invoices.scss";
import { InvoiceContext } from "../../contexts/InvoiceContext";
import { DEFAULT_NUMERIC_VALUES, TRIM_COUNT_CONSTANTS } from "../../constants/NumericConstants";
import useWorkspaceConfigurations from "../../hooks/useWorkspaceConfigurations";

const InvoiceNavbar = () => {
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { invoiceData } = React.useContext(InvoiceContext) as InvoiceType;
  const { path } = useRouteMatch();
  const { configs } = useWorkspaceConfigurations();

  const getDropdownRoutes = (displayName: string, companyName: string): any[] => {
    return configs.invoices.routes.dropdown.map((route: Record<string, string>) => {
      return {
        displayName: `${displayName ? displayName : "All"} ${route.entity}`,
        routeName: companyName ? `${route.routeName}&customer_name=${companyName}` : `${route.routeName}`,
      };
    });
  };

  const dropdownRootpath = path.substr(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/"));

  return (
    <div className={`invoice-head`}>
      <Switch>
        <Route
          exact
          path={[
            `${path}`,
            `${path}/late_91`,
            `${path}/late_61_90`,
            `${path}/late_31_60`,
            `${path}/late_1_30`,
            `${path}/futureDue`,
            `${path}/closed`,
          ]}
          component={() => <Navbar tabs={configs.invoices.routes.main} rootPath={path} />}
        />
        {/*
         * Navbar with the back route pointing to invoice table view
         */}
        <Route
          exact
          path={`${path}/:invoiceId`}
          component={() => {
            const companyName = invoiceData?.customer?.companyName || "N/A";
            const displayName = companyName
              ? companyName.length >= TRIM_COUNT_CONSTANTS.COMPANY_NAME
                ? companyName.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, TRIM_COUNT_CONSTANTS.COMPANY_NAME) + "..."
                : companyName
              : "";
            return (
              <Navbar
                tabs={configs.invoices.routes.main}
                dropdown={getDropdownRoutes(displayName, companyName)}
                dropdownRootPath={dropdownRootpath}
                dropdownDefault={`${configs.invoices.routes.dropdownDefaultTitle} ${invoiceData?.referenceCode ?? "N/A"}`}
                rootPath={path}
                handleBackRoute={true}
                backRoute={path}
                backDisplayName={`All ${configs.invoices.routes.dropdownDefaultTitle}`}
              />
            );
          }}
        />
        {/*
         * Navbar with back route pointing to invoice details page
         */}
        <Route
          path={[`${path}/:invoiceId`]}
          component={() => {
            const invoiceNumber = invoiceData?.referenceCode;
            const companyName = invoiceData?.customer?.companyName || "N/A";
            const displayName = companyName
              ? companyName.length >= TRIM_COUNT_CONSTANTS.COMPANY_NAME
                ? companyName.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, TRIM_COUNT_CONSTANTS.COMPANY_NAME) + "..."
                : companyName
              : "";
            return (
              <Navbar
                rootPath={path}
                tabs={configs.invoices.routes.main}
                dropdown={getDropdownRoutes(displayName, companyName)}
                dropdownRootPath={dropdownRootpath}
                dropdownDefault={`${configs.invoices.routes.dropdownDefaultTitle} ${invoiceData?.referenceCode ?? "N/A"}`}
                handleBackRoute={true}
                backRoute={getBaseRoute()}
                backDisplayName={`${configs.invoices.routes.dropdownDefaultTitle} #${invoiceNumber}`}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default InvoiceNavbar;
