import APIClient from "../APIClient";

export class CompaniesClient {
  /**
   * * Get on  /api/v2/connections?archive=${archive}&workspace_id=${workspace_id}
   * @returns Promise of type APIResponse Model or Exception
   */
  getCustomerSummaries(filters?: string, sortQuery?: string, pageSize?: number, pageNumber?: number): Promise<FetchResult> {
    let url = `/inbox/api/v2/connections/workspace_mappings?page=${pageNumber}&page_size=${pageSize}`;
    if (filters) {
      url = `${url}&${filters}`;
    }
    if (sortQuery) {
      url = `${url}&${sortQuery}`;
    }
    return APIClient.get(url).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        records: response.data.records,
      };
    });
  }

  createArchiveConnection(payload: UpdateCustomerStatusPayload): Promise<APIResponse> {
    const url = `/inbox/api/v2/connections/workspace_mappings/`;
    return APIClient.post(url, payload).then((response) => {
      return response.data;
    });
  }

  updateCustomer(payload: UpdateCustomerStatusPayload): Promise<APIResponse> {
    const url = `/inbox/api/v2/connections/workspace_mappings/`;
    return APIClient.patch(url, payload).then((response) => {
      return response.data;
    });
  }

  exportConnections(filters: string): Promise<APIResponse> {
    let url = `inbox/api/v2/connections.csv`;
    if (filters) {
      url = `${url}?${filters}`;
    }
    return APIClient.get(url).then((response) => response.data);
  }

  mergeConnections(connectionId: string, mergeIds: string[], workspaceId: number): Promise<APIResponse> {
    const url = `inbox/api/v2/connections/`;
    return APIClient.patch(url, {
      connection_ids: mergeIds,
      workspace_id: workspaceId,
      action_type: "merge",
      id: connectionId,
    }).then((response) => {
      return response.data;
    });
  }
}
