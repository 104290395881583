import React from "react";
import InvoiceDetail from "./InvoiceDetail/InvoiceDetail";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import InvoiceTable from "./InvoiceTable/InvoiceTable";
import "./Invoices.scss";
import FourOFourError from "../../routes/FourOFourError/FourOFourError";
import InvoiceNavbar from "./InvoiceNavbar";

type InvoicesProps = {
  componentConfig: any;
};

/**
 * * Defines the Invoices Tab (Top-level route)
 */
export default function Invoices(props: InvoicesProps): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <div className={`invoices-wrapper`}>
      <InvoiceNavbar />
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, overflowBehavior: { x: "hidden" } }}>
        <div className={`body`}>
          <Switch>
            <Route
              exact
              path={[
                `${path}`,
                `${path}/late_91`,
                `${path}/late_61_90`,
                `${path}/late_31_60`,
                `${path}/late_1_30`,
                `${path}/futureDue`,
                `${path}/closed`,
              ]}
              component={() => {
                return <InvoiceTable configs={props.componentConfig} />;
              }}
            />
            <Route
              path={`${path}/:invoiceId`}
              render={() => {
                return <InvoiceDetail />;
              }}
            />
            <Route
              component={() => {
                return <FourOFourError />;
              }}
            />
          </Switch>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}
