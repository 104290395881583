import React from "react";
import SmartTextComponent from "./SmartTextComponent";
import { SmartTextContainerInterface } from "./SmartTextInterfaces";

const SmartTextContainer: React.FC<SmartTextContainerInterface> = (props: SmartTextContainerInterface) => {
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const contentRef = React.useRef<HTMLParagraphElement | null>(null);
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

  const toggleTooltip = () => {
    if (divRef.current && contentRef.current && divRef.current.clientWidth < contentRef.current.scrollWidth) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const highlightContent = () => {
    if (props?.highlighter?.matchText) {
      const regStr = props.title;
      const matchRegex = new RegExp(props.highlighter.matchText, "ig");
      const matchKeys = regStr.match(matchRegex);
      return regStr.replace(matchKeys?.[0] ?? "~", `<mark>${matchKeys?.[0]}</mark>`);
    }
    return props.title;
  };

  React.useEffect(() => {
    toggleTooltip();
    window.addEventListener("resize", toggleTooltip);
    return () => window.removeEventListener("resize", toggleTooltip);
  }, []);

  return (
    <SmartTextComponent
      {...props}
      title={props?.highlighter?.enableHighlighter ? highlightContent() : props.title}
      toggleTooltip={toggleTooltip}
      tooltip={{ showTooltip, title: props.title }}
      divRef={divRef}
      contentRef={contentRef}
    />
  );
};

export default SmartTextContainer;
