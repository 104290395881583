import React, { ChangeEvent, KeyboardEvent, MouseEventHandler } from "react";
import { Search } from "../Icons/Icons";
import "./Input.scss";

type LabelObject = {
  regularText: string;
  extraText: string;
};

interface SearchBarProps {
  placeholder?: string;
  defaultValue?: string | null;
  label?: string | LabelObject;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onClick?: MouseEventHandler<HTMLInputElement>;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: any;
  type?: string;
  autocomplete?: string;
  required?: boolean;
  value?: string;
  disabled?: boolean;
  errorMessage?: string;
  icon?: React.ReactNode;
  maxLength?: number;
  name?: string;
}

export function Input(props: SearchBarProps): React.ReactElement {
  const isLabelObject = (x: string | LabelObject): x is LabelObject => true;
  const inputId = typeof props?.label === "string" ? props?.label : props?.label?.regularText;

  return (
    <div className={`sb-wrapper ${props.className ?? ""}`}>
      {props?.label &&
        (typeof props?.label === "string" ? (
          <span className="sb-label-container">
            <label htmlFor={inputId} className="sb-label">
              {props?.label ? props.label : ""}
            </label>
          </span>
        ) : isLabelObject(props?.label) ? (
          <span className="sb-label-container">
            <label htmlFor={inputId} className="sb-label">
              {props.label?.regularText}

              <span className="sb-label-optional">{props?.label?.extraText}</span>
            </label>
          </span>
        ) : (
          <></>
        ))}

      <input
        id={inputId}
        className={`sb-input ${props.className ?? ""}`}
        placeholder={props.placeholder ?? "Search"}
        onChange={(e: ChangeEvent<HTMLInputElement>) => (props.onChange ? props.onChange(e) : null)}
        onKeyDown={props.onKeyDown}
        onClick={props.onClick}
        defaultValue={props.defaultValue ?? undefined}
        ref={props.inputRef ?? undefined}
        autoComplete={props.autocomplete ?? undefined}
        type={props.type ?? undefined}
        required={props.required}
        disabled={props.disabled}
        value={props.value ?? undefined}
        maxLength={props.maxLength}
        style={props.errorMessage ? { border: "1px solid #e54646" } : {}}
        name={props.name}
      />
      {props?.errorMessage && (
        <span className="sb-error-container">
          <label htmlFor={inputId} className="sb-error">
            {props?.errorMessage ?? ""}
          </label>
        </span>
      )}
    </div>
  );
}

export function InputWithIcon(props: SearchBarProps): React.ReactElement {
  const inputId = typeof props?.label === "string" ? props?.label : props?.label?.regularText;

  return (
    <div className={`sbIcon-wrapper ${props.className ?? ""}`}>
      {props?.label && (
        <label htmlFor={inputId} className="sbIcon-label">
          <>{props?.label ? props.label : ""}</>
        </label>
      )}

      <div className={"sbIcon-input-wrapper"}>
        <input
          id={inputId}
          className={`sbIcon-input`}
          placeholder={props.placeholder ?? "Search"}
          onChange={(e: ChangeEvent<HTMLInputElement>) => (props.onChange ? props.onChange(e) : null)}
          onKeyDown={props.onKeyDown}
          defaultValue={props.defaultValue ?? undefined}
          required={props.required}
          value={props.value ?? undefined}
          maxLength={props.maxLength}
        />
        {props.icon || <Search />}
      </div>
    </div>
  );
}
