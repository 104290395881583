import React, { useState, MouseEvent, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import Table from "../../../library/Table/Table";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import { FallbackTypes } from "../../../../types/enums";
import { invoicesClient } from "../../../../db/accessor";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES, ISO_DATE_SLICE } from "../../../../constants/NumericConstants";
import NewActivityUtils from "../../../../utils/NewActivityUtils/NewActivityUtils";
import NewActivityPopup, { ActivityDropdownItem } from "../../../library/AddNewActivityDropdown/NewActivityPopup";
import { Email, NoteFill, Phone } from "../../../library/Icons/Icons";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { AlertContext } from "../../../../contexts/AlertContext";
import { viewType } from "../../../../app/Templates/TemplateTypes";
import { TemplateContext } from "../../../../contexts/TemplateContext";
import { TemplateDefinitionProps } from "../../../../app/Templates/TemplateFactory";
import { formatDate } from "../../../../db/utils/date";
import { AppContext } from "../../../../contexts/AppContext";
import { DocumentSwitchContext } from "../../../../contexts/DocumentSwitchContext";
import { ApplicationRouteContext } from "../../../../contexts/ApplicationRouteContext";
import Utils from "../../../../utils/utils";
import { InvoiceContext } from "../../../../contexts/InvoiceContext";

interface OpenInvoicesProps {
  configs: any;
}
/**
 * * Define the Invoices Tab of the Customer Detail's page
 */
export default function OpenInvoices(props: OpenInvoicesProps): React.ReactElement {
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const { company , signature} = React.useContext(CustomerContext) as CustomerType;
  const params = new URLSearchParams(window.location.search);
  const { get, invoiceData, setInvoiceData } = React.useContext(InvoiceContext) as InvoiceType;
  const openInvoiceConfigs = props.configs.open_invoices;

  const pageNoFromUrl = params.get("page");
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "0");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    } else {
      return pageNum;
    }
  });
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION_VALUES.PAGE_SIZE);

  // New activity states
  const [newActivityType, setNewActivityType] = useState<string>("");
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<string>("");
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const newActivityUtils = new NewActivityUtils(selectedWorkspace?.id || FallbackTypes.Id);
  const { templateFactory, handleTemplateAttachment, prepareTemplateDataObject, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { userStatus, getContactsOptions, allContactOptions, filterPrimaryContacts } = React.useContext(AppContext) as AppType;
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setPlatformPageFetchParams,
    setDocumentType,
    setEnableDocumentSwitch,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;

  function handleRowClick(e: MouseEvent<HTMLTableRowElement>, row: { original: { id?: string } }) {
    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     * @param selectedActivityStreamId - currently selected document id
     * @param activityStreamIds - list of documents in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    setSelectedActivityStreamId({ id: row.original.id } as ActivityStreamId);
    setActivityStreamIds(tableData.map((item) => ({ id: item.id })));
    setCurrentPaginationState({ page: pageNumber, pageCount, pageSize, totalRecords: totalCount });
    setDocumentType("related-invoice");
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${getBaseRoute()}/${openInvoiceConfigs.routeType}/${row.original.id ?? ""}`);
  }

  const getTemplateDefinitionsByView = (row: any) => {
    const view: viewType =
      selectedWorkspace.workspace_type === "accounts_payable"
        ? viewType.AP_CONNECTION_VENDOR_OPEN_BILLS
        : viewType.AR_CONNECTION_CUSTOMER_OPEN_INVOICES;
    return templateFactory.getTemplateDefinitionsByView(view, { invoiceNumber: row.invoice_number, customer: row.customer_name });
  };

  const getCompanyData = async (customerId: string) => {
    const data = await newActivityUtils.fetchCompanyInfo(customerId, setToastOptions);
    setCompanyData(data);
  };

  const resetShowActivityStates = () => {
    setShowActivity(false);
  };

  const handleCloseActivityPopUp = () => {
    setShowActivity(false);
    setCompanyContactOptions([]);
  };

  const fetchParser = (fetchResult: InvoiceSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: InvoiceSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.invoiceId,
          }),
          ...((variant === "export" || variant === "all") && {
            invoice_number: record.invoiceNumber,
            status: record.status,
            invoice_date: record.invoiceDate,
            due_date: record.paymentDueDate,
            invoice_amount: record.invoiceAmount,
            outstanding_amount: record.outstandingBalance,
            customer_name: record.customerName,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const fetchInvoices = () => {
    const preDefinedFilters = [
      ...openInvoiceConfigs.table.preDefinedFilters,
      {
        searchlightFilter: `(CUSTOMERID eq '${customerId}')`,
      },
    ];
    const sortQuery = TableUtils.getSortFromURL(params);
    const filterQuery = TableUtils.getFilterFromURL(params, openInvoiceConfigs.table.columns);
    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    const fetchParams = { pageSize, page: pageNumber, order: sortQuery, filter: filterQuery };
    setPlatformPageFetchParams(fetchParams as PlatformPageFetchParams);
    return invoicesClient.getInvoiceSummaries(
      TableUtils.columnFilterParser(filterQuery, false, preDefinedFilters),
      undefined,
      TableUtils.sortQueryParser(sortQuery ?? "", false),
      pageSize,
      pageNumber
    );
  };

  const addActivityDropdownOptions: ActivityDropdownItem[] = [
    { displayName: "Email", icon: <Email /> },
    { displayName: "Note", icon: <NoteFill /> },
    { displayName: "Phone Call", icon: <Phone /> },
  ];

  const filteredContacts = [...companyContactOptions, ...allContactOptions];

  const activityProps = newActivityUtils.evaluateNewActivityProps(
    newActivityType,
    showActivity,
    companyContactOptions,
    filteredContacts,
    setToastOptions,
    handleCloseActivityPopUp,
    setNewActivityType,
    addActivityDropdownOptions,
    invoiceId,
    resetShowActivityStates,
    customerId
  );

  React.useEffect(() => {
    /**
     * Fetch and set company data on initial loading
     */
    getCompanyData(userStatus.account_company_id as string);
    return () => {
      setTotalCount(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    };
  }, []);

  const setCompanyContacts = async (customerId: string) => {
    setCompanyContactOptions(await getContactsOptions(customerId));
  };

  const fetchData = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      const res = await get(invoiceId);
      setInvoiceData(res);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleNewActivity = async (rowData: TableData, buttonId: string) => {
    if (buttonId === "email") {
      setSupportedTemplateList(getTemplateDefinitionsByView(rowData));
    }
    await setCompanyContacts(customerId);
    setSelectedItem(rowData);
    setNewActivityType(buttonId);
    setInvoiceId(rowData.id);
    setShowActivity(true);
    fetchData();
  };

  const ellipsisActionHandlers: Partial<RowSelectButtons> = {
    "action.newEmail": handleNewActivity,
    "action.newNote": handleNewActivity,
    "action.newPhoneCall": handleNewActivity,
  };

  const cellRendererMapping: CellRendererMappingObject = {
    "table.formatString": (props: { value: string | null }) => TableUtils.formatString(props.value, false, "contact-bold"),
    "table.formatDate": (props: { value: string | null }) => TableUtils.formatDate(props.value, false, "contact-bold"),
    "table.formatAction": (props: { value: string | null; row: { original: { status: string } } }) => {
      return TableUtils.formatAction(
        props.row.original,
        openInvoiceConfigs.table.ellipsisActions.map((action: EllipsisAction) => {
          return { ...action, clickFunction: ellipsisActionHandlers[action.handler] };
        })
      );
    },
    "table.formatCurrency": (props: { value: number | null }) =>
      TableUtils.formatCurrency(props.value, false, userStatus?.currency?.locale, userStatus?.currency?.code),
    "table.formatInvoiceStatus": (props: { value: string | null; row: { original: { due_date: string | null; status: string } } }) => {
      return TableUtils.formatInvoiceStatusByDueDate(
        props.row.original.due_date?.slice(ISO_DATE_SLICE.START, ISO_DATE_SLICE.END_SHORT_DATE) ?? "",
        props.row.original.status ?? "",
        false
      );
    },
  };

  // Calculates the payments applied amount for a given invoice
  const calculatedPaymentsApplied = useMemo(
    () =>
      invoiceData?.payments
        ? invoiceData?.payments.reduce((a, b) => a + b.paymentAppliedAmount, DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
        : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
    [invoiceData]
  );

  const prepareActivityBodyByTemplateID = (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    setFileCallback?: FunctionCall
  ) => {
    if (templateID) {
      const data: any = {
        customer: selectedItem?.customer_name || "",
        invoiceNumber: selectedItem?.invoice_number || "",
        invoiceDate: formatDate(selectedItem.invoice_date) || "",
        paymentDueDate: formatDate(selectedItem.due_date) || "",
        invoiceAmount: Utils.formatValueAsCurrency(
          selectedItem.invoice_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          userStatus?.currency?.locale,
          userStatus?.currency?.code
        ),
        outstandingBalance: Utils.formatValueAsCurrency(
          selectedItem.outstanding_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          userStatus?.currency?.locale,
          userStatus?.currency?.code
        ),
        emailAddress: companyData?.emailAddress || "",
        companyName: companyData?.companyName || "",
        phone: companyData?.phoneNumber || "",
        //Assuming first payment is the latest payment number
        paymentNumber: invoiceData?.payments ? invoiceData?.payments[0]?.referenceCode : "{paymentNumber}",
        totalPaymentApplied: Utils.formatValueAsCurrency(
          calculatedPaymentsApplied ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          userStatus?.currency?.locale,
          userStatus?.currency?.code
        ),
        signature: signature?.email_signature || ""
      };
      const templateObj = templateData.get(selectedWorkspace?.id).get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(templateObj.parseTemplate(data));
      if (templateObj.templateAttachment) {
        const templateDataMapper = prepareTemplateDataObject(
          templateObj,
          [{ name: `Invoice #${selectedItem.invoice_number ?? "N/A"}`, id: selectedItem.id }],
          null,
          null
        );
        handleTemplateAttachment(templateObj, setFileCallback, templateDataMapper);
      }
    }
  };

  const activityComponentProps = () => {
    return {
      ...activityProps,
      isTemplateSupport: newActivityType === "email",
      supportedTemplateList,
      defaultTo: filterPrimaryContacts(companyContactOptions),
      prepareActivityBodyByTemplateID,
      fromTime,
      toTime,
      setToTime,
    };
  };

  return (
    <>
      <Table
        dataSets={[
          {
            id: "Invoices",
            displayName: "Invoices",
            rowSelectToken: "invoiceId",
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: fetchInvoices,
              fetchParser: fetchParser,
            },
            columns: openInvoiceConfigs.table.columns.map((column: ColumnHeaderType) => {
              return { ...column, Cell: cellRendererMapping[column.CellRenderer] };
            }),
            export: {
              exportFileName: `${(company as CustomerDetailsModel)?.name ?? "N/A"} - Open Invoices`,
            },
            handleRowClick: handleRowClick,
            defaultSort: "PAYMENTDUEDATE, INVOICENUMBER",
            defaultSortToken: "DESC",
          },
        ]}
        states={{
          isLoading: isLoading,
          setLoading: setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: totalCount,
          setTotalCount: setTotalCount,
          setPageSize: setPageSize,
        }}
        toggles={{
          showSearchbar: false,
          showExportBtn: true,
          showCaption: true,
          showRowSelect: true,
          showNavigation: true,
        }}
        isColumnFixed={true}
      />
      {showActivity && <NewActivityPopup {...activityComponentProps()} />}
    </>
  );
}
