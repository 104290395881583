import React from "react";
import RedirectRequestContainer from "./components/Approvals/RedirectRequest/RedirectRequestContainer";
import Status from "./components/Approvals/Status/Status";
import { Failure, NamedLockstepLogo } from "./components/library/Icons/Icons";
import "./styles.scss";
import { Route, Switch } from "react-router";
import FourOFourError from "./routes/FourOFourError/FourOFourError";
import ApprovalReview from "./components/Approvals/ApprovalReview";
import MagicAuthProvider from "./contexts/external/MagicAuthContext";
import { ApprovalDetailsContext, ApprovalsProviderType } from "./contexts/external/ApprovalDetailsContext";

const MagicLinks = () => {
  const { requestData } = React.useContext(ApprovalDetailsContext) as ApprovalsProviderType;

  const AccountHeader = () => {
    return requestData?.account_details?.account_logo_url ? (
      <img src={requestData.account_details.account_logo_url} alt={requestData?.account_details?.account_name} />
    ) : requestData?.account_details?.account_name ? (
      <h2 className="account-name">{requestData?.account_details?.account_name}</h2>
    ) : (
      <></>
    );
  };

  const LinkExpired = () => (
    <Status size="sm" logo={<Failure />} statusMessage={"Link has expired"} subText={"Looks like the magic link has no longer exist"} />
  );

  return (
    <div className="magic-app-container">
      <div className="app-header">
        <AccountHeader />
      </div>
      <div className="app-content">
        <MagicAuthProvider>
          <Switch>
            <Route exact path={`/approval-request/:id`} component={ApprovalReview} />
            <Route exact path={`/redirect-request/:id`} component={RedirectRequestContainer} />
            <Route exact path={`/link-expired`} component={LinkExpired} />
            <Route path={`/`} component={() => <FourOFourError transparent />} />
          </Switch>
        </MagicAuthProvider>
      </div>
      <div className="app-footer">
        <p className="footer-txt">Connected By</p>
        <div className="footer-logo">
          <NamedLockstepLogo />
        </div>
      </div>
    </div>
  );
};

export default MagicLinks;
