import React from "react";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { formatDate } from "../../../db/utils/date";
import Utils from "../../../utils/utils";
import { TableContainerInterface } from "./Interfaces/TableInterface";
import "./Styles/scss/TableStyles.scss";
import TableComponent from "./TableComponent";
import TableUtils from "../../../utils/TableUtils/TableUtils";

const TableContainer: React.FC<TableContainerInterface> = ({ tableConfig }: TableContainerInterface) => {
  /**
   *  Get Dependent Parameters
   */
  const {
    checkboxes,
    controllers: { loading, hasMore, loadMore },
    handlers: { onClickRowHandler },
    currencyConfig,
  } = tableConfig;

  const [currentActiveFilterIndex, setCurrentActiveFilterIndex] = React.useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const setCurrentFilterIndex = (index: number) => {
    setCurrentActiveFilterIndex(index);
  };

  /**
   *  Get the size of overflow scroll area
   */

  const [bottomHeight, setBottomHeight] = React.useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  /**
   *  React Ref Object for table
   */
  const tableRef = React.useRef<HTMLDivElement>(null);

  /**
   *  Scroll Listener Handler for the Table
   */
  const scrollListenerHandler = React.useCallback(() => {
    if (!tableRef.current) {
      return;
    }
    const bottomArea = tableRef.current.scrollHeight - tableRef.current.clientHeight;
    if (!bottomHeight) {
      setBottomHeight(Math.round((bottomArea / NUMERIC_VALUES.CONSTANT_HUNDRED) * NUMERIC_VALUES.CONSTANT_TWENTY));
    }
    if (tableRef.current.scrollTop > bottomArea - bottomHeight && hasMore && !loading && loadMore) {
      loadMore();
    }
  }, [hasMore, loadMore, loading, bottomHeight]);

  /**
   *  Getter for formatting table data
   */
  const formatListData = (value: string | number | null | Array<string> | undefined, type?: string, status?: string): string | React.ReactElement => {
    switch (type) {
      case "timestamp":
        return formatDate(Utils.evaluateDate(value as number));
      case "isoDate":
        return formatDate(value as string);
      case "currency":
        return Utils.formatValueAsCurrency(value as number, currencyConfig?.locale, currencyConfig?.code);
      case "array_of_strings":
        return value instanceof Array ? value?.join?.(",") : "N/A";
      case "invoiceStatus":
        return TableUtils.formatInvoiceStatusByDueDate(value as string, status ?? "", false);
      default:
        return value ? value?.toString() : "";
    }
  };

  /**
   *  Row Handler Function onClick
   */

  const rowHandler = (data: any) => {
    if (onClickRowHandler) {
      onClickRowHandler(data);
    }
  };

  /**
   *  Adding Scroll Listener before the mount to reach better accuracy
   */
  React.useLayoutEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement?.addEventListener("scroll", scrollListenerHandler);
      return () => {
        tableElement?.removeEventListener("scroll", scrollListenerHandler);
      };
    }
  }, [scrollListenerHandler]);

  return (
    <TableComponent
      tableConfig={{
        ...tableConfig,
        checkboxes: checkboxes ?? {},
        loading,
        tableRef,
        formatter: formatListData,
        header: {
          ...tableConfig.header,
          currentActiveFilterIndex,
          setCurrentFilterIndex,
        },
        handlers: { ...tableConfig.handlers, onClickRowHandler: rowHandler },
      }}
    />
  );
};

export default TableContainer;
