import React from "react";
import { Input } from "../../../library/Input/Input";

interface ActivityTitleProps {
  title: string;
  isDisabled?: boolean;
  handleOnChange: (value: string) => void;
}

export default function ActivityTitle(props: ActivityTitleProps): React.ReactElement {
  return (
    <Input value={props.title} disabled={props?.isDisabled ?? false} onChange={(e) => props.handleOnChange(e.target.value)} placeholder="Subject" />
  );
}
