import React from "react";
import { useHistory } from "react-router";
import { AuthAccessor } from "../../db/unauthAccessors";

export interface MagicAuthContextType {
  refreshIntervalId: NodeJS.Timeout | undefined;
  setRefreshIntervalId: React.Dispatch<React.SetStateAction<NodeJS.Timeout | undefined>>;
  getToken: (id: UUID) => Promise<unknown>;
  authenticated: boolean;
}

export const MagicAuthContext = React.createContext<MagicAuthContextType | null>(null);

/**
 * Context provides thes Magic TOKEN request and authentication related
 * states. It keeps track of refresh interval id which prevents system
 * to setup duplicate authentication request intervals
 *
 * @param props ReactNode or string type element or Array
 * @returns Provides token api calls & authentication related values
 */
const MagicAuthProvider = (props: { children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string }) }) => {
  const [refreshIntervalId, setRefreshIntervalId] = React.useState<NodeJS.Timeout>();
  const [authenticated, setAuthenticated] = React.useState<boolean>(false);

  const history = useHistory();

  const getToken = async (id: UUID) => {
    let response = {} as any;
    try {
      response = await AuthAccessor.getToken(id);
      sessionStorage.setItem("token", response.data);
      /**
       * we only authenticated state once, refreshIntervalId represents
       * that we have a recurring call setup for authentication and hence
       * we skip the set state, if it is already set.
       */
      if (!refreshIntervalId) {
        setAuthenticated(true);
      }
      /**
       * check if call was success or not
       */
      if (!response.success) {
        history.push("/link-expired");
      }
    } catch (error) {
      console.error("error: ", error);
      history.push("/link-expired");
    }
    return response.success;
  };

  return (
    <MagicAuthContext.Provider value={{ refreshIntervalId, setRefreshIntervalId, getToken, authenticated }}>
      {props.children}
    </MagicAuthContext.Provider>
  );
};

export default MagicAuthProvider;
