import React, { useState } from "react";
import { DEFAULT_NUMERIC_VALUES } from "../constants/NumericConstants";
import { AppContext } from "./AppContext";

export const SettingsContext = React.createContext<SettingsType | null>(null);

export interface SettingsProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const SettingsProvider: React.FC<SettingsProviderProps> = ({ children }: SettingsProviderProps) => {
  const { userStatus } = React.useContext(AppContext) as AppType;

  // State needed for Settings pages, hoisted up to pass to the header.
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [syncHasUpdated, setSyncHasUpdated] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [lastSync, setLastSync] = useState<SyncRequestModel>({
    syncRequestId: "",
    groupKey: "",
    statusCode: "",
    processResultMessage: "",
    appEnrollmentId: "",
    created: "",
    modified: "",
    modifiedUserId: "",
    details: null,
  });
  /**
   * this limits the scope of the ap automation mail state to settings.
   * So, that while writing updates for the same, we could reduce
   * unnecessary re-renders by not updating userStatus global state.
   *
   * Note: writing in this context also means that userStatus could have stale
   * ap_automation_email data. But that does not affect the application as that
   * is only being used to load intial server data and update requests will be
   * write-through i.e. state here will be update first!
   */
  const [apAutomationMail, setApAutomationMail] = useState<string>(userStatus.ap_automation_email || "");

  /**
   * refreshes the state of ap automation email, which
   * updates the UI for the settings.
   */
  React.useEffect(() => {
    setApAutomationMail(userStatus.ap_automation_email ?? "");
  }, [userStatus.ap_automation_email]);

  return (
    <SettingsContext.Provider
      value={{
        tableData,
        setTableData,
        setSyncHasUpdated,
        syncHasUpdated,
        setLastSync,
        lastSync,
        apAutomationMail,
        setApAutomationMail,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
