import React, { useState, Dispatch, SetStateAction } from "react";
import { IJodit } from "jodit/types";
import { UserPic } from "../../../../library/Icons/Icons";
import { Skeleton } from "@mui/material";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { MENTION_REGEX_CONSTANTS } from "../../../../../constants/RegexConstants";

/**
 * @function MentionHOC
 * A Wrapper to Wrap the Jodit Editor in order to suggest mention members for @ types
 * A Selection Modal will be opened as @ is typed followed with a query.
 * @param editorState Current Editor State value
 * @param setEditorState Set the current Editor value
 * @param mentionMembers The Mention Members to suggest
 * @children the Jodit Editor to wrap
 * @param EditorInstance the Jodit Editor instance.
 */

type MentionsDataItemType = {
  id: number;
  name?: string;
  email_address?: string;
  active_at?: string;
  user_role?: string;
};

type MentionMembersType = {
  loading: boolean;
  members: Array<MentionsDataItemType>;
};

interface MentionHOCProps {
  editorState: string;
  setEditorState: Dispatch<SetStateAction<string>>;
  mentionMembers: MentionMembersType;
  children: React.ReactNode;
  EditorInstance: React.MutableRefObject<IJodit | undefined>;
}

function MentionHOC(props: MentionHOCProps): React.ReactElement {
  /**
   * The Component takes the following props :
   * @param loading Denote the loading state of mention members of workspace
   * @param members Denote the mention members to show and assign for the query
   * @state isSuggesting denotes if the component is suggesting currently or not
   * @state query denotes the query typed followed after @ by user
   * @state backspaceUpdate holds the backspace data before deletion using backspace key
   * @state marker sets the marker for the editor
   * @ref mentionboxRef is ref for the mentionbox
   * spaceCount to check if space is pressed twice
   */

  const [isSuggesting, setIsSuggesting] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [lookupStyles, setLookupStyles] = useState({});
  const [backspaceUpdate, setbackspaceUpdate] = React.useState<string>("");
  const [marker, setMarker] = React.useState<string | null>(null);
  const mentionBoxRef = React.useRef<HTMLUListElement>(null);
  const { loading, members } = props.mentionMembers;
  const spaceCount = React.useRef(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  /**
   * To Position the Mention List on the end of @ in the editor
   */

  const setupLookup = () => {
    const sel = window.getSelection();
    const parent = document.querySelector(".jodit-react-container");
    const parentRect = parent?.getClientRects() || [];
    const pRect1 = parentRect[0];

    // check if selection exists
    if (!sel?.rangeCount) return null;

    // get range
    const range = sel.getRangeAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO).cloneRange();
    if (!range.getClientRects) return null;

    // get client rect
    range.collapse(false);
    const rects = range.getClientRects();
    if (rects.length <= DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setLookupStyles({ position: "absolute", left: `26px`, top: `17px` });
      return null;
    }

    // return coord
    const rect = rects[0];
    if (rect.x > (pRect1.left + pRect1.right) / DEFAULT_NUMERIC_VALUES.DEFAULT_TWO) {
      setLookupStyles({ position: "absolute", right: `${pRect1.right - rect.x}px`, top: `${rect.y - pRect1.y}px` });
    } else {
      setLookupStyles({ position: "absolute", left: `${rect.x - pRect1.x}px`, top: `${rect.y - pRect1.y + DEFAULT_NUMERIC_VALUES.DEFAULT_FIVE}px` });
    }
  };

  /**
   * handle keyboard types other than character keys.
   * To Handle focus changes - To switch between list or editor
   * To Handle Backspace changes - Removing Mention on backspace ( Not Suggesting ) or Removing Query ( Suggesting )
   */

  const parseDownKeys = (e: React.KeyboardEvent) => {
    if (isSuggesting && e.key === "Escape") {
      setIsSuggesting(false);
      setQuery("");
    }
    if (isSuggesting && e.key === "ArrowDown") {
      mentionBoxRef.current?.focus?.();
    }
    if (isSuggesting && (e.key === "ArrowUp" || e.key === "ArrowLeft" || e.key === "ArrowRight")) {
      setIsSuggesting(false);
      setQuery("");
      props.EditorInstance.current?.selection?.focus?.();
    }
    if (!isSuggesting && e.key === "Backspace") {
      const changesArr = e.target?.innerHTML?.match(MENTION_REGEX_CONSTANTS.REGEX_GET_MENTION);
      const unchangedArr = backspaceUpdate?.match(MENTION_REGEX_CONSTANTS.REGEX_GET_MENTION);
      let changed: string | null = null;
      if (changesArr && unchangedArr && changesArr?.length === unchangedArr?.length) {
        changesArr.forEach((val: string, idx: number) => {
          if (val !== unchangedArr[idx]) {
            changed = val;
          }
        });
      }
      if (
        changed &&
        (document.getElementsByClassName("jodit-wysiwyg na-body-editor")[0] as HTMLDivElement) &&
        (document.getElementsByClassName("jodit-wysiwyg na-body-editor")[0] as HTMLDivElement).innerHTML
      ) {
        (document.getElementsByClassName("jodit-wysiwyg na-body-editor")[0] as HTMLDivElement).innerHTML = (
          document.getElementsByClassName("jodit-wysiwyg na-body-editor")[0] as HTMLDivElement
        ).innerHTML.replace(changed, `<span id="move-marker-here"></span>`);
        props.setEditorState((document.getElementsByClassName("jodit-wysiwyg na-body-editor")[0] as HTMLDivElement).innerHTML);
        const markerNode = document.getElementById("move-marker-here");
        if (markerNode !== null) {
          props.EditorInstance.current?.selection.setCursorAfter(markerNode);
        }
      }
      setbackspaceUpdate("");
    }
    if (isSuggesting && e.key === "Backspace") {
      if (query === "") {
        setIsSuggesting(false);
      } else {
        setQuery(query.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, query.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE));
      }
    }
  };

  const parseKeys = (e: React.KeyboardEvent) => {
    if (e.key === "@") {
      setIsSuggesting(true);
      setupLookup();
    }
    if (!(e.key === " " || e.code === "Space")) {
      spaceCount.current = 0;
    }
    if (isSuggesting) {
      setQuery(query + e.key);
    }
    if (isSuggesting && (e.key === " " || e.code === "Space")) {
      if (spaceCount.current == DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
        setIsSuggesting(false);
        setQuery("");
        spaceCount.current = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
      } else {
        spaceCount.current = DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
      }
    }
  };

  /**
   * Click handler to handle mention on select
   */

  const selectMention = (mention: MentionsDataItemType) => {
    let markerInst = marker;
    if (!markerInst) {
      markerInst =
        (document.getElementsByClassName("jodit-wysiwyg na-body-editor")[0] as HTMLDivElement).innerHTML
          .match(MENTION_REGEX_CONSTANTS.SELECT_METION_WITH_MARKER)?.[0]
          ?.replace(MENTION_REGEX_CONSTANTS.SELECT_MENTION_QUERY, "") ?? "";
      setMarker(markerInst);
    }
    const nState = (document.getElementsByClassName("jodit-wysiwyg na-body-editor")[0] as HTMLDivElement).innerHTML.replace(
      MENTION_REGEX_CONSTANTS.SELECT_METION_WITH_MARKER,
      `<span style="color:#2D9DE7;">@${mention.name}</span>&nbsp;${markerInst}`
    );
    if (nState) {
      (document.getElementsByClassName("jodit-wysiwyg na-body-editor")[0] as HTMLDivElement).innerHTML = nState;
    }
    setQuery("");
    setIsSuggesting(false);
    props.EditorInstance.current?.selection?.focus();
  };

  /**
   * To Check where the click is done, if the click is outside the mention box, the options are closed
   */

  const checkPlace = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).id !== "mention-list-items" && isSuggesting) {
      setIsSuggesting(false);
      setQuery("");
    }
  };

  /**
   * To Filter Out Members based on the Query typed
   */

  const setQueryFilter = (item: MentionsDataItemType, checker: string) => {
    if (checker === "") return true;
    if (
      (item.name && item.name?.indexOf(checker) !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE) ||
      (item.email_address && item.email_address?.indexOf(checker) !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE)
    )
      return true;
    return false;
  };

  const mentionsArr = members.filter((item) => setQueryFilter(item, query));

  /**
   * Keylogger for Jodit Editor For Handling Enter and Backspace Functions
   */

  React.useEffect(() => {
    props.EditorInstance.current?.events?.on?.("keydown", (e) => {
      if (e.key === "Enter") {
        setIsSuggesting(false);
        setQuery("");
      }
      if (e.key === "Backspace") {
        setbackspaceUpdate(e.target.innerHTML);
      }
    });
  }, [props.EditorInstance.current]);

  /**
   * Mention HOC component to wrap the Jodit Editor with the Mentions component
   * The Mentions component takes the mentions members from workspace and filters based on the typed query after @
   * The Component shows different members and on choosing , adds the corresponding member into the UI.
   */

  return (
    <div className="mention-hoc" onKeyPress={parseKeys} onKeyDown={parseDownKeys} onClick={checkPlace}>
      <>
        {loading && isSuggesting && (
          <ul id="mention-list-items" className="mentions" style={lookupStyles}>
            <li id="mention-list-items" className="mention-disabled" key="not-found">
              <div id="mention-list-items" className="user-pic">
                <Skeleton height={40} width={40} variant="circular" />
              </div>

              <div id="mention-list-items" className="mention-data-loading">
                <div id="mention-list-items" className="name">
                  <Skeleton height={16} width={100} variant="rectangular" />
                </div>
                <div id="mention-list-items" className="email">
                  <Skeleton height={16} width={60} variant="rectangular" />
                </div>
              </div>
            </li>
          </ul>
        )}
        {isSuggesting && !loading ? (
          <>
            {mentionsArr.length ? (
              <ul id="mention-list-items" className="mentions" style={lookupStyles} ref={mentionBoxRef}>
                {mentionsArr.map((item, key) => {
                  return (
                    <li id="mention-list-items" key={key} className="mention" onClick={() => selectMention(item)}>
                      <div id="mention-list-items" className="user-pic">
                        <UserPic />
                      </div>

                      <div id="mention-list-items" className="mention-data">
                        <span id="mention-list-items" className="name">
                          {item.name}
                        </span>
                        <span id="mention-list-items" className="email">
                          {item.email_address}
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul id="mention-list-items" className="mentions" style={lookupStyles}>
                <li id="mention-list-items" className="mention-disabled" key="not-found">
                  <div id="mention-list-items" className="user-pic">
                    <UserPic />
                  </div>

                  <div id="mention-list-items" className="mention-data">
                    <span id="mention-list-items" className="name">
                      No Members Found
                    </span>
                  </div>
                </li>
              </ul>
            )}
          </>
        ) : null}
        {props.children}
      </>
    </div>
  );
}

interface MentionHOCWrapperProps {
  editorState: string;
  setEditorState: Dispatch<SetStateAction<string>>;
  mentionMembers: MentionMembersType;
  children: React.ReactNode;
  EditorInstance: React.MutableRefObject<IJodit | undefined>;
  enableMentions: boolean;
}

export default function MentionHOCWrapper(props: MentionHOCWrapperProps): React.ReactElement {
  if (!props.enableMentions) {
    return <>{props.children}</>;
  }
  return (
    <MentionHOC
      editorState={props.editorState}
      setEditorState={props.setEditorState}
      mentionMembers={props.mentionMembers}
      EditorInstance={props.EditorInstance}
    >
      {props.children}
    </MentionHOC>
  );
}
