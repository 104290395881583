import APIClient from "../APIClient";

export class EnrollmentsClient {
  getEnrollments2(id: UUID | number): Promise<any> {
    const apiUrl = `inbox/api/v2/workspaces/${id}/channels`;
    return APIClient.get(apiUrl).then((res) => res.data);
  }

  deleteEnrollment(id: string, workspace_id: number, data: EmailConnectorRemoveModel): Promise<APIResponse> {
    const url = `inbox/api/v2/workspaces/${workspace_id}/channels/${id}`;
    const options = { data };
    return APIClient.delete(url, options).then((res) => res.data);
  }

  reconnectChannel(workspace_id: UUID | number, channel_id: UUID | number, body: AddConnector): Promise<APIResponse> {
    const url = `inbox/api/v2/workspaces/${workspace_id}/channels/${channel_id}/reconnect`;
    const apiBody = Object.assign(body, process.env.REACT_APP_ENV === "local" ? { local_testing: true } : {});
    return APIClient.patch(url, apiBody).then((res) => res.data);
  }

  createEnrollment(workspace_id: UUID | number, body: AddConnector) {
    const url = `inbox/api/v2/workspaces/${workspace_id}/channels`;
    const apiBody = Object.assign(body, process.env.REACT_APP_ENV === "local" ? { local_testing: true } : {});
    return APIClient.post(url, apiBody).then((res) => res.data);
  }
}
