import { Drawer, TextField } from "@mui/material";
import React from "react";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";
import Button from "../../../library/Button/Button";
import { CustomerMergeComponentProps } from "../../CustomerInterfaces/CustomerMergeInterface";
import "./CustomerMerge.scss";

export const CustomerMergeComponent = (props: CustomerMergeComponentProps) => {
  return (
    <Drawer className="customer-action-drawer" open={props.open} anchor="right" disablePortal onClose={() => props.onClose()}>
      <div className="flyout">
        <h3 className="header">Merge Connection</h3>
        <p className="subheader body1">The activity streams, contacts, and documents will be merged into another connection.</p>
        <div>
          <Autocomplete
            getOptionLabel={(option: Partial<CompanyModel>) => option.companyName ?? "N/A"}
            options={props.companyList}
            loading={props.companyListDropdownLoader}
            onChange={(_e, value: Partial<CompanyModel>) => {
              props.setSelectedCompany(value);
            }}
            renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: Partial<CompanyModel>) => (
              <li {...props} key={option.companyId}>
                {option.companyName}
              </li>
            )}
            blurOnSelect
            disableClearable
            disablePortal
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Choose A Connection",
                  disableUnderline: true,
                }}
              />
            )}
            isOptionEqualToValue={(option: Partial<CompanyModel>, value: string) => option.companyId === value}
          />
        </div>
        <div className="btn-grp">
          <Button size="lg" onClick={() => props.setOpenDialog(true)} loading={props.isLoading} disabled={props.disableMerge}>
            Merge
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
