import React, { useState, useContext, useRef, useEffect } from "react";
import { AppContext } from "./AppContext";
import { NUMERIC_TIMEOUTS } from "../constants/NumericConstants";
import { activitiesClientV2 } from "../db/version2Accessor";
import { FallbackTypes } from "../types/enums";
import { WorkspaceContext } from "./WorkspaceContext";

/**
 * @constant MetaContext
 * A context to poll and provide Meta information, which includes
 * the unread activity counts and its detailes
 */
export const MetaContext = React.createContext<MetaType | null>(null);
export interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const META_DETAILED_SCHEMA = {
  mine_count: {
    active: 0,
    waiting_for_response: 0,
    snoozed: 0,
    closed: 0,
    total: 0,
  },
  assigned_count: {
    total: 0,
  },
  unassigned_count: {
    active: 0,
    closed: 0,
    total: 0,
  },
  all_count: {
    active: 0,
    waiting_for_response: 0,
    snoozed: 0,
    closed: 0,
    total: 0,
  },
  spam_count: {
    total: 0,
  },
};

const MetaProvider: React.FC<Props> = ({ children }: Props) => {
  const { userStatus } = useContext(AppContext) as AppType;
  const { selectedWorkspace } = useContext(WorkspaceContext) as WorkspaceDataType;
  const pollingInterval = useRef<null | ReturnType<typeof setInterval>>(null);
  const [metaDetailed, setMetaDetailed] = useState<ActivityCountDetailed>({
    ...META_DETAILED_SCHEMA,
  });

  const getActivityCounts = async () => {
    if (!userStatus.user_id) {
      return;
    }
    const newActivityCount: any = {
      ...metaDetailed,
    };
    await activitiesClientV2.getActivityStreamMeta(selectedWorkspace?.id || FallbackTypes.Id).then((results: ActivityStreamMeta) => {
      newActivityCount.mine_count = results?.data?.count?.mine_count ?? metaDetailed.mine_count;
      newActivityCount.unassigned_count = results?.data?.count?.unassigned_count ?? metaDetailed.unassigned_count;
      newActivityCount.all_count = results?.data?.count?.all_count ?? metaDetailed.all_count;
      newActivityCount.spam_count = results?.data?.count?.spam_count ?? metaDetailed.spam_count;
      setMetaDetailed(newActivityCount);
    });
  };

  const clearTimer = () => {
    clearTimeout(pollingInterval.current as ReturnType<typeof setInterval>);
    pollingInterval.current = null;
  };

  const fetchCount = async () => {
    clearTimer();
    await getActivityCounts();
    if (!pollingInterval.current) {
      pollingInterval.current = setInterval(
        () => {
          getActivityCounts();
        },
        process.env.REACT_APP_ENV !== "local" ? NUMERIC_TIMEOUTS.TEN_SECOND : NUMERIC_TIMEOUTS.TEN_SECOND
      );
    }
  };

  useEffect(() => {
    fetchCount();
    return () => {
      clearTimer();
    };
  }, [userStatus.user_id, selectedWorkspace?.id]);

  return <MetaContext.Provider value={{ metaDetailed, clearTimer }}>{children}</MetaContext.Provider>;
};

export default MetaProvider;
