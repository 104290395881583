import { Skeleton } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { AR_HEADER_SKELETON_CONSTANTS } from "../../../constants/StyleConstants";
import { AppContext } from "../../../contexts/AppContext";
import Utils from "../../../utils/utils";
import "./ARHeader.scss";

type HeaderType = {
  type: string;
  customLabel?: string;
};

export interface Props {
  list: HeaderType[];
  reportDate: string;
}

export default function ARHeader(props: Props): React.ReactElement {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [ARHeader, setARHeader] = useState<ArHeaderInfoModel>({} as ArHeaderInfoModel);
  const { getARHeader } = React.useContext(AppContext) as AppType;
  const { userStatus } = React.useContext(AppContext) as AppType;

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(false);
      try {
        await getARHeader(props.reportDate).then((data) => {
          setARHeader(data);
        });
      } catch (error: any) {
        setError(true);
        console.log(error);
      }
      setLoading(false);
    })();
  }, [props.reportDate]);

  return (
    <div className="ARHeader-wrapper">
      {isLoading ? (
        props.list.map((_, index: number) => {
          return (
            <Skeleton
              key={`ARHeader-${index}`}
              variant="text"
              width={Utils.getConvertedSizeInRem(AR_HEADER_SKELETON_CONSTANTS.WIDTH)}
              height={Utils.getConvertedSizeInRem(AR_HEADER_SKELETON_CONSTANTS.HEIGHT)}
              animation="wave"
            />
          );
        })
      ) : isError ? (
        <span className="text-span">
          <span className="text-label">Oops! Something Went Wrong... </span>
        </span>
      ) : (
        props.list.map((item: HeaderType, index: number) => {
          let label = "";
          let value: string | number = "";
          switch (item.type) {
            case "reportPeriod":
              label = item.customLabel ?? "Report Period";
              value = ARHeader.reportPeriod ?? "N/A";
              break;
            case "totalCustomers":
              label = item.customLabel ?? "Customers";
              value = ARHeader?.totalCustomers ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalCustomersPaidPast30Days":
              label = item.customLabel ?? "Customers";
              value = ARHeader?.customersPaidPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalCustomersInvoicedPast30Days":
              label = item.customLabel ?? "Customers";
              value = ARHeader?.customersInvoicedPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalInvoices":
              label = item.customLabel ?? "Invoices";
              value = ARHeader?.totalInvoices ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalInvoicesPast30Days":
              label = item.customLabel ?? "Invoices";
              value = ARHeader?.invoicesPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalInvoicedAmountPast30Days":
              label = item.customLabel ?? "Amount Invoiced";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(ARHeader.amountInvoicedPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalCollectedPast30Days":
              label = item.customLabel ?? "Amount Collected";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(ARHeader.amountCollectedPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalInvoicedAmount":
              label = item.customLabel ?? "Amount Invoiced";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(ARHeader.totalInvoicedAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalUnappliedPayments":
              label = item.customLabel ?? "Total Unapplied Payment Amount";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(ARHeader.totalUnappliedPayments ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalUnappliedPaymentsPast30Days":
              label = item.customLabel ?? "Total Unapplied Payment Amount";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(ARHeader.unappliedAmountPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalCollected":
              label = item.customLabel ?? "Amount Collected";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(ARHeader.totalCollected ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalArAmount":
              label = item.customLabel ?? "Total Balance";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(ARHeader.totalArAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalInvoicesOpen":
              label = item.customLabel ?? "Invoiced";
              value = isNaN(ARHeader.totalInvoices - ARHeader.totalInvoicesPaid)
                ? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
                : ARHeader.totalInvoices - ARHeader.totalInvoicesPaid;
              break;
            case "totalInvoicesPaid":
              label = item.customLabel ?? "Invoices Paid";
              value = ARHeader?.totalInvoicesPaid ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalInvoicesPaidPast30Days":
              label = item.customLabel ?? "Invoices Paid";
              value = ARHeader?.invoicesPaidPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalInvoicesPastDue":
              label = item.customLabel ?? "Past Due Invoices";
              value = ARHeader?.totalInvoicesPastDue ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalInvoices90DaysPastDue":
              label = item.customLabel ?? "Past Due Invoices";
              value = ARHeader?.totalInvoices90DaysPastDue ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalPastDueAmount":
              label = item.customLabel ?? "Past Due Amount";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(ARHeader.totalPastDueAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalPastDueAmount90Days":
              label = item.customLabel ?? "Past Due Amount";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(ARHeader.totalPastDueAmount90Days ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "percentageOfTotalAr":
              label = item.customLabel ?? "Percentage of Total AR";
              value = `${ARHeader.percentageOfTotalAr ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}%`;
              break;
            case "percentageOfTotalAr90DaysPastDue":
              label = item.customLabel ?? "Percentage of Total AR";
              value = `${ARHeader.percentageOfTotalAr90DaysPastDue ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}%`;
              break;
            case "dso":
              label = item.customLabel ?? "Days Sales Outstanding";
              value = Utils.roundToPrecisionPoint(ARHeader?.dso, DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalInvoiceAmountCurrentYear":
              label = item.customLabel ?? `Total Invoiced in ${DateTime.now().toFormat("yyyy")}`;
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(ARHeader.totalInvoiceAmountCurrentYear ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalInvoiceAmountPreviousYear":
              label = item.customLabel ?? `Total Invoiced in ${DateTime.now().minus({ years: 1 }).toFormat("yyyy")}`;
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(ARHeader.totalInvoiceAmountPreviousYear ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            default:
              label = "INVALID TYPE";
              break;
          }
          return (
            <span className="text-span" key={`ARHeader-${index}`}>
              {value} <span className="text-label">{label}</span>
            </span>
          );
        })
      )}
    </div>
  );
}
