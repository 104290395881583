import AP from "./AP.json";
import AR from "./AR.json";

export interface GSConfigInterface {
  [key: string]: any;
}

export const globalSearchConfigGetter = (workspaceType: string) => {
  const globalSearchConfig: GSConfigInterface = Object.freeze({
    AP: AP,
    AR: AR,
  });

  return globalSearchConfig[workspaceType];
};
