import React, { ChangeEvent, FormEvent } from "react";
import { useHistory, useParams } from "react-router";
import _ from "underscore";
import { ApprovalActivityAccessor } from "../../../db/unauthAccessors";
import Utils from "../../../utils/utils";
import { Failure, Success } from "../../library/Icons/Icons";
import Loading from "../../library/Loading/Loading";
import { RedirectFormData } from "../ApprovalsInterfaces";
import Status from "../Status/Status";
import "./RedirectRequest.scss";
import RedirectRequestComponent from "./RedirectRequestComponent";
import { MagicAuthContext, MagicAuthContextType } from "../../../contexts/external/MagicAuthContext";
import { ApprovalDetailsContext, ApprovalsProviderType } from "../../../contexts/external/ApprovalDetailsContext";
// import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";

const RedirectRequestContainer = () => {
  const [formData, setFormData] = React.useState<RedirectFormData>({ email_address: "", name: "", note: "" });
  const [formError, setFormError] = React.useState<Partial<RedirectFormData>>({ email_address: "", name: "" });
  const [redirectStatus, setRedirectStatus] = React.useState<boolean>();
  const [validating, setValidating] = React.useState<boolean>(false);

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { getToken } = React.useContext(MagicAuthContext) as MagicAuthContextType;
  const { fetchRequestData } = React.useContext(ApprovalDetailsContext) as ApprovalsProviderType;

  // const countRef = React.useRef<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  const postRedirectRequest = async (id: UUID, data: RedirectFormData) => {
    let response = {} as APIResponse;
    try {
      response = await ApprovalActivityAccessor.redirectApprovalRequest(id, data);
    } catch (error) {
      response.success = false;
      console.error(error);
      history.push("/link-expired");
    } finally {
      setRedirectStatus(response.success);
    }
  };

  const validateFormData = () => {
    let errors = {} as Partial<RedirectFormData>;

    Object.keys(formData).forEach((key) => {
      if (key === "email_address") {
        _.isEmpty(formData.email_address)
          ? (errors = { ...errors, email_address: "NO EMAIL GIVEN" })
          : !Utils.validEmail(formData.email_address) && (errors = { ...errors, email_address: "INVALID EMAIL GIVEN" });
      }
      if (key === "name") {
        _.isEmpty(formData.name) && (errors = { ...errors, name: "NO NAME GIVEN" });
      }
      if (key === "note") {
        _.isEmpty(formData.note) && (errors = { ...errors, note: "NO NOTE GIVEN" });
      }
    });

    setFormError(errors);
    // valid if no errors are present
    return _.isEmpty(errors);
  };

  const onSubmitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    // make api call to submit redirect request.
    postRedirectRequest(id, formData);
  };

  const onChangeFormData = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validateFormData();
  };

  /**
   * Initial call for token to verify the validity of redirect magic link id.
   * any failure of this request will redirect the user to /link-expired view.
   *
   * Redirect link expires after the defined time-period in the database OR
   * when user has already redirected OR reviewed (approved/declined) the
   * respective approval request.
   */
  React.useEffect(() => {
    /**
     * This call is moved outside to fetch approval request data
     */

    // if (countRef.current > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
    (async function validateRedirectLink() {
      setValidating(true);
      await getToken(id);
      fetchRequestData(id);
      setValidating(false);
    })();
    // }
    // countRef.current += DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
  }, []);

  return validating ? (
    <div className="redirect-form-loader">
      <Loading isRelative={true} />
    </div>
  ) : redirectStatus === true ? (
    <Status size="sm" logo={<Success />} statusMessage={`Approval request has been redirected to ${formData.name}`} />
  ) : redirectStatus === false ? (
    <Status
      size="sm"
      logo={<Failure />}
      statusMessage={`Failed to redirect request to ${formData.name}`}
      subText={"Something went wrong while redirecting your request!"}
    />
  ) : (
    <RedirectRequestComponent
      onSubmitForm={onSubmitForm}
      onChangeFormData={onChangeFormData}
      onBlurValidateData={validateFormData}
      formErrors={formError}
    />
  );
};

export default RedirectRequestContainer;
