import React from "react";
import { OnboardingRequestedProps } from "./../ActivityItemVariantTypes";
import { Player } from "@lottiefiles/react-lottie-player";
import "./OnboardingRequested.scss";
import { formatDate } from "../../../../../../../db/utils/date";
import Confetti from "../../../../../../../assets/lotties/confetti.json";

const OnboardingRequested: React.FC<OnboardingRequestedProps> = ({ date }) => {
  return (
    <div className="boarding-initiated activity-feed-item-card">
      <div className="boarding-initiated-icon">
        <Player src={Confetti} autoplay style={{ width: "4.5rem", height: "4.5rem", margin: "auto" }} />
      </div>
      <div className="boarding-initiated-body">
        <h3 className="boarding-initiated-body-header">Nice Job!</h3>
        <p className="boarding-initiated-body-text">
          You requested a <span className="green-highlight">profile onboard</span> on {formatDate(date)}.
        </p>
      </div>
    </div>
  );
};

export default OnboardingRequested;
