export const BTN_SAVE = "Save";
export const BTN_ADD = "Add";
export const BTN_CANCEL = "Cancel";
export const BTN_SAVE_EDITS = "Save Edits";
export const BTN_CANCEL_EDIT = "Cancel Edit";
export const BTN_SEND = "Send";
export const BTN_SEND_AND_CLOSE = "Send & Close";
export const BTN_MOVE = "Move";
export const BTN_ASSIGN = "Assign";
export const BTN_RESEND = "Resend";
export const BTN_APPROVE = "Approve";
export const BTN_DECLINE = "Decline";
export const BTN_SEND_REMINDER = "Send Reminder";
export const BTN_RETRY_SENDING = "Retry Sending";
export const BTN_NOT_SPAM = "Not Spam";

export const LBL_BCC = "Bcc";
export const LBL_CC = "Cc";

export const LBL_TO = "To";
export const HEADING_ADD_NOTE = "Add a Note";
export const SUB_HEADING_ADD_NOTE = "This note will only be visible to team members within Lockstep.";

export const HEADING_ADD_PHONE_CALL = "Add a Phone Call";
export const SUB_HEADING_ADD_PHONE_CALL = "This phone call will only be visible to team members within Lockstep.";

export const HEADING_MOVE_ACTIVITY_STREAM = "Move Activity Stream";
export const SUB_HEADING_MOVE_ACTIVITY_STREAM = "Move this activity stream to another connection.";
export const LBL_CONNECTION = "Connection";

export const HEADING_CHANGE_ASSIGNMENT_OWNER = "Change Assignment Owner";
export const SUB_HEADING_CHANGE_ASSIGNMENT_OWNER = "Assign this assignment to another team member within Lockstep.";
export const LBL_TEAM_MEMBER = "Team Member";

export const HEADER_PHONE_CALL = "Phone Call";
export const HEADER_NOTE = "Note";
export const HEADER_ASSIGNED_ACTIVITY_STREAM = "Assigned Activity Stream";
export const HEADER_UNASSIGNED_ACTIVITY_STREAM = "Unassigned Activity Stream";
export const HEADER_ACTIVITY_CLOSE = "Closed Activity Stream";
export const HEADER_MARKED_AS_SPAM = "Marked as Spam";
export const HEADER_FORWARDED = "Forwarded";
export const HEADER_MOVED_NEW_CONNECTION = "Moved to New Connection";
export const HEADER_SNOOZED = "Snoozed";
export const HEADER_UNSNOOZED_STREAM = "Unsnoozed activity stream";
export const HEADER_SPAM_SENDER = "Marked sender as spam";
export const HEADER_UNSPAM_SENDER = "Removed sender as spam";
export const HEADER_FRAUD_SENDER = "Marked sender as fraud";
export const HEADER_UNFRAUD_SENDER = "Removed sender as unfraud";
export const HEADER_MERGED_CONNECTION = "Merged Connection";
export const HEADER_APPROVAL_REQUEST = "Approval Request";
export const HEADER_FORWARD_AP_AUTOMATION = "Mail had been forwarded to AP automation tool";
export const HEADER_APPROVAL_REQ_REDIRECT = "Approval request is redirected";
export const HEADER_MOVED_STREAM = "Moved activity stream";
export const HEADER_REMOVED_MARKED_NOT_SPAM = "Removed from spam";
export const HEADER_EMAIL = "Email";
export const HEADER_NUDGE = "Nudge";
export const LBL_REPLAY_ALL = "Reply All";
export const LBL_FORWARD = "Forward";
export const LBL_VIEW_ORIGINAL = "View Original";
export const BTN_RETRY_MESSAGE = "Retry This Message";
export const BTN_UNSNOOZE = "Unsnooze";
export const LBL_MESSAGE_NOT_DELIVERED = "Message Not Delivered";
export const LBL_MESSAGE_NOT_DELIVERED_MSG = "There was a problem delivering your message to";
export const BTN_NUDGE_AGAIN = "Nudge Again";
export const BTN_SEE_FORWARDED_STREAM = "See Forwarded Stream";
export const BTN_SEE_ORIGINAL_STREAM = "See Original Stream";
// export const BTN_READ_MORE = "Read More";
// export const BTN_READ_LESS = "Read Less";

export const BTN_REMOVE = "Remove";
export const BTN_CLOSE = "CLOSE";
export const BTN_CHANGE_OWNER = "Change Owner";
export const LBL_CHANGE_OWNERSHIP = "Change Ownership";
export const BTN_REMIND = "Remind";
export const BTN_DELETE = "Delete";
export const BTN_INVITE_MEMBERS = "Invite Members";
export const BTN_SEND_INVITE = "Send Invite";
export const BTN_INVITE = "Invite";
export const BTN_EDIT = "Edit";
export const BTN_UPDATE = "Update";
export const BTN_CONNECT_EMAIL = "Connect Email";

export const SNIPPET_INFO =
  "These templates consist of snippets and brackets, e.g., '{{emailAddress}}'. If you modify these snippets or brackets, the system won't be able to recognize the command and autofill the appropriate content. Please do not modify them.";
export const MAGIC_LINK_SNIPPET = "{Magic Link Call to Action Button}";
