import React, { useState, useEffect } from "react";
import Loading from "../../../library/Loading/Loading";
import Error from "../../../library/Error/Error";
import { IconButton, Tooltip } from "@mui/material";
import { TooltipIcon } from "../../../library/Icons/Icons";
import { DashboardContext } from "../../../../contexts/DashboardContext";
import DonutChart from "../../../library/Charts/DonutChart";
import NoData from "../../../library/NoData/NoData";
import { getCurrencySuffix, shortenCurrency } from "../../../../db/utils/currency";
import { useRouteMatch, useHistory } from "react-router-dom";
import "./Aging.scss";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { InvoiceStatusAging, WorkspaceType } from "../../../../types/enums";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { ComponentConfigContext } from "../../../../contexts/ComponentConfigContext";
import { DRILLDOWN_NAVIGATION_CONSTANTS } from "../../../../constants/NavigationConstants";
import { AppContext } from "../../../../contexts/AppContext";

const marginTop = 4;
const marginRight = 4;
const marginBottom = 4;
const marginLeft = 4;

/**
 * * Defines the Aging Widget
 */

interface AgingInterface {
  disableInfoTooltip?: boolean;
  configs: any;
}

interface NumberObject {
  [key: string]: number;
}

interface StringObject {
  [key: string]: string;
}

export default function Aging({ configs, disableInfoTooltip }: AgingInterface): React.ReactElement {
  // Get GET method of Aging Report from Dashboard Context
  const { getArAgingData, getApAgingData } = React.useContext(DashboardContext) as DashboardType;
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;

  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const config = getConfig(selectedWorkspace?.workspace_type_route ?? WorkspaceType.AR)["dashboard"]["drilldowns"]["agingModule"];

  // Loading State of API Call to GET Aging Data
  const [isLoading, setLoading] = useState<boolean>(false);
  // Error Message of API Call to GET Aging Data
  const [errorMessage, setErrorMessage] = useState<string>("");
  // Aging Data from API Call to GET Aging Data
  const [data, setData] = useState<GraphData[]>([]);
  const [reportValueMap, setReportValueMap] = useState<NumberObject>({});
  const [reportIdMap, setReportIdMap] = useState<StringObject>({});
  const [colorIdMap, setColorIdMap] = useState<StringObject>({});
  // Total amount outstanding from Aging Data
  const [totalAmount, setTotalAmount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const history = useHistory();
  const { path } = useRouteMatch();
  const InvoiceStatusAgingEnum = React.useRef(InvoiceStatusAging as StringObject);
  // GET on /api/v1/Aging
  const fetchData = async () => {
    // Default error message
    setErrorMessage("");
    // API call loading...
    setLoading(true);
    try {
      // On success, set data
      const res = selectedWorkspace.workspace_type_route === WorkspaceType.AR ? await getArAgingData() : await getApAgingData();
      return res ?? [];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error.message);
      setErrorMessage("Error Loading Data");
    }
  };

  const handleOnClick = (id: string) => {
    if (!configs.isWidgetClickable) {
      return null;
    }
    let route;
    switch (id) {
      case "FUTURE DUE":
      case InvoiceStatusAgingEnum.current["FUTURE DUE"]:
        route = "/future_due";
        break;
      case "LATE 1-30 DAYS":
      case InvoiceStatusAgingEnum.current["LATE 1-30 DAYS"]:
        route = "/late_1_30";
        break;
      case "LATE 31-60 DAYS":
      case InvoiceStatusAgingEnum.current["LATE 31-60 DAYS"]:
        route = "/late_31_60";
        break;
      case "LATE 61-90 DAYS":
      case InvoiceStatusAgingEnum.current["LATE 61-90 DAYS"]:
        route = "/late_61_90";
        break;
      default:
        route = "";
        break;
    }
    history.push(`${path}/aging${route}?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.AGING.SORT}`);
  };

  const handleResponse = (response: GraphData[]) => {
    if (response) {
      const resValMap: NumberObject = {};
      const resIdMap: StringObject = {};
      const resClrMap: StringObject = {};
      if (response) {
        response.forEach((report) => {
          resValMap[report?.id?.toUpperCase()] = report.value;
          resIdMap[`${report?.id?.toUpperCase()}_ID`] = report.id;
          resClrMap[report?.id?.toUpperCase()] = report.color;
        });
      }
      setReportValueMap(resValMap);
      setReportIdMap(resIdMap);
      setColorIdMap(resClrMap);
      setData(response ?? []);
      setTotalAmount(
        response
          ? response.reduce((a: number, b: GraphData) => a + b.value, DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
          : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
      );
      setData(response ?? []);
    }
  };

  // On mount, fetch Aging data,  Also on Unmount set cancelInitialFetch to false to not setData as this is a stale call which was made before switching workspaces.
  // It basically blocks the API call made for one workspace when we have already switched to another
  useEffect(() => {
    let cancelled = false;
    fetchData().then((response) => {
      if (!cancelled) {
        handleResponse(response ?? []);
        // API call finalized
        setLoading(false);
      }
    });
    return () => {
      cancelled = true;
    };
  }, [selectedWorkspace]);

  return isLoading ? (
    <div className={`aging-wrapper`}>
      <Loading />
    </div>
  ) : errorMessage ? (
    <div className={`aging-wrapper`}>
      <Error>{errorMessage}</Error>
    </div>
  ) : (
    <div className={`aging-wrapper`}>
      <div className={`aging-head`}>
        <p className={`aging-title ${!configs.isWidgetClickable ? "non-clickable" : "clickable"}`} onClick={() => handleOnClick("")}>
          <span className="pretext">Aging Report </span>Current {configs.selectedAging}
        </p>
        {!disableInfoTooltip && (
          <Tooltip title={config.widgetTooltip} placement="right" arrow disableInteractive>
            <IconButton size="small">
              <TooltipIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {data?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
        <NoData />
      ) : (
        <div className={`aging-body`}>
          <div className="donut-chart">
            <div className={`aging-pie-wrapper ${!configs.isWidgetClickable ? "non-clickable" : "clickable"}`}>
              <DonutChart
                hoverSquare={false}
                graphData={data
                  ?.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
                  ?.reverse?.()
                  ?.filter?.((d) => Object.keys(InvoiceStatusAging).includes(d.id))
                  ?.map?.((d) => ({ ...d, id: InvoiceStatusAgingEnum.current[d.id] }))}
                graphColors={data
                  ?.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
                  ?.reverse?.()
                  .map((d) => d.color)}
                marginTop={marginTop}
                marginRight={marginRight}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                handleClick={(id) => {
                  handleOnClick(id);
                }}
                currency={userStatus?.currency}
              />
            </div>
          </div>
          <div className="aging-details">
            <div className="total">
              <p className="amount">{shortenCurrency(totalAmount, true)}</p>
              {Number(shortenCurrency(totalAmount)) !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
                <div className="currency-suffix">
                  <p className="suffix-title">Total Due</p>
                  <p className="h4">
                    ({userStatus?.currency?.symbol ?? "$"}
                    {getCurrencySuffix(totalAmount)})
                  </p>
                </div>
              )}
            </div>
            <div className="breakdown">
              {Object.keys(InvoiceStatusAging).map((status) => (
                <div
                  key={status}
                  className={`breakdown-item ${!configs.isWidgetClickable ? "non-clickable" : "clickable"}`}
                  onClick={() => {
                    handleOnClick(reportIdMap[`${status}_ID`]);
                  }}
                >
                  <div className="status-container">
                    <div className="status-indicator" style={{ background: colorIdMap[status] }}>
                      &nbsp;
                    </div>
                    <p className="late-status">{InvoiceStatusAgingEnum.current[status]}</p>
                  </div>
                  <p className="late-amount">
                    {shortenCurrency(reportValueMap[status], true)} ({userStatus?.currency?.symbol ?? "$"}
                    {getCurrencySuffix(reportValueMap[status])})
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* 
          For Referrence of color identification in future implementation
          <Legend
            className="aging-legend"
            data={data.map((val: GraphData) => {
              return {
                color: val.color,
                id: val.id,
                value: val.value,
                onClick: handleOnClick,
              };
            })}
          /> */}
        </div>
      )}
    </div>
  );
}
