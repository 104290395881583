/* eslint-disable no-magic-numbers */
import React, { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { CUSTOM_BARCHART_CONSTANTS, STYLED_CONSTANTS } from "../../../constants/StyleConstants";
import Utils from "../../../utils/utils";

// Define BarChart Props
interface Props {
  hoverSquare: boolean;
  data: GraphData[];
  margin?: { top: number; right: number; bottom: number; left: number };
  hoverFormat?: "currency" | "days" | "percent";
  padding?: number;
  onClick: (id: string) => void;
  titleAlign?: "start" | "middle" | "end";
  currency?: { code: string; symbol: string; locale: string };
}

interface monthHash {
  [month: string]: number;
}

const marginCalc: monthHash = {
  JAN: 34.5,
  FEB: 34,
  MAR: 37,
  APR: 35,
  MAY: 36,
  JUN: 37,
  JUL: 34,
  AUG: 36,
  SEP: 37,
  OCT: 36,
  NOV: 37,
  DEC: 35,
  COLLECTED: 75,
  INVOICED: 64,
  BILLED: 55,
  PAID: 36,
  "DUE NEXT 30 DAYS": 110,
  "DUE NEXT 14 DAYS": 110,
  "DUE NEXT 7 DAYS": 100,
  CURRENT: 65,
  PRIOR: 55,
  LAST: 36,
};

/**
 * * Calculate left margin of Bar Graph based on @param id month values
 * @param data - Bar Graph Data object array
 *    @param id - Represents id for a specific bar of the bar graph
 *    @param color - Represents the color for the specific bar @param id
 *    @param value - Represents the value for the specific bar @param id
 * @returns Numeric value representing the left margin of the Bar Graph
 */

export function calculateLeftMargin(data: GraphData[]): number {
  let max = 0;
  data.forEach((val) => {
    max = Math.max(max, marginCalc[val.id]);
  });
  return max;
}

// Define Wrapper for Nivo's Responsive Bar
export default function BarChart(props: Props): React.ReactElement {
  const [borderRadius, setBorderRadius] = useState(parseFloat(Utils.getPixelByWindowWidth(STYLED_CONSTANTS.BORDER_RADIUS, true)));
  const [data, setData] = useState<GraphData[]>([]);

  const getCharDim = () => ({
    fontSize: parseFloat(Utils.getPixelByWindowWidth(10, true)),
    margin: {
      top: typeof props?.margin?.top === "number" ? parseFloat(Utils.getPixelByWindowWidth(props?.margin?.top, true)) : 0,
      right:
        typeof props?.margin?.right === "number"
          ? parseFloat(Utils.getPixelByWindowWidth(props?.margin?.right, true))
          : parseFloat(Utils.getPixelByWindowWidth(16, true)),
      bottom:
        typeof props?.margin?.bottom === "number"
          ? parseFloat(Utils.getPixelByWindowHeight(props?.margin?.bottom, true))
          : parseFloat(Utils.getPixelByWindowHeight(50, true)),
      left:
        typeof props?.margin?.left === "number"
          ? parseFloat(Utils.getPixelByWindowWidth(props?.margin?.left, true))
          : parseFloat(Utils.getPixelByWindowWidth(95, true)),
    },
    axisLeft: {
      tickPadding: props?.padding
        ? parseFloat(Utils.getPixelByWindowWidth(props?.padding, true))
        : parseFloat(Utils.getPixelByWindowWidth(CUSTOM_BARCHART_CONSTANTS.LEFT_AXIS_TICK_PADDING, true)),
    },
  });
  const [chartDimensions, setChartDimensions] = React.useState(getCharDim());

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isZero, setZero] = useState<boolean[]>(new Array(props.data.length).fill(false));

  function handleResize() {
    if (window.innerHeight <= NUMERIC_VALUES.CONSTANT_THOUSAND) setBorderRadius(STYLED_CONSTANTS.BORDER_RADIUS);
    else setBorderRadius(CUSTOM_BARCHART_CONSTANTS.BORDER_RADIUS);

    setChartDimensions(getCharDim());
    setBorderRadius(parseFloat(Utils.getPixelByWindowWidth(STYLED_CONSTANTS.BORDER_RADIUS, true)));
  }

  useEffect(() => {
    // Set Border Radius on resize
    window.addEventListener("resize", handleResize);
    // Set Graph Data
    const min = props.data.every((val: GraphData) => val.value === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
      ? NUMERIC_VALUES.CONSTANT_HUNDRED
      : props.data.reduce((a: number, b: GraphData) => {
          if (b.value === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) return a;
          else return Math.min(a, b.value);
        }, Number.MAX_SAFE_INTEGER);
    setData(
      props.data.map((val, index: number) => {
        if (val.value === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
          setZero((prev) => {
            prev[index] = true;
            return prev;
          });
        }
        return {
          ...val,
          value: val.value === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? min / NUMERIC_VALUES.CONSTANT_FIFTY : val.value,
        };
      })
    );
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ResponsiveBar
      data={data}
      theme={{ fontSize: chartDimensions.fontSize, fontFamily: "Work Sans" }}
      keys={["value"]}
      groupMode="grouped"
      margin={chartDimensions.margin}
      padding={0.3}
      borderRadius={borderRadius}
      layout="horizontal"
      colors={{ datum: "data.color" }}
      axisLeft={{
        tickSize: 0,
        tickPadding: chartDimensions.axisLeft.tickPadding,
      }}
      axisBottom={null}
      enableLabel={false}
      enableGridY={false}
      enableGridX={false}
      // @ts-expect-error: Change later
      onClick={(ev: unknown) => props.onClick(ev.indexValue)}
      motionConfig="slow"
      maxValue={isZero.every((val: boolean) => val === true) ? CUSTOM_BARCHART_CONSTANTS.RESPONSIVE_BAR_MAX_VAL : "auto"}
      tooltip={(input) => {
        return (
          <div className={`hover-wrapper`}>
            {props.hoverSquare && <div className={`hover-square`} style={{ backgroundColor: input.color ?? "black" }} />}
            <p className={`hover-text`}>
              {input.indexValue}:{" "}
              {props.hoverFormat === "currency" ? (
                <span>
                  {new Intl.NumberFormat(props.currency?.locale ?? "en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: props.currency?.code ?? "USD",
                  }).format(isZero[input.index] ? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO : input.value)}
                </span>
              ) : props.hoverFormat === "days" ? (
                <span>{isZero[input.index] ? "0" : input.value} Days</span>
              ) : props.hoverFormat === "percent" ? (
                <span>{isZero[input.index] ? "0" : input.value.toFixed(NUMERIC_VALUES.CONSTANT_TWO)}%</span>
              ) : (
                <span>{isZero[input.index] ? "0" : input.value}</span>
              )}
            </p>
          </div>
        );
      }}
    />
  );
}
