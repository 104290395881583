import React, { useState } from "react";
import { msalInstance } from "..";
import { leadsClient, reportsClient, syncClient, userRolesClient } from "../db/accessor";
import { StatusClientV2 } from "../db/version2Accessor";
import { appInsights } from "../AppInsights";
import { UserRoles, UserActions } from "../types/enums";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../constants/NumericConstants";
import { WorkspaceContext } from "./WorkspaceContext";
import { contactsClientV2 } from "../db/version2Accessor";
import _ from "underscore";
import { globalSearchRemoveStorage } from "../components/GlobalSearch/Utils/LocalStorageUtils";

export const AppContext = React.createContext<AppType | null>(null);

export interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const AppProvider: React.FC<Props> = ({ children }: Props) => {
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const [selectedERP, setSelectedERP] = useState<ERPModel | null>(null);
  const [syncError, setSyncError] = useState<boolean>(false);
  const [erpConnectionError, setERPConnectionError] = useState<boolean>(false);
  const [initialSyncInProgress, setInitialSyncInProgress] = useState<boolean>(true);
  const [allContactOptions, setAllContactOptions] = useState<To[]>([]);
  const [userStatus, setUserStatus] = useState<StatusModelV2>({
    user_name: "",
    account_name: "",
    account_company_id: "",
    user_id: "",
    group_key: "",
    logged_in: false,
    roles: [],
    user_status: "",
    environment: "",
    version: "",
    onboarding_scheduled: false,
    dependencies: null,
    user_groups: [],
    account_id: "",
    account_type: "",
    email: "",
    erp_type: "",
    user_title: "",
    default_workspace: null,
    currency: { code: "USD", symbol: "$", locale: "en-US" },
    ap_automation_email: "",
  });
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [userRoles, setUserRoles] = useState<UserRoleModel[]>([]);
  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroupModel>({} as UserGroupModel);

  const permissionMap = new Map();
  permissionMap.set(UserActions.AddNote, [UserRoles.GroupOwner, UserRoles.GroupAdmin, UserRoles.Member]);
  permissionMap.set(UserActions.InvitationsCheckbox, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.InvitationsRemind, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.InvitationsRemove, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.InviteMembers, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.InvitationsChangeRole, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.RemovedMembersInvite, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.RemovedMembersCheckbox, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.ActiveMembersCheckbox, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.ActiveMembersRemove, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.ChangeRoles, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.EditMyProfile, [UserRoles.GroupOwner, UserRoles.GroupAdmin, UserRoles.Member]);
  permissionMap.set(UserActions.EditMyCompany, [UserRoles.GroupOwner, UserRoles.GroupAdmin, UserRoles.Member]);
  permissionMap.set(UserActions.AddEmailClient, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.EditAccountingSoftware, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.RunSync, [UserRoles.Member, UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.EditWorkspace, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);

  async function getARHeader(reportDate: string, companyId?: string): Promise<ArHeaderInfoModel> {
    return reportsClient.getARHeader(reportDate, companyId).then((data: ArHeaderInfoModel) => {
      return data;
    });
  }

  async function getAPHeader(reportDate: string, companyId?: string): Promise<ApHeaderInfoModel> {
    return reportsClient.getAPHeader(reportDate, companyId).then((data: ApHeaderInfoModel) => {
      return data;
    });
  }

  async function getDPOHeader(reportDate: string, companyId?: string): Promise<ApDPOInfoHeader> {
    return reportsClient.getDPOHeader(reportDate, companyId).then((data: ApDPOInfoHeader) => {
      return data;
    });
  }

  async function getDocumentsHeader(companyId?: string): Promise<AttachmentHeaderInfoModel> {
    return reportsClient.getAttachmentsHeader(companyId).then((data: AttachmentHeaderInfoModel) => {
      return data;
    });
  }

  // GET on /api/v1/Reports/ar-aging-header
  async function getArAgingHeader(): Promise<ArAgingHeaderInfoModel[]> {
    return reportsClient.getArAging(selectedWorkspace.workspace_type_route as string).then((data: ArAgingHeaderInfoModel[]) => {
      // 91+ -> ... -> Future Due
      data.sort((left: ArAgingHeaderInfoModel, right: ArAgingHeaderInfoModel) =>
        (left?.reportBucket ?? "") < (right?.reportBucket ?? "") ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
      );
      return data;
    });
  }

  // GET on /api/v1/Reports/ap-aging-header
  async function getApAgingHeader(): Promise<ApAgingHeaderInfoModel[]> {
    return reportsClient.getApAging(selectedWorkspace.workspace_type_route as string).then((data: ApAgingHeaderInfoModel[]) => {
      // 91+ -> ... -> Future Due
      data.sort((left: ApAgingHeaderInfoModel, right: ApAgingHeaderInfoModel) =>
        (left?.reportBucket ?? "") < (right?.reportBucket ?? "") ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
      );
      return data;
    });
  }

  // Clears the user's session and redirects back to login
  function logout(): void {
    msalInstance.logoutRedirect();
    appInsights?.clearAuthenticatedUserContext();
    sessionStorage.removeItem("selectedWorkspaceId");
    globalSearchRemoveStorage();
  }

  function getTokenFromQueryString(): string | null {
    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has("id_token")) {
      return null;
    } else {
      return String(urlParams.get("id_token"));
    }
  }

  /**
   * @function getStatus
   * A helper function to fetch the status information of logged in user from v2 API
   * @returns Promise<StatusModelV2>
   */
  async function getStatus(): Promise<StatusModelV2> {
    const response = await StatusClientV2.getStatusV2().then((res: APIResponse) => {
      window.localStorage.setItem("groupKey", res?.data?.group_key);

      setUserStatus((res?.data ? { ...userStatus, ...res?.data } : res?.data) as StatusModelV2);
      return res?.data;
    });
    await userRolesClient
      .query()
      .then((data: UserRoleModelFetchResult) => {
        setUserRoles(
          data?.records?.sort((a: UserRoleModel, b: UserRoleModel) =>
            a.userRoleId < b.userRoleId ? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE : DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
          ) ?? []
        );
      })
      .catch(() => {
        setUserRoles([]);
      });
    setInitialLoad(false);
    return response;
  }

  /**
   * A helper function to fetch user status and update the state.
   * @returns Promise<StatusModelV2>
   */
  async function updateUserStatus(): Promise<StatusModelV2> {
    const response = await StatusClientV2.getStatusV2().then((res: APIResponse) => {
      setUserStatus(res?.data as StatusModelV2);
      return res?.data;
    });
    return response;
  }

  function hasPermission(action: string) {
    if (action) {
      return permissionMap.get(action).some((role: string) => userStatus.roles?.includes(role));
    } else {
      return false;
    }
  }

  async function createLead(leadData: LeadsModel[]): Promise<LeadsModel[]> {
    return leadsClient.createLead(leadData);
  }

  async function getSyncStatus(syncRequestId: string, includeDetails?: boolean): Promise<SyncRequestModel> {
    return syncClient.getSyncStatus(syncRequestId, includeDetails);
  }

  async function fetchContacts(customerId?: string) {
    let filter = "qa[is_active_eq]=true";
    if (customerId) {
      filter = `${filter}&qa[company_id_eq]=${customerId}`;
    }
    return contactsClientV2.getContacts(
      selectedWorkspace.id,
      filter,
      undefined,
      NUMERIC_VALUES.CONSTANT_FIVE_THOUSAND,
      DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
    );
  }

  /**
   * This function fetches and stores all contact options or company contact option depending
   * upon whether customer Id is passed or not and sets it in the context so we can use it across the app
   *
   * @param {string} customerId if available
   * @returns contact options
   */
  async function getContactsOptions(customerId = ""): Promise<To[]> {
    const response: ContactModelFetchResultV2 = await fetchContacts(customerId);
    if (!response) {
      return [];
    }
    const filteredContacts = response?.records
      ?.filter((record) => !_.isEmpty(record?.email_address))
      .map((contact: ContactItem) => ({
        id: contact?.contact_name ? contact.email_address : "",
        label: contact?.contact_name || contact.email_address,
        primary: contact?.is_primary,
        connectionId: contact?.company_id,
        erpContact: !_.isNull(contact?.app_enrollment_id),
        companyId: contact?.company_id
      })) as To[];
    !customerId &&
      setAllContactOptions(!_.isEmpty(filteredContacts) ? filteredContacts.filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i) : []);
    return filteredContacts.filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i);
  }

  /**
   * This function takes company contacts and filters out the primary contact from it
   * @param {To[]} companyContacts list of company contacts
   * @returns filtered Primary contact only
   */
  function filterPrimaryContacts(companyContacts: To[]): To[] {
    let filteredContacts = {} as To[];
    filteredContacts = companyContacts.filter((item) => item.primary === true);
    return filteredContacts;
  }

  return (
    <AppContext.Provider
      value={{
        logout,
        getTokenFromQueryString,
        getStatus,
        createLead,
        getSyncStatus,
        setSelectedERP,
        setInitialSyncInProgress,
        selectedERP,
        initialSyncInProgress,
        getARHeader,
        getAPHeader,
        getDPOHeader,
        getDocumentsHeader,
        getArAgingHeader,
        getApAgingHeader,
        erpConnectionError,
        setERPConnectionError,
        syncError,
        setSyncError,
        userStatus,
        hasPermission,
        initialLoad,
        userRoles,
        fetchContacts,
        getContactsOptions,
        allContactOptions,
        filterPrimaryContacts,
        selectedUserGroup,
        setSelectedUserGroup,
        updateUserStatus,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
