import { Tooltip } from "@mui/material";
import React from "react";
import _ from "underscore";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../../../../constants/NumericConstants";
import Utils from "../../../../../../../../utils/utils";
import { AutomateActivity } from "../../../../../../../library/Icons/Icons";
import { TimeSavingIndicatorProps } from "../HelperTypes";

const TimeSavingIndicator: React.FC<TimeSavingIndicatorProps> = ({ timeSaving }) => {
  const enableTimeSaving =
    !_.isUndefined(timeSaving) &&
    !_.isUndefined(timeSaving.to_time) &&
    !_.isUndefined(timeSaving.from_time) &&
    timeSaving.to_time > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
    timeSaving.from_time > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;

  return enableTimeSaving ? (
    <div className="automate-time-wrapper">
      <Tooltip title="Email automatically composed by Lockstep Inbox" placement="top" componentsProps={{ tooltip: { sx: { maxWidth: "none" } } }}>
        <div>
          <AutomateActivity />
        </div>
      </Tooltip>

      <p className="time-text-wrapper">{`Time Savings:  ${Utils.formatTimeInMinutesAndSeconds(
        timeSaving?.from_time ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
        timeSaving?.to_time ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
      )}`}</p>
    </div>
  ) : (
    <></>
  );
};

export default TimeSavingIndicator;
