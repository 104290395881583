import React from "react";
import { DashboardLabels, InsightsCarouselContainerInterface } from "./InsightsInterface/InsightsInterface";
import InsightsCarouselComponent from "./InsightsCarouselComponent";
import Utils from "../../../utils/utils";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { FallbackTypes, WorkspaceType } from "../../../types/enums";
import { dashboardClientV2 } from "../../../db/version2Accessor";
import { AppContext } from "../../../contexts/AppContext";

const InsightsCarouselContainer: React.FC<InsightsCarouselContainerInterface> = (props: InsightsCarouselContainerInterface) => {
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { userStatus } = React.useContext(AppContext) as AppType;
  const [loading, setLoading] = React.useState(false);
  const [summary, setSummary] = React.useState({});
  const errorMsg = React.useRef(props.configs.insights_error_message);

  const fetchInsightDetails = (
    refinedData = {
      AccountSummary: { enable: true, header: "", subheader: "", body: [] as Array<{ item_key: string; item_value: string }> },
      ActivitySummary: { enable: true, header: "", subheader: "", body: [] as Array<{ item_key: string; item_value: string }> },
      TxnSummary: { enable: false, header: "", subheader: "", body: [] as Array<{ item_key: string; item_value: string }> },
    }
  ) => {
    dashboardClientV2
      .dashboardSummary(selectedWorkspace?.id || FallbackTypes.Id)
      .then((response) => {
        const json_data: { [key: string]: any } = response?.data ?? {};
        const period = Utils.capitalize(json_data?.["period"]).toLowerCase();

        const responseKeys = Object.keys(json_data);
        if (responseKeys?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
          responseKeys.forEach((key) => {
            if (key === "period") {
              // Getting Slide Headers
              refinedData.AccountSummary.header = props.configs.account_summary.header[period];
              refinedData.TxnSummary.header = props.configs.account_summary.header[period];
              refinedData.ActivitySummary.header = props.configs.activity_summary.header[period];
            } else if (key === props.configs.account_summary_type && json_data?.[key] instanceof Object) {
              refinedData.AccountSummary.subheader = props.configs.account_summary.subheader[period];
              // Getting AR summary stats
              Object.keys(json_data?.[key]).forEach((ar_keys) => {
                if (props.configs.account_summary.dataLabels.includes(ar_keys)) {
                  refinedData.AccountSummary.body = [
                    ...refinedData.AccountSummary.body,
                    {
                      item_key: (props.configs.dashboardInsightsLabels as DashboardLabels)[ar_keys],
                      item_value: `${userStatus?.currency?.symbol ?? json_data?.[key]?.currency ?? "$"}${
                        json_data?.[key]?.[ar_keys]?.formatted ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
                      } (${json_data?.[key]?.[ar_keys]?.percent ? `${json_data?.[key]?.[ar_keys]?.percent}%` : "N/A"})`,
                    },
                  ];
                }
              });
            } else if (key === props.configs.activity_summary_type && json_data?.[key] instanceof Object) {
              // Getting Activity summary stats

              Object.keys(json_data?.[key]).forEach((ar_keys) => {
                if (ar_keys === "total") {
                  refinedData.ActivitySummary.subheader = `${json_data?.[key]?.[ar_keys]?.count ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO} ${
                    props.configs.activity_summary.subheader[period]
                  }`;
                } else {
                  if (props.configs.activity_summary.dataLabels.includes(ar_keys)) {
                    refinedData.ActivitySummary.body = [
                      ...refinedData.ActivitySummary.body,
                      {
                        item_key: (props.configs.dashboardInsightsLabels as DashboardLabels)[ar_keys],
                        item_value: `${json_data?.[key]?.[ar_keys]?.count ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO} (${
                          json_data?.[key]?.[ar_keys]?.percent ? `${json_data?.[key]?.[ar_keys]?.percent}%` : "N/A"
                        })`,
                      },
                    ];
                  }
                }
              });
            } else if (
              selectedWorkspace?.workspace_type_route === WorkspaceType.AP &&
              key === props.configs.transaction_summary_type &&
              json_data?.[key] instanceof Object
            ) {
              refinedData.TxnSummary.enable = true;
              refinedData.TxnSummary.subheader =
                json_data[key]?.txn_count !== null && json_data[key]?.txn_count !== undefined
                  ? `${json_data[key]?.txn_count} ${props.configs.transaction_summary.subheader[period]}`
                  : "Transaction Data Unavailable";
              Object.keys(json_data?.[key]).forEach((ar_keys) => {
                if (props.configs.transaction_summary.dataLabels.includes(ar_keys)) {
                  refinedData.TxnSummary.body = [
                    ...refinedData.TxnSummary.body,
                    {
                      item_key: props.configs.dashboardInsightsLabels[ar_keys]["count"],
                      item_value: json_data?.[key]?.[ar_keys]?.count ?? "N/A",
                    },
                    {
                      item_key: props.configs.dashboardInsightsLabels[ar_keys]["amount"],
                      item_value: json_data?.[key]?.[ar_keys]?.formatted
                        ? `${userStatus?.currency?.symbol ?? "$"}${json_data?.[key]?.[ar_keys]?.formatted}`
                        : "N/A",
                    },
                  ];
                }
              });
            }
          });

          setLoading(false);
          setSummary(refinedData);
        } else {
          setLoading(false);
          setSummary({});
        }
      })
      .catch((err) => {
        console.error("Summary API Error::", err);
        errorMsg.current = "Insights Unavailable";
        setLoading(false);
        setSummary({});
      });
  };

  React.useEffect(() => {
    setLoading(true);
    fetchInsightDetails();
    errorMsg.current = props.configs.insights_error_message;
  }, [selectedWorkspace, userStatus?.currency]);
  return <InsightsCarouselComponent error_message={errorMsg.current} summary={summary} loading={loading} />;
};

export default InsightsCarouselContainer;
