import { WorkspaceType } from "../../types/enums";
import APIClient from "../APIClient";

export class ReportsClient {
  /**
   * * GET on /api/v1/Reports/cashflow
   * @param timeframe - Timeframe from today cashflow should be processed within (Defaults 30)
   * @returns Promise of type CashflowReportModel
   */
  getCashflow(timeframe?: number): Promise<CashFlowModel> {
    const url = "api/v1/Reports/cashflow";

    const options = {
      params: {
        timeframe,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  getPayablesSummary(timeframe?: number): Promise<PayablesSummaryModel> {
    const url = "api/v1/Reports/payables-summary";
    const options = {
      params: {
        timeframe,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  getPayablesComingDue(): Promise<PayablesComingDueData[]> {
    const url = "/api/v1/Reports/payables-coming-due";

    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }

  getPayablesComingDueSummary(
    filter?: string,
    include?: string,
    order?: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<PayablesComingDueSummary> {
    const url = "/api/v1/Reports/payables-coming-due-summary";
    const config = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };
    return APIClient.get(url, config).then((response) => {
      return response.data;
    });
  }

  getPayablesComingDueHeader(reportDate: string): Promise<PayablesComingDueHeader> {
    const url = "/api/v1/Reports/payables-coming-due-header";
    const options = {
      params: {
        reportDate,
      },
    };
    return APIClient.get(url, options).then((response) => {
      return response.data[0];
    });
  }

  /**
   * * GET on /api/v1/Reports/dailysalesoutstanding
   * @returns Promise of type DSOReport
   */
  getDSO(): Promise<DailySalesOutstandingReportModel[]> {
    const url = "api/v1/Reports/dailysalesoutstanding";

    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }

  getDPO(): Promise<DailyPayableOutstandingReportModel[]> {
    const url = "/api/v1/Reports/daily-payable-outstanding";

    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }

  /**
   * * GET on /api/v1/Reports/riskrates
   * @returns Promise of type RiskRateModel[]
   */
  getRiskRate(): Promise<RiskRateModel[]> {
    const url = "api/v1/Reports/riskrates";

    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }

  /**
   * * GET on /api/v1/Reports/ar-header
   * @param reportDate - The date of the report.
   * @param companyId - Include a company to get AR data for a specific company, leave as null to include all Companies.
   * @returns Promise of type ArHeaderInfoModel
   */
  async getARHeader(reportDate: string, companyId?: string): Promise<ArHeaderInfoModel> {
    const url = "api/v1/Reports/ar-header";

    const options = {
      params: {
        reportDate,
        companyId,
      },
    };

    const response = await APIClient.get(url, options);
    return response.data;
  }

  /**
   * * GET on /api/v1/Reports/ap-header
   * @param reportDate - The date of the report.
   * @param companyId - Include a company to get AR data for a specific company, leave as null to include all Companies.
   * @returns Promise of type ArHeaderInfoModel
   */
  async getAPHeader(reportDate: string, companyId?: string): Promise<ApHeaderInfoModel> {
    const url = "api/v1/Reports/ap-header";

    const options = {
      params: {
        reportDate,
        companyId,
      },
    };

    const response = await APIClient.get(url, options);
    return response.data;
  }

  /**
   * * GET on /api/v1/Reports/daily-payable-outstanding-summary-total
   * @param reportDate - The date of the report.
   * @param companyId - Include a company to get AR data for a specific company, leave as null to include all Companies.
   * @returns Promise of type ArHeaderInfoModel
   */
  async getDPOHeader(reportDate: string, companyId?: string): Promise<ApDPOInfoHeader> {
    const url = "api/v1/Reports/daily-payable-outstanding-summary-total";

    const options = {
      params: {
        reportDate,
        companyId,
      },
    };

    const response = await APIClient.get(url, options);
    return response.data;
  }

  /**
   * * GET on /api/v1/Reports/aging
   * @param CompanyId - Company aging buckets are filtered by (If undefined, all company aging returned)
   * @param Recalculate - Force api to recalculate aging data, cached data may be returned when set to false
   * @param CurrencyCode - Currency aging buckets are converted to (If undefined, no conversion)
   * @param CurrencyProvider - Currency provider currency rates (If undefined, defaults to Lockstep currency provider)
   * @param Buckets - Customized buckets used for aging calculations (Defaults to [0, 30, 60, 90, 120, 180])
   * @returns Promise of type ArAgingHeaderInfoModel[] or Exception
   */
  async getArAging(getWorkspaceType: string): Promise<ArAgingHeaderInfoModel[]> {
    let url;

    switch (getWorkspaceType) {
      case "AR":
      case WorkspaceType.AR:
        url = "api/v1/Reports/ar-aging-header";
        break;
      default:
        url = "";
    }

    const options = {
      params: {},
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  /**
   * * GET on /api/v1/Reports/aging
   * @param CompanyId - Company aging buckets are filtered by (If undefined, all company aging returned)
   * @param Recalculate - Force api to recalculate aging data, cached data may be returned when set to false
   * @param CurrencyCode - Currency aging buckets are converted to (If undefined, no conversion)
   * @param CurrencyProvider - Currency provider currency rates (If undefined, defaults to Lockstep currency provider)
   * @param Buckets - Customized buckets used for aging calculations (Defaults to [0, 30, 60, 90, 120, 180])
   * @returns Promise of type ApAgingHeaderInfoModel[] or Exception
   */
  async getApAging(getWorkspaceType: string): Promise<ApAgingHeaderInfoModel[]> {
    let url;

    switch (getWorkspaceType) {
      case "AP":
      case WorkspaceType.AP:
        url = "api/v1/Reports/ap-aging-header";
        break;
      default:
        url = "";
    }

    const options = {
      params: {},
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  /**
   * * GET on /api/v1/Reports/attachments-header
   * @param companyId - Include a company to get Attachment data for a specific company, leave as null to include all Companies.
   * @returns Promise of type AttachmentHeaderInfoModel
   */
  async getAttachmentsHeader(companyId?: string): Promise<AttachmentHeaderInfoModel> {
    const url = "api/v1/Reports/attachments-header";

    const options = {
      params: {
        companyId,
      },
    };

    const response = await APIClient.get(url, options);
    return response.data;
  }
}
