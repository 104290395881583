import styles from "../../../../../css/_export.module.scss";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import Utils from "../../../../../utils/utils";
import { Caution } from "../../../../library/Icons/Icons";
import HeaderComponentProps, { HeaderItemMeta } from "../HeaderInterfaces/HeaderInterfaces";
import "./../Header.scss";

export const HeaderComponent = (props: HeaderComponentProps) => {
  const getCurrencyEle = (value: number, label: string): JSX.Element => {
    return (
      <div key={label}>
        <span className={`balance-amount-dark`}>{Utils.formatValueAsCurrency(value, props.currencyConfig?.locale, props.currencyConfig?.code)}</span>
        <span className={`balance-amount-light`}> {label}</span>
      </div>
    );
  };

  const formatCustomerInfo = (value: number, label: string): React.ReactElement => {
    const displayLabel = Utils.formatLabelPluralization(value, label);

    return (
      <div key={label}>
        <span className={`balance-amount-dark`}>{value} </span>
        <span className={`balance-amount-light`}>{displayLabel}</span>
      </div>
    );
  };

  const { path } = useRouteMatch();
  const isOtherConnection = path.includes("connections");

  const formatFunctionMap: any = {
    formatCurrency: getCurrencyEle,
    formatCustomerInfo,
  };

  const useRichTooltipStyles = makeStyles(() => ({
    tooltip: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: styles.base,
      gap: "0.875rem",
      backgroundColor: styles.white100,
      color: styles.primary,
      boxShadow: `0px 0.25rem 0.25rem rgba(0, 0, 0, 0.08), 0px -0.125rem 0.5rem rgba(0, 0, 0, 0.04)`,
      borderRadius: styles.tiny,
      minHeight: "3.75rem",
      maxWidth: "17.5rem",
    },
  }));

  return (
    <>
      <div className="left">
        <div className={`tag`}>{props.companyData?.name?.charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) ?? ""}</div>
        <p className={`customerName`}>{props.companyData?.name ?? ""}</p>
      </div>
      <div className="right">
        <div className={`amounts-wrapper ${isOtherConnection ? "amounts-separator" : ""}`}>
          <Switch>
            <Route
              exact
              path={[`${path}/open_invoices`, `${path}/open_bills`]}
              render={() => {
                return (
                  <>
                    {props.configs.openInvoices.map((item: HeaderItemMeta) => {
                      const data = props.transactionData;
                      return formatFunctionMap[item.value](
                        data[item.accessor as keyof typeof data] ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
                        item.label
                      );
                    })}
                  </>
                );
              }}
            />
            <Route
              exact
              path={[`${path}/closed_invoices`, `${path}/closed_bills`]}
              render={() => {
                return (
                  <>
                    {props.configs.closedInvoices.map((item: HeaderItemMeta) => {
                      // update label for specific fields
                      let label = item.label;
                      if (item.currentYear) {
                        label = `${label} ${new Date().getFullYear()}`;
                      } else if (item.prevYear) {
                        label = `${label} ${new Date().getFullYear() - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE}`;
                      }
                      const data = props.transactionData;
                      return formatFunctionMap[item.value](data[item.accessor as keyof typeof data] ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, label);
                    })}
                  </>
                );
              }}
            />
            <Route
              exact
              path={`${path}/payments`}
              render={() => {
                return (
                  <>
                    {props.configs.payments.map((item: HeaderItemMeta) => {
                      // update label for specific fields
                      let label = item.label;
                      if (item.currentYear) {
                        label = `${label} ${new Date().getFullYear()}`;
                      }
                      const data = props.transactionData;
                      return formatFunctionMap[item.value](data[item.accessor as keyof typeof data] ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, label);
                    })}
                  </>
                );
              }}
            />
            <Route
              exact
              path={`${path}/contacts`}
              render={() => {
                return <>{formatCustomerInfo(props.contactsCount, "Total Contact")}</>;
              }}
            />
            <Route
              exact
              path={`${path}/documents`}
              render={() => {
                return <>{formatCustomerInfo(props.documentsCount, "Total Document")}</>;
              }}
            />
          </Switch>
        </div>
        {isOtherConnection && (
          <div className="other-connection">
            <span className="text">Other Connection </span>
            <Tooltip
              classes={{ tooltip: useRichTooltipStyles().tooltip }}
              title="This connection was created because the email address was not found in the ERP."
              followCursor
              placement="bottom-start"
            >
              <div className="column-icon">
                <Caution />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};
