import DOMPurify from "dompurify";
import React from "react";
import { PrimaryActivityContent } from "../components/Activities/ActivityDetail/ActivityFeed/ActivityFeedItem/PrimaryActivity/PrimaryActivityTypes";
import { DEFAULT_NUMERIC_VALUES } from "../constants/NumericConstants";

const useLimitContent = (order: number, content: Omit<PrimaryActivityContent, "originalContent">) => {
  const [limitContent, setLimitContent] = React.useState(true);
  const [isOverflowDetected, setOverflowDetected] = React.useState(false);
  const [contentBody, setContentBody] = React.useState(DOMPurify.sanitize(content.preview ?? ""));

  const emailRendererRef = React.useRef<HTMLDivElement | null>(null);
  const emailContainerRef = React.useRef<HTMLDivElement | null>(null);

  /**
   * Get preview content and simple_content and keep them sanitized
   */
  const previewContent = DOMPurify.sanitize(content.preview ?? "");
  const simpleContent = DOMPurify.sanitize(content.simpleEmailContent ?? "");

  /**
   * When an overflow is detected inside activity renderer iframe and height limit is exceeded,
   * then show preview content else show simple content
   */
  React.useEffect(() => {
    if (isOverflowDetected && limitContent) {
      setContentBody(previewContent);
    }
    if (isOverflowDetected && !limitContent) {
      setContentBody(simpleContent);
    }
  });

  React.useEffect(() => {
    if (emailRendererRef.current && emailContainerRef.current && emailRendererRef.current.offsetHeight < emailContainerRef.current.offsetHeight) {
      setOverflowDetected(true);
    } else {
      setOverflowDetected(false);
    }
  }, []);

  /**
   * Open the first activity in the stream in read more mode and set read less button
   */
  React.useEffect(() => {
    if (order === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setLimitContent(!limitContent);
    }
  }, [order]);

  const toggleContentLimit = () => {
    setLimitContent(!limitContent);
  };

  return { contentBody, emailRendererRef, emailContainerRef, limitContent, toggleContentLimit, isOverflowDetected };
};

export default useLimitContent;
