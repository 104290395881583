import React from "react";
import { Delete, MoveTo, Edit, PrimaryContact } from "../../../../library/Icons/Icons";
import "../../../../Activities/Mine/HoverBar/Hoverbar.scss";
import Button from "../../../../library/Button/Button";
import { ContactFormContainerProps } from "./HoverBarContainer";

const ContactHoverBarComponent = (props: ContactFormContainerProps): React.ReactElement => {
  return (
    <div
      className={props.barriers ? "hoverbar-container" : "hoverbar-container in-table"}
      style={{ width: props?.barriers ? "22.5rem" : "20.625rem" }}
    >
      {!props.data.appEnrollmentId && (
        <Button
          icon={<Edit />}
          variant="transparent"
          alignIcon="left"
          onClick={(e) => {
            e.stopPropagation();
            props.editContact(props.data.id);
          }}
          className="btn-icon-only"
          tooltip="Edit"
        />
      )}
      {!props.data.isPrimary && (
        <Button
          icon={<PrimaryContact />}
          variant="transparent"
          alignIcon="left"
          onClick={(e) => {
            e.stopPropagation();
            props.markPrimaryContact({
              contactId: props.data.id,
              contactName: props.data.name,
              contactRole: props.data.role,
              contactEmail: props.data.email,
              contactPhone: props.data.phoneNumber,
              contactIsPrimary: props.data.isPrimary,
            } as ContactDataType);
          }}
          className="btn-icon-only"
          tooltip={"Make primary contact"}
        />
      )}
      {!props.data.appEnrollmentId && !props.data.isPrimary && (
        <>
          <Button
            icon={<MoveTo />}
            variant="transparent"
            alignIcon="left"
            onClick={(e) => {
              e.stopPropagation();
              props.moveContact(props.data.id);
            }}
            className="btn-icon-only"
            tooltip="Move"
          />
          <Button
            icon={<Delete />}
            variant="transparent"
            alignIcon="left"
            onClick={(e) => {
              props.deleteContact([props.data.id]);
              e.stopPropagation();
            }}
            className="btn-icon-only"
            tooltip="Delete"
          />
        </>
      )}
    </div>
  );
};

export default ContactHoverBarComponent;
