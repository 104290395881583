import React, { useState, MouseEvent } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import Table from "../../../library/Table/Table";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import { paymentsClient } from "../../../../db/accessor";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES, ISO_DATE_SLICE } from "../../../../constants/NumericConstants";
import NewActivityUtils from "../../../../utils/NewActivityUtils/NewActivityUtils";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { AlertContext } from "../../../../contexts/AlertContext";
import { EmailAction, FallbackTypes } from "../../../../types/enums";
import NewActivityPopup from "../../../library/AddNewActivityDropdown/NewActivityPopup";
import { Email, NoteFill, Phone } from "../../../library/Icons/Icons";
import { TemplateContext } from "../../../../contexts/TemplateContext";
import { TemplateDefinitionProps } from "../../../../app/Templates/TemplateFactory";
import { ActivityContext } from "../../../../contexts/ActivityContext";
import { automationClientV2, emailsClientV2 } from "../../../../db/version2Accessor";
import { AxiosError } from "axios";
import { CONNECTION_STATUS } from "../../../../constants/ConnectionConstants";
import { TemplateTypes, viewType } from "../../../../app/Templates/TemplateTypes";
import { PaymentContext } from "../../../../contexts/PaymentContext";
import { formatDate } from "../../../../db/utils/date";
import { AppContext } from "../../../../contexts/AppContext";
import TrackingUtils from "../../../Tracking/Tracking.Utils";
import { ApplicationRouteContext } from "../../../../contexts/ApplicationRouteContext";
import { DocumentSwitchContext } from "../../../../contexts/DocumentSwitchContext";
import Utils from "../../../../utils/utils";
import _ from "underscore";
import { CustomerContext } from "../../../../contexts/CustomerContext";

interface PaymentsProps {
  configs: any;
}
/**
 * * Define the Payments Tab of the Customer Detail's page
 *   TODO: Add API call to (pending) new Payments view (From Shane)
 */
export default function Payments(props: PaymentsProps): React.ReactElement {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const params = new URLSearchParams(window.location.search);
  const paymentsConfigs = props.configs.payments;

  const pageNoFromUrl = params.get("page");
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "0");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    } else {
      return pageNum;
    }
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION_VALUES.PAGE_SIZE);
  // New activity states
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [newActivityType, setNewActivityType] = useState<string>("");
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const newActivityUtils = new NewActivityUtils(selectedWorkspace?.id || FallbackTypes.Id);
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [editorState, setEditorState] = useState<string>("");
  const { templateFactory, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const [item, setItem] = useState<any>();
  const { handleClose } = React.useContext(ActivityContext) as ActivityType;
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { userStatus, getContactsOptions, allContactOptions, filterPrimaryContacts } = React.useContext(AppContext) as AppType;
  const { signature } = React.useContext(CustomerContext) as CustomerType;
  const [templateName, setTemplateName] = useState<string>("");
  const { getDetailViewInvoices } = React.useContext(PaymentContext) as PaymentType;
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setPlatformPageFetchParams,
    setDocumentType,
    setEnableDocumentSwitch,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;

  /**
   * @function getCompanyIdForFirstTo
   * A helper function to get the company id for the first contact selected
   * @param to - Contacts
   * @returns
   */
  const getCompanyIdForFirstTo = (to: To[]) => {
    if (to && to.length) {
      return to[0].companyId;
    }
    return "";
  };

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "Messages Sent" };
    const activityTransactions = _.uniq([...inboxAttachments, { transaction_id: item.paymentId, transaction_type: "payment" }], "transaction_id");
    const payload: Partial<ActivityItemEmailModel> = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      contact_company_id: getCompanyIdForFirstTo(to),
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      content_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "input_email"
          : newActivityType && newActivityType === "email"
          ? "input_email"
          : newActivityType,
      activity_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "email"
          : newActivityType,
      activity_transactions: activityTransactions,
      primary_connection_id: customerId,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, activityTransactions),
    };
    return new Promise((resolve) => {
      emailsClientV2
        .post(payload)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;

            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(to, "Ellipsis", "New", isSendAndClose ?? false, allContactOptions, "Payments", path, newActivityType ?? "");
          setShowActivity(false);
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: "Activity Closed" };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: "Activity was not closed",
          };
        }
        setToastOptions(toastOptions);
      }
    }
  };

  React.useEffect(() => {
    newActivityUtils.fetchCompanyInfo(userStatus.account_company_id as string, setToastOptions).then((companyData) => setCompanyData(companyData));
    return () => {
      setTotalCount(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    };
  }, []);

  const fetchParser = (fetchResult: PaymentSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: PaymentSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.paymentId,
          }),
          ...((variant === "export" || variant === "all") && {
            payment_id: record.paymentId,
            payment_number: record.referenceCode,
            type: record.tenderType,
            payment_date: record.paymentDate,
            payment_amount: record.paymentAmount,
            paymentCompanyId: record.paymentCompanyId,
            paymentCompanyName: record.paymentCompanyName,
            totalPaymentsApplied: record.totalPaymentsApplied,
            invoices: record.invoiceList,
            paymentId: record.paymentId,
            unapplied_amount: record?.unappliedAmount,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const fetchPayments = () => {
    const preDefinedFilters = [
      ...paymentsConfigs.table.preDefinedFilters,
      {
        searchlightFilter: `(PaymentCompanyId eq '${customerId}')`,
      },
    ];
    const defaultSort = `${paymentsConfigs.defaultSortKey} ${paymentsConfigs.defaultSortToken}`;
    let sortQuery = TableUtils.getSortFromURL(params);
    if (!sortQuery || sortQuery === "") {
      sortQuery = defaultSort;
    }
    const filterQuery = TableUtils.getFilterFromURL(params, paymentsConfigs.table.columns);

    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    const fetchParams = {
      pageSize,
      page: pageNumber,
      order: TableUtils.sortQueryParser(sortQuery ?? "", false),
      filter: TableUtils.columnFilterParser(filterQuery, false, preDefinedFilters),
      include: undefined,
    };
    setPlatformPageFetchParams(fetchParams as PlatformPageFetchParams);
    return paymentsClient.querySummaries(
      TableUtils.columnFilterParser(filterQuery, false, preDefinedFilters),
      undefined,
      TableUtils.sortQueryParser(sortQuery ?? "", false),
      pageSize,
      pageNumber
    );
  };

  function handleRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { payment_id?: string };
    }
  ) {
    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     * @param selectedActivityStreamId - currently selected document id
     * @param activityStreamIds - list of documents in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    setSelectedActivityStreamId({ id: row.original.payment_id } as ActivityStreamId);
    setActivityStreamIds(tableData.map((item) => ({ id: item.id })));
    setCurrentPaginationState({ page: pageNumber, pageCount, pageSize, totalRecords: totalCount });
    setDocumentType("related-payment");
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${getBaseRoute()}/payments/${row.original.payment_id ?? ""}`);
  }

  const fetchCompanyContactOptions = async (customerId: string) => {
    await setCompanyContactOptions(await getContactsOptions(customerId));
  };

  /**
   * Function is used to prepare the invoice data which need's to show in templates.
   *
   * @param {string} workspaceType The workspaceType indicate which works space is currently selected.
   * @param {TemplateTypes} templateID The templateID indicate which template is selected by user from subject dropdown.
   * @param {PaymentDetailViewResponse[]} invoices the invoice
   * @returns {}
   */
  const prepareInvoiceDataForTemplate = (workspaceType: string | undefined | null, invoices: PaymentDetailViewResponse[]): Record<string, any> => {
    let invoiceInfo: Record<string, any> = [];
    const isAccountReceivable = workspaceType === "accounts_receivable";
    if (invoices) {
      invoiceInfo = invoices.map((item, index) => {
        const props = {
          title: isAccountReceivable ? `Invoice- ${++index}` : `Bill- ${++index}`,
          items: [
            {
              key: isAccountReceivable ? "Invoice number" : "Bill number",
              value: item.invoice.referenceCode,
            },
            {
              key: "Due date",
              value: formatDate(item.invoice.paymentDueDate),
            },
            {
              key: isAccountReceivable ? "Amount paid" : "Paid amount",
              value: item.paymentAppliedAmount,
            },
            isAccountReceivable
              ? {
                  key: "Invoice status",
                  value: item.invoice.invoiceStatusCode,
                }
              : "",
          ],
        };

        return props;
      });
    }
    return invoiceInfo;
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = async (templateID: string | null, setEditorState: React.Dispatch<React.SetStateAction<string>>) => {
    if (templateID) {
      setTemplateName(templateID);
      const workspaceType = selectedWorkspace.workspace_type;
      let invoiceInfo: Record<string, any> = [];
      if (templateID !== TemplateTypes.PAYMENT_CONFIRMATION_REQUEST) {
        const invoices = await getDetailViewInvoices(`PaymentId eq ${item.id}`, "invoice");
        invoiceInfo = prepareInvoiceDataForTemplate(workspaceType, invoices);
      }

      const templateObj = templateData.get(selectedWorkspace?.id).get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: item?.paymentCompanyName || "",
          paymentNumber: item?.payment_number || "",
          paymentDate: formatDate(item.payment_date) || "",
          paymentDueDate: formatDate(item.paymentDueDate) || "",
          paymentAmount: Utils.formatValueAsCurrency(
            item.payment_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          totalPaymentsApplied: Utils.formatValueAsCurrency(
            item.totalPaymentsApplied ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          invoices: invoiceInfo,
          signature: signature?.email_signature || "",
          // TODO come up with a more user friendly key names for the invoices list - there can be a separate task for this will be taken up later
        })
      );
    }
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (row: any): TemplateDefinitionProps[] => {
    const view: viewType =
      selectedWorkspace.workspace_type === "accounts_payable" ? viewType.AP_CONNECTION_VENDOR_PAYMENTS : viewType.AR_CONNECTION_CUSTOMER_PAYMENTS;
    return templateFactory.getTemplateDefinitionsByView(view, {
      paymentNumber: row.payment_number,
      customer: row.paymentCompanyName,
    });
  };

  /**
   * Event handler which triggered when user select the 'email', 'note' or 'phone call'
   * context menu item. It will open the new activity dialog based to the context menu item
   * selected by the user.
   *
   * @param {Object} row The selected row from the table.
   * @param {String} buttonId The buttonId indicate which button has been selected by the user.
   */
  const onClickActionButton = async (row: any, buttonId: string) => {
    if (buttonId === "email") {
      setSupportedTemplateList(getTemplateDefinitionsByView(row));
    }

    setItem(row);
    await fetchCompanyContactOptions(row.paymentCompanyId);
    setNewActivityType(buttonId);
    setShowActivity(true);
  };

  /**
   * The ellipsis action handlers which used to open the activity dialog.
   */
  const ellipsisActionHandlers: Partial<RowSelectButtons> = {
    "action.newEmail": onClickActionButton,
    "action.newNote": onClickActionButton,
    "action.newPhoneCall": onClickActionButton,
  };

  const cellRendererMapping: CellRendererMappingObject = {
    "table.formatString": (props: { value: string | null }) => TableUtils.formatString(props.value, false, "contact-bold"),
    "table.formatDate": (props: { value: string | null }) => TableUtils.formatDate(props.value, false, "contact-bold"),
    "table.formatAction": (props: { value: string | null; row: { original: { status: string } } }) => {
      return TableUtils.formatAction(
        props.row.original,
        paymentsConfigs.table.ellipsisActions.map((action: EllipsisAction) => {
          return { ...action, clickFunction: ellipsisActionHandlers[action.handler] };
        })
      );
    },
    "table.formatCurrency": (props: { value: number | null }) =>
      TableUtils.formatCurrency(props.value, false, userStatus?.currency?.locale, userStatus?.currency?.code),
    "table.formatInvoiceStatus": (props: { value: string | null; row: { original: { due_date: string | null; status: string } } }) => {
      return TableUtils.formatInvoiceStatusByDueDate(
        props.row.original.due_date?.slice(ISO_DATE_SLICE.START, ISO_DATE_SLICE.END_SHORT_DATE) ?? "",
        props.row.original.status ?? "",
        false
      );
    },
    "table.formatList": (props: { value: string[] | null }) => TableUtils.formatList(props.value, false),
    "table.formatPaymentType": (props: { value: string | null }) => TableUtils.formatPaymentType(props.value, false),
  };

  const filteredContacts = [...companyContactOptions, ...allContactOptions];

  const primaryContact = filterPrimaryContacts(companyContactOptions);

  return (
    <>
      <Table
        dataSets={[
          {
            id: "Payments",
            displayName: "Payments",
            rowSelectToken: "paymentId",
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: fetchPayments,
              fetchParser: fetchParser,
            },
            columns: paymentsConfigs.table.columns.map((column: ColumnHeaderType) => {
              return { ...column, Cell: cellRendererMapping[column.CellRenderer] };
            }),
            handleRowClick: handleRowClick,
            export: {
              exportFileName: "Customer Details - Payments",
            },
          },
        ]}
        states={{
          isLoading: isLoading,
          setLoading: setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: totalCount,
          setTotalCount: setTotalCount,
          setPageSize: setPageSize,
        }}
        toggles={{
          showSearchbar: false,
          showExportBtn: true,
          showCaption: true,
          showRowSelect: true,
          showNavigation: true,
          showSelectAllBar: true,
        }}
        isColumnFixed={true}
      />
      {showActivity && (
        <NewActivityPopup
          key={`key-add-activity-${newActivityType}`}
          title={"New Activity"}
          open={showActivity}
          contactOptions={filteredContacts}
          defaultTo={_.isEmpty(primaryContact) ? [companyContactOptions[0]] : primaryContact}
          handleSend={handleSend}
          handleSendMarkClosed={handleSendAndMarkClosed}
          isTemplateSupport={newActivityType === "email"}
          supportedTemplateList={supportedTemplateList}
          onClose={() => {
            setShowActivity(false);
            setEditorState("");
          }}
          editorState={editorState}
          activityType={newActivityType}
          setNewActivityType={(type) => {
            setNewActivityType(type);
          }}
          addActivityDropdown={[
            { displayName: "Email", icon: <Email /> },
            { displayName: "Note", icon: <NoteFill /> },
            { displayName: "Phone Call", icon: <Phone /> },
          ]}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
        />
      )}
    </>
  );
}
