import React, { useMemo, useRef, Dispatch, SetStateAction, useEffect } from "react";
import * as constants from "../../../../constants/config";
import Attachment from "./CustomButtons/Attachment";
import FileAttachment from "../../../library/FileAttachment/FileAttachment";
import Utils from "../../../../utils/utils";
import "./ActivityBody.scss";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import JoditEditor from "./JoditHOC/JoditHOC";
import { IJodit } from "jodit/types";
import { Undo, Redo, Bold, Italic, Underline, Strikethrough } from "./JoditIcons/JoditIcons";
import DOMPurify from "dompurify";
import MentionHOC from "./MentionHOC/MentionHOC";

type MentionsDataItemType = {
  id: number;
  name?: string;
  email_address?: string;
  active_at?: string;
  user_role?: string;
};

type MentionMembersType = {
  loading: boolean;
  members: Array<MentionsDataItemType>;
};

interface ActivityBodyProps {
  editorState: string;
  setEditorState: Dispatch<SetStateAction<string>>;
  files: (File | string)[];
  handleFileSelect: (newFiles: FileList) => void;
  filesChanged: number;
  filesSelected: boolean;
  handleFileRemove: (key: number) => void;
  forwarding?: boolean;
  isCC?: boolean;
  isBCC?: boolean;
  to?: To[];
  onClickAddAttachments: (attachments: InboxAttachment[]) => void;
  addMagicLinkSnippet?: boolean;
  mentionMembers: MentionMembersType;
  enableMentions: boolean;
}

export default function ActivityBody(props: ActivityBodyProps): React.ReactElement {
  /**
   * Jodit Editor
   * This component makes use of Jodit, an opensource WYSIWYG editor written in pure typescript and no dependencies.
   * This implementation is using jodit-react, which is a react wrapper on jodit base library. This component is initialized
   * with a memoized config object as shown below. Editor state is always created and consumed as string type.
   * @see {@link https://xdsoft.net/jodit/ | Jodit Editor}
   * @see {@link https://xdsoft.net/jodit/play.html | Customisation}
   */
  const editor = useRef(null);
  const EditorInstance = useRef<IJodit>();
  const config = useMemo(() => {
    return {
      autofocus: false,
      showTooltip: false,
      readonly: props?.forwarding ?? false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      spellcheck: false,
      toolbarButtonSize: "large",
      minHeight: "27.25rem",
      maxHeight: "27.25rem",
      tabIndex: 1,
      buttons: "undo,redo,|,bold,italic,underline,strikethrough",
      buttonsMD: "undo,redo,|,bold,italic,underline,strikethrough",
      buttonsSM: "undo,redo,|,bold,italic,underline,strikethrough",
      buttonsXS: "undo,redo,|,bold,italic,underline,strikethrough",
      editorCssClass: "na-body-editor",
      disablePlugins: "add-new-line,placeholder,inline-popup",
      activeButtonsInReadOnly: [],
      extraButtons: ["LinkEditorPlugin"],
      events: {
        getIcon: (name: string, control: any, clearName: string) => {
          let code = clearName;
          switch (clearName) {
            case "undo":
              code = Undo;
              break;
            case "redo":
              code = Redo;
              break;
            case "bold":
              code = Bold;
              break;
            case "italic":
              code = Italic;
              break;
            case "underline":
              code = Underline;
              break;
            case "strikethrough":
              code = Strikethrough;
              break;

            default:
              return;
          }
          return code;
        },
        afterInit: (instance: IJodit) => {
          EditorInstance.current = instance;
        },
      },
    };
  }, []);

  /**
   * When user clicks on Insert Magic Link, the snippet is added
   * on the cursor position of the user.
   */
  useEffect(() => {
    props.addMagicLinkSnippet &&
      !props.editorState.includes(constants.MAGIC_LINK_SNIPPET) &&
      EditorInstance.current?.selection.insertHTML(`${constants.MAGIC_LINK_SNIPPET}&nbsp`);
  }, [props.addMagicLinkSnippet]);

  return (
    <div className={`na-body-wrapper`}>
      <Attachment
        renderKey={0}
        handleFileSelect={props.handleFileSelect}
        fileSelected={props.filesSelected}
        customerId={props.to?.[0]?.connectionId ?? ""}
        onClickAddAttachments={props.onClickAddAttachments}
      />

      <MentionHOC
        editorState={props.editorState}
        setEditorState={props.setEditorState}
        mentionMembers={props.mentionMembers}
        EditorInstance={EditorInstance}
        enableMentions={props.enableMentions}
      >
        <JoditEditor
          ref={editor}
          value={props.editorState?.length ? DOMPurify.sanitize(props.editorState, { USE_PROFILES: { html: true } }) : ""}
          config={config}
          /**
           * preferred to use only this option to update the content for performance reasons
           */
          onChange={(newContent: string) => {
            props.setEditorState(newContent);
          }}
        />
      </MentionHOC>

      {props.files.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
        <div className={`na-body-attachments`}>
          {props.files.map((value, index) => (
            <FileAttachment
              key={index}
              label={(value as File)?.name ?? value}
              size={Utils.calculateFileSize((value as File)?.size) ?? ""}
              showClose={props?.forwarding !== null && !props.forwarding}
              handleClick={() => props.handleFileRemove(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
