import { AxiosError } from "axios";
import { DateTime } from "luxon";
import React, { MouseEvent, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import _ from "underscore";
import { TemplateDefinitionProps } from "../../../../../app/Templates/TemplateFactory";
import { viewType } from "../../../../../app/Templates/TemplateTypes";
import { CONNECTION_STATUS } from "../../../../../constants/ConnectionConstants";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES, NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { ActivityContext } from "../../../../../contexts/ActivityContext";
import { AlertContext } from "../../../../../contexts/AlertContext";
import { AppContext } from "../../../../../contexts/AppContext";
import { ComponentConfigContext } from "../../../../../contexts/ComponentConfigContext";
import { TemplateContext } from "../../../../../contexts/TemplateContext";
import { WorkspaceContext } from "../../../../../contexts/WorkspaceContext";
import { companiesClient } from "../../../../../db/accessor";
import { formatDate } from "../../../../../db/utils/date";
import { automationClientV2, companiesClientV2, emailsClientV2 } from "../../../../../db/version2Accessor";
import { EmailAction, FallbackTypes, WorkspaceType } from "../../../../../types/enums";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import Utils from "../../../../../utils/utils";
import NewActivityPopup from "../../../../library/AddNewActivityDropdown/NewActivityPopup";
import { Email, NoteFill, Phone } from "../../../../library/Icons/Icons";
import Table from "../../../../library/Table/Table";
import TrackingUtils from "../../../../Tracking/Tracking.Utils";
import { CustomerContext } from "../../../../../contexts/CustomerContext";

interface DaysOutstandingModuleTable {
  routeType: string;
  reportDate: string;
}

export default function DaysOutstandingModuleTable(props: DaysOutstandingModuleTable): React.ReactElement {
  const { path } = useRouteMatch();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const config = getConfig(selectedWorkspace?.workspace_type_route ?? WorkspaceType.AR)["dashboard"]["drilldowns"]["daysOutstandingModule"];

  const { allRowsSelected, handleClose } = React.useContext(ActivityContext) as ActivityType;
  const { userStatus, getContactsOptions, allContactOptions, filterPrimaryContacts } = React.useContext(AppContext) as AppType;
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const { templateFactory, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [selectedInvoice, setSelectedInvoice] = useState<any>();
  const [newActivityType, setNewActivityType] = useState<string | null>(null);
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const [templateName, setTemplateName] = useState<string>("");
  const [editorState, setEditorState] = useState<string>("");
  const [defaultTitle, setDefaultTitle] = useState<string>("");
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { signature } = React.useContext(CustomerContext) as CustomerType;

  const fetchCompanyInfo = (companyId: UUID) => {
    if (!companyId) {
      return;
    }
    companiesClient.getCompany(companyId, "Classification").then((companyModel: CompanyModel) => {
      setCompanyData(companyModel);
    });
  };

  /**
   * @function getCompanyIdForFirstTo
   * A helper function to get the company id for the first contact selected
   * @param to - Contacts
   * @returns
   */
  const getCompanyIdForFirstTo = (to: To[]) => {
    if (to && to.length) {
      return to[0].companyId;
    }
    return "";
  };

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "Messages Sent" };
    const activityTransactions = _.uniq([...inboxAttachments, { transaction_id: selectedInvoice.id, transaction_type: "invoice" }], "transaction_id");
    const payload: Partial<ActivityItemEmailModel> = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      contact_company_id: getCompanyIdForFirstTo(to),
      content_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "input_email"
          : newActivityType && newActivityType === "email"
          ? "input_email"
          : newActivityType,
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "email"
          : newActivityType,
      activity_transactions: activityTransactions,
      primary_connection_id: selectedInvoice.customer_id,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, activityTransactions),
    };

    return new Promise((resolve) => {
      emailsClientV2
        .post(payload)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;

            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(
            to,
            "New Email",
            "New",
            isSendAndClose ?? false,
            allContactOptions,
            props.routeType == "days_sales_outstanding" ? "DaySalesOutstanding" : "DaysPayableOutstanding",
            path,
            newActivityType ?? ""
          );
          setShowActivity(false);
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: "Activity Closed" };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: "Activity was not closed",
          };
        }
        setToastOptions(toastOptions);
      }
    }
  };

  const fetchCompanyContactOptions = async (customerId: string) => {
    await setCompanyContactOptions(await getContactsOptions(customerId));
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (row: any): TemplateDefinitionProps[] => {
    const view: viewType =
      selectedWorkspace.workspace_type === "accounts_payable"
        ? viewType.AP_DASHBOARD_DAYS_PAYABLE_OUTSTANDING
        : viewType.AR_DASHBOARD_DAYS_SALES_OUTSTANDING;
    return templateFactory.getTemplateDefinitionsByView(view, { customer: row.company_name });
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = (templateID: string | null, setEditorState: React.Dispatch<React.SetStateAction<string>>) => {
    if (templateID) {
      setTemplateName(templateID);
      const templateObj = templateData.get(selectedWorkspace?.id).get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: selectedInvoice?.company_name || "",
          pastDueAmount: Utils.formatValueAsCurrency(
            selectedInvoice.past_due_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          signature: signature.email_signature || "",
          accountTotalBalance: Utils.formatValueAsCurrency(
            selectedInvoice.outstanding_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
        })
      );
    }
  };

  /**
   * Event handler which triggered when user select the 'email', 'note' or 'phone call'
   * context menu item. It will open the new activity dialog based to the context menu item
   * selected by the user.
   *
   * @param {Object} row The selected row from the table.
   * @param {String} buttonId The buttonId indicate which button has been selected by the user.
   */
  const onClickActionButton = async (row: any, buttonId: string) => {
    setSelectedInvoice(row);
    if (buttonId === "email") {
      setSupportedTemplateList(getTemplateDefinitionsByView(row));
    }
    await fetchCompanyContactOptions(row.company_id);
    setNewActivityType(buttonId);
    setShowActivity(true);
  };

  /**
   * The ellipsis action handlers which used to open the activity dialog.
   */
  const ellipsisActionHandlers: Partial<RowSelectButtons> = {
    "action.newEmail": onClickActionButton,
    "action.newNote": onClickActionButton,
    "action.newPhoneCall": onClickActionButton,
  };

  const cellFormattersMap: CellRendererMappingObject = {
    FormatString: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
    FormatNumber: (props: { value: number | null }) => TableUtils.formatNumber(props.value, true),
    FormatCurrency: (props: { value: number | null }) =>
      TableUtils.formatCurrency(props.value, true, userStatus?.currency?.locale, userStatus?.currency?.code),
    FormatNumberRounded: (props: { value: number | null }) =>
      TableUtils.formatNumber(props.value !== null ? Math.round(props.value) : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, true),
    FormatLastActivity: (props: { value: number | null }) => (
      <p className="last_activity_data">
        {_.isNumber(props.value) && props.value ? formatDate(Utils.evaluateDate(props.value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)) : "N/A"}
      </p>
    ),
    FormatFunction: (props: { value: string | null; row: { original: { status: string } } }) => {
      return TableUtils.formatAction(
        props.row.original,
        config?.table?.ellipsisActions.map((action: EllipsisAction) => {
          return { ...action, clickFunction: ellipsisActionHandlers[action.handler] };
        })
      );
    },
  };

  const CUSTOMERSCOLUMNS: ColumnHeaderType[] = config?.table?.columns?.map((col: ColumnHeaderType) => ({
    ...col,
    Cell: cellFormattersMap[col.CellRenderer],
  }));

  const pageNoFromUrl = params.get("page");
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "0");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    } else {
      return pageNum;
    }
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION_VALUES.PAGE_SIZE);
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  const predefinedFilters = Array.from(Array(NUMERIC_VALUES.CONSTANT_THREE).keys()).map((index: number) => {
    if (config?.table?.predefinedFilters_by === "quarter") {
      const lastMonth = DateTime.now()
        .minus({ months: index * NUMERIC_VALUES.CONSTANT_THREE })
        .startOf("month");
      const lastYear = lastMonth.year?.toString?.()?.slice(NUMERIC_VALUES.CONSTANT_TWO);
      const quaterMap: { [key: number]: string } = {
        0: "Current",
        1: "Last",
        2: "Prior",
      };
      return {
        route: `${quaterMap[index]}_${lastYear}`,
        searchlightFilter: "",
      };
    }
    const lastOfMonth = DateTime.now().minus({ months: index }).endOf("month");
    return {
      route: `${lastOfMonth.toFormat("MMM")}_${lastOfMonth.toFormat("yy")}`,
      searchlightFilter: "",
    };
  });

  const fetchParser = (fetchResult: ConnectionsSummaryModelFetchResultV2, variant?: FetchVariant): Partial<TableData>[] => {
    const responseKeys = config?.table?.column_response_key as ColumnResponseKey;
    return (
      fetchResult?.records?.map((record: CustomerSummaryModelV2 | VendorSummaryModelV2) => {
        if (config?.table?.summaryType === "customers") {
          return {
            ...((variant === "id" || variant === "all") && {
              id: record?.[responseKeys.connection_id] as string,
            }),
            ...((variant === "export" || variant === "all") && {
              company_name: (record?.[responseKeys.connection_name] as string) || "N/A",
              primary_contact: (record?.[responseKeys.connection_primary_contact] as string) || "N/A",
              invoices_count: (record?.[responseKeys.connections_outstanding_receipt_count] as string) || "N/A",
              past_due_amount: (record?.[responseKeys.connection_amount_due] as string) || "N/A",
              days_sales_outstanding: (record?.[responseKeys.connection_daysAccountOutstanding] as string) || "N/A",
              last_activity: (record?.[responseKeys.connections_last_activity] as string) || "N/A",
              company_id: (record?.[responseKeys.connection_id] as string) || "",
              outstanding_amount: (record?.[responseKeys.connections_outstanding_amount] as string) || "N/A",
            }),
            ...(variant === "all" && {
              disableCheckbox: undefined,
              isUnread: undefined,
            }),
          };
        }
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.[responseKeys.connection_id] as string,
          }),
          ...((variant === "export" || variant === "all") && {
            company_name: (record?.[responseKeys.connection_name] as string) || "N/A",
            primary_contact: (record?.[responseKeys.connection_primary_contact] as string) || "N/A",
            bills_count: (record?.[responseKeys.connections_outstanding_receipt_count] as string) || "N/A",
            outstanding_amount: (record?.[responseKeys.connection_amount_due] as string) || "N/A",
            days_payables_outstanding: (record?.[responseKeys.connection_daysAccountOutstanding] as string) || "N/A",
            last_activity: (record?.[responseKeys.connections_last_activity] as string) || "N/A",
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleCustomerRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${workspaceHomePath}/${config?.table?.summaryType}/${row.original.id ?? "invalid-company"}`);
  }

  const getFilters = (exportConnection = false, ids?: string[]) => {
    const additionalFilters: { searchlightFilter: string }[] = [
      {
        searchlightFilter: `status=all`,
      },
      {
        searchlightFilter: config?.table?.predefinedFilters_searchLightFilter,
      },
      {
        searchlightFilter: `qa[report_date]=${DateTime.fromFormat(props.reportDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy")}`,
      },
      {
        searchlightFilter: `workspace_id=${selectedWorkspace?.id || FallbackTypes.Id}`,
      },
    ];
    // evaluate export filters - consider ids only if all rows are not selected
    if (exportConnection && !allRowsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      ids.forEach((id: string) => {
        additionalFilters.push({
          searchlightFilter: selectedWorkspace?.workspace_type_route === WorkspaceType.AR ? `qa[company_id_in][]=${id}` : `qa[vendor_id_in][]=${id}`,
        });
      });
    }

    const defaultSort = `${config.table.defaultSortKey} ${config.table.defaultSortToken}`;
    let sortQuery = params.get("sort");
    if (!sortQuery || sortQuery === "") {
      sortQuery = defaultSort;
    }
    additionalFilters.push({
      searchlightFilter: `qs=${TableUtils.sortQueryParser(sortQuery ?? "", false)}`,
    });

    const filterQuery = TableUtils.getFilterFromURL(params, config.table.columns);
    return TableUtils.columnFilterParser(filterQuery, true, additionalFilters ? [...additionalFilters] : undefined);
  };

  const getSummaryApi = () => {
    return companiesClientV2.getCustomerSummaries(getFilters(), undefined, pageSize, pageNumber).then((fetchResult: any) => fetchResult);
  };

  React.useEffect(() => {
    fetchCompanyInfo(userStatus.account_company_id as string);
  }, []);

  const filteredContacts = [...companyContactOptions, ...allContactOptions];
  const primaryContact = filterPrimaryContacts(companyContactOptions);
  return (
    <>
      <Table
        dataSets={[
          {
            id: config?.table?.displayName,
            displayName: config?.table?.displayName,
            rowSelectToken: config?.table?.rowSelectToken,
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: getSummaryApi,
              fetchParser: fetchParser,
            },
            columns: CUSTOMERSCOLUMNS,
            export: {
              exportFileName: config?.table?.exportFileName,
            },
            defaultSort: config?.table?.defaultSort,
            defaultSortToken: "DESC",
            handleRowClick: handleCustomerRowClick,
            predefinedFilters: predefinedFilters,
          },
        ]}
        states={{
          isLoading: isLoading,
          setLoading: setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: totalCount,
          setTotalCount: setTotalCount,
          setPageSize: setPageSize,
        }}
        toggles={{
          showRowSelect: true,
          showExportBtn: true,
          showCaption: true,
          showNavigation: true,
          showSearchbar: false,
        }}
        isColumnFixed={true}
      />
      {showActivity && (
        <NewActivityPopup
          key={`key-add-activity-${newActivityType}`}
          title={"New Activity"}
          open={showActivity}
          contactOptions={filteredContacts}
          defaultTo={_.isEmpty(primaryContact) ? [companyContactOptions[0]] : primaryContact}
          handleSend={handleSend}
          isTemplateSupport={true}
          supportedTemplateList={supportedTemplateList}
          defaultTitle={defaultTitle}
          editorState={editorState}
          handleSendMarkClosed={handleSendAndMarkClosed}
          onClose={() => {
            setShowActivity(false);
            setEditorState("");
          }}
          activityType={newActivityType}
          setNewActivityType={(type) => {
            setNewActivityType(type);
          }}
          addActivityDropdown={[
            { displayName: "Email", icon: <Email /> },
            { displayName: "Note", icon: <NoteFill /> },
            { displayName: "Phone Call", icon: <Phone /> },
          ]}
          setDefaultTitle={setDefaultTitle}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
        />
      )}
    </>
  );
}
