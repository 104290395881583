import React from "react";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import "./Arrow.scss";

// Define default Button Props
export interface Props {
  className?: string;
  isFlipped?: boolean;
  borderWidth?: number;
  direction: "right" | "left" | "center" | "up" | "down";
}

// Define Button with icon React Element
export default function Arrow(props: Props): React.ReactElement {
  return (
    <div
      style={{ borderWidth: `${props.borderWidth ? props.borderWidth / DEFAULT_NUMERIC_VALUES.DEFAULT_REM_CONVERSION_VALUE + "rem" : "0.5rem"}` }}
      className={`arrow-${props.direction}${props.isFlipped ? "-flipped" : ""} ${props.className ?? ""}`}
    ></div>
  );
}
