import React, { useState, MouseEvent, ReactElement, useEffect, SetStateAction, Dispatch } from "react";
import Button from "../Button/Button";
import ActivityTo from "./ActivityTo/ActivityTo";
import ActivityTitle from "./ActivityTitle/ActivityTitle";
import ActivityBody from "./ActivityBody/ActivityBody";
import CloseIcon from "@mui/icons-material/Close";
import "./NewActivityPopup.scss";
import { attachmentsClientV2, userAccountsClientV2 } from "../../../db/version2Accessor";
import { AlertContext } from "../../../contexts/AlertContext";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import Loading from "../../library/Loading/Loading";
import DropMenu from "../../library/DropMenu/DropMenu";
import Menu from "../../library/Menu/Menu";
import MenuItem from "../../library/MenuItem/MenuItem";
import ActivityTemplateSelect, { TemplateType } from "./ActivityTemplateSelect/ActivityTemplateSelect";
import _ from "underscore";
import { TemplateAttachDetail } from "../../../contexts/TemplateContext";
import TimeIcon from "./ActivityBody/CustomButtons/TimeIcon";
import { VIEW } from "./Attachments/AttachmentConstants";
import { TemplateTypes } from "../../../app/Templates/TemplateTypes";
import DOMPurify from "dompurify";
import { Reply } from "../../library/Icons/Icons";
import * as constants from "../../../constants/config";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import { MENTION_REGEX_CONSTANTS } from "../../../constants/RegexConstants";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { ActivityOptions } from "../../../types/enums";

export type ActivityToRef = {
  onSelectTo: (contacts: To[]) => void;
} | null;

export type ActivityPopupRef = {
  refreshPopupContent: () => void;
} | null;

type MentionsDataItemType = {
  id: number;
  name?: string;
  email_address?: string;
  active_at?: string;
  user_role?: string;
};

type MentionMembersType = {
  loading: boolean;
  members: Array<MentionsDataItemType>;
};

export interface NewActivityPopupProps {
  title?: string | null;
  open?: boolean;
  defaultTo?: To[];
  defaultCc?: To[];
  defaultBcc?: To[];
  defaultTitle?: string;
  defaultBody?: string;
  defaultFiles?: (File | string)[];
  replyOptions?: { replyButton: boolean; start: string; reply: To[]; replyAll: To[]; replyAllCC: To[] };
  forwarding?: boolean;
  disableTitle?: boolean;
  handleSend?: (to: To[], cc: To[], bcc: To[], title: string, body: string, attachmentIds: AttachmentIds, inboxAttachments: any) => void;
  handleSendMarkClosed?: (to: To[], cc: To[], bcc: To[], title: string, body: string, attachmentIds: AttachmentIds, inboxAttachments: any) => void;
  onClose?: () => void;
  contactOptions?: To[];
  activityType?: string | null;
  setNewActivityType?: (value: any) => void;
  addActivityDropdown?: ActivityDropdownItem[];
  editorState?: any;
  buttonProps?: {
    primaryActionLabel?: string;
    secondaryActionLabel?: string;
  } | null;
  typeAheadAction?: (val: string) => void;
  setDefaultTitle?: (value: any) => void;
  isTemplateSupport?: boolean;
  supportedTemplateList?: TemplateType[];
  defaultTemplateId?: TemplateTypes | string;
  prepareActivityBodyByTemplateID?: (templateID: string | null, setEditorState: React.Dispatch<React.SetStateAction<string>>, cb: any) => void;
  clickDisabled?: boolean;
  disableSecondaryAction?: boolean;
  loading?: boolean;
  fromTime?: number;
  toTime?: number;
  setToTime?: Dispatch<SetStateAction<number>>;
  defaultAttachmentsList?: AttachmentItem[];
  refs?: {
    toRef?: React.MutableRefObject<ActivityToRef>;
    popupRef?: React.MutableRefObject<ActivityPopupRef>;
  };
}

export type ActivityDropdownItem = {
  displayName: string;
  icon: ReactElement;
};

export default function NewActivityPopup(props: NewActivityPopupProps): React.ReactElement {
  const newActivityTypings = new Map();
  newActivityTypings.set("Reply", props?.replyOptions?.reply);
  newActivityTypings.set("Reply All", props?.replyOptions?.replyAll);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const replyButtonStateMappings = new Map();
  replyButtonStateMappings.set("Reply", "Reply All");
  replyButtonStateMappings.set("Reply All", "Reply");
  const [to, setTo] = React.useState<To[]>(props.defaultTo ?? []);
  const [cc, setCc] = React.useState<To[]>(props.defaultCc ?? []);
  const [bcc, setBcc] = React.useState<To[]>(props.defaultBcc ?? []);
  // const [toOption, setToOption] = React.useState<string>(replyButtonStateMappings.get(props?.replyOptions?.start) ?? "");
  const [title, setTitle] = React.useState<string>(props.defaultTitle ?? "");
  const [editorState, setEditorState] = React.useState<string>(props?.defaultBody || props.editorState || "");
  const [files, setFiles] = useState<(File | string)[]>(
    props?.defaultFiles ??
      (props?.defaultAttachmentsList ?? []).map((attachment: AttachmentItem) => attachment.file_name ?? "").filter((element) => element !== "") ??
      []
  );
  const [filesChanged, setFilesChanged] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [filesSelected, setFilesSelected] = useState<boolean>(false);
  const [ccVisible, setCcVisible] = useState<boolean>(props.defaultCc && props.defaultCc.length ? true : false);
  const [bccVisible, setBccVisible] = useState<boolean>(props.defaultBcc && props.defaultBcc.length ? true : false);
  const [attachmentIds, setAttachmentIds] = useState<(number | UUID)[]>(props?.defaultAttachmentsList?.map((item) => item.id) ?? []);
  const [companyAttachments, setCompanyAttachments] = useState<any>();
  const [isUploading, setUploading] = useState<boolean>(false);
  const [defaultPrimaryActionLabel, setDefaultPrimaryActionLabel] = React.useState<string>("Send");
  const [defaultSecondaryActionLabel, setDefaultSecondaryActionLabel] = React.useState<string>("Send & Close");
  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const dropdownMenuOpen = Boolean(dropdownMenuAnchorEl);
  const [selectedTemplateID, setSelectedTemplateID] = useState<string | null>(null);
  const [toOption, setToOption] = useState<string>(replyButtonStateMappings.get(props?.replyOptions?.start) ?? "");

  const [contentHasMagicLink, setContentHasMagicLink] = React.useState<boolean>(false);
  const [mentionMembers, setMentionMembers] = React.useState<MentionMembersType>({ loading: false, members: [] });
  const mentionsAvailableFor = Object.freeze(["note", "phone_call", "call_log"]);
  const { selectedWorkspace } = useWorkspaceConfigurations();
  const { signature } = React.useContext(CustomerContext) as CustomerType;

  /**
   *
   * @param attachments : TemplateAttachDetail[]
   * This function is responsible for attaching invoices/payments/documents into the editor.
   * Which will be for viewing of file in the editor and setting up corresponding Id's
   * Note: not responsible to prepare payload for API
   */
  const checkAndAddFiles = (attachments: TemplateAttachDetail[]) => {
    const newFiles = attachments
      .filter((item) => item && attachmentIds.indexOf(item.id) === NUMERIC_VALUES.CONSTANT_NEGATIVE_ONE)
      .map((item) => item.name);
    setFilesSelected(newFiles.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setFiles([...files, ...newFiles]);
    setAttachmentIds([
      ...attachmentIds,
      ...attachments.filter((item) => item && attachmentIds.indexOf(item.id) === NUMERIC_VALUES.CONSTANT_NEGATIVE_ONE).map((item) => item.id),
    ]);
  };

  /**
   * This function takes in array of items selected from the new
   * attachment modal and attaches them to the activity pop-up
   *
   * @param selection files selected from the new attachment modal
   */
  const addCompanyAttachments = (selection: InboxAttachment[]) => {
    // invoices and payments are attached as inbox attachments
    // documents are stored as normal attachments as they have
    // numeric v2 ids
    setCompanyAttachments(
      selection
        .filter((item) => item.transaction_type !== VIEW.DOCUMENTS)
        .map((item) => ({
          transaction_id: item.transaction_id,
          transaction_type: item.transaction_type.toLocaleLowerCase(),
        }))
    );
    checkAndAddFiles(
      selection.map((item) => ({
        name: item.attachment_title,
        id: item.transaction_id,
      }))
    );
  };

  const setActivityTitle = (value: string) => {
    setTitle(DOMPurify.sanitize(value));
  };

  React.useEffect(() => {
    if (["phone_call", "note"].includes(props.activityType ?? "")) {
      setDefaultPrimaryActionLabel("Save");
      setDefaultSecondaryActionLabel("Save & Close");
      props.setToTime?.(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    } else {
      setDefaultPrimaryActionLabel("Send");
      setDefaultSecondaryActionLabel("Send & Close");
    }
  }, [props.activityType]);

  useEffect(() => {
    // Update the email body when user has selected template from subject dropdown.
    if (props.prepareActivityBodyByTemplateID) {
      props.prepareActivityBodyByTemplateID(selectedTemplateID, setEditorState, checkAndAddFiles);
    }
  }, [selectedTemplateID]);

  /**
   * attach event hooks for activity pop up ref
   */
  React.useImperativeHandle(props.refs?.popupRef, () => ({
    refreshPopupContent() {
      props.prepareActivityBodyByTemplateID?.(selectedTemplateID, setEditorState, checkAndAddFiles);
    },
  }));
  /**
   * This function toggles cc, bcc based on the action
   * selected by the user. It requires replyOptions
   * props to be able to understand what action is performed.
   *
   * Applicable actions: reply, reply-all and forward
   */
  const toggleCC = () => {
    if (props?.replyOptions) {
      if (toOption === "Reply") {
        // set reply all and cc
        setCc(props?.replyOptions?.replyAllCC ?? []);
        setTo(props?.replyOptions?.replyAll ?? []);
        setCcVisible(true);
      } else {
        // reset reply and cc
        setCc([]);
        setBcc([]);
        setTo(props?.replyOptions?.reply ?? []);
        setCcVisible(false);
        setBccVisible(false);
      }
    }
  };

  const handleReplyButtonClick = () => {
    setTo(newActivityTypings.get(toOption) ?? []);
    setToOption(replyButtonStateMappings.get(toOption) ?? "");
    if (toOption === "Reply All") {
      setCcVisible(true);
      setCc(props?.replyOptions?.replyAllCC ?? []);
      setTo(props?.replyOptions?.replyAll ?? []);
    } else {
      setCc([]);
      setBcc([]);
      setCcVisible(false);
      setBccVisible(false);
    }
  };

  const handleSetTo = (e: React.SyntheticEvent, value: To[]) => {
    value.forEach((val: string | To, index, value) => {
      if (typeof val === "string") {
        value[index] = { id: "", label: val.replace(/\s+/g, "") };
      }
    });
    setTo(value);

    // trigger component to rerender the template
    if (props.refs?.toRef) {
      props.refs?.toRef?.current?.onSelectTo(value);
    }
  };

  const handleSetCc = (e: React.SyntheticEvent, value: To[]) => {
    value.forEach((val: string | To, index, value) => {
      if (typeof val === "string") {
        value[index] = { id: "", label: val.replace(/\s+/g, "") };
      }
    });
    setCc(value);
  };

  const handleSetBcc = (e: React.SyntheticEvent, value: To[]) => {
    value.forEach((val: string | To, index, value) => {
      if (typeof val === "string") {
        value[index] = { id: "", label: val.replace(/\s+/g, "") };
      }
    });
    setBcc(value);
  };

  const handleUpload = async (file: File | string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("workspace_id", selectedWorkspace?.id?.toString());
    const res = await attachmentsClientV2.postAttachment(formData).catch((err) => err);
    return res;
  };

  const handleSingleFileUpload = async (newFile: File) => {
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: "File uploaded successfully",
    };
    let attachmentId;
    await Promise.resolve(handleUpload(newFile))
      .then((response) => {
        if (response.success) {
          setAttachmentIds([...attachmentIds, response.data.id]);
        } else {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: "File upload failed",
          };
          setFiles(files.filter((value) => value != newFile));
        }
      })
      .finally(() => {
        setToastOptions(toastOptions);
      });
    return attachmentId;
  };

  const handleMultipleFileUpload = async (newFilesList: File[]) => {
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: "File uploaded successfully",
    };
    let newAttachmentIds: number[] = [DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO];
    await Promise.all(newFilesList.map((file: File) => handleUpload(file)))
      .then((values: any[]) => {
        if (values.every((value) => value.success)) {
          newAttachmentIds = [...values.map((value) => value.data.id)];
        } else {
          const failedFileNames: string[] = [];
          const uploadedFiles = [
            ...newFilesList.filter((file: File, index: number) => {
              if (values[index].success) {
                newAttachmentIds.push(values[index].data.id);
                return file;
              } else {
                failedFileNames.push(file.name);
              }
            }),
          ];
          setFiles([...files, ...uploadedFiles]);
          toastOptions = { ...toastOptions, severity: "error", message: `File upload failed` };
        }
      })
      .finally(() => {
        setAttachmentIds([...attachmentIds, ...newAttachmentIds]);
        setToastOptions(toastOptions);
      });
    return newAttachmentIds;
  };

  /**
   * function uploads single/multiple files to the server and updates the attachmentIds
   *
   * @param newFiles list of files to upload and attach with activity stream
   */
  const handleFileSelect = async (newFiles: FileList) => {
    const newFilesList = Array.from(newFiles);
    setFiles([...files, ...newFilesList]);
    setFilesSelected(newFiles.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setFilesChanged(newFiles.length);
    setUploading(true);
    if (newFilesList.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      await handleSingleFileUpload(newFilesList[0]);
    } else {
      await handleMultipleFileUpload(newFilesList);
    }
    setUploading(false);
  };

  const handleFileRemove = (index: number) => {
    const newFiles = _.clone(files);
    const newAttach = _.clone(attachmentIds);
    // if anything selected from company records, remove those
    if (companyAttachments) {
      const removedAttachId = attachmentIds[index];
      setCompanyAttachments([...companyAttachments.filter((attach: any) => attach && attach.transaction_id !== removedAttachId)]);
    }
    newAttach.splice(index, DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
    setAttachmentIds(newAttach);
    newFiles.splice(index, DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
    setFiles(newFiles);
    setFilesSelected(newFiles.length !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setFilesChanged(newFiles.length);
  };

  const handleCcVisibility = () => {
    setCcVisible(true);
  };

  const handleBccVisibility = () => {
    setBccVisible(true);
  };

  const typeAheadActionHandler = (val: string) => {
    if (props.typeAheadAction) {
      props.typeAheadAction(val);
    }
  };

  /**
   * @ mention tag replacer
   * @returns editorState with body removing move markers and replaces all tags with backend friendly format [@John K](mention://user/11/John%20K)
   */
  const replaceTagsWithMentions = () => {
    if (props.activityType === "phone_call" || props.activityType === "call_log" || props.activityType === "note") {
      let newState = editorState;
      let allMentions: Array<MentionsDataItemType> = [];
      const listOfMentions = newState.match(MENTION_REGEX_CONSTANTS.REGEX_REPLACE_MENTION);
      if (listOfMentions) {
        const listOfAllMentions = listOfMentions.map((item) => item.replace(`<span style="color:#2D9DE7;">@`, "").replace(`</span>`, ""));
        allMentions = mentionMembers.members.filter((item) => listOfAllMentions?.indexOf(item.name ?? "") !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE);
      }
      allMentions.forEach((item) => {
        newState = newState.replaceAll(
          `<span style="color:#2D9DE7;">@${item.name}</span>`,
          `[@${item.name}](mention://user/${item.id}/${encodeURIComponent(item.name ?? "")})`
        );
      });
      return newState.replaceAll(`<span id="move-marker-here"></span>`, "");
    }
    return editorState.replaceAll(`<span id="move-marker-here"></span>`, "");
  };

  /**
   * checks the editor state on every update for magic link snippet
   * if snippet is not matched, editor enables the 'Insert Magic Link'
   * button on the activity popup actions
   */
  useEffect(() => {
    setContentHasMagicLink(editorState.includes(constants.MAGIC_LINK_SNIPPET));
  }, [editorState]);

  const isCcApplicable = props.activityType !== "phone_call" && props.activityType !== "approval_request";
  const isBccApplicable = props.activityType !== "phone_call" && props.activityType !== "approval_request";

  useEffect(() => {
    toggleCC();
  }, [toOption]);

  useEffect(() => {
    setMentionMembers({ loading: true, members: [] });
    userAccountsClientV2
      .getAllUserAccounts(selectedWorkspace?.id)
      .then((res) => {
        setMentionMembers({ loading: false, members: res.data });
      })
      .catch((err) => {
        console.log("error", err);
        setMentionMembers({ loading: false, members: [] });
      });
  }, [selectedWorkspace?.id]);

  /**
   * Setup user's signature into activity popup body
   * If activity type is email or approval request and has no template selected
   * then simply append the signature from user context at once.
   */
  useEffect(() => {
    if (
      (!_.isEmpty(signature) &&
        editorState.length == DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
        (props.activityType === "email" || props.activityType === "approval_request")) ||
      props?.forwarding
    ) {
      setEditorState(editorState + `<p></p>` + signature?.email_signature ?? "");
    }
  }, []);

  return (
    <div className={`na-wrapper${props.open ? "" : "-closed"}`}>
      <div className={"na-header"}>
        <p className={"na-header-title-text"}>{props.title}</p>
        <div
          className={"na-header-close-wrapper"}
          onClick={() => {
            setCcVisible(false);
            setBccVisible(false);
            props?.setToTime?.(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            if (props?.onClose) {
              props.onClose();
            }
            setSelectedTemplateID("");
          }}
        >
          <CloseIcon />
        </div>
      </div>
      {props.loading ? (
        <Loading />
      ) : (
        <>
          <div className={"na-body"}>
            {props?.addActivityDropdown?.length ? (
              <div className={"na-body-to na-body-child"}>
                <div className={`na-body-activity-type-line ${props.clickDisabled ? "disabled" : ""}`}>
                  <DropMenu
                    key={`activity-type-dropdown-${props?.addActivityDropdown?.length}`}
                    title={props.activityType?.split("_").join(" ") || ""}
                    isActive={dropdownMenuOpen}
                    onClick={(e: MouseEvent<HTMLDivElement>) => (props.clickDisabled ? null : setDropdownMenuAnchorEl(e.currentTarget))}
                    iconAlign="right"
                    appendIcon={
                      props.addActivityDropdown && props.addActivityDropdown.length ? (
                        props.addActivityDropdown.filter(
                          (item: ActivityDropdownItem) => item.displayName.toLowerCase().split(" ").join("_") === props.activityType
                        )[0]?.icon
                      ) : (
                        <></>
                      )
                    }
                  />
                  <Menu
                    open={dropdownMenuOpen}
                    anchorEl={dropdownMenuAnchorEl}
                    onClose={() => setDropdownMenuAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    className="activity-types-list-menu"
                  >
                    {props?.addActivityDropdown?.length
                      ? props?.addActivityDropdown.map((tab: ActivityDropdownItem, index: number) => {
                          return (
                            <MenuItem
                              key={index}
                              onClick={() => {
                                if (props?.setNewActivityType) {
                                  props?.setNewActivityType(tab.displayName.toLowerCase().split(" ").join("_"));
                                }
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="icon">{tab.icon}</div>
                                <div>{tab.displayName}</div>
                              </div>
                            </MenuItem>
                          );
                        })
                      : null}
                  </Menu>
                </div>
              </div>
            ) : null}

            {props.activityType !== "note" ? (
              <div className={"na-body-to na-body-child"}>
                <div className={"na-body-toline"}>
                  <div className={"na-body-to-To"}>
                    <p>To</p>
                  </div>
                  <ActivityTo
                    options={props?.contactOptions && props?.contactOptions.length ? props?.contactOptions : []}
                    to={to}
                    setTo={handleSetTo}
                    typeAheadAction={typeAheadActionHandler}
                  />
                  <div className={"na-body-to-extra-recipients"}>
                    {isCcApplicable && !ccVisible ? (
                      <div className={"na-body-to-extra-cc"} onClick={handleCcVisibility}>
                        <p>Cc</p>
                      </div>
                    ) : null}
                    {isBccApplicable && !bccVisible ? (
                      <div className={"na-body-to-extra-bcc"} onClick={handleBccVisibility}>
                        <p>Bcc</p>
                      </div>
                    ) : null}
                  </div>
                </div>
                {props?.replyOptions?.replyButton && (
                  <div className={"na-body-toline-reply"}>
                    <Button icon={<Reply />} onClick={handleReplyButtonClick} alignIcon={"right"} variant={"secondary"}>
                      {toOption}
                    </Button>
                  </div>
                )}
              </div>
            ) : null}

            {ccVisible && (
              <div className={"na-body-to na-body-child"}>
                <div className={"na-body-ccline"}>
                  <div className={"na-body-to-Cc"}>
                    <p>Cc</p>
                  </div>
                  <ActivityTo
                    options={props?.contactOptions && props?.contactOptions.length ? props?.contactOptions : []}
                    to={cc}
                    setTo={handleSetCc}
                    typeAheadAction={typeAheadActionHandler}
                  />
                </div>
              </div>
            )}

            {bccVisible && (
              <div className={"na-body-to na-body-child"}>
                <div className={"na-body-bccline"}>
                  <div className={"na-body-to-Bcc"}>
                    <p>Bcc</p>
                  </div>
                  <ActivityTo
                    options={props?.contactOptions && props?.contactOptions.length ? props?.contactOptions : []}
                    to={bcc}
                    setTo={handleSetBcc}
                    typeAheadAction={typeAheadActionHandler}
                  />
                </div>
              </div>
            )}

            {((props.activityType === ActivityOptions.EMAIL && to?.[0]?.erpContact) ||
              props.activityType === ActivityOptions.APPROVAL_REQUEST ||
              props.activityType === ActivityOptions.ONBOARD_VENDOR) &&
            props.isTemplateSupport ? (
              <div className={"na-body-title na-body-child na-body-template-selector"}>
                <ActivityTemplateSelect
                  templateList={props.supportedTemplateList as TemplateType[]}
                  onSelectTemplate={setActivityTitle}
                  setSelectedTemplateID={setSelectedTemplateID}
                  defaultTemplateType={props.defaultTitle}
                  defaultTemplateId={props.defaultTemplateId}
                />
              </div>
            ) : (
              <div className={"na-body-title na-body-child"}>
                <ActivityTitle title={title ?? ""} handleOnChange={setActivityTitle} isDisabled={props.disableTitle} />
              </div>
            )}
            <div className={"hyperlink-modal-container"}>{/* Insert hyperlink edit modal here*/}</div>
            <div className={"hyperlink-edit-modal-container"}>{/* Insert hyperlink edit modal here*/}</div>
            {isUploading ? (
              <Loading />
            ) : (
              <div className={"na-body-message na-body-child"}>
                <ActivityBody
                  key={`body${files.length}`}
                  forwarding={props.forwarding}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  files={files}
                  handleFileSelect={handleFileSelect}
                  onClickAddAttachments={addCompanyAttachments}
                  filesChanged={filesChanged}
                  filesSelected={filesSelected}
                  handleFileRemove={handleFileRemove}
                  isCC={ccVisible}
                  isBCC={bccVisible}
                  to={to} // connection id of the first contact's in the to list
                  addMagicLinkSnippet={contentHasMagicLink}
                  enableMentions={mentionsAvailableFor.includes(props.activityType ?? "")}
                  mentionMembers={mentionMembers}
                />

                <div className={"na-body-message-send"}>
                  <div className={"na-body-message-send-button"}>
                    {!_.isUndefined(props.toTime) &&
                      props.toTime > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
                      (props.activityType === ActivityOptions.EMAIL ||
                        props.activityType === ActivityOptions.APPROVAL_REQUEST ||
                        props.activityType === ActivityOptions.ONBOARD_VENDOR) && (
                        <TimeIcon index={0} timeFrom={props.fromTime} timeTo={props.toTime} />
                      )}
                    {selectedWorkspace?.workspace_type_route !== "ap" && props.activityType === "email" && (
                      <Button
                        variant="solo-icon"
                        size="sm"
                        disabled={!to?.[0]?.erpContact || contentHasMagicLink}
                        onClick={() => setContentHasMagicLink(true)}
                        tooltip={!to?.[0]?.erpContact ? "The Self Service portal not supported for email generated connections" : ""}
                      >
                        {"Insert Magic Link"}
                      </Button>
                    )}
                    {!props.disableSecondaryAction && (
                      <Button
                        disabled={isUploading}
                        variant={"secondary"}
                        onClick={() => {
                          props.handleSendMarkClosed &&
                            props.handleSendMarkClosed(to, cc, bcc, title, replaceTagsWithMentions(), attachmentIds, companyAttachments);
                        }}
                      >
                        {props?.buttonProps?.secondaryActionLabel ?? defaultSecondaryActionLabel}
                      </Button>
                    )}

                    <Button
                      variant={"primary"}
                      disabled={isUploading}
                      onClick={() => {
                        if (props?.handleSend) {
                          props.handleSend(to, cc, bcc, title, replaceTagsWithMentions(), attachmentIds, companyAttachments);
                        }
                      }}
                    >
                      {props?.buttonProps?.primaryActionLabel ?? defaultPrimaryActionLabel}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
