import APIClient from "../APIClient";

export class AuthClient {
  BASE_URL = "inbox/api/magic_links";

  getToken(id: UUID): Promise<APIResponse> {
    const url = `${this.BASE_URL}/${id}/token`;
    return APIClient.get(url, undefined, true, false, false).then((res) => res.data);
  }
}
