import React from "react";
import { SecondaryActivityProps, SecondaryType } from "./SecondaryActivityTypes";
import AccordionActivity from "./AccordionActivity/AccordionActivity";
import BaseActivity from "./BaseActivity/BaseActivity";

const SecondaryActivity: React.FC<SecondaryActivityProps> = ({ variant, creator, children }) => {
  switch (variant) {
    case SecondaryType.Base:
      return <BaseActivity creator={creator} />;
    case SecondaryType.Accordion:
      return <AccordionActivity creator={creator}>{children}</AccordionActivity>;
  }
};

export default SecondaryActivity;
