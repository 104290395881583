import APIClient from "../APIClient";

export class ActivitiesClient {
  /**
   * * GET on /api/v1/Activities/{id}
   * @param id - The unique Lockstep Platform ID number of this Activity
   * @param include - To fetch additional data on this object, specify the list of elements to retrieve.
   * @returns Promise of type ActivityModel or Exception
   */
  get(id: string, include?: string): Promise<ActivityModel> {
    const url = `api/v1/Activities/${id}`;
    const options = {
      params: {
        include,
      },
    };
    return APIClient.get(url, options).then((res) => res.data);
  }

  /**
   * * PATCH on /api/v1/Activities/{id}
   * @param id - The unique Lockstep Platform ID number of this Activity
   * @returns Promise of type ActivityModel or Exception
   */
  patch(id: string, body: Partial<ActivityModel>): Promise<ActivityModel> {
    const url = `api/v1/Activities/${id}`;
    return APIClient.patch(url, body).then((res) => res.data);
  }

  /**
   * * DELETE on /api/v1/Activities/{id}
   * @param id - The unique Lockstep Platform ID number of this Activity
   * @returns Promise of type ActivityModel or Exception
   */
  delete(id: string): Promise<ActivityModel> {
    const url = `api/v1/Activities/${id}`;
    return APIClient.delete(url).then((res) => res.data);
  }

  /**
   * * POST on /api/v1/Activities
   * @param body - Array of ActivityModels to be posted
   * @returns Promise of type ActivityModel[] or Exception
   */
  post(body: Partial<ActivityModel>[]): Promise<ActivityModel[]> {
    const url = "api/v1/Activities";
    return APIClient.post(url, body).then((res) => res.data);
  }

  /**
   * * GET on /api/v1/Activities/query
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - Specify options
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type ActivityModelFetchResult or Exception
   */
  query(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<ActivityModelFetchResult> {
    const url = "api/v1/Activities/query";
    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };
    return APIClient.get(url, options).then((res) => res.data);
  }
  /**
   * GET on /api/v1/Activities/{id}/stream
   * @param id - The id of the activity to retrieve the stream for
   * @returns Promise of type ActivityStreamItem[] or Exception
   */
  getStream(id: string): Promise<ActivityStreamItem[]> {
    const url = `api/v1/Activities/${id}/stream`;
    return APIClient.get(url).then((res) => res.data);
  }
  /**
   * * POST on /api/v1/Activities/{activityId}/forward/{userId}
   * @param activityId - Id of the activity to forward
   * @param userId - Id of the user to forward the activity to
   * @returns Promise of type ActivityModel or Exception
   */
  forward(activityId: string, userId: string): Promise<ActivityModel> {
    const url = `api/v1/Activities/${activityId}/forward/${userId}`;
    return APIClient.post(url).then((res) => res.data);
  }
}
