import React, { useState, useEffect } from "react";
import { featureFlagClient } from "../db/accessor";

/**
 * @constant FeatureFlags
 * A react context to consume FeatureFlagsProvider api
 */
export const FeatureFlagContext = React.createContext<FeatureFlagProviderType | null>(null);

export type Props = {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
};

/**
 * @constant featureFlagsMap
 * An object to hold the feature flag key value mapping.
 */
const featureFlagsMap = {
  FORWARD_AP_AUTOMATION: "Inbox.ForwardToAPAutomation",
};

type FEATURE_FLAGS = keyof typeof featureFlagsMap;

export type FeatureFlagProviderType = {
  isEnabled: (FLAG_NAME: FEATURE_FLAGS) => boolean;
};

/**
 * @function FeatureFlagsProvider
 * A react function to provide FeatureFlags context
 * @param param
 * @returns
 */
const FeatureFlagsProvider: React.FC<Props> = ({ children }: Props) => {
  const [featureFlags, setFeatureFlags] = useState<{ [key: string]: boolean | undefined }>({});

  /**
   * @function fetchFlag
   * A helper function to fetch a feature flag currently enabled for the user logged in.
   * @param featureFlags
   * @returns
   */
  const fetchFlag = async (featureFlags: string[]) => {
    const response = await featureFlagClient.fetchFeatureFlags({ names: featureFlags });
    setFeatureFlags(response.values);
  };

  /**
   * @function isEnabled
   * A context hook to check if a feature flag is enabled for the current user
   * @param flag
   * @returns
   */
  const isEnabled = (flag: FEATURE_FLAGS) => {
    return featureFlags[featureFlagsMap[flag]] ?? false;
  };

  useEffect(() => {
    fetchFlag(Object.values(featureFlagsMap));
  }, []);

  return <FeatureFlagContext.Provider value={{ isEnabled }}>{children}</FeatureFlagContext.Provider>;
};

export default FeatureFlagsProvider;
