export enum TemplateTypes {
  INVOICE_COPY = "INVOICE_COPY",
  INVOICE_PAST_DUE_REMINDER = "INVOICE_PAST_DUE_REMINDER",
  INVOICE_SECOND_REMINDER = "INVOICE_SECOND_REMINDER",
  ACCOUNT_PAST_DUE_REMINDER = "ACCOUNT_PAST_DUE_REMINDER",
  ACCOUNT_PAST_DUE_SECOND_REMINDER = "ACCOUNT_PAST_DUE_SECOND_REMINDER",
  REQUEST_FOR_INVOICE_PAYMENT_STATUS = "REQUEST_FOR_INVOICE_PAYMENT_STATUS",
  REQUEST_FOR_ACCOUNT_PAYMENT_STATUS = "REQUEST_FOR_ACCOUNT_PAYMENT_STATUS",
  PAYMENT_CONFIRMATION = "PAYMENT_CONFIRMATION",
  REMITTANCE_REQUEST = "REMITTANCE_REQUEST",
  BANK_INFO = "BANK_INFO",
  AR_TAX_EXEMPT_CERTIFICATE = "AR_TAX_EXEMPT_CERTIFICATE", // Customer
  AP_TAX_EXEMPT_CERTIFICATE = "AP_TAX_EXEMPT_CERTIFICATE", // Vendor.
  W9_FORM = "W9_FORM",

  BILL_RECEIPT_NOTIFICATION = "BILL_RECEIPT_NOTIFICATION",
  REQUEST_COPY_BILL = "REQUEST_COPY_BILL",
  ACTION_REQUIRED_BILL = "ACTION_REQUIRED_BILL",
  BILL_PAYMENT_SCHEDULED = "BILL_PAYMENT_SCHEDULED",
  ACCOUNT_PAYMENT_SCHEDULED = "ACCOUNT_PAYMENT_SCHEDULED",
  PAYMENT_REMITTANCE_INFO = "PAYMENT_REMITTANCE_INFO",
  PAYMENT_APPLICATION_DISCREPANCY = "PAYMENT_APPLICATION_DISCREPANCY",
  PAYMENT_CONFIRMATION_REQUEST = "PAYMENT_CONFIRMATION_REQUEST",
  REQUEST_W9 = "REQUEST_W9",
  REQUEST_BANK_INFO = "REQUEST_BANK_INFO",
  SUPPORTING_DOCUMENTS = "SUPPORTING_DOCUMENTS",

  AR_APPROVAL_REQUEST = "AR_APPROVAL_REQUEST",
  AP_APPROVAL_REQUEST = "AP_APPROVAL_REQUEST",

  AP_VENDOR_PROFILE_REQUEST = "AP_VENDOR_PROFILE_REQUEST",
}

export interface IData {
  customer?: string;
  invoiceNumber?: string;
  invoiceDate?: string;
  paymentDueDate?: string;
  outstandingBalance?: number;
  invoiceAmount?: number;
  emailAddress?: string;
  phone?: string;
  vendor?: string;
  billNumber?: string;
  invoiceInfo?: Record<string, string | number>;
}

export enum viewType {
  AR_DASHBOARD_AGING,
  AR_DASHBOARD_DAYS_SALES_OUTSTANDING,
  AR_DASHBOARD_RECEIVABLES_SUMMARY_INVOICED,
  AR_DASHBOARD_RECEIVABLES_SUMMARY_COLLECTED,
  AR_DASHBOARD_RECEIVABLES_RISK,
  AR_ALL_CUSTOMER_CONNECTIONS,
  AR_TRANSACTION_INVOICES,
  AR_TRANSACTION_INVOICES_CLOSED,
  AR_TRANSACTION_PAYMENTS,
  AR_CONNECTION_CUSTOMER_ACTIVITIES,
  AR_CONNECTION_CUSTOMER_OPEN_INVOICES,
  AR_CONNECTION_CUSTOMER_CLOSE_INVOICES,
  AR_CONNECTION_CUSTOMER_PAYMENTS,
  AP_DASHBOARD_AGING,
  AP_DASHBOARD_SUMMARY_BILLED,
  AP_DASHBOARD_SUMMARY_PAID,
  AP_DASHBOARD_DAYS_PAYABLE_OUTSTANDING,
  AP_DASHBOARD_PAYABLE_COMING_DUE,
  AP_DASHBOARD_PAYABLES_SUMMARY_PAID,
  AP_DASHBOARD_PAYABLES_SUMMARY_BILLED,
  AP_ALL_VENDOR_CONNECTIONS,
  AP_TRANSACTION_BILLS,
  AP_TRANSACTION_BILLS_CLOSED,
  AP_TRANSACTION_PAYMENTS,
  AP_TRANSACTION_ADVANCE_PAYMENTS,
  AP_CONNECTION_VENDOR_ACTIVITY,
  AP_CONNECTION_VENDOR_PROFILE,
  AP_CONNECTION_VENDOR_OPEN_BILLS,
  AP_CONNECTION_VENDOR_CLOSED_BILLS,
  AP_CONNECTION_VENDOR_PAYMENTS,
  AR_APPROVAL_REQUEST_ACTIVITY,
  AP_APPROVAL_REQUEST_ACTIVITY,
  VENDOR_ONBOARD_REQUEST_ACTIVITY,
}
