import APIClient from "../APIClient";

export class IndexClient {
  getIndex(resolvedUrl: string): Promise<any> {
    let url = `inbox/api/v2/`;
    url = url + resolvedUrl;
    return APIClient.get(url).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        records: response.data.data,
      };
    });
  }
}
