import React from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import { ApplicationRouteContext } from "../../contexts/ApplicationRouteContext";
import { CustomerContext } from "../../contexts/CustomerContext";
import useWorkspaceConfigurations from "../../hooks/useWorkspaceConfigurations";
import { ConnectionStatus } from "../../types/enums";
import Navbar from "../library/Navbar/Navbar";

const CustomerNavbar = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { configs } = useWorkspaceConfigurations();
  const { company, customerId } = React.useContext(CustomerContext) as CustomerType;
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const companyName = (company as CustomerDetailsModel)?.name;
  const isOtherConnection = path.includes("connections");

  // set root path for navbar
  const status = history.location.pathname.replace(path, "").split("/")[1];
  const navRootPath = Object.values(ConnectionStatus).includes(status as ConnectionStatus)
    ? `${path}/${status}/${customerId}`
    : `${path}/${customerId}`;
  const dashboardRoutes = isOtherConnection
    ? ["profile", "contacts", "documents"]
    : ["profile", "open_invoices", "open_bills", "closed_invoices", "closed_bills", "payments", "contacts", "documents"];
  const views = [`${path}/active/:customerId`, `${path}/spam/:customerId`, `${path}/fraud/:customerId`, `${path}/archived/:customerId`];
  const navConfigs = configs[isOtherConnection ? "otherConnections" : "connections"];
  /**
   * generates all dashboard paths for different connection status views
   */
  const dashboardPaths = (function generatePaths() {
    let result: string[] = [];
    views.map((view) => {
      result = [...result, ...dashboardRoutes.map((route) => view + "/" + route)];
    });
    return result;
  })();

  return (
    <div className={`customer-head`}>
      <Switch>
        {/* Table Nav Bar */}
        <Route
          exact
          path={[`${path}/active`, `${path}/archived`, `${path}/spam`, `${path}/fraud`]}
          render={() => {
            return <Navbar tabs={navConfigs.routes.main} rootPath={path} />;
          }}
        />
        {/* Adds backroute to the table */}
        <Route
          exact
          path={[...views, ...dashboardPaths]}
          component={() => {
            const lastPage = sessionStorage.getItem("lastPage");
            const pageSize = sessionStorage.getItem("PageSize");
            return (
              <Navbar
                tabs={navConfigs.routes.dashboard}
                rootPath={navRootPath}
                dropdown={[{ displayName: companyName ?? "N/A", routeName: "" }]}
                dropdownDefault={companyName ?? "N/A"}
                hideDropdown={true}
                handleBackRoute={true}
                backDisplayName={`${navConfigs.routes.backroutes.customerDetail.displayName}`}
                backRoute={`${path}${navConfigs.routes.backroutes.customerDetail.query}&page=${lastPage}&pageSize=${pageSize ?? "25"}` ?? path}
              />
            );
          }}
        />
        {/* Adds backroute to the customer dashboard */}
        <Route
          path={views}
          component={() => {
            return (
              <Navbar
                tabs={navConfigs.routes.dashboard}
                rootPath={navRootPath}
                dropdown={[{ displayName: companyName ?? "N/A", routeName: "" }]}
                dropdownDefault={companyName ?? "N/A"}
                hideDropdown={true}
                handleBackRoute={true}
                backDisplayName={`${companyName}`}
                backRoute={getBaseRoute()}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default CustomerNavbar;
