import React from "react";
import InvoiceProvider from "../../contexts/InvoiceContext";
import DashboardProvider from "../../contexts/DashboardContext";
import PaymentProvider from "../../contexts/PaymentContext";
import MainContentHead from "./MainContentHead/MainContentHead";
import MainContentSidebar from "./MainContentSidebar/MainContentSidebar";
import SettingsProvider from "../../contexts/SettingsContext";
import ActivityProvider from "../../contexts/ActivityContext";
import "./MainContent.scss";
import ProfileContext from "../../contexts/ProfileContext";
import MainContentView from "./MainContentView";
import { Route, Switch } from "react-router-dom";
import FourOFourError from "../../routes/FourOFourError/FourOFourError";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import PrivateRoute from "../../auth/PrivateRoute";
import Settings from "../Settings/SettingsRoot/Settings";
import Profiles from "../Dashboard/Profiles/Profiles";
import DocumentSwitchContextProvider from "../../contexts/DocumentSwitchContext";
import MetaProvider from "../../contexts/MetaContext";

const MainContent = (): React.ReactElement => {
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;

  return (
    <DocumentSwitchContextProvider>
      <SettingsProvider>
        <ProfileContext>
          <MetaProvider>
            <div className="uc-wrapper">
              <div className={`head ${selectedWorkspace?.workspace_type_route}-theme-content`}>
                <MainContentHead />
              </div>
              <div className="body">
                <div className={`left ${selectedWorkspace?.workspace_type_route}-theme-content`}>
                  <MainContentSidebar />
                </div>
                <div className="right">
                  <div className="content">
                    <DashboardProvider>
                      <ActivityProvider>
                        <InvoiceProvider>
                          <PaymentProvider>
                            <Switch>
                              {/* Can be used on config array as well */}
                              <Route path={`/AR/${selectedWorkspace?.id?.toString()}`} component={MainContentView} />
                              {/* AP Routes */}
                              <Route path={`/AP/${selectedWorkspace?.id?.toString()}`} component={MainContentView} />
                              <PrivateRoute
                                path={`/settings`}
                                component={() => {
                                  return <Settings />;
                                }}
                              />
                              <PrivateRoute
                                path={`/profiles`}
                                component={() => {
                                  return <Profiles />;
                                }}
                              />
                              <PrivateRoute
                                component={() => {
                                  return <FourOFourError />;
                                }}
                              />
                            </Switch>
                          </PaymentProvider>
                        </InvoiceProvider>
                      </ActivityProvider>
                    </DashboardProvider>
                  </div>
                </div>
              </div>
            </div>
          </MetaProvider>
        </ProfileContext>
      </SettingsProvider>
    </DocumentSwitchContextProvider>
  );
};

export default MainContent;
