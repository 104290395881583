import React from "react";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { AlertContext } from "../../../../contexts/AlertContext";
import { CustomerMergeComponent } from "./CustomerMergeComponent";
import { CustomerMergeContainerProps } from "../../CustomerInterfaces/CustomerMergeInterface";
import { companiesClientV2 } from "../../../../db/version2Accessor";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { FallbackTypes } from "../../../../types/enums";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { Modal } from "../../../library/DialogModal";

const CustomerMergeContainer = (props: CustomerMergeContainerProps) => {
  const [selectedCompany, setSelectedCompany] = React.useState<Partial<CompanyModel> | null>(null);
  const [isLoadingCompanyList, setLoadingCompanyList] = React.useState<boolean>(true);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const [openMergeDialog, setOpenMergeDialog] = React.useState<boolean>(false);

  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { toggleConnectionUpdate } = React.useContext(CustomerContext) as CustomerType;

  const onClickMerge = async () => {
    setShowLoader(true);
    let toastOptions: ToastOptions = { severity: "error", message: "Something Went Wrong", open: true };

    const createMessage = (actionTaken: string, noOfRecords: number) => {
      return noOfRecords > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? `${noOfRecords} contacts ${actionTaken} to ${selectedCompany?.companyName} successfully`
        : `Contact is ${actionTaken} to ${selectedCompany?.companyName} successfully`;
    };

    try {
      await companiesClientV2.mergeConnections(selectedCompany?.companyId ?? "", [...props.connectionIds], selectedWorkspace.id || FallbackTypes.Id);
      toastOptions = { severity: "success", message: createMessage("merged", props.connectionIds.length), open: true };
      // refresh table & side bar
      props.softRefresh?.();
      toggleConnectionUpdate();
    } catch (error) {
      console.error("error: ", error);
    } finally {
      setShowLoader(false);
      setToastOptions(toastOptions);
      setOpenMergeDialog(false);
      // reset states
      props.setOpen(false);
      props.setConnectionIds([]);
    }
  };

  const onClickCancel = () => {
    setOpenMergeDialog(false);
    props.setConnectionIds([]);
    props.setOpen(false);
  };

  const onClickClose = () => {
    props.setOpen(false);
    setSelectedCompany(null);
    setLoadingCompanyList(false);
  };

  return (
    <>
      <CustomerMergeComponent
        companyList={props.companyList.filter((company) => !props.connectionIds?.includes(company.companyId ?? ""))}
        companyListDropdownLoader={isLoadingCompanyList}
        setSelectedCompany={setSelectedCompany}
        onClose={onClickClose}
        open={props.open}
        isLoading={showLoader}
        setOpenDialog={setOpenMergeDialog}
        disableMerge={selectedCompany === null}
      />

      <Modal
        modalStates={{
          open: openMergeDialog,
          onCloseFunction: () => setOpenMergeDialog(false),
        }}
        title={{
          label: "Merge these connections?",
        }}
        content={{
          label: "The activity streams, contacts, documents will be all merged into the connection and cannot be undone.",
        }}
        secondaryButton={{
          enable: true,
          type: "button",
          variant: "primary",
          label: "Merge",
          loader: showLoader,
          onClickHandler: onClickMerge,
        }}
        primaryButton={{
          enable: true,
          type: "button",
          variant: "grey",
          label: "Cancel",
          onClickHandler: onClickCancel,
        }}
      />
    </>
  );
};

export default CustomerMergeContainer;
