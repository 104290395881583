import React, { useEffect } from "react";
import { Switch, useRouteMatch, Route, useParams } from "react-router-dom";
import { default as CustomerProfile } from "../../Customers/CustomerDetail/Profile/Profile";
import { default as CustomerHeader } from "../../Customers/CustomerDetail/Header/Header";
import Details from "./Detail/Details";
import ContactDetail from "../../Customers/CustomerDetail/Contacts/ContactDetail/ContactDetail";
import { PaymentContext } from "../../../contexts/PaymentContext";
import { CustomerContext } from "../../../contexts/CustomerContext";
import FourOFourError from "../../../routes/FourOFourError/FourOFourError";
import ActivityDetail from "../../Activities/ActivityDetail/ActivityDetail";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import Loading from "../../library/Loading/Loading";
import InvoiceDetail from "../../Invoices/InvoiceDetail/InvoiceDetail";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";

export default function PaymentDetail(): React.ReactElement {
  const { paymentId } = useParams<{ paymentId: string }>();
  const { path } = useRouteMatch();
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const { isDetailViewLoading, setPaymentDetailViewData, getDetailViewInvoices, setPaymentDetailViewInvoices, getDetailView, setDetailViewLoading } =
    React.useContext(PaymentContext) as PaymentType;
  const { getCompanyDetails } = React.useContext(CustomerContext) as CustomerType;
  const { configs } = useWorkspaceConfigurations();

  const fetchPaymentsDetailViewData = async () => {
    let response = {} as PaymentDetailModel;
    try {
      setDetailViewLoading(true);
      response = await getDetailView(`PaymentId eq ${paymentId}`);
      if (!response) throw { message: "ID doesn't exists" };
      getCompanyDetails(response.customerId);
      const invoices = await getDetailViewInvoices(`PaymentId eq ${paymentId}`, "invoice");
      const mappedResponse = invoices.map((element) => {
        return {
          invoiceId: element.invoiceId,
          referenceCode: element.invoice?.referenceCode ?? "N/A",
          paymentAppliedAmount: element.paymentAppliedAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          outstandingBalanceAmount: element.invoice?.outstandingBalanceAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          invoiceStatusCode: element.invoice?.invoiceStatusCode ?? "N/A",
          paymentDueDate: element.invoice?.paymentDueDate,
        };
      });
      setPaymentDetailViewInvoices(mappedResponse);
      setPaymentDetailViewData(response);
    } catch (error: any) {
      setErrorMessage(error.message);
      console.error("Error Fetching Payment Details", error);
    } finally {
      setDetailViewLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentsDetailViewData();
  }, [paymentId]);

  return isDetailViewLoading ? (
    <Loading isRelative />
  ) : (
    <Switch>
      <Route
        exact
        path={[`${path}`, `${path}/payments/:paymentId`]}
        render={() => {
          return <Details errorMessage={errorMessage} configs={configs.payments} />;
        }}
      />
      <Route
        exact
        path={[`${path}`, `${path}/${configs.payments.routes.resource.invoices}/:invoiceId`]}
        render={() => {
          return <InvoiceDetail />;
        }}
      />
      <Route
        exact
        path={`${path}/activities/:activityId`}
        component={() => {
          return <ActivityDetail />;
        }}
      />
      <Route
        exact
        path={`${path}/${configs.payments.routes.resource.customer}/active/:customerId`}
        render={() => {
          return (
            <div className="customer-detail-wrapper">
              <div className="head">
                <CustomerHeader />
              </div>
              <div className="body">
                <CustomerProfile />
              </div>
            </div>
          );
        }}
      />
      <Route
        exact
        path={`${path}/contactProfile/:contactId`}
        component={() => {
          return (
            <div>
              <ContactDetail />
            </div>
          );
        }}
      />
      <Route
        component={() => {
          return <FourOFourError />;
        }}
      />
    </Switch>
  );
}
