import APIClient from "../APIClient";

export class FeatureFlagClient {
  /**
   * * POST on /api/v1/feature-flags
   * To call this endpoint, you must have one of these roles:
   * Group Owner
   * Group Admin
   * Member
   * Read-Only
   * @returns Promise of type FeatureFlagsResponseModel or Exception
   */
  fetchFeatureFlags(body: FeatureFlagsRequestModel): Promise<FeatureFlagsResponseModel> {
    const url = "api/v1/feature-flags";

    return APIClient.post(url, body).then((response) => {
      return response.data;
    });
  }
}
