import { Drawer, TextField } from "@mui/material";
import React from "react";
import Autocomplete from "../../../../../library/Autocomplete/Autocomplete";
import Button from "../../../../../library/Button/Button";
import "./ContactMoveComponent.scss";

type ContactMoveComponentProps = {
  ids: string[];
  handleMove: (e: React.MouseEvent<HTMLButtonElement>) => void;
  companyList: Partial<CompanyModel>[];
  isLoadingCompanyList: boolean;
  setSelectedCompany: React.Dispatch<React.SetStateAction<Partial<CompanyModel> | null>>;
  onClose: () => void;
  open: boolean;
  isLoading: boolean;
};

const renderOptions = (props: React.HTMLAttributes<HTMLLIElement>, option: Partial<CompanyModel>) => {
  return (
    <li {...props} key={option.companyId}>
      {option.companyName}
    </li>
  );
};

export const ContactMoveComponent = (props: ContactMoveComponentProps) => {
  return (
    <Drawer className="activity-feed-action-drawer" open={props.open} anchor="right" disablePortal onClose={() => props.onClose()}>
      <div className="activity-flyout">
        <h3 className="header">Move Contact</h3>
        <p className="subheader body1">Move this contact to another connection.</p>
        <label htmlFor={"string"}>Connection</label>
        <div>
          <Autocomplete
            options={props.companyList}
            loading={props.isLoadingCompanyList}
            onChange={async (_event, value: Partial<CompanyModel>) => {
              props.setSelectedCompany(value);
            }}
            blurOnSelect
            disableClearable
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Choose A Connection",
                  disableUnderline: true,
                }}
              />
            )}
            getOptionLabel={(option: Partial<CompanyModel>) => option.companyName ?? "N/A"}
            renderOption={renderOptions}
          />
        </div>
        <div className="btn-grp">
          <Button className={"move-icon-btn"} size="lg" onClick={props.handleMove} loading={props.isLoading}>
            Move
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
