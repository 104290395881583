/*eslint-disable no-magic-numbers*/
import React, { useEffect, useState, MouseEvent } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import MainContent from "../../components/MainContent/MainContent";
import WaitScreen from "../../components/Onboard/WaitScreen/WaitScreen";
import { AppContext } from "../../contexts/AppContext";
import { LockstepLogo, PlusSign } from "../../components/library/Icons/Icons";
import "./Home.scss";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { NUMERIC_TIMEOUTS } from "../../constants/NumericConstants";
import Dialog from "../../components/library/Dialog/Dialog";
import DialogTitle from "../../components/library/DialogTitle/DialogTitle";
import DialogContent from "../../components/library/DialogContent/DialogContent";
import DialogContentText from "../../components/library/DialogContentText/DialogContentText";
import DialogActions from "../../components/library/DialogActions/DialogActions";
import Menu from "../../components/library/Menu/Menu";
import MenuItem from "../../components/library/MenuItem/MenuItem";
import Utils from "../../utils/utils";
import Button from "../../components/library/Button/Button";
import * as configValue from "../../constants/config";
import { UserActions } from "../../types/enums";
import ReadOnlyBanner from "../../components/library/ReadOnlyBanner/ReadOnlyBanner";
import FeatureFlagsProvider from "../../contexts/FeatureFlagContext";

export type WorkspaceMenuListItem = {
  id: number;
  label: string;
  selected?: boolean;
  onClickHandler?: () => void;
};
import CustomerProvider from "../../contexts/CustomerContext";
import { Redirect } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { ComponentConfigContext } from "../../contexts/ComponentConfigContext";

const Home: React.FC = () => {
  const { getSyncStatus, setInitialSyncInProgress, initialSyncInProgress, setSyncError, syncError, hasPermission } = React.useContext(
    AppContext
  ) as AppType;
  const {
    workspaceHomePath,
    createNewWorkspace,
    fetchingWorkspace,
    startFetchingWorkspace,
    workspaceData,
    switchActiveWorkspace,
    selectedWorkspace,
    workspaceConfigChangeInProgress,
  } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const baseConfigs = getConfig("base");
  const history = useHistory();
  const [creatingNewWorkspace, setCreatingNewWorkspace] = React.useState<boolean>(false);
  const { instance } = useMsal();
  const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false);
  const { show } = useIntercom();
  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const dropdownMenuOpen = Boolean(dropdownMenuAnchorEl);

  const checkSyncStatus = async () => {
    const syncRequestKey = `SyncRequest-${instance.getActiveAccount()?.username}`;
    const syncRequestId = localStorage.getItem(syncRequestKey) || "";

    try {
      if (syncRequestId) {
        const response = await getSyncStatus(syncRequestId);

        switch (response.statusCode) {
          case "Connector":
          case "Ready":
          case "In Progress":
            setInitialSyncInProgress(true);
            break;
          case "Success":
          case "Cancelled":
          case "Failed":
            setInitialSyncInProgress(false);
            localStorage.removeItem(syncRequestKey);

            if (response.statusCode === "Failed") {
              setSyncError(true);
            } else {
              setSyncError(false);
            }

            break;
          default:
            break;
        }
      } else {
        setInitialSyncInProgress(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
    }
  };

  const startInitialSync = async () => {
    const checkWorkspaceStatus = await startFetchingWorkspace();
    if (checkWorkspaceStatus) {
      checkSyncStatus();
    } else {
      setSyncError(true);
    }
  };

  const addWorkspace = async (accountConfig: WorkspaceCreate) => {
    setCreatingNewWorkspace(true);
    const res = await createNewWorkspace(accountConfig);
    setCreatingNewWorkspace(false);
    setShowCreateModal(res);
  };

  const WorkspaceMenuList = baseConfigs.workspaces.supportedWorkspaces
    ?.filter((workspace: WorkspaceConfig) => !workspaceData?.map((item) => item.workspace_type).includes(workspace.workspace_type))
    .map((w_space: WorkspaceConfig, index: number) => {
      return {
        id: index + 1,
        label: `${w_space?.workspace_name
          ?.split(" ")
          ?.map((name: string) => name[0])
          ?.join("")
          ?.toUpperCase()} Workspace`,
        onClickHandler: () => {
          addWorkspace(w_space);
        },
      };
    }) as WorkspaceMenuListItem[];

  useEffect(() => {
    startInitialSync();
  }, []);

  // We periodically check the sync status so we can show the dashboard when it's completed. We should eventually replace this polling with a
  // push mechanism of some kind.
  useEffect(() => {
    const interval = setInterval(() => {
      checkSyncStatus();
    }, NUMERIC_TIMEOUTS.FIVE_SECOND);

    return () => {
      clearInterval(interval);
    };
  });

  React.useEffect(() => {
    return () => {
      setShowCreateModal(false);
    };
  }, []);

  const getWorkspaceCreateModal = () => (
    <Dialog open={showCreateModal} onClose={() => setShowCreateModal(false)}>
      <DialogTitle className="h3">You're Almost There!</DialogTitle>
      <DialogContent className="mt-ba mb-lg">
        <DialogContentText className="body1">
          To make the most of your Lockstep experience, you’ll need to connect your team’s{" "}
          {selectedWorkspace?.workspace_type_route?.toUpperCase?.() + " " ?? " "}
          email address and add your team members to this workspace.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className="mr-auto"
          variant="secondary"
          onClick={() => {
            setShowCreateModal(false);
          }}
        >
          {configValue.BTN_CANCEL}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setShowCreateModal(false);
            history.push(`/settings/emailSettings/workspace/${selectedWorkspace?.id}`);
          }}
        >
          {configValue.BTN_CONNECT_EMAIL}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const getLoaderMessages = () => {
    if (creatingNewWorkspace) {
      return "Creating new workspace!";
    } else if (workspaceConfigChangeInProgress) {
      return "Making changes to your workspace configurations!";
    } else {
      return undefined;
    }
  };

  return (
    <CustomerProvider>
      <FeatureFlagsProvider>
        <div className={"home-wrapper"}>
          {initialSyncInProgress || fetchingWorkspace ? (
            <WaitScreen loadingMessage={getLoaderMessages()} />
          ) : (
            <>
              <div className={`profilebar ${selectedWorkspace?.workspace_type_route}-theme-bg`}>
                <LockstepLogo className="profilebar-logo" onClick={() => history.push("/")} />
                <div className="switcher-container">
                  {workspaceData && workspaceData.length
                    ? workspaceData.map((workspace, key) => {
                        return (
                          <div
                            key={key}
                            className={`workspace-switcher-item ${workspace.id == selectedWorkspace.id ? "active" : ""}`}
                            onClick={() => switchActiveWorkspace(workspace.id)}
                          >
                            {workspace?.workspace_type_route?.toUpperCase()}
                          </div>
                        );
                      })
                    : null}
                  {workspaceData &&
                  workspaceData.length &&
                  !Utils.compareArrays(
                    baseConfigs.workspaces.supportedWorkspaces.map((item: WorkspaceConfig) => item.workspace_type),
                    workspaceData.map((e) => e?.workspace_type?.toLowerCase?.()) as Array<string>
                  ) &&
                  hasPermission(UserActions.EditWorkspace) ? (
                    <div
                      className={`workspace-add-more  ${selectedWorkspace?.workspace_type_route}-theme-bg`}
                      onClick={(e: MouseEvent<HTMLDivElement>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        setDropdownMenuAnchorEl(e.currentTarget);
                      }}
                    >
                      <PlusSign></PlusSign>
                    </div>
                  ) : null}
                </div>
                <Menu
                  open={dropdownMenuOpen}
                  anchorEl={dropdownMenuAnchorEl}
                  onClose={() => setDropdownMenuAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {WorkspaceMenuList.map((item: WorkspaceMenuListItem, index: number) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          setDropdownMenuAnchorEl(null);
                          item?.onClickHandler?.();
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
              {syncError && (
                <>
                  <div className="sync-error-wrapper">
                    <div>
                      <p>
                        We encountered a problem retrieving data from your accounting system. This issue has been recorded and our engineering team is
                        on it.
                      </p>

                      <p>
                        In the meantime, you can chat with our support team by clicking&nbsp;
                        <a
                          data-testid="SyncFailedChatWithSupportLink"
                          href="#"
                          rel="noreferrer"
                          onClick={(event) => {
                            event.preventDefault();
                            show();
                          }}
                        >
                          here
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </>
              )}
              {!syncError && (
                <Switch>
                  <Redirect exact from={"/"} to={`${workspaceHomePath}/dashboard`} />
                  <Redirect exact from={"/dashboard"} to={`${workspaceHomePath}/dashboard`} />
                  {/* Can be used on config array as well */}
                  <Route path={`/`} component={MainContent} />
                </Switch>
              )}
            </>
          )}
          {getWorkspaceCreateModal()}
          <ReadOnlyBanner />
        </div>
      </FeatureFlagsProvider>
    </CustomerProvider>
  );
};

export default Home;
