import React from "react";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { ActivityActionPerformStatus, ActivityContext } from "../../../../../contexts/ActivityContext";
import { ActivityActionTypes, ActivityStatus, ConnectionStatus, FallbackTypes } from "../../../../../types/enums";
import Button from "../../../../library/Button/Button";
import { AssignTo, Calendar, Spam, Close, Ellipses, MarkAsUnread, MoveTo, Snoozed, SnoozedToast } from "../../../../library/Icons/Icons";
import ActivityMove from "../ActivityFeedActions/ActivityMove";
import ActivityReassign from "../ActivityFeedActions/ActivityReassign";
import ApprovalRequestReview from "../ActivityFeedActions/ApprovalRequestReview";
import methods from "../ActivityFeedMethods";
import { useHistory, useParams } from "react-router";
import { activitiesClientV2 } from "../../../../../db/version2Accessor";
import { ApplicationRouteContext } from "../../../../../contexts/ApplicationRouteContext";
import useWorkspaceConfigurations from "../../../../../hooks/useWorkspaceConfigurations";
import { AlertContext } from "../../../../../contexts/AlertContext";
import { SENDER_SPAM_FRAUD, SNOOZE_LIST } from "../../../../../constants/ListConstants";
import MenuItem from "../../../../library/MenuItem/MenuItem";
import { NUMERIC_VALUES } from "../../../../../constants/NumericConstants";

interface FeedActionHeaderProps {
  getActivityStream: (withLoadingAnimation: boolean, onActionPerform?: boolean, explicitlySetPageSize?: number, refreshStream?: boolean) => void;
  details: {
    refreshActivity: () => void;
    connectionStatus: string;
  };
  reassign: {
    handlers: {
      resetData: () => void;
    };
  };
  nudge: {
    handlers: {
      handleNudge: () => void;
    };
  };
  spamFraud: {
    isSenderOpen: boolean;
    handlers: {
      openModal: (e: React.SyntheticEvent) => void;
      setIsSenderOpen: React.Dispatch<React.SetStateAction<boolean>>;
    };
  };
  approval: {
    approvalReviewDrawer: boolean;
    activityItemId: string | undefined;
    reviewAction: string | undefined;
    handlers: {
      onCloseApprovalReview: () => void;
    };
  };
}

const FeedActionHeader: React.FC<FeedActionHeaderProps> = ({
  getActivityStream,
  details: { refreshActivity, connectionStatus },
  reassign: {
    handlers: { resetData },
  },
  nudge: {
    handlers: { handleNudge },
  },
  spamFraud: {
    isSenderOpen,
    handlers: { openModal, setIsSenderOpen },
  },
  approval: {
    approvalReviewDrawer,
    activityItemId,
    reviewAction,
    handlers: { onCloseApprovalReview },
  },
}) => {
  const classes = methods.useTooltipStyles();

  const { customerId, activityId } = useParams<{ customerId: string; activityId: string }>();
  const history = useHistory();

  const { activityData, activityPerformInProgress, activityActionInProgress, activityCloseAction } = React.useContext(
    ActivityContext
  ) as ActivityType;
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;

  const [reassignDrawerOpen, setReassignDrawerOpen] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [moveDrawerOpen, setMoveDrawerOpen] = React.useState<boolean>(false);

  const { selectedWorkspace, configs } = useWorkspaceConfigurations();

  /****** NETWORK *******/
  const handleUnread = async () => {
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "Activity marked as unread" };
    let response = {} as APIResponse;
    try {
      response = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, activityId, ActivityActionTypes.Read, {
        read: false,
      });
      if (customerId) {
        // We visited by way of the contact detail page
        history.push(`/AR/${configs.activities.detail.resources.connections}/${customerId}`);
      } else if (getBaseRoute().includes("activities")) {
        // we came from activities table view, go back to table
        history.goBack();
      } else {
        // we came from some other view, move back to base route
        history.push(getBaseRoute());
      }
    } catch (error: unknown) {
      response.success = false;
      console.log(error);
    } finally {
      if (!response.success) {
        toastOptions = { ...toastOptions, severity: "error", message: "Activity was not unread" };
      }
      setToastOptions(toastOptions);
    }
  };

  const handleSpam = async () => {
    let toastOptions: ToastOptions = { open: true, severity: "success", message: `Activity is marked as Spam` };
    let response = {} as APIResponse;
    try {
      response = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, activityId, ActivityActionTypes.Spam, { spam: true });
    } catch {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = { ...toastOptions, severity: "error", message: `Something went wrong` };
      }
      setToastOptions(toastOptions);
      getActivityStream(false, true);
      refreshActivity();
    }
  };

  /****** EVENT HANDLERS *******/
  /**
   * @function onCloseCallback
   * A helper callback to set specific behaviour on closing an activity stream.
   * history.goBack() is used to navigate back the index from which activity stream has been opened.
   * Alternatively getActivityStream(true, true); & props.refreshActivity(); can be used to refresh the current activity stream.
   */
  const onCloseCallback = () => {
    history.goBack();
  };

  /****** UPDATE FLAGS *******/
  const handleTooltipClose = () => {
    setIsOpen(false);
  };

  const handleTooltipToggle = (tooltipToggle: boolean) => {
    setIsOpen(!tooltipToggle);
  };

  const handleSenderClose = () => {
    setIsSenderOpen(false);
  };

  const handleSenderToggle = (tooltipToggle: boolean) => {
    setIsSenderOpen(!tooltipToggle);
  };

  /******* UTILITY *******/
  const generateMenuList = (menuList: Array<any>) => {
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "" };
    return menuList.map((item, index) => {
      return (
        <MenuItem
          key={index}
          onClick={async (e) => {
            e.stopPropagation();
            let response = {} as APIResponse;
            const now: number = Math.floor(Date.now() / NUMERIC_VALUES.CONSTANT_THOUSAND);
            try {
              response = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, activityId, ActivityActionTypes.Status, {
                status: ActivityStatus.Snoozed.toLocaleLowerCase() as Status,
                snooze_until: now + item.valueInEpoch,
              });
              if (response.success) {
                setToastOptions({
                  ...toastOptions,
                  message: `Activity Snoozed for ${item.id}`,
                  icon: <SnoozedToast />,
                });
                refreshActivity();
                getActivityStream(false, true);
              }
            } catch (error) {
              response.success = false;
              setToastOptions({
                ...toastOptions,
                severity: "error",
                message: "Activity was not Snoozed",
                icon: <SnoozedToast />,
              });
              console.log(error);
            }
            handleTooltipClose();
          }}
          id={item.id}
        >
          {item.value}
        </MenuItem>
      );
    });
  };

  const generateMenuListForSender = (menuList: Array<any>) => {
    return menuList.map((item, index) => {
      return (
        <MenuItem key={index} onClick={(e) => openModal(e)} id={item.id}>
          {item.value}
        </MenuItem>
      );
    });
  };

  return (
    <div className="head-grp">
      {activityData?.spam || [ConnectionStatus.FRAUD, ConnectionStatus.SPAM].includes(connectionStatus as ConnectionStatus) ? null : (
        <>
          <Button
            icon={<AssignTo />}
            onClick={() => setReassignDrawerOpen(true)}
            variant={"transparent"}
            alignIcon={"left"}
            tooltip={"Assign"}
            className={"detail-icon-btn"}
            disabled={activityActionInProgress}
            loading={(activityPerformInProgress as ActivityActionPerformStatus).Assign}
          />
          {activityData?.status?.toLowerCase() !== ActivityStatus.Closed.toLowerCase() && (
            <Button
              icon={<Close />}
              onClick={() => activityCloseAction([activityId], onCloseCallback)}
              variant={"transparent"}
              alignIcon={"left"}
              tooltip={"Close"}
              className={"detail-icon-btn"}
              disabled={activityActionInProgress}
              loading={(activityPerformInProgress as ActivityActionPerformStatus).Close}
            />
          )}
          {activityData?.status?.toLowerCase() !== ActivityStatus.Closed.toLowerCase() && (
            <Button
              icon={<MarkAsUnread />}
              onClick={() => handleUnread()}
              variant={"transparent"}
              alignIcon={"left"}
              tooltip={"Mark as unread"}
              className={"detail-icon-btn"}
              disabled={activityActionInProgress}
              loading={(activityPerformInProgress as ActivityActionPerformStatus).Read}
            />
          )}
          {activityData?.status?.toLowerCase() !== ActivityStatus.Snoozed.toLowerCase() && (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                title={generateMenuList(SNOOZE_LIST)}
                classes={{ tooltip: classes.tooltip }}
                placement="bottom-start"
                open={isOpen}
                disableHoverListener
              >
                <span>
                  <Button
                    icon={<Snoozed />}
                    variant={"transparent"}
                    alignIcon={"left"}
                    tooltip="Snooze"
                    className={"detail-icon-btn"}
                    onClick={() => handleTooltipToggle(isOpen)}
                    disabled={activityActionInProgress}
                    loading={(activityPerformInProgress as ActivityActionPerformStatus).Snooze}
                  />
                </span>
              </Tooltip>
            </ClickAwayListener>
          )}
          <Button
            icon={<MoveTo />}
            onClick={() => setMoveDrawerOpen(true)}
            variant={"transparent"}
            alignIcon={"left"}
            tooltip={"Move"}
            className={"detail-icon-btn"}
            disabled={activityActionInProgress}
            loading={(activityPerformInProgress as ActivityActionPerformStatus).Move}
          />

          {activityData?.status && activityData?.status?.toLowerCase() !== ActivityStatus.WaitingForResponse.toLowerCase() ? (
            <Button
              icon={<Spam />}
              variant={"transparent"}
              alignIcon={"left"}
              onClick={(e) => {
                e.stopPropagation();
                handleSpam();
              }}
              className="detail-icon-btn"
              tooltip={"Mark as spam"}
              disabled={activityActionInProgress}
              loading={(activityPerformInProgress as ActivityActionPerformStatus).Spam}
            />
          ) : null}

          {activityData?.status?.toLowerCase() === ActivityStatus.WaitingForResponse.toLowerCase() && (
            <Button
              icon={<Calendar />}
              variant={"transparent"}
              alignIcon={"left"}
              onClick={(e) => {
                e.stopPropagation();
                handleNudge();
              }}
              className="detail-icon-btn"
              tooltip={"Nudge Activity"}
              disabled={activityActionInProgress}
              loading={(activityPerformInProgress as ActivityActionPerformStatus).Spam}
            />
          )}

          <ClickAwayListener onClickAway={handleSenderClose}>
            <Tooltip
              title={generateMenuListForSender(SENDER_SPAM_FRAUD)}
              classes={{ tooltip: classes.tooltip }}
              placement="bottom-end"
              open={isSenderOpen}
              disableHoverListener
            >
              <span>
                <Button
                  icon={<Ellipses />}
                  variant={"transparent"}
                  alignIcon={"left"}
                  className={"detail-icon-btn"}
                  disabled={activityActionInProgress}
                  onClick={() => handleSenderToggle(isSenderOpen)}
                />
              </span>
            </Tooltip>
          </ClickAwayListener>
          <ActivityReassign
            open={reassignDrawerOpen}
            onClose={() => setReassignDrawerOpen(false)}
            onCallback={() => {
              resetData();
              getActivityStream(true, true);
              refreshActivity();
            }}
            activityIds={[activityId]}
          />
          <ActivityMove
            open={moveDrawerOpen}
            onClose={() => setMoveDrawerOpen(false)}
            onCallback={() => {
              getActivityStream(true, true);
              refreshActivity();
            }}
            activityIds={[activityId]}
          />
          <ApprovalRequestReview
            open={approvalReviewDrawer}
            onClose={onCloseApprovalReview}
            activityId={activityId}
            requestId={activityItemId ?? ""}
            reviewAction={reviewAction ?? ""}
            getActivityStream={getActivityStream}
          />
        </>
      )}
    </div>
  );
};
export default FeedActionHeader;
