export const MAGIC_LINK = Object.freeze({
  HANDLE: /{[\s\S]*}/,
});

export const MENTION_REGEX_CONSTANTS = Object.freeze({
  REGEX_GET_MENTION: /<span style="color:#2D9DE7;">@[\w_. ]*(\s+[\w_. ]+)*<\/span>&nbsp;/g,
  REGEX_REPLACE_MENTION: /<span style="color:#2D9DE7;">@[\w_. ]+(\s+[\w_. ]+)*<\/span>/g,
  SELECT_METION_WITH_MARKER: /@[\w_./@/-/+/$/# ]*<span[^>]*>/,
  SELECT_MENTION_QUERY: /@[\w_./@/-/+/$/# ]*/,
  FILTER_MENTION: /\[(@[\w_. ]+)\]\(mention:\/\/(?:user|team)\/\d+\/[\w_.%]+\)/g,
  FILTER_MENTION_NAME: /\[(@[\w_. ]+)\]/,
  FILTER_ALL_URLS: /\(mention:\/\/(?:user|team)\/\d+\/[\w_.%]+\)/g,
});
