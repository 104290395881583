import React, { useState, MouseEvent } from "react";
import { Box, IconButton, Menu, MenuItem, SvgIcon } from "@mui/material";
import { ReactComponent as HubIcon } from "../../../assets/Hub.svg";
import { ReactComponent as InboxIcon } from "../../../assets/Inbox.svg";
import { ReactComponent as AccountIcon } from "../../../assets/Account.svg";
import { useHistory } from "react-router-dom";
import "./LockstepBar.scss";

export default function LockstepBar(): React.ReactElement {
  const history = useHistory();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(menuAnchor);

  const handleAccountRedirect = () => {
    window.location.href = process.env.REACT_APP_ACCOUNT_REDIRECT_URL || "";
    setMenuAnchor(null);
  };

  const handleInboxRedirect = () => {
    history.push("/");
    setMenuAnchor(null);
  };

  return (
    <Box id="lockstep-bar">
      <IconButton
        className={`bar-icon-btn ${menuOpen ? "active" : ""}`}
        onClick={(e: MouseEvent<HTMLButtonElement>) => setMenuAnchor(e.currentTarget)}
      >
        <SvgIcon component={HubIcon} inheritViewBox />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          id: "lockstep-bar-menu",
        }}
      >
        <MenuItem className="menu-item" onClick={() => handleAccountRedirect()}>
          <IconButton disableRipple>
            <SvgIcon component={AccountIcon} inheritViewBox />
          </IconButton>
          <p>Account</p>
        </MenuItem>
        <MenuItem className="menu-item" onClick={() => handleInboxRedirect()}>
          <IconButton disableRipple>
            <SvgIcon component={InboxIcon} inheritViewBox />
          </IconButton>
          <p>Inbox</p>
        </MenuItem>
      </Menu>
    </Box>
  );
}
