import React from "react";
import ApprovalActivityComponent from "./ApprovalActivityComponent";
import methods from "../../Activities/ActivityDetail/ActivityFeed/ActivityFeedMethods";
import { ActivityType, AuditLogType } from "../../../types/enums";
import Utils from "../../../utils/utils";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { ActivityStreamData as ApprovalActivityContainerProps } from "../ApprovalsInterfaces";
import { ActivityFeedItemProps } from "../../Activities/ActivityDetail/ActivityFeed/ActivityFeedItem/ActivityFeedItemContainer";

const ApprovalActivityContainer: React.FC<ApprovalActivityContainerProps> = (props) => {
  // stream parser
  const streamParser = (streamItems: (ActivityItemModel | AuditLogItemModel)[]): Partial<ActivityFeedItemProps>[] => {
    const results = streamItems ?? [];
    return results.map((feedItem: any, order: number) => {
      const activityStreamType = methods.evaluateVariant(feedItem);
      const to = methods.evaluateTo(feedItem);
      const from = methods.evaluateFrom(feedItem, {});
      const content = methods.evaluateContent(feedItem) ?? "";
      const status = methods.evaluateStatus(feedItem) ?? ";";
      const header = methods.evaluateHeader(feedItem, activityStreamType) ?? "";
      const useFrom = [
        ActivityType.Note,
        ActivityType.Move,
        ActivityType.PhoneCall,
        AuditLogType.Snoozed,
        AuditLogType.Assign,
        AuditLogType.Close,
        AuditLogType.Spam,
        AuditLogType.FraudConnection,
        AuditLogType.SpamConnection,
        AuditLogType.UnspamConnection,
        AuditLogType.UnfraudConnection,
      ].includes(activityStreamType as ActivityType | AuditLogType);
      return {
        ...header,
        primaryConnection: feedItem?.activity_stream?.primary_connection,
        to: useFrom ? from : to,
        date: Utils.evaluateDate(feedItem?.created_at ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO),
        content: feedItem?.content ?? "",
        isRead: feedItem?.is_read,
        isStreamSpam: feedItem?.activity_stream?.spam,
        variant: activityStreamType,
        creator:
          (feedItem?.type === ActivityType.AuditLog && feedItem?.action_type === AuditLogType.Forward) ||
          feedItem?.action_type === AuditLogType.Snoozed ||
          feedItem?.action_type === AuditLogType.Unsnoozed ||
          feedItem?.action_type === AuditLogType.Assign ||
          feedItem?.action_type === AuditLogType.Unassign ||
          feedItem?.action_type === AuditLogType.MergeConnection ||
          feedItem?.action_type === AuditLogType.Move
            ? feedItem?.action_by
            : feedItem?.creator,
        attachments: feedItem?.attachments,
        emailType: feedItem?.email_type,
        originMessageContent: feedItem?.origin_message_content,
        id: feedItem?.id,
        cc: feedItem?.cc ?? feedItem?.cc,
        bcc: feedItem?.bcc ?? feedItem?.bcc,
        from: feedItem?.from,
        workspace: feedItem?.workspace,
        originalStreamId: feedItem?.origin_activity_stream_id,
        originalStreamItemId: feedItem?.origin_activity_id,
        preview:
          feedItem?.type === ActivityType.AuditLog && feedItem?.action_type === AuditLogType.MergeConnection ? content : feedItem?.preview ?? "",
        snoozedUntil: feedItem?.snooze_until ?? null,
        simple_content:
          feedItem?.type === ActivityType.AuditLog && feedItem?.action_type === AuditLogType.MergeConnection
            ? content
            : feedItem?.simple_content ?? "",
        activityId: "",
        status: status,
        type: feedItem?.type,
        order,
        timeSaved: feedItem?.time_saved,
        assigneeName: feedItem?.to_user?.name ?? feedItem?.to_user?.email,
      };
    });
  };

  return <ApprovalActivityComponent {...{ ...props, stream: streamParser(props.stream ?? []) }} />;
};

export default ApprovalActivityContainer;
