import React from "react";
import NavLinkWrapper from "../../library/NavLink";
import { useHistory } from "react-router-dom";
import "./SettingsPopUp.scss";
import MenuItem from "../../library/MenuItem/MenuItem";
import { Settings } from "../../library/Icons/Icons";
import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";

interface SettingsPopUpInterface {
  tooltipPlacement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

type MenuList = {
  onClickValue: string;
  value: string;
  id: string;
};

const useStyles = makeStyles(() => ({
  popper: {
    marginLeft: "0.5rem !important",
  },
  tooltip: {
    background: "white !important",
    margin: "0 !important",
    padding: "0 !important",
    borderRadius: "0.125rem",
    boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
  },
  menuItem: {
    padding: "1rem 1rem !important",
  },
}));

const SettingsPopUp: React.FC<SettingsPopUpInterface> = ({ tooltipPlacement }: SettingsPopUpInterface) => {
  const history = useHistory();
  const popOptions = [{ onClickValue: "inboxSettings", value: "Inbox Settings", id: "inboxSettings" }];
  const classes = useStyles();

  const handleMenuClick = (e: any) => {
    if (e.target.id === "inboxSettings") {
      history.push(`/settings/inboxSettings`);
    }
  };

  const generateListItem = (menuList: Array<MenuList>) => {
    return menuList.map((item, index) => {
      return (
        <MenuItem
          key={index}
          onClick={(e) => {
            handleMenuClick(e);
          }}
          id={item.id}
          classes={{ root: classes.menuItem }}
        >
          {item.value}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <Tooltip
        placement={tooltipPlacement}
        title={generateListItem(popOptions)}
        classes={{ tooltip: classes.tooltip, popper: classes.popper }}
        disableFocusListener
      >
        <div className="navlink-wrapper">
          <NavLinkWrapper className={"settings-btn"} exact={false} strict={false} key={"settings-nav"} to={`/settings/inboxSettings`}>
            {(isActive: boolean) => {
              return (
                <div className={`settings-icon ${isActive ? "settings-icon-active" : ""}`}>
                  <Settings />
                </div>
              );
            }}
          </NavLinkWrapper>
        </div>
      </Tooltip>
    </>
  );
};
export default SettingsPopUp;
