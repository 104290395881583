import React, { useState, MouseEvent, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useEffect } from "react";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import { EmailAction, FallbackTypes } from "../../../types/enums";
import { companiesClient } from "../../../db/accessor";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES, ISO_DATE_SLICE } from "../../../constants/NumericConstants";
import NewActivityPopup from "../../library/AddNewActivityDropdown/NewActivityPopup";
import { AppContext } from "../../../contexts/AppContext";
import { automationClientV2, emailsClientV2, invoicesClientV2 } from "../../../db/version2Accessor";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { AxiosError } from "axios";
import { CONNECTION_STATUS } from "../../../constants/ConnectionConstants";
import { AlertContext } from "../../../contexts/AlertContext";
import { formatDate, getFormattedDate } from "../../../db/utils/date";
import "./InvoiceTable.scss";
import { Email, Export, NoteFill, Phone } from "../../library/Icons/Icons";
import { ActivityContext } from "../../../contexts/ActivityContext";
import { CSVLink } from "react-csv";
import Table from "../../library/Table/Table";
import { SummaryItem } from "../../library/SummaryHeader/SummaryHeaderInterfaces/SummaryHeaderInterface";
import { SummaryHeader } from "../../library/SummaryHeader";
import { TemplateDefinitionProps } from "../../../app/Templates/TemplateFactory";
import { TemplateContext } from "../../../contexts/TemplateContext";
import { viewType } from "../../../app/Templates/TemplateTypes";
import TrackingUtils from "../../Tracking/Tracking.Utils";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import Utils from "../../../utils/utils";
import _ from "underscore";
import { CustomerContext } from "../../../contexts/CustomerContext";

type InvoiceTableProps = {
  configs: any;
};

export default function InvoiceTable(props: InvoiceTableProps): React.ReactElement {
  const history = useHistory();
  const { url } = useRouteMatch();
  const params = new URLSearchParams(window.location.search);
  const viewConfigs = props.configs.view;
  const summaryConfigs = props.configs.summary;
  const pageNoFromUrl = params.get("page");
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "1");
    return isNaN(pageNum) ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : pageNum;
  });
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION_VALUES.PAGE_SIZE);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const { userStatus, getContactsOptions, allContactOptions, filterPrimaryContacts } = React.useContext(AppContext) as AppType;
  const tableRef = useRef<TableHandle>(null);
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { signature } = React.useContext(CustomerContext) as CustomerType;
  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { handleClose } = React.useContext(ActivityContext) as ActivityType;
  const { allRowsSelected } = React.useContext(ActivityContext) as ActivityType;
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [newActivityType, setNewActivityType] = useState<string | null>(null);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [exportData, setExportData] = useState<any[]>([]);
  const csvInstance = useRef<any | null>(null);
  const [defaultTitle, setDefaultTitle] = useState<string>("");
  const [editorState, setEditorState] = useState<string>("");
  const { templateFactory, handleTemplateAttachment, prepareTemplateDataObject, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<any>();
  const [templateName, setTemplateName] = useState<string>("");
  const [summaryItems, setSummaryItems] = useState<SummaryItem[]>([]);
  const [summaryLoader, setSummaryLoading] = useState<boolean>(false);
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setPlatformPageFetchParams,
    setDocumentType,
    setEnableDocumentSwitch,
    setRootSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { updateBaseRoute } = React.useContext(ApplicationRouteContext) as any;

  const fetchCompanyInfo = (companyId: UUID) => {
    if (!companyId) {
      return;
    }
    companiesClient.getCompany(companyId, "Classification").then((companyModel: CompanyModel) => {
      setCompanyData(companyModel);
    });
  };

  const userView = useRef(url.slice(url.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE));

  const fetchCompanyContactOptions = async (customerId: string) => {
    await setCompanyContactOptions(await getContactsOptions(customerId));
  };

  const fetchParser = (fetchResult: InvoiceModelFetchResultV2, variant?: FetchVariant): Partial<TableData>[] => {
    const results =
      fetchResult?.records?.map((record: Partial<InvoiceModelV2>) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.invoice_id,
          }),
          ...((variant === "export" || variant === "all") && {
            invoice_number: record.invoice_number,
            customer_name: record.customer_name,
            invoice_date: record.invoice_date,
            status: record.status,
            due_date: record.payment_due_date,
            invoice_amount: record.invoice_amount,
            outstanding_amount: record.outstanding_balance,
            customer_id: record.customer_id,
            last_activity_date: record.last_activity_date,
            closed_date: record.invoice_closed_date,
            //Assuming the fisrt record is the latest payment
            payment_number: record?.payment_numbers ? record?.payment_numbers[0] : "{paymentNumber}",
            //TODO API call for total payment if payment numbers start coming in - currently all null
          }),
          ...(variant === "all" && {
            disableCheckbox: false,
            isUnread: undefined,
          }),
        };
      }) ?? [];
    return results;
  };

  const getFilters = (exportInvoice = false, ids?: string[]) => {
    const additionalFilters = [];
    //Below commented code might be needed further hence not removing as of now
    // evaluate future due filters
    if (userView.current == "futureDue") {
      const today = new Date();
      //const comingDueDate = new Date(new Date().setDate(today.getDate() + DAYS_CONSTANTS.MONTH));
      additionalFilters.push({
        searchlightFilter: `qa[payment_due_date_gt]=${getFormattedDate(today)}`,
      });
      // additionalFilters.push({
      //   searchlightFilter: `qa[payment_due_date_lteq]=${getFormattedDate(comingDueDate)}`,
      // });
    }
    // evaluate select filter on export
    if (exportInvoice && !allRowsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      ids.forEach((id: string) => {
        additionalFilters.push({
          searchlightFilter: `qa[invoice_id_in][]=${id}`,
        });
      });
    }
    const filterQuery = TableUtils.getFilterFromURL(params, viewConfigs[userView.current].table.columns);

    return TableUtils.columnFilterParser(filterQuery, true, [...viewConfigs[userView.current].preDefinedFilters, ...additionalFilters] ?? []);
  };

  const fetchInvoices = async () => {
    const defaultSort = `${props.configs.defaultSortKey} ${props.configs.defaultSortToken}`;
    let sortQuery = TableUtils.getSortFromURL(params);
    if (!sortQuery) {
      sortQuery = defaultSort;
    }
    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    const fetchParams = { pageSize, page: pageNumber, order: TableUtils.sortQueryParser(sortQuery ?? "", true), filter: getFilters() };
    setPlatformPageFetchParams(fetchParams as PlatformPageFetchParams);
    return invoicesClientV2
      .getInvoices(pageSize, pageNumber, TableUtils.sortQueryParser(sortQuery ?? "", true), getFilters())
      .then((fetchResult: any) => fetchResult);
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    setFileCallback: FunctionCall
  ) => {
    if (templateID) {
      setTemplateName(templateID);
      const templateObj = templateData.get(selectedWorkspace?.id).get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: selectedInvoice?.customer_name || "",
          invoiceNumber: selectedInvoice?.invoice_number || "",
          invoiceDate: formatDate(selectedInvoice.invoice_date) || "",
          paymentDueDate: formatDate(selectedInvoice.due_date) || "",
          invoiceAmount: Utils.formatValueAsCurrency(
            selectedInvoice.invoice_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          outstandingBalance: Utils.formatValueAsCurrency(
            selectedInvoice.outstanding_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          paymentNumber: selectedInvoice.payment_number,
          signature: signature?.email_signature || "",
        })
      );

      if (templateObj.templateAttachment) {
        const templateDataMapper = prepareTemplateDataObject(
          templateObj,
          [{ name: `Invoice #${selectedInvoice.invoice_number ?? "N/A"}`, id: selectedInvoice.id }],
          null,
          null
        );
        handleTemplateAttachment(templateObj, setFileCallback, templateDataMapper);
      }
    }
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (row: any): TemplateDefinitionProps[] => {
    let view: viewType;
    if (selectedWorkspace.workspace_type === "accounts_payable") {
      view = row.status === "Closed" ? viewType.AP_TRANSACTION_BILLS_CLOSED : viewType.AP_TRANSACTION_BILLS;
    } else {
      view = row.status === "Closed" ? viewType.AR_TRANSACTION_INVOICES_CLOSED : viewType.AR_TRANSACTION_INVOICES;
    }
    return templateFactory.getTemplateDefinitionsByView(view, { invoiceNumber: row.invoice_number, customer: row.customer_name });
  };

  const fetchInvoicesSummary = async () => {
    setSummaryLoading(true);
    try {
      const result = await invoicesClientV2.getSummary(getFilters(), false);
      const data = result.data as InvoiceSummary;
      setSummaryItems(
        summaryConfigs.items.map((item: SummaryItemMeta) => ({
          value: data[item.accessor as keyof typeof data] ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          ...item,
        }))
      );
    } catch (error) {
      console.log(error);
      setSummaryItems([]);
    } finally {
      setSummaryLoading(false);
    }
  };

  /**
   * Event handler which triggered when user select the 'email', 'note' or 'phone call'
   * context menu item. It will open the new activity dialog based to the context menu item
   * selected by the user.
   *
   * @param {Object} row The selected row from the table.
   * @param {String} buttonId The buttonId indicate which button has been selected by the user.
   */
  const onClickActionButton = async (row: any, buttonId: string) => {
    setSelectedInvoice(row);
    if (buttonId === "email") {
      setSupportedTemplateList(getTemplateDefinitionsByView(row));
    }

    await fetchCompanyContactOptions(row.customer_id);
    setNewActivityType(buttonId);
    setShowActivity(true);
  };

  /**
   * The ellipsis action handlers which used to open the activity dialog.
   */
  const ellipsisActionHandlers: Partial<RowSelectButtons> = {
    "action.newEmail": onClickActionButton,
    "action.newNote": onClickActionButton,
    "action.newPhoneCall": onClickActionButton,
  };

  /**
   * @function getCompanyIdForFirstTo
   * A helper function to get the company id for the first contact selected
   * @param to - Contacts
   * @returns
   */
  const getCompanyIdForFirstTo = (to: To[]) => {
    if (to && to.length) {
      return to[0].companyId;
    }
    return "";
  };

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "Messages Sent" };
    const activityTransactions = _.uniq([...inboxAttachments, { transaction_id: selectedInvoice.id, transaction_type: "invoice" }], "transaction_id");
    const payload: Partial<ActivityItemEmailModel> = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      contact_company_id: getCompanyIdForFirstTo(to),
      content_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "input_email"
          : newActivityType && newActivityType === "email"
          ? "input_email"
          : newActivityType,
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "email"
          : newActivityType,
      activity_transactions: activityTransactions,
      primary_connection_id: selectedInvoice.customer_id,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, activityTransactions),
    };

    return new Promise((resolve) => {
      emailsClientV2
        .post(payload)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;
            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(
            to,
            "Ellipsis",
            "New",
            isSendAndClose ?? false,
            allContactOptions,
            props.configs.routes.resource.invoices,
            url,
            newActivityType ?? ""
          );
          setShowActivity(false);
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
          tableRef.current?.softRefresh();
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: "Activity Closed" };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: "Activity was not closed",
          };
        }
        setToastOptions(toastOptions);
        tableRef.current?.softRefresh();
      }
    }
  };

  // Handles redirect to Invoice Details page on row click of the Data Grid
  const handleRowClick = (e: MouseEvent<HTMLTableRowElement>, row: { original: { id?: string } }) => {
    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     * @param selectedActivityStreamId - currently selected document id
     * @param activityStreamIds - list of documents in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    setSelectedActivityStreamId({ id: row.original.id } as ActivityStreamId);
    setActivityStreamIds(tableData.map((item) => ({ id: item.id })));
    setCurrentPaginationState({ page: pageNumber, pageCount, pageSize, totalRecords: totalCount });
    setDocumentType("invoice");
    setRootSwitcherStateSnapshot();
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${workspaceHomePath}/${props.configs.routes.resource.invoices}/${row.original.id ?? ""}`);
    updateBaseRoute(`${workspaceHomePath}/${props.configs.routes.resource.invoices}/${row.original.id ?? ""}`);
  };

  const handleExport = async (ids: string[]) => {
    const filters = getFilters(true, ids);
    let response: any = {};
    try {
      setExportLoading(true);
      response = await invoicesClientV2.exportInvoices(filters);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setExportData(response);
      setExportLoading(false);
      tableRef.current?.softRefresh();
    }
  };

  const rowSelectBtns: RowSelectButtons[] = [
    {
      variant: "primary",
      children: "Export",
      icon: <Export />,
      alignIcon: "left",
      type: "button",
      loading: exportLoading,
      callback: (ids: string[]) => {
        handleExport(ids);
      },
    } as RowSelectButtons,
  ];

  useEffect(() => {
    fetchCompanyInfo(userStatus.account_company_id as string);
    if (summaryConfigs) {
      fetchInvoicesSummary();
    }
    return () => {
      setTotalCount(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    };
  }, []);

  useEffect(() => {
    if (
      (exportData?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setExportData([]);
      });
    }
  }, [exportData]);

  const cellRendererMapping: CellRendererMappingObject = {
    "table.formatString": (props: { value: string | null }) => TableUtils.formatString(props.value, false, "contact-bold"),
    "table.formatDate": (props: { value: string | null }) => TableUtils.formatDate(props.value, false, "contact-bold"),
    "table.formatFunction": (props: { value: string | null; row: { original: { status: string } } }) => {
      return TableUtils.formatAction(
        props.row.original,
        viewConfigs[userView.current].ellipsisActions.map((action: EllipsisAction) => {
          return { ...action, clickFunction: ellipsisActionHandlers[action.handler] };
        })
      );
    },
    "table.formatCurrency": (props: { value: number | null }) =>
      TableUtils.formatCurrency(props.value, false, userStatus?.currency?.locale, userStatus?.currency?.code),
    "table.formatInvoiceStatus": (props: { value: string | null; row: { original: { due_date: string | null; status: string } } }) => {
      return TableUtils.formatInvoiceStatusByDueDate(
        props.row.original.due_date?.slice(ISO_DATE_SLICE.START, ISO_DATE_SLICE.END_SHORT_DATE) ?? "",
        props.row.original.status ?? "",
        false
      );
    },
  };

  const filteredContacts = [...companyContactOptions, ...allContactOptions];
  const primaryContact = filterPrimaryContacts(companyContactOptions);

  return (
    <div className="table-wrapper">
      {summaryConfigs && (
        <SummaryHeader items={summaryItems} isLoading={summaryLoader} itemCount={summaryConfigs.items.length} currency={userStatus.currency} />
      )}
      <div className="body-table">
        <Table
          ref={tableRef}
          dataSets={[
            {
              id: "Invoices",
              displayName: "Invoices",
              rowSelectToken: "invoiceId",
              data: {
                tableData: tableData,
                setTableData: setTableData,
                fetchCall: fetchInvoices,
                fetchParser: fetchParser,
              },
              columns: viewConfigs[userView.current].table.columns.map((column: ColumnHeaderType) => {
                return { ...column, Cell: cellRendererMapping[column.CellRenderer] };
              }),
              export: {
                exportFileName: "All Invoices",
              },
              handleRowClick: handleRowClick,
              predefinedFilters: [
                {
                  route: "",
                  searchlightFilter: "",
                },
              ],
            },
          ]}
          states={{
            isLoading: isLoading,
            setLoading: setLoading,
            isError: isError,
            setError: setError,
            errorMessage: errorMessage,
            setErrorMessage: setErrorMessage,
          }}
          pagination={{
            pageCount: pageCount,
            setPageCount: setPageCount,
            pageNumber: pageNumber,
            setPageNumber: setPageNumber,
            totalCount: totalCount,
            setTotalCount: setTotalCount,
            setPageSize: setPageSize,
            startingIndex: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
          }}
          toggles={{
            showSearchbar: false,
            showExportBtn: false,
            showCaption: true,
            showRowSelect: true,
            showNavigation: true,
            showSelectAllBar: true,
          }}
          rowSelectHeaderBtns={rowSelectBtns}
          isColumnFixed={true}
        />
      </div>
      {showActivity && (
        <NewActivityPopup
          key={`key-add-activity-${newActivityType}`}
          title={"New Activity"}
          open={showActivity}
          contactOptions={filteredContacts}
          defaultTo={_.isEmpty(primaryContact) ? [companyContactOptions[0]] : primaryContact}
          handleSend={handleSend}
          isTemplateSupport={newActivityType === "email"}
          supportedTemplateList={supportedTemplateList}
          defaultTitle={defaultTitle}
          editorState={editorState}
          handleSendMarkClosed={handleSendAndMarkClosed}
          onClose={() => {
            setShowActivity(false);
            setEditorState("");
          }}
          activityType={newActivityType}
          setNewActivityType={(type) => {
            setNewActivityType(type);
          }}
          addActivityDropdown={[
            { displayName: "Email", icon: <Email /> },
            { displayName: "Note", icon: <NoteFill /> },
            { displayName: "Phone Call", icon: <Phone /> },
          ]}
          setDefaultTitle={setDefaultTitle}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
        />
      )}
      {(exportData?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
        <CSVLink data={exportData} filename={`${viewConfigs.exportFileName}`} ref={csvInstance} style={{ display: "none" }} />
      ) : undefined}
    </div>
  );
}
