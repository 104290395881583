import React, { useState, useEffect, useCallback } from "react";
import _ from "underscore";
import Chip from "../../../../../library/Chip/Chip";
import { Attachments, ReadMore, ReadMoreClicked } from "../../../../../library/Icons/Icons";
import { ReactComponent as Flag } from "../../../../../../assets/Flag.svg";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";
import { formatDate } from "../../../../../../db/utils/date";
import { PrimaryActivityProps } from "./PrimaryActivityTypes";
import methods from "../../ActivityFeedMethods";
import DOMPurify from "dompurify";
import { ReplyOptions, TimeSavingIndicator, AttachmentsList } from "./Helpers";
import useLimitContent from "../../../../../../hooks/useLimitContent";
import "../ActivityFeedItem.scss";
import { MAGIC_LINK, MENTION_REGEX_CONSTANTS } from "../../../../../../constants/RegexConstants";
import { useLocation } from "react-router-dom";
import { GLOBAL_SEARCH_CONSTANTS } from "../../../../../../constants/GlobalSearchConstants";
import { SmartText } from "../../../../../library/SmartText";
import { flagScheme } from "./activityFlagScheme";

const PrimaryActivity: React.FC<PrimaryActivityProps> = ({
  order,
  creator,
  icon,
  attachments: { items: attachments },
  FLAGS: ACTION_FLAGS,
  timeSaving,
  actions,
  isRead,
  contacts,
  content,
  children,
  flag,
  readonly: READ_ONLY,
  ...props
}) => {
  // read more-less logic
  const {
    emailContainerRef: containerRef,
    emailRendererRef: rendererRef,
    limitContent,
    toggleContentLimit,
    contentBody,
    isOverflowDetected,
  } = useLimitContent(order, content);
  const [showAttachmentList, setShowAttachmentList] = React.useState<boolean>(false);
  const [isAttachListVisible, setAttachVisibility] = React.useState<boolean>(false);
  const [isHover, setIsHover] = React.useState<boolean>(false);
  const toggleAttachments = () => {
    setAttachVisibility(!isAttachListVisible);
    setShowAttachmentList(false);
  };

  const TopicHeader = <span className="activity-variant">{!_.isUndefined(creator.message) ? creator.message : ""}</span>;

  const AttachmentsCountTag = attachments.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
    <div
      className={`tag attachments ${isAttachListVisible ? "active" : ""}`}
      onClick={(e) => {
        //To suppress event bubbling up to feed component and triggering "Read More" toggle
        e.stopPropagation();
        toggleAttachments?.();
      }}
    >
      <Attachments />
      &nbsp;{attachments.length}
    </div>
  );

  const { search } = useLocation();
  const queryFromParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const [isHighlighting, setIsHighlighting] = useState<boolean>(true);
  const StatusChip = !isRead ? <Chip text="NEW" variant="open" /> : <></>;

  /**
   * DOMPurify hook to open all links in a new window
   * See {@link https://github.com/cure53/DOMPurify/tree/main/demos#hook-to-open-all-links-in-a-new-window-link | DOMPurify - open all links in a new window}
   */
  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
    }
  });

  /**
   * @function escapeTextHighlight
   * A helper function to catch "escape key" press and disable highlighting of search results, and also delete the query param
   */
  const escapeTextHighlight = useCallback((event: KeyboardEvent) => {
    if ((event && event.keyCode === GLOBAL_SEARCH_CONSTANTS.ESCAPE_KEY_CODE) || event.key === "Escape" || event.code === "Escape") {
      setIsHighlighting(false);
      queryFromParams?.delete("query");
    }
  }, []);

  /**
   * A side-effect to catch escape key press to disable search result text highlighting
   */
  useEffect(() => {
    document.addEventListener("keydown", escapeTextHighlight);
    return () => {
      document.removeEventListener("keydown", escapeTextHighlight);
      setShowAttachmentList(false);
    };
  }, [escapeTextHighlight]);

  /**
   * @function isValidMagicLink
   * A helper function to validate if the activity has a valid magic-link
   * @returns boolean
   */
  const isValidMagicLink = () => {
    return !_.isUndefined(props?.magicLink);
  };

  /**
   * @function filterMagicLinkHandle
   * A helper function to filter magic-link handle from email content body
   * @param contentBody - The email content body
   * @returns
   */
  const filterMagicLinkHandle = (contentBody: string) => {
    if (isValidMagicLink()) {
      return contentBody.replace(MAGIC_LINK.HANDLE, "");
    }
    return contentBody;
  };

  /**
   * @function filterMentions
   * A helper function to filter all mentions from email content body and replace with UI friendly syntaxes
   * @param contentBody - The email content body
   * replaces [@John K](mention://user/11/John%20K) to <span style="color:#2D9DE7;">@John K</span>
   */

  const filterMentions = (contentBody: string) => {
    const allMentions = contentBody.match(MENTION_REGEX_CONSTANTS.FILTER_MENTION);
    if (allMentions) {
      let names = allMentions.map((item) => {
        const nameVal = item.match(MENTION_REGEX_CONSTANTS.FILTER_MENTION_NAME);
        if (nameVal) return nameVal[0].replace("[@", "").replace("]", "");
        else return null;
      });
      names = names.filter((name) => name);
      let contentNew = contentBody;
      contentNew = contentNew.replaceAll(MENTION_REGEX_CONSTANTS.FILTER_ALL_URLS, `(replace)`);
      names.forEach((nameVal) => {
        contentNew = contentNew.replace(`[@${nameVal}](replace)`, `<span style="color:#2D9DE7;">@${nameVal}</span>&nbsp;`);
      });
      return contentNew;
    }
    return contentBody;
  };

  /**
   * @function highlightSearchQuery
   * A helper function to highlight the search query in activity body,
   * by replacing the match with itself enclosed in mark tag
   * @param body
   * @returns string
   */
  const highlightSearchQuery = (body: string) => {
    const searchQuery = queryFromParams.get("query") || "";
    if (isHighlighting && searchQuery.length) {
      return body.replace(new RegExp(searchQuery, "gi"), (match: string) => {
        return `<mark>${match}</mark>`;
      });
    }
    return body;
  };

  /**
   * @function calculateMagicLinkEta
   * A helper function to calculate the number of days remaining for the magic link to expire.
   * @returns number - eta
   */
  const calculateMagicLinkEta = () => {
    const createdOn = new Date(creator.createdOn);
    const expiryOn = new Date(createdOn.setDate(createdOn.getDate() + NUMERIC_VALUES.CONSTANT_THIRTY));
    const now = new Date();
    const etaInTime = expiryOn.getTime() - now.getTime();
    const etaInDays =
      etaInTime /
      (NUMERIC_VALUES.CONSTANT_TWENTY_FOUR * NUMERIC_VALUES.CONSTANT_SIXTY * NUMERIC_VALUES.CONSTANT_SIXTY * NUMERIC_VALUES.CONSTANT_THOUSAND);
    return Math.ceil(etaInDays);
  };

  /**
   * @function makeMagicLinkEtaString
   * A helper function to generate an eta string for the magic link.
   * @returns string
   */
  const makeMagicLinkEtaString = () => {
    const eta = calculateMagicLinkEta();
    if (eta > NUMERIC_VALUES.CONSTANT_ZERO) {
      return `This link will expire after ${eta} days.`;
    } else if (eta === NUMERIC_VALUES.CONSTANT_ZERO) {
      return `This link will expire today.`;
    } else if (eta < NUMERIC_VALUES.CONSTANT_ZERO) {
      return `This link has expired`;
    }
  };

  /**
   * react component to handle activity flags
   * @returns React component
   */
  const ActivityFlag: React.FC<{ flag: StreamFlag }> = ({ flag }) => {
    return (
      <div className={`header-flag ${flagScheme[flag].color}`}>
        <div className="header-flag-icon">
          <Flag />
        </div>
        <div className="header-flag-title">{flagScheme[flag].title}</div>
      </div>
    );
  };

  return (
    <div
      className="activity-feed-item-wrapper"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={toggleContentLimit}
    >
      {/* update className to left-content */}
      <div className="head">
        <div className="icon">{icon}</div>
      </div>
      {/* update className to right-content */}
      <div className="body">
        {/* header */}
        <div className="header">
          <div className="header-details">
            <p className="body2">
              {creator.name && (
                <span className="activity-creator">
                  <SmartText
                    title={creator.name}
                    highlighter={{
                      enableHighlighter: isHighlighting,
                      matchText: queryFromParams.get("query") ?? "~",
                    }}
                  />
                  &nbsp;
                </span>
              )}
              {TopicHeader}
            </p>
            &nbsp;{AttachmentsCountTag}
            {!_.isNull(flag) && !_.isEmpty(flag) && <ActivityFlag flag={flag} />}
            {StatusChip}
          </div>
          <div className="header-date">
            <SmartText
              title={creator.createdOn && `${formatDate(creator.createdOn)}`}
              highlighter={{
                enableHighlighter: isHighlighting,
                matchText: queryFromParams.get("query") ?? "~",
              }}
            />
          </div>
        </div>
        {/* subheader */}
        <div className="subheader-wrapper">
          {_.isArray(contacts.to) && _.size(contacts.to) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
            <p className="subheader caption">{methods.GenerateSubheadContactString("To", contacts.to)}</p>
          )}
          {_.isArray(contacts.to) && _.size(contacts.cc ?? []) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
            <p className="subheader caption">{methods.GenerateSubheadContactString("Cc", contacts.cc)}</p>
          )}
          {_.isArray(contacts.to) && _.size(contacts.bcc ?? []) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
            <p className="subheader caption">{methods.GenerateSubheadContactString("Bcc", contacts.bcc)}</p>
          )}
        </div>
        {/* email content */}
        <div ref={rendererRef} className={`email-renderer ${limitContent ? "full-height" : ""}`}>
          <div
            key={"activity-body-content" + isHighlighting}
            ref={containerRef}
            className={`email-container ${!limitContent ? "expanded-margin" : ""}`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                highlightSearchQuery(filterMentions(filterMagicLinkHandle(contentBody).replace(/\t/g, "\u00a0")).replace(/\n/g, "<br/>")),
                {
                  FORBID_TAGS: ["img"],
                }
              ),
            }}
          />
        </div>

        {isValidMagicLink() && (
          <div className="magic-link-cta-container">
            <a
              className={`btn btn-primary btn-lg magic-link-cta ${calculateMagicLinkEta() < NUMERIC_VALUES.CONSTANT_ZERO ? "disabled" : ""}`}
              href={props?.magicLink}
              target="_blank"
              rel="noreferrer"
            >
              <p className="btn-text">Open Self-Service</p>
            </a>
            <p className="magic-link-cta-eta">{makeMagicLinkEtaString()}</p>
          </div>
        )}

        <div className={`activity-action-container ${limitContent ? "limitContent" : ""}`}>
          <div className="left">
            {/* TODO: decouple this block from body - CSS */}
            {
              isOverflowDetected &&
                // <Button variant="secondary" size="sm" alignIcon="left" className="btn btn-secondary read-toggle" onClick={toggleContentLimit}>
                (limitContent ? <ReadMore isHover={isHover} /> : <ReadMoreClicked isHover={isHover} />)
              // </Button>
            }
            {!READ_ONLY && actions?.left}
            {!READ_ONLY && children}
          </div>
          <div className="right">
            {!READ_ONLY && actions?.right}
            {ACTION_FLAGS?.TIME_SAVING_INDICATOR ? <TimeSavingIndicator timeSaving={timeSaving} /> : <></>}
            {!READ_ONLY && ACTION_FLAGS?.REPLY_OPTIONS ? (
              <ReplyOptions
                {...{
                  onClickForward: props.onClickForward,
                  onClickReplyAll: props.onClickReplyAll,
                  onClickViewOriginalEmail: props.onClickViewOriginalEmail,
                  onClickReply: props.onClickReply,
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* attachments */}
        <AttachmentsList
          {...{
            visible: isAttachListVisible,
            items: attachments,
            onClickCloseAttachmentBox: toggleAttachments,
            setShowAttachmentList: setShowAttachmentList,
            showAttachmentList: showAttachmentList,
          }}
        />
      </div>
    </div>
  );
};

export default PrimaryActivity;
