import { CURRENCY_CONSTANTS, DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../constants/NumericConstants";
import Utils from "../../utils/utils";

export const shortenCurrency = (amount: number, decimal?: boolean): string => {
  const stringifiedAmount = String(Math.abs(Math.floor(amount)));
  const decimalPrecisionMap: { [key: string]: number } = {
    "1": 3,
    "2": 2,
    "3": 1,
  };

  const getRoundedPrecision = (currencyBucket: number) => {
    const precisionAmount = Math.abs(amount) / currencyBucket;
    return Utils.roundToPrecisionPoint(precisionAmount, NUMERIC_VALUES.CONSTANT_FOUR - precisionAmount?.toString?.()?.split(".")?.[0].length);
  };

  if (decimal) {
    let decimalVal = 0.0;
    if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_MILLION) {
      decimalVal = getRoundedPrecision(CURRENCY_CONSTANTS.CURRENCY_IN_KILOS);
    } else if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_BILLION) {
      decimalVal = getRoundedPrecision(CURRENCY_CONSTANTS.CURRENCY_IN_MILLION);
    } else if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_TRILLION) {
      decimalVal = getRoundedPrecision(CURRENCY_CONSTANTS.CURRENCY_IN_BILLION);
    } else {
      decimalVal = getRoundedPrecision(CURRENCY_CONSTANTS.CURRENCY_IN_TRILLION);
    }

    const decimalString = decimalVal.toString();
    const [decimalNumber, precisionNumber] = decimalString.split(".");

    if (amount !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      return `${amount < DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? "-" : ""}${decimalNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}.${
        precisionNumber ?? ""
      }${"000".slice(
        DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
        (decimalPrecisionMap[decimalNumber?.length?.toString?.()] ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) -
          (precisionNumber?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
      )}`;
    }
    return stringifiedAmount;
  } else {
    if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_KILOS) {
      return String(Math.round(amount));
    } else if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_MILLION) {
      return String(Math.round(amount / CURRENCY_CONSTANTS.CURRENCY_IN_KILOS));
    } else if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_BILLION) {
      return String(Math.round(amount / CURRENCY_CONSTANTS.CURRENCY_IN_MILLION));
    } else if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_TRILLION) {
      return String(Math.round(amount / CURRENCY_CONSTANTS.CURRENCY_IN_BILLION));
    } else {
      return String(Math.round(amount / CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_TRILLION));
    }
  }
};

export const getCurrencySuffix = (amount: number): string => {
  const stringifiedAmountLength = String(Math.abs(Math.floor(amount))).length;
  if (stringifiedAmountLength <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_KILOS) {
    return "";
  } else if (stringifiedAmountLength <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_MILLION) {
    return "k";
  } else if (stringifiedAmountLength <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_BILLION) {
    return "m";
  } else if (stringifiedAmountLength <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_TRILLION) {
    return "b";
  } else {
    return "t";
  }
};

export const commaSeparatedCurrency = (value?: string, currency = "$"): string => {
  let [amount, deci] = value?.split(".") ?? ["", ""];
  amount = amount ?? "";
  deci = deci ? `.${deci}` : "";

  if (amount && amount.length < NUMERIC_VALUES.CONSTANT_FOUR) {
    return `${currency}${amount}${deci}`;
  } else if (amount && amount.length >= NUMERIC_VALUES.CONSTANT_FOUR && amount.length <= NUMERIC_VALUES.CONSTANT_SIX) {
    return `${currency}${amount.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, amount.length - NUMERIC_VALUES.CONSTANT_THREE)},${amount.slice(
      amount.length - NUMERIC_VALUES.CONSTANT_THREE,
      amount.length
    )}${deci}`;
  } else if (amount && amount.length >= NUMERIC_VALUES.CONSTANT_SEVEN && amount.length <= NUMERIC_VALUES.CONSTANT_NINE) {
    return `${currency}${amount.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, amount.length - NUMERIC_VALUES.CONSTANT_SIX)},${amount.slice(
      amount.length - NUMERIC_VALUES.CONSTANT_SIX,
      amount.length - NUMERIC_VALUES.CONSTANT_THREE
    )},${amount.slice(amount.length - NUMERIC_VALUES.CONSTANT_THREE, amount.length)}${deci}`;
  } else if (amount && amount.length >= NUMERIC_VALUES.CONSTANT_TEN && amount.length <= NUMERIC_VALUES.CONSTANT_TWELVE) {
    return `${currency}${amount.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, amount.length - NUMERIC_VALUES.CONSTANT_NINE)},${amount.slice(
      amount.length - NUMERIC_VALUES.CONSTANT_NINE,
      amount.length - NUMERIC_VALUES.CONSTANT_SIX
    )},${amount.slice(amount.length - NUMERIC_VALUES.CONSTANT_SIX, amount.length - NUMERIC_VALUES.CONSTANT_THREE)},${amount.slice(
      amount.length - NUMERIC_VALUES.CONSTANT_THREE,
      amount.length
    )}${deci}`;
  } else if (amount && amount.length >= NUMERIC_VALUES.CONSTANT_THIRTEEN) {
    return `${currency}${amount.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, amount.length - NUMERIC_VALUES.CONSTANT_TWELVE)},${amount.slice(
      amount.length - NUMERIC_VALUES.CONSTANT_TWELVE,
      amount.length - NUMERIC_VALUES.CONSTANT_NINE
    )},${amount.slice(amount.length - NUMERIC_VALUES.CONSTANT_NINE, amount.length - NUMERIC_VALUES.CONSTANT_SIX)},${amount.slice(
      amount.length - NUMERIC_VALUES.CONSTANT_SIX,
      amount.length - NUMERIC_VALUES.CONSTANT_THREE
    )},${amount.slice(amount.length - NUMERIC_VALUES.CONSTANT_THREE, amount.length)}${deci}`;
  }
  return "N/A";
};
