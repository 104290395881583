/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, useParams, useHistory } from "react-router";
import ClosedInvoices from "./ClosedInvoices/ClosedInvoices";
import Profile from "./Profile/Profile";
import Activities from "./Activities/Activities";
import OpenInvoices from "./OpenInvoices/OpenInvoices";
import Payments from "./Payments/Payments";
import Contacts from "./Contacts/Contacts";
import Documents from "./Documents/Documents";
import ContactDetail from "./Contacts/ContactDetail/ContactDetail";
import ActivityDetail from "../../Activities/ActivityDetail/ActivityDetail";
import { CustomerContext } from "../../../contexts/CustomerContext";
import Loading from "../../library/Loading/Loading";
import Error from "../../library/Error/Error";
import { Header } from "./Header";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";

export default function CustomerDetail(): React.ReactElement {
  const { path } = useRouteMatch();
  const { setCustomerId, customerId } = React.useContext(CustomerContext) as CustomerType;
  const { customerId: paramCustomerId } = useParams<{ customerId: string }>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { getCompanyDetails } = React.useContext(CustomerContext) as CustomerType;
  const { configs } = useWorkspaceConfigurations();
  const { updateBaseRoute, getBaseFromPath } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const [hideHeader, setHidHeader] = React.useState<boolean>(false);

  const fetchData = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      await getCompanyDetails(paramCustomerId);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    /**
     * Updates the active customer id and the base route
     *
     * this updates the customer id if it's not present OR
     * it's not equal to the customer id present in the url.
     */
    if (paramCustomerId && (customerId === "" || customerId !== paramCustomerId)) {
      setCustomerId(paramCustomerId);
      updateBaseRoute(getBaseFromPath());
    }
    fetchData();
  }, [paramCustomerId]);

  return errorMessage ? (
    <div className="customer-detail-wrapper">
      <Error isRelative>{errorMessage}</Error>
    </div>
  ) : (
    <div className="customer-detail-wrapper">
      {!hideHeader && (
        <Switch>
          <Route
            path={[
              `${path}`,
              `${path}/profile`,
              `${path}/open_invoices`,
              `${path}/open_bills`,
              `${path}/closed_invoices`,
              `${path}/closed_bills`,
              `${path}/payments`,
              `${path}/contacts`,
              `${path}/documents`,
            ]}
            component={() => {
              return (
                <div className="head">
                  <Header />
                </div>
              );
            }}
          />
        </Switch>
      )}
      {isLoading ? (
        <div className="customer-detail-wrapper">
          <Loading isRelative />
        </div>
      ) : (
        <Switch>
          <Route
            exact
            path={`${path}`}
            component={() => {
              return (
                <div className="body-table">
                  <Activities />
                </div>
              );
            }}
          />
          <Route
            exact
            path={[`${path}/profile`, `${path}/customers/:customerId`]}
            render={() => {
              return (
                <div className="body">
                  <Profile />
                </div>
              );
            }}
          />
          <Route
            exact
            path={[`${path}/open_invoices`, `${path}/open_bills`, `${path}/invoices`, `${path}/bills`]}
            component={() => {
              return (
                <div className="body-table">
                  <OpenInvoices configs={configs.connections.details} />
                </div>
              );
            }}
          />
          <Route
            exact
            path={[`${path}/closed_invoices`, `${path}/closed_bills`]}
            component={() => {
              return (
                <div className="body-table">
                  <ClosedInvoices configs={configs.connections.details} />
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/payments`}
            component={() => {
              return (
                <div className="body-table">
                  <Payments configs={configs.connections.details} />
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/contacts`}
            component={() => {
              return (
                <div className="body-table">
                  <Contacts configs={configs.connections.details} />
                </div>
              );
            }}
          />
          {/* supported details routes */}
          <Route
            exact
            path={`${path}/contactProfile/:contactId`}
            component={() => {
              setHidHeader(true);
              return (
                <div>
                  <ContactDetail />
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/documents`}
            component={() => {
              return (
                <div className="body-table">
                  <Documents configs={configs.connections.details.documents} />
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/activities/:activityId`}
            component={() => {
              setHidHeader(true);
              return <ActivityDetail />;
            }}
          />
        </Switch>
      )}
    </div>
  );
}
