import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GLOBAL_SEARCH_CONSTANTS } from "../../../constants/GlobalSearchConstants";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { AppContext } from "../../../contexts/AppContext";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { globalSearchClientV2 } from "../../../db/version2Accessor";
import { GlobalSearchCategories, KeyPressEventKeys, WorkspaceType } from "../../../types/enums";
import Utils from "../../../utils/utils";
import { AllConnectionsGrey, AllContacts, FilesExtSimple, FlowChart, Invoices, Payments } from "../../library/Icons/Icons";
import { globalSearchConfigGetter } from "../Config";
import { RecentSearchItem, WidgetIconsMapInterface } from "../Interfaces/WidgetInterfaces";
import "../Styles/WidgetStyles.scss";
import { createNewSearchHistory, getSearchFilters, onSelectRecentSearchHistory, setSearchHistory } from "../Utils/LocalStorageUtils";
import WidgetComponent from "./WidgetComponent";
import WidgetSuggestions from "./WidgetSuggestions";

let previousTimeout: NodeJS.Timeout;

const WidgetContainer: React.FC = () => {
  /**
   * User State for Currency
   */

  const { userStatus } = React.useContext(AppContext) as AppType;

  /**
   * Document Switcher for Activity Streams, Invoices & Payments
   */
  const { setEnableDocumentSwitch } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;

  /**
   * Common States
   */
  const globalSearchWidgetRef = React.useRef<HTMLDivElement>(null);
  const { pathname, search } = useLocation();
  const getQueryFromParams: string = React.useMemo(() => new URLSearchParams(search).get("query") as string, [search]);

  /**
   * Workspace States
   */
  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;

  /**
   * Global Search Config
   */
  const configRef = React.useRef(
    globalSearchConfigGetter(selectedWorkspace?.workspace_type_route?.toUpperCase() || WorkspaceType.AR?.toUpperCase())["global-search-suggestions"]
  );

  /**
   * Dropdown States
   */
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedCategory, setSelectedCategory] = React.useState<string>(
    pathname?.split("/")?.includes("search") ? (getSearchFilters()?.category as string) ?? GlobalSearchCategories.all : GlobalSearchCategories.all
  );
  const predefinedCategorySet = React.useRef<Array<string>>(configRef.current.predefinedCategorySet);

  /**
   *  Input States
   */
  const [searchString, setSearchString] = React.useState<string>("");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isSearchStarted, setIsSearchStarted] = React.useState<boolean>(false);
  const [searchOrigin, setSearchOrigin] = React.useState<string>("");

  /**
   * Suggestions States
   */
  const searchContainerActiveSelectorRef = React.useRef<HTMLDivElement>(null);
  const searchActiveItemSelectorRef = React.useRef<HTMLDivElement>(null);

  // Search Results
  const searchResultsArrayIndexes = React.useRef<Array<number>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enableSuggestionsDropdown, setEnableSuggestionsDropdown] = React.useState<boolean>(false);
  const [enableGlobalSuggestions, setEnableGlobalSuggestions] = React.useState<boolean>(false);
  const [suggestionData, setSuggestionData] = React.useState<Array<GlobalSearchResponse>>([]);
  const [activeKeyDownIndex, setActiveKeyDownIndex] = React.useState<number | null>(null);

  const suggestionsIconMap: WidgetIconsMapInterface = {
    activity_streams: <FlowChart />,
    connections: <AllConnectionsGrey />,
    contacts: <AllContacts />,
    attachments: <FilesExtSimple />,
    invoices: <Invoices />,
    bills: <Invoices />,
    payments: <Payments />,
  };

  //Recent Search
  const [enableRecentSuggestions, setEnableRecentSuggestions] = React.useState<boolean>(false);
  const [recentSearchArray, setRecentSearchArray] = React.useState<Array<RecentSearchItem>>([]);

  /**
   * Nav States
   */
  const history = useHistory();

  /**
   * Local Storage Handler
   */
  const createRecentSearchHistory = () => {
    setRecentSearchArray(createNewSearchHistory(selectedCategory, selectedWorkspace.workspace_type_route?.toUpperCase() as string));
  };

  const appendRecentSearchHistory = (id: string, query: string, category: string) => {
    setRecentSearchArray(onSelectRecentSearchHistory(id, query, category, selectedWorkspace.workspace_type_route?.toUpperCase() as string));
  };

  /**
   *  API Handlers
   */
  const getCategoryFilter = (category?: string) => {
    const useCategory = category ?? selectedCategory;
    return useCategory?.toLocaleLowerCase() === GlobalSearchCategories.all ? undefined : selectedCategory;
  };

  // Show the recent search only if there is at least one recent search data is there
  // Show the search results on at least 3chars
  const toggleDropDown = (queryString: string) => {
    if (queryString?.length >= GLOBAL_SEARCH_CONSTANTS.MIN_SEARCH_CHARACTERS) {
      setEnableSuggestionsDropdown(true);
    } else {
      const recentSearchData = createNewSearchHistory(selectedCategory, selectedWorkspace.workspace_type_route?.toUpperCase() as string);
      if (recentSearchData?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        setRecentSearchArray(recentSearchData);
        setEnableSuggestionsDropdown(true);
      } else {
        setEnableSuggestionsDropdown(false);
      }
    }
  };

  /**
   * Fetch Search API Call
   */
  const getSuggestions = async (queryString: string, callToggleDropDown = true, categoryString?: string) => {
    if (queryString?.length < GLOBAL_SEARCH_CONSTANTS.MIN_SEARCH_CHARACTERS) {
      if (callToggleDropDown) {
        toggleDropDown(queryString);
      }
      setEnableRecentSuggestions(true);
      setEnableGlobalSuggestions(false);
      return null;
    }
    setLoading(true);
    if (callToggleDropDown) {
      toggleDropDown(queryString);
    }
    setEnableGlobalSuggestions(true);
    setEnableRecentSuggestions(false);
    setAnchorEl(null);
    let result = {} as APIResponse;
    try {
      const checkCategory = categoryString ? categoryString === GlobalSearchCategories.all : selectedCategory === GlobalSearchCategories.all;
      result = await globalSearchClientV2.getSearchSuggestions(
        queryString,
        getCategoryFilter(categoryString),
        selectedWorkspace.id,
        checkCategory ? undefined : DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
        checkCategory ? undefined : NUMERIC_VALUES.CONSTANT_THREE
      );
    } catch (error: any) {
      console.error(error.response);
      setSuggestionData([]);
    } finally {
      if (result.success) {
        const resultData = result.data ?? [];
        setSuggestionData(resultData);
        searchResultsArrayIndexes.current = resultData
          ?.map((data: any, index: number) => {
            return data && data?.result?.map((res: any, pos: number) => res && index * GLOBAL_SEARCH_CONSTANTS.ALL_FILTER_MAX_SHOW_RESULTS + pos);
          })
          ?.flat(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
      }
      setLoading(false);
    }
  };

  /**
   * Dropdown Handlers
   */
  const dropdownOpen = Boolean(anchorEl);
  const handleDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setEnableSuggestionsDropdown(false);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const changeCategory = (category: string) => {
    setSelectedCategory(category);
    handleDropdownClose();
  };

  /**
   * Suggestions Handlers
   */
  const onClickSuggestionsHandler = (id: string, query: string, category: string) => {
    setIsSearchStarted(false);
    if (!id) {
      return;
    }
    appendRecentSearchHistory(id, query, category);
    setActiveKeyDownIndex(null);
    setEnableSuggestionsDropdown(false);
    setEnableDocumentSwitch(false);
    switch (category) {
      case GlobalSearchCategories.activity_streams:
        return history.push(`${workspaceHomePath}/activities/all/${id}?query=${searchString && searchString.length ? searchString : query}`);
      case GlobalSearchCategories.invoices:
        return history.push(`${workspaceHomePath}/${configRef.current.invoiceType}/${id}`);
      case GlobalSearchCategories.payments:
        return history.push(`${workspaceHomePath}/payments/${id}`);
      case GlobalSearchCategories.connections:
        return history.push(`${workspaceHomePath}/${configRef.current.connectionType}/active/${id}`);
      case GlobalSearchCategories.attachments:
        return history.push(`${workspaceHomePath}/${configRef.current.connectionType}/active/${id}/documents?sort=created_at+DESC&file_name=${query}`);
      case GlobalSearchCategories.contacts:
        return history.push(`${workspaceHomePath}/${configRef.current.connectionType}/active/${id}/contacts?sort=contact_name&name=${query}`);
      default:
        console.error("Category selection not found");
    }
  };

  const backNavigationHandler = () => {
    setEnableRecentSuggestions(false);
    if (isSearchStarted) {
      setSearchString("");
      getSuggestions("");
      return;
    }
    return window.history.back();
  };

  React.useEffect(() => {
    if (!isSearchStarted && searchOrigin === history.location.pathname) {
      setSearchString("");
      getSuggestions("");
    }
  }, [searchOrigin, history.location.pathname]);

  const onClickNavToAllResults = (query: string, category: string) => {
    setIsSearchStarted(false);
    setActiveKeyDownIndex(null);
    history.push({
      pathname: `${workspaceHomePath}/search/${category}`,
      search: `?query=${query}`,
    });

    const getURLActiveView: string = pathname?.split?.("/")?.[NUMERIC_VALUES.CONSTANT_THREE];

    // Check if we search all in the search results page again
    if (getURLActiveView !== "search") {
      setSearchHistory({
        filters: {
          category: selectedCategory,
        },
        previousContext: {
          previousContextName: Utils.capitalize(getURLActiveView) ?? "Dashboard",
          previousPath: pathname ? pathname + search : `${workspaceHomePath}/dashboard`,
        },
      });
    }
    setActiveKeyDownIndex(null);
    setEnableSuggestionsDropdown(false);
    inputRef?.current?.blur();
  };

  /**
   *  Input Handlers
   */
  const debounce = (func: (query: string) => Promise<null | undefined>, timeout = NUMERIC_VALUES.CONSTANT_FIVE_HUNDRED) => {
    return (...args: [query: string]) => {
      clearTimeout(previousTimeout);
      previousTimeout = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  const searchInputHandler = (event: any) => {
    const searchVal = event.target.value;
    setSearchString(searchVal);
    setSearchOrigin(history.location.pathname);
    setIsSearchStarted(true);
    setActiveKeyDownIndex(null);
    debounce(getSuggestions)(searchVal?.trim());
  };

  const onFocusHandler = () => {
    if (searchString?.length < GLOBAL_SEARCH_CONSTANTS.MIN_SEARCH_CHARACTERS) {
      setEnableRecentSuggestions(true);
    } else {
      setEnableGlobalSuggestions(true);
    }
    toggleDropDown(searchString);
  };

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (enableRecentSuggestions) {
      // Event Key = Arrow Up
      if (event.key === KeyPressEventKeys.arrowUp) {
        if (activeKeyDownIndex !== null && activeKeyDownIndex > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
          setActiveKeyDownIndex(activeKeyDownIndex - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
        } else {
          setActiveKeyDownIndex(null);
        }
      }
      // Event Key = Arrow Down
      else if (event.key === KeyPressEventKeys.arrowDown) {
        if (activeKeyDownIndex !== null && activeKeyDownIndex < recentSearchArray?.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
          setActiveKeyDownIndex(activeKeyDownIndex + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
        } else if (activeKeyDownIndex === null) {
          setActiveKeyDownIndex(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
        }
      }
      // Event Key = Enter
      else if (activeKeyDownIndex !== null && event.key === KeyPressEventKeys.enter) {
        const getRecentSearchData = recentSearchArray[activeKeyDownIndex] as RecentSearchItem;
        onClickSuggestionsHandler(getRecentSearchData?.id, getRecentSearchData?.query, getRecentSearchData?.category);
        setActiveKeyDownIndex(null);
        setEnableSuggestionsDropdown(false);
        inputRef?.current?.blur();
      }
    } else if (enableGlobalSuggestions) {
      const resultArrayIndexes = searchResultsArrayIndexes.current;
      const currentIndexPosition = resultArrayIndexes?.indexOf(activeKeyDownIndex ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE);
      // Event Key = Arrow Up
      if (event.key === KeyPressEventKeys.arrowUp) {
        if (activeKeyDownIndex !== null && currentIndexPosition > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
          setActiveKeyDownIndex(resultArrayIndexes[currentIndexPosition - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE]);
        } else {
          setActiveKeyDownIndex(null);
        }
      }
      // Event Key = Arrow Down
      else if (event.key === KeyPressEventKeys.arrowDown) {
        if (activeKeyDownIndex !== null && currentIndexPosition < resultArrayIndexes?.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
          setActiveKeyDownIndex(resultArrayIndexes[currentIndexPosition + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE]);
        } else if (activeKeyDownIndex === null) {
          setActiveKeyDownIndex(resultArrayIndexes[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO]);
        }
      } else if (activeKeyDownIndex !== null && event.key === KeyPressEventKeys.enter) {
        // Event Key = Enter
        const getResultByCategory =
          suggestionData[
            selectedCategory === GlobalSearchCategories.all
              ? Math.floor(activeKeyDownIndex / GLOBAL_SEARCH_CONSTANTS.ALL_FILTER_MAX_SHOW_RESULTS)
              : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
          ];
        const getResultCategory = getResultByCategory?.category;
        const getResultData =
          getResultByCategory?.result[
            selectedCategory === GlobalSearchCategories.all
              ? activeKeyDownIndex % GLOBAL_SEARCH_CONSTANTS.ALL_FILTER_MAX_SHOW_RESULTS
              : activeKeyDownIndex
          ];
        const getConfig = configRef.current.dataSelectors[getResultCategory];
        onClickSuggestionsHandler(getResultData[getConfig?.id], getResultData[getConfig?.header.key], getResultCategory);
        setActiveKeyDownIndex(null);
        setEnableSuggestionsDropdown(false);
        inputRef?.current?.blur();
      }
    }
  };

  const onKeyDownScrollController = () => {
    if (searchActiveItemSelectorRef?.current && searchContainerActiveSelectorRef?.current) {
      // On Arrow Down
      // Condition : [Item Scroll Position + Item Height - View Height (*Not scroll height) + Container Top Padding] > Scroll Position of container
      const itemsBottomScrollPosition =
        searchActiveItemSelectorRef.current.offsetTop +
        searchActiveItemSelectorRef.current.offsetHeight -
        searchContainerActiveSelectorRef.current.offsetHeight;

      if (itemsBottomScrollPosition > searchContainerActiveSelectorRef.current?.scrollTop) {
        // Add Extra Pixels after View Height + 8PX Extra Padding for bottom padding of the container
        searchContainerActiveSelectorRef.current?.scrollTo(
          DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          itemsBottomScrollPosition + NUMERIC_VALUES.CONSTANT_EIGHT
        );
      }
      // On Arrow UP
      // Condition : [Item Scroll Position - Container Top Padding] < Scroll Position of container
      else if (searchActiveItemSelectorRef.current?.offsetTop - NUMERIC_VALUES.CONSTANT_EIGHT < searchContainerActiveSelectorRef.current?.scrollTop) {
        // Scroll Top Position of the items and Sub extra 8PX padding for top of the container
        searchContainerActiveSelectorRef.current?.scrollTo(
          DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          searchActiveItemSelectorRef.current.offsetTop - NUMERIC_VALUES.CONSTANT_EIGHT
        );
      }
    }
    // Special Case : Arrow Up on first element
    if (!searchActiveItemSelectorRef?.current) {
      searchContainerActiveSelectorRef.current?.scrollTo(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    }
  };

  /**
   * Click Away Listener for the Suggestions
   * Don't use onBlur of input to control the dropdown otherwise onclick redirection won't be working
   */
  const clickAwayListenerHandler = (event: MouseEvent) => {
    if (globalSearchWidgetRef.current && !globalSearchWidgetRef.current?.contains(event.target as Node | null)) {
      setEnableSuggestionsDropdown(false);
      setActiveKeyDownIndex(null);
    }
  };

  /**
   * On load Search handlers
   */
  const setupSearchOnLoadAndRefresh = () => {
    const getURLActiveView: string = pathname?.split?.("/")?.[NUMERIC_VALUES.CONSTANT_THREE];

    if (getURLActiveView === "search") {
      const getCategory = getSearchFilters()?.category as string;
      const query: string = getQueryFromParams;

      if (query?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && predefinedCategorySet.current?.includes(getCategory)) {
        setSearchString(query);
        setIsSearchStarted(true);
        setSelectedCategory(getCategory);
        getSuggestions(query, false, getCategory);
      }
    }
  };

  /**
   * Workspace Change Handler
   */
  const changeWorkspace = () => {
    setSearchString("");
    setIsSearchStarted(false);
    setSelectedCategory(GlobalSearchCategories.all);
    setEnableRecentSuggestions(true);
    setEnableGlobalSuggestions(false);
    createRecentSearchHistory();
    configRef.current = globalSearchConfigGetter(selectedWorkspace?.workspace_type_route?.toUpperCase() || WorkspaceType.AR?.toUpperCase())[
      "global-search-suggestions"
    ];
    predefinedCategorySet.current = configRef.current.predefinedCategorySet;
    setActiveKeyDownIndex(null);
  };

  /**
   * Lifecycle Hooks
   */
  // Implementation for click away listener
  React.useEffect(() => {
    if (globalSearchWidgetRef) {
      window.addEventListener("mousedown", clickAwayListenerHandler);
      return () => {
        window.removeEventListener("mousedown", clickAwayListenerHandler);
      };
    }
  }, [globalSearchWidgetRef]);

  // Filtering Recent Search Based on Filters
  React.useEffect(() => {
    createRecentSearchHistory();
    getSuggestions(searchString, false);
    setSearchHistory({
      filters: {
        category: selectedCategory,
      },
    });
  }, [selectedCategory]);

  // Changing scroll position of the suggestions scroll bar
  React.useEffect(() => {
    onKeyDownScrollController();
  }, [activeKeyDownIndex]);

  // Unselect key down index on view change
  React.useEffect(() => {
    if (enableRecentSuggestions || enableGlobalSuggestions) {
      setActiveKeyDownIndex(null);
    }
  }, [enableRecentSuggestions, enableGlobalSuggestions]);

  // Recalculating Recent Search
  React.useEffect(() => {
    if (enableSuggestionsDropdown && enableRecentSuggestions) {
      createRecentSearchHistory();
    }
  }, [enableSuggestionsDropdown, enableRecentSuggestions]);

  // On Workspace Change
  React.useEffect(() => {
    changeWorkspace();
  }, [selectedWorkspace?.workspace_type_route]);

  // On Load and Refresh
  React.useEffect(() => {
    setupSearchOnLoadAndRefresh();
  }, []);

  return (
    <div ref={globalSearchWidgetRef} className="gsw-main-container">
      <WidgetComponent
        dropdownProps={{
          config: configRef.current,
          anchorEl,
          selectedCategory,
          dropdownOpen,
          predefinedCategorySet: predefinedCategorySet.current,
          handleDropdownClick,
          handleDropdownClose,
          changeCategory,
        }}
        inputProps={{
          inputRef,
          value: searchString,
          onChangeHandlerForInput: searchInputHandler,
          onFocusHandler,
          onKeyDownHandler,
          goBack: backNavigationHandler,
        }}
      />
      <WidgetSuggestions
        commonProps={{
          activeItemRef: searchActiveItemSelectorRef,
          activeKeyDownIndex,
          config: configRef.current,
          containerRef: searchContainerActiveSelectorRef,
          enableDropdown: enableSuggestionsDropdown,
          iconMap: suggestionsIconMap,
          loading,
          onClickNavToAllResults,
          onClickSuggestions: onClickSuggestionsHandler,
          query: searchString,
          currency: userStatus?.currency,
        }}
        globalSearchResultProps={{
          enableGlobalSuggestions,
          data: suggestionData,
        }}
        recentSearchProps={{
          enableRecentSuggestions,
          recentSuggestions: recentSearchArray,
        }}
      />
    </div>
  );
};

export default WidgetContainer;
