import React from "react";
import { TransactionType } from "../../../../../types/enums";
import { Invoices, Payments } from "../../../../library/Icons/Icons";
import { RelatedTransactionComponentProps, RelatedBillItem, RelatedInvoiceItem, RelatedPaymentItem } from "../ActivityTabInterfaces";
import RelatedTransactionItem from "./RelatedTransactionItem";

const transactionInfoMeta = {
  invoices: {
    due_date: { label: "Due Date", type: "date" },
    total_amount: { label: "Total Amount", type: "currency" },
    outstanding_balance: { label: "Outstanding Balance", type: "currency" },
  },
  payments: {
    payment_date: { label: "Payment Date", type: "date" },
    total_amount: { label: "Total Amount", type: "currency" },
    unapplied_amount: { label: "Unapplied Amount", type: "currency" },
    type: { label: "Type", type: "string" },
  },
  bills: {
    due_date: { label: "Due Date", type: "date" },
    total_amount: { label: "Total Amount", type: "currency" },
    outstanding_balance: { label: "Outstanding Balance", type: "currency" },
  },
};

const RelatedTransactionsComponent = ({
  invoices = [],
  bills = [],
  payments = [],
  ...props
}: RelatedTransactionComponentProps): React.ReactElement => {
  return (
    <>
      {invoices.map((data: RelatedInvoiceItem) => {
        return (
          <RelatedTransactionItem
            item={{ ...data, transactionType: TransactionType.Invoice, transactionId: data.invoice_id }}
            key={data.invoice_number}
            label={`Invoice #${data.invoice_number}`}
            meta={transactionInfoMeta.invoices}
            icon={<Invoices />}
            buttonLabel={"View Invoice"}
            ellipsesMenuItems={props.ellipsesMenuItems}
            onClickNavigate={props.getNavigateHandler("invoices", data.invoice_id)}
          />
        );
      })}
      {bills.map((data: RelatedBillItem) => {
        return (
          <RelatedTransactionItem
            item={{ ...data, transactionType: TransactionType.Invoice, transactionId: data.bill_id }}
            key={data.bill_id}
            label={`Bill #${data.bill_number}`}
            meta={transactionInfoMeta.invoices}
            icon={<Invoices />}
            buttonLabel={"View Bill"}
            ellipsesMenuItems={props.ellipsesMenuItems}
            onClickNavigate={props.getNavigateHandler("bills", data.bill_id)}
          />
        );
      })}
      {payments.map((data: RelatedPaymentItem) => {
        return (
          <RelatedTransactionItem
            item={{ ...data, transactionType: TransactionType.Payment, transactionId: data.payment_id }}
            key={data.payment_id}
            label={`Payment ${data.payment_number}`}
            meta={transactionInfoMeta.payments}
            icon={<Payments />}
            buttonLabel={"View Payment"}
            ellipsesMenuItems={props.ellipsesMenuItems}
            onClickNavigate={props.getNavigateHandler("payments", data.payment_id)}
          />
        );
      })}
    </>
  );
};

export default RelatedTransactionsComponent;
