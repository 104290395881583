import APIClient from "../APIClient";

export class SignatureClients {
  /**
   * * POST on /api/v2/settings/signature
   * @returns Promise or Exception
   */
  create(body: SignatureModel): Promise<SignatureFetchModel> {
    const url = "inbox/api/v2/settings/signatures";

    return APIClient.post(url, body).then((response) => {
      return response.data;
    });
  }

  update(body: SignatureModel): Promise<SignatureFetchModel> {
    const url = "inbox/api/v2/settings/signatures";

    return APIClient.patch(url, body).then((response) => {
      return response.data;
    });
  }

  fetch(): Promise<SignatureFetchModel> {
    const url = "inbox/api/v2/settings/signatures";
    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }

  delete(): Promise<void> {
    const url = "inbox/api/v2/settings/signatures";
    return APIClient.delete(url).then((response) => {
      return response.data;
    });
  }
}
