import React from "react";
import { ComponentConfigContext } from "../contexts/ComponentConfigContext";
import { WorkspaceContext } from "../contexts/WorkspaceContext";
import { WorkspaceType } from "../types/enums";

const useWorkspaceConfigurations = () => {
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;

  const workspaceType = selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR;
  const configs = getConfig(workspaceType) as any;

  return { configs, workspaceType, selectedWorkspace };
};

export default useWorkspaceConfigurations;
