import React, { ReactElement, useRef, useState, useEffect } from "react";
import { Important } from "../../library/Icons/Icons";
import DOMPurify from "dompurify";
import JoditEditor from "jodit-react";
import { IJodit } from "jodit/types";
import { Undo, Redo, Bold, Italic, Underline, Strikethrough } from "../../library/AddNewActivityDropdown/ActivityBody/JoditIcons/JoditIcons";
import Button from "../../library/Button/Button";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";

import { SignatureClientsV2 } from "../../../db/version2Accessor";
import { AlertContext } from "../../../contexts/AlertContext";
import SetupMessage from "./MessageBox/SetupMessage";
import AwsomeText from "./MessageBox/AwsomeText";
import _ from "underscore";
import Loading from "../../library/Loading/Loading";
import { AxiosError } from "axios";
import { Modal } from "../../library/DialogModal";
import { CustomerContext } from "../../../contexts/CustomerContext";

import "./SignatureSettings.scss";

const SignatureSettings = (): ReactElement => {
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const EditorInstance = useRef<IJodit>();
  const editor = React.useRef(null);
  const config = React.useMemo(() => {
    return {
      autofocus: false,
      readonly: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      spellcheck: true,
      toolbarButtonSize: "large",
      maxHeight: "32rem",
      tabIndex: 1,
      buttons: "undo,redo,|,bold,italic,underline,strikethrough",
      buttonsMD: "undo,redo,|,bold,italic,underline,strikethrough",
      buttonsSM: "undo,redo,|,bold,italic,underline,strikethrough",
      buttonsXS: "undo,redo,|,bold,italic,underline,strikethrough",
      editorCssClass: "temp-body-editor",
      disablePlugins: "add-new-line,inline-popup",
      activeButtonsInReadOnly: [],
      showPlaceholder: true,
      placeholder: "Best Regards,<br/><br/>Your name <br/>Your position, team name <br/>company url.com",
      events: {
        getIcon: function (name: string, control: any, clearName: string) {
          let code = clearName;
          switch (clearName) {
            case "undo":
              code = Undo;
              break;
            case "redo":
              code = Redo;
              break;
            case "bold":
              code = Bold;
              break;
            case "italic":
              code = Italic;
              break;
            case "underline":
              code = Underline;
              break;
            case "strikethrough":
              code = Strikethrough;
              break;
            default:
              return;
          }
          return code;
        },
        afterInit: (instance: IJodit) => {
          EditorInstance.current = instance;
        },
      },
    };
  }, []);

  const [editorState, setEditorState] = useState<string>("");
  const [signature, setSignature] = useState<Signature>({} as Signature);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const { getUserSignature: updateUserSignatureInContext } = React.useContext(CustomerContext) as CustomerType;

  /**
   * @function getSignature
   * A helper function to get the signature for a given user
   */
  const getSignature = () => {
    setIsLoading(true);
    setEditorState("");
    let toastOptions: ToastOptions = { open: true, severity: "error", message: "" };
    SignatureClientsV2.fetch()
      .then((response: SignatureFetchModel) => {
        setSignature(response.data);
      })
      .catch((error: AxiosError) => {
        const errorResponse = error.response as AxiosErrorResponseData;
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: errorResponse?.data?.messages?.errors[0] ?? "Something went wrong",
        };
        if (errorResponse?.data?.messages?.errors[0] !== "User No signature present") {
          setToastOptions(toastOptions);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * @function saveSignature
   * A helper function to save signature for a user
   */
  const saveSignature = () => {
    setActionLoading(true);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "" };
    SignatureClientsV2.create({ email_signature: editorState })
      .then((response: SignatureFetchModel) => {
        toastOptions = {
          ...toastOptions,
          severity: "success",
          message: response?.messages?.success[0] ?? "Signature added successfully",
        };
      })
      .catch((error: AxiosError) => {
        const errorResponse = error.response as AxiosErrorResponseData;
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: errorResponse?.data?.messages?.errors[0] ?? "Something went wrong",
        };
      })
      .finally(() => {
        setToastOptions(toastOptions);
        setActionLoading(false);
        getSignature();
      });
  };

  /**
   * @function deleteSignature
   * A helper function to delete signature for a user
   */
  const deleteSignature = () => {
    setActionLoading(true);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "" };
    SignatureClientsV2.delete()
      .then(() => {
        toastOptions = {
          ...toastOptions,
          severity: "success",
          message: "Your signature have been removed",
        };
        setSignature({} as Signature);
      })
      .catch((error: any) => {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: error?.data?.messages?.errors[0] ?? "Something went wrong",
        };
      })
      .finally(() => {
        setToastOptions(toastOptions);
        setActionLoading(false);
        setConfirmModalVisible(false);
        getSignature();
      });
  };

  /**
   * @function updateSignature
   * A helper function to edit/update signature for a user
   */
  const updateSignature = () => {
    setActionLoading(true);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "" };
    SignatureClientsV2.update({ email_signature: editorState })
      .then((response: SignatureFetchModel) => {
        toastOptions = {
          ...toastOptions,
          severity: "success",
          message: response?.messages?.success[0] ?? "Signature updated successfully",
        };
        setIsUpdate(false);
      })
      .catch((error: AxiosError) => {
        const errorResponse = error.response as AxiosErrorResponseData;
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: errorResponse?.data?.messages?.errors[0] ?? "Something went wrong",
        };
      })
      .finally(() => {
        setToastOptions(toastOptions);
        setActionLoading(false);
        getSignature();
      });
  };

  const setupEditorForUpdate = () => {
    setEditorState(signature.email_signature);
    setSignature({} as Signature);
    setIsUpdate(true);
  };

  const cancelUpdate = () => {
    setActionLoading(false);
    setIsUpdate(false);
    getSignature();
  };

  useEffect(() => {
    getSignature();
    return () => {
      updateUserSignatureInContext();
    };
  }, []);

  return (
    <>
      <div className="signature-settings container">
        {isLoading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading isRelative />
          </div>
        ) : (
          <>
            <div className="signature-settings-body">
              <div className="signature-settings-message-text-container">{_.isEmpty(signature) ? <SetupMessage /> : <AwsomeText />}</div>
              <div className="signature-container">
                {_.isEmpty(signature) ? (
                  <div className="signature-text-editor">
                    <div className="jodit-wrapper">
                      <JoditEditor
                        ref={editor}
                        value={DOMPurify.sanitize(editorState, { USE_PROFILES: { html: true } })}
                        config={config}
                        onChange={(newContent) => {
                          setEditorState(newContent);
                        }}
                      />
                    </div>
                    <div className="save-button-container">
                      {isUpdate ? (
                        <>
                          <Button variant={"secondary"} onClick={cancelUpdate}>
                            Cancel
                          </Button>
                          <Button
                            variant={"primary"}
                            disabled={editorState?.length == DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO || editorState === "<p><br></p>"}
                            onClick={updateSignature}
                            loading={actionLoading}
                          >
                            Save Changes
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant={"primary"}
                          disabled={editorState?.length == DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO || editorState === "<p><br></p>"}
                          onClick={saveSignature}
                          loading={actionLoading}
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="signature-text">
                    <div className="signature-header">Your Signature</div>
                    <div className="signature-body-container">
                      <div
                        className="signature-body"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(signature.email_signature, {
                            FORBID_TAGS: ["img", "a"],
                          }),
                        }}
                      ></div>
                      <div className="signature-controls">
                        <Button variant={"primary"} onClick={setupEditorForUpdate}>
                          Update
                        </Button>
                        <Button variant={"secondary"} loading={actionLoading} onClick={() => setConfirmModalVisible(true)}>
                          Remove
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="signature-settings-help-text-container">
              <Important />
              <span className="signature-settings-help-text">Note: The best email signature are those that have a goal in mind.</span>
            </div>
          </>
        )}
      </div>
      <Modal
        classes="modal-signature-settings"
        modalStates={{
          open: confirmModalVisible,
          onCloseFunction: () => setConfirmModalVisible(false),
        }}
        title={{
          label: "Are you sure!",
        }}
        content={{
          label:
            "Removing the signature will stop auto populating signatures from your email drafts and templates. This will add 1-2 minutes per email to add signature manually.",
        }}
        secondaryButton={{
          enable: true,
          type: "button",
          variant: "secondary",
          label: "Cancel",
          onClickHandler: () => setConfirmModalVisible(false),
        }}
        primaryButton={{
          enable: true,
          type: "button",
          variant: "primary",
          label: "Remove",
          loader: actionLoading,
          onClickHandler: deleteSignature,
        }}
      />
    </>
  );
};

export default SignatureSettings;
