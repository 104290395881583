import React, { useState, useEffect } from "react";
import { default as View } from "./ContactsView";
import { codeDefinitionsClient } from "../../../../db/accessor";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
interface ContactsProps {
  configs: any;
}

export default function Contacts(props: ContactsProps): React.ReactElement {
  const params = new URLSearchParams(window.location.search);
  const pageSizeFromUrl = params.get("pageSize");
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const pageNoFromUrl = params.get("page");
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "0");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    } else {
      return pageNum;
    }
  });
  const [pageSize, setPageSize] = useState<number>(parseInt(pageSizeFromUrl ?? "25"));
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  const [isLoadingRoleList, setIsLoadingRoleList] = useState<boolean>(true);
  const [roleList, setRoleList] = useState<CodeDefinitionModel[]>([]);

  const fetchParser = (fetchResult: ContactModelFetchResultV2, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: ContactItem) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.contact_id,
          }),
          ...((variant === "export" || variant === "all") && {
            name: record?.contact_name,
            role: record?.role_code,
            email: record?.email_address,
            phoneNumber: record?.phone,
            lastUpdated: record?.modified,
            createdFrom: record?.app_enrollment_id ? "Accounting System" : "Email",
            isPrimary: record?.is_primary,
            appEnrollmentId: record?.app_enrollment_id,
          }),
          ...(variant === "all" && {
            disableCheckbox: record?.app_enrollment_id ? true : false,
          }),
        };
      }) ?? []
    );
  };

  useEffect(() => {
    (async function fetchData() {
      setIsLoadingRoleList(true);
      await codeDefinitionsClient
        .queryCodeDefinitions("CODETYPE EQ 'AccountingRole'", undefined, "codeDefinitionId")
        .then((data: CodeDefinitionModelFetchResult) => {
          setRoleList(data?.records ?? []);
        });
      setIsLoadingRoleList(false);
    })();
  }, []);

  return (
    <View
      tableData={tableData}
      setTableData={setTableData}
      fetchParser={fetchParser}
      isLoading={isLoading}
      setLoading={setLoading}
      isError={isError}
      setError={setError}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      pageCount={pageCount}
      setPageCount={setPageCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      totalCount={totalCount}
      setTotalCount={setTotalCount}
      isLoadingRoleList={isLoadingRoleList}
      roleList={roleList}
      configs={props.configs}
    />
  );
}
