import React, { useEffect, useState, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { activitiesClientV2 } from "../../../db/version2Accessor";
import Utils from "../../../utils/utils";
import "./ActivityDetail.scss";
import ActivityFeed from "./ActivityFeed/ActivityFeed";
import ActivityTab from "./ActivityTab/ActivityTab";
import Header from "./Header/Header";
import { Email, NoteFill, Pen, Phone } from "../../library/Icons/Icons";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { FallbackTypes } from "../../../types/enums";
import { ActivityContext } from "../../../contexts/ActivityContext";
import { GLOBAL_SEARCH_CONSTANTS } from "../../../constants/GlobalSearchConstants";

interface ActivityDetailProps {
  userView?: string;
}

export default function ActivityDetail(props: ActivityDetailProps): React.ReactElement {
  const { activityId } = useParams<{ activityId: string }>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [connectionData, setConnectionData] = useState<Partial<PrimaryConnection>>({} as PrimaryConnection);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { activityData, setActivityData, setShowAttachmentTab } = React.useContext(ActivityContext) as ActivityType;
  const { search } = useLocation();
  const queryFromParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const [isHighlighting, setIsHighlighting] = useState<boolean>(true);

  async function fetchData() {
    setLoading(true);
    let response = {} as ActivityStreamItem;
    try {
      if (activityId) {
        const res = await activitiesClientV2.get(selectedWorkspace?.id || FallbackTypes.Id, activityId);
        const data: ActivityStreamItem = res.data;
        if (data.attachments) {
          data.attachments = data.attachments.map((item: AttachmentItem) => ({
            ...item,
            created_at: Utils.evaluateDate(item.created_at as number),
          }));
        }
        if (data) {
          setConnectionData(data.primary_connection ? data.primary_connection : {});
          response = data;
        }
      }
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setLoading(false);
      setActivityData(response);
      setShowAttachmentTab(false);
    }
    sessionStorage.setItem("activitySubject", response.subject ?? "");
  }

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * @function escapeTextHighlight
   * A helper function to catch "escape key" press and disable highlighting of search results, and also delete the query param
   */
  const escapeTextHighlight = useCallback((event: KeyboardEvent) => {
    if ((event && event.keyCode === GLOBAL_SEARCH_CONSTANTS.ESCAPE_KEY_CODE) || event.key === "Escape" || event.code === "Escape") {
      setIsHighlighting(false);
      queryFromParams?.delete("query");
    }
  }, []);

  /**
   * A side-effect to catch escape key press to disable search result text highlighting
   */
  useEffect(() => {
    document.addEventListener("keydown", escapeTextHighlight);
    return () => {
      document.removeEventListener("keydown", escapeTextHighlight);
    };
  }, [escapeTextHighlight]);

  return (
    <div className="activity-detail-wrapper">
      <Header
        name={connectionData.name ?? "N/A"}
        status={activityData.status}
        subject={activityData.subject ?? "N/A"}
        startedDate={Utils.evaluateDate(activityData.created_at as number) ?? "N/A"}
        companyId={connectionData?.id ?? "N/A"}
        loading={isLoading}
        erpConnection={connectionData.app_enrollment_id !== null}
        highlighterConfig={{ isHighlighting, query: queryFromParams.get("query") }}
      />
      <div className="body">
        <div className="left">
          <ActivityFeed
            activityData={activityData}
            refreshActivity={() => fetchData()}
            addActivityDropdown={[
              { displayName: "Email", icon: <Email /> },
              { displayName: "Note", icon: <NoteFill /> },
              { displayName: "Phone Call", icon: <Phone /> },
              { displayName: "Approval Request", icon: <Pen /> },
            ]}
            connectionStatus={connectionData.status ?? ""}
            onboardingInitiated={connectionData.started_vendor_onboarding}
            userView={props.userView}
          />
        </div>
        <div className="right">
          <ActivityTab
            phone={connectionData?.phone_number ?? "N/A"}
            email={connectionData?.email_address ?? "N/A"}
            erpConnection={connectionData?.app_enrollment_id !== null}
            contacts={activityData.contacts ?? []}
            attachments={activityData.attachments ?? []}
            companyId={connectionData?.id ?? "N/A"}
            transactions={activityData.transactions ?? {}}
            dataLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
