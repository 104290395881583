import React, { useState, MouseEvent, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router";
import Table from "../../../../library/Table/Table";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import { companiesClient } from "../../../../../db/accessor";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { DRILLDOWN_NAVIGATION_CONSTANTS } from "../../../../../constants/NavigationConstants";
import { AppContext } from "../../../../../contexts/AppContext";

export default function TotalBalance(): React.ReactElement {
  const history = useHistory();
  const { path } = useRouteMatch();
  const params = new URLSearchParams(window.location.search);
  const { userStatus } = React.useContext(AppContext) as AppType;

  const CUSTOMERSCOLUMNS = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "customer_name",
        width: "22.5%",
        showFilter: true,
        popupPlaceholder: "ex. Blushing Blooms",
        showSort: true,
        searchlightField: "COMPANYNAME",
        searchlightToken: "CONTAINS",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Primary Contact",
        accessor: "primary_contact",
        width: "17.5%",
        showFilter: true,
        popupPlaceholder: "ex. John Doe",
        showSort: true,
        searchlightField: "PRIMARYCONTACT",
        sortField: "PRIMARYCONTACT, COMPANYNAME",
        searchlightToken: "CONTAINS",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Open Invoices",
        accessor: "open_invoices",
        width: "20%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "OUTSTANDINGINVOICES",
        sortField: "OUTSTANDINGINVOICES, COMPANYNAME",
        searchlightToken: "EQ",
        alignment: "right",
        filterAnchorPosition: "center",
        Cell: (props: { value: number | null }) => TableUtils.formatNumber(props.value, true),
      },
      {
        Header: "Total Balance",
        accessor: "total_balance",
        width: "20%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "OUTSTANDINGAMOUNT",
        sortField: "OUTSTANDINGAMOUNT, COMPANYNAME",
        searchlightToken: "EQ",
        alignment: "right",
        filterAnchorPosition: "center",
        Cell: (props: { value: number | null }) =>
          TableUtils.formatCurrency(props.value, true, userStatus?.currency?.locale, userStatus?.currency?.code),
      },
      {
        Header: "Last Activity",
        accessor: "last_activity",
        width: "20%",
        showFilter: false,
        popupPlaceholder: "ex. 100",
        showSort: false,
        searchlightField: "NEWESTACTIVITY",
        sortField: "NEWESTACTIVITY, COMPANYNAME",
        searchlightToken: "CONTAINS",
        alignment: "left",
        filterAnchorPosition: "left",
        Cell: (props: { value: string | null }) => TableUtils.formatLastActivity(props.value, false),
      },
    ],
    [userStatus]
  );

  const pageNoFromUrl = params.get("page");
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "0");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    } else {
      return pageNum;
    }
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  const fetchParser = (fetchResult: CustomerSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: CustomerSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.companyId,
          }),
          ...((variant === "export" || variant === "all") && {
            customer_name: record?.companyName || "N/A",
            primary_contact: record?.primaryContact || "N/A",
            open_invoices: record?.outstandingInvoices,
            total_balance: record?.outstandingAmount,
            last_activity: record?.newestActivity,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleCustomerRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(
      `${path.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/dashboard/top_customers"))}/customers/${
        row.original.id ?? ""
      }/open_invoices?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.AGING.SORT}`
    );
  }
  return (
    <Table
      dataSets={[
        {
          id: "By Customers",
          displayName: "By Customers",
          rowSelectToken: "companyId",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: companiesClient.getCustomerSummaries,
            fetchParser: fetchParser,
          },
          columns: CUSTOMERSCOLUMNS,
          export: {
            exportFileName: "Top Customers Drilldown - Open Invoices",
          },
          defaultSort: "OUTSTANDINGINVOICES, COMPANYNAME",
          defaultSortToken: "DESC",
          handleRowClick: handleCustomerRowClick,
          predefinedFilters: [
            {
              route: "open_invoices",
              searchlightFilter: "(OUTSTANDINGINVOICES > 0)",
            },
          ],
        },
      ]}
      states={{
        isLoading: isLoading,
        setLoading: setLoading,
        isError: isError,
        setError: setError,
        errorMessage: errorMessage,
        setErrorMessage: setErrorMessage,
      }}
      pagination={{
        pageCount: pageCount,
        setPageCount: setPageCount,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
        totalCount: totalCount,
        setTotalCount: setTotalCount,
      }}
      toggles={{
        showRowSelect: true,
        showExportBtn: true,
        showCaption: true,
        showNavigation: true,
        showSearchbar: false,
      }}
    />
  );
}
