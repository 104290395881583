import React, { useMemo } from "react";
import { Route, Switch, Redirect, useLocation, useRouteMatch } from "react-router-dom";
import DaysOutstandingModuleTable from "./DaysOutstandingModuleTable/DaysOutstandingModuleTable";
import Navbar from "../../../library/Navbar/Navbar";
import { DateTime } from "luxon";
import "../Drilldowns.scss";
import ARHeader from "../../../library/ARHeader/ARHeader";
import FourOFourError from "../../../../routes/FourOFourError/FourOFourError";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import APHeader from "../../../library/APHeader/APHeader";
import { ComponentConfigContext } from "../../../../contexts/ComponentConfigContext";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { WorkspaceType } from "../../../../types/enums";
import { DRILLDOWN_NAVIGATION_CONSTANTS } from "../../../../constants/NavigationConstants";

export default function DaysOutstandingModule(): React.ReactElement {
  const location = useLocation();
  const { path } = useRouteMatch();
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const config = getConfig(selectedWorkspace?.workspace_type_route ?? WorkspaceType.AR)["dashboard"]["drilldowns"]["daysOutstandingModule"];

  const routeType = React.useRef(path.slice(path.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE));
  const [childPath, setChildPath] = React.useState(location.pathname.slice(location.pathname.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE));

  React.useEffect(() => {
    setChildPath(location.pathname.slice(location.pathname.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE));
  }, [location.pathname]);

  const routes = Array.from(Array(NUMERIC_VALUES.CONSTANT_THREE).keys()).map((index: number) => {
    if (routeType.current === "days_sales_outstanding") {
      const lastOfMonth = DateTime.now().minus({ months: index }).endOf("month");
      return {
        displayName: `${lastOfMonth.toFormat("MMM")} ${lastOfMonth.toFormat("yy")}`,
        routeName: `${lastOfMonth.toFormat("MMM")}_${lastOfMonth.toFormat(
          "yy"
        )}?sort=${`${DRILLDOWN_NAVIGATION_CONSTANTS.DAYS_SALES_OUTSTANDING.SORT}`}`,
      };
    } else {
      const lastMonth = DateTime.now()
        .minus({ months: index * NUMERIC_VALUES.CONSTANT_THREE })
        .startOf("month");
      const lastQuater = lastMonth.quarter;
      const lastYear = lastMonth.year?.toString?.()?.slice(NUMERIC_VALUES.CONSTANT_TWO);
      const quaterMap: { [key: number]: string } = {
        0: "Current Quarter",
        1: "Last Quarter",
        2: "Prior Quarter",
      };
      return { displayName: `${quaterMap[index]} (${lastYear}Q${lastQuater})`, routeName: `${quaterMap[index].split(" ")[0]}_${lastYear}` };
    }
  });

  const reportDate = useMemo(() => {
    let reportDate = DateTime.now().toFormat("yyyy-MM-dd");
    if (routeType.current === "days_sales_outstanding") {
      if (childPath !== "days_sales_outstanding") {
        const dateArr = childPath?.split?.("_");
        reportDate = DateTime.fromFormat(`${dateArr[1]} ${dateArr[0]} 01`, "yy MMM dd").endOf("month").toFormat("yyyy-MM-dd");
      }
    } else if (routeType.current === "days_payables_outstanding") {
      const dateArr = childPath?.split?.("_");
      const quarterMap: { [key: string]: number } = {
        Current: 0,
        Last: 1,
        Prior: 2,
      };
      const quaterMonthMap: { [key: number]: string } = {
        1: "Mar",
        2: "Jun",
        3: "Sep",
        4: "Dec",
      };
      const quarterMonth =
        quaterMonthMap[
          DateTime.now()
            .minus({ months: quarterMap[Object.keys(quarterMap).includes(dateArr[0]) ? dateArr[0] : "Current"] * NUMERIC_VALUES.CONSTANT_THREE })
            .endOf("quarter").quarter
        ];

      reportDate = DateTime.fromFormat(`${isNaN(parseInt(dateArr[1], 10)) ? DateTime.now().year : dateArr[1]} ${quarterMonth} 01`, "yy MMM dd")
        .endOf("quarter")
        .toFormat("yyyy-MM-dd");
    }
    return reportDate;
  }, [childPath]);

  return (
    <div className="drilldown-wrapper">
      <div className="head">
        <Navbar
          tabs={routes}
          rootPath={path}
          backDisplayName="Dashboard"
          backRoute={`${path.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/"))}`}
          tooltip={config?.nav?.tooltip}
          dropdownRootPath={path.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/"))}
          dropdown={config?.nav?.dropdown}
          dropdownDefault={config?.nav?.defaultDropDown}
          handleBackRoute
        />
      </div>
      <div className="body">
        <div className="content">
          {routeType.current === "days_sales_outstanding" && childPath !== "days_sales_outstanding" && (
            <ARHeader
              list={[{ type: "totalCustomers" }, { type: "totalInvoicesPastDue" }, { type: "totalPastDueAmount" }, { type: "dso" }]}
              reportDate={reportDate}
            />
          )}
          {routeType.current === "days_payables_outstanding" && childPath !== "days_payables_outstanding" && (
            <APHeader
              list={[{ type: "vendors" }, { type: "bills" }, { type: "amountOutstanding" }, { type: "daysPayableOutstanding" }]}
              reportDate={reportDate}
              reportType={routeType.current}
            />
          )}
          <div className="content-table">
            <Switch>
              <Redirect exact from={path} to={`${path}/${routes?.[0]?.routeName}`} />
              <Route
                exact
                path={[
                  ...routes.map((route: any) => {
                    return `${path}/${route.routeName.replace(/\?.+$/, "")}`;
                  }),
                ]}
                component={() => {
                  return <DaysOutstandingModuleTable routeType={routeType.toString()} reportDate={reportDate} />;
                }}
              />
              <Route
                component={() => {
                  return <FourOFourError />;
                }}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
