/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Switch, useRouteMatch, Route, useLocation, Redirect } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import CustomerDetail from "./CustomerDetail/CustomerDetail";
import CustomerTable from "./CustomerTable/CustomerTable";
import "./Customers.scss";
import { NUMERIC_VALUES } from "../../constants/NumericConstants";
import PrivateRoute from "../../auth/PrivateRoute";
import FourOFourError from "../../routes/FourOFourError/FourOFourError";
import CustomerNavbar from "./CustomersNavbar";
import InvoiceDetail from "../Invoices/InvoiceDetail/InvoiceDetail";
import PaymentDetail from "../Payments/PaymentDetail/PaymentDetail";

export default function Customers(): React.ReactElement {
  const location = useLocation();
  const { path } = useRouteMatch();

  function generatePaths(routes: string[]) {
    let result: string[] = [];
    [`${path}/active/:customerId`, `${path}/spam/:customerId`, `${path}/fraud/:customerId`, `${path}/archived/:customerId`].map((view) => {
      result = [...result, ...routes.map((route) => view + "/" + route)];
    });
    return result;
  }

  return (
    <div className={`customers-wrapper`}>
      <CustomerNavbar />
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true }}>
        <div className={`body`}>
          <Switch>
            <Redirect exact from={path} to={{ pathname: `${location?.pathname}/active`, search: location?.search }} />
            <Route
              exact
              path={[`${path}/active`, `${path}/spam`, `${path}/fraud`, `${path}/archived`]}
              component={() => {
                return <CustomerTable />;
              }}
            />
            <Route
              exact
              path={generatePaths([
                "open_invoices/:invoiceId",
                "closed_invoices/:invoiceId",
                "closed_bills/:invoiceId",
                "open_bills/:invoiceId",
                "invoices/:invoiceId",
                "bills/:invoiceId",
              ])}
              component={() => {
                return <InvoiceDetail />;
              }}
            />
            <Route
              exact
              path={generatePaths(["payments/:paymentId"])}
              component={() => {
                return <PaymentDetail />;
              }}
            />
            {/* LOOSE detail path to consider nested scenarios */}
            <Route
              path={`${path}/archived/:customerId`}
              component={() => {
                return <CustomerDetail key={location.pathname.split("/")[NUMERIC_VALUES.CONSTANT_THREE]} />;
              }}
            />
            <Route
              path={`${path}/spam/:customerId`}
              component={() => {
                return <CustomerDetail key={location.pathname.split("/")[NUMERIC_VALUES.CONSTANT_THREE]} />;
              }}
            />
            <Route
              path={`${path}/fraud/:customerId`}
              component={() => {
                return <CustomerDetail key={location.pathname.split("/")[NUMERIC_VALUES.CONSTANT_THREE]} />;
              }}
            />
            <Route
              path={`${path}/active/:customerId`}
              component={() => {
                return <CustomerDetail key={location.pathname.split("/")[NUMERIC_VALUES.CONSTANT_THREE]} />;
              }}
            />
            <PrivateRoute
              component={() => {
                return <FourOFourError />;
              }}
            />
          </Switch>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}
