import React from "react";
//import BaseTemplate from "../app/Templates/BaseTemplate";
import TemplateFactory from "../app/Templates/TemplateFactory";
import { TemplateTypes } from "../app/Templates/TemplateTypes";
import { AttachmentType } from "../types/enums";
import { WorkspaceContext } from "./WorkspaceContext";
import { TemplatesClientV2 } from "../db/version2Accessor";
import BaseTemplate from "../app/Templates/BaseTemplate";
import { AlertContext } from "./AlertContext";

export const TemplateContext = React.createContext<ITemplateProps | null>(null);

export interface TemplateProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

export interface TemplateAttachDetail {
  name: string;
  id: UUID | number;
}
/**
 * Template mapper will take attachment type as keys and return TemplateAttachDetail
 */
export type TemplateDataMapper = {
  [AttachmentType.INVOICE]?: TemplateAttachDetail[];
  [AttachmentType.DOCUMENT]?: TemplateAttachDetail[];
  [AttachmentType.PAYMENT]?: TemplateAttachDetail[];
  [AttachmentType.BILL]?: TemplateAttachDetail[];
};

const TemplateProvider: React.FC<TemplateProviderProps> = ({ children }: TemplateProviderProps) => {
  const { selectedWorkspace, workspaceData } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [templateData, setTemplateData] = React.useState<Map<number, any>>(new Map());
  const templateFactory = new TemplateFactory(templateData.get(selectedWorkspace?.id));

  const getTemplateObjectList = (templateAPIData: Array<TemplateModelV2>, workspaceId: number) => {
    if (selectedWorkspace) {
      const objectMap: Map<TemplateTypes, BaseTemplate> = new Map();
      const templateObjectData = templateFactory.createTemplateObjects(templateAPIData, selectedWorkspace.workspace_type ?? "accounts_receivable");
      templateObjectData.map((item) => {
        if (item) {
          objectMap.set(item.templateCode as TemplateTypes, item);
        }
      });
      templateData.set(workspaceId, objectMap);
      if (workspaceId === selectedWorkspace?.id) {
        templateFactory.setTemplateObjectsMap(templateData.get(selectedWorkspace?.id));
      }
    }
  };

  const fetchTemplatesList = async (workspaceId: number) => {
    return TemplatesClientV2.getTemplates(workspaceId)
      .then((response) => {
        if (response.success) {
          getTemplateObjectList(response.data, workspaceId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createTemplateMapsForWorkspaces = () => {
    if (workspaceData) {
      workspaceData.forEach(async (item) => {
        await fetchTemplatesList(item.id);
      });
    }
  };

  const saveAndUpdateTemplate = (
    templateBody: string,
    templateSubject: string,
    templateCode: string,
    workspaceId: number,
    templateId: number,
    templateName: string
  ) => {
    let toastOptions: ToastOptions = {
      open: true,
      severity: "error",
      message: `There was an error while updating '${templateName}'.`,
    };
    TemplatesClientV2.saveTemplate(workspaceId, templateId, { subject: templateSubject, content: templateBody })
      .then((response) => {
        if (response.success) {
          Array.from(templateData.get(workspaceId).values()).map((item: any) => {
            if (item.templateCode === templateCode) {
              item.setTemplateBody(response.data.content);
              item.setTemplateSubject(response.data.subject);
              item.setTemplateId(response.data.id);
            }
          });
          toastOptions = { ...toastOptions, severity: "success", message: `Email template '${templateName}' updated successfully.` };
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setToastOptions(toastOptions);
      });
  };

  React.useEffect(() => {
    if (selectedWorkspace) {
      createTemplateMapsForWorkspaces();
    }
  }, [selectedWorkspace?.name]);

  /**
   *
   * @param templateObj : Template Object extending BaseTemplate
   * @param setFileCallback : callback for setting files in editor
   * @param templateDataMapper :
   * @returns : void
   */
  const handleTemplateAttachment = (templateObj: any, setFileCallback: FunctionCall, templateDataMapper: TemplateDataMapper) => {
    const templateAttachmentType = templateObj.attachmentType ?? [];
    const fileNames = templateAttachmentType.map((type: AttachmentType) => templateDataMapper[type]).flat();
    setFileCallback(fileNames);
  };

  /**
   * creates a nested object of attachments which includes,
   * invoices, documents & payments.
   *
   * @param templateObj template object
   * @param invoiceData invoices attachment list
   * @param documentData document attachment list
   * @param paymentData payment attachment list
   * @returns TemplateDataMapper
   */
  const prepareTemplateDataObject = (
    templateObj: any,
    invoiceData: TemplateAttachDetail[] | null,
    documentData: TemplateAttachDetail[] | null,
    paymentData: TemplateAttachDetail[] | null
  ) => {
    const templateDataMapper = {} as TemplateDataMapper;
    const templateAttachmentType = templateObj.attachmentType ?? [];
    templateAttachmentType.forEach((attachmentType: AttachmentType) => {
      if (attachmentType === AttachmentType.INVOICE && invoiceData !== null) {
        templateDataMapper[AttachmentType.INVOICE] = invoiceData as TemplateAttachDetail[];
      } else if (attachmentType === AttachmentType.DOCUMENT && documentData !== null) {
        templateDataMapper[AttachmentType.DOCUMENT] = documentData as TemplateAttachDetail[];
      } else if (attachmentType === AttachmentType.PAYMENT && paymentData !== null) {
        templateDataMapper[AttachmentType.PAYMENT] = paymentData as TemplateAttachDetail[];
      }
    });
    return templateDataMapper;
  };

  return (
    <TemplateContext.Provider
      value={{
        templateFactory,
        handleTemplateAttachment: handleTemplateAttachment,
        prepareTemplateDataObject: prepareTemplateDataObject,
        getTemplateObjectList: getTemplateObjectList,
        saveAndUpdateTemplate: saveAndUpdateTemplate,
        createTemplateMapsForWorkspaces: createTemplateMapsForWorkspaces,
        templateData: templateData,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export default TemplateProvider;
