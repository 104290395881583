import { Tooltip } from "@mui/material";
import React from "react";
import { SmartTextComponentInterface } from "./SmartTextInterfaces";
import "./SmartTextStyles/index.scss";

const SmartTextComponent: React.FC<SmartTextComponentInterface> = (props: SmartTextComponentInterface) => {
  return (
    <div ref={props.divRef} className="container">
      <Tooltip title={props.tooltip.showTooltip ? props.tooltip.title : ""} placement="top" followCursor>
        <p
          dangerouslySetInnerHTML={{
            __html: props.title,
          }}
          ref={props.contentRef}
          onMouseOver={props.toggleTooltip}
          onMouseOut={props.toggleTooltip}
          className={`default-styles-${props.type ?? "p"} ${props.className} default-styles-width ${props.tooltip.showTooltip && "overflow-styles"}`}
        />
      </Tooltip>
    </div>
  );
};

export default SmartTextComponent;
