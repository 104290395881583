import React from "react";
import { useRouteMatch, useHistory, useParams } from "react-router-dom";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { LeftArrow, RightArrow } from "../Icons/Icons";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import "./DocumentSwitcher.scss";

/**
 * @function DocumentSwitcher
 * A reusable UI component to facilitate navigation between documents within document show page.
 */
export default function DocumentSwitcher(): React.ReactElement {
  const {
    getActivityStreamIds,
    getActivityStreamId,
    getCurrentPaginationState,
    setSelectedActivityStreamId,
    setCurrentPaginationState,
    setNextPage,
    setPreviousPage,
    isTransaction,
    getTransactionType,
    getEnableDocumentSwitch,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { path } = useRouteMatch();
  const { customerId, invoiceId, paymentId } = useParams<{ customerId: string; invoiceId: string; paymentId: string }>();
  const history = useHistory();
  /**
   * Get the current activity stream index's pagination state like page number, page size, total pages, total records etc.
   */
  const paginationState = getCurrentPaginationState();
  const totalRecords = paginationState?.totalRecords ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
  /**
   * Get all activity stream record ids available for current activity stream index.
   * This includes filter, sorts etc.
   */
  let activityStreamIds = getActivityStreamIds();
  /**
   * A pointer to track currently displayed/selected activity stream record's index.
   */
  let selectedRecordIndex = activityStreamIds.findIndex((record: ActivityStreamId) => record.id === getActivityStreamId()?.id);
  let currentRecordNumber: number = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
  /**
   * The following logic is used to calculate the chronological order of currently selected
   * activity stream record with respect to the total number of records available for that index.
   */
  if (paginationState && paginationState.page === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
    currentRecordNumber = selectedRecordIndex + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
  } else if (paginationState && paginationState.page > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
    currentRecordNumber =
      (paginationState.page - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) * activityStreamIds.length +
      (selectedRecordIndex + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
  }
  if (activityStreamIds.length < paginationState.pageSize) {
    currentRecordNumber =
      (paginationState.page - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) * paginationState.pageSize +
      (selectedRecordIndex + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
  }

  /**
   * For apis indexing from zero
   */
  if (paginationState && paginationState.page === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
    currentRecordNumber = selectedRecordIndex + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
  }

  /**
   * @function getNextRecord
   * A helper function to get the id of the next available activity stream in the current index.
   * @returns ActivityStreamId
   */
  const getNextRecord = (): ActivityStreamId => {
    return activityStreamIds[selectedRecordIndex + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE];
  };

  /**
   * @function getPreviousRecord
   * A helper function to get the id of the previous available activity stream in the current activity stream index.
   * @returns ActivityStreamId
   */
  const getPreviousRecord = (): ActivityStreamId => {
    return activityStreamIds[selectedRecordIndex - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE];
  };

  /**
   * @function hasPrevious
   * A helper function to check if there is a predecessor to the current activity stream record.
   * @returns boolean
   */
  const hasPrevious = (): boolean => {
    if (paginationState.page <= DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && selectedRecordIndex === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      return false;
    }
    return true;
  };

  /**
   * @function hasNext
   * A helper function to check if there is a successor to the current activity stream record.
   * @returns boolean
   */
  const hasNext = (): boolean => {
    if (
      (paginationState.page === paginationState.pageCount || paginationState.page === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) &&
      selectedRecordIndex === activityStreamIds?.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
    ) {
      return false;
    }
    return true;
  };

  /**
   * @function shouldFetchNextPage
   * A helper function to check if a successor page is available for the current page.
   * @returns boolean
   */
  const shouldFetchNextPage = (): boolean => {
    if (selectedRecordIndex === activityStreamIds?.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && paginationState.page < paginationState.pageCount) {
      return true;
    }
    return false;
  };

  /**
   * @function shouldFetchPreviousPage
   * A helper function to check if a predecessor page is available for the current page.
   * @returns boolean
   */
  const shouldFetchPreviousPage = (): boolean => {
    if (hasPrevious() && activityStreamIds.indexOf(activityStreamIds[selectedRecordIndex]) === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      return true;
    }
    return false;
  };

  /**
   * @function nextDoc
   * A helper function to navigate to the next activity stream record if available.
   * This function also checks if the upper bound of the current index page is
   * reached, and fetches the next page if required, when available.
   */
  const nextDoc = async () => {
    if (hasNext()) {
      let pathToPush = "";
      if (shouldFetchNextPage() && !shouldFetchPreviousPage()) {
        await setNextPage(paginationState.page + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
        activityStreamIds = getActivityStreamIds();
        paginationState.page = paginationState.page + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
        setCurrentPaginationState(paginationState);
        selectedRecordIndex = DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE;
      }
      const nextRecord = getNextRecord();
      pathToPush = `${path
        .replace(/:activityId/, nextRecord?.id)
        .replace(/:customerId/, customerId)
        .replace(/:invoiceId/, isTransaction() && getTransactionType() !== "related-payment" ? nextRecord?.id : invoiceId)
        .replace(/:paymentId/, isTransaction() && getTransactionType() !== "related-invoice" ? nextRecord?.id : paymentId)}`;
      await setSelectedActivityStreamId(nextRecord);
      history.push(pathToPush);
    }
  };

  /**
   * @function prevDoc
   * A helper function to navigate to the previous activity stream record if available.
   * This function also checks if the lower bound of the current index page is
   * reached, and fetches the previous page if required, when available.
   */
  const prevDoc = async () => {
    if (hasPrevious()) {
      let pathToPush = "";
      if (shouldFetchPreviousPage() && !shouldFetchNextPage()) {
        await setPreviousPage(paginationState.page - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
        activityStreamIds = getActivityStreamIds();
        paginationState.page = paginationState.page - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
        setCurrentPaginationState(paginationState);
        selectedRecordIndex = paginationState.pageSize;
      }
      const prevRecord = getPreviousRecord();
      pathToPush = `${path
        .replace(/:activityId/, prevRecord?.id)
        .replace(/:customerId/, customerId)
        .replace(/:invoiceId/, isTransaction() && getTransactionType() !== "related-payment" ? prevRecord?.id : invoiceId)
        .replace(/:paymentId/, isTransaction() && getTransactionType() !== "related-invoice" ? prevRecord?.id : paymentId)}`;
      await setSelectedActivityStreamId(prevRecord);
      history.push(pathToPush);
    }
  };

  if (getEnableDocumentSwitch()) {
    return (
      <div className="activity-navigation">
        {new Intl.NumberFormat().format(currentRecordNumber)} of {new Intl.NumberFormat().format(totalRecords)}
        <div className="navigation-arrows">
          <div className={`navigation-arrow ${!hasPrevious() ? "disabled" : ""}`} onClick={prevDoc}>
            <LeftArrow></LeftArrow>
          </div>
          <div className={`navigation-arrow ${!hasNext() ? "disabled" : ""}`} onClick={nextDoc}>
            <RightArrow></RightArrow>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
