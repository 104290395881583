import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { DEFAULT_NUMERIC_VALUES } from "../../constants/NumericConstants";
import { ApplicationRouteContext } from "../../contexts/ApplicationRouteContext";
import { PaymentContext } from "../../contexts/PaymentContext";
import useWorkspaceConfigurations from "../../hooks/useWorkspaceConfigurations";
import Navbar from "../library/Navbar/Navbar";

const PaymentNavbar = () => {
  const { isDetailViewLoading, paymentDetailViewData } = React.useContext(PaymentContext) as PaymentType;
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;

  const { path } = useRouteMatch();
  const { configs } = useWorkspaceConfigurations();

  const getDropdownRoutes = (displayName: string, customerName: string) => {
    return configs.payments.routes.dropdownRoutes.map((route: Record<string, string>) => {
      return {
        displayName: `${displayName ? displayName : "All"} ${route.entity}`,
        routeName: customerName ? `${route.routeName}&customer_name=${customerName ?? ""}` : `${route.routeName}`,
      };
    });
  };

  const dropdownRootpath = path.substr(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/"));

  return (
    <div className="payment-head">
      <Switch>
        {/* TABLE/INDEX */}
        <Route
          exact
          path={[`${path}`, `${path}/unappliedPayments`, `${path}/advancedPayments`]}
          component={() => {
            return <Navbar tabs={configs.payments.routes.main} rootPath={path} />;
          }}
        />
        {/* DETAIL/SHOW */}
        <Route
          exact
          path={[`${path}/:paymentId`, `${path}/unappliedPayments/:paymentId`, `${path}/advancedPayments/:paymentId`]}
          component={() => {
            return (
              <Navbar
                tabs={configs.payments.routes.main}
                rootPath={path}
                dropdown={getDropdownRoutes(paymentDetailViewData?.customerName, paymentDetailViewData?.customerName)}
                dropdownRootPath={dropdownRootpath}
                dropdownDefault={`${paymentDetailViewData?.customerName ?? ""} Activities`}
                hideDropdown={isDetailViewLoading}
                handleBackRoute={true}
                backRoute={path}
                backDisplayName={`All Payments`}
              />
            );
          }}
        />
        {/* REST */}
        <Route
          path={[`${path}/:paymentId`, `${path}/unappliedPayments/:paymentId`, `${path}/advancedPayments/:paymentId`]}
          component={() => {
            return (
              <Navbar
                tabs={configs.payments.routes.main}
                rootPath={path}
                dropdown={getDropdownRoutes(paymentDetailViewData?.customerName, paymentDetailViewData?.customerName)}
                dropdownRootPath={dropdownRootpath}
                dropdownDefault={`${paymentDetailViewData?.customerName ?? ""} Activities`}
                hideDropdown={isDetailViewLoading}
                handleBackRoute={true}
                backRoute={getBaseRoute()}
                backDisplayName={`Payment #${paymentDetailViewData?.referenceCode}`}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default PaymentNavbar;
