import React, { ReactNode } from "react";
import { CircularProgress, Tooltip } from "@mui/material";

export interface ButtonProps {
  className?: string;
  variant?: "primary" | "secondary" | "transparent" | "grey" | "error" | "solo-icon" | "snippet";
  size?: "sm" | "lg";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children?: React.ReactNode;
  icon?: ReactNode;
  alignIcon?: "left" | "right";
  type?: "submit" | "reset" | "button";
  form?: string;
  loading?: boolean;
  tooltip?: string;
  fullWidth?: boolean;
}

export default function Button(props: ButtonProps): React.ReactElement {
  return (
    <Tooltip
      title={props.tooltip ?? ""}
      followCursor
      placement="top"
      componentsProps={props.fullWidth ? { tooltip: { sx: { maxWidth: "none" } } } : {}}
    >
      {/**
       * MUI does not support tooltip with disabled elements
       * and as a workaround span is wrapped around the button.
       *
       * Official docs: https://mui.com/material-ui/react-tooltip/#disabled-elements
       */}
      <span>
        <button
          className={`
        btn ${props.icon ? "icon-btn" : ""} ${props.icon && props.alignIcon ? `icon-btn-${props.alignIcon}` : ""}
        btn-${props.variant ?? "primary"}
        btn-${props.size === "sm" ? "sm" : "lg"}
        ${props.loading ? "btn-loading" : ""}
        ${props.className ? props.className : ""}
        ${props.variant === "secondary" && "secondary-border"}
      `}
          onClick={props.onClick}
          disabled={props.disabled || props.loading}
          type={props.type}
          form={props.form}
          style={props.disabled ? { pointerEvents: "none" } : {}}
        >
          {props.icon && props.alignIcon === "left" ? props.icon : null}
          {props.children && <p className={`btn-text${props.size === "sm" ? "-sm" : ""}`}>{props.children}</p>}
          {props.icon && props.alignIcon === "right" ? props.icon : null}
          <div className={`btn-load-wrapper btn-load-wrapper-${props.variant ?? "primary"}`}>
            <CircularProgress size={18} />
          </div>
        </button>
      </span>
    </Tooltip>
  );
}
