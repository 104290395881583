// Imports from react
import React, { useState, useEffect } from "react";
import BarChart, { calculateLeftMargin } from "../../../library/Charts/BarChart";
import Loading from "../../../library/Loading/Loading";
import Error from "../../../library/Error/Error";
import { DashboardContext } from "../../../../contexts/DashboardContext";
import "./AccountSummaryWidget.scss";
import NoData from "../../../library/NoData/NoData";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { TooltipIcon } from "../../../library/Icons/Icons";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, TRIM_COUNT_CONSTANTS } from "../../../../constants/NumericConstants";
import { CONNECTION_STATUS } from "../../../../constants/ConnectionConstants";
import { getCurrencySuffix, shortenCurrency } from "../../../../db/utils/currency";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { WorkspaceType } from "../../../../types/enums";
import { DRILLDOWN_NAVIGATION_CONSTANTS } from "../../../../constants/NavigationConstants";
import { AppContext } from "../../../../contexts/AppContext";

/**
 * * Defines the Collection Summary Widget
 */

interface AccountSummaryWidgetInterface {
  disableInfoTooltip?: boolean;
  configs: any;
}

export default function AccountSummaryWidget({ configs, disableInfoTooltip }: AccountSummaryWidgetInterface): React.ReactElement {
  // Get GET method for Cashflow Report from Dashboard Context
  const { getSummaryData } = React.useContext(DashboardContext) as DashboardType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { userStatus } = React.useContext(AppContext) as AppType;
  // Loading state from GET API Call for Cashflow Report
  const [isLoading, setLoading] = useState<boolean>(false);
  // Error message from GET API Call for Cashflow Report
  const [errorMessage, setErrorMessage] = useState<string>("");
  // Data from GET API Call for Cashflow Report
  const [data, setData] = useState<GraphData[] | []>([]);
  const [displayData, setDisplayData] = React.useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const history = useHistory();
  const { path } = useRouteMatch();

  // GET on /api/v1/Reports/cashflow
  const fetchData = async () => {
    // Default error message
    setErrorMessage("");
    // API call loading...
    setLoading(true);
    try {
      // On success, set data
      const res = await getSummaryData(configs.summaryType);
      if (res) {
        return res ?? [];
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.status === CONNECTION_STATUS.NOT_FOUND_404.STATUS_CODE) {
        setData([]);
      } else {
        console.error(error.message);
        setErrorMessage("Error Loading Data");
      }
    }
  };

  const handleOnClick = (value: string) => {
    if (!configs.isWidgetClickable) {
      return null;
    }
    let route = `?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.RECEIVABLES_SUMMARY.SORT}`;
    if (value === "INVOICED") {
      route = "/invoiced?sort=OUTSTANDINGAMOUNT+DESC%2C+COMPANYNAME+DESC";
    }
    if (selectedWorkspace?.workspace_type_route === WorkspaceType.AP) {
      route = "?sort=AMOUNTPAIDLAST30+DESC%2C+VENDORNAME+DESC";
      if (configs.summaryType === "comingDueSummary") {
        route = "/due_now?sort=DUEDATE+DESC%2C+VENDORNAME+DESC";
        return history.push(`${path}/payables_coming_due${route}`);
      }
      if (configs.summaryType === "accountSummary") {
        route =
          value?.toString?.()?.toLowerCase?.() === "billed"
            ? "/billed?sort=AMOUNTPAIDLAST30+DESC%2C+VENDORNAME+DESC"
            : "?sort=AMOUNTPAIDLAST30+DESC%2C+VENDORNAME+DESC";
      }
      return history.push(`${path}/payables_summary${route}`);
    }
    history.push(`${path}/receivables_summary${route}`);
  };

  // On mount, fetch Collection Summary data
  useEffect(() => {
    let cancelled = false;
    fetchData().then((response) => {
      if (!cancelled) {
        if (response) {
          setDisplayData(response[configs.displayDataIndex]?.value);
          setData(response.slice(configs.startGraphDataIndex));
        }
        // API call finalized
        setLoading(false);
      }
    });
    return () => {
      cancelled = true;
    };
  }, [selectedWorkspace]);

  return isLoading ? (
    <div className={`cs-wrapper`}>
      <Loading />
    </div>
  ) : errorMessage ? (
    <div className={`cs-wrapper`}>
      <Error>{errorMessage}</Error>
    </div>
  ) : data.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
    <div className={`cs-wrapper`}>
      <div className={`cs-top`}>
        <p className={`title`}>{configs.header}</p>
        {!disableInfoTooltip && (
          <Tooltip title="Rolling 30 day collected amount verses invoiced amount." placement="right" arrow disableInteractive>
            <IconButton size="small">
              <TooltipIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <NoData />
    </div>
  ) : (
    <div className={`cs-wrapper`}>
      <div className={`cs-top`}>
        <p className={`title ${!configs.isWidgetClickable ? "non-clickable" : "clickable"}`} onClick={() => handleOnClick("")}>
          {configs.header}
        </p>
        {!disableInfoTooltip && (
          <Tooltip title="Rolling 30 day collected amount verses invoiced amount." placement="right" arrow disableInteractive>
            <IconButton size="small">
              <TooltipIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={`cs-mid`}>
        <div className="cs-mid-title">
          <p onClick={() => handleOnClick("")} className={`large-number ${!configs.isWidgetClickable ? "non-clickable" : "clickable"}`}>
            {Number(shortenCurrency(displayData ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, true)) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
              <>{shortenCurrency(displayData, true)}</>
            ) : (
              DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
            )}
          </p>
          <div className="suffix-container">
            <p className={`caption`}>{configs.priceText}</p>
            <p className="h4 suffix">
              {Number(shortenCurrency(displayData ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, true)) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
                `(${userStatus?.currency?.symbol ?? "$"}${getCurrencySuffix(displayData)})`}
            </p>
          </div>
        </div>
      </div>
      <div className={`cs-bot ${!configs.isWidgetClickable ? "non-clickable" : "clickable"}`}>
        <BarChart
          hoverSquare={false}
          data={data}
          hoverFormat="currency"
          padding={15}
          margin={
            data.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
              ? {
                  top: 15,
                  right: 0,
                  bottom: 15,
                  left: calculateLeftMargin(data),
                }
              : data.length === NUMERIC_VALUES.CONSTANT_TWO
              ? {
                  top: 12,
                  right: 0,
                  bottom: 12,
                  left: calculateLeftMargin(data),
                }
              : data.length === TRIM_COUNT_CONSTANTS.RR_ROUTE
              ? {
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: calculateLeftMargin(data),
                }
              : undefined
          }
          onClick={(id) => handleOnClick(id)}
          currency={userStatus?.currency}
        />
      </div>
    </div>
  );
}
