/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { default as TopRisksWidget } from "./Widgets/TopCompanies/TopCompanies";
import { default as TopRisksDrilldown } from "./Drilldowns/TopCustomers/TopCustomers";
import { default as AgingWidget } from "./Widgets/Aging/Aging";
import { default as AgingDrilldown } from "./Drilldowns/Aging/Aging";
import { default as ReceivablesRiskWidget } from "./Widgets/ReceivablesRisk/ReceivablesRisk";
import { default as ReceivablesRiskDrilldown } from "./Drilldowns/ReceivablesRisk/ReceivablesRisk";
import { default as PayablesComingDue } from "./Drilldowns/PayablesComingDue/PayablesComingDue";
import { default as DaysOutstandingModuleWidget } from "./Widgets/DaysOutstandingWidget/DaysOutstandingWidget";
import { default as DaysOutstandingModuleDrilldown } from "./Drilldowns/DaysOutstandingModule/DaysOutstandingModule";
import { default as AccountSummaryWidget } from "./Widgets/AccountSummaryWidget/AccountSummaryWidget";
import { default as ReceivablesSummaryDrilldown } from "./Drilldowns/ReceivablesSummary/ReceivablesSummary";
import { default as PayablesSummaryDrilldown } from "./Drilldowns/PayablesSummary/PayablesSummary";
import "./Dashboard.scss";
import { DEFAULT_NUMERIC_VALUES } from "../../constants/NumericConstants";
import { InsightsCarousel } from "./InsightsCarousel";
import { Divider } from "@mui/material";
import { ProfileContext } from "../../contexts/ProfileContext";
import { AppContext } from "../../contexts/AppContext";
import PrivateRoute from "../../auth/PrivateRoute";
import FourOFourError from "../../routes/FourOFourError/FourOFourError";

const routes = [
  {
    routeName: "",
  },
  {
    routeName: "receivables_summary",
    component: <ReceivablesSummaryDrilldown />,
  },
  {
    routeName: "receivables_risk",
    component: <ReceivablesRiskDrilldown />,
  },
  {
    routeName: "days_sales_outstanding",
    component: <DaysOutstandingModuleDrilldown />,
  },
  {
    routeName: "days_payables_outstanding",
    component: <DaysOutstandingModuleDrilldown />,
  },
  {
    routeName: "aging",
    component: <AgingDrilldown />,
  },
  {
    routeName: "top_customers",
    component: <TopRisksDrilldown />,
  },
  {
    routeName: "payables_summary",
    component: <PayablesSummaryDrilldown />,
  },
  {
    routeName: "payables_coming_due",
    component: <PayablesComingDue />,
  },
];

interface DashboardProps {
  configs: any;
}

/**
 * * Defines the Dashboard Page
 */
export default function Dashboard(props: DashboardProps): React.ReactElement {
  const { path } = useRouteMatch();
  const { userName } = React.useContext(ProfileContext) as ProfileType;
  const { userStatus } = React.useContext(AppContext) as AppType;

  const getDashboardWidgets: (configs?: any) => { [key: string]: React.ReactNode } = (configs?: any) => ({
    DSO: <DaysOutstandingModuleWidget disableInfoTooltip configs={configs} />,
    AGING: <AgingWidget disableInfoTooltip configs={configs} />,
    SUMMARY: <AccountSummaryWidget disableInfoTooltip configs={configs} />,
    AR_RISK: <ReceivablesRiskWidget disableInfoTooltip />,
  });

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={() => {
          return (
            <div className="dashboard-container">
              <div className="scrollable-container">
                <OverlayScrollbarsComponent className="scroll-component" options={{ paddingAbsolute: true, autoUpdate: true }}>
                  <div className="db-wrapper">
                    {/* Header of Dashboard (Welcome) */}
                    <div className="head">
                      <p className="welcome-main">
                        {userStatus.user_name ? `Welcome, ${userName ?? userStatus.user_name}` : "Welcome to Lockstep Inbox"}
                      </p>
                      <p className="welcome-sub">
                        {userStatus.account_name
                          ? `Here’s today’s ${props.configs.dashboardType} health for ${userStatus.account_name}`
                          : "Work better with your customers and vendors."}
                      </p>
                    </div>
                    {/* Body of Dashboard (Widget Wrapper) */}
                    <div className="content">
                      <div className="row">
                        <div className="widget">
                          <div className="dashboard-widget-container">
                            <h3 className="padding-sm">{props.configs.widgets.accountingWidget.header}</h3>
                            <div className="dashboard-widget-body">
                              {
                                getDashboardWidgets(props.configs.widgets.widgetConfigs[props.configs.widgets.accountingWidget.widget1_config])[
                                  props.configs.widgets.accountingWidget.activeWidgets[0]
                                ]
                              }
                              <Divider orientation="vertical" className="row-widget-divider" />
                              {
                                getDashboardWidgets(props.configs.widgets.widgetConfigs[props.configs.widgets.accountingWidget.widget2_config])[
                                  props.configs.widgets.accountingWidget.activeWidgets[1]
                                ]
                              }
                            </div>
                          </div>
                        </div>
                        <div className="widget">
                          <div className="dashboard-widget-container">
                            <h3 className="padding-sm">{props.configs.widgets.summaryWidget.header}</h3>
                            <div className="dashboard-widget-body-small-margin dashboard-widget-body">
                              {
                                getDashboardWidgets(props.configs.widgets.widgetConfigs[props.configs.widgets.summaryWidget.widget1_config])[
                                  props.configs.widgets.summaryWidget.activeWidgets[0]
                                ]
                              }
                              <Divider orientation="vertical" className="row-widget-divider" />
                              {
                                getDashboardWidgets(props.configs.widgets.widgetConfigs[props.configs.widgets.summaryWidget.widget2_config])[
                                  props.configs.widgets.summaryWidget.activeWidgets[1]
                                ]
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </OverlayScrollbarsComponent>
              </div>
              <div className="insights-wrapper">
                <OverlayScrollbarsComponent
                  className="insights-container"
                  options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: true }}
                >
                  <div className="head">
                    <p className="title">Insights</p>
                    <InsightsCarousel configs={props.configs.cards.insights} />
                  </div>
                  <div className="top-customers">
                    <TopRisksWidget disableInfoTooltip configs={props.configs.cards.topCustomers} />
                  </div>
                </OverlayScrollbarsComponent>
              </div>
            </div>
          );
        }}
      />

      {routes &&
        routes.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE).map((val) => {
          return (
            <Route
              path={`${path}/${val.routeName}`}
              key={`${val.routeName}`}
              render={() => {
                if (val.component) {
                  return val.component;
                } else {
                  return <></>;
                }
              }}
            />
          );
        })}
      <PrivateRoute
        component={() => {
          return <FourOFourError />;
        }}
      />
    </Switch>
  );
}
