import React from "react";
import Dashboard from "../Dashboard/Dashboard";
import Activities from "../Activities/Activities";
import Customers from "../Customers/Customers";
import Invoices from "../Invoices/Invoices";
import PrivateRoute from "../../auth/PrivateRoute";
import Payments from "../Payments/Payments";
import { Switch, useRouteMatch } from "react-router-dom";

import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { ComponentConfigContext } from "../../contexts/ComponentConfigContext";
import { WorkspaceType } from "../../types/enums";
import ApplicationRouteProvider from "../../contexts/ApplicationRouteContext";
import { GlobalSearchResults } from "../GlobalSearch";

const MainContentView: React.FC = () => {
  const { path } = useRouteMatch();
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const componentConfig = getConfig(selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR) as any;
  return (
    <ApplicationRouteProvider>
      <Switch>
        <PrivateRoute
          path={`${path}/search`}
          render={() => {
            return <GlobalSearchResults />;
          }}
        />
        <PrivateRoute
          path={`${path}/dashboard`}
          render={() => {
            return <Dashboard configs={componentConfig.dashboard} />;
          }}
        />
        <PrivateRoute
          path={[`${path}/customers`, `${path}/vendors`, `${path}/connections`]}
          render={() => {
            return <Customers />;
          }}
        />
        <PrivateRoute
          path={`${path}/activities`}
          render={() => {
            return <Activities />;
          }}
        />
        <PrivateRoute path={[`${path}/invoices`, `${path}/bills`]} render={() => <Invoices componentConfig={componentConfig.invoices} />} />
        <PrivateRoute
          path={`${path}/payments`}
          render={() => {
            return <Payments configs={componentConfig.payments} />;
          }}
        />
      </Switch>
    </ApplicationRouteProvider>
  );
};

export default MainContentView;
