import { DEFAULT_NUMERIC_VALUES } from "../../constants/NumericConstants";
import { ConnectionStatus } from "../../types/enums";
import APIClient from "../APIClient";

export const formatInvoiceColumnFilters = (filters: SearchFilter[]): Record<string, string> => {
  const columnFiltersObj = {} as Record<string, string>;
  if (filters && filters.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
    filters.forEach((filter: SearchFilter) => {
      const key = filter.filterKey;
      const value = filter.filterValue;
      const token = filter?.filterToken;
      if (key === "invoice_number" || key === "customer_name") {
        columnFiltersObj[`qa[${key}_contains]`] = value.toString();
      } else if (key === "invoice_amount" || key === "outstanding_balance") {
        columnFiltersObj[`qa[${key}_eq]`] = value.toString();
      } else if (key === "status" || key === "days_past_due") {
        columnFiltersObj[`qa[${key}_${token}]`] = value.toString();
      }
    });
  }
  return columnFiltersObj;
};
export class InvoicesClient {
  getInvoices(
    pageSize?: number,
    page?: number,
    order?: string,
    filters?: string,
    spamFraudArchivedTab?: boolean
  ): Promise<InvoiceModelFetchResultV2> {
    let url = `inbox/api/v2/invoices?page=${page}&page_size=${pageSize}`;
    if (order) {
      url = `${url}&${order}`;
    }
    if (!spamFraudArchivedTab) {
      url = `${url}&connection_status_not_in=${ConnectionStatus.SPAM},${ConnectionStatus.FRAUD},${ConnectionStatus.ARCHIVED}`;
    }
    if (filters) {
      url = `${url}&${filters}`;
    }
    return APIClient.get(url).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        records: response.data.data,
      };
    });
  }

  exportInvoices(filters: any): Promise<APIResponse> {
    let url = `inbox/api/v2/invoices.csv`;
    if (filters) {
      url = `${url}?${filters}`;
    }
    return APIClient.get(url).then((response) => response.data);
  }

  getSummary(filter?: string, spamFraudArchivedTab = false): Promise<APIResponse> {
    let url = `inbox/api/v2/invoices/summary`;

    if (filter) {
      url = `${url}?${filter}`;
    }

    const options = {
      params: {
        connection_status_not_in: spamFraudArchivedTab ? "" : `${ConnectionStatus.SPAM},${ConnectionStatus.FRAUD},${ConnectionStatus.ARCHIVED}`,
      },
    };
    return APIClient.get(url, options).then((response) => response.data);
  }

  /**
   * * GET on /api/v1/Invoices/views/summary
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type InvoiceSummaryModelFetchResult or Exception
   */
  getAtRiskSummaries(
    filter?: string,
    include?: string,
    order?: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<AtRiskInvoiceSummaryModelFetchResultV2> {
    const url = "inbox/api/v2/invoices/invoice_at_risk_summary";

    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        records: response.data.data,
      };
    });
  }
}
