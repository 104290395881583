import React from "react";
import { InsightsCardInterface } from "./InsightsInterface/InsightsInterface";
import "./InsightsStyles/InsightsCardStyles.scss";
import clsx from "clsx";
import Loading from "../../library/Loading/Loading";
import { CostCenter, Email, Notes, Payments, Phone, Invoices } from "../../library/Icons/Icons";

const InsightsCard: React.FC<InsightsCardInterface> = ({
  summary,
  data_error = false,
  error_message = "Error Loading Data",
  loading = false,
}: InsightsCardInterface) => {
  const carouselIcons: { [key: string]: React.ReactElement } = {
    Emails: <Email />,
    "Phone Calls": <Phone />,
    Notes: <Notes />,
    "Outstanding AR": <CostCenter />,
    "Outstanding AP": <CostCenter />,
    "Past Due AR": <CostCenter />,
    "Coming Due Next 30 Days": <CostCenter />,
    "Bills Paid": <CostCenter />,
    Payments: <Payments />,
    "Total Paid": <Payments />,
    "Bills Entered": <Invoices />,
    "Total Bills Entered": <Invoices />,
  };

  return (
    <div className={clsx("insights-summary-container", { ["data-error"]: data_error })}>
      {loading && <Loading />}
      {!loading && (
        <>
          {!data_error && (
            <>
              <div className="insights-summary-head">
                <div className="insights-summary-header">
                  <p className="h4">
                    <>{summary?.header ?? ""}</>
                  </p>
                </div>
                <div className="insights-summary-sub-header">
                  <p className="body1">
                    <>{summary?.subheader ?? ""}</>
                  </p>
                </div>
              </div>
              <div className="insights-summary-body">
                {summary?.body instanceof Array &&
                  summary?.body?.map((item, index) => (
                    <div className="item-container" key={index}>
                      <div className="item-key body1">
                        {carouselIcons[item?.item_key]}
                        {item?.item_key}
                      </div>
                      <div className="item-value caption">{item?.item_value}</div>
                    </div>
                  ))}
              </div>
            </>
          )}
          {data_error && <p className="error_message">{error_message}</p>}
        </>
      )}
    </div>
  );
};

export default InsightsCard;
