import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { MoveActivityProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

const MoveActivity: React.FC<MoveActivityProps> = ({ connectionName, creator, note }) => {
  const headerMessage = `${Labels.HEADER_MOVED_STREAM}${connectionName ? ` to ${connectionName}` : ""}`;

  return (
    <SecondaryActivity variant={SecondaryType.Accordion} creator={{ ...creator, message: headerMessage }}>
      <p>{note ? note : `This activity stream now belongs to ${connectionName}.`}</p>
    </SecondaryActivity>
  );
};

export default MoveActivity;
