/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { MouseEvent, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../../constants/NumericConstants";
import { attachmentsClientV2 } from "../../../../db/version2Accessor";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import { Download, Upload } from "../../../library/Icons/Icons";
import Button from "../../../library/Button/Button";
import { FileUploader } from "../../../library/FileUploader";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";
import { TextField } from "@mui/material";
import Table from "../../../library/Table/Table";
import useWorkspaceConfigurations from "../../../../hooks/useWorkspaceConfigurations";
import { FallbackTypes } from "../../../../types/enums";
import { CustomerContext } from "../../../../contexts/CustomerContext";

interface DocumentsProps {
  configs: any;
}
/**
 * * Define the Document Tab of the Customer Detail's page
 */
export default function Documents(props: DocumentsProps): React.ReactElement {
  const tableRef = useRef<TableHandle>(null);
  const { customerId } = useParams<{ customerId: string }>();
  const params = new URLSearchParams(window.location.search);
  const pageNoFromUrl = params.get("page");

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION_VALUES.PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "1");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
    } else {
      return pageNum;
    }
  });
  const [fileType, setFileType] = React.useState<string>("W-9");
  const [companyData] = useState<CompanyModel>({} as CompanyModel);
  const [fileUploaderOpen, setFileUploaderOpen] = React.useState<boolean>(false);

  const { selectedWorkspace } = useWorkspaceConfigurations();
  const { toggleDataUpdated } = React.useContext(CustomerContext) as CustomerType;

  // table configuration
  const ellipsisActionHandlers: Partial<RowSelectButtons> = {
    "action.downloadAll": TableUtils.downloadAll,
  };
  const ellipsisActions: RowSelectButtons = props.configs.table.ellipsisActions.map((action: EllipsisAction) => {
    return { ...action, clickFunction: ellipsisActionHandlers[action.handler] };
  });

  const cellRendererMapping: CellRendererMappingObject = {
    "table.formatString": (props: { value: string | null }) => TableUtils.formatString(props.value, false, "contact-bold"),
    "table.formatDate": (props: { value: string | null }) => TableUtils.formatDate(props.value, false, "contact-bold"),
    "table.formatFunction": (props: { value: string | null; row: { original: { status: string } } }) => {
      return TableUtils.formatAction([props.row.original], ellipsisActions);
    },
  };

  const fetchParser = (fetchResult: any, variant?: FetchVariant): Partial<TableFetchResult>[] => {
    return (
      fetchResult?.records?.map((record: any) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.id,
          }),
          ...((variant === "export" || variant === "all") && {
            file_name: record.file_name,
            owner: record.owner?.name,
            file_type: record.file_type,
            status: record.status,
            created_at: record.created_at,
            file_url: record.file_url,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const toggleFileUpload = () => {
    if (fileUploaderOpen) {
      tableRef?.current?.softRefresh();
      setLoading(false);
    }
    setFileUploaderOpen(!fileUploaderOpen);
    toggleDataUpdated();
  };

  const fetchDocumentsTableData = (): Promise<any> => {
    const sortQuery = TableUtils.sortQueryParser(TableUtils.getSortFromURL(params) ?? "", true) ?? "";
    const filterQuery = TableUtils.columnFilterParser(TableUtils.getFilterFromURL(params, props.configs.table.columns), true);
    const filters = `filter_by=connection&connection_id=${customerId}${filterQuery ? `&${filterQuery}` : ""}`;
    return attachmentsClientV2.getAttachments(filters, sortQuery, pageNumber, pageSize);
  };

  const handleDownloadDocument = (ids: string[]) => {
    const urls = tableData
      .map((item) => {
        const url = ids.find((id) => parseInt(id) === parseInt(item.id));
        if (url) {
          return item;
        }
      })
      .filter((element) => !!element);
    TableUtils.downloadAll(urls);
  };

  function handleRowClick(e: MouseEvent<HTMLTableRowElement>, row: { original: { id?: string } }) {
    TableUtils.downloadAll([row.original]);
  }

  return (
    <div className="documents-wrapper">
      <Table
        ref={tableRef}
        dataSets={[
          {
            id: "Documents",
            displayName: "Documents",
            data: {
              tableData: tableData,
              setTableData: setTableData,

              fetchCall: fetchDocumentsTableData,
              fetchParser: fetchParser,
            },
            columns: props.configs.table.columns.map((column: ColumnHeaderType) => {
              return { ...column, Cell: cellRendererMapping[column.CellRenderer] };
            }),
            handleRowClick: handleRowClick,
            defaultSortToken: "DESC",
          },
        ]}
        states={{
          isLoading: isLoading,
          setLoading: setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: totalCount,
          setTotalCount: setTotalCount,
          setPageSize: setPageSize,
          startingIndex: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
        }}
        toggles={{
          showSearchbar: false,
          showExportBtn: false,
          showCaption: true,
          showRowSelect: true,
          showNavigation: true,
          showSelectAllBar: false,
        }}
        rowSelectBtns={[
          {
            icon: <Download />,
            variant: "secondary",
            alignIcon: "left",
            children: "Download",
            callback: (ids: string[]) => {
              handleDownloadDocument(ids);
            },
          },
        ]}
        headerBtns={[
          {
            component: (
              <Button icon={<Upload />} alignIcon="left" onClick={() => setFileUploaderOpen(true)}>
                Upload
              </Button>
            ),
            hideOnRowSelect: false,
          },
        ]}
      />
      <FileUploader
        open={fileUploaderOpen}
        toggleFunction={toggleFileUpload}
        tableName={"Company"}
        objectId={companyData.companyId ?? ""}
        attachmentType={fileType}
        origin={"connections"}
        customerId={customerId}
        workspaceId={selectedWorkspace?.id ?? FallbackTypes.Id}
        setParentLoader={setLoading}
      >
        <p className="caption-med caption-primary-dark">Choose Your File Type</p>
        <Autocomplete
          options={["W-9", "W-8BEN", "Payment Instructions"]}
          blurOnSelect
          onChange={async (_event, value: string) => {
            setFileType(value);
          }}
          value={fileType}
          disableClearable
          disablePortal
          fullWidth
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                placeholder: "Choose A File Type",
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => {
            return option === value;
          }}
        />
      </FileUploader>
    </div>
  );
}
