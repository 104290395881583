import React, { useState } from "react";

import { invoicesClient } from "../db/accessor";

export const InvoiceContext = React.createContext<InvoiceType | null>(null);

export interface InvoiceProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const InvoiceProvider: React.FC<InvoiceProviderProps> = ({ children }: InvoiceProviderProps) => {
  // Current Invoice customer name (Shown on invoice navbar)
  const [isInvoiceDataLoading, setInvoiceDataLoading] = useState<boolean>(false);
  const [customerName, setCustomerName] = useState<string | null>(null);
  const [invoiceData, setInvoiceData] = useState<InvoiceModel>({} as InvoiceModel);
  const [lastFilterItem, setLastFilterItem] = useState<string>("");
  const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);

  async function getInvoiceSummaryExportData(): Promise<Omit<InvoicesGridData, "id">[]> {
    return await invoicesClient.getInvoiceSummaries().then((data) => {
      return (
        data.records?.map((val: InvoiceSummaryModel) => {
          return {
            invoice_number: val.invoiceNumber,
            customer_name: val.customerName,
            status: val.status,
            due_date: val.paymentDueDate,
            outstanding_amount: val.outstandingBalance,
          };
        }) ?? []
      );
    });
  }

  // GET on /api/v1/Invoices/{id}
  async function get(id: string): Promise<InvoiceModel> {
    return invoicesClient.getInvoice(id, "Company,Customer,Payments,CreditMemos,Attachments").then((data) => {
      setCustomerName(data?.company?.companyName ?? "N/A");
      return data;
    });
  }
  /**
   * Calling API to retrieve the PDF for invoices and bills
   * GET api/v1/invoices/{transactionId}/pdf
   */
  function downloadTransaction(transactionId: UUID): Promise<any> {
    return invoicesClient.getTransactionPDF(transactionId);
  }

  return (
    <InvoiceContext.Provider
      value={{
        isInvoiceDataLoading,
        setInvoiceDataLoading,
        get,
        getInvoiceSummaryExportData,
        customerName,
        setInvoiceData,
        invoiceData,
        lastFilterItem,
        setLastFilterItem,
        allRowsSelected,
        setAllRowsSelected,
        downloadTransaction
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};

export default InvoiceProvider;
