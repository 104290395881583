import React from "react";
import { User, Payments, Date, Currency } from "../../components/library/Icons/Icons";
import Gmail from "../../assets/ConnectGmailLogo.svg";
import Outlook from "../../assets/OutlookConnector.svg";
import GmailButton from "../../assets/GmailLogo.svg";
import OutlookButton from "../../assets/OutlookLogo.svg";

const iconMap = new Map<string, string>();
iconMap.set("GmailLogo", Gmail);
iconMap.set("OutlookLogo", Outlook);

export const getIconURL = (icon: string): string => {
  return iconMap.get(icon) ?? "";
};

const connectorButtonMap = new Map<string, string>();
connectorButtonMap.set("GmailLogo", GmailButton);
connectorButtonMap.set("OutlookLogo", OutlookButton);

export const getConnetorIconURL = (icon: string): string => {
  return connectorButtonMap.get(icon) ?? "";
};

const icons: Record<string, React.ReactNode> = Object.freeze({
  UserSVG: <User />,
  PaymentSVG: <Payments />,
  DateSVG: <Date />,
  CurrencySVG: <Currency />,
});

export const getIcon = (iconName: string): React.ReactNode => {
  return icons[iconName];
};
