import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { reportsClient } from "../../../../../db/accessor";
import "../../../../library/APHeader/APHeader.scss";
import { PAYABLES_COMING_DUE_HEADER_SKELETON_CONSTANTS } from "../../../../../constants/StyleConstants";
import Utils from "../../../../../utils/utils";
import { AppContext } from "../../../../../contexts/AppContext";

type HeaderType = {
  type: string;
  customLabel?: string;
};

export interface Props {
  list: HeaderType[];
  reportDate: string;
}
export default function PayablesComingDueHeader(props: Props): React.ReactElement {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [PayablesComingDueHeader, setPayablesComingDueHeader] = useState<PayablesComingDueHeader>({} as PayablesComingDueHeader);
  const { userStatus } = React.useContext(AppContext) as AppType;

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(false);
      try {
        await reportsClient.getPayablesComingDueHeader(props.reportDate).then((data) => {
          setPayablesComingDueHeader(data);
        });
      } catch (error: any) {
        setError(true);
        console.log(error);
      }
      setLoading(false);
    })();
  }, [location.pathname]);
  return (
    <div className="APHeader-wrapper">
      {isLoading ? (
        props.list.map((_, index: number) => {
          return (
            <Skeleton
              key={`APHeader-${index}`}
              variant="text"
              width={Utils.getConvertedSizeInRem(PAYABLES_COMING_DUE_HEADER_SKELETON_CONSTANTS.WIDTH)}
              height={Utils.getConvertedSizeInRem(PAYABLES_COMING_DUE_HEADER_SKELETON_CONSTANTS.HEIGHT)}
              animation="wave"
            />
          );
        })
      ) : isError ? (
        <span className="text-span">
          <span className="text-label">Oops! Something Went Wrong... </span>
        </span>
      ) : (
        props.list.map((item: HeaderType, index: number) => {
          let label = "";
          let value: string | number = "";
          switch (item.type) {
            case "totalVendors":
              label = item.customLabel ?? "Vendors";
              value = PayablesComingDueHeader.numberOfVendors ?? "N/A";
              break;

            case "totalBillsPastDue":
              label = item.customLabel ?? "Bills Due";
              value = PayablesComingDueHeader.numberOfBillsDue ?? "N/A";
              break;

            case "totalPastDueAmount":
              label = item.customLabel ?? "Total Amount Due";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(PayablesComingDueHeader.totalAmountDue ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;

            case "percentageOfTotalAp":
              label = item.customLabel ?? "% of Total Payable";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "percent",
              }).format(PayablesComingDueHeader.percentageOfTotal / NUMERIC_VALUES.CONSTANT_HUNDRED ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            default:
              label = "INVALID TYPE";
              break;
          }
          return (
            <span className="text-span" key={`APHeader-${index}`}>
              {value} <span className="text-label">{label}</span>
            </span>
          );
        })
      )}
    </div>
  );
}
