export const SNOOZE_LIST = [
  { id: "12 hours", value: "Snooze for 12 Hours", valueInEpoch: 43200 },
  { id: "24 hours", value: "Snooze for 24 Hours", valueInEpoch: 86400 },
  { id: "2 Days", value: "Snooze for 2 Days", valueInEpoch: 172800 },
  { id: "3 Days", value: "Snooze for 3 Days", valueInEpoch: 259200 },
  { id: "1 Week", value: "Snooze for 1 Week", valueInEpoch: 604800 },
];

export const SENDER_SPAM_FRAUD = [
  { id: "1", value: "Mark Sender As Spam", Clickvalue: "Spam" },
  { id: "2", value: "Mark Sender As Fraud", Clickvalue: "Fraud" },
];
