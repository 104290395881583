import { Divider, Tooltip } from "@mui/material";
import React from "react";
import { NUMERIC_VALUES } from "../../../../../../../../constants/NumericConstants";
import TableUtils from "../../../../../../../../utils/TableUtils/TableUtils";
import AttachmentChip from "../../../../../../AttachmentChip/AttachmentChip";
import Button from "../../../../../../../library/Button/Button";
import { CloseDarken } from "../../../../../../../library/Icons/Icons";
import { AttachmentListProps } from "../HelperTypes";

const AttachmentsList: React.FC<AttachmentListProps> = ({ visible, items, onClickCloseAttachmentBox, children, setShowAttachmentList, showAttachmentList }) => {
  const onClickShowMore = () => {
    setShowAttachmentList(true);
  };

  const AttachmentShowMoreChip: React.FC<{ restCount: number }> = ({ restCount }) => {
    return (
      <Tooltip title={"Show More"} arrow>
        <div className="attachment-show-more-chip" onClick={() => onClickShowMore()}>
          <span className="attachment-text">{`${restCount} More`}</span>
        </div>
      </Tooltip>
    );
  };

  const onClickDownloadAll = () => TableUtils.downloadAll([...items]);

  return visible ? (
    <div className="attachments-wrapper">
      <div className="list-wrapper">
        <div className="attachments-container">
          {items?.length <= NUMERIC_VALUES.CONSTANT_THREE || showAttachmentList ? (
            items.map((item: any, key: number) => <AttachmentChip key={key} attachment={item} />)
          ) : (
            <>
              <AttachmentChip key={items[0].file_name} attachment={items[0]} />
              <AttachmentChip key={items[1].file_name} attachment={items[1]} />
              <AttachmentShowMoreChip restCount={items.length - NUMERIC_VALUES.CONSTANT_TWO} />
            </>
          )}
        </div>
      </div>
      <div className="attachments-action">
        <div className="close-container" onClick={onClickCloseAttachmentBox}>
          <CloseDarken />
        </div>
        <div className="attachments-button-wrapper">
          {/* additional children */}
          {children && (
            <>
              {children}
              <Divider flexItem orientation="vertical" />
            </>
          )}
          <Button size="sm" variant="secondary" alignIcon="left" className="btn btn-secondary downlaod-all" onClick={onClickDownloadAll}>
            Download All
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default AttachmentsList;
