import {
  DATE_CONSTANTS_IN_MIN,
  DAYS_CONSTANTS,
  DEFAULT_NUMERIC_VALUES,
  FILE_SIZE_IN_BYTES,
  ISO_DATE_SLICE,
  NUMERIC_VALUES,
} from "../constants/NumericConstants";

/* eslint-disable no-useless-escape */
import { Liquid } from "liquidjs";
import { LOCKSTEP_DIMENSIONS } from "../constants/StyleConstants";
import _ from "underscore";

class Utils {
  public static validEmail = (email: string): boolean => {
    const mailformat = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return !!email.match(mailformat);
  };

  /* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
  public static openPopup = (url: string, windowName: string, window: any, width: number, height: number): void => {
    const top = window.top.outerHeight / NUMERIC_VALUES.CONSTANT_TWO + window.top.screenY - height / NUMERIC_VALUES.CONSTANT_TWO;
    const left = window.top.outerWidth / NUMERIC_VALUES.CONSTANT_TWO + window.top.screenX - width / NUMERIC_VALUES.CONSTANT_TWO;

    return window.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  };

  public static getFullStoryConfig(): { fullStoryId: string; forceEnableFullStory: boolean } {
    return {
      fullStoryId: "G2QXT",
      forceEnableFullStory: false,
    };
  }

  public static getIntercomAppId(): string {
    return "a6npq4d0";
  }

  public static shouldEnableIntercom(): boolean {
    return this.isSandbox() || this.isProduction();
  }

  public static isLocal(): boolean {
    return process.env.REACT_APP_ENV === "local";
  }

  public static isDev(): boolean {
    return process.env.REACT_APP_ENV === "dev";
  }

  public static isDevQA(): boolean {
    return process.env.REACT_APP_ENV === "devqa";
  }

  public static isQA(): boolean {
    return process.env.REACT_APP_ENV === "qa";
  }

  public static isSandbox(): boolean {
    return process.env.REACT_APP_ENV === "sbx";
  }

  public static isProduction(): boolean {
    return process.env.REACT_APP_ENV === "prod";
  }

  public static formatPhoneNumber(number: string): string {
    const cleaned = ("" + number).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      const intlCode = match[1] ? "+1 " : "";

      return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
    }

    return number;
  }

  /**
   * A helper method to format a given to international format (comma separated)
   * @param number - The number to be formatted
   * @returns
   */
  public static formatNumber(number: number | string): string {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  /**
   * Returns proper pluralization for the supplied label based on the amount.
   *
   * @param amount Number of items being counted
   * @param label The label for a single unit of the supplied list.
   *
   * e.x. - formatLabelPluralization(2, Company) should return Companies
   */
  public static formatLabelPluralization(amount: number, label: string): string {
    // Singular
    if (amount === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      return label;
    }

    // Pluralize
    const lastChar = label.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;

    if (label.charAt(lastChar) !== "y") {
      return `${label}s`;
    } else {
      return `${label.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, lastChar)}ies`;
    }
  }

  public static testSanityCheck() {
    return true;
  }

  public static delay(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  /**
   * This function is used to consume a direct download link and trigger this in the browser.
   * @param uri direct download link object from api
   */
  public static downloadItem = async (uri: any) => {
    const url = uri.downloadLink;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.txt");
    document.body.appendChild(link);
    await Utils.delay(NUMERIC_VALUES.CONSTANT_FIVE_HUNDRED);
    link.click();
    document.body.removeChild(link);
  };

  public static roundToPrecisionPoint = (size: number, digitsAfterDecimal = DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) =>
    Math.round((size + Number.EPSILON) * NUMERIC_VALUES.CONSTANT_TEN ** digitsAfterDecimal) / NUMERIC_VALUES.CONSTANT_TEN ** digitsAfterDecimal;

  /**
   * @param fileSize size of file
   * @returns converted file size in B/KB/MB/GB
   */
  public static calculateFileSize = (fileSize: number) => {
    if (!fileSize) return null;
    if (fileSize > FILE_SIZE_IN_BYTES.BYTES && fileSize <= FILE_SIZE_IN_BYTES.KBYTES) {
      if (fileSize < FILE_SIZE_IN_BYTES.KBYTES / NUMERIC_VALUES.CONSTANT_HUNDRED) return `${fileSize}B`;
      return `${Utils.roundToPrecisionPoint(fileSize / FILE_SIZE_IN_BYTES.KBYTES, NUMERIC_VALUES.CONSTANT_TWO)}KB`;
    } else if (fileSize > FILE_SIZE_IN_BYTES.KBYTES && fileSize <= FILE_SIZE_IN_BYTES.MBYTES) {
      if (fileSize < FILE_SIZE_IN_BYTES.MBYTES / NUMERIC_VALUES.CONSTANT_HUNDRED)
        return `${Utils.roundToPrecisionPoint(fileSize / FILE_SIZE_IN_BYTES.KBYTES, NUMERIC_VALUES.CONSTANT_TWO)}KB`;
      return `${Utils.roundToPrecisionPoint(fileSize / FILE_SIZE_IN_BYTES.MBYTES, NUMERIC_VALUES.CONSTANT_TWO)}MB`;
    } else if (fileSize > FILE_SIZE_IN_BYTES.MBYTES && fileSize <= FILE_SIZE_IN_BYTES.GBYTES) {
      if (fileSize < FILE_SIZE_IN_BYTES.GBYTES / NUMERIC_VALUES.CONSTANT_HUNDRED)
        return `${Utils.roundToPrecisionPoint(fileSize / FILE_SIZE_IN_BYTES.MBYTES, NUMERIC_VALUES.CONSTANT_TWO)}MB`;
      return `${Utils.roundToPrecisionPoint(fileSize / FILE_SIZE_IN_BYTES.GBYTES, NUMERIC_VALUES.CONSTANT_TWO)}GB`;
    }
    return `${fileSize}B`;
  };

  /**
   * @param date in timestamp
   * @returns date iso string (*T*)
   */
  public static evaluateDate = (date: number, sliceStart: number = ISO_DATE_SLICE.START, sliceEnd: number = ISO_DATE_SLICE.END): string => {
    return new Date(date * NUMERIC_VALUES.CONSTANT_THOUSAND || DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO).toISOString().slice(sliceStart, sliceEnd);
  };

  /**
   * @param str in string
   * @returns capitalized string
   */
  public static capitalize = (str: string) => {
    return (
      (str?.charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)?.toUpperCase() ?? "") + (str?.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE).toLowerCase() ?? "")
    )
      ?.trim()
      ?.replace(/[\s_\t-]/gi, " ");
  };
  /**
   * @param date in timestamp
   * @returns date iso string (*T*)
   */
  public static isNonEmptyString = (str: string | undefined) => {
    return str && typeof str === "string" && str?.trim()?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
  };

  public static runTemplateEngine = (template: string, scope: any) => {
    const engine = new Liquid();
    const tpl = engine.parse(template);
    return engine.render(tpl, scope);
  };

  public static getDateDifferenceFromNow = (date_a: number, date_b = Date.now()) => {
    if (date_a === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) return "N/A";
    const getTimeElapsedDifference = Math.abs(date_a - date_b) / DATE_CONSTANTS_IN_MIN.DAY_IN_MS;
    const diffInDays = this.roundToPrecisionPoint(getTimeElapsedDifference, DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

    if (diffInDays === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) return "Today";
    else if (diffInDays >= DAYS_CONSTANTS.DAY && diffInDays < DAYS_CONSTANTS.MONTH) {
      return `${diffInDays} ${diffInDays === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? "day" : "days"} ago`;
    } else if (diffInDays >= DAYS_CONSTANTS.MONTH && diffInDays < DAYS_CONSTANTS.YEAR) {
      return `${Math.floor(diffInDays / DAYS_CONSTANTS.MONTH)} ${
        Math.floor(diffInDays / DAYS_CONSTANTS.MONTH) === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? "month" : "months"
      } ago`;
    }
  };

  public static compareArrays = (arrA: Array<string | number | null> = [], arrB: Array<string | number | null> = []) => {
    const checkInOtherArray = (val: string | number | null) => {
      return arrB.includes(val);
    };
    return arrA.sort().every(checkInOtherArray);
  };

  public static dateSortFunction = (left: string, right: string) => {
    const start = +new Date(left);
    const end = +new Date(right) - start;
    return end;
  };

  public static formatSearchFiltersArrayAsURLParamsObject(filters: SearchFilter[] | string): Record<string, string> {
    const columnFiltersObj = {} as Record<string, string>;
    if (filters && filters instanceof Array && filters.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      filters.forEach((filter: SearchFilter) => {
        const key = filter.filterKey;
        const value = filter.filterValue;
        const token = filter?.filterToken;
        columnFiltersObj[`qa[${key}_${token}]`] = value.toString();
      });
    }
    return columnFiltersObj;
  }

  public static formatValueAsCurrency = (value: number, locale = "en-US", code = "USD") => {
    const numberFormat = new Intl.NumberFormat(locale, { style: "currency", currency: code });
    return numberFormat.format(value);
  };

  public static formatTimeInMinutesAndSeconds = (timeFrom: number, timeTo: number): string => {
    let finalTimeFormat = "";
    let fromTimeString = "";
    let toTimeString = "";

    const fromMinutes = Math.floor(timeFrom / DATE_CONSTANTS_IN_MIN.HOURS);
    const fromSeconds = timeFrom % DATE_CONSTANTS_IN_MIN.HOURS;
    fromTimeString = timeFrom ? `${fromMinutes}m ${fromSeconds ? `${fromSeconds}s` : ""}` : "";

    const ToMinutes = Math.floor(timeTo / DATE_CONSTANTS_IN_MIN.HOURS);
    const ToSeconds = timeTo % DATE_CONSTANTS_IN_MIN.HOURS;
    toTimeString = `${ToMinutes}m ${ToSeconds ? `${ToSeconds}s` : ""}`;

    finalTimeFormat = fromTimeString ? `${fromTimeString} - ${toTimeString}` : toTimeString;
    return finalTimeFormat;
  };

  /**
   * This function checks if any of the transaction id is present in
   * attachment id list; if so, it returns true and false otherwise.
   *
   * @param attachmentIds list of attachment ids & uuids of invoices/payments/documents
   * @param activityTransactions list of attached transactions
   * @returns boolean
   */
  public static isThereAnyAttachment(attachmentIds: AttachmentIds, activityTransactions: TransactionItemType[]): boolean {
    if (!attachmentIds?.length || !activityTransactions?.length) return false;
    const attachmentIdsSet = new Set(attachmentIds);
    const itemFind = activityTransactions?.filter((activityTransaction) => attachmentIdsSet?.has(activityTransaction?.transaction_id));
    return !_.isUndefined(itemFind) && itemFind?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
  }

  public static getPixelByWindowWidth = (pixel: number, disableUnit?: boolean) =>
    window.innerWidth * (pixel / LOCKSTEP_DIMENSIONS.WIDTH) + (disableUnit ? "" : "px");

  public static getPixelByWindowHeight = (pixel: number, disableUnit?: boolean) =>
    window.innerHeight * (pixel / LOCKSTEP_DIMENSIONS.HEIGHT) + (disableUnit ? "" : "px");

  public static getConvertedSizeInRem = (pixel: number) => pixel / DEFAULT_NUMERIC_VALUES.DEFAULT_REM_CONVERSION_VALUE + "rem";

  public static clearMagicLinkAuthRequest = (): void => {
    localStorage.removeItem("adminGrant");
  };

  public static isMagicLinkAuthRequest = (): boolean => {
    const adminGrant = localStorage.getItem("adminGrant") ?? "";
    return adminGrant.length > NUMERIC_VALUES.CONSTANT_ZERO;
  };
}

export default Utils;
