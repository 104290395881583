import React, { Dispatch, MouseEvent, ReactElement, SetStateAction, useState } from "react";
import Button from "../Button/Button";
import { ArrowDown } from "../Icons/Icons";
import Menu from "../Menu/Menu";
import MenuItem from "../MenuItem/MenuItem";
import NewActivityPopup, { ActivityPopupRef, ActivityToRef } from "./NewActivityPopup";
import { TemplateType } from "./ActivityTemplateSelect/ActivityTemplateSelect";
import { TemplateTypes } from "../../../app/Templates/TemplateTypes";

type ActivityDropdownItem = {
  displayName: string;
  icon: ReactElement;
};

export type AddNewActivityDropdownProps = {
  key?: string;
  title?: string;
  open?: boolean;
  origin?: string;
  toTime?: number;
  defaultTo?: To[];
  defaultCc?: To[];
  defaultBcc?: To[];
  fromTime?: number;
  buttonName?: string;
  onClose?: () => void;
  forwarding?: boolean;
  defaultTitle?: string;
  replyToOptions?: To[];
  contactOptions?: To[];
  disableTitle?: boolean;
  isNudgeActivity?: boolean;
  showAddActivity?: boolean;
  resetFunction?: () => void;
  isTemplateSupport?: boolean;
  newActivityType?: string | null;
  defaultFiles?: (File | string)[];
  disableSecondaryAction?: boolean;
  defaultTemplateId?: TemplateTypes | string;
  supportedTemplateList?: TemplateType[];
  typeAheadAction?: (val: string) => void;
  editorState?: string | null;
  setNewActivityType?: (type: string) => void;
  addActivityDropdown?: ActivityDropdownItem[];
  setToTime?: Dispatch<SetStateAction<number>>;
  setShowAddActivity?: (type: boolean) => void;
  setNewActivityVisibility?: (type: boolean) => void;
  setActivityItemId?: (activityItemId: string) => void;
  replyOptions?: { replyButton: boolean; start: string; reply: To[]; replyAll: To[]; replyAllCC: To[] };
  handleSend?: (to: To[], cc: To[], bcc: To[], title: string, body: string, attachmentIds: AttachmentIds, inboxAttachments: any) => void;
  handleReplySend?: (to: To[], cc: To[], bcc: To[], title: string, body: string, attachmentIds: AttachmentIds, inboxAttachments: any) => void;
  handleReplySendClose?: (to: To[], cc: To[], bcc: To[], title: string, body: string, attachmentIds: AttachmentIds, inboxAttachments: any) => void;
  handleSendMarkClosed?: (to: To[], cc: To[], bcc: To[], title: string, body: string, attachmentIds: AttachmentIds, inboxAttachments: any) => void;
  prepareActivityBodyByTemplateID?: (templateID: string | null, setEditorState: React.Dispatch<React.SetStateAction<string>>) => void;
  defaultAttachmentsList?: AttachmentItem[];
  refs?: {
    toRef?: React.MutableRefObject<ActivityToRef>;
    popupRef?: React.MutableRefObject<ActivityPopupRef>;
  };
};

export default function AddNewActivityDropdown(props: AddNewActivityDropdownProps): React.ReactElement {
  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const dropdownMenuOpen = Boolean(dropdownMenuAnchorEl);
  const typeAheadActionHandler = (val: string) => {
    if (props.typeAheadAction) {
      props.typeAheadAction(val);
    }
  };

  const onCloseHandler = () => {
    if (props?.setShowAddActivity) {
      props?.setShowAddActivity(false);
    }
    // reset activity type on closing the pop up
    if (props?.setNewActivityType) {
      props?.setNewActivityType("");
    }
    if (props?.resetFunction) {
      props?.resetFunction();
    }
  };

  /**
   * Event handler triggered when user click on 'New Activity Stream' dropdown menu item.
   * it will open the new Activity dialog.
   *
   * @param {ActivityDropdownItem} item The item which is clicked by user.
   */
  const onClickMenuItems = (item: ActivityDropdownItem) => {
    if (props?.setNewActivityType) {
      props?.setNewActivityType(item.displayName.toLowerCase().split(" ").join("_"));
    }
    setDropdownMenuAnchorEl(null);
    if (props?.setShowAddActivity) {
      props?.setShowAddActivity(true);
    }
    props.setNewActivityVisibility?.(false);
    props.setActivityItemId?.("");
  };

  return (
    <div>
      <Button
        className={props.origin && props.origin === "index" ? " " : "add-activity-btn"}
        size={props.origin && props.origin === "index" ? "lg" : "sm"}
        icon={<ArrowDown />}
        alignIcon="right"
        onClick={(e: MouseEvent<any>) => setDropdownMenuAnchorEl(e.currentTarget)}
      >
        {props.buttonName ? props.buttonName : "Add Activity"}
      </Button>
      <Menu
        open={dropdownMenuOpen}
        anchorEl={dropdownMenuAnchorEl}
        onClose={() => setDropdownMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {props?.addActivityDropdown?.map((item: ActivityDropdownItem, index: number) => {
          return (
            <MenuItem key={index} onClick={() => onClickMenuItems(item)}>
              {item.displayName}
            </MenuItem>
          );
        })}
      </Menu>
      {props.showAddActivity && (
        <NewActivityPopup
          key={`key-add-activity-${props.newActivityType}`}
          title={props.buttonName ?? `Add Activity`}
          open={props.showAddActivity}
          contactOptions={
            props?.replyToOptions && props?.replyToOptions.length
              ? props.replyToOptions.filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i)
              : []
          }
          defaultTo={props.defaultTo ?? []}
          handleSend={props.handleReplySend}
          handleSendMarkClosed={props.handleReplySendClose}
          onClose={onCloseHandler}
          defaultTitle={`${props.defaultTitle ? props.defaultTitle : ""}`}
          disableTitle={props.disableTitle}
          activityType={props.newActivityType}
          setNewActivityType={props.setNewActivityType}
          addActivityDropdown={props.addActivityDropdown}
          typeAheadAction={typeAheadActionHandler}
          editorState={props.editorState ?? ""}
          clickDisabled={props?.isNudgeActivity}
          disableSecondaryAction={props.disableSecondaryAction}
          isTemplateSupport={props?.isTemplateSupport}
          supportedTemplateList={props?.supportedTemplateList}
          defaultTemplateId={props?.defaultTemplateId}
          prepareActivityBodyByTemplateID={props?.prepareActivityBodyByTemplateID}
          defaultCc={props?.defaultCc}
          defaultBcc={props?.defaultBcc}
          fromTime={props.fromTime}
          toTime={props.toTime}
          setToTime={props.setToTime}
          defaultAttachmentsList={props.defaultAttachmentsList ?? []}
          refs={props.refs}
        />
      )}
    </div>
  );
}
