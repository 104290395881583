import DOMPurify from "dompurify";
import React from "react";
import Button from "../../library/Button/Button";
import { BlueTick } from "../../library/Icons/Icons";
import { TextArea } from "../../library/TextArea/TextArea";
import { ApprovalComponentProps } from "../ApprovalsInterfaces";
import "./ApprovalRequest.scss";

const ApprovalRequestComponent: React.FC<ApprovalComponentProps> = ({ requestData: request, onChangeNote, onClickApprove, onClickDecline }) => {
  return (
    <div className="approval-form-container">
      <div className="left">
        <BlueTick />
      </div>
      <div className="right">
        <div className="header-wrapper">
          <div className="header">
            <h3 className="req-title">{request.title}</h3>
            <div className="req-date">{request.date}</div>
          </div>
          <div className="req-message">
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(request.message) }}></p>
          </div>
        </div>

        <TextArea onChange={onChangeNote} label="Note" placeholder="Add a note" />
        <div className="btns-wrapper">
          <Button size="lg" type="button" variant="primary" onClick={onClickApprove}>
            Approve
          </Button>
          <Button size="lg" type="button" variant="secondary" onClick={onClickDecline}>
            Decline
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApprovalRequestComponent;
