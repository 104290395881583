import APIClient from "../APIClient";
/**
 * Reprts client for V2 API's
 */
export class ReportsClient {
  /**
   * @param workspaceId : active workspace id
   * @returns
   */
  getDSO(workspaceId: number) {
    const url = `inbox/api/v2/workspaces/${workspaceId}/reports/dailysalesoutstanding`;
    return APIClient.get(url).then((response) => response.data);
  }
  /**
   * @param workspaceId : active workspace id
   * @returns
   */
  getCashFlow(workspaceId: number) {
    const url = `inbox/api/v2/workspaces/${workspaceId}/reports/cashflow`;
    return APIClient.get(url).then((response) => response.data);
  }
  /**
   * @param workspaceId : active workspace id
   * @returns
   */
  getRiskRate(workspaceId: number) {
    const url = `inbox/api/v2/workspaces/${workspaceId}/reports/riskrate`;
    return APIClient.get(url).then((response) => response.data);
  }
  /**
   * @param workspaceId : active workspace id
   * @returns
   */
  getARAging(workspaceId: number) {
    const url = `inbox/api/v2/workspaces/${workspaceId}/reports/ar-aging-header`;
    return APIClient.get(url).then((response) => response.data);
  }
  /**
   * @param workspaceId : active workspace id
   * @returns
   */
  getDPO(workspaceId: number) {
    const url = `inbox/api/v2/workspaces/${workspaceId}/reports/daily-payable-outstanding`;
    return APIClient.get(url).then((response) => response.data);
  }
  /**
   * @param workspaceId : active workspace id
   * @returns
   */
  getAPAging(workspaceId: number) {
    const url = `inbox/api/v2/workspaces/${workspaceId}/reports/ap-aging-header`;
    return APIClient.get(url).then((response) => response.data);
  }
  /**
   * @param workspaceId : active workspace id
   * @returns
   */
  getPayablesComingDue(workspaceId: number) {
    const url = `inbox/api/v2/workspaces/${workspaceId}/reports/payables-coming-due`;
    return APIClient.get(url).then((response) => response.data);
  }

  /**
   * @param workspaceId : active workspace id
   * @returns
   */
  getPayablesComingDueSummary(workspaceId: number, order?: string, filter?: string, page?: number, page_size?: number) {
    const url = `inbox/api/v2/workspaces/${workspaceId}/reports/payables-coming-due-summary`;

    const options = {
      params: {
        order,
        filter,
        page,
        page_size,
      },
    };

    return APIClient.get(url, options).then((response) => ({
      pageNumber: response.data.page_number,
      pageSize: response.data.page_size,
      totalCount: response.data.total_count,
      data: response.data.data,
    }));
  }

  /**
   * @param workspaceId : active workspace id
   * @returns
   */
  getPayablesSummary(workspaceId: number) {
    const url = `inbox/api/v2/workspaces/${workspaceId}/reports/payables-summary`;
    return APIClient.get(url).then((response) => response.data);
  }
}
