import APIClient from "../APIClient";

export class WorkspaceClient {
  /**
   * * POST on  /api/v2/workspaces
   * @returns Promise of type APIResponse Model or Exception
   */
  createWorkspace(body: WorkspaceCreate, bearer?: string, overrideMSAL = true): Promise<APIResponse> {
    const url = `inbox/api/v2/workspaces`;

    const options = bearer
      ? {
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
        }
      : undefined;

    return APIClient.post(url, body, options, overrideMSAL).then((response) => {
      return response.data;
    });
  }

  /**
   * * GET on  /api/v2/workspaces
   * @returns Promise of type APIResponse Model or Exception
   */
  getAllWorkspaces(): Promise<APIResponse> {
    const url = `inbox/api/v2/workspaces`;

    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }

  /**
   * * Delete on  /api/v2/workspaces/workspaceId
   * @returns Promise of type APIResponse Model or Exception
   */
  deleteWorkspace(workspaceId: number, delete_data = false): Promise<APIResponse> {
    const url = `inbox/api/v2/workspaces/${workspaceId}?delete_data=${delete_data}`;

    return APIClient.delete(url).then((response) => {
      return response.data;
    });
  }

  /**
   * * Patch on  /api/v2/workspaces
   * @returns Promise of type APIResponse Model or Exception
   */
  updateWorkspace(workspaceId: number, workspaceName: string, defaultMarker: boolean): Promise<APIResponse> {
    const url = "inbox/api/v2/workspaces/";

    const body = {
      id: workspaceId,
      name: workspaceName,
      default: defaultMarker,
    };

    return APIClient.patch(url, body).then((response) => {
      return response.data;
    });
  }
}
