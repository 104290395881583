import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { ClosedProps } from "../ActivityItemVariantTypes";
import * as configValue from "../../../../../../../constants/config";

const Closed: React.FC<ClosedProps> = ({ creator }) => {
  return <SecondaryActivity variant={SecondaryType.Base} creator={{ ...creator, message: configValue.HEADER_ACTIVITY_CLOSE }} />;
};

export default Closed;
