import React from "react";
import Chip from "../../../library/Chip/Chip";
import { formatDate } from "../../../../db/utils/date";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@mui/material";
import "./Header.scss";
import { ActivityStatus, WorkspaceType } from "../../../../types/enums";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { ComponentConfigContext } from "../../../../contexts/ComponentConfigContext";
import DocumentSwitcher from "../../../library/DocumentSwitcher/DocumentSwitcher";
import { ApplicationRouteContext } from "../../../../contexts/ApplicationRouteContext";
import Utils from "../../../../utils/utils";
import { ACTIVITY_DETAILS_SKELETON_CONSTANTS } from "../../../../constants/StyleConstants";
import { SmartText } from "../../../library/SmartText";

interface HeaderProps {
  name: string;
  status: string | null;
  subject: string;
  startedDate: string;
  companyId: string;
  loading: boolean;
  erpConnection: boolean;
  highlighterConfig: HighLighterConfig;
}

export default function Header(props: HeaderProps): React.ReactElement {
  const history = useHistory();
  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const componentConfig = getConfig(selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR) as any;
  const { updateBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;

  const onClickCustomerName = () => {
    const base = `${workspaceHomePath}/${
      props.erpConnection ? componentConfig.connections.routeType : componentConfig.otherConnections.routeType
    }/active/${props.companyId}`;
    updateBaseRoute(base);
    history.push(base);
  };

  if (props.loading) {
    return (
      <div className="activity-detail-head">
        <div className="amounts-wrapper">
          <Skeleton
            variant="text"
            width={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.HEADER.SUBJECT_WIDTH)}
            height={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.HEADER.COMMON_HEIGHT)}
            animation="wave"
          />
        </div>
        <div className="mid-section">
          <Skeleton
            variant="text"
            width={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.HEADER.OTHER_WIDTH)}
            height={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.HEADER.COMMON_HEIGHT)}
            animation="wave"
          />
          <Skeleton
            variant="text"
            width={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.HEADER.OTHER_WIDTH)}
            height={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.HEADER.COMMON_HEIGHT)}
            animation="wave"
          />
        </div>
        <div className="customer">
          <Skeleton
            variant="text"
            width={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.HEADER.OTHER_WIDTH)}
            height={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.HEADER.COMMON_HEIGHT)}
            animation="wave"
          />
        </div>
        <DocumentSwitcher />
      </div>
    );
  }

  const parseStatusToChipVariant = (activityStatus: string | null) => {
    switch (activityStatus) {
      case ActivityStatus.WaitingForResponse.toLowerCase():
        return "not-started";
      case ActivityStatus.Active.toLowerCase():
        return "active";
      case ActivityStatus.Closed.toLowerCase():
        return "closed";
      case ActivityStatus.Snoozed.toLowerCase():
        return "snoozed";
      default:
        return "active";
    }
  };

  return (
    <div className="activity-detail-head">
      <div className={`amounts-wrapper`}>
        <div>
          <p className="dark">
            <SmartText
              title={props.subject}
              highlighter={{
                enableHighlighter: props?.highlighterConfig?.isHighlighting,
                matchText: props?.highlighterConfig?.query ?? "~",
              }}
              className="gsw-suggestion-listItem-subheader"
            />
          </p>
          <span className="light">Subject</span>
        </div>
      </div>
      <div className="mid-section">
        <div>
          <span className="dark">
            <SmartText
              title={formatDate(props.startedDate)}
              highlighter={{
                enableHighlighter: props?.highlighterConfig?.isHighlighting,
                matchText: props?.highlighterConfig?.query ?? "~",
              }}
              className="gsw-suggestion-listItem-subheader"
            />
          </span>
          <span className="light">Activity Started</span>
        </div>
        <div>
          <Chip text={props.status?.toUpperCase().split("_").join(" ") ?? "ACTIVE"} variant={parseStatusToChipVariant(props.status)} />
        </div>
      </div>
      <div className="customer" onClick={onClickCustomerName}>
        <div className={`tag`}>{props.name.charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) ?? ""}</div>
        <p className={`customerName`}>
          <SmartText
            title={props.name ?? ""}
            highlighter={{
              enableHighlighter: props?.highlighterConfig?.isHighlighting,
              matchText: props?.highlighterConfig?.query ?? "~",
            }}
            className="gsw-suggestion-listItem-subheader"
          />
        </p>
      </div>
      <DocumentSwitcher />
    </div>
  );
}
