import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { AxiosError } from "axios";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_TIMEOUTS } from "../../../constants/NumericConstants";
import { DETAIL_SKELETON_DEFAULT_SIZING } from "../../../constants/StyleConstants";
import { ActivityContext } from "../../../contexts/ActivityContext";
import { AlertContext } from "../../../contexts/AlertContext";
import { AppContext } from "../../../contexts/AppContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { emailsClientV2 } from "../../../db/version2Accessor";
import { FallbackTypes } from "../../../types/enums";
import Utils from "../../../utils/utils";
import NewActivityPopup from "../AddNewActivityDropdown/NewActivityPopup";
import { Copy as CopySVG, QuestionMark as QuestionMarkSVG } from "../../library/Icons/Icons";
import TrackingUtils from "../../Tracking/Tracking.Utils";
import "./Detail.scss";

type column = {
  title?: string;
  value: string | null;
  format?: "tel" | "email" | "link";
  copyToClipboard?: boolean;
  toolTip?: string;
};

type DetailProps = {
  className?: string;
  icon?: React.ReactNode;
  columns?: column | column[];
  isLoading?: boolean;
  skeletonStyles?: {
    tagWidth?: number;
    tagHeight?: number;
    titleWidth?: number;
    titleHeight?: number;
    textWidth?: number;
    textHeight?: number;
  };
  handleTransactionActivity?: React.Dispatch<React.SetStateAction<boolean>>;
};

function Detail(props: DetailProps, ref: any): React.ReactElement {
  const { path } = useRouteMatch();
  const [textFade, setTextFade] = useState("fade-out");
  const [newActivity, setNewActivity] = useState<boolean>(false);
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const { customerId } = useParams<{ customerId: string }>();
  const [toEmailId, setToEmailId] = useState<To[]>([]);

  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { handleClose } = React.useContext(ActivityContext) as ActivityType;
  const { getContactsOptions, allContactOptions } = React.useContext(AppContext) as AppType;

  const {
    tagWidth = DETAIL_SKELETON_DEFAULT_SIZING.TAG_WIDTH,
    tagHeight = DETAIL_SKELETON_DEFAULT_SIZING.TAG_HEIGHT,
    titleWidth = DETAIL_SKELETON_DEFAULT_SIZING.TITLE_WIDTH,
    titleHeight = DETAIL_SKELETON_DEFAULT_SIZING.TITLE_HEIGHT,
    textWidth = DETAIL_SKELETON_DEFAULT_SIZING.TEXT_WIDTH,
    textHeight = DETAIL_SKELETON_DEFAULT_SIZING.TEXT_HEIGHT,
  } = props?.skeletonStyles ?? {};

  const closeParentComponent = () => {
    if (props.handleTransactionActivity) {
      props.handleTransactionActivity(false);
    }
  };

  // Copies email to clipboard and fades in and out the copied text
  function onCopyToClipBoard(email?: string) {
    if (email) {
      navigator.clipboard.writeText(email ?? "N/A");
    } else {
      navigator.clipboard.writeText((props.columns as column).value ?? "N/A");
    }
    setTextFade(() => "fade-in");
    setTimeout(() => {
      setTextFade(() => "fade-out");
    }, NUMERIC_TIMEOUTS.CUSTOM.LIBRARY_DETAIL_FADE_OUT);
  }

  const ApiErrorCode400 = 400;

  /**
   * @function getCompanyIdForFirstTo
   * A helper function to get the company id for the first contact selected
   * @param to - Contacts
   * @returns
   */
  const getCompanyIdForFirstTo = (to: To[]) => {
    if (to && to.length) {
      return to[0].companyId;
    }
    return "";
  };

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[],
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "Messages Sent" };

    return new Promise((resolve) => {
      emailsClientV2
        .post({
          to: getEmailArray(to),
          cc: getEmailArray(cc),
          bcc: getEmailArray(bcc),
          subject: title,
          content: body,
          email_action: "new",
          workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
          attachments: attachmentIds.filter((item) => typeof item === "number"),
          contact_company_id: getCompanyIdForFirstTo(to),
          content_type: "input_email",
          activity_type: "email",
          primary_connection_id: customerId ? customerId : "",
          activity_transactions: inboxAttachments,
          attach_pdf: Utils.isThereAnyAttachment(attachmentIds, inboxAttachments),
        })
        .then(async (emailResponse: ActivityStream) => {
          TrackingUtils.trackFSData(to, "New Activity Stream", "New", isSendAndClose ?? false, allContactOptions, path, path, "email");
          setNewActivity(false);
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === ApiErrorCode400) {
            toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: "Activity Closed" };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: "Activity was not closed",
          };
        }
        setToastOptions(toastOptions);
      }
    }
  };

  const getConnectionContacts = async () => {
    setCompanyContactOptions(await getContactsOptions(customerId));
  };

  const handleActivityPopup = (emailId: string) => {
    const to = {
      id: emailId,
      label: emailId,
    };
    setToEmailId([to]);
    setNewActivity(true);
  };

  useEffect(() => {
    if (
      (props?.columns instanceof Array &&
        props?.columns?.filter((col) => col?.format?.toLowerCase() === "email")?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) ||
      (!(props?.columns instanceof Array) && props?.columns?.format === "email")
    ) {
      if (customerId) {
        getConnectionContacts();
      }
    }
  }, []);

  useImperativeHandle(ref, () => ({
    hideDetailActivityComponent() {
      setNewActivity(false);
    },
  }));

  const filteredContactOptions = [...companyContactOptions, ...allContactOptions];

  if (props.isLoading) {
    return (
      <div className={`detail-wrapper ${props.className ?? ""}`}>
        {props?.icon && (
          <div className="tag">
            <Skeleton
              variant="rectangular"
              width={Utils.getConvertedSizeInRem(tagWidth)}
              height={Utils.getConvertedSizeInRem(tagHeight)}
              animation="wave"
            />
          </div>
        )}
        <div className="col-grp">
          <div className="col" key="title">
            <p className="title">
              <Skeleton
                variant="text"
                width={Utils.getConvertedSizeInRem(titleWidth)}
                height={Utils.getConvertedSizeInRem(titleHeight)}
                animation="wave"
              />
            </p>
            <div className="text-wrapper">
              <Skeleton
                variant="text"
                width={Utils.getConvertedSizeInRem(textWidth)}
                height={Utils.getConvertedSizeInRem(textHeight)}
                animation="wave"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render Columns Array
  if (props.columns && Array.isArray(props.columns)) {
    // All values are null
    if (props.columns.every((column: column) => column.value === null)) {
      return <></>;
    }
    // At least one value is not null
    return (
      <>
        <div className={`detail-wrapper ${props.className ?? ""}`}>
          <div className="tag">{props.icon}</div>
          <div className="col-grp">
            {props.columns.map((column: column, index: number) => {
              return column.value ? (
                <div className="col" key={index} style={{ gap: column.title ? "0.25rem" : "0" }}>
                  {column.title && (
                    <p className="title">
                      {column.title}
                      {column.toolTip && (
                        <Tooltip title={column.toolTip} placement="right" arrow>
                          <IconButton size="small">
                            <QuestionMarkSVG />
                          </IconButton>
                        </Tooltip>
                      )}
                    </p>
                  )}
                  <div className="text-wrapper">
                    {column.format === "email" && column?.value ? (
                      <div
                        className="link"
                        onClick={() => {
                          handleActivityPopup(column.value || "");
                          closeParentComponent();
                        }}
                      >
                        {column?.value}
                      </div>
                    ) : column.format == "link" ? (
                      <a className="link" href={`https://${column?.value}`}>
                        {column?.value}
                      </a>
                    ) : column.format === "tel" ? (
                      <p className="text">{Utils.formatPhoneNumber(column?.value ?? "N/A")}</p>
                    ) : (
                      <p className="text">{column?.value}</p>
                    )}
                    {column.copyToClipboard && (
                      <>
                        <CopySVG onClick={() => onCopyToClipBoard(column?.value ?? "N/A")} />
                        <div className={textFade}>Copied</div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                ""
              );
            })}
          </div>
        </div>
        {newActivity ? (
          <NewActivityPopup
            open={newActivity}
            onClose={() => setNewActivity(false)}
            title={"New Activity"}
            defaultTo={toEmailId}
            activityType={"email"}
            // [HACK ALERT]: Because of unexpected toggleCC function from in NewActivity.tsx will clear the TO filed and because of that we need to
            // apply below hack.
            replyOptions={{ replyButton: false, start: "", reply: toEmailId, replyAll: [], replyAllCC: [] }}
            contactOptions={filteredContactOptions}
            handleSend={handleSend}
            handleSendMarkClosed={handleSendAndMarkClosed}
          />
        ) : null}
      </>
    );
  }

  const value = props.columns?.value || "";
  const icon = props.icon ? <div className="tag">{props.icon}</div> : <></>;

  // Render Columns object
  if (props.columns && !Array.isArray(props.columns)) {
    return props.columns?.value ? (
      <>
        <div className={`detail-wrapper ${props.className ?? ""}`}>
          {icon}
          <div className="col-grp">
            <div className="col" key="title" style={{ gap: props.columns.title ? "0.25rem" : "0" }}>
              {props.columns.title && (
                <p className="title">
                  {props.columns.title}
                  {props.columns.toolTip && (
                    <Tooltip title={props.columns.toolTip} placement="right" arrow>
                      <IconButton size="small">
                        <QuestionMarkSVG />
                      </IconButton>
                    </Tooltip>
                  )}
                </p>
              )}
              <div className="text-wrapper">
                {props.columns.format === "email" && props.columns?.value ? (
                  <Tooltip title={props.columns?.value} disableInteractive>
                    <div className="link" onClick={() => handleActivityPopup(value)}>
                      {props.columns?.value}
                    </div>
                  </Tooltip>
                ) : props.columns.format == "link" ? (
                  <a className="link" href={`https://${props.columns?.value}`}>
                    {props.columns?.value}
                  </a>
                ) : props.columns.format === "tel" ? (
                  <p className="text">{Utils.formatPhoneNumber(props.columns?.value)}</p>
                ) : (
                  <p className="text">{props.columns?.value}</p>
                )}
                {props.columns.copyToClipboard && (
                  <>
                    <CopySVG onClick={() => onCopyToClipBoard()} />
                    <div className={textFade}>Copied</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {newActivity ? (
          <NewActivityPopup
            open={newActivity}
            onClose={() => setNewActivity(false)}
            title={"New Activity"}
            activityType={"email"}
            defaultTo={toEmailId}
            // [HACK ALERT]: Because of unexpected toggleCC function from in NewActivity.tsx will clear the TO filed and because of that we need to
            // apply below hack.
            replyOptions={{ replyButton: false, start: "", reply: toEmailId, replyAll: [], replyAllCC: [] }}
            contactOptions={filteredContactOptions}
            handleSend={handleSend}
            handleSendMarkClosed={handleSendAndMarkClosed}
          />
        ) : null}
      </>
    ) : (
      <></>
    );
  }

  // Return fragment
  return <></>;
}

// Detail.displayName = 'Detail';

const FancyButton = forwardRef(Detail);

export default FancyButton;
