import React from "react";
import Button from "../../../../../../library/Button/Button";
import { Pen } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { ApprovalRedirectProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

const Redirect: React.FC<ApprovalRedirectProps> = ({ onClickApprove, onClickDecline, onClickNudge, approverName, enableReviewOptions, ...props }) => {
  return (
    <PrimaryActivity
      {...{
        ...props,
        icon: <Pen />,
        creator: { ...props.creator, name: `${Labels.HEADER_APPROVAL_REQ_REDIRECT}${approverName ? ` to ${approverName}` : ""}`, message: "" },
        FLAGS: { REPLY_OPTIONS: true, TIME_SAVING_INDICATOR: false },
      }}
    >
      {" "}
      {enableReviewOptions && (
        <>
          <Button variant="primary" size="sm" type="button" onClick={onClickApprove}>
            {Labels.BTN_APPROVE}
          </Button>
          <Button variant="secondary" size="sm" type="button" onClick={onClickDecline}>
            {Labels.BTN_DECLINE}
          </Button>
          <Button variant="transparent" size="sm" type="button" onClick={onClickNudge}>
            {Labels.BTN_SEND_REMINDER}
          </Button>
        </>
      )}
    </PrimaryActivity>
  );
};

export default Redirect;
