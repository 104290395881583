import React, { useState } from "react";
import { Alert, Snackbar } from "@mui/material";

export const AlertContext = React.createContext<AlertContextType | null>(null);

export interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const AlertProvider: React.FC<Props> = ({ children }: Props) => {
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    severity: "success",
    message: "",
  });
  return (
    <AlertContext.Provider value={{ setToastOptions }}>
      {children}

      {/* Render Snackbar Alert */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toastOptions.open}
        onClose={() => setToastOptions((prev) => ({ ...prev, open: false }))}
        autoHideDuration={3000}
      >
        <Alert
          icon={toastOptions.icon ?? ""}
          onClose={() => setToastOptions((prev) => ({ ...prev, open: false }))}
          severity={toastOptions.severity}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setToastOptions((prev) => ({ ...prev, open: false }))}>
              CLOSE
            </p>
          }
        >
          {toastOptions.message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};

export default AlertProvider;
