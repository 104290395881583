/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { formatDate } from "../../../../db/utils/date";
import Loading from "../../../library/Loading/Loading";
import { Email, Phone, NoteFill, Download, Pen } from "../../../library/Icons/Icons";
import { InvoiceContext } from "../../../../contexts/InvoiceContext";
import { useHistory, useParams } from "react-router-dom";
import Detail from "../../../library/Detail/Detail";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import "./Details.scss";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import NewActivityPopup from "../../../library/AddNewActivityDropdown/AddNewActivityDropdown";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { AlertContext } from "../../../../contexts/AlertContext";
import NewActivityUtils from "../../../../utils/NewActivityUtils/NewActivityUtils";
import { ActivityOptions, FallbackTypes } from "../../../../types/enums";
import { ActivityDropdownItem } from "../../../library/AddNewActivityDropdown/NewActivityPopup";
import { companiesClient } from "../../../../db/accessor";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import DetailsTab from "../../../Payments/PaymentDetail/Detail/DetailsTab/DetailsTab";
import Utils from "../../../../utils/utils";
import { AppContext } from "../../../../contexts/AppContext";
import { getIcon } from "../../../../utils/IconUtils/IconUtils";
import { TemplateDefinitionProps } from "../../../../app/Templates/TemplateFactory";
import { TemplateTypes, viewType } from "../../../../app/Templates/TemplateTypes";
import { TemplateContext } from "../../../../contexts/TemplateContext";
import FourOFourError from "../../../../routes/FourOFourError/FourOFourError";
import { IconButton } from "@mui/material";
import _ from "underscore";
import DocumentSwitcher from "../../../../components/library/DocumentSwitcher/DocumentSwitcher";
import { ApplicationRouteContext } from "../../../../contexts/ApplicationRouteContext";
import { DocumentSwitchContext } from "../../../../contexts/DocumentSwitchContext";
import useRefreshApprover from "../../../../hooks/useRefreshActivityPopupContent";
import { CustomerContext } from "../../../../contexts/CustomerContext";

type PaymentsAppliedData = {
  id: string;
  referenceCode: string | null;
  appliedAmount: number;
  applyToInvoiceDate: string | null;
  type: string;
  handleClick: ((id: string) => void) | (() => null);
};

type DetailValueMappingObject = {
  [key: string]: any;
};

interface DetailsInterface {
  configs: any;
}

export default function InvoiceDetail(props: DetailsInterface): React.ReactElement {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { get, setInvoiceData, invoiceData, downloadTransaction } = React.useContext(InvoiceContext) as InvoiceType;
  const history = useHistory();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const detailRef = useRef<any>(null);
  const { signature } = React.useContext(CustomerContext) as CustomerType;
  const [newActivityType, setNewActivityType] = useState<string>("");
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const newActivityUtils = new NewActivityUtils(selectedWorkspace?.id || FallbackTypes.Id);
  const [refreshToggler, setRefreshToggler] = React.useState<boolean>(false);
  const { userStatus, getContactsOptions, allContactOptions, filterPrimaryContacts } = React.useContext(AppContext) as AppType;
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [enableTypeAheadSuggestions, setEnableTypeAheadSuggestions] = React.useState(false);
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const { templateFactory, handleTemplateAttachment, prepareTemplateDataObject, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const { getBaseRoute, updateBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [defaultTemplateId, setDefaultTemplateId] = useState<TemplateTypes | string>();
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setDocumentType,
    setEnableDocumentSwitch,
    setCurrentSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { toRef, popupRef, approver, setApprover } = useRefreshApprover();
  const getNumberFormatted = (amt: number) =>
    new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "currency",
      currency: userStatus?.currency?.code ?? "USD",
    }).format(amt);

  const fetchCompanyInfo = (companyId: UUID) => {
    if (!companyId) return;
    companiesClient.getCompany(companyId, "Classification").then((companyModel: CompanyModel) => {
      setCompanyData(companyModel);
    });
  };

  const primaryContact = filterPrimaryContacts(companyContactOptions);

  /**
   * update the approver as we get company contact option
   */
  useEffect(() => {
    setApprover(primaryContact[0]);
  }, [companyContactOptions]);

  const toggleActivity = async (toggler: boolean) => {
    setShowActivity(toggler);
  };

  const hideActivityPopUp = () => {
    toggleActivity(false);
  };

  const resetActivityStates = () => {
    setNewActivityType("");
    setRefreshToggler(!refreshToggler);
    toggleActivity(false);
  };

  const addActivityDropdownOptions: ActivityDropdownItem[] = [
    { displayName: "Email", icon: <Email /> },
    { displayName: "Note", icon: <NoteFill /> },
    { displayName: "Phone Call", icon: <Phone /> },
    { displayName: "Approval Request", icon: <Pen /> },
  ];

  // Calculates the payments applied amount for a given invoice
  const calculatedPaymentsApplied = useMemo(
    () =>
      invoiceData?.payments
        ? invoiceData?.payments.reduce((a, b) => a + b.paymentAppliedAmount, DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
        : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
    [invoiceData]
  );

  const fetchData = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      const res = await get(invoiceId);
      setInvoiceData(res);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  const fetchCompanyContacts = async () => {
    setCompanyContactOptions(await getContactsOptions(invoiceData.customer?.companyId));
  };

  useEffect(() => {
    fetchCompanyInfo(userStatus.account_company_id as string);
    fetchCompanyContacts();
    if (Object.keys(invoiceData).length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      fetchData();
    }
  }, []);

  const handleNameClick = (id: string) => {
    if (
      _.isUndefined(props.configs) ||
      _.isUndefined(props.configs.routes) ||
      _.isUndefined(props.configs.routes.resource) ||
      _.isUndefined(props.configs.routes.resource.customer)
    )
      return;
    sessionStorage.setItem("lastPath", history.location.pathname);
    const base = `${workspaceHomePath}/${props.configs.routes.resource.customer}/active/${id ?? ""}`;
    updateBaseRoute(base);
    history.push(base);
  };

  const handlePaymentClick = (id: string) => {
    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     */
    setEnableDocumentSwitch(true);
    /**
     * Save the current document switcher state
     */
    setCurrentSwitcherStateSnapshot();
    setSelectedActivityStreamId({ id: id } as ActivityStreamId);
    setActivityStreamIds(paymentsApplied.map((item) => ({ id: item.id })));
    setCurrentPaginationState({
      page: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
      pageCount: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
      pageSize: paymentsApplied.length,
      totalRecords: paymentsApplied.length,
    });
    setDocumentType("related-payment");
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${getBaseRoute()}/payments/${id ?? ""}`);
  };

  const hasPayments = !(invoiceData.payments == null || invoiceData.payments.length < DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
  const hasCreditMemos = !(invoiceData.creditMemos == null || invoiceData.creditMemos.length < DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
  const paymentsApplied: PaymentsAppliedData[] = [
    ...(hasPayments ? (invoiceData.payments as InvoicePaymentDetailModel[]) : []).map((element: InvoicePaymentDetailModel) => {
      return {
        id: element.paymentId,
        referenceCode: element.referenceCode,
        appliedAmount: element.paymentAppliedAmount,
        applyToInvoiceDate: element.applyToInvoiceDate,
        type: "Payment",
        handleClick: (id: string) => handlePaymentClick(id),
      };
    }),
    ...(hasCreditMemos ? (invoiceData.creditMemos as CreditMemoInvoiceModel[]) : []).map((element: CreditMemoInvoiceModel) => {
      return {
        id: element.creditMemoInvoiceId,
        referenceCode: element.referenceCode,
        appliedAmount: element.creditMemoAppliedAmount,
        applyToInvoiceDate: element.applyToInvoiceDate,
        type: "Credit Memo",
        // TODO: Add onclick handler to route to Credit Memo Details Page (Invoice Details Page)
        handleClick: () => null,
      };
    }),
  ].sort((first, second) =>
    (first?.applyToInvoiceDate ?? "") < (second?.applyToInvoiceDate ?? "")
      ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
      : DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
  );

  const typeAheadAction = (val: string) => {
    if (val.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setEnableTypeAheadSuggestions(true);
    } else {
      setEnableTypeAheadSuggestions(false);
    }
  };

  const setInvoiceNewActivity = (type: string) => {
    setNewActivityType(type);
    detailRef.current.hideDetailActivityComponent();
  };

  const newActivitiyDropdownProps = () => {
    return newActivityUtils.evaluateNewActivityDropdownProps(
      "New Activity Stream",
      newActivityType,
      showActivity,
      enableTypeAheadSuggestions ? [...companyContactOptions, ...allContactOptions] : companyContactOptions,
      setToastOptions,
      hideActivityPopUp,
      setInvoiceNewActivity,
      toggleActivity,
      addActivityDropdownOptions,
      invoiceId,
      resetActivityStates,
      invoiceData.customer?.companyId,
      typeAheadAction
    );
  };
  /**
   * Download Invoice or Bill
   * Server sends File content (to recieve directly blob FE is sending {responseType: 'blob'} as a config in API)
   * If any error cones from server (because FE initially sends {responseType: 'blob'}) so in error-block response is converted back into JSON
   * And server error is shown
   */
  const downloadInvoiceAndBill = async () => {
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "Your download will start in a few seconds" };
    try {
      setToastOptions({
        ...toastOptions,
        icon: (
          <IconButton style={{ padding: "0" }}>
            <Download className="download-icon" />
          </IconButton>
        ),
      });
      const response = await downloadTransaction(invoiceId);
      const uri = URL.createObjectURL(response);
      const fileName = `#${invoiceData?.referenceCode}-${new Date().toDateString()}__${new Date().toLocaleTimeString()}`;
      const completeFileName = selectedWorkspace.workspace_type === "accounts_payable" ? `Bill-${fileName}` : `Invoice-${fileName}`;
      TableUtils.downloadAll([
        {
          file_name: completeFileName,
          file_url: uri,
        },
      ]);
    } catch (error: any) {
      if (error.response) {
        const blob = new Blob([error.response.data], { type: "application/json" });
        const fileReader = new FileReader();
        fileReader.onload = (event: any) => {
          // Reason for strict checking is this error possibly will come on for demo connector rest with non-demo connector there will be more meaningful error messages
          const errorMessage =
            event.target.result
              .toLowerCase()
              .split('"')
              .reduce((a: string, b: string) => a + b) === "the app demo data does not support pdf retrieval."
              ? `PDF Download not supported for ${selectedWorkspace.workspace_type === "accounts_payable" ? "Bill" : "Invoice"} #${
                  invoiceData?.referenceCode
                }`
              : event.target.result;

          setToastOptions({ ...toastOptions, severity: "error", message: errorMessage });
        };
        fileReader.readAsText(blob);
      } else {
        setToastOptions({ ...toastOptions, severity: "error", message: "Something went wrong" });
      }
    }
  };

  const propertyValueMap: DetailValueMappingObject = {
    "value.primaryContact": invoiceData?.customerPrimaryContact?.contactName ?? null,
    "value.email": invoiceData?.customerPrimaryContact?.emailAddress ?? null,
    "value.totalAmount": invoiceData.totalAmount !== null ? getNumberFormatted(invoiceData.totalAmount) : null,
    "value.appliedAmount": getNumberFormatted(calculatedPaymentsApplied),
    "value.outstandingBalanceAmount":
      invoiceData?.outstandingBalanceAmount !== null ? getNumberFormatted(invoiceData.outstandingBalanceAmount) : null,
    "value.invoiceDate": formatDate(invoiceData?.invoiceDate ?? null),
    "value.paymentDueDate": formatDate(invoiceData?.paymentDueDate ?? null),
    "value.expectedPaymentDate": invoiceData?.invoiceClosedDate ? null : "N/A",
    "value.invoiceClosedDate": invoiceData?.invoiceClosedDate ? formatDate(invoiceData.invoiceClosedDate) : null,
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    setFileCallback?: FunctionCall
  ) => {
    if (templateID) {
      const { companyName = "{Company name}" } = invoiceData.customer as CompanyModel;
      const templateObj = templateData.get(selectedWorkspace?.id).get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      if (templateObj.templateAttachment) {
        const templateDataMapper = prepareTemplateDataObject(
          templateObj,
          [{ name: `Invoice #${invoiceData?.referenceCode ?? "N/A"}`, id: invoiceId }],
          null,
          null
        );
        handleTemplateAttachment(templateObj, setFileCallback, templateDataMapper);
      }
      setEditorState(
        templateObj.parseTemplate({
          customer: companyName,
          invoiceNumber: invoiceData?.referenceCode,
          invoiceDate: formatDate(invoiceData.invoiceDate) || "",
          paymentDueDate: formatDate(invoiceData.paymentDueDate) || "",
          invoiceAmount: Utils.formatValueAsCurrency(
            invoiceData.totalAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          outstandingBalance: Utils.formatValueAsCurrency(
            invoiceData.outstandingBalanceAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          userName: `${userStatus?.user_name ?? "{Your Name}"}`,
          workspaceName: selectedWorkspace?.name,
          contactName: `${approver?.label ?? "{Recipient's Name}"}`,
          totalPaymentsApplied: Utils.formatValueAsCurrency(
            calculatedPaymentsApplied ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          //first payment number assuming its the latest
          paymentNumber: paymentsApplied ? paymentsApplied[0]?.referenceCode : "{paymentNumber}",
          signature: signature.email_signature || "",
        })
      );
    }
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (item: InvoiceModel): TemplateDefinitionProps[] => {
    let view: viewType;
    if (selectedWorkspace.workspace_type === "accounts_payable") {
      view = item.invoiceStatusCode === "Closed" ? viewType.AP_TRANSACTION_BILLS_CLOSED : viewType.AP_TRANSACTION_BILLS;
    } else {
      view = item.invoiceStatusCode === "Closed" ? viewType.AR_TRANSACTION_INVOICES_CLOSED : viewType.AR_TRANSACTION_INVOICES;
    }
    const { companyName = "{Company name}" } = item.customer as CompanyModel;
    return templateFactory.getTemplateDefinitionsByView(view, { invoiceNumber: item.referenceCode, customer: companyName });
  };

  useEffect(() => {
    if (newActivityType === ActivityOptions.EMAIL) {
      setSupportedTemplateList(getTemplateDefinitionsByView(invoiceData));
      setDefaultTemplateId("");
    } else if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      setSupportedTemplateList(
        templateFactory.getTemplateDefinitionsByView(
          selectedWorkspace.workspace_type === "accounts_payable" ? viewType.AP_APPROVAL_REQUEST_ACTIVITY : viewType.AR_APPROVAL_REQUEST_ACTIVITY
        )
      );
      setDefaultTemplateId(
        selectedWorkspace.workspace_type === "accounts_payable" ? TemplateTypes.AP_APPROVAL_REQUEST : TemplateTypes.AR_APPROVAL_REQUEST
      );
    }
    // reset template states
    else {
      setSupportedTemplateList([]);
      setDefaultTemplateId("");
    }
  }, [newActivityType]);

  /**
   * Function which return the component.
   *
   * @returns {Component} return the NewActivityDropdown component.
   */
  const addActivity = () => {
    return (
      <NewActivityPopup
        {...{
          ...newActivitiyDropdownProps(),
          defaultTo: primaryContact,
          isTemplateSupport: newActivityType === ActivityOptions.EMAIL || newActivityType === ActivityOptions.APPROVAL_REQUEST,
          supportedTemplateList: supportedTemplateList,
          prepareActivityBodyByTemplateID: prepareActivityBodyByTemplateID,
          fromTime: fromTime,
          toTime: toTime,
          setToTime: setToTime,
          disableSecondaryAction: newActivityType === ActivityOptions.APPROVAL_REQUEST,
          defaultTemplateId: defaultTemplateId ?? "",
          refs: { toRef, popupRef },
        }}
      />
    );
  };
  return (
    <div className={`id-wrapper`}>
      {isLoading ? (
        <Loading isRelative />
      ) : errorMessage ? (
        <FourOFourError />
      ) : (
        <>
          <div className={`header-invoices`}>
            <p className={`title`}>{`${props.configs.details.routeType} #${invoiceData?.referenceCode ?? "N/A"}`}</p>
            <div className={`due-wrapper-invoices`}>
              <p className={`due-amount`}>{getNumberFormatted(invoiceData?.outstandingBalanceAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)}</p>
              <p className={`due-text`}>Total Due </p>
            </div>
            {TableUtils.formatInvoiceStatusByDueDate(invoiceData.paymentDueDate, invoiceData.invoiceStatusCode, false)}
            <div className={`tag-invoices`}>
              {invoiceData && (invoiceData?.customer?.companyName ?? "N/A").charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)}
            </div>
            <p className={`name`} onClick={() => handleNameClick(invoiceData.customerId)}>
              {invoiceData && (invoiceData?.customer?.companyName ?? "N/A")}
            </p>
            <DocumentSwitcher />
          </div>
          {/* Body of Details*/}
          <div className={`body`}>
            {/* Invoice Details */}
            <div className={`invoice`}>
              <div className={`invoice-contact-wrapper`}>
                <div className={`head`}>
                  {addActivity()}
                  <IconButton style={{ padding: "0" }} color="primary" disableRipple size="small" onClick={downloadInvoiceAndBill}>
                    <Download />
                  </IconButton>
                </div>
                <div className="body">
                  {props.configs.details.body.map((detail: any) => {
                    return (
                      <Detail
                        ref={detailRef}
                        key={detail.icon}
                        icon={getIcon(detail.icon)}
                        columns={detail.row.map((column: any) => ({ ...column, value: propertyValueMap[column.value] }))}
                        handleTransactionActivity={setShowActivity}
                      />
                    );
                  })}
                </div>
              </div>

              {(hasPayments || hasCreditMemos) && (
                <div className={`payment-wrapper`}>
                  <div className={`title`}>Payments Applied</div>
                  <div className="row row-header">
                    {props.configs.details.table.columns.map((column: any) => {
                      return (
                        <div className={`header ${column.styles}`} key={column.header}>
                          {column.header}
                        </div>
                      );
                    })}
                  </div>
                  <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: false }}>
                    {paymentsApplied &&
                      paymentsApplied?.map((val: PaymentsAppliedData) => {
                        return (
                          <div className="row" onClick={() => val.handleClick(val.id)} key={val.id}>
                            <p className="id">{`${val?.referenceCode || " N/A"}`}</p>
                            <p className={`type`}>
                              {val.appliedAmount == invoiceData.totalAmount ? `Full ${val.type} Applied` : `Partial ${val.type} Applied`}
                            </p>
                            <p className={`date`}>{formatDate(val.applyToInvoiceDate)}</p>
                            <p className={`amount`}>{val.appliedAmount !== undefined && getNumberFormatted(val.appliedAmount)}</p>
                          </div>
                        );
                      })}
                  </OverlayScrollbarsComponent>
                </div>
              )}
            </div>
            {/* tabs */}
            <div className="details-tab-wrapper">
              <DetailsTab
                newActivityComponentProps={{
                  setNewActivityType,
                  setShowAddActivity: toggleActivity,
                  addActivityDropdown: addActivityDropdownOptions,
                }}
                id={invoiceId}
                type="invoice"
                refreshList={refreshToggler}
                profileInfo={{
                  customerId: invoiceData?.customerId ?? "",
                  phone: invoiceData?.customerPrimaryContact?.phone ?? "",
                  email: invoiceData?.customerPrimaryContact?.emailAddress ?? "",
                }}
              />
            </div>
          </div>
          <div id="tooltip-container" style={{ display: "none" }} ref={tooltipRef} />
        </>
      )}
    </div>
  );
}
