import React, { useEffect, useRef, useState } from "react";
import { Drawer, TextField, DrawerProps, Tooltip, createFilterOptions } from "@mui/material";
import Button from "../../../../library/Button/Button";
import { TextArea } from "../../../../library/TextArea/TextArea";
import { UserPic } from "../../../../library/Icons/Icons";
import Autocomplete from "../../../../library/Autocomplete/Autocomplete";
import { FallbackTypes } from "../../../../../types/enums";
import { ActivityContext } from "../../../../../contexts/ActivityContext";
import { AlertContext } from "../../../../../contexts/AlertContext";
import "./ActivityFeedActions.scss";
import { userAccountsClientV2 } from "../../../../../db/version2Accessor";
import { WorkspaceContext } from "../../../../../contexts/WorkspaceContext";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import {
  BTN_ASSIGN,
  BTN_CANCEL,
  HEADING_CHANGE_ASSIGNMENT_OWNER,
  LBL_TEAM_MEMBER,
  SUB_HEADING_CHANGE_ASSIGNMENT_OWNER,
} from "../../../../../constants/config";

interface ActivityReassignProps extends DrawerProps {
  onClose: () => void;
  onCallback: () => void;
  activityIds: string[];
}

interface UserEmailProps {
  option: any;
}

function UserEmailComponent({ option }: UserEmailProps): React.ReactElement {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [disableHover, setDisableHover] = useState(false);
  const handleResize = () => {
    if (wrapperRef) {
      if (
        (wrapperRef.current?.offsetWidth ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) <
        (wrapperRef.current?.scrollWidth ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
      ) {
        setDisableHover(false);
      } else {
        setDisableHover(true);
      }
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });


  return (
    <Tooltip title={option.userEmail} followCursor disableHoverListener={disableHover}>
      <span ref={wrapperRef} className="contact-email">
        {option.userEmail || "--"}
      </span>
    </Tooltip>
  );

}

export default function ActivityReassign({ open, onClose, onCallback, activityIds, ...rest }: ActivityReassignProps): React.ReactElement {
  const { handleReassign } = React.useContext(ActivityContext) as ActivityType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;

  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [noteValue, setNoteValue] = useState<string>("");

  const [isLoadingTeamMemberList, setLoadingTeamMemberList] = useState<boolean>(true);
  const [teamMemberList, setTeamMemberList] = useState<Partial<UserAccountModel>[]>([]);
  const [teamMemberListOpen, setTeamMemberListOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<Partial<UserAccountModel> | null>(null);
  const isMounted = useRef<boolean>(false);

  const onReassignHandler = async () => {
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message:
        activityIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? (
          <p>
            Successfully changed owner to <span style={{ fontWeight: 700 }}>{selectedUser?.userName}</span>
          </p>
        ) : (
          `${activityIds.length} Activities Reassigned`
        ),
    };
    let response = {} as APIResponse;

    try {
      response = await handleReassign(
        activityIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? activityIds[0] : activityIds,
        selectedUser?.userId ?? null,
        noteValue
      );
    } catch (e: unknown) {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message:
            activityIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
              ? "Activity was not reassigned"
              : `${activityIds.length} Activities were not reassigned`,
        };
      }
      onCallback();
      setNoteValue("");
      onClose();
      setToastOptions(toastOptions);
    }
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    await onReassignHandler();
    setLoadingSubmit(false);
  };

  const fetchUserList = async () => {
    setLoadingTeamMemberList(true);
    try {
      await userAccountsClientV2.getAllUserAccounts(selectedWorkspace?.id || FallbackTypes.Id).then((response) => {
        if (response.success) {
          setTeamMemberList(
            response?.data?.map((user: UserItem) => {
              return {
                userId: `${user.id}`,
                userName: user.name,
                userEmail: user.email_address,
              };
            }) ?? []
          );
        }
      });
      const unassignedUser = { userId: "", userName: "Unassigned", userEmail: "" };
      setTeamMemberList((prev: Partial<UserAccountModel>[]) => {
        return [...prev, unassignedUser];
      });
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setLoadingTeamMemberList(false);
    }
  };

  // Load list of user choices for re-assignment
  useEffect(() => {
    if (isMounted.current) {
      if (open) {
        fetchUserList();
      }
    } else {
      isMounted.current = true;
    }
  }, [open]);


  /**
   * This Function filters out the options when user types to search for members.
   *
   * @param val After comparing return the label if Id is present else return the Id.
   */
  const getOptions = (val: any) => {
    return val.userName;
  };

  const filterOptions = createFilterOptions({
    stringify: (option: any) => option.userName + option.userEmail,
  });

  return (
    <Drawer className="activity-feed-action-drawer" open={open} anchor="right" disablePortal onClose={() => onClose()} {...rest}>
      <div className="activity-flyout">
        <h3 className="header">{HEADING_CHANGE_ASSIGNMENT_OWNER}</h3>
        <p className="subheader body1">{SUB_HEADING_CHANGE_ASSIGNMENT_OWNER}</p>
        <label htmlFor={"string"}>{LBL_TEAM_MEMBER}</label>
        <div>
          <Autocomplete
            getOptionLabel={getOptions}
            filterOptions={filterOptions}
            options={teamMemberList}
            loading={isLoadingTeamMemberList}
            open={teamMemberListOpen}
            className="team-member-select"
            onOpen={() => {
              setTeamMemberListOpen(true);
            }}
            onClose={() => {
              setNoteValue("");
              setTeamMemberListOpen(false);
            }}
            onChange={async (_event, value: Partial<UserAccountModel>) => {
              setSelectedUser(value)
            }}
            blurOnSelect
            disableClearable
            disablePortal
            fullWidth
            popupIcon={<UserPic />}
            staticIcon={true}
            renderOption={(props, option) => {
              return (
                <li key={option.userId} className="contact-item-parent" {...props}>
                  <div className="contact-item-profile-tag">
                    <UserPic></UserPic>
                  </div>
                  <div className="contact-info-item">
                    {option.userName !== "" && option.userEmail !== "" ? (
                      <>
                        <span className="contact-name">{option.userName || "--"}</span>
                        <UserEmailComponent option={option} />
                      </>
                    ) : (
                      <span className="contact-name">{option.userEmail || option.userName || "--"}</span>
                    )}
                  </div>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Choose A Team Member",
                  disableUnderline: true,
                }}
              />
            )}
          />
        </div>

        <TextArea
          label={{ regularText: "Note", extraText: " (optional)" }}
          placeholder={""}
          onChange={(e) => setNoteValue(e.target.value)}
          defaultValue={noteValue}
        />
        <div className="btn-grp">
          <Button variant="secondary" size="lg" onClick={() => onClose()} loading={isLoadingSubmit}>
            {BTN_CANCEL}
          </Button>
          <Button size="lg" onClick={() => handleSubmit()} loading={isLoadingSubmit}>
            {BTN_ASSIGN}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
