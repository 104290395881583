/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import _ from "underscore";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { ActivityOptions, ActivityType, AuditLogType, EmailAction, InlinePrompts } from "../../../../../types/enums";
import Utils from "../../../../../utils/utils";
import ViewOriginalEmail from "./PrimaryActivity/Helpers/ViewOriginalEmail/ViewOriginalEmail";

import "./ActivityFeedItem.scss";
import { useHistory, useLocation } from "react-router";
import ActivityFeedItemComponent from "./ActivityFeedItemComponent";

export type ActivityFeedItemVariant = ActivityType | AuditLogType | InlinePrompts;

export type ActivityFeedItemProps = {
  header?: string;
  to: ContactItem[];
  cc?: ContactItem[];
  bcc?: ContactItem[];
  date?: string;
  content: string;
  isRead: boolean;
  isStreamSpam: boolean;
  variant: ActivityFeedItemVariant;
  icon?: React.ReactNode;
  creator?: any;
  from?: any;
  attachments: any[];
  emailType?: string;
  retryEmail?: (to: ContactItem[], content: string) => void;
  originMessageContent?: string;
  id: string;
  originalStreamId?: string;
  originalStreamItemId?: string;
  setActionType?: (showNewActivity: string) => void;
  setActivityItemId?: (id: string) => void;
  setNewActivityType?: (type: string) => void;
  enableForwardAPAutomationBtn?: boolean;
  handleUnsnoozeStream: () => void;
  preview?: string;
  snoozedUntil?: number;
  simple_content?: string;
  activityId: string;
  status: string;
  type: string;
  isNudgeActivity?: boolean;
  handleNudge?: () => void;
  order: number;
  timeSaved?: Partial<AutomateTimeModel>;
  handleApprovalReview?: (reviewAction: ApprovalReviewAction) => void;
  getOriginalEmail?: (itemId: string) => any;
  readonly?: boolean;
  primaryConnection?: Record<string, any>;
  handleNotSpam: () => void;
  assigneeName: string | undefined;
  magicLink?: string | undefined;
  bounceErrorMsg?: string;
  flag: string;
};

export function ActivityFeedItem(props: ActivityFeedItemProps): React.ReactElement {
  const [openOriginalEmail, setoOriginalEmailVisiblity] = useState(false);
  const [originalEmailContent, setOriginalEmailContent] = useState({ subject: "", body: "" });

  const location = useLocation();
  const history = useHistory();
  const snoozedDate = Utils.evaluateDate(props.snoozedUntil ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  const toggleOriginalEmail = () => {
    setoOriginalEmailVisiblity(!openOriginalEmail);
  };

  /**
   * opens up editor with attachments and content with activity
   * type provided as actionType
   * @param actionType reply, reply_all, forward
   */
  const handleAction = (actionType: string) => {
    props.setActionType?.(actionType);
    // default reply, reply_all and forward activity type
    props.setNewActivityType?.("email");
    props.setActivityItemId?.(props.id);
  };

  /**
   * redirects the stream to original/forwarded
   */
  const redirectForwardedStream = () => {
    history.push({
      pathname: `${location.pathname.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, location.pathname.lastIndexOf("/"))}/${props?.originalStreamId}`,
      state: {
        itemId: props.originalStreamItemId,
      },
    });
  };

  /**
   * opens up editor with attachments and content with activity
   * type as forward ap automation
   */
  const sendApAutomationMail = () => {
    props.setActionType?.("reply");
    // default reply, reply_all and forward activity type
    props.setNewActivityType?.(EmailAction.FORWARD_AP_AUTOMATION);
    props.setActivityItemId?.(props.id);
  };

  /**
   * will return comma separated values of the emails bounced
   */
  const getBouncedEmailArray = (to: ContactItem[]): Array<any> => {
    const emailArray = to.map((item) => item?.email_address);

    return [emailArray.join(", ")];
  };

  /**
   * base props for primary and secondary activity
   */
  const baseProps = {
    order: props.order,
    creator: {
      name: props.creator?.name || props.from?.name || props.to?.[0]?.name,
      message: props.header ?? "",
      createdOn: props.date ?? "",
    },
    icon: "",
    attachments: {
      items: props.attachments,
      onClickForwardToAutomation: sendApAutomationMail,
    },
    contacts: {
      to:
        props.variant === ActivityType.PhoneCall
          ? [props.from]
          : props.variant === ActivityType.BouncedEmail
          ? getBouncedEmailArray(props.to)
          : props.to, // for phone variant to should be from
      cc: props.cc,
      bcc: props.bcc,
      from: props.from,
    },
    content: {
      simpleEmailContent: props.simple_content,
      preview: props.preview,
    },
    isRead: props.isRead,
    readonly: props.readonly,
    magicLink: props.magicLink,
    flag: props.flag,
  };

  /**
   * handlers to enable various actions on feed items
   */
  const handlers = {
    /**
     * reply options contain - reply, reply all, forward
     * and open email in original view
     */
    reply: {
      onClickViewOriginalEmail: async () => {
        const email = await props.getOriginalEmail?.(props.id);
        setOriginalEmailContent({ subject: email.data.subject, body: email.data.content });
        toggleOriginalEmail();
      },
      onClickReplyAll: () => handleAction(EmailAction.REPLY_ALL),
      onClickForward: () => handleAction(EmailAction.FORWARD),
      onClickReply: () => handleAction(EmailAction.REPLY),
    },
    /** bounce email require retry send functionality */
    bounce: {
      onClickRetrySend: () => {
        props.retryEmail?.(props.to, props.originMessageContent || "");
      },
    },
    /** approval request/redirect need review options
     * as well as nudge as 'send a reminder' functionality
     */
    approval: {
      onClickApprove: () => {
        props.setActivityItemId?.(props.id);
        props.handleApprovalReview?.("approve");
      },
      onClickDecline: () => {
        props.setActivityItemId?.(props.id);
        props.handleApprovalReview?.("decline");
      },
      onClickNudge: () => props.handleNudge?.(),
    },
    /**
     * nudge again functionality handler
     */
    nudge: {
      onClickNudge: () => props.handleNudge?.(),
    },
    /**
     * unsnooze functionality handler
     */
    snooze: {
      onClickUnsnooze: () => props.handleUnsnoozeStream?.(),
    },
    /**
     * see original or forwarded stream functionality handler
     */
    forward: {
      onClickRedirect: () => redirectForwardedStream?.(),
    },
    /**
     * mark activity as not spam functionality handler
     */
    spam: {
      onClickNotSpam: () => props.handleNotSpam?.(),
    },
    /**
     * resend call to action on ap automation activity
     */
    onboardVendor: {
      onClickOnboard: () => {
        props.setActionType?.(EmailAction.NEW);
        props.setNewActivityType?.(ActivityOptions.ONBOARD_VENDOR);
        props.setActivityItemId?.(props.id);
      },
    },
  };

  /**
   * Props that are used in various type of activities to help
   * in display correct activity types, header message or action
   * buttons
   */
  const otherProps = {
    erroneousEmail: props.from?.email_address ?? "N/A",
    note: props.preview ?? "",
    primaryConnection: props.primaryConnection?.name,
    timestampUntilSnoozed: snoozedDate,
    connectionStatus: props.variant,
    timeSaving: {
      from_time: props.timeSaved?.from_time,
      to_time: props.timeSaved?.to_time,
    },
    activityStatus: props.status,
    isStreamSpam: props.isStreamSpam,
    isNudgeActivity: _.isUndefined(props.isNudgeActivity) ? false : props.isNudgeActivity,
    approverName: props?.to?.[0]?.name ?? props?.to?.[0]?.email_address,
    originalStreamItemId: props.originalStreamItemId,
    type: props.type,
    assigneeName: props.assigneeName,
    bounceErrorMsg: props.bounceErrorMsg ?? "",
  };

  return (
    <>
      <ActivityFeedItemComponent baseProps={baseProps} {...otherProps} variant={props.variant} handlers={handlers} />

      <ViewOriginalEmail
        openOriginalEmail={openOriginalEmail}
        toggleOriginalEmail={toggleOriginalEmail}
        originalEmailContent={originalEmailContent}
        creator={props.creator}
        attachments={props.attachments}
        header={props.header}
        to={props.to}
        date={props.date}
        content={props.content}
        is_read={props.isRead}
        variant={props.variant}
        magicLink={props.magicLink}
      />
    </>
  );
}
