import { createTheme } from "@mui/material";
import styles from "../css/_export.module.scss";

export const primaryTheme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paperAnchorRight: {
          padding: styles.large,
          width: "19.5rem",
          boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
        },
        modal: { backgroundColor: "rgba(0, 0, 0, 0)" },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          border: "0.0625rem solid transparent",
          padding: "0",
          borderRadius: "0.125rem",
          "&.Mui-focused": {
            border: `0.0625rem solid ${styles.grey70}`,
          },
          "&.MuiAutocomplete-tag": {
            margin: "0.1875rem",
            maxWidth: "calc(100% - 0.375rem)",
          },
        },
        inputRoot: {
          backgroundColor: styles.grey98,
          color: styles.primary,
          fontFamily: styles.primaryFontFamily,
          fontSize: styles.fontSizeSM,
          height: "2.5rem",
          width: "100%",
          padding: "0 !important",
          borderRadius: "0.125rem",
          "&:hover": {
            backgroundColor: styles.grey96,
          },
          "&.Mui-focused": {
            backgroundColor: styles.grey93,
          },
          "&.Mui-focused input": {
            color: styles.primary,
          },
          "&.Mui-focused input:focus::placeholder": {
            color: "transparent",
          },
          ".MuiAutocomplete-endAdornment": {
            marginRight: "0.75rem",
            button: {
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
            svg: {
              fill: styles.grey60,
            },
            top: "unset",
          },
          ".MuiInput-input": {
            padding: "0",
          },
          ".MuiAutocomplete-clearIndicator": {
            display: "none",
          },
        },
        input: {
          textIndent: "0.75rem",
          height: "100%",
          fontSize: styles.fontSizeSM,
          paddingRight: "2.9375rem !important",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
        listbox: {
          maxHeight: "12.8125rem",
          fontFamily: styles.primaryFontFamily,
          fontSize: styles.fontSizeSM,
          fontWeight: "normal",
          boxShadow: "none",
          padding: "0.5rem 0",
          "&::-webkit-scrollbar-track": {
            backgroundColor: styles.white100,
          },

          "&::-webkit-scrollbar": {
            width: "0.5rem",
            backgroundColor: styles.white100,
          },

          "&::-webkit-scrollbar-thumb": {
            borderRadius: "0.125rem",
            backgroundColor: styles.grey96,
            height: "5rem",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: styles.grey90,
          },

          "& .MuiAutocomplete-option": {
            paddingTop: "0.375rem",
            paddingBottom: "0.375rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          },
        },
        paper: {
          borderRadius: "0 0 0.125rem 0.125rem",
          boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
        },
        noOptions: {
          fontFamily: styles.primaryFontFamily,
          fontSize: styles.fontSizeSM,
          fontWeight: "normal",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: "success" },
          style: {
            height: "3.5rem",
            borderRadius: "0.125rem",
            backgroundColor: styles.connectedGreen,
            boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1.375rem",
            padding: "0 1.5rem 0 1.375rem",
            "& .MuiAlert-icon": {
              color: styles.white100,
              display: "flex",
              alignItems: "center",
              margin: "0",
              padding: "0",
              width: "1.25rem",
              height: "1.25rem",
              "& .MuiSvgIcon-root": {
                width: "1.25rem",
                height: "1.25rem",
              },
            },
            "& .MuiAlert-message": {
              color: styles.white100,
              fontFamily: styles.primaryFontFamily,
              fontWeight: "500",
              padding: "0",
              display: "flex",
              alignItems: "center",
            },
            "& .MuiAlert-action": {
              display: "flex",
              alignItems: "center",
              padding: "0",
              margin: "0",
              cursor: "pointer",
              color: styles.white100,
            },
          },
        },
        {
          props: { severity: "error" },
          style: {
            height: "3.5rem",
            borderRadius: "0.125rem",
            backgroundColor: styles.red,
            boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1.375rem",
            padding: "0 1.5rem 0 1.375rem",
            "& .MuiAlert-icon": {
              color: styles.white100,
              display: "flex",
              alignItems: "center",
              margin: "0",
              padding: "0",
              width: "1.25rem",
              height: "1.25rem",
              "& .MuiSvgIcon-root": {
                width: "1.25rem",
                height: "1.25rem",
              },
            },
            "& .MuiAlert-message": {
              color: styles.white100,
              fontFamily: styles.primaryFontFamily,
              fontWeight: "500",
              padding: "0",
              display: "flex",
              alignItems: "center",
            },
            "& .MuiAlert-action": {
              display: "flex",
              alignItems: "center",
              padding: "0",
              margin: "0",
              cursor: "pointer",
              color: styles.white100,
            },
          },
        },
        {
          props: { severity: "info" },
          style: {
            height: "3.5rem",
            borderRadius: "0.125rem",
            backgroundColor: styles.link,
            boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1.375rem",
            padding: "0 1.5rem 0 1.375rem",
            "& .MuiAlert-icon": {
              color: styles.white100,
              display: "flex",
              alignItems: "center",
              margin: "0",
              padding: "0",
              width: "1.25rem",
              height: "1.25rem",
              "& .MuiSvgIcon-root": {
                width: "1.25rem",
                height: "1.25rem",
              },
            },
            "& .MuiAlert-message": {
              color: styles.white100,
              fontFamily: styles.primaryFontFamily,
              fontWeight: "500",
              padding: "0",
              display: "flex",
              alignItems: "center",
            },
            "& .MuiAlert-action": {
              display: "flex",
              alignItems: "center",
              padding: "0",
              margin: "0",
              cursor: "pointer",
              color: styles.white100,
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: styles.primary,
          fontFamily: styles.primaryFontFamily,
          fontWeight: 500,
          fontSize: styles.fontSizeXS,
          lineHeight: styles.lineHeightSM,
          color: styles.white100,
          fontStyle: "normal",
          padding: "0.5rem",
          borderRadius: "0.125rem",
          margin: "0.125rem",
          minWidth: "fit-content",
          maxWidth: "18.75rem",

          "& .MuiTooltip-arrow::before": {
            color: styles.primary,
          },
          "& .MuiTooltip-tooltipPlacementBottom": {
            color: styles.primary,
          },
        },
        popper: {
          "& .MuiTooltip-tooltipPlacementBottom": {
            marginTop: "0.875rem !important",
          },
          "& .MuiTooltip-tooltipPlacementTop": {
            marginBottom: "0.875rem !important",
          },
          "& .MuiTooltip-tooltipPlacementLeft": {
            marginRight: "0.875rem !important",
          },
          "& .MuiTooltip-tooltipPlacementRight": {
            marginLeft: "0.875rem !important",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "0.5rem",
          "& > svg": {
            fill: styles.grey60,
          },
          "&:hover > svg": {
            fill: styles.primary,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "3rem",
          padding: "0 1rem",
        },
        content: {
          margin: "0.75rem 0",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "0.25rem 1rem",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "0.25rem",
          maxHeight: " calc(100% - 4rem)",
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        text: { borderRadius: "0.25rem/0.41875rem" },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderRightWidth: "0.0625rem",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "2rem",
        },
        deleteIcon: {
          fontSize: "1.375rem",
          margin: "0 0.3125rem 0 -0.375rem",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "0.0625rem solid rgba(224, 224, 224, 1)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "0.25rem 0 0.3125rem",
        },
      },
    },
  },
});
