import APIClient from "../APIClient";

export class NotificationsClient {
  /**
   * * GET on  /api/v2/notifications/settings
   * @returns Promise of type APIResponse Model or Exception
   */
  getAllNotificationPermissions(): Promise<APIResponse> {
    const url = `inbox/api/v2/notifications/settings`;

    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }

  /**
   * * Patch on  /api/v2/notifications/settings
   * @returns Promise of type APIResponse Model or Exception
   */
  updateNotificationsSettings(payload: NotificationsSettings): Promise<APIResponse> {
    const url = "inbox/api/v2/notifications/settings";
    return APIClient.patch(url, payload).then((response) => {
      return response.data;
    });
  }
}
