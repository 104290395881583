import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import FourOFourError from "../../../routes/FourOFourError/FourOFourError";
import Navbar from "../../library/Navbar/Navbar";
import MyProfile from "./MyProfile/MyProfile";

const Profiles = (): React.ReactElement => {
  const { path } = useRouteMatch();
  return (
    <div className="profile-wrapper">
      <div className="profile-head">
        <Switch>
          <Route
            exact
            path={`${path}`}
            render={() => {
              return <Navbar tabs={[{ displayName: "My Profile", routeName: "" }]} rootPath={path} />;
            }}
          />
        </Switch>
      </div>
      <Switch>
        <Route exact path={`${path}`} component={MyProfile} />
        <Route component={FourOFourError} />
      </Switch>
    </div>
  );
};

export default Profiles;
