import { useMsal } from "@azure/msal-react";
import { IconButton } from "@mui/material";
import React, { MouseEvent, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_TIMEOUTS, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { AppContext } from "../../../contexts/AppContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { enrollmentsClient, syncClient } from "../../../db/accessor";
import { formatTimeElapsed } from "../../../db/utils/date";
import { UserActions } from "../../../types/enums";
import { Sync } from "../../library/Icons/Icons";
import Menu from "../../library/Menu/Menu";
import MenuItem from "../../library/MenuItem/MenuItem";
import CompanyProfile from "../../library/Sidebar/CompanyProfile/CompanyProfile";
import Userpic from "../../library/Sidebar/Userpic/Userpic";
import Tracking from "../../Tracking/Tracking";
import { userAccountsClient } from "./../../../db/accessor";
import "./MainContentHead.scss";
import { ProfileContext } from "../../../contexts/ProfileContext";
import SettingsPopUp from "../../Settings/SettingsPopUp/SettingsPopUp";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { CustomerContext } from "../../../contexts/CustomerContext";
import LockstepBar from "../LockstepBar/LockstepBar";
import { WorkspaceType } from "../../../types/enums";
import { GlobalSearchWidget } from "../../GlobalSearch";
import _ from "underscore";
import { TemplateContext } from "../../../contexts/TemplateContext";

type UserId = {
  id: string;
  username: string;
  custom: {
    groupKey: string;
    accountName: string;
    companyId: string;
    userRole: string;
  };
};

function LastSync(): React.ReactElement {
  const { hasPermission } = React.useContext(AppContext) as AppType;
  const { syncHasUpdated, setLastSync, lastSync, setSyncHasUpdated } = React.useContext(SettingsContext) as SettingsType;
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleSyncClick = async () => {
    // Sync is running, do nothing
    if (!(lastSync?.statusCode === "Failed" || lastSync?.statusCode === "Success" || lastSync?.statusCode === "Cancelled")) {
      return;
    }

    if (!hasPermission(UserActions.RunSync)) {
      history.push(`${path}/settings/accountingSoftware`);
    }

    try {
      await enrollmentsClient
        .getEnrollments("(isActive eq 'true')")
        .then((data: AppEnrollmentModelFetchResult) => {
          return data?.records?.filter((record: AppEnrollmentModel) => record.app.appType === "ERP Connector") ?? [];
        })
        .then((data: AppEnrollmentModel[]) => {
          // Active ERP exists, then run sync
          if (data[0]) {
            syncClient.startSync({ appEnrollmentId: data[0].appEnrollmentId }).then(() => {
              setSyncHasUpdated((prev: number): number => prev + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
            });
          } else {
            // Otherwise, route to AS settings
            history.push(`${path}/settings/accountingSoftware`);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const checkSyncStatus = async () => {
      try {
        const response = await syncClient.querySync(NUMERIC_VALUES.CONSTANT_TWO).then((res) => res.records);
        const lastSyncResponse = response && response[0];
        if (lastSyncResponse) {
          setLastSync(lastSyncResponse);
          if (
            lastSyncResponse?.statusCode === "Failed" ||
            lastSyncResponse?.statusCode === "Success" ||
            lastSyncResponse?.statusCode === "Cancelled"
          ) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            clearInterval(pollSync);
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          clearInterval(pollSync);
        }
        return lastSyncResponse;
      } catch (e) {
        console.info(e);
      }
    };
    checkSyncStatus();
    const pollSync = setInterval(() => {
      checkSyncStatus();
    }, NUMERIC_TIMEOUTS.FIVE_SECOND);

    return () => {
      clearInterval(pollSync);
    };
  }, [syncHasUpdated]);

  if (!lastSync?.statusCode || !lastSync?.appEnrollmentId) {
    <div className="last-sync-wrapper"></div>;
  }

  return (
    <div className="last-sync-wrapper">
      {_.isEmpty(lastSync.appEnrollmentId) === false &&
        (lastSync?.statusCode === "Failed" || lastSync?.statusCode === "Success" || lastSync?.statusCode === "Cancelled" ? (
          <>
            <span className="light">LAST SYNC </span>
            <span className="bold">{lastSync?.created ? `${formatTimeElapsed(lastSync.created)} ago` : "N/A"}</span>
            <IconButton size="small" onClick={() => handleSyncClick()}>
              <Sync />
            </IconButton>
          </>
        ) : (
          <>
            <span className="light">RUNNING SYNC </span>
            <IconButton size="small" onClick={() => handleSyncClick()}>
              <Sync />
            </IconButton>
          </>
        ))}
    </div>
  );
}

/**
 * * Defines the Head of the Main Content being displayed to the user for a given Route
 */
export default function MainContentHead(): React.ReactElement {
  const { selectedWorkspace, setWorkspaceData, setUserGroupChanged, userGroupChanged, workspaceData } = React.useContext(
    WorkspaceContext
  ) as WorkspaceDataType;
  const { logout, getStatus, userStatus, getContactsOptions, setSelectedUserGroup } = React.useContext(AppContext) as AppType;
  const { imageUrl, setImageUrl, userName, setUserName } = React.useContext(ProfileContext) as ProfileType;
  const { createTemplateMapsForWorkspaces } = React.useContext(TemplateContext) as ITemplateProps;

  const { getCompany } = React.useContext(CustomerContext) as CustomerType;
  const [profileData, setProfileData] = useState<UserAccountModelV2 | StatusModelV2>({} as UserAccountModelV2 | StatusModelV2);
  const [userInfo, setUserInfo] = useState<UserId>({
    id: "",
    username: "unknown",
    custom: {
      groupKey: "",
      accountName: "",
      companyId: "",
      userRole: "",
    },
  });
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const menuIsOpen = Boolean(menuAnchorEl);

  const history = useHistory();
  const { boot, shutdown, update } = useIntercom();
  const { instance } = useMsal();

  /**
   * @function setupUserProfileData
   * A helper function to fetch status information
   */
  const setupUserProfileData = () => {
    getStatus()
      .then((data) => {
        if (data.logged_in) {
          setUserInfo({
            id: data.user_id ? data.user_id : "",
            username: data.user_name ? data.user_name : "",
            custom: {
              groupKey: data.group_key ? data.group_key : "",
              accountName: data.account_name ? data.account_name : "",
              companyId: data.account_company_id ? data.account_company_id : "",
              userRole: data.roles ? data.roles[0] : "",
            },
          });
          setProfileData(data);
          shutdown();
          boot({
            userId: data?.user_id ?? "",
            name: data?.user_name ?? "Guest User",
            email: instance.getActiveAccount()?.username ?? "",
          });

          update({ hideDefaultLauncher: false });
          userAccountsClient.getUserAccount(data?.user_id ?? "", "AccountingRole").then((data: UserAccountModel) => {
            setUserName(data?.userName ?? userName);
            setImageUrl(data?.imageURL ?? imageUrl);
          });
          getCompany(data.account_company_id ?? "");
        } else {
          // The user has an Azure B2C account but is not recognized as being set up in Insights, so we need to send them through onboarding
          //needs to be tested and updated
          history.push(`${process.env.REACT_APP_REDIRECT_URL || ""}/account/newAccount`);
        }
      })
      .catch((error: unknown) => {
        console.log(error);
      });
  };

  /**
   * fetch user status information when app loads
   */
  useEffect(() => {
    setupUserProfileData();
  }, []);

  useEffect(() => {
    getContactsOptions();
  }, [selectedWorkspace?.name]);

  /**
   * @function handleUserGroupChange
   * A function to handle user group change event.
   * Once a change is registered on platform, it will refetch the status information as per the new user group
   * @param userGroupItem
   */
  const handleUserGroupChange = (userGroupItem: UserGroupModel) => {
    setSelectedUserGroup(userGroupItem as UserGroupModel);
    setUserGroupChanged(true);
    userAccountsClient.changeGroup(userGroupItem?.group_key).then((res: UserGroupModelV1) => {
      /**
       * group key is set to localstorage and will be passed with every call to server
       */
      window.localStorage.setItem("groupKey", res?.groupKey);
      setupUserProfileData();
    });
  };

  /**
   * Once a user group change is registered on platform, this effect will update workspace
   * configuration as per the new user group and redirect to the default workspace of that group.
   */
  useEffect(() => {
    if (userStatus.group_key && userStatus.group_key !== "" && userStatus.group_key.length && userGroupChanged) {
      const userGroup = userStatus?.user_groups.filter((item) => item.group_key === userStatus.group_key)[0];
      const workspaces = userGroup?.workspaces;
      setWorkspaceData([
        ...workspaces?.map((element: UserItem) => ({
          ...element,
          workspace_type_route:
            element?.workspace_type
              ?.split?.("_")
              ?.map?.((e: string) => e[0])
              .join("") ?? WorkspaceType.AR,
        })),
      ]);
    }
  }, [userStatus.group_key, userGroupChanged]);

  /**
   * Whenever multiAccount switch is done load the templates for the selected company
   */
  useEffect(() => {
    createTemplateMapsForWorkspaces();
  }, [workspaceData]);

  return (
    <>
      <Tracking identity={userInfo} profileData={profileData as UserAccountModelV2} />
      <div className="accountInfo-wrapper">
        <CompanyProfile
          accountType={selectedWorkspace?.name ?? "Accounts Receivable"}
          companyName={userStatus.account_name || "Pending..."}
          userGroups={userStatus.user_groups}
          handleUserGroupChange={handleUserGroupChange}
        />
        <GlobalSearchWidget />
      </div>

      <div className="userInfo-wrapper">
        <LastSync />
        <SettingsPopUp tooltipPlacement="bottom-end" />
        <LockstepBar />
        <Userpic id="logout-btn" onClick={(e: MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(e.currentTarget)} imageUrl={imageUrl ?? ""} />
        <Menu
          open={menuIsOpen}
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem
            onClick={() => {
              history.push(`/profiles`);
              setMenuAnchorEl(null);
            }}
          >
            My Profile
          </MenuItem>
          <MenuItem onClick={() => logout()}>Sign Out</MenuItem>
        </Menu>
      </div>
    </>
  );
}
