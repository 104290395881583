import clsx from "clsx";
import React from "react";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import Button from "../Button/Button";
import { LeftArrow, RightArrow } from "../Icons/Icons";
import { CarouselComponentInterface } from "./CarouselInterfaces/CarouselInterfaces";
import "./CarouselStyles/Carousel.scss";

const CarouselComponent: React.FC<CarouselComponentInterface> = (props: CarouselComponentInterface) => {
  const getNavButtons = () => {
    return (
      <>
        {!props.navigatorControls.hideControls && !props.autoScroll && (
          <div
            className={clsx(
              "navigator",
              { ["navigator-top-start"]: ["top", "top-start"].includes(props?.navigatorControls?.position ?? "") },
              { ["navigator-top-center"]: props?.navigatorControls?.position === "top-center" },
              { ["navigator-top-end"]: props?.navigatorControls?.position === "top-end" },
              { ["navigator-middle-start"]: ["middle", "middle-start"].includes(props?.navigatorControls?.position ?? "") },
              { ["navigator-middle-center"]: props?.navigatorControls?.position === "middle-center" },
              { ["navigator-middle-end"]: props?.navigatorControls?.position === "middle-end" },
              { ["navigator-bottom-start"]: ["bottom", "bottom-start"].includes(props?.navigatorControls?.position ?? "") },
              { ["navigator-bottom-center"]: props?.navigatorControls?.position === "bottom-center" },
              { ["navigator-bottom-end"]: props?.navigatorControls?.position === "bottom-end" }
            )}
          >
            <Button
              disabled={props.totalChildNodes <= DEFAULT_NUMERIC_VALUES.DEFAULT_ONE}
              className="navigator-buttons"
              type="button"
              size="sm"
              variant="solo-icon"
              icon={<LeftArrow />}
              onClick={props.navigatorControls.handlePrev}
              alignIcon="left"
            />
            <Button
              disabled={props.totalChildNodes <= DEFAULT_NUMERIC_VALUES.DEFAULT_ONE}
              className="navigator-buttons"
              type="button"
              size="sm"
              variant="solo-icon"
              icon={<RightArrow />}
              onClick={props.navigatorControls.handleNext}
              alignIcon="left"
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className="carousel-container carousel-container-dimensions"
      style={{ width: props.dimension?.width ?? "", height: props.dimension?.height ?? "" }}
    >
      <>
        {props.totalChildNodes === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && (
          <>
            <div className="carousel-item">{props.children}</div>
            {getNavButtons()}
          </>
        )}
        {props.totalChildNodes > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && props.children instanceof Array && (
          <>
            {props.children?.flat(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)?.map((element, index) => (
              <div
                key={index}
                className={clsx(
                  "carousel-item",
                  { ["inactive-item-l"]: props.currentActiveIndex > index },
                  { ["inactive-item-r"]: props.currentActiveIndex < index }
                )}
              >
                {element}
                {getNavButtons()}
              </div>
            ))}
          </>
        )}
      </>
    </div>
  );
};

export default CarouselComponent;
