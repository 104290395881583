import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { AppContext } from "../../../../../contexts/AppContext";

export default function ArAgingHeader(): React.ReactElement {
  const location = useLocation();
  const [ArAgingHeader, setArAgingHeader] = useState<ArAgingHeaderInfoModel>({} as ArAgingHeaderInfoModel);
  const { getArAgingHeader } = React.useContext(AppContext) as AppType;
  const { userStatus } = React.useContext(AppContext) as AppType;
  const locationMap = new Map([
    ["aging", "Past Due 91+"],
    ["late_61_90", "Past Due 61-90"],
    ["late_31_60", "Past Due 31-60"],
    ["late_1_30", "Past Due 1-30"],
    ["future_due", "Future Due"],
  ]);

  useEffect(() => {
    const currentBucket = location.pathname.substring(location.pathname.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) ?? "";
    try {
      getArAgingHeader().then((data) => {
        setArAgingHeader(data[data.findIndex((element: ArAgingHeaderInfoModel) => element.reportBucket === locationMap.get(currentBucket))]);
      });
    } catch (error: any) {
      console.log(error);
    }
  }, [location.pathname]);

  return (
    <>
      <span className="text-span">
        {ArAgingHeader.totalCustomers ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO} <span className="text-label">Customers</span>
      </span>
      <span className="text-span">
        {ArAgingHeader.totalInvoicesOutstanding ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO} <span className="text-label">Open Invoices</span>
      </span>
      <span className="text-span">
        {new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          style: "currency",
          currency: userStatus?.currency?.code ?? "USD",
        }).format(ArAgingHeader.totalOutstandingAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)}{" "}
        <span className="text-label">Outstanding Amount</span>
      </span>
      <span className="text-span">
        {`${new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          style: "decimal",
        }).format(ArAgingHeader.percentageOfTotalAr ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)}%`}{" "}
        <span className="text-label">Percentage of Total AR</span>
      </span>
    </>
  );
}
