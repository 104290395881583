import React, { useState, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import { NUMERIC_TIMEOUTS } from "../../../constants/NumericConstants";
import { CUSTOM_DONUT_CHART } from "../../../constants/StyleConstants";

// Defines the Pie Props
interface Props {
  hoverSquare: boolean;
  graphData: GraphData[];
  graphColors: string[];
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
  handleClick: (id: string) => void;
  currency?: { code: string; symbol: string; locale: string };
}

const DonutChart = (props: Props): React.ReactElement => {
  const [data, setData] = useState<GraphData[]>([]);

  useEffect(() => {
    const animations = setTimeout(() => setData(props.graphData), NUMERIC_TIMEOUTS.FOURTYTH_OF_SECOND);
    return () => clearTimeout(animations);
  }, []);

  return (
    <ResponsivePie
      data={data}
      margin={{
        top: props.marginTop,
        right: props.marginRight,
        bottom: props.marginBottom,
        left: props.marginLeft,
      }}
      sortByValue={false}
      innerRadius={0.7}
      borderColor={{ from: "color", modifiers: [["darker", CUSTOM_DONUT_CHART.COLOR_MODIFIER_DARK]] }}
      colors={props.graphColors}
      motionConfig="slow"
      enableArcLinkLabels={false}
      activeOuterRadiusOffset={4}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#ffffff"
      arcLinkLabelsColor={{ from: "color", modifiers: [] }}
      enableArcLabels={false}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: "color", modifiers: [["darker", CUSTOM_DONUT_CHART.COLOR_MODIFIER_DARKER]] }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick={(ev: any) => props.handleClick(ev.id)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tooltip={(input: any) => {
        return (
          <div className={`hover-wrapper`}>
            {props.hoverSquare && <div className={`hover-square`} style={{ backgroundColor: input.datum.color ?? "black" }} />}
            <p className={`hover-text`}>
              {input.datum.id}:{" "}
              {new Intl.NumberFormat(props?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: props?.currency?.code ?? "USD",
              }).format(input.datum.value)}
            </p>
          </div>
        );
      }}
    />
  );
};

export default DonutChart;
