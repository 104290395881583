import React from "react";
import { useHistory, useParams } from "react-router";
import useMagicAuth from "../../hooks/useMagicAuth";
import ApprovalRequestContainer from "./ApprovalRequest/ApprovalRequestContainer";
import ApprovalActivityContainer from "./ApprovalActivity/ApprovalActivityContainer";
import "./Approvals.scss";
import Status from "./Status/Status";
import { Success } from "../library/Icons/Icons";
import { MagicAuthContext, MagicAuthContextType } from "../../contexts/external/MagicAuthContext";
import { ApprovalDetailsContext, ApprovalsProviderType } from "../../contexts/external/ApprovalDetailsContext";
import { ApprovalActivityAccessor, AuthAccessor } from "../../db/unauthAccessors";
import { formatDate } from "../../db/utils/date";
import Loading from "../library/Loading/Loading";
import { ActivityStreamData, RequestDetailsType } from "./ApprovalsInterfaces";
import Utils from "../../utils/utils";
// import { DEFAULT_NUMERIC_VALUES } from "../../constants/NumericConstants";

type ReviewAction = { actionTaken: boolean; success: boolean };

const ApprovalReview = () => {
  const [action, setAction] = React.useState<ReviewAction>();
  const [activity, setActivity] = React.useState<ActivityStreamData>({} as ActivityStreamData);
  const [reviewerName, setReviewerName] = React.useState<string>("");

  const { authenticated } = React.useContext(MagicAuthContext) as MagicAuthContextType;
  const { requestData, loading, error, setError, fetchRequestData } = React.useContext(ApprovalDetailsContext) as ApprovalsProviderType;
  const history = useHistory();

  // const countRef = React.useRef<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  // UUID to set up recurring token request
  const { id } = useParams<{ id: string }>();
  useMagicAuth(id);

  const parseRequestDetails = (data: RequestDetailsType) => {
    return {
      title: `${data.from} requested an approval from you`,
      date: `${formatDate(data.date)}`,
      message: `${data.message}`,
      status: `${data.status}`,
    };
  };

  /**
   * fetch request details and checks for action performed,
   * if the request is already reviewed, redirects the user
   * to reviewed screen
   */
  const getRequestData = async () => {
    await fetchRequestData(id);
    if (["approved", "declined"].includes(requestData.status)) {
      setAction({
        actionTaken: true,
        success: requestData.success,
      });
    }
  };

  /**
   * fetches the activity stream details where approval request
   * was intialized.
   */
  const fetchActivityFeed = async () => {
    let response = {} as any;
    try {
      response = await ApprovalActivityAccessor.getActivityDetails(id);
    } catch (error) {
      console.error("error: ", error);
      setError(true);
    } finally {
      if (response.success) {
        setActivity({
          stream: response.data.activity_stream,
          transactions: response.data.transactions,
          attachments: response.data.attachments?.map((item: AttachmentItem) => ({
            ...item,
            created_at: Utils.evaluateDate(item.created_at as number),
          })),
          erpConnection: response.data.erp_connection,
        });
      }
    }
  };

  /**
   * This function required to check whether action is
   * taken against a request or not and updates the state
   * accordingly.
   * @param action whether action against the request has
   * been taken
   */
  const onClickReview = (action: ReviewAction, reviewerName: string) => {
    setAction(action);
    setReviewerName(reviewerName);
  };

  const SuccessScreen = () => (
    <Status
      size="lg"
      logo={<Success />}
      statusMessage={`Nice work, thanks for taking care of ${reviewerName ? `${reviewerName}’s` : "the"} request!`}
      subText={"Your response to the approval request has been sent to the requestor."}
    />
  );

  React.useEffect(() => {
    // if (countRef.current > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      (async function fetchDetails() {
        if (!authenticated) {
          await AuthAccessor.getToken(id);
        } else {
          await getRequestData();
          await fetchActivityFeed();
        }
      })();
    // }
    // countRef.current += DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
  }, [authenticated]);

  /** redirects user if error occur on the page */
  React.useEffect(() => {
    error && history.push("/link-expired");
  }, [error]);

  return action?.actionTaken && action?.success ? (
    <SuccessScreen />
  ) : authenticated && !loading ? (
    <div className="approval-container">
      <ApprovalRequestContainer id={id} onClickReview={onClickReview} requestData={parseRequestDetails(requestData)} />
      <ApprovalActivityContainer {...activity} />
    </div>
  ) : (
    <div className="redirect-form-loader">
      <Loading isRelative={true} />
    </div>
  );
};

export default ApprovalReview;
