import React from "react";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import { ActivityStatus } from "../../../types/enums";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import ActivitiesTable from "../ActivitiesTable/ActivitiesTable";

const AR_COLUMNS = [
  {
    Header: "From",
    accessor: "primary_connection.name",
    width: "20%",
    showFilter: true,
    showSort: true,
    searchlightField: "platform_connection_company_name",
    searchlightToken: "cont",
    alignment: "left",
    filterAnchorPosition: "right",
    Cell: (props: { row: { original: { read: boolean } } }) => TableUtils.formatActivityIndex(props.row.original, !props.row.original.read),
  },
  {
    Header: "Subject",
    accessor: "subject_and_attachments",
    width: "50%",
    showFilter: true,
    showSort: true,
    searchlightField: "subject",
    sortField: "subject",
    searchlightToken: "i_cont",
    alignment: "left",
    filterAnchorPosition: "center",
    Cell: (props: { value: { subject: string | null; attachments: any }; row: { original: { read: boolean } } }) => {
      return TableUtils.formatActivitySubject(props.value, !props.row.original.read);
    },
  },
  {
    Header: "Last Activity Date",
    accessor: "last_activity_at",
    width: "25%",
    showSort: true,
    searchlightField: "CREATED",
    sortField: "CREATED",
    searchlightToken: "CONTAINS",
    alignment: "left",
    filterAnchorPosition: "left",
    Cell: (props: { value: number | null; row: { original: { read: boolean } } }) =>
      TableUtils.formatActivitySentDateEpoch(props.value, !props.row.original.read),
  },
];

const AP_COLUMNS = [
  {
    Header: "From",
    accessor: "primary_connection.name",
    width: "15%",
    showFilter: true,
    showSort: true,
    searchlightField: "platform_connection_company_name",
    searchlightToken: "cont",
    alignment: "left",
    filterAnchorPosition: "right",
    Cell: (props: { row: { original: { read: boolean } } }) => TableUtils.formatActivityIndex(props.row.original, !props.row.original.read),
  },
  {
    Header: "Subject",
    accessor: "subject_and_attachments",
    width: "40%",
    showFilter: true,
    showSort: true,
    searchlightField: "subject",
    sortField: "subject",
    searchlightToken: "i_cont",
    alignment: "left",
    filterAnchorPosition: "center",
    Cell: (props: { value: { subject: string | null; attachments: any }; row: { original: { read: boolean } } }) => {
      return TableUtils.formatActivitySubject(props.value, !props.row.original.read);
    },
  },
  {
    Header: "Last Activity Date",
    accessor: "last_activity_at",
    width: "20%",
    showSort: true,
    searchlightField: "CREATED",
    sortField: "CREATED",
    searchlightToken: "CONTAINS",
    alignment: "left",
    filterAnchorPosition: "left",
    Cell: (props: { value: number | null; row: { original: { read: boolean } } }) =>
      TableUtils.formatActivitySentDateEpoch(props.value, !props.row.original.read),
  },
  {
    Header: "Flag",
    accessor: "flag",
    width: "25%",
    showFilter: false,
    showSort: true,
    searchlightField: "auto_detected_flag",
    sortField: "auto_detected_flag",
    searchlightToken: "cont",
    alignment: "left",
    filterAnchorPosition: "center",
    Cell: (props: { value: string | null }) => TableUtils.formatActivityFlag(props.value as StreamFlag),
  },
];

export default function Unassigned(): React.ReactElement {
  const { selectedWorkspace } = useWorkspaceConfigurations();
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;

  return (
    <div className="table-activity-wrapper">
      <div className="body-table">
        <ActivitiesTable
          predefinedFilter={[
            {
              route: "waitingForResponse",
              searchlightFilter: `(ACTIVITYSTATUS EQ '${ActivityStatus.WaitingForResponse}') AND (ISOPEN EQ true) AND (USERASSIGNEDTO IS NULL)`,
            },
            {
              route: "closed",
              searchlightFilter: `(ACTIVITYSTATUS EQ '${ActivityStatus.Closed}') AND (ISOPEN EQ false) AND (USERASSIGNEDTO IS NULL)`,
            },
          ]}
          columns={isEnabled("FORWARD_AP_AUTOMATION") && selectedWorkspace?.workspace_type_route === "ap" ? AP_COLUMNS : AR_COLUMNS}
          assigneeType={"unassigned"}
          route={"unassigned"}
        />
      </div>
    </div>
  );
}
