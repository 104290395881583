import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Divider } from "@mui/material";
import Button from "../../../../library/Button/Button";
import { RelatedTransactionItemProps } from "../ActivityTabInterfaces";
import Utils from "../../../../../utils/utils";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import { DateTime } from "luxon";
import { GLOBAL_SEARCH_CONSTANTS } from "../../../../../constants/GlobalSearchConstants";
import { SmartText } from "../../../../library/SmartText";
import { AppContext } from "../../../../../contexts/AppContext";

const RelatedTransactionItem = (props: RelatedTransactionItemProps) => {
  const [isHighlighting, setIsHighlighting] = useState<boolean>(true);
  const { search } = useLocation();
  const queryFromParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const { userStatus } = React.useContext(AppContext) as AppType;

  const valueParser = (val: number, type: string) => {
    switch (type) {
      case "currency":
        return Utils.formatValueAsCurrency(val, userStatus?.currency?.locale, userStatus?.currency?.code);
      case "date":
        return DateTime.fromFormat(val.toString(), "yyyy-mm-dd").toFormat("MMM dd, yyyy");
      default:
        return val;
    }
  };

  /**
   * @function escapeTextHighlight
   * A helper function to catch "escape key" press and disable highlighting of search results, and also delete the query param
   */
  const escapeTextHighlight = useCallback((event: KeyboardEvent) => {
    if ((event && event.keyCode === GLOBAL_SEARCH_CONSTANTS.ESCAPE_KEY_CODE) || event.key === "Escape" || event.code === "Escape") {
      setIsHighlighting(false);
      queryFromParams?.delete("query");
    }
  }, []);

  /**
   * A side-effect to catch escape key press to disable search result text highlighting
   */
  useEffect(() => {
    document.addEventListener("keydown", escapeTextHighlight);
    return () => {
      document.removeEventListener("keydown", escapeTextHighlight);
    };
  }, [escapeTextHighlight]);

  return (
    <>
      <div className="rt-component">
        <div className="rt-wrapper">
          <div className="icon-container">
            <div className="rt-icon">{props.icon}</div>
          </div>
          <div className="details-container">
            <div className="header">
              <span className="label">
                <SmartText
                  title={props.label}
                  highlighter={{
                    enableHighlighter: isHighlighting,
                    matchText: queryFromParams.get("query") ?? "~",
                  }}
                />
              </span>
              {!props.readonly && <div className="ellipse-icon">{TableUtils.formatAction(props.item, props.ellipsesMenuItems)}</div>}
            </div>
            <div className="details">
              {Object.keys(props.meta).map((info: string) => {
                const key = props.meta[info].label;
                const val = valueParser((props.item as any)[info], props.meta[info].type);
                return (
                  val && (
                    <div key={key} className="row">
                      <span className="col-key">{key}</span>
                      <span className="col-val">
                        <SmartText
                          title={(val as string) ?? "N/A"}
                          highlighter={{
                            enableHighlighter: isHighlighting,
                            matchText: queryFromParams.get("query") ?? "~",
                          }}
                        />
                      </span>
                    </div>
                  )
                );
              })}
            </div>
            {!props.readonly && (
              <div className="rt-btn">
                <Button variant="secondary" size="sm" onClick={props.onClickNavigate}>
                  <div className="rt-btn-label">{props.buttonLabel}</div>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Divider flexItem className="rt-divider" />
    </>
  );
};

export default RelatedTransactionItem;
