import APIClient from "../APIClient";

type ApAutomationSettings = {
  ap_automation_email: string;
};

// payload type should be an interface, extending each type of inbox settigns
// interface SettingsPayload extends ApAutomationSettings {}

export class SettingsClient {
  BASE_ROUTE = "/inbox/api/v2/accounts";

  async updateSettings(payload: ApAutomationSettings): Promise<APIResponse> {
    const res = await APIClient.patch(this.BASE_ROUTE, payload);
    return res.data;
  }
}
