import React, { useMemo } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Navbar from "../../../library/Navbar/Navbar";
import PayablesComingDueTable from "./PayablesComingDueTable/PayablesComingDueTable";
import { DateTime } from "luxon";
import "../Drilldowns.scss";
import PayablesComingDueHeader from "./PayablesComingDueHeader/PayablesComingDueHeader";
import FourOFourError from "../../../../routes/FourOFourError/FourOFourError";
import { DEFAULT_NUMERIC_VALUES, NEXT_DAYS } from "../../../../constants/NumericConstants";
import { DRILLDOWN_NAVIGATION_CONSTANTS } from "../../../../constants/NavigationConstants";

const routes = [
  { displayName: "Due Now", routeName: "due_now?sort=DUEDATE+DESC%2C+VENDORNAME+DESC" },
  { displayName: "Next 7 Days", routeName: "next_7_days?sort=DUEDATE+DESC%2C+VENDORNAME+DESC" },
  { displayName: "Next 14 Days", routeName: "next_14_days?sort=DUEDATE+DESC%2C+VENDORNAME+DESC" },
  { displayName: "Next 30 Days", routeName: "next_30_days?sort=DUEDATE+DESC%2C+VENDORNAME+DESC" },
];

export default function PayablesComingDue(): React.ReactElement {
  const { path } = useRouteMatch();
  const reportDate = useMemo(() => {
    const childPath = location.pathname.slice(location.pathname.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
    const reportDate = DateTime.now();
    return {
      due_now: reportDate.toFormat("yyyy-MM-dd"),
      next_7_days: reportDate.plus({ days: NEXT_DAYS.SEVEN }).toFormat("yyyy-MM-dd"),
      next_14_days: reportDate.plus({ days: NEXT_DAYS.FORTEEN }).toFormat("yyyy-MM-dd"),
      next_30_days: reportDate.plus({ days: NEXT_DAYS.THIRTY }).toFormat("yyyy-MM-dd"),
    }[childPath];
  }, [location.pathname]);

  return (
    <div className="drilldown-wrapper">
      <div className="head">
        <Navbar
          tabs={routes}
          rootPath={path}
          dropdownRootPath={path.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/"))}
          dropdown={[
            { displayName: "DPO", routeName: "days_payables_outstanding?sort=AMOUNTBILLEDOUTSTANDING+DESC%2C+VENDORNAME+DESC" },
            { displayName: "Current AP Aging", routeName: `aging?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.AGING.SORT}` },
            { displayName: "Payables Coming Due", routeName: "payables_coming_due/due_now?sort=DUEDATE+DESC%2C+VENDORNAME+DESC" },
            { displayName: "Payables Summary", routeName: "payables_summary?sort=AMOUNTPAIDLAST30+DESC%2C+VENDORNAME+DESC" },
          ]}
          dropdownDefault={"Payables Coming Due"}
          backDisplayName="Dashboard"
          backRoute={`${path.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/"))}`}
          handleBackRoute
        />
      </div>
      <div className="body">
        <div className="content">
          <PayablesComingDueHeader
            list={[
              { type: "totalVendors" },
              { type: "totalBillsPastDue", customLabel: "Bills Due" },
              { type: "totalPastDueAmount", customLabel: "Total Amount Due" },
              { type: "percentageOfTotalAp", customLabel: "of Total Payable" },
            ]}
            reportDate={reportDate || ""}
          />
          <div className="content-table">
            <Switch>
              <Route
                exact
                path={routes.map((route: { displayName: string; routeName: string }) => {
                  return `${path}/${route.routeName.replace(/\?.+$/, "")}`;
                })}
                component={() => {
                  return <PayablesComingDueTable />;
                }}
              />
              <Route
                component={() => {
                  return <FourOFourError />;
                }}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
