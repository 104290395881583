import React, { useEffect, useRef, useState } from "react";
import { Drawer, TextField, DrawerProps } from "@mui/material";
import Button from "../../../../library/Button/Button";
import { TextArea } from "../../../../library/TextArea/TextArea";
import { companiesClient } from "../../../../../db/accessor";
import Autocomplete from "../../../../library/Autocomplete/Autocomplete";
import { ActivityContext } from "../../../../../contexts/ActivityContext";
import { AlertContext } from "../../../../../contexts/AlertContext";
import "./ActivityFeedActions.scss";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { WorkspaceContext } from "../../../../../contexts/WorkspaceContext";
import { ComponentConfigContext } from "../../../../../contexts/ComponentConfigContext";
import { WorkspaceType } from "../../../../../types/enums";
import {
  BTN_CANCEL,
  BTN_MOVE,
  HEADING_MOVE_ACTIVITY_STREAM,
  LBL_CONNECTION,
  SUB_HEADING_MOVE_ACTIVITY_STREAM,
} from "../../../../../constants/config";

interface ActivityMoveProps extends DrawerProps {
  onClose: () => void;
  onCallback: () => void;
  activityIds: string[];
}

export default function ActivityMove({ open, onClose, onCallback, activityIds, ...rest }: ActivityMoveProps): React.ReactElement {
  const { handleMove } = React.useContext(ActivityContext) as ActivityType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [noteValue, setNoteValue] = useState<string>("");

  const [isLoadingCompanyList, setLoadingCompanyList] = useState<boolean>(true);
  const [companyListOpen, setCompanyListOpen] = useState<boolean>(false);

  const [companyList, setCompanyList] = useState<Partial<CompanyModel>[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Partial<CompanyModel> | null>(null);
  const isMounted = useRef<boolean>(false);

  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const configs = getConfig(selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR) as any;

  const onMoveHandler = async () => {
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message:
        activityIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? (
          <p>
            Successfully changed connection to <span style={{ fontWeight: 700 }}>{selectedCompany?.companyName}</span>
          </p>
        ) : (
          `${activityIds.length} Activities Moved`
        ),
    };
    let response = {} as APIResponse;
    try {
      response = await handleMove(
        activityIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? activityIds[0] : activityIds,
        selectedCompany?.companyId ?? null,
        noteValue
      );
    } catch (e: unknown) {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message:
            activityIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? "Activity was not moved" : `${activityIds.length} Activities were not moved`,
        };
      }
      onCallback();
      setNoteValue("");
      onClose();
      setToastOptions(toastOptions);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onMoveHandler();
    setLoading(false);
  };

  const fetchCompanyList = async () => {
    setLoadingCompanyList(true);
    try {
      await companiesClient.getCompanies(configs.connections.defaultConnectionFilter).then((data) => {
        setCompanyList(
          data.records?.map((company: CompanyModel) => {
            return {
              companyId: company.companyId,
              companyName: company.companyName,
            };
          }) ?? []
        );
      });
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setLoadingCompanyList(false);
    }
  };

  // Load list of user choices for moving
  useEffect(() => {
    if (isMounted.current) {
      if (open) {
        fetchCompanyList();
      }
    } else {
      isMounted.current = true;
    }
  }, [open]);

  return (
    <Drawer className="activity-feed-action-drawer" open={open} anchor="right" disablePortal onClose={() => onClose()} {...rest}>
      <div className="activity-flyout">
        <h3 className="header">{HEADING_MOVE_ACTIVITY_STREAM}</h3>
        <p className="subheader body1">{SUB_HEADING_MOVE_ACTIVITY_STREAM}</p>
        <label htmlFor={"string"}>{LBL_CONNECTION}</label>
        <div>
          <Autocomplete
            getOptionLabel={(option: Partial<CompanyModel>) => option.companyName ?? "N/A"}
            options={companyList}
            loading={isLoadingCompanyList}
            open={companyListOpen}
            onOpen={() => {
              setCompanyListOpen(true);
            }}
            onClose={() => {
              setCompanyListOpen(false);
            }}
            onChange={async (_event, value: Partial<CompanyModel>) => {
              setSelectedCompany(value);
            }}
            renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: Partial<CompanyModel>) => (
              <li {...props} key={option.companyId}>
                {option.companyName}
              </li>
            )}
            blurOnSelect
            disableClearable
            disablePortal
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Choose A Connection",
                  disableUnderline: true,
                }}
              />
            )}
            isOptionEqualToValue={(option: Partial<CompanyModel>, value: string) => {
              return option.companyId === value;
            }}
          />
        </div>
        <TextArea
          label={{ regularText: "Note", extraText: " (optional)" }}
          placeholder={""}
          onChange={(e) => setNoteValue(e.target.value)}
          defaultValue={noteValue}
        />
        <div className="btn-grp">
          <Button variant="secondary" size="lg" onClick={() => onClose()} loading={isLoading}>
            {BTN_CANCEL}
          </Button>
          <Button size="lg" onClick={() => handleSubmit()} loading={isLoading}>
            {BTN_MOVE}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
