import React, { Dispatch, SetStateAction } from "react";
import Dialog from "../../library/Dialog/Dialog";
import DialogActions from "../../library/DialogActions/DialogActions";
import DialogContent from "../../library/DialogContent/DialogContent";
import DialogContentText from "../../library/DialogContentText/DialogContentText";
import DialogTitle from "../../library/DialogTitle/DialogTitle";
import Button from "../../library/Button/Button";

type SpamFraudModalProps = {
  connectionSpam: boolean;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleSpamFraud: () => void;
};

export default function SpamFraudModal(props: SpamFraudModalProps): React.ReactElement {
  const spamText =
    "This sender will be marked as spam. Would you like to stop recieving new messages from this sender. If so, you can mark the sender as Spam.";
  const fraudText =
    "This sender will be marked as Fraud. Would you like to stop recieving new messages from this sender. If so, you can mark the sender as Fraud";

  return (
    <Dialog open={props.showModal} onClose={() => props.setShowModal(false)}>
      <DialogTitle>{`Mark Sender as ${props.connectionSpam ? "Spam" : "Fraud"}?`}</DialogTitle>
      <DialogContent className="mt-ba mb-lg">
        <DialogContentText>{props.connectionSpam ? spamText : fraudText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="primary" onClick={() => props.handleSpamFraud()}>
          {`Mark ${props.connectionSpam ? "Spam" : "Fraud"}`}
        </Button>
        <Button className="ml-auto" variant="secondary" onClick={() => props.setShowModal(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
