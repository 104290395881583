import React, { useState, MouseEvent } from "react";
import Userpic from "../Userpic/Userpic";
import { Company, DropdownArrow } from "../../Icons/Icons";
import "./CompanyProfile.scss";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import Menu from "../../Menu/Menu";
import MenuItem from "../../MenuItem/MenuItem";
import _ from "underscore";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import Utils from "../../../../utils/utils";
import { AppContext } from "../../../../contexts/AppContext";

interface Props {
  accountType: string;
  companyName: string;
  userGroups: UserGroupModel[];
  handleUserGroupChange: (userGroupItem: UserGroupModel) => void;
}

/**
 * * Defines Company Settings for Navigation Side Bar
 */
export default function CompanyProfile(props: Props): React.ReactElement {
  const { loginUserCompany } = React.useContext(CustomerContext) as CustomerType;
  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const dropdownMenuOpen = Boolean(dropdownMenuAnchorEl);
  const [loadingUserGroupData, setLoadingUserGroupData] = useState(false);
  const { updateUserStatus } = React.useContext(AppContext) as AppType;

  const isMultiAccount = (): boolean => {
    return props?.userGroups && !_.isEmpty(props?.userGroups) && props?.userGroups.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
  };

  const onClickMenuItems = (userGroupItem: UserGroupModel) => {
    props.handleUserGroupChange(userGroupItem);
    setDropdownMenuAnchorEl(null);
  };

  /**
   * A helper function to update the user status when user tries to switch the
   * user group so that updated counts for activity streams are displayed
   */
  const fetchUpdatedUserGroupData = async () => {
    setLoadingUserGroupData(true);
    await updateUserStatus();
    setLoadingUserGroupData(false);
  };

  return (
    <>
      <div
        className={`companyProfile-wrapper`}
        onClick={(e: MouseEvent<any>) => {
          if (isMultiAccount()) {
            fetchUpdatedUserGroupData();
            setDropdownMenuAnchorEl(e.currentTarget);
          }
        }}
      >
        <div>
          <Userpic imageUrl={(loginUserCompany as CompanyModel).companyLogoUrl ?? ""} icon={<Company />}></Userpic>
        </div>
        <div>
          <div className="companyName-container">
            <p className={`companyName`}>{props.companyName}</p>
            {isMultiAccount() && <DropdownArrow></DropdownArrow>}
          </div>
          <p className={`accountType ${props.accountType.toLowerCase().split(" ").join("-")}`}>{props.accountType}</p>
        </div>
      </div>
      {isMultiAccount() && (
        <Menu
          open={dropdownMenuOpen}
          anchorEl={dropdownMenuAnchorEl}
          onClose={() => setDropdownMenuAnchorEl(null)}
          className="company-account-selector"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {props?.userGroups?.map((item, index: number) => {
            return (
              <MenuItem key={index} onClick={() => onClickMenuItems(item)}>
                <div className="company-logo">
                  {item?.company_logo_url ? <img loading="lazy" width="24" alt={item?.group_name} src={item?.company_logo_url} /> : <Company />}
                </div>
                <div className="company-user-group-info">
                  <span className="user-group-name">{item?.group_name || "--"}</span>
                  {loadingUserGroupData ? (
                    <span className="user-group-unread-activity-count loader"></span>
                  ) : (
                    <span className="user-group-unread-activity-count">{Utils.formatNumber(item?.unread_activity_streams_count || DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)}</span>
                  )}
                </div>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
}
