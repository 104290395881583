import React from "react";
import Button from "../../../../../library/Button/Button";
import Chip from "../../../../../library/Chip/Chip";
import { FlowChart } from "../../../../../library/Icons/Icons";
import { SmartText } from "../../../../../library/SmartText";
import { Divider } from "@mui/material";
import { ActivityStatus } from "../../../../../../types/enums";
import { formatDate } from "../../../../../../db/utils/date";

interface RASComponentInterface {
  header: string | null;
  status: string | null;
  lastActivity: string | null;
  streamId: string;
  streamItemId: string;
  handleRASSelection: (streamId: string, streamItemId: string) => void;
}

const RelatedActivityStreamsComponent: React.FC<RASComponentInterface> = ({
  header,
  status,
  lastActivity,
  streamId,
  streamItemId,
  handleRASSelection,
}: RASComponentInterface) => {
  
  const parseStatusToChipVariant = (activityStatus: string | null) => {
    switch(activityStatus){
      case ActivityStatus.WaitingForResponse.toLowerCase():
        return "not-started";
      case ActivityStatus.Active.toLowerCase():
        return "active";
      case ActivityStatus.Closed.toLowerCase():
        return "closed";
      case ActivityStatus.Snoozed.toLowerCase():
        return "snoozed";
      default:
        return "active";
    }
  };

  return (
    <div className="ras-container">
      <div className="ras-item-container">
        <div className="ras-icon">
          <div className="icon-container">
            <FlowChart />
          </div>
        </div>
        <div className="ras-payment-details-container">
          <SmartText title={header ?? "N/A"} className="header" />
          <div className="subheader caption">{formatDate(lastActivity?.toString?.() ?? null)}</div>
          <Button size="sm" type="button" variant="grey" className="see-stream-button" onClick={() => handleRASSelection(streamId, streamItemId)}>
            View Activity Stream
          </Button>
        </div>
        <div className="status-container">
          <Chip text={status?.toUpperCase().split("_").join(" ") ?? "ACTIVE"} variant={parseStatusToChipVariant(status ?? null)} />
        </div>
      </div>
      <Divider flexItem className="rs-divider" />
    </div>
  );
};

export default RelatedActivityStreamsComponent;
