import { DateTime } from "luxon";
import React from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { AppContext } from "../../../../../contexts/AppContext";
import { ComponentConfigContext } from "../../../../../contexts/ComponentConfigContext";
import { CustomerContext } from "../../../../../contexts/CustomerContext";
import { WorkspaceContext } from "../../../../../contexts/WorkspaceContext";
import { attachmentsClientV2, contactsClientV2 } from "../../../../../db/version2Accessor";
import { WorkspaceType } from "../../../../../types/enums";
import { HeaderComponent } from "../HeaderComponent/HeaderComponent";
import { ApHeaderInfoModelExtended, ArHeaderInfoModelExtended } from "../HeaderInterfaces/HeaderInterfaces";

export default function HeaderContainer() {
  // get the data
  const [ARHeaderInfo, setARHeaderInfo] = React.useState<ArHeaderInfoModelExtended>({} as ArHeaderInfoModelExtended);
  const [APHeaderInfo, setAPHeaderInfo] = React.useState<ApHeaderInfoModelExtended>({} as ApHeaderInfoModelExtended);
  const [contacts, setContacts] = React.useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [documentsCount, setDocumentsCount] = React.useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const { company, isDataUpdated } = React.useContext(CustomerContext) as CustomerType;
  const { getARHeader, getAPHeader } = React.useContext(AppContext) as AppType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const { userStatus } = React.useContext(AppContext) as AppType;

  const { customerId } = useParams<{ customerId: string }>();
  const { path } = useRouteMatch();

  const isArWorkspace = selectedWorkspace.workspace_type_route?.toLocaleLowerCase() === WorkspaceType.AR;
  const configs = getConfig(selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR)[
    path.includes("connections") ? "otherConnections" : "connections"
  ]["details"]["header"];

  const fetchHeaderData = () => {
    try {
      // verify the workspace and call respective header api -
      if (isArWorkspace) {
        getARHeader(DateTime.now().toFormat("yyyy-MM-dd"), customerId).then((data) => {
          const openInvoices = data?.totalInvoices - data?.totalInvoicesPaid;
          setARHeaderInfo({
            ...data,
            totalOpenInvoices: Number.isNaN(openInvoices) ? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO : openInvoices,
          });
        });
      } else {
        getAPHeader(DateTime.now().toFormat("yyyy-MM-dd"), customerId).then((data) => {
          const openBills = data?.totalBills - data?.totalBillsPaid;
          setAPHeaderInfo({
            ...data,
            totalOpenBills: Number.isNaN(openBills) ? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO : openBills,
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function fetchContactCount() {
    const contacts = await contactsClientV2.getContacts(
      selectedWorkspace.id,
      `qa[status_eq]=active&qa[is_active_eq]=true&qa[company_id_eq]=${customerId}`,
      undefined,
      NUMERIC_VALUES.CONSTANT_THOUSAND,
      DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
    );
    setContacts(contacts.records.length);
  }

  async function fetchDocumentCount() {
    const documents = await attachmentsClientV2.getAttachments(
      `filter_by=connection&connection_id=${customerId}`,
      "",
      DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
      NUMERIC_VALUES.CONSTANT_THOUSAND
    );
    setDocumentsCount(documents.records?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  }

  React.useEffect(() => {
    (async function fetchData() {
      await fetchHeaderData();
      await fetchContactCount();
      await fetchDocumentCount();
    })();
  }, [isDataUpdated]);

  return (
    <HeaderComponent
      transactionData={isArWorkspace ? ARHeaderInfo : APHeaderInfo}
      companyData={company as CustomerDetailsModel}
      configs={configs}
      contactsCount={contacts}
      documentsCount={documentsCount}
      currencyConfig={userStatus?.currency}
    />
  );
}
