import { AutocompleteRenderInputParams, TextField } from "@mui/material";
import React from "react";
import Autocomplete from "../../../Autocomplete/Autocomplete";
import { SelectFilterProps } from "../../Interfaces/TableActionInterfaces";

const SelectFilter: React.FC<SelectFilterProps> = ({ selectOptions, filterValue, setFilterValue, onPressKeyDown }) => {
  return (
    <Autocomplete
      options={selectOptions}
      onChange={(_event, val: { label: string; value: string; id: string }) => setFilterValue(val.value)}
      value={filterValue !== null ? selectOptions.filter((value: any) => value.value === filterValue ?? "")[0]?.label : null}
      disableClearable
      disablePortal
      getOptionLabel={(option) => option.label ?? option}
      isOptionEqualToValue={(option, value) => option.label === value}
      onKeyDown={onPressKeyDown}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} InputProps={{ ...params.InputProps, disableUnderline: true }} placeholder="Select" />
      )}
    />
  );
};

export default SelectFilter;
