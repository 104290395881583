import React, { useState } from "react";
import { default as ProfileTab } from "./ActivityProfileTab/ActivityProfileTab";
import { default as AttachmentTab } from "./ActivityAttachmentTab/ActivityAttachmentTab";
import "./ActivityTab.scss";
import { ActivityTabType } from "../../../../types/enums";
import { RelatedTransactions } from "./RelatedTransactions/Index";
import { RelatedTransactions as IRelatedTransactions } from "./ActivityTabInterfaces";
import { Skeleton } from "@mui/material";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";

import { ActivityContext } from "../../../../contexts/ActivityContext";

type ActivityTabProps = {
  phone: string;
  email: string;
  contacts: ContactItem[];
  attachments: AttachmentItem[];
  companyId: string;
  transactions: IRelatedTransactions;
  erpConnection: boolean;
  dataLoading: boolean;
};

export default function ActivityTab({ dataLoading = true, erpConnection = false, ...props }: ActivityTabProps): React.ReactElement {
  const { showAttachmentTab } = React.useContext(ActivityContext) as ActivityType;
  //Check if more attachments is clicked and show attachments Tab by default on opening Activity Detail
  const [tab, setTab] = useState<ActivityTabType>(showAttachmentTab ? ActivityTabType.Attachments : ActivityTabType.Profile);


  return (
    <div className="activity-tab-wrapper">
      <div className="head">
        {!dataLoading ? (
          <div className="tabs-wrapper">
            <p className={`tab ${tab === ActivityTabType.Profile ? "tab-active" : ""} body2`} onClick={() => setTab(ActivityTabType.Profile)}>
              Profile
            </p>
            {erpConnection && (
              <p
                className={`tab ${tab === ActivityTabType.Transactions ? "tab-active" : ""} body2`}
                onClick={() => setTab(ActivityTabType.Transactions)}
              >
                {`Transactions (${[...(props.transactions.invoices ?? []) , ...(props.transactions.bills ?? []) , ...(props.transactions.payments ?? [])].length})`}
              </p>
            )}
            <p className={`tab ${tab === ActivityTabType.Attachments ? "tab-active" : ""} body2`} onClick={() => setTab(ActivityTabType.Attachments)}>
                {`Attachments (${props.attachments ? props.attachments.length : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO})`}
            </p>
          </div>
        ) : (
          <Skeleton width={"16rem"} />
        )}
      </div>
      <div className="body">
        {tab === ActivityTabType.Profile && <ProfileTab phone={props.phone} email={props.email} contacts={props.contacts} />}
        {tab === ActivityTabType.Transactions && <RelatedTransactions transactions={props.transactions} />}
        {tab === ActivityTabType.Attachments && <AttachmentTab attachments={props.attachments} />}
      </div>
    </div>
  );
}
