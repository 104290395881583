import React, { useState, MouseEvent, useRef, Dispatch, SetStateAction, useEffect } from "react";
import Table, { HeaderButton, RowSelectButtons } from "../../../library/Table/Table";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import Button from "../../../library/Button/Button";
import { PlusSign, Delete, MoveTo } from "../../../library/Icons/Icons";
import { useParams, useHistory } from "react-router-dom";
import { Drawer } from "@mui/material";
import "./Contacts.scss";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { AlertContext } from "../../../../contexts/AlertContext";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { ContactFormContainer } from "./ContactForm/ContactFormContainer";
import { DataMode } from "../../../../types/enums";
import { ContactHoverBarContainer } from "./HoverBar/HoverBarContainer";
import { ContactMoveContainer } from "./ContactActions/ContactMove/ContactMoveContainer";
import { contactsClientV2 } from "../../../../db/version2Accessor";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { Modal } from "../../../library/DialogModal";

type ContactsProps = {
  tableData: TableData[];
  setTableData: Dispatch<SetStateAction<TableData[]>>;
  fetchParser: (response: FetchResult, variant?: FetchVariant) => Partial<TableData>[];
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isError: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageCount: number;
  setPageCount: Dispatch<SetStateAction<number>>;
  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
  totalCount: number;
  setTotalCount: Dispatch<SetStateAction<number>>;
  isLoadingRoleList: boolean;
  roleList: CodeDefinitionModel[];
  configs: any;
};

/**
 * * Define the Contacts Tab of the Customer Detail's page
 */
export default function ContactsView(props: ContactsProps): React.ReactElement {
  const { customerId } = useParams<{ customerId: string }>();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteIds, setDeleteIds] = useState<string[]>([]);

  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [disableRowSelectButtons, setDisableRowSelectButtons] = useState<boolean>(false);

  const [dataMode, setDataMode] = useState<DataMode>(DataMode.View);
  const [contactEditData, setContactEditData] = useState<ContactDataType | null>(null);
  const [contactFlyout, setContactFlyout] = useState<boolean>(false);

  const [moveIds, setMoveIds] = React.useState<string[]>([]);
  const [openMoveFlyout, setMoveFlyoutVisibility] = React.useState<boolean>(false);

  const [newPrimaryContact, setNewPrimaryContact] = React.useState<ContactDataType | null>(null);
  const [isContactAlreadyPrimary, setiIsContactAlreadyPrimary] = React.useState<boolean>(false);
  const [showPrimaryContactWarning, setShowPrimaryContactWarning] = React.useState<boolean>(false);

  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  const tableRef = useRef<TableHandle>(null);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { company, toggleDataUpdated } = React.useContext(CustomerContext) as CustomerType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const contactsConfigs = props.configs.contacts;

  const cellRendererMapping: CellRendererMappingObject = {
    "table.formatString": (props: { value: string | null }) => TableUtils.formatString(props.value, false, "contact-bold"),
    "table.formatDate": (props: { value: string | null }) => TableUtils.formatDate(props.value, false, "contact-bold"),
    "table.formatStringWithIcon": (props: { value: string | null; row: { original: TableData } }) =>
      TableUtils.formatStringWithIcon(props.value, props.row.original.isPrimary),
    "table.formatContactString": (props: { value: string | null }) => TableUtils.formatContactString(props.value),
    "table.formatPhoneNumber": (props: { value: string | null }) => TableUtils.formatPhoneNumber(props.value, "contact-bold"),
  };

  const tranformContactData = (
    contactId: UUID,
    contactName: string,
    contactRole: string,
    contactEmail: string,
    contactPhone: string,
    contactIsPrimary: boolean
  ): ContactDataType => ({
    contactId,
    contactName,
    contactRole,
    contactEmail,
    contactPhone,
    contactIsPrimary,
  });

  // manage flyout and modal states
  const editContacts = (ids: string) => {
    setDataMode(DataMode.Edit);
    const item = props.tableData.filter((row) => ids.includes(row.id))[0];
    setContactEditData(tranformContactData(item.id, item.name, item.role, item.email, item.phoneNumber, item.isPrimary));
    setContactFlyout(true);
  };

  const deleteContacts = (ids: string[] | string) => {
    setDeleteIds(ids instanceof Array ? ids : [ids]);
    setShowDeleteModal(true);
  };

  const markPrimaryContact = (data: ContactDataType) => {
    setNewPrimaryContact(data);
    setShowPrimaryContactWarning(true);
  };

  const moveContacts = (ids: string[] | string) => {
    setMoveIds(ids instanceof Array ? ids : [ids]);
    setMoveFlyoutVisibility(true);
  };

  // handlers
  const handleDeleteContacts = async () => {
    setShowLoader(true);
    let toastOptions: ToastOptions = { severity: "error", message: "Something Went Wrong", open: true };

    const createMessage = (actionTaken: string, noOfRecords: number) => {
      return noOfRecords > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? `${noOfRecords} contacts ${actionTaken} successfully`
        : `Contact is ${actionTaken} successfully`;
    };

    try {
      await contactsClientV2.deleteContacts(deleteIds, selectedWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE);
      toastOptions = { severity: "success", message: createMessage("deleted", deleteIds.length), open: true };
    } catch (error: unknown) {
      console.log("error:: ", error);
    } finally {
      setShowLoader(false);
      setToastOptions(toastOptions);
      // reset states
      setShowDeleteModal(false);
      setDeleteIds([]);
      // refresh table
      tableRef?.current?.softRefresh();
      toggleDataUpdated();
    }
  };

  const handleMarkPrimaryContact = async () => {
    setShowLoader(true);
    let toastOptions: ToastOptions = {
      severity: "error",
      message: "Something Went Wrong",
      open: true,
    };
    if (newPrimaryContact) {
      try {
        await contactsClientV2.updateContacts(
          {
            items: [
              {
                contact_id: newPrimaryContact.contactId,
                is_primary: true,
              },
            ],
          },
          selectedWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
        );
        toastOptions = {
          ...toastOptions,
          severity: "success",
          message: `${newPrimaryContact.contactName} is now the primary contact`,
        };
      } catch (error) {
        console.log("error:: ", error);
      } finally {
        setShowLoader(false);
        setToastOptions(toastOptions);
        // reset states
        setShowPrimaryContactWarning(false);
        setNewPrimaryContact(null);
        // refresh table
        tableRef?.current?.softRefresh();
        toggleDataUpdated();
      }
    }
  };

  function handleRowClick(e: MouseEvent<HTMLTableRowElement>, row: { original: { id?: string } }) {
    e.preventDefault();
    sessionStorage.setItem("lastPath", history.location.pathname);
    const path = history.location.pathname;
    history.push(`${path.substr(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/contacts"))}/contactProfile/${row.original.id ?? ""}`);
  }

  const headerBtns: HeaderButton[] = [
    {
      component: (
        <Button icon={<PlusSign />} alignIcon="left" onClick={() => setContactFlyout((prev: boolean) => !prev)}>
          Add Contact
        </Button>
      ),
      hideOnRowSelect: false,
    },
  ];

  const primaryAndERPContactLookup = (selectedRows: any) => {
    const hasPrimaryAndERPContact = selectedRows.some((row: any) => row.isPrimary || row.appEnrollmentId);
    setDisableRowSelectButtons(hasPrimaryAndERPContact);
  };

  const fetchContacts = () => {
    const preDefinedFilters = [
      ...contactsConfigs.table.preDefinedFilters,
      {
        searchlightFilter: `qa[company_id_eq]=${customerId}`,
      },
    ];
    const sortQuery = TableUtils.getSortFromURL(params);
    const filterQuery = TableUtils.getFilterFromURL(params, contactsConfigs.table.columns);
    return contactsClientV2.getContacts(
      selectedWorkspace.id,
      TableUtils.columnFilterParser(filterQuery, true, preDefinedFilters),
      TableUtils.sortQueryParser(sortQuery ?? "", true),
      props.pageSize,
      props.pageNumber
    );
  };

  const rowSelectBtns: RowSelectButtons[] = [
    {
      variant: "secondary",
      children: "Edit",
      alignIcon: "left",
      tooltip: "Edit",
      style: { border: "0.0625rem black" },
      disabled: disableEdit,
      callback: (ids: string[]) => editContacts(ids[0]),
    } as RowSelectButtons,
    ...(!disableRowSelectButtons
      ? ([
          "divider",
          {
            icon: <MoveTo />,
            variant: "solo-icon",
            alignIcon: "left",
            tooltip: "Move",
            style: { border: "0.0625rem black" },
            callback: moveContacts,
          } as RowSelectButtons,
          {
            icon: <Delete />,
            variant: "solo-icon",
            alignIcon: "left",
            tooltip: "Delete",
            style: { border: "0.0625rem black" },
            callback: deleteContacts,
          } as RowSelectButtons,
        ] as RowSelectButtons[])
      : []),
  ];

  const currentSelectedCompany = company as CustomerDetailsModel;

  useEffect(() => {
    if (contactEditData?.contactIsPrimary) {
      setiIsContactAlreadyPrimary(true);
    }
  }, [contactEditData?.contactIsPrimary]);

  return (
    <>
      <Table
        ref={tableRef}
        dataSets={[
          {
            id: "Contacts",
            displayName: "Contacts",
            data: {
              tableData: props.tableData,
              setTableData: props.setTableData,
              fetchCall: fetchContacts,
              fetchParser: props.fetchParser,
              disableCheckboxMessage: "Contact synced from your accounting system, you cannot edit/move/delete this contact.",
            },
            columns: contactsConfigs.table.columns.map((column: ColumnHeaderType) => {
              return { ...column, Cell: cellRendererMapping[column.CellRenderer] };
            }),
            handleRowClick: handleRowClick,
            defaultSort: "contact_name",
            defaultSortToken: "",
          },
        ]}
        states={{
          isLoading: props.isLoading,
          setLoading: props.setLoading,
          isError: props.isError,
          setError: props.setError,
          errorMessage: props.errorMessage,
          setErrorMessage: props.setErrorMessage,
        }}
        pagination={{
          pageCount: props.pageCount,
          setPageCount: props.setPageCount,
          pageNumber: props.pageNumber,
          setPageNumber: props.setPageNumber,
          totalCount: props.totalCount,
          setTotalCount: props.setTotalCount,
        }}
        toggles={{
          showSearchbar: false,
          showExportBtn: false,
          showCaption: true,
          showRowSelect: true,
          showNavigation: true,
        }}
        onRowSelect={primaryAndERPContactLookup}
        headerBtns={headerBtns}
        rowSelectBtns={rowSelectBtns}
        hoverBar={false}
        addCustomHoverBar={(row: TableData) => (
          <ContactHoverBarContainer
            barriers={false}
            data={row.original}
            moveContact={moveContacts}
            editContact={editContacts}
            deleteContact={deleteContacts}
            markPrimaryContact={markPrimaryContact}
          />
        )}
        setDisableContactEdit={setDisableEdit}
        useV2ColumnFilters={true}
      />
      <Drawer
        anchor={"right"}
        open={contactFlyout}
        onClose={() => {
          setContactFlyout(false);
          setDataMode(DataMode.View);
        }}
      >
        <ContactFormContainer
          dataMode={dataMode}
          customerId={customerId}
          setAddContactFlyout={setContactFlyout}
          flyoutState={contactFlyout}
          tableData={props.tableData}
          isLoadingRoleList={props.isLoadingRoleList}
          roleList={props.roleList}
          contactEditData={contactEditData}
          setDataMode={setDataMode}
          markPrimaryContact={markPrimaryContact}
          isContactAlreadyPrimary={isContactAlreadyPrimary}
        />
      </Drawer>
      <Modal
        modalStates={{
          open: showDeleteModal,
          onCloseFunction: () => setShowDeleteModal(false),
        }}
        title={{
          label: "Delete Contacts",
        }}
        content={{
          label: `Are you sure you want to delete ${deleteIds.length} contact${deleteIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? "" : "s"}`,
        }}
        primaryButton={{
          enable: true,
          type: "button",
          variant: "error",
          label: "Delete",
          loader: showLoader,
          onClickHandler: handleDeleteContacts,
        }}
        secondaryButton={{
          enable: true,
          type: "button",
          variant: "grey",
          label: "Cancel",
          onClickHandler: () => setShowDeleteModal(false),
        }}
      />
      <Modal
        modalStates={{
          open: showPrimaryContactWarning,
          onCloseFunction: () => setShowPrimaryContactWarning(false),
        }}
        title={{
          label: "Make primary contact?",
        }}
        content={{
          label: `${newPrimaryContact?.contactName} will become the default contact for communications to ${currentSelectedCompany.name}.  You can change to another contact in the future.`,
        }}
        primaryButton={{
          enable: true,
          type: "button",
          variant: "primary",
          label: "Yes, make primary",
          loader: showLoader,
          onClickHandler: handleMarkPrimaryContact,
        }}
        secondaryButton={{
          enable: true,
          type: "button",
          variant: "grey",
          label: "No",
          onClickHandler: () => setShowPrimaryContactWarning(false),
        }}
      />
      <ContactMoveContainer
        moveIds={moveIds}
        setMoveIds={setMoveIds}
        open={openMoveFlyout}
        setOpen={setMoveFlyoutVisibility}
        softRefresh={() => ((tableRef as any)?.current as any)?.softRefresh()}
      />
    </>
  );
}
