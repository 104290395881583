import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NoteStream from "../../../library/NoteStream/NoteStream";
import NotesProvider from "../../../../contexts/NoteContext";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { formatAddress } from "../../../../db/utils/address";
import { User as UserSVG, Email as EmailSVG, Fax as FaxSVG, Phone as PhoneSVG, Location as LocationSVG } from "../../../library/Icons/Icons";
import Detail from "../../../library/Detail/Detail";
import Loading from "../../../library/Loading/Loading";
import Error from "../../../library/Error/Error";

export default function Profile(): React.ReactElement {
  const { customerId } = useParams<{ customerId: string }>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { company, getCompanyDetails } = React.useContext(CustomerContext) as CustomerType;

  const fetchData = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      await getCompanyDetails(customerId);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return errorMessage ? (
    <Error>{errorMessage}</Error>
  ) : isLoading ? (
    <Loading />
  ) : (
    <>
      {/* Contacts */}
      <div className={`left`}>
        <Detail icon={<UserSVG />} columns={{ title: "Primary Contact", value: (company as CustomerDetailsModel)?.contactName }} />
        <Detail
          icon={<EmailSVG />}
          columns={{
            title: "Email",
            value: (company as CustomerDetailsModel)?.contactEmail ?? (company as CustomerDetailsModel)?.email,
            format: "email",
            copyToClipboard: true,
          }}
        />
        <Detail icon={<PhoneSVG />} columns={{ title: "Phone", value: company?.phoneNumber, format: "tel" }} />
        <Detail icon={<FaxSVG />} columns={{ title: "Fax", value: company?.faxNumber, format: "tel" }} />
        <Detail
          icon={<LocationSVG />}
          columns={{
            title: "Mailing Address",
            value: company?.address1
              ? formatAddress(
                  company?.address1 ?? "",
                  company?.address2 ?? "",
                  company?.address3 ?? "",
                  company?.city ?? "",
                  (company as CustomerDetailsModel)?.state ?? "",
                  company?.postalCode ?? "",
                  company?.country ?? ""
                )
              : null,
          }}
        />
      </div>
      {/* Note Stream */}
      <NotesProvider>
        <NoteStream title="Notes" tableKey="company" objectKey={customerId} />
      </NotesProvider>
    </>
  );
}
