import React, { MouseEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "../../library/Table/Table";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../constants/NumericConstants";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { PaymentsClientV2 } from "../../../db/version2Accessor";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import { AppContext } from "../../../contexts/AppContext";

type PaymentsProps = {
  configs: any;
};

export default function UnappliedPayments(props: PaymentsProps): React.ReactElement {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const tableConfigs = props.configs.view.unappliedPayments.table;

  const pageNoFromUrl = params.get("page");
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "0");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    } else {
      return pageNum;
    }
  });
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION_VALUES.PAGE_SIZE);
  const { workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { updateBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { userStatus } = React.useContext(AppContext) as AppType;
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setPlatformPageFetchParams,
    setDocumentType,
    setEnableDocumentSwitch,
    setRootSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;

  const fetchParser = (fetchResult: FetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: PaymentSummaryModelV2) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.payment_id,
          }),
          ...((variant === "export" || variant === "all") && {
            payment_number: record.reference_code,
            customer_name: record.payment_company_name,
            type: record.tender_type,
            payment_date: record.payment_date,
            payment_amount: record.payment_amount,
            unapplied_amount: record?.unapplied_amount,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const fetchPayments = () => {
    const defaultSort = `${props.configs.defaultSortKey} ${props.configs.defaultSortToken}`;
    let sortQuery = TableUtils.getSortFromURL(params);
    if (!sortQuery) {
      sortQuery = defaultSort;
    }
    const filterQuery = TableUtils.getFilterFromURL(params, tableConfigs.columns);
    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    const fetchParams = {
      pageSize,
      page: pageNumber,
      order: TableUtils.sortQueryParser(sortQuery ?? "", true),
      filter: TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters),
      spamFraudArchivedTab: false,
    };
    setPlatformPageFetchParams(fetchParams as PlatformPageFetchParams);
    return PaymentsClientV2.querySummaries(
      TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters),
      TableUtils.sortQueryParser(sortQuery ?? "", true),
      pageSize,
      pageNumber,
      false
    ).then((fetchResult: any) => {
      return fetchResult;
    });
  };

  // Handles redirect to Payment Details page on row click of the Data Grid
  const handleRowClick = (
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) => {
    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     * @param selectedActivityStreamId - currently selected document id
     * @param activityStreamIds - list of documents in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    setSelectedActivityStreamId({ id: row.original.id } as ActivityStreamId);
    setActivityStreamIds(tableData.map((item) => ({ id: item.id })));
    setCurrentPaginationState({ page: pageNumber, pageCount, pageSize, totalRecords: totalCount });
    setDocumentType("payment");
    setRootSwitcherStateSnapshot();
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${workspaceHomePath}/payments/${row.original.id ?? ""}`);
    updateBaseRoute(`${workspaceHomePath}/payments/${row.original.id ?? ""}`);
  };

  // table configuration
  const cellRendererMapping: CellRendererMappingObject = {
    "table.formatString": (props: { value: string | null }) => TableUtils.formatString(props.value, false, "contact-bold"),
    "table.formatDate": (props: { value: string | null }) => TableUtils.formatDate(props.value, false, "contact-bold"),
    "table.formatPaymentType": (props: { value: string | null }) => TableUtils.formatPaymentType(props.value, false),
    "table.formatCurrency": (props: { value: number | null }) =>
      TableUtils.formatCurrency(props.value, false, userStatus?.currency?.locale, userStatus?.currency?.code),
    "table.formatList": (props: { value: string[] | null; row: { original: { id: string } } }) =>
      TableUtils.formatList(props.value, false, props.row.original.id),
  };

  return (
    <Table
      key="Unapplied Payments"
      dataSets={[
        {
          id: "Unapplied Payments",
          displayName: "Unapplied Payments",
          rowSelectToken: "paymentId",
          columns: tableConfigs.columns.map((column: ColumnHeaderType) => {
            return { ...column, Cell: cellRendererMapping[column.CellRenderer] };
          }),
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: fetchPayments,
            fetchParser: fetchParser,
          },
          export: {
            exportFileName: "All Unapplied Payments",
          },
          handleRowClick: handleRowClick,
        },
      ]}
      states={{
        isLoading: isLoading,
        setLoading: setLoading,
        isError: isError,
        setError: setError,
        errorMessage: errorMessage,
        setErrorMessage: setErrorMessage,
      }}
      pagination={{
        pageCount: pageCount,
        setPageCount: setPageCount,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
        totalCount: totalCount,
        setTotalCount: setTotalCount,
        setPageSize: setPageSize,
      }}
      toggles={{
        showSearchbar: false,
        showExportBtn: true,
        showCaption: true,
        showRowSelect: true,
        showNavigation: true,
        showSelectAllBar: true,
      }}
    />
  );
}
