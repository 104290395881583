import React from "react";
import { useHistory } from "react-router";
import { MagicAuthContext, MagicAuthContextType } from "../../../contexts/external/MagicAuthContext";
import { ReviewResponse } from "../../../db/magicClients/ApprovalActivitiesClient";
import { ApprovalActivityAccessor } from "../../../db/unauthAccessors";
import { ApprovalRequestData } from "../ApprovalsInterfaces";
import ApprovalRequestComponent from "./ApprovalRequestComponent";

type ReviewAction = { actionTaken: boolean; success: boolean };
interface ApprovalRequestContainerProps {
  id: UUID;
  onClickReview: (action: ReviewAction, reviewerName: string) => void;
  requestData: ApprovalRequestData;
}

const ApprovalRequestContainer: React.FC<ApprovalRequestContainerProps> = ({ id, onClickReview, requestData }) => {
  const [approverMsg, setApproverMsg] = React.useState<string>("");
  const history = useHistory();
  const { refreshIntervalId, setRefreshIntervalId } = React.useContext(MagicAuthContext) as MagicAuthContextType;

  // Network
  const updateApprovalRequest = async (requestId: string, action: string, message: string | null) => {
    let response = {} as ReviewResponse;
    try {
      response = await ApprovalActivityAccessor.reviewRequest(requestId, action, message?.replace(/\n/g, "<br>\n").replace(/ /g, "&nbsp;") ?? "");
    } catch (error) {
      console.error("error: ", error);
      history.push("/link-expired");
    } finally {
      onClickReview(
        {
          actionTaken: true,
          success: response.success,
        },
        response.creator.name
      );
      // cancels the next token call after review action has been taken place
      clearInterval(refreshIntervalId);
      setRefreshIntervalId(undefined);
    }
  };

  // Event Handlers
  const onChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setApproverMsg(e.target.value);
  };
  const onClickApprove = () => {
    updateApprovalRequest(id, "approve", approverMsg);
  };
  const onClickDecline = () => {
    updateApprovalRequest(id, "decline", approverMsg);
  };

  return (
    <ApprovalRequestComponent requestData={requestData} onChangeNote={onChangeNote} onClickApprove={onClickApprove} onClickDecline={onClickDecline} />
  );
};

export default ApprovalRequestContainer;
