import React, { useState, useEffect } from "react";
import Arrow from "../../../library/Arrow/Arrow";
import Loading from "../../../library/Loading/Loading";
import Error from "../../../library/Error/Error";
import BarChart, { calculateLeftMargin } from "../../../library/Charts/BarChart";
import { DashboardContext } from "../../../../contexts/DashboardContext";
import "./DaysOutstandingWidget.scss";
import NoData from "../../../library/NoData/NoData";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { TooltipIcon } from "../../../library/Icons/Icons";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, TRIM_COUNT_CONSTANTS } from "../../../../constants/NumericConstants";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import Utils from "../../../../utils/utils";
import { AppContext } from "../../../../contexts/AppContext";

/**
 * * Defines the Days Sales Outstanding Widget
 */

interface DaysOutstandingModuleInterface {
  disableInfoTooltip?: boolean;
  configs: any;
}

export default function DaysOutstandingWidget({ configs, disableInfoTooltip = false }: DaysOutstandingModuleInterface): React.ReactElement {
  const { getOutstandingData } = React.useContext(DashboardContext) as DashboardType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { userStatus } = React.useContext(AppContext) as AppType;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<GraphData[]>([]);
  const history = useHistory();
  const { path } = useRouteMatch();

  // GET on /api/v1/Reports/dailysalesoutstanding
  const fetchData = async () => {
    // Default error message
    setErrorMessage("");
    // API call loading...
    setLoading(true);
    try {
      // On success, return data
      const res = await getOutstandingData();
      if (res && res.some((elem) => elem.value !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)) {
        return res;
      } else {
        return [];
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error.message);
      setErrorMessage("Error Loading Data");
    }
  };

  const handleOnClick = (id: string) => {
    if (!configs.isWidgetClickable) {
      return null;
    }
    const bucket = id ? id : data?.[data?.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE]?.id ?? "";

    const getDataValue = data?.filter((val) => val.id === bucket)?.[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO];
    const newDate = getDataValue?.timestamp ? new Date(getDataValue?.timestamp) : new Date(Date.now());
    const year = newDate.getFullYear();
    const route = `/${Utils.capitalize(bucket)}_${year?.toString?.()?.slice(NUMERIC_VALUES.CONSTANT_TWO)}`;

    history.push(`${path}/${configs.navRoute}${route}${configs.navSort}`);
  };

  /* On mount, fetch DSO Data, Also on Unmount set cancelInitialFetch to false to not setData 
     as this is a stale call which was made before switching workspaces. 
     It basically blocks the API call made for one workspace when we have already switched to another */
  useEffect(() => {
    let cancelled = false;
    fetchData().then((response) => {
      if (!cancelled) {
        setData(response ?? []);
        // API call finalized
        setLoading(false);
      }
    });
    return () => {
      cancelled = true;
    };
  }, [selectedWorkspace]);

  return isLoading ? (
    <div className={`dso-wrapper`}>
      <Loading />
    </div>
  ) : errorMessage ? (
    <div className={`dso-wrapper`}>
      <Error>{errorMessage}</Error>
    </div>
  ) : data.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
    <div className={`dso-wrapper`}>
      <div className={`dso-top`}>
        <p className={`title ${!configs.isWidgetClickable ? "non-clickable" : "clickable"}`}>
          {configs.prefix} {configs.header}
        </p>
        {!disableInfoTooltip && (
          <Tooltip title="The average number of days that it takes for an invoice to be fully paid." placement="right" arrow disableInteractive>
            <IconButton size="small">
              <TooltipIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <NoData />
    </div>
  ) : (
    <div className={`dso-wrapper`}>
      <div className={`dso-top`}>
        <p className={`title ${!configs.isWidgetClickable ? "non-clickable" : "clickable"}`} onClick={() => handleOnClick("")}>
          <span className="pretext">{configs.prefix} </span>
          {configs.header}
        </p>
        {!disableInfoTooltip && (
          <Tooltip title="The average number of days that it takes for an invoice to be fully paid." placement="right" arrow disableInteractive>
            <IconButton size="small">
              <TooltipIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={`dso-mid`}>
        <div>
          <p onClick={() => handleOnClick("")} className={`amount ${!configs.isWidgetClickable ? "non-clickable" : "clickable"}`}>
            {data && data.length !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
              ? `${new Intl.NumberFormat().format(
                  data[data.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE].value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
                )} Days`
              : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}{" "}
          </p>
        </div>
        <div className={`mid-row`}>
          <p className={`caption-med`}>{configs.duration}</p>
          <div className={`arrow-wrapper`}>
            <Arrow
              direction={
                data && data.length >= NUMERIC_VALUES.CONSTANT_TWO
                  ? (data[data.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE].value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) -
                      (data[data.length - NUMERIC_VALUES.CONSTANT_TWO].value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) <
                    DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
                    ? "down"
                    : (data[data.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE].value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) -
                        (data[data.length - NUMERIC_VALUES.CONSTANT_TWO].value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) >
                      DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
                    ? "up"
                    : "center"
                  : "up"
              }
            />
            <p className={`arrow-text`}>
              {data && data.length >= NUMERIC_VALUES.CONSTANT_TWO
                ? (data[data.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE].value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) -
                  (data[data.length - NUMERIC_VALUES.CONSTANT_TWO].value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
                : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}{" "}
              DAYS
            </p>
          </div>
        </div>
      </div>
      <div className={`dso-bot ${!configs.isWidgetClickable ? "non-clickable" : "clickable"}`}>
        <BarChart
          hoverSquare={false}
          data={data}
          onClick={(id) => {
            handleOnClick(id);
          }}
          padding={15}
          margin={
            data.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
              ? {
                  top: 15,
                  right: 0,
                  bottom: 15,
                  left: calculateLeftMargin(data),
                }
              : data.length === NUMERIC_VALUES.CONSTANT_TWO
              ? {
                  top: 12,
                  right: 0,
                  bottom: 12,
                  left: calculateLeftMargin(data),
                }
              : data.length === TRIM_COUNT_CONSTANTS.DSO_VALUE
              ? {
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: calculateLeftMargin(data),
                }
              : undefined
          }
          hoverFormat="days"
          currency={userStatus?.currency}
        />
      </div>
    </div>
  );
}
