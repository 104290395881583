import React, { ReactElement } from "react";
import { LeftArrow } from "../../library/Icons/Icons";
import "./SettingsNavbar.scss";

interface SettingsNavbarProps {
  backRoute?: string;
}

export default function SettingsNavbar(props: SettingsNavbarProps): ReactElement {
  return (
    <div className="settings-navbar">
      <div className="settings-navbar-nav" onClick={() => props.backRoute && history.back()}>
        <LeftArrow />
      </div>
      <div className="settings-navbar-header-text">
        <span className="header-text">Signature Settings</span> | <span className="subheader-text">Add or manage your signature.</span>
      </div>
    </div>
  );
}
