import React, { MouseEvent, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../../../constants/NumericConstants";
import { companiesClient } from "../../../../../db/accessor";
import { formatDate } from "../../../../../db/utils/date";
import { InvoiceStatusType, WorkspaceType } from "../../../../../types/enums";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import Table from "../../../../library/Table/Table";

import { AxiosError } from "axios";
import _ from "underscore";
import { TemplateDefinitionProps } from "../../../../../app/Templates/TemplateFactory";
import { viewType } from "../../../../../app/Templates/TemplateTypes";
import { CONNECTION_STATUS } from "../../../../../constants/ConnectionConstants";
import { ActivityContext } from "../../../../../contexts/ActivityContext";
import { AlertContext } from "../../../../../contexts/AlertContext";
import { AppContext } from "../../../../../contexts/AppContext";
import { DocumentSwitchContext } from "../../../../../contexts/DocumentSwitchContext";
import { TemplateContext } from "../../../../../contexts/TemplateContext";
import { WorkspaceContext } from "../../../../../contexts/WorkspaceContext";
import { automationClientV2, companiesClientV2, emailsClientV2, invoicesClientV2 } from "../../../../../db/version2Accessor";
import { EmailAction, FallbackTypes } from "../../../../../types/enums";
import Utils from "../../../../../utils/utils";
import NewActivityPopup from "../../../../library/AddNewActivityDropdown/NewActivityPopup";
import { Email, NoteFill, Phone } from "../../../../library/Icons/Icons";
import TrackingUtils from "../../../../Tracking/Tracking.Utils";
import { DateTime } from "luxon";
import { CustomerContext } from "../../../../../contexts/CustomerContext";

type InvoicedComponentType = {
  reportDate: string;
};

export default function Invoiced({ reportDate }: InvoicedComponentType): React.ReactElement<InvoicedComponentType> {
  const history = useHistory();
  const { path } = useRouteMatch();
  const params = new URLSearchParams(window.location.search);
  const pageNoFromUrl = params.get("page");
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "1");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
    } else {
      return pageNum;
    }
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION_VALUES.PAGE_SIZE);
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const tableDataSetIndex = params.get("dataSetIndex");
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const { signature } = React.useContext(CustomerContext) as CustomerType;
  const { allRowsSelected, handleClose } = React.useContext(ActivityContext) as ActivityType;
  const { userStatus, getContactsOptions, allContactOptions, filterPrimaryContacts } = React.useContext(AppContext) as AppType;
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const { templateFactory, prepareTemplateDataObject, handleTemplateAttachment, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const [selectedInvoice, setSelectedInvoice] = useState<any>();
  const [newActivityType, setNewActivityType] = useState<string | null>(null);
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const [editorState, setEditorState] = useState<string>("");
  const [defaultTitle, setDefaultTitle] = useState<string>("");
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const [templateName, setTemplateName] = useState<string>("");
  const { setPlatformPageFetchParams } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;

  const fetchCompanyInfo = (companyId: UUID) => {
    if (!companyId) {
      return;
    }
    companiesClient.getCompany(companyId, "Classification").then((companyModel: CompanyModel) => {
      setCompanyData(companyModel);
    });
  };

  /**
   * @function getCompanyIdForFirstTo
   * A helper function to get the company id for the first contact selected
   * @param to - Contacts
   * @returns
   */
  const getCompanyIdForFirstTo = (to: To[]) => {
    if (to && to.length) {
      return to[0].companyId;
    }
    return "";
  };

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "Messages Sent" };
    const activityTransactions = _.uniq([...inboxAttachments, { transaction_id: selectedInvoice.id, transaction_type: "invoice" }], "transaction_id");
    const payload: Partial<ActivityItemEmailModel> = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      contact_company_id: getCompanyIdForFirstTo(to),
      content_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "input_email"
          : newActivityType && newActivityType === "email"
          ? "input_email"
          : newActivityType,
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "email"
          : newActivityType,
      activity_transactions: activityTransactions,
      primary_connection_id: selectedInvoice.customer_id,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, activityTransactions),
    };

    return new Promise((resolve) => {
      emailsClientV2
        .post(payload)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;

            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(
            to,
            "Ellipsis",
            "New",
            isSendAndClose ?? false,
            allContactOptions,
            "Receivables Summary",
            `${path}/collected`,
            newActivityType ?? ""
          );
          setShowActivity(false);
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: "Activity Closed" };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: "Activity was not closed",
          };
        }
        setToastOptions(toastOptions);
      }
    }
  };

  const fetchCompanyContactOptions = async (customerId: string) => {
    await setCompanyContactOptions(await getContactsOptions(customerId));
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (row: any): TemplateDefinitionProps[] => {
    const view: viewType = viewType.AR_DASHBOARD_RECEIVABLES_SUMMARY_INVOICED;
    return templateFactory.getTemplateDefinitionsByView(view, { invoiceNumber: row.invoice_number, customer: row.customer_name });
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = async (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    setFileCallback: FunctionCall
  ) => {
    if (templateID) {
      setTemplateName(templateID);
      const templateObj = templateData.get(selectedWorkspace?.id).get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: selectedInvoice?.customer_name || "",
          invoiceNumber: selectedInvoice.invoice_number || "",
          paymentDueDate: selectedInvoice.payment_due_date || "",
          invoiceAmount: Utils.formatValueAsCurrency(
            selectedInvoice.invoice_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          outstandingBalance: Utils.formatValueAsCurrency(
            selectedInvoice.outstanding_balance ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          invoiceDate: selectedInvoice.invoice_date || "",
          signature: signature.email_signature || "",
        })
      );
      if (templateObj.templateAttachment) {
        const templateDataMapper = prepareTemplateDataObject(
          templateObj,
          [{ name: `Invoice #${selectedInvoice.invoice_number ?? "N/A"}`, id: selectedInvoice.id }],
          null,
          null
        );
        handleTemplateAttachment(templateObj, setFileCallback, templateDataMapper);
      }
    }
  };

  /**
   * Event handler which triggered when user select the 'email', 'note' or 'phone call'
   * context menu item. It will open the new activity dialog based to the context menu item
   * selected by the user.
   *
   * @param {Object} row The selected row from the table.
   * @param {String} buttonId The buttonId indicate which button has been selected by the user.
   */
  const onClickActionButton = async (row: any, buttonId: string) => {
    setSelectedInvoice(row);
    if (buttonId === "email") {
      setSupportedTemplateList(getTemplateDefinitionsByView(row));
    }
    await fetchCompanyContactOptions(row.customer_id);
    setNewActivityType(buttonId);
    setShowActivity(true);
  };

  /**
   * The ellipsis action handlers which used to open the activity dialog.
   */
  const ellipsisActionHandlers: Partial<RowSelectButtons> = {
    "action.newEmail": onClickActionButton,
    "action.newNote": onClickActionButton,
    "action.newPhoneCall": onClickActionButton,
  };

  const CUSTOMERSCOLUMNS = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "customer_name",
        width: "17.5%",
        showFilter: true,
        popupPlaceholder: "ex. Blushing Blooms",
        showSort: true,
        sortField: "companyName",
        searchlightField: "company_name",
        searchlightToken: "contains",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Primary Contact",
        accessor: "primary_contact",
        width: "15%",
        showFilter: true,
        popupPlaceholder: "ex. John Doe",
        showSort: true,
        searchlightField: "primary_contact",
        sortField: "primaryContact",
        searchlightToken: "contains",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Amount Invoiced",
        accessor: "amount_invoiced",
        width: "17.5%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "invoiced_amount_past_thirty_days",
        sortField: "invoicedAmountPastThirtyDays",
        searchlightToken: "lteq",
        alignment: "right",
        filterAnchorPosition: "center",
        Cell: (props: { value: number | null }) =>
          TableUtils.formatCurrency(props.value, true, userStatus?.currency?.locale, userStatus?.currency?.code),
      },
      {
        Header: "Total Amount Due",
        accessor: "total_amount_due",
        width: "17.5%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "outstanding_amount_past_thirty_days",
        sortField: "outstandingAmountPastThirtyDays",
        searchlightToken: "lteq",
        alignment: "right",
        filterAnchorPosition: "center",
        Cell: (props: { value: number | null }) =>
          TableUtils.formatCurrency(props.value, true, userStatus?.currency?.locale, userStatus?.currency?.code),
      },
      {
        Header: "Invoiced",
        accessor: "open_invoices",
        width: "17.5%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "invoices_past_thirty_days",
        sortField: "invoicesPastThirtyDays",
        searchlightToken: "eq",
        alignment: "right",
        filterAnchorPosition: "center",
        Cell: (props: { value: number | null }) => TableUtils.formatNumber(props.value, true),
      },
      {
        Header: "Last Activity",
        accessor: "last_activity",
        width: "17.5%",
        showFilter: false,
        popupPlaceholder: "ex. yyyy-mm-dd",
        showSort: false,
        searchlightField: "last_activity_at",
        sortField: "last_activity_at",
        searchlightToken: "eq",
        alignment: "left",
        Cell: (props: { value: number | null }) => (
          <p className="last_activity_data">
            {props.value ? formatDate(Utils.evaluateDate(props.value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)) : "N/A"}
          </p>
        ),
      },
      {
        Header: "",
        accessor: "actionHeader",
        width: "4.5%",
        showFilter: false,
        showSort: false,
        alignment: "right",
        Cell: (props: { value: string | null; row: { original: { status: string } } }) => {
          return TableUtils.formatAction(
            props.row.original,
            [
              {
                value: "New Email",
                id: "email",
                handler: "action.newEmail",
              },
              {
                value: "New Note",
                id: "note",
                handler: "action.newNote",
              },
              {
                value: "New Phone Call",
                id: "phone_call",
                handler: "action.newPhoneCall",
              },
            ].map((action: EllipsisAction) => {
              return { ...action, clickFunction: ellipsisActionHandlers[action.handler] };
            })
          );
        },
      },
    ],
    []
  );

  const customerFetchParser = (fetchResult: ConnectionsSummaryModelFetchResultV2, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: CustomerSummaryModelV2 | VendorSummaryModelV2) => {
        const recordData = record as CustomerSummaryModelV2;
        return {
          ...((variant === "id" || variant === "all") && {
            id: recordData?.company_id,
          }),
          ...((variant === "export" || variant === "all") && {
            customer_name: recordData?.company_name || "N/A",
            primary_contact: recordData?.primary_contact || "N/A",
            total_amount_due: recordData?.outstanding_amount_past_thirty_days,
            open_invoices: recordData?.invoices_past_thirty_days,
            last_activity: recordData?.last_activity_at,
            customer_id: recordData?.company_id,
            amount_invoiced: recordData?.invoiced_amount_past_thirty_days,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const getFilters = (exportConnection = false, ids?: string[]) => {
    const additionalFilters: { searchlightFilter: string }[] = [
      {
        searchlightFilter: `status=all`,
      },
      {
        searchlightFilter: `qa[outstanding_amount_past_thirty_days_gt]=0`,
      },
      {
        searchlightFilter: `qa[report_date]=${DateTime.fromFormat(reportDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy")}`,
      },
      {
        searchlightFilter: `workspace_id=${selectedWorkspace?.id || FallbackTypes.Id}`,
      },
    ];
    // evaluate export filters - consider ids only if all rows are not selected
    if (exportConnection && !allRowsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      ids.forEach((id: string) => {
        additionalFilters.push({
          searchlightFilter: selectedWorkspace?.workspace_type_route === WorkspaceType.AR ? `qa[company_id_in][]=${id}` : `qa[vendor_id_in][]=${id}`,
        });
      });
    }

    const defaultSort = `OUTSTANDINGAMOUNT DESC`;
    let sortQuery = TableUtils.getSortFromURL(params);
    if (!sortQuery || sortQuery === "") {
      sortQuery = defaultSort;
    }

    additionalFilters.push({
      searchlightFilter: `qs=${TableUtils.sortQueryParser(sortQuery ?? "", false)}`,
    });

    const filterQuery = TableUtils.getFilterFromURL(params, CUSTOMERSCOLUMNS);
    return TableUtils.columnFilterParser(filterQuery, true, additionalFilters ? [...additionalFilters] : undefined);
  };

  const getSummaryApi = () => {
    return companiesClientV2
      .getCustomerSummaries(getFilters(), undefined, pageSize, pageNumber - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)
      .then((fetchResult: any) => fetchResult);
  };

  function handleCustomerRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(
      `${path.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/dashboard/receivables_summary"))}/customers/${row.original.id ?? ""}`
    );
  }

  const INVOICESCOLUMNS = useMemo(
    () => [
      {
        Header: "Invoice Number",
        accessor: "invoice_number",
        width: "20%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "invoice_number",
        sortField: "invoice_number",
        searchlightToken: "cont_any",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        width: "17.5%",
        showFilter: true,
        popupPlaceholder: "ex. John Doe",
        showSort: true,
        searchlightField: "customer_name",
        sortField: "customer_name",
        searchlightToken: "cont_any",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Status",
        accessor: "status",
        width: "15%",
        showFilter: false,
        popupPlaceholder: "ex. Open, Closed, Past Due",
        showSort: false,
        searchlightField: "days_past_due",
        sortField: " days_past_due",
        searchlightToken: "gt",
        upperBoundToken: "lteq",
        dropdownOptions: [
          { label: "OPEN", value: InvoiceStatusType["OPEN"], id: "open" },
          { label: "PAST DUE 1-30", value: "0", id: "late1" },
          { label: "PAST DUE 31-60", value: InvoiceStatusType["PAST DUE 31-60"], id: "late31" },
          { label: "PAST DUE 61-90", value: InvoiceStatusType["PAST DUE 61-90"], id: "late61" },
          { label: "PAST DUE 91+", value: InvoiceStatusType["PAST DUE 91+"], id: "late91" },
          { label: "CLOSED", value: InvoiceStatusType["CLOSED"], id: "closed" },
        ],
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null; row: { original: { days_late: string | null } } }) =>
          TableUtils.formatStatus(props.value, parseInt(props.row.original.days_late || "0", 10), false),
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        width: "12.5%",
        showFilter: true,
        popupPlaceholder: "ex. yyyy-mm-dd",
        showSort: true,
        searchlightField: "payment_due_date",
        sortField: "payment_due_date",
        searchlightToken: "eq",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, true),
      },
      {
        Header: "Invoice Amount",
        accessor: "invoice_amount",
        width: "17.5%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "invoice_amount",
        sortField: "invoice_amount",
        searchlightToken: "lteq",
        alignment: "right",
        filterAnchorPosition: "center",
        Cell: (props: { value: number | null }) =>
          TableUtils.formatCurrency(props.value, false, userStatus?.currency?.locale, userStatus?.currency?.code),
      },
      {
        Header: "Last Activity",
        accessor: "last_activity",
        width: "17.5%",
        showFilter: false,
        popupPlaceholder: "ex. yyyy-mm-dd",
        showSort: false,
        searchlightField: "last_activity_date",
        sortField: "last_activity_date",
        searchlightToken: "eq",
        alignment: "left",
        filterAnchorPosition: "left",
        Cell: (props: { value: string | null }) => TableUtils.formatLastActivity(props.value, false),
      },
      {
        Header: "",
        accessor: "actionHeader",
        width: "4.5%",
        showFilter: false,
        showSort: false,
        alignment: "right",
        Cell: (props: { value: string | null; row: { original: { status: string } } }) => {
          return TableUtils.formatAction(
            props.row.original,
            [
              {
                value: "New Email",
                id: "email",
                handler: "action.newEmail",
              },
              {
                value: "New Note",
                id: "note",
                handler: "action.newNote",
              },
              {
                value: "New Phone Call",
                id: "phone_call",
                handler: "action.newPhoneCall",
              },
            ].map((action: EllipsisAction) => {
              return { ...action, clickFunction: ellipsisActionHandlers[action.handler] };
            })
          );
        },
      },
    ],
    []
  );

  const getFiltersForInvoices = (exportInvoice = false, ids?: string[]) => {
    const additionalFilters: Array<Record<string, string>> = [
      {
        searchlightFilter: `qa[invoice_type_code_eq]= AR Invoice`,
      },
      {
        searchlightFilter: `qa[invoice_closed_date_lteq]=${DateTime.fromFormat(reportDate, "yyyy-MM-dd").toFormat("yyyy-MM-dd")}`,
      },
      {
        searchlightFilter: `qa[invoice_closed_date_gteq]=${DateTime.fromFormat(reportDate, "yyyy-MM-dd")
          .minus({ months: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE })
          .toFormat("yyyy-MM-dd")}`,
      },
    ];

    // evaluate select filter on export
    if (exportInvoice && !allRowsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      ids.forEach((id: string) => {
        additionalFilters.push({
          searchlightFilter: `qa[invoice_id_in][]=${id}`,
        });
      });
    }
    const filterQuery = TableUtils.getFilterFromURL(params, INVOICESCOLUMNS);

    return TableUtils.columnFilterParser(filterQuery, true, additionalFilters ? [...additionalFilters] : undefined);
  };

  const fetchInvoices = async () => {
    const defaultSort = `payment_due_date+DESC%2C+invoice_number+DESC`;
    let sortQuery = TableUtils.getSortFromURL(params);
    if (!sortQuery) {
      sortQuery = defaultSort;
    }
    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    const fetchParams = { pageSize, page: pageNumber, order: TableUtils.sortQueryParser(sortQuery ?? "", true), filter: getFiltersForInvoices() };
    setPlatformPageFetchParams(fetchParams as PlatformPageFetchParams);
    return invoicesClientV2
      .getInvoices(pageSize, pageNumber, TableUtils.sortQueryParser(sortQuery ?? "", true), getFiltersForInvoices())
      .then((fetchResult: any) => fetchResult);
  };

  const invoiceFetchParser = (fetchResult: InvoiceModelFetchResultV2, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: Partial<InvoiceModelV2>) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.invoice_id,
          }),
          ...((variant === "export" || variant === "all") && {
            invoice_number: record?.invoice_number,
            customer_name: record?.customer_name || "N/A",
            status: record?.status,
            due_date: record?.payment_due_date,
            invoice_amount: record?.invoice_amount,
            last_activity: record?.last_activity_date,
            payment_due_date: record?.payment_due_date,
            outstanding_balance: record?.outstanding_balance,
            customer_id: record?.customer_id,
            invoice_date: record?.invoice_date,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleInvoiceRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(
      `${path.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/dashboard/receivables_summary"))}/invoices/${row.original.id ?? ""}`
    );
  }

  React.useEffect(() => {
    fetchCompanyInfo(userStatus.account_company_id as string);
  }, []);

  const filteredContacts = [...companyContactOptions, ...allContactOptions];
  const primaryContact = filterPrimaryContacts(companyContactOptions);

  return (
    <>
      <Table
        dataSets={[
          {
            id: "By Customers",
            displayName: "By Customers",
            rowSelectToken: "companyId",
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: getSummaryApi,
              fetchParser: customerFetchParser,
            },
            columns: CUSTOMERSCOLUMNS,
            export: {
              exportFileName: "Receivables Summary Drilldown - Invoiced - By Customers",
            },
            defaultSort: "OUTSTANDINGAMOUNT",
            defaultSortToken: "DESC",
            handleRowClick: handleCustomerRowClick,
          },
          {
            id: "By Invoices",
            displayName: "By Invoices",
            rowSelectToken: "invoiceId",
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: fetchInvoices,
              fetchParser: invoiceFetchParser,
            },
            columns: INVOICESCOLUMNS,
            export: {
              exportFileName: "Receivables Summary Drilldown - Invoiced - By Invoices",
            },
            defaultSort: "payment_due_date",
            defaultSortToken: "DESC",
            handleRowClick: handleInvoiceRowClick,
          },
        ]}
        states={{
          isLoading: isLoading,
          setLoading: setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: totalCount,
          setTotalCount: setTotalCount,
          setPageSize: setPageSize,
          startingIndex: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
        }}
        toggles={{
          showRowSelect: true,
          showExportBtn: true,
          showCaption: true,
          showNavigation: true,
          showSearchbar: false,
        }}
        isColumnFixed={true}
      />

      {showActivity && (
        <NewActivityPopup
          key={`key-add-activity-${newActivityType}`}
          title={"New Activity"}
          open={showActivity}
          contactOptions={filteredContacts}
          defaultTo={_.isEmpty(primaryContact) ? [companyContactOptions[0]] : primaryContact}
          handleSend={handleSend}
          isTemplateSupport={tableDataSetIndex === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE.toString()}
          supportedTemplateList={supportedTemplateList}
          defaultTitle={defaultTitle}
          editorState={editorState}
          handleSendMarkClosed={handleSendAndMarkClosed}
          onClose={() => {
            setShowActivity(false);
            setEditorState("");
          }}
          activityType={newActivityType}
          setNewActivityType={(type) => {
            setNewActivityType(type);
          }}
          addActivityDropdown={[
            { displayName: "Email", icon: <Email /> },
            { displayName: "Note", icon: <NoteFill /> },
            { displayName: "Phone Call", icon: <Phone /> },
          ]}
          setDefaultTitle={setDefaultTitle}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
        />
      )}
    </>
  );
}
