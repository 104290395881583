import { AxiosError } from "axios";
import React, { MouseEvent, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { TemplateDefinitionProps } from "../../../app/Templates/TemplateFactory";
import { TemplateTypes, viewType } from "../../../app/Templates/TemplateTypes";
import { CONNECTION_STATUS } from "../../../constants/ConnectionConstants";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../constants/NumericConstants";
import { ActivityContext } from "../../../contexts/ActivityContext";
import { AlertContext } from "../../../contexts/AlertContext";
import { AppContext } from "../../../contexts/AppContext";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import { PaymentContext } from "../../../contexts/PaymentContext";
import { TemplateContext } from "../../../contexts/TemplateContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { companiesClient } from "../../../db/accessor";
import { formatDate } from "../../../db/utils/date";
import { automationClientV2, emailsClientV2, PaymentsClientV2 } from "../../../db/version2Accessor";
import { EmailAction, FallbackTypes } from "../../../types/enums";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import NewActivityPopup from "../../library/AddNewActivityDropdown/NewActivityPopup";
import { Email, NoteFill, Phone } from "../../library/Icons/Icons";
import { SummaryHeader } from "../../library/SummaryHeader";
import { SummaryItem } from "../../library/SummaryHeader/SummaryHeaderInterfaces/SummaryHeaderInterface";
import Table from "../../library/Table/Table";
import TrackingUtils from "../../Tracking/Tracking.Utils";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import "../Payments.scss";
import Utils from "../../../utils/utils";
import _ from "underscore";
import { CustomerContext } from "../../../contexts/CustomerContext";

type PaymentsProps = {
  configs: any;
};

export default function AllPayments(props: PaymentsProps): React.ReactElement {
  const { path } = useRouteMatch();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const tableConfigs = props.configs.view.all.table;
  const summaryConfigs = props.configs.view.all.summary;
  const pageNoFromUrl = params.get("page");
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "1");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
    } else {
      return pageNum;
    }
  });
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION_VALUES.PAGE_SIZE);
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [editorState, setEditorState] = useState<string>("");
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { handleClose } = React.useContext(ActivityContext) as ActivityType;
  const { signature } = React.useContext(CustomerContext) as CustomerType;
  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const [paymentId, setPaymentId] = useState<string>("");
  const [customerId, setCustomerId] = useState<string>("");
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [newActivityType, setNewActivityType] = useState<string | null>(null);
  const { templateFactory, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const [item, setItem] = useState<any>();
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { userStatus, getContactsOptions, allContactOptions, filterPrimaryContacts } = React.useContext(AppContext) as AppType;
  const { getDetailViewInvoices } = React.useContext(PaymentContext) as PaymentType;
  const [templateName, setTemplateName] = useState<string>("");
  const [summaryItems, setSummaryItems] = useState<SummaryItem[]>([]);
  const [summaryLoader, setSummaryLoading] = useState<boolean>(false);
  const { updateBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setPlatformPageFetchParams,
    setDocumentType,
    setEnableDocumentSwitch,
    setRootSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;

  const fetchCompanyInfo = (companyId: UUID) => {
    if (!companyId) {
      return;
    }
    companiesClient.getCompany(companyId, "Classification").then((companyModel: CompanyModel) => {
      setCompanyData(companyModel);
    });
  };

  const fetchCompanyContactOptions = async (customerId: string) => {
    setCustomerId(customerId);
    await setCompanyContactOptions(await getContactsOptions(customerId));
  };

  // fetch call
  const fetchParser = (fetchResult: FetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: PaymentSummaryModelV2) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.payment_id,
          }),
          ...((variant === "export" || variant === "all") && {
            payment_number: record.reference_code,
            customer_name: record.payment_company_name,
            type: record.tender_type,
            payment_date: record.payment_date,
            payment_amount: record.payment_amount,
            invoices: record.invoice_list,
            customerId: record.payment_company_id,
            totalPaymentAmount: record.total_payments_applied,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const filterQuery = TableUtils.getFilterFromURL(params, tableConfigs.columns);

  const fetchPayments = () => {
    const defaultSort = `${props.configs.defaultSortKey} ${props.configs.defaultSortToken}`;
    let sortQuery = TableUtils.getSortFromURL(params);
    if (!sortQuery) {
      sortQuery = defaultSort;
    }
    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    const fetchParams = {
      pageSize,
      page: pageNumber,
      order: TableUtils.sortQueryParser(sortQuery ?? "", true),
      filter: TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters),
      spamFraudArchivedTab: false,
    };
    setPlatformPageFetchParams(fetchParams as PlatformPageFetchParams);
    return PaymentsClientV2.querySummaries(
      TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters),
      TableUtils.sortQueryParser(sortQuery ?? "", true),
      pageSize,
      pageNumber,
      false
    ).then((fetchResult: any) => {
      return fetchResult;
    });
  };

  const fetchPaymentSummary = async () => {
    setSummaryLoading(true);
    try {
      const result = await PaymentsClientV2.getPaymentsSummary(TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters));
      const data = result.data as AllPaymentsSummary;
      setSummaryItems(
        summaryConfigs.items.map((item: SummaryItemMeta) => ({
          ...item,
          value: data[item.accessor as keyof typeof data] ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
        }))
      );
    } catch (error) {
      console.error("error: ", error);
      setSummaryItems([]);
    } finally {
      setSummaryLoading(false);
    }
  };

  /**
   * @function getCompanyIdForFirstTo
   * A helper function to get the company id for the first contact selected
   * @param to - Contacts
   * @returns
   */
  const getCompanyIdForFirstTo = (to: To[]) => {
    if (to && to.length) {
      return to[0].companyId;
    }
    return "";
  };

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "Messages Sent" };
    const activityTransactions = _.uniq([...inboxAttachments, { transaction_id: paymentId, transaction_type: "payment" }], "transaction_id");
    const payload: Partial<ActivityItemEmailModel> = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      contact_company_id: getCompanyIdForFirstTo(to),
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      content_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "input_email"
          : newActivityType && newActivityType === "email"
          ? "input_email"
          : newActivityType,
      activity_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "email"
          : newActivityType,
      activity_transactions: activityTransactions,
      primary_connection_id: customerId,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, activityTransactions),
    };
    return new Promise((resolve) => {
      emailsClientV2
        .post(payload)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;
            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(to, "Ellipsis", "New", isSendAndClose ?? false, allContactOptions, "Payments", path, newActivityType ?? "");
          setShowActivity(false);
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: "Activity Closed" };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: "Activity was not closed",
          };
        }
        setToastOptions(toastOptions);
      }
    }
  };

  // Handles redirect to Payment Details page on row click of the Data Grid
  const handleRowClick = (
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) => {
    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     * @param selectedActivityStreamId - currently selected document id
     * @param activityStreamIds - list of documents in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    setSelectedActivityStreamId({ id: row.original.id } as ActivityStreamId);
    setActivityStreamIds(tableData.map((item) => ({ id: item.id })));
    setCurrentPaginationState({ page: pageNumber, pageCount, pageSize, totalRecords: totalCount });
    setDocumentType("payment");
    setRootSwitcherStateSnapshot();
    sessionStorage.setItem("lastPath", history.location.pathname);
    sessionStorage.setItem("lastPage", pageNumber.toString());
    history.push(`${workspaceHomePath}/payments/${row.original.id ?? ""}`);
    updateBaseRoute(`${workspaceHomePath}/payments/${row.original.id ?? ""}`);
  };

  useEffect(() => {
    fetchCompanyInfo(userStatus.account_company_id as string);
    if (summaryConfigs) {
      fetchPaymentSummary();
    }
  }, []);

  /**
   * Function is used to prepare the invoice data which need's to show in templates.
   *
   * @param {string} workspaceType The workspaceType indicate which works space is currently selected.
   * @param {TemplateTypes} templateID The templateID indicate which template is selected by user from subject dropdown.
   * @param {PaymentDetailViewResponse[]} invoices the invoice
   * @returns {}
   */
  const prepareInvoiceDataForTemplate = (workspaceType: string | undefined | null, invoices: PaymentDetailViewResponse[]): Record<string, any> => {
    let invoiceInfo: Record<string, any> = [];
    const isAccountReceivable = workspaceType === "accounts_receivable";
    if (invoices) {
      invoiceInfo = invoices.map((item, index) => {
        const props = {
          title: `Invoice- ${++index}`,
          items: [
            {
              key: isAccountReceivable ? "Invoice number" : "Bill number",
              value: item.invoice.referenceCode,
            },
            {
              key: "Due date",
              value: formatDate(item.invoice.paymentDueDate),
            },
            {
              key: isAccountReceivable ? "Amount paid" : "Paid amount",
              value: Utils.formatValueAsCurrency(
                item.paymentAppliedAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
                userStatus?.currency?.locale,
                userStatus?.currency?.code
              ),
            },
            isAccountReceivable
              ? {
                  key: "Invoice status",
                  value: item.invoice.invoiceStatusCode,
                }
              : "",
          ],
        };

        return props;
      });
    }
    return invoiceInfo;
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = async (templateID: string | null, setEditorState: React.Dispatch<React.SetStateAction<string>>) => {
    if (templateID) {
      setTemplateName(templateID);
      const workspaceType = selectedWorkspace.workspace_type;
      let invoiceInfo: Record<string, any> = [];
      if (templateID !== TemplateTypes.PAYMENT_CONFIRMATION_REQUEST) {
        const invoices = await getDetailViewInvoices(`PaymentId eq ${item.id}`, "invoice");
        invoiceInfo = prepareInvoiceDataForTemplate(workspaceType, invoices);
      }
      const templateObj = templateData.get(selectedWorkspace?.id).get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: item?.customer_name || "",
          paymentNumber: item?.payment_number || "",
          paymentDate: formatDate(item.payment_date) || "",
          paymentDueDate: formatDate(item.paymentDueDate) || "",
          paymentAmount: Utils.formatValueAsCurrency(
            item.payment_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          totalPaymentsApplied: Utils.formatValueAsCurrency(
            item.total_payments_applied ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          invoices: invoiceInfo,
          signature: signature.email_signature || "",
          // TODO come up with a more user friendly key names for the invoices list - this will worked on later
        })
      );
    }
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (row: any): TemplateDefinitionProps[] => {
    const view: viewType =
      selectedWorkspace.workspace_type === "accounts_payable" ? viewType.AP_TRANSACTION_PAYMENTS : viewType.AR_TRANSACTION_PAYMENTS;

    return templateFactory.getTemplateDefinitionsByView(view, {
      paymentNumber: row.payment_number,
      customer: row.customer_name,
      //Passing first invoice number from the list of invoices assuming its the latest
      invoiceNumber: row.invoices ? row?.invoices[0] : "",
    });
  };

  /**
   * Event handler which triggered when user select the 'email', 'note' or 'phone call'
   * context menu item. It will open the new activity dialog based to the context menu item
   * selected by the user.
   *
   * @param {Object} row The selected row from the table.
   * @param {String} buttonId The buttonId indicate which button has been selected by the user.
   */
  const onClickActionButton = async (row: any, buttonId: string) => {
    if (buttonId === "email") {
      setSupportedTemplateList(getTemplateDefinitionsByView(row));
    }

    setItem(row);
    await fetchCompanyContactOptions(row.customerId);
    setPaymentId(row.id);
    setNewActivityType(buttonId);
    setShowActivity(true);
  };

  // table configuration
  const ellipsisActionHandlers: Partial<RowSelectButtons> = {
    "action.newEmail": onClickActionButton,
    "action.newNote": onClickActionButton,
    "action.newPhoneCall": onClickActionButton,
  };

  const ellipsisActions: RowSelectButtons = tableConfigs.ellipsisActions.map((action: EllipsisAction) => {
    return { ...action, clickFunction: ellipsisActionHandlers[action.handler] };
  });

  const cellRendererMapping: CellRendererMappingObject = {
    "table.formatString": (props: { value: string | null }) => TableUtils.formatString(props.value, false, "contact-bold"),
    "table.formatDate": (props: { value: string | null }) => TableUtils.formatDate(props.value, false, "contact-bold"),
    "table.formatFunction": (props: { value: string | null; row: { original: { status: string } } }) => {
      return TableUtils.formatAction(props.row.original, ellipsisActions);
    },
    "table.formatPaymentType": (props: { value: string | null }) => TableUtils.formatPaymentType(props.value, false),
    "table.formatCurrency": (props: { value: number | null }) =>
      TableUtils.formatCurrency(props.value, false, userStatus?.currency?.locale, userStatus?.currency?.code),
    "table.formatList": (props: { value: string[] | null; row: { original: { id: string } } }) =>
      TableUtils.formatList(props.value, false, props.row.original.id),
  };

  const filteredContacts = [...companyContactOptions, ...allContactOptions];
  const primaryContact = filterPrimaryContacts(companyContactOptions);

  return (
    <div className="table-wrapper">
      {summaryConfigs && (
        <SummaryHeader items={summaryItems} isLoading={summaryLoader} itemCount={summaryConfigs.items.length} currency={userStatus?.currency} />
      )}
      <div className="body-table">
        <Table
          key="All Payments"
          dataSets={[
            {
              id: "Payments",
              displayName: "Payments",
              rowSelectToken: "paymentId",
              columns: tableConfigs.columns.map((column: ColumnHeaderType) => {
                return { ...column, Cell: cellRendererMapping[column.CellRenderer] };
              }),
              data: {
                tableData: tableData,
                setTableData: setTableData,
                fetchCall: fetchPayments,
                fetchParser: fetchParser,
              },
              export: {
                exportFileName: "Payments",
              },
              handleRowClick: handleRowClick,
            },
          ]}
          states={{
            isLoading: isLoading,
            setLoading: setLoading,
            isError: isError,
            setError: setError,
            errorMessage: errorMessage,
            setErrorMessage: setErrorMessage,
          }}
          pagination={{
            pageCount: pageCount,
            setPageCount: setPageCount,
            pageNumber: pageNumber,
            setPageNumber: setPageNumber,
            totalCount: totalCount,
            setTotalCount: setTotalCount,
            setPageSize: setPageSize,
            startingIndex: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
          }}
          toggles={{
            showSearchbar: false,
            showExportBtn: true,
            showCaption: true,
            showRowSelect: true,
            showNavigation: true,
            showSelectAllBar: true,
          }}
          isColumnFixed={true}
        />
      </div>
      {showActivity && (
        <NewActivityPopup
          key={`key-add-activity-${newActivityType}`}
          title={"New Activity"}
          open={showActivity}
          contactOptions={filteredContacts}
          defaultTo={_.isEmpty(primaryContact) ? [companyContactOptions[0]] : primaryContact}
          handleSend={handleSend}
          handleSendMarkClosed={handleSendAndMarkClosed}
          isTemplateSupport={newActivityType === "email"}
          supportedTemplateList={supportedTemplateList}
          onClose={() => {
            setShowActivity(false);
            setEditorState("");
          }}
          editorState={editorState}
          activityType={newActivityType}
          setNewActivityType={(type) => {
            setNewActivityType(type);
          }}
          addActivityDropdown={[
            { displayName: "Email", icon: <Email /> },
            { displayName: "Note", icon: <NoteFill /> },
            { displayName: "Phone Call", icon: <Phone /> },
          ]}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
        />
      )}
    </div>
  );
}
