import React from "react";
import "./ContentNavLink.scss";

// Define Content Nav Link's Props
interface Props {
  className?: string;
  isActive?: boolean;
  disabled?: boolean;
  children?: React.ReactElement;
  noMargin?: boolean;
  count?: number;
  currentStream?: string;
  tab?: string;
}

// * Wrapper for the contents of a Nav Link in order to customize active state
export default function ContentNavLink(props: Props): React.ReactElement {
  return (
    <div
      style={{ margin: props.noMargin ? "0" : "0 0 0 1rem" }}
      className={`content-nav ${props.isActive ? "content-nav-active" : ""} ${props.className ?? ""}`}
    >
      <p className={`content-nav-text`}>
        {props.children} {props.count !== null && props.currentStream !== "spam" && props.tab === "Active" ? `(${props.count})` : ""}
      </p>
    </div>
  );
}
