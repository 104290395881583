import React from "react";
import { CarouselContainerInterface } from "./CarouselInterfaces/CarouselInterfaces";
import CarouselComponent from "./CarouselComponent";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_TIMEOUTS, NUMERIC_VALUES } from "../../../constants/NumericConstants";

const CarouselContainer: React.FC<CarouselContainerInterface> = (props: CarouselContainerInterface) => {
  const [totalNodes, setTotalNodes] = React.useState(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [activeIndex, setActiveIndex] = React.useState(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  const calculateNext = (index: number) => {
    return index + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE === totalNodes
      ? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
      : index + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
  };

  const handleNext = () => {
    setActiveIndex(calculateNext(activeIndex));
  };

  const calculatePrev = (index: number) => {
    return index - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE === DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
      ? totalNodes - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
      : index - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
  };

  const handlePrev = () => {
    setActiveIndex(calculatePrev(activeIndex));
  };

  React.useEffect(() => {
    if (props.children && props.children instanceof Array) {
      setTotalNodes(props.children.flat(NUMERIC_VALUES.CONSTANT_TWO).length);
    } else {
      if (["string", "object"].includes(typeof props.children)) {
        setTotalNodes(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
      }
    }
    setActiveIndex(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  }, [props.children]);

  React.useEffect(() => {
    let interval: ReturnType<typeof setTimeout> | null = null;
    if (props?.autoScroll && totalNodes > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      let currentActiveIndex = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
      interval = setInterval(() => {
        setActiveIndex(currentActiveIndex);
        currentActiveIndex = calculateNext(currentActiveIndex);
      }, props?.timeout ?? NUMERIC_TIMEOUTS.FIVE_SECOND);
    }

    return () => {
      clearInterval(interval as ReturnType<typeof setTimeout>);
    };
  }, [totalNodes]);

  return (
    <CarouselComponent
      autoScroll={props?.autoScroll ?? false}
      currentActiveIndex={activeIndex}
      dimension={props.dimension}
      totalChildNodes={totalNodes}
      navigatorControls={{ handleNext, handlePrev, hideControls: props.hideControls, position: "top-end" }}
    >
      {props.children}
    </CarouselComponent>
  );
};

export default CarouselContainer;
