import React, { useMemo } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Navbar from "../../../library/Navbar/Navbar";
import ReceivablesRiskTable from "./ReceivablesRiskTable/ReceivablesRiskTable";
import { DateTime } from "luxon";
import "../Drilldowns.scss";
import ARHeader from "../../../library/ARHeader/ARHeader";
import FourOFourError from "../../../../routes/FourOFourError/FourOFourError";
import { DEFAULT_NUMERIC_VALUES, TRIM_COUNT_CONSTANTS } from "../../../../constants/NumericConstants";
import { DRILLDOWN_NAVIGATION_CONSTANTS } from "../../../../constants/NavigationConstants";

const routes = Array.from(Array(TRIM_COUNT_CONSTANTS.RR_ROUTE).keys()).map((index: number) => {
  const lastOfMonth = DateTime.now().minus({ months: index }).endOf("month");
  return {
    displayName: `${lastOfMonth.toFormat("MMM")} ${lastOfMonth.toFormat("yy")}`,
    routeName:
      index === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
        ? ""
        : `${lastOfMonth.toFormat("MMM")}_${lastOfMonth.toFormat("yy")}?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.RECEIVABLES_RISK.SORT}`,
  };
});

export default function ReceivablesRisk(): React.ReactElement {
  const { path } = useRouteMatch();
  const reportDate = useMemo(() => {
    const childPath = location.pathname.slice(location.pathname.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
    let reportDate = DateTime.now().toFormat("yyyy-MM-dd");
    if (childPath !== "receivables_risk") {
      const dateArr = childPath.split("_");
      reportDate = DateTime.fromFormat(`${dateArr[1]} ${dateArr[0]} 01`, "yy MMM dd").endOf("month").toFormat("yyyy-MM-dd");
    }
    return reportDate;
  }, [location.pathname]);

  return (
    <div className="drilldown-wrapper">
      <div className="head">
        <Navbar
          tabs={routes}
          rootPath={path}
          dropdownRootPath={path.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/"))}
          dropdown={[
            { displayName: "Current AR Aging", routeName: `aging?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.AGING.SORT}` },
            { displayName: "DSO", routeName: `days_sales_outstanding?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.DAYS_SALES_OUTSTANDING.SORT}` },
            { displayName: "Receivables Risk", routeName: `receivables_risk?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.RECEIVABLES_RISK.SORT}` },
            { displayName: "Receivables Summary", routeName: `receivables_summary?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.RECEIVABLES_SUMMARY.SORT}` },
            { displayName: "Top Customers", routeName: "top_customers?sort=AMOUNTPASTDUE+DESC%2C+TOTALINVOICESPASTDUE+DESC" },
          ]}
          dropdownDefault={"Receivables Risk"}
          backDisplayName="Dashboard"
          backRoute={`${path.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/"))}`}
          tooltip="The percentage of total AR balance left unpaid after 90 days."
          handleBackRoute
        />
      </div>
      <div className="body">
        <div className="content">
          <ARHeader
            list={[
              { type: "totalCustomers" },
              { type: "totalInvoices90DaysPastDue" },
              { type: "totalPastDueAmount90Days" },
              { type: "percentageOfTotalAr90DaysPastDue" },
            ]}
            reportDate={reportDate}
          />
          <div className="content-table">
            <Switch>
              <Route
                exact
                path={routes.map((route: { displayName: string; routeName: string }) => {
                  return `${path}/${route.routeName.replace(/\?.+$/, "")}`;
                })}
                component={() => {
                  return <ReceivablesRiskTable />;
                }}
              />
              <Route
                component={() => {
                  return <FourOFourError />;
                }}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
