import React from "react";
import { AuditLogType } from "../../../../../../../types/enums";
import Button from "../../../../../../library/Button/Button";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { SnoozedProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";
import { DateTime } from "luxon";

const Snoozed: React.FC<SnoozedProps> = ({ onClickUnsnooze, timestampUntilSnoozed, creator, status, readonly }) => {
  const formatDate = (value: string) => {
    const date = DateTime.fromFormat(value.split("T")[0], "yyyy-MM-dd").toFormat("MMM dd, yyyy");
    const time = DateTime.fromFormat(value.split("T")[1], "hh:mm:ss").toFormat("hh:mm a");
    return `${date}, ${time}`;
  };
  // write conversion for time stamp -> MMM dd, yyyy, hh:mm
  creator = { ...creator, message: `Snoozed until ${timestampUntilSnoozed ? formatDate(timestampUntilSnoozed) : "N/A"}` };

  return status === AuditLogType.Snoozed && !readonly ? (
    <SecondaryActivity variant={SecondaryType.Accordion} creator={creator}>
      <Button variant="primary" size="sm" onClick={onClickUnsnooze}>
        {Labels.BTN_UNSNOOZE}
      </Button>
    </SecondaryActivity>
  ) : (
    <SecondaryActivity variant={SecondaryType.Base} creator={creator} />
  );
};

export default Snoozed;
