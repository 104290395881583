import React from "react";
import { Pen } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { ApprovalReviewedProps as ApprovalAccessRevokedProps } from "../ActivityItemVariantTypes";

const AccessRevoked: React.FC<ApprovalAccessRevokedProps> = (props) => {
  return <PrimaryActivity {...{ ...props, icon: <Pen />, FLAGS: { REPLY_OPTIONS: true, TIME_SAVING_INDICATOR: true } }} />;
};

export default AccessRevoked;
