import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";
import "./ActivityTemplateSelect.scss";
import { AutocompleteRenderInputParams } from "@mui/material";
import { TemplateTypes } from "../../../../app/Templates/TemplateTypes";
import DOMPurify from "dompurify";

export type TemplateType = {
  displayText: string;
  id: string;
  label: string;
};

interface ActivityTemplateSelectProps {
  templateList: TemplateType[];
  defaultTemplateType?: string;
  onSelectTemplate?: (value: string) => void;
  setSelectedTemplateID?: React.Dispatch<React.SetStateAction<string | null>>;
  defaultTemplateId?: TemplateTypes | string | null;
  dirtyText?: boolean;
  setOpenWarningModal?: Dispatch<SetStateAction<boolean>>;
}

const ActivityTemplateSelect = (props: ActivityTemplateSelectProps): React.ReactElement => {
  const [displayText, setDisplayText] = useState<string>(props?.defaultTemplateType ?? "");

  /**
   * Event handler called after the input field has been changed.
   * It will update the display text and select the template id.
   * @param event
   * @param value
   */
  const onChangeHandler = (event: React.SyntheticEvent<Element, Event>, value: TemplateType) => {
    if (props.dirtyText && props.setOpenWarningModal) {
      props.setOpenWarningModal(true);
    } else {
      setDisplayText(value ? value.displayText : "");
      props.setSelectedTemplateID?.(value ? value.id : null);
    }
  };

  useEffect(() => {
    props.onSelectTemplate?.(displayText);
  }, [displayText]);

  useEffect(() => {
    props.setSelectedTemplateID?.(props.defaultTemplateId ?? null);
    setDisplayText(props.templateList.filter((template: TemplateType) => template.id === props.defaultTemplateId)[0]?.displayText ?? "");
  }, [props.defaultTemplateId]);

  return (
    <Autocomplete
      id="activity-template-selector"
      freeSolo
      style={{ border: "none" }}
      className="activity-template-selector-autocomplete"
      onChange={onChangeHandler}
      onInputChange={(event: React.SyntheticEvent<Element, Event>, value: string) => setDisplayText(value)}
      value={DOMPurify.sanitize(displayText)}
      options={props.templateList}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
          placeholder="Subject"
        />
      )}
    />
  );
};

export default ActivityTemplateSelect;
