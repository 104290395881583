/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, MouseEvent, useEffect, useRef } from "react";
import TableForActivityStreams, { RowSelectButtons, HeaderButton } from "../../library/Table/TableForActivityStreams";
import { activitiesClientV2, emailsClientV2, companiesClientV2, automationClientV2 } from "../../../db/version2Accessor";
import { Email, MarkAsUnread, AssignTo, Spam, Phone, NoteFill, MoveTo, Snoozed, SnoozedToast, Ellipses } from "../../library/Icons/Icons";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import ActivityMove from "../ActivityDetail/ActivityFeed/ActivityFeedActions/ActivityMove";
import ActivityReassign from "../ActivityDetail/ActivityFeed/ActivityFeedActions/ActivityReassign";
import { AlertContext } from "../../../contexts/AlertContext";
import { AxiosError } from "axios";
import {
  ActivityActionTypes,
  ActivityStatus,
  ConnectionStatus,
  FallbackTypes,
  WorkspaceType,
  EmailAction,
  AttachmentType,
} from "../../../types/enums";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../constants/NumericConstants";
import { CONNECTION_STATUS } from "../../../constants/ConnectionConstants";
import { SNOOZE_LIST, SENDER_SPAM_FRAUD } from "../../../constants/ListConstants";
import { ActivityActionPerformStatus, ActivityContext } from "../../../contexts/ActivityContext";
import NewActivityDropdown from "../../library/AddNewActivityDropdown/AddNewActivityDropdown";
import MenuItem from "../../library/MenuItem/MenuItem";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SpamFraudModal from "../SpamFraudModal/SpamFraudModal";
import { ComponentConfigContext } from "../../../contexts/ComponentConfigContext";
import { TemplateContext } from "../../../contexts/TemplateContext";
import { TemplateDefinitionProps } from "../../../app/Templates/TemplateFactory";
import { AppContext } from "../../../contexts/AppContext";
import NewActivityUtils from "../../../utils/NewActivityUtils/NewActivityUtils";
import { viewType } from "../../../app/Templates/TemplateTypes";
import NewActivityPopup from "../../library/AddNewActivityDropdown/NewActivityPopup";
import { Row } from "react-table";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import TrackingUtils from "../../Tracking/Tracking.Utils";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import { CustomerContext } from "../../../contexts/CustomerContext";
import _ from "underscore";
import Utils from "../../../utils/utils";
import { companiesClient } from "../../../db/accessor";

type TableProps = {
  columns: any[];
  predefinedFilter?: { route: string; searchlightFilter: string }[];
  assigneeType?: string;
  route?: string;
  fromCustomers?: boolean;
};

export default function ActivitiesTable(props: TableProps): React.ReactElement {
  const { allContactOptions, getContactsOptions, filterPrimaryContacts } = React.useContext(AppContext) as AppType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const configs = getConfig(selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR) as any;
  const {
    activityPerformInProgress,
    activityActionInProgress,
    activityCloseAction,
    attachSelectedAllPayload,
    attachSelectedAllPayloadForCustomers,
    handleClose,
    activityStatus,
  } = React.useContext(ActivityContext) as ActivityType;
  const [enableTypeAheadSuggestions, setEnableTypeAheadSuggestions] = React.useState(false);
  const params = new URLSearchParams(window.location.search);
  const { path } = useRouteMatch();
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const pageNoFromUrl = params.get("page");
  const tableRef = useRef<TableHandle>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "1");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
    } else {
      return pageNum;
    }
  });
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION_VALUES.PAGE_SIZE);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [newActivity, setNewActivity] = useState<boolean>(false);
  const [activityIds, setActivityIds] = useState<string[]>([]);
  const [isMarkAsUnread, setIsMarkAsUnread] = useState<boolean>(false);
  const [moveDrawerOpen, setMoveDrawerOpen] = useState<boolean>(false);
  const [reassignDrawerOpen, setReassignDrawerOpen] = useState<boolean>(false);
  const [isSnoozeOpen, setIsSnoozeOpen] = useState<boolean>(false);
  const [isSenderOpen, setIsSenderOpen] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [connectionSpam, setConnectionSpam] = useState<boolean>(false);
  const [connectionIds, setConnectionIds] = useState<UUID[]>([]);
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [templateName, setTemplateName] = useState<string>("");
  const [newActivityType, setNewActivityType] = useState<string | null>(null);
  // New activity dropdown states
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const [item, setSelectedItem] = useState<any>();
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { userStatus } = React.useContext(AppContext) as AppType;
  const [editorState, setEditorState] = useState<string>("");
  const newActivityUtils = new NewActivityUtils(selectedWorkspace?.id || FallbackTypes.Id);
  const [isTemplateSupport, setIsTemplateSupport] = useState<boolean>(false);
  const [originalSubjectLine, setOriginalSubjectLine] = useState<string>("");
  const [isNudgeActivity, setIsNudgeActivity] = useState<boolean>(false);
  const [isReply, setIsReply] = useState<boolean>(false);
  const [nudgeTo, setNudgeTo] = useState<To[]>([]);
  const [nudgeCc, setNudgeCc] = useState<To[]>([]);
  const [nudgeBcc, setNudgeBcc] = useState<To[]>([]);
  const [isActivityPopupLoading, setIsActivityPopupLoading] = useState<boolean>(false);
  const [inboxCompany, setInboxCompany] = useState<CompanyModel | null>(null);
  const [trackActivityTransactions, setTrackActivityTransactions] = useState<TransactionItemType[]>([]);
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setDocumentType,
    setEnableDocumentSwitch,
    setRootSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { company, toggleConnectionUpdate, signature } = React.useContext(CustomerContext) as CustomerType;
  const { updateBaseRoute, getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { templateFactory, prepareTemplateDataObject, handleTemplateAttachment, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const useStyles = makeStyles(() => ({
    tooltip: {
      background: "white !important",
      margin: "0 !important",
      padding: "0 !important",
      borderRadius: "0.125rem",
      boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
    },
  }));

  const fetchParser = (fetchResult: ActivityModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.data?.map((record: ActivityStreamItem) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.id,
          }),
          ...((variant === "export" || variant === "all") && {
            primary_connection: record.primary_connection,
            assignee: record.assignee ?? "Unassigned",
            status: record.status,
            last_activity_at: record.last_activity_at,
            subject_and_attachments: {
              subject: record.subject,
              attachments: record.attachments ?? [],
            },
            flag: record.auto_detected_flag,
          }),
          ...(variant === "all" && {
            read: record.read,
          }),
        };
      }) ?? []
    );
  };

  async function handleRowClick(e: MouseEvent<HTMLTableRowElement>, row: { original: { id?: string; read?: boolean } }) {
    let pathToPush!: string;

    if (path.includes("all")) {
      pathToPush = `${workspaceHomePath}/activities/all/${row.original.id}`;
    } else if (path.includes("mine")) {
      pathToPush = `${workspaceHomePath}/activities/mine/${row.original.id}`;
    } else if (path.includes("unassigned")) {
      pathToPush = `${workspaceHomePath}/activities/unassigned/${row.original.id}`;
    } else if (path.includes("spam")) {
      pathToPush = `${workspaceHomePath}/activities/spam/${row.original.id}`;
    } else {
      // coming from customer dashboard
      const route = getBaseRoute();
      pathToPush = `${route}/activities/${row.original.id}`;
    }

    /**
     * Set the following properties into ActivityStreamSwitchContext
     * to facilitate activity stream switching.
     * @param selectedActivityStreamId - currently selected activity stream id
     * @param activityStreamIds - list of activity streams in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    await setSelectedActivityStreamId({ id: row.original.id } as ActivityStreamId);
    await setActivityStreamIds(tableData.map((item) => ({ id: item.id })));
    await setCurrentPaginationState({ page: pageNumber, pageCount, pageSize, totalRecords: totalCount });
    setDocumentType("activity_stream");
    setRootSwitcherStateSnapshot();
    history.push(pathToPush);
    // if coming from customers, no need to update the base route
    if (!path.includes(configs.activities.detail.resources.connections) && !path.includes("connections")) {
      updateBaseRoute(pathToPush);
    }
    if (!row.original.read) {
      try {
        await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, row.original.id ?? "", ActivityActionTypes.Read, {
          read: true,
        });
      } catch (error: unknown) {
        console.log(error);
      }
    }
  }

  const onRowSelect = (val: TableData[]) => {
    setIsMarkAsUnread(!val.some((row: TableData) => !row.read));
  };

  /**
   * @function getCompanyIdForFirstTo
   * A helper function to get the company id for the first contact selected
   * @param to - Contacts
   * @returns
   */
  const getCompanyIdForFirstTo = (to: To[]) => {
    if (to && to.length) {
      return to[0].companyId;
    }
    return "";
  };

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    disableSoftRefresh?: boolean,
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    setLoading(true);
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "Messages Sent" };
    const transactionSelected = _.uniq([...inboxAttachments, ...trackActivityTransactions], "transaction_id");
    const requestBody = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      contact_company_id: getCompanyIdForFirstTo(to),
      content_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "input_email"
          : newActivityType && newActivityType === "email"
          ? "input_email"
          : newActivityType,
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_transactions: transactionSelected.filter(
        (item) => attachmentIds.indexOf(item.transaction_id) !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
      ),
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, transactionSelected),
      activity_type: isNudgeActivity ? EmailAction.NUDGE : newActivityType && newActivityType == "phone_call" ? "call_log" : newActivityType,
      primary_connection_id: customerId ? customerId : "",
    };

    return new Promise((resolve) => {
      emailsClientV2
        .post(requestBody)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;
            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(
            to,
            "New Activity Stream",
            "New",
            isSendAndClose ?? false,
            allContactOptions,
            props.fromCustomers ? "Customers" : props.assigneeType ?? "",
            path,
            newActivityType ?? ""
          );
          setNewActivity(false);
          setShowActivity(false);
          if (!disableSoftRefresh) {
            await tableRef?.current?.softRefresh();
          }
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
          setLoading(false);
        });
    });
  };

  const handleSendReply = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[],
    disableSoftRefresh?: boolean,
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "Messages Sent" };
    setLoading(true);

    const requestBody = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.REPLY,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      contact_company_id: getCompanyIdForFirstTo(to),
      content_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "input_email"
          : newActivityType && newActivityType === "email"
          ? "input_email"
          : newActivityType,
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_transactions: inboxAttachments,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, inboxAttachments),
      activity_type: isNudgeActivity ? EmailAction.NUDGE : newActivityType && newActivityType == "phone_call" ? "call_log" : newActivityType,
      primary_connection_id: customerId ? customerId : "",
    } as ActivityItemEmailModel;

    return new Promise((resolve) => {
      emailsClientV2
        .postActivities(selectedWorkspace?.id || FallbackTypes.Id, item.id, requestBody)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;
            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(
            to,
            "New Activity",
            "Reply",
            isSendAndClose ?? false,
            allContactOptions,
            props.fromCustomers ? "Customers" : props.assigneeType ?? "",
            path,
            newActivityType ?? ""
          );
          setNewActivity(false);
          setShowActivity(false);
          if (!disableSoftRefresh) {
            await tableRef?.current?.softRefresh();
          }
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
          setLoading(false);
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true, true);

    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: "Activity Closed" };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message:
              activityIds?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                ? "Activity was not closed"
                : `${activityIds?.length} Activities were not closed`,
          };
        }
        await tableRef?.current?.softRefresh();
        setToastOptions(toastOptions);
      }
    }
  };

  const handleMarkAsRead = async (ids: string[]) => {
    let toastOptions: ToastOptions = { open: true, severity: "success", message: `Activities ${isMarkAsUnread ? "Unread" : "Read"}` };
    let response = {} as APIResponse;
    try {
      let reqBody: ActivitiesStreamUpdateBody = {
        read: !isMarkAsUnread,
      };
      reqBody = attachSelectedAllPayload(reqBody);
      const isBulk = ids instanceof Array;

      response =
        isBulk && ids?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
          ? await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, ids[0], ActivityActionTypes.Read, reqBody)
          : await activitiesClientV2.patch(
              selectedWorkspace?.id || FallbackTypes.Id,
              "",
              ActivityActionTypes.Read,
              { activity_stream_ids: ids, ...reqBody },
              true
            );
    } catch {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: `${ids?.length} ${ids?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? "Activities were" : "Activity was"} not ${
            isMarkAsUnread ? "unread" : "read"
          }`,
        };
      }
      setToastOptions(toastOptions);
      if (response.success) tableRef?.current?.softRefresh();
    }
  };
  const fectCompanyContacts = async (customerId: string) => {
    setCompanyContactOptions(await getContactsOptions(customerId));
  };

  useEffect(() => {
    newActivityUtils.fetchCompanyInfo(userStatus.account_company_id as string, setToastOptions).then((companyData) => setCompanyData(companyData));
    setTotalCount(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    if (customerId) {
      fectCompanyContacts(customerId);
    }
  }, []);

  const handleSpam = async (ids: string[], spamStatus: boolean) => {
    let toastOptions: ToastOptions = { open: true, severity: "success", message: `Activity is marked as ${spamStatus ? "" : "Not"} Spam` };
    let response = {} as APIResponse;
    try {
      let reqBody: ActivitiesStreamUpdateBody = { spam: spamStatus };
      reqBody = attachSelectedAllPayload(reqBody);

      const isBulk = ids instanceof Array;

      response =
        isBulk && ids.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
          ? await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, ids[0], ActivityActionTypes.Spam, reqBody)
          : await activitiesClientV2.patch(
              selectedWorkspace?.id || FallbackTypes.Id,
              "",
              ActivityActionTypes.Spam,
              { activity_stream_ids: ids, ...reqBody },
              true
            );
    } catch {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: `${ids?.length} ${ids?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? "Activities were" : "Activity was"} not Spam`,
        };
      }
      setToastOptions(toastOptions);
      if (response.success) tableRef?.current?.softRefresh();
    }
  };

  const handleUnSnooze = async (ids: string[]) => {
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "", icon: <SnoozedToast /> };
    let response = {} as APIResponse;
    try {
      let reqBody: ActivitiesStreamUpdateBody = {
        status: ActivityStatus.Active.toLocaleLowerCase() as Status,
      };
      reqBody = attachSelectedAllPayload(reqBody);
      const isBulk = ids instanceof Array;

      response =
        isBulk && ids?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
          ? await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, ids[0], ActivityActionTypes.Status, reqBody)
          : await activitiesClientV2.patch(
              selectedWorkspace?.id || FallbackTypes.Id,
              "",
              ActivityActionTypes.Status,
              { activity_stream_ids: ids, ...reqBody },
              true
            );

      if (response.success) {
        setToastOptions({
          ...toastOptions,
          message: `${ids?.length} Activities Unsnoozed`,
        });
        tableRef?.current?.softRefresh();
      }
    } catch (error) {
      response.success = false;
      setToastOptions({
        ...toastOptions,
        severity: "error",
        message: "Something went wrong!",
      });
      console.log(error);
    }
  };

  const handleTooltipClose = () => {
    setIsSnoozeOpen(false);
  };

  const handleTooltipToggle = (tooltipToggle: boolean) => {
    setIsSnoozeOpen(!tooltipToggle);
  };

  const generateMenuList = (menuList: Array<any>, ids: string[]) => {
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "", icon: <SnoozedToast /> };
    return menuList.map((item, index) => {
      return (
        <MenuItem
          key={index}
          onClick={async (e) => {
            e.stopPropagation();
            let response = {} as APIResponse;
            const now = Math.floor(Date.now() / NUMERIC_VALUES.CONSTANT_THOUSAND);
            try {
              let reqBody: ActivitiesStreamUpdateBody = {
                status: ActivityStatus.Snoozed.toLocaleLowerCase() as Status,
                snooze_until: now + item.valueInEpoch,
              };
              reqBody = attachSelectedAllPayload(reqBody);
              const isBulk = ids instanceof Array;

              response =
                isBulk && ids?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                  ? await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, ids[0], ActivityActionTypes.Status, reqBody)
                  : await activitiesClientV2.patch(
                      selectedWorkspace?.id || FallbackTypes.Id,
                      "",
                      ActivityActionTypes.Status,
                      { activity_stream_ids: ids, ...reqBody },
                      true
                    );

              if (response.success) {
                setToastOptions({
                  ...toastOptions,
                  message: `Activity Snoozed for ${item.id}`,
                });
                tableRef?.current?.softRefresh();
              }
            } catch (error) {
              response.success = false;
              setToastOptions({
                ...toastOptions,
                severity: "error",
                message: "Activity was not Snoozed",
              });
              console.log(error);
            }
            handleTooltipClose();
          }}
          id={item.id}
        >
          {item.value}
        </MenuItem>
      );
    });
  };

  const classes = useStyles();

  const handleSnooze = () => {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={generateMenuList(SNOOZE_LIST, activityIds)}
          classes={{ tooltip: classes.tooltip }}
          placement="bottom-start"
          open={isSnoozeOpen}
          disableHoverListener
        >
          <div onClick={() => handleTooltipToggle(isSnoozeOpen)} className="snooze-icon">
            <Snoozed />
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  };

  const handleSenderClose = () => {
    setIsSenderOpen(false);
  };

  const handleSenderToggle = (tooltipToggle: boolean) => {
    setIsSenderOpen(!tooltipToggle);
  };

  const handleSpamFraud = async () => {
    const toastOptions: ToastOptions = { open: true, severity: "success", message: "" };
    let response = {} as APIResponse;
    try {
      let reqBody: UpdateCustomerStatusPayload = {
        workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
        status: connectionSpam ? ConnectionStatus.SPAM : ConnectionStatus.FRAUD,
        connection_ids: _.uniq(connectionIds ?? []),
      };
      /**
       * Select-all payload is not applicable for customer-dashboard > activitys-streams
       * table because this view only have records from a single customer at any
       * given time.
       * Unlike the Activity Streams views (me/all/unassigned) where any table view
       * contains records from multiple customers, hence makes it necessary to match
       * the list of  selected records on both FE & BE by providing select-all payload
       * as filters.
       */
      reqBody = !props.fromCustomers ? attachSelectedAllPayloadForCustomers(reqBody) : reqBody;
      response = await companiesClientV2.updateCustomer(reqBody);
      if (response.success) {
        setToastOptions({
          ...toastOptions,
          message: `Successfully marked sender as ${connectionSpam ? "spam" : "fraud"}`,
        });
        // refresh sidebar
        toggleConnectionUpdate();
        // refresh table
        tableRef?.current?.softRefresh();
      }
    } catch (error) {
      response.success = false;
      setToastOptions({
        ...toastOptions,
        severity: "error",
        message: response.messages.errors[0],
      });
    }
    handleSenderClose();
    setShowModal(false);
  };

  const openModal = (e: React.SyntheticEvent) => {
    e.currentTarget.textContent === "Mark Sender As Spam" ? setConnectionSpam(true) : setConnectionSpam(false);
    setShowModal(true);
  };

  const generateMenuListForSender = (menuList: Array<any>) => {
    return menuList.map((item, index) => {
      return (
        <MenuItem
          key={index}
          onClick={(e) => {
            openModal(e);
          }}
          id={item.id}
        >
          {item.value}
        </MenuItem>
      );
    });
  };

  const generateSenderSpamFraudList = () => {
    return (
      <ClickAwayListener onClickAway={handleSenderClose}>
        <Tooltip
          title={generateMenuListForSender(SENDER_SPAM_FRAUD)}
          classes={{ tooltip: classes.tooltip }}
          placement="bottom-end"
          open={isSenderOpen}
          disableHoverListener
        >
          <div onClick={() => handleSenderToggle(isSenderOpen)} className="sender-icon">
            <Ellipses />
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  };

  const filterAndSetConnectionID = (ActivityIds: Array<UUID>) => {
    const connections: Array<UUID> = [];
    tableData.forEach((tableItem) => {
      if (ActivityIds.includes(tableItem.id)) {
        connections.push(tableItem.primary_connection.id);
      }
    });
    setConnectionIds(connections);
  };

  const rowSelectButtons: RowSelectButtons[] = [
    ...(activityStatus === "closed" || activityStatus === "snoozed"
      ? []
      : [
          {
            variant: "secondary",
            children: "Close",
            disabled: activityActionInProgress,
            loading: (activityPerformInProgress as ActivityActionPerformStatus).Close,
            callback: (ids: string[]) => {
              setActivityIds(ids);
              activityCloseAction(ids, tableRef?.current?.softRefresh);
            },
          } as RowSelectButtons,
        ]),

    ...(activityStatus === "snoozed"
      ? [
          {
            variant: "secondary",
            children: "Unsnooze",
            disabled: activityActionInProgress,
            loading: (activityPerformInProgress as ActivityActionPerformStatus).Snooze,
            callback: (ids: string[]) => {
              setActivityIds(ids);
              handleUnSnooze(ids);
            },
          } as RowSelectButtons,
        ]
      : []),
    ...[
      "divider",

      {
        icon: <AssignTo />,
        variant: "transparent",
        className: "btn-icon-only",
        alignIcon: "left",
        tooltip: "Assign",
        disabled: activityActionInProgress,
        loading: (activityPerformInProgress as ActivityActionPerformStatus).Assign,
        callback: (ids: string[]) => {
          setActivityIds(ids);
          setReassignDrawerOpen(true);
        },
      },
      {
        icon: <MarkAsUnread />,
        variant: "transparent",
        className: "btn-icon-only",
        alignIcon: "left",
        tooltip: `Mark as ${isMarkAsUnread ? "unread" : "read"}`,
        disabled: activityActionInProgress,
        loading: (activityPerformInProgress as ActivityActionPerformStatus).Read,
        callback: (ids: string[]) => handleMarkAsRead(ids),
      },
      ...(path.includes("/waitingForResponse")
        ? []
        : [
            {
              icon: <MoveTo />,
              variant: "transparent",
              className: "btn-icon-only",
              alignIcon: "left",
              tooltip: "Move",
              disabled: activityActionInProgress,
              loading: (activityPerformInProgress as ActivityActionPerformStatus).Move,
              callback: (ids: string[]) => {
                setActivityIds(ids);
                setMoveDrawerOpen(true);
              },
            },
          ]),
      ...(activityStatus === "snoozed"
        ? []
        : [
            {
              icon: handleSnooze(),
              variant: "transparent",
              className: "btn-icon-only",
              alignIcon: "left",
              tooltip: "Snooze",
              disabled: activityActionInProgress,
              loading: (activityPerformInProgress as ActivityActionPerformStatus).Snooze,
              callback: (ids) => setActivityIds(ids),
            } as RowSelectButtons,
          ]),
      ...(path.includes("/waitingForResponse")
        ? []
        : [
            {
              icon: <Spam />,
              variant: "transparent",
              className: "btn-icon-only",
              alignIcon: "left",
              tooltip: "Spam",
              disabled: activityActionInProgress,
              loading: (activityPerformInProgress as ActivityActionPerformStatus).Spam,
              callback: (ids: string[]) => {
                setActivityIds(ids);
                handleSpam(ids, true);
              },
            },
          ]),
      {
        icon: generateSenderSpamFraudList(),
        variant: "transparent",
        className: "btn-icon-only",
        alignIcon: "left",
        disabled: activityActionInProgress,
        callback: (ids) => filterAndSetConnectionID(ids),
      } as RowSelectButtons,
    ],
  ] as RowSelectButtons[];
  const spamTableButtons: RowSelectButtons[] = [
    {
      variant: "secondary",
      children: "Not Spam",
      disabled: activityActionInProgress,
      loading: (activityPerformInProgress as ActivityActionPerformStatus).Spam,
      callback: (ids: string[]) => handleSpam(ids, false),
    } as RowSelectButtons,
  ];

  const spam = path.toLowerCase().includes("/spam") ? true : false;
  /**
   * Only show contacts relevant to the connection
   */
  let filteredContactOptions = allContactOptions;

  if (customerId) {
    filteredContactOptions = enableTypeAheadSuggestions ? [...companyContactOptions, ...allContactOptions] : companyContactOptions;
  }

  const typeAheadAction = (val: string) => {
    if (val?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setEnableTypeAheadSuggestions(true);
    } else {
      setEnableTypeAheadSuggestions(false);
    }
  };

  /**
   * Function which return the Template instance based on workspace selected.
   *
   * @param {String} Customer The customer name which need to show in mail body.
   * @returns {Object} return the template instance.
   */
  const getTemplateInstance = (customer: string | null) => {
    const view: viewType =
      selectedWorkspace.workspace_type === "accounts_payable" ? viewType.AP_CONNECTION_VENDOR_ACTIVITY : viewType.AR_CONNECTION_CUSTOMER_ACTIVITIES;
    return templateFactory.getTemplateDefinitionsByView(view, { customer });
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (row: Row<TableData>): TemplateDefinitionProps[] => {
    const {
      original: { primary_connection },
    } = row;
    return getTemplateInstance(primary_connection.name);
  };

  useEffect(() => {
    if (newActivityType === "email" && (path.includes("vendors") || path.includes("customers"))) {
      setSupportedTemplateList(getTemplateInstance((company as CustomerDetailsModel).name));
    }
  }, [newActivityType]);

  const getCompanyData = async (): Promise<CompanyModel> => {
    if (!inboxCompany) {
      const companyModel: CompanyModel = await companiesClient.getCompany(userStatus.account_company_id as string, "Attachments");
      setInboxCompany(companyModel);
      return companyModel;
    }
    return inboxCompany;
  };

  const handleTemplateAttachments = async (templateObj: any, setFileCallback: FunctionCall) => {
    const comapnyData = await getCompanyData();
    const allW9Documents: AttachmentModel[] = (comapnyData?.attachments as AttachmentModel[]).filter(
      (attachment: AttachmentModel) => attachment.attachmentType === "W-9"
    );
    const allDcoumentsMeta = allW9Documents
      ? allW9Documents.map((document: AttachmentModel) => ({
          name: `${document.fileName ?? "N/A"}`,
          id: document.attachmentId,
        }))
      : [];
    setTrackActivityTransactions([
      ...allDcoumentsMeta.map((document) => ({ transaction_id: document.id, transaction_type: AttachmentType.DOCUMENT })),
    ]);
    const templateDataMapper = prepareTemplateDataObject(templateObj, null, allDcoumentsMeta, null);
    handleTemplateAttachment(templateObj, setFileCallback, templateDataMapper);
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    setFileCallback?: FunctionCall
  ) => {
    if (templateID) {
      setTemplateName(templateID);

      let customerName = "{customer}";
      if (!_.isEmpty(item)) {
        const {
          original: { primary_connection = {} },
        } = item;

        customerName = primary_connection?.name;
      } else {
        customerName = (company as CustomerDetailsModel)?.name ?? "{customer}";
      }

      const templateObj = templateData.get(selectedWorkspace?.id).get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: customerName,
          workspaceCompanyName: companyData?.companyName || "{AR Company Name}",
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          signature: signature?.email_signature || "",
        })
      );
      if (templateObj.templateAttachment) {
        handleTemplateAttachments(templateObj, setFileCallback as FileCallback);
      }
    }
  };

  const hideNewActivityDropdownTypes = ["/spam"];
  const showNewActivityDropdown = hideNewActivityDropdownTypes.some((type) => path.toLowerCase().includes(type));
  const headerButtons: HeaderButton[] = showNewActivityDropdown
    ? []
    : [
        {
          component: (
            <NewActivityDropdown
              buttonName={"New Activity Stream"}
              origin={"index"}
              handleReplySend={handleSend}
              handleReplySendClose={handleSendAndMarkClosed}
              addActivityDropdown={
                path.includes("customers" || "vendors")
                  ? [
                      { displayName: "Email", icon: <Email /> },
                      { displayName: "Phone Call", icon: <Phone /> },
                      { displayName: "Note", icon: <NoteFill /> },
                    ]
                  : [
                      { displayName: "Email", icon: <Email /> },
                      { displayName: "Phone Call", icon: <Phone /> },
                    ]
              }
              isTemplateSupport={newActivityType === "email" && (path.includes("vendors") || path.includes("customers"))}
              supportedTemplateList={supportedTemplateList}
              prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
              replyToOptions={filteredContactOptions}
              newActivityType={newActivityType}
              setNewActivityType={setNewActivityType}
              showAddActivity={newActivity}
              setShowAddActivity={setNewActivity}
              typeAheadAction={typeAheadAction}
              resetFunction={() => {
                setEnableTypeAheadSuggestions(false);
              }}
              fromTime={fromTime}
              toTime={toTime}
              setToTime={setToTime}
            />
          ),
        },
      ];

  const createTo = (contact: ContactItem) => {
    return {
      id: contact?.name ? contact?.email_address ?? "" : "",
      label: contact?.name || contact?.email_address || "",
    } as To;
  };

  const filterValidContacts = (contacts: ContactItem[]) => {
    return contacts
      .filter((contact: ContactItem) => {
        return contact.email_address !== "";
      })
      .map((contact: ContactItem) => {
        return createTo(contact);
      });
  };

  /**
   * @function setContactsToNudge
   * A helper function to set contact options to perform nudge on a selected activity stream.
   * This function will fetch required activities belonging to the activity stream by using its id.
   * @param activityStreamId
   */
  const setContactsToNudge = async (activityStreamId: string) => {
    const activities = await activitiesClientV2.getStream(selectedWorkspace?.id, activityStreamId);
    const latestActivity = (activities?.data?.length ? activities?.data[0] : {}) as ActivityItemModel;
    setNudgeTo(latestActivity?.to ? filterValidContacts(latestActivity?.to) : []);
    setNudgeCc(latestActivity?.cc ? filterValidContacts(latestActivity?.cc) : []);
    setNudgeBcc(latestActivity?.bcc ? filterValidContacts(latestActivity?.bcc) : []);
    setIsActivityPopupLoading(false);
  };

  /**
   * Ellipse handler which open the new activity dialog.
   *
   * @param {TableData} row The current selected row of the table.
   * @param {boolean} requireTemplates Flag to check if templates are required or not. This flag is set on hover bar nudge activity creation
   */
  const handleNewActivity = (row: Row<TableData>, requireTemplates = true) => {
    setSelectedItem(row);
    setSupportedTemplateList(getTemplateDefinitionsByView(row));
    setIsTemplateSupport(requireTemplates);
    setIsReply(true);
    setIsActivityPopupLoading(true);
    if (!requireTemplates) {
      setContactsToNudge(row?.original?.id);
      setOriginalSubjectLine(row?.original?.subject);
      setIsNudgeActivity(true);
    }
    setNewActivityType("email");
    setShowActivity(true);
  };

  /**
   * @function closeAddActivity
   * A helper function to close Add activity popup and clear subscriptions.
   */
  const closeAddActivity = () => {
    setShowActivity(false);
    setIsNudgeActivity(false);
    setOriginalSubjectLine("");
    setIsReply(false);
    setNudgeTo([]);
    setNudgeCc([]);
    setNudgeBcc([]);
    setFromTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setEditorState("");
  };

  const primaryContact = filterPrimaryContacts(companyContactOptions);
  const filteredCompanyContactOptions = [...companyContactOptions, ...allContactOptions];
  return (
    <>
      <TableForActivityStreams
        ref={tableRef}
        dataSets={[
          {
            id: "Activities",
            displayName: "Activities",
            rowSelectToken: "activityId",
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: activitiesClientV2.fetchTableRecords,
              fetchParser: fetchParser,
              includeOption: "attachments, company, UserAssignedToName",
            },
            columns: props.columns,
            // defaultSort: "CREATED",
            defaultSortToken: "DESC",
            handleRowClick: handleRowClick,
            predefinedFilters: props.predefinedFilter,
            assigneeType: props.assigneeType,
            route: props.route,
          },
        ]}
        states={{
          isLoading: isLoading,
          setLoading: setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: totalCount,
          setTotalCount: setTotalCount,
          setPageSize: setPageSize,
        }}
        toggles={{
          showSearchbar: false,
          showExportBtn: false,
          showCaption: true,
          showRowSelect: true,
          showNavigation: true,
          showSelectAllBar: true,
        }}
        email={true}
        hoverBar={!spam}
        onRowSelect={onRowSelect}
        headerBtns={headerButtons}
        activityHandler={handleNewActivity}
        rowSelectBtns={spam ? spamTableButtons : rowSelectButtons}
      />
      {showActivity && (
        <NewActivityPopup
          key={`key-add-activity-${newActivityType}-${isActivityPopupLoading}`}
          title={"New Activity"}
          open={showActivity}
          contactOptions={filteredCompanyContactOptions}
          handleSend={isReply ? handleSendReply : handleSend}
          isTemplateSupport={isTemplateSupport}
          supportedTemplateList={supportedTemplateList}
          editorState={editorState}
          handleSendMarkClosed={handleSendAndMarkClosed}
          onClose={closeAddActivity}
          activityType={newActivityType}
          setNewActivityType={(type: any) => {
            setNewActivityType(type);
          }}
          addActivityDropdown={[
            { displayName: "Email", icon: <Email /> },
            { displayName: "Note", icon: <NoteFill /> },
            { displayName: "Phone Call", icon: <Phone /> },
          ]}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          defaultTitle={originalSubjectLine}
          clickDisabled={isNudgeActivity}
          defaultTo={isNudgeActivity ? nudgeTo : _.isEmpty(primaryContact) ? [companyContactOptions[0]] : primaryContact}
          defaultCc={isNudgeActivity ? nudgeCc : []}
          defaultBcc={isNudgeActivity ? nudgeBcc : []}
          loading={isNudgeActivity && isActivityPopupLoading}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
        />
      )}
      <ActivityMove
        open={moveDrawerOpen}
        onClose={() => setMoveDrawerOpen(false)}
        activityIds={activityIds}
        onCallback={() => tableRef?.current?.softRefresh()}
      />
      <ActivityReassign
        open={reassignDrawerOpen}
        onClose={() => setReassignDrawerOpen(false)}
        activityIds={activityIds}
        onCallback={() => tableRef?.current?.softRefresh()}
      />
      <SpamFraudModal showModal={showModal} setShowModal={setShowModal} connectionSpam={connectionSpam} handleSpamFraud={handleSpamFraud} />
    </>
  );
}
