import APIClient from "../APIClient";

export interface ReviewResponse extends APIResponse {
  creator: {
    name: string;
    email_address: string;
    type: string;
    id: number;
  };
}
export class ApprovalActivitiesClient {
  BASE_URL = "inbox/api/magic_links";

  /**
   * This redirects the approval request to new approver
   *
   * @param id redirect magic UUID
   * @param data redirect request data
   */
  redirectApprovalRequest(id: UUID, data: any): Promise<APIResponse> {
    const url = `${this.BASE_URL}/${id}/activity/redirect`;
    return APIClient.post(url, data, undefined, true, false, false).then((res) => res.data);
  }

  /**
   * Updates the approval request with approver review details
   *
   * @param requestId approval request Id
   * @param reviewAction approve/decline
   * @param reviewNote message from approver
   */

  reviewRequest(requestId: string, reviewAction: string, reviewNote: string | null): Promise<ReviewResponse> {
    const url = `${this.BASE_URL}/${requestId}/activity/review`;
    const payload = {
      review_action: reviewAction,
      note: reviewNote,
    };
    return APIClient.patch(url, payload, undefined, true, false, false).then((res) => res.data);
  }

  /**
   * This fetches the details of the approval request. includes -
   * approver, message & approval request date
   *
   * @param id magic UUID
   * @returns approval request details
   */
  getRequestDetails(id: UUID): Promise<APIResponse> {
    const url = `${this.BASE_URL}/${id}/approval_activity`;
    return APIClient.get(url, undefined, true, false, true).then((res) => res.data);
  }

  /**
   * This fetches the details for approval activity stream (top 3
   * stream items) & related transactions
   *
   * @param id magic UUID
   * @returns approval activity stream details
   */
  getActivityDetails(id: UUID): Promise<APIResponse> {
    const url = `${this.BASE_URL}/${id}/activity_stream`;
    return APIClient.get(url, undefined, true, false, true).then((res) => res.data);
  }
}
