import { AxiosError } from "axios";
import Utils from "../utils";
import { companiesClient } from "../../db/accessor";
import { activitiesClientV2, emailsClientV2 } from "../../db/version2Accessor";
import { CONNECTION_STATUS } from "../../constants/ConnectionConstants";
import { ActivityActionTypes, ActivityOptions, ActivityStatus, ActivityType, EmailAction } from "../../types/enums";
import { ActivityDropdownItem } from "../../components/library/AddNewActivityDropdown/NewActivityPopup";
import TrackingUtils from "../../components/Tracking/Tracking.Utils";
import _ from "underscore";

class NewActivityUtils {
  public workspaceId: number;

  constructor(workspaceId: number) {
    this.workspaceId = workspaceId;
  }

  public fetchCompanyInfo = async (companyId: string, setToastOptions: React.Dispatch<React.SetStateAction<ToastOptions>>): Promise<CompanyModel> => {
    if (!companyId) {
      return {} as CompanyModel;
    }
    const toastOptions: ToastOptions = { severity: "error", message: "Something Went Wrong", open: true };
    let data = {} as CompanyModel;
    try {
      data = await companiesClient.getCompany(companyId, "Classification");
    } catch (error) {
      console.log(error);
      setToastOptions(toastOptions);
    }
    return data;
  };

  /**
   *
   * @param emailArray autocomplete compatible array of contacts
   * @returns array of email address/label that goes in the payload
   */
  public getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);

  /**
   * @function getCompanyIdForFirstTo
   * A helper function to get the company id for the first contact selected
   * @param to - Contacts
   * @returns
   */
  private getCompanyIdForFirstTo = (to: To[]) => {
    if (to && to.length) {
      return to[0].companyId;
    }
    return "";
  };

  /**
   *
   * @param to array of contacts in TO
   * @param cc array of contacts in CC
   * @param bcc array of contacts in BCC
   * @param title subject of the new message
   * @param body content of the message
   * @param attachmentIds ids of attached files
   * @param activityType type of activity e.g. email/payment_reminder/phone_call etc.
   * @param transactionId id of the invoice from where user has created the new activity
   * @returns payload to create new activity
   */
  private _preparePayload = (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    activityType: string,
    transactionId?: string,
    companyId?: string,
    transactionType?: string
  ) => {
    let payload: Partial<ActivityItemEmailModel> = {
      to: this.getEmailArray(to),
      cc: this.getEmailArray(cc),
      bcc: this.getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: this.workspaceId,
      contact_company_id: this.getCompanyIdForFirstTo(to),
      content_type:
        activityType && activityType === ActivityOptions.PHONE_CALL
          ? ActivityType.PhoneCall
          : activityType && [ActivityOptions.EMAIL, ActivityOptions.APPROVAL_REQUEST].includes(activityType as ActivityOptions)
          ? ActivityType.Email
          : activityType,
      attachments: attachmentIds,
      activity_type:
        activityType && activityType === ActivityOptions.PHONE_CALL
          ? ActivityType.PhoneCall
          : activityType === ActivityOptions.APPROVAL_REQUEST
          ? ActivityType.Approval
          : activityType,
    };
    if (transactionId && transactionId !== "") {
      const activityTransactions = _.uniq(
        [...inboxAttachments, { transaction_id: transactionId, transaction_type: transactionType ?? "invoice" }],
        "transaction_id"
      );
      payload = { ...payload, activity_transactions: activityTransactions };
    }
    if (companyId && companyId !== "") {
      payload = { ...payload, primary_connection_id: companyId };
    }
    payload = {
      ...payload,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, payload.activity_transactions as TransactionItemType[]),
      attachments: attachmentIds.filter((item) => typeof item === "number"),
    };

    return payload;
  };

  /**
   * @param id id of activity stream
   * @returns update api response
   */
  public closeNewActivity = async (id: string) => {
    let response = {};
    try {
      response = await activitiesClientV2.patch(this.workspaceId, id, ActivityActionTypes.Status, {
        status: ActivityStatus.Closed?.toLocaleLowerCase() as Status,
      });
    } catch (error) {
      return error;
    }
    return response;
  };

  /**
   * @param invoiceId invoice id
   * @param activityType type of activity e.g. input_email/payment_reminder/call_log etc.
   * @param resetShowActivityStates callback function to reset show activity states
   * @param setToastOptions callback function to set toast messag
   * @returns handleSend function
   */
  public sendNewActivity = (
    activityType: string,
    setToastOptions: (options: ToastOptions) => void,
    setShowActivity?: React.Dispatch<React.SetStateAction<boolean>> | ((toggler: boolean) => Promise<void>),
    invoiceId?: string,
    resetShowActivityStates?: () => void,
    customerId?: string,
    isSendAndClose?: boolean,
    contactOptions?: To[],
    transactionType?: string
  ) => {
    return (
      to: To[],
      cc: To[],
      bcc: To[],
      title: string,
      body: string,
      attachmentIds: AttachmentIds,
      inboxAttachments: TransactionItemType[],
      disableSoftRefresh = false
    ): Promise<APIResponse> => {
      const payload = this._preparePayload(
        to,
        cc,
        bcc,
        title,
        body,
        attachmentIds,
        inboxAttachments,
        activityType,
        invoiceId,
        customerId,
        transactionType
      );
      let toastOptions: ToastOptions = { open: true, severity: "success", message: "Messages Sent" };

      return new Promise((resolve) => {
        emailsClientV2
          .post(payload)
          .then(async (emailResponse: ActivityStream) => {
            TrackingUtils.trackFSData(
              to,
              "New Email",
              "New",
              isSendAndClose ?? false,
              contactOptions ?? [{} as To],
              "Customers",
              "Open Invoices",
              activityType
            );
            !disableSoftRefresh && resetShowActivityStates?.();
            resolve(emailResponse ?? []);
            setShowActivity?.(false);
          })
          .catch((err: AxiosError) => {
            toastOptions.severity = "error";
            if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
              toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
            } else {
              const errorResponse = err.response as AxiosErrorResponseData;
              toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
            }
          })
          .finally(() => {
            setToastOptions(toastOptions);
          });
      });
    };
  };

  /**
   * @param invoiceId invoice id
   * @param activityType type of activity e.g. input_email/payment_reminder/call_log etc.
   * @param resetShowActivityStates callback function to reset show activity states
   * @param setToastOptions callback function to set toast messag
   * @returns handleSendAndClose function
   */
  public sendNewActivityAndClose = (
    activityType: string,
    setToastOptions: (options: ToastOptions) => void,
    setShowActivity?: React.Dispatch<React.SetStateAction<boolean>> | ((toggler: boolean) => Promise<void>),
    invoiceId?: string,
    resetShowActivityStates?: () => void,
    customerId?: string,
    transactionType?: string
  ) => {
    return async (
      to: To[],
      cc: To[],
      bcc: To[],
      title: string,
      body: string,
      attachmentIds: AttachmentIds,
      inboxAttachments: TransactionItemType[]
    ) => {
      const handleSend = this.sendNewActivity(
        activityType,
        setToastOptions,
        setShowActivity,
        invoiceId,
        resetShowActivityStates,
        customerId,
        true,
        undefined,
        transactionType
      );
      handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true).then((response) => {
        if (response.success) {
          let toastOptions: ToastOptions = { open: true, severity: "success", message: "Activity Closed" };
          let closeResponse = {} as APIResponse;

          this.closeNewActivity(response.data.activity_stream.id)
            .then((res) => {
              closeResponse = res as APIResponse;
            })
            .catch((error) => {
              closeResponse.success = false;
              console.log(error);
            })
            .finally(() => {
              {
                if (!closeResponse.success) {
                  toastOptions = {
                    ...toastOptions,
                    severity: "error",
                    message: "Activity was not closed",
                  };
                }
                setToastOptions(toastOptions);
                resetShowActivityStates?.();
              }
            });
        }
      });
    };
  };

  public evaluateNewActivityProps = (
    activityType: string,
    showActivity: boolean,
    defaultContactOptions: To[],
    contactOptions: To[],
    setToastOptions: React.Dispatch<React.SetStateAction<ToastOptions>>,
    closeActivityPopUp: () => void,
    setNewActivityType: React.Dispatch<React.SetStateAction<string>>,
    addActivityDropdown: any[],
    invoiceId?: string,
    resetShowActivityStates?: () => void,
    customerId?: string,
    transactionType?: string
  ) => {
    const handleSend = this.sendNewActivity(
      activityType,
      setToastOptions,
      undefined,
      invoiceId,
      resetShowActivityStates,
      customerId,
      false,
      undefined,
      transactionType
    );
    const handleSendMarkClosed = this.sendNewActivityAndClose(
      activityType,
      setToastOptions,
      undefined,
      invoiceId,
      resetShowActivityStates,
      customerId,
      transactionType
    );

    return {
      key: `key-add-activity-${activityType}`,
      title: "New Activity",
      open: showActivity,
      contactOptions: contactOptions.filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i),
      defaultTo: defaultContactOptions,
      handleSend,
      defaultTitle: "",
      handleSendMarkClosed,
      onClose: closeActivityPopUp,
      activityType,
      setNewActivityType: (type: string) => {
        setNewActivityType(type);
      },
      addActivityDropdown: addActivityDropdown,
    };
  };

  public evaluateNewActivityDropdownProps = (
    buttonName: string,
    activityType: string,
    showActivity: boolean,
    contactOptions: To[],
    setToastOptions: React.Dispatch<React.SetStateAction<ToastOptions>>,
    closeActivityPopUp: () => void,
    setNewActivityType: any,
    setShowActivity: React.Dispatch<React.SetStateAction<boolean>> | ((toggler: boolean) => Promise<void>),
    addActivityDropdown: ActivityDropdownItem[],
    invoiceId?: string,
    resetShowActivityStates?: () => void,
    customerId?: string,
    typeAheadAction?: (val: string) => void
  ) => {
    const handleReplySend = this.sendNewActivity(activityType, setToastOptions, setShowActivity, invoiceId, resetShowActivityStates, customerId);
    const handleReplySendClose = this.sendNewActivityAndClose(
      activityType,
      setToastOptions,
      setShowActivity,
      invoiceId,
      resetShowActivityStates,
      customerId
    );

    return {
      key: `key-add-activity-${activityType}`,
      handleReplySend,
      handleReplySendClose,
      addActivityDropdown,
      setNewActivityType,
      buttonName,
      replyToOptions: contactOptions,
      newActivityType: activityType,
      showAddActivity: showActivity,
      defaultTitle: "",
      setShowAddActivity: setShowActivity,
      resetFunction: closeActivityPopUp,
      typeAheadAction,
    };
  };
}

export default NewActivityUtils;
