import React from "react";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../../../contexts/FeatureFlagContext";
import { ActivityType, AuditLogType, InlinePrompts } from "../../../../../types/enums";
import { ActivityFeedItemContainerProps } from "./ActivityFeedItemTypes";
import {
  AccessRevoked,
  Assign,
  BouncedEmail,
  Closed,
  Email,
  ForwardedActivity,
  ForwardedLog,
  MarkedSender,
  MergeConnection,
  MoveActivity,
  NoteActivity,
  NudgeActivity,
  OnboardingRequested,
  OnboardVendor,
  PhoneActivity,
  Redirect,
  Request,
  Response,
  Reviewed,
  Snoozed,
  Spam,
  Unsnoozed,
} from "./ActivityItemVariants";

const ActivityFeedItemComponent: React.FC<ActivityFeedItemContainerProps> = ({ variant, handlers, timeSaving, baseProps, ...props }) => {
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;

  switch (variant) {
    case ActivityType.Email:
    case ActivityType.IncomingEmail:
      return <Email timeSaving={timeSaving} {...{ ...baseProps, ...handlers?.reply }} />;
    case ActivityType.BouncedEmail:
      return (
        <BouncedEmail
          creator={baseProps.creator}
          readonly={baseProps.readonly}
          erroneousEmail={baseProps.contacts.to ? baseProps.contacts.to[0] : ""}
          bounceErrorMsg={props.bounceErrorMsg ?? ""}
          {...handlers?.bounce}
        />
      );
    case ActivityType.PhoneCall:
      return <PhoneActivity {...baseProps} />;
    case ActivityType.Note:
      return <NoteActivity {...{ ...baseProps, contacts: { to: undefined, cc: undefined, bcc: undefined } }} />;
    case ActivityType.ApprovalRequest:
      return (
        <Request
          enableReviewOptions={!["approved", "declined"].includes(props?.activityStatus)}
          timeSaving={timeSaving}
          {...{ ...baseProps, ...handlers?.approval, ...handlers?.reply }}
        />
      );
    case ActivityType.ApprovalRedirect:
      return (
        <Redirect
          enableReviewOptions={!["approved", "declined", "redirected"].includes(props?.activityStatus)}
          approverName={props.approverName}
          {...{ ...baseProps, ...handlers?.reply, ...handlers?.approval }}
        />
      );
    case ActivityType.ApprovalReviewed:
      return <Reviewed timeSaving={timeSaving} {...{ ...baseProps, ...handlers?.reply }} />;
    case ActivityType.ApprovalAccessRevoked:
      return <AccessRevoked timeSaving={timeSaving} {...{ ...baseProps, ...handlers?.reply }} />;
    case ActivityType.ApprovalResponse:
      return <Response {...baseProps} />;
    case ActivityType.Nudge:
      return <NudgeActivity enableNudgeAgain={props.isNudgeActivity} {...{ ...baseProps, ...handlers?.reply, ...handlers?.nudge }} />;
    case AuditLogType.Assign:
      return <Assign creator={baseProps.creator} assigneeNote={props.note} assigneeName={props.assigneeName} />;
    case AuditLogType.Close:
      return <Closed creator={baseProps.creator} />;
    case AuditLogType.Spam:
      return <Spam creator={baseProps.creator} readonly={baseProps.readonly} enableNotSpam={props.isStreamSpam} {...handlers?.spam} />;
    case AuditLogType.Forward:
    case ActivityType.Forward:
      return props.type === ActivityType.AuditLog ? (
        <ForwardedLog creator={baseProps.creator} readonly={baseProps.readonly} {...handlers?.forward} />
      ) : (
        <ForwardedActivity {...{ ...baseProps, ...handlers?.forward, ...handlers.reply }} />
      );
    case ActivityType.Move:
      return <MoveActivity creator={baseProps.creator} connectionName={props.primaryConnection} note={props.note} />;
    case AuditLogType.Snoozed:
      return (
        <Snoozed
          creator={baseProps.creator}
          timestampUntilSnoozed={props.timestampUntilSnoozed}
          status={props.activityStatus}
          readonly={baseProps.readonly}
          {...handlers?.snooze}
        />
      );
    case AuditLogType.Unsnoozed:
      return <Unsnoozed creator={baseProps.creator} />;
    case AuditLogType.SpamConnection:
    case AuditLogType.UnspamConnection:
    case AuditLogType.FraudConnection:
    case AuditLogType.UnfraudConnection:
      return <MarkedSender creator={baseProps.creator} currentStatus={props.connectionStatus} />;
    case AuditLogType.MergeConnection:
      return <MergeConnection creator={baseProps.creator} connectionName={props.primaryConnection} />;
    case AuditLogType.OnboardRequest:
      return isEnabled("FORWARD_AP_AUTOMATION") ? <OnboardingRequested date={baseProps.creator.createdOn} /> : null;
    case InlinePrompts.ONBOARD_VENDOR:
      return isEnabled("FORWARD_AP_AUTOMATION") ? <OnboardVendor {...{ ...handlers.onboardVendor }} /> : null;
    default:
      console.error("Activity Type does not exist");
      return <></>;
  }
};

export default ActivityFeedItemComponent;
