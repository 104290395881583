import React from "react";
import Utils from "../../../utils/utils";
import UploadComponent from "./UploadComponent";
import "./UploadComponentStyles/UploadComponent.scss";
import { UploadContainerProps } from "./UploadComponentInterfaces/UploadComponentInterfaces";
import * as UUID from "uuid";
import { attachmentsClientV2 } from "../../../db/version2Accessor";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_TIMEOUTS } from "../../../constants/NumericConstants";
import { NUMERIC_VALUES } from "../../../constants/NumericConstants";

const UploadContainer: React.FC<UploadContainerProps> = ({
  cropperProps,
  enableFakeLoader = false,
  getImageURL = (url: string) => {
    console.log(url);
  },
  labelProps,
  open,
  toggleFunction,
}: UploadContainerProps) => {
  const [imageLoadingLocal, setImageLoadingLocal] = React.useState<boolean>(false);
  const [imageLoadingLocalPercent, setImageLoadingLocalPercent] = React.useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [imageUploadSuccess, setImageUploadSuccess] = React.useState<boolean>(false);
  const [imageCropSuccess, setImageCropSuccess] = React.useState<boolean>(false);
  const [selectedImageBlobURL, setSelectedImageBlobURL] = React.useState<string>("");
  const [selectedImageSize, setSelectedImageSize] = React.useState<string>("");
  const [finalFile, setFinalFile] = React.useState<File>();
  const [finalFileUploading, setFinalFileUploading] = React.useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onClickUploadButton = () => {
    inputRef?.current?.click();
  };

  const onCompleteCropping = (imgSrc: { blob: Blob; localBlobDataURL: string }) => {
    setSelectedImageBlobURL(imgSrc.localBlobDataURL);
    const id = UUID.v4().replace(/-/g, "");
    const timestamp = new Date().getTime();
    const file = new File([imgSrc.blob], `${id}_${timestamp}.jpg`, { type: "image/jpeg" });
    setFinalFile(file);
    setImageCropSuccess(true);
  };

  const onClickResetStates = () => {
    setSelectedImageBlobURL("");
    setSelectedImageSize("");
    setImageLoadingLocal(false);
    setImageLoadingLocalPercent(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setImageUploadSuccess(false);
    setImageCropSuccess(false);
    setFinalFile(undefined);
    setFinalFileUploading(false);
  };
  const onClickCloseModal = () => {
    toggleFunction(false);
    onClickResetStates();
  };

  const onCompleteSave = () => {
    if (finalFile) {
      setFinalFileUploading(true);
      const formData = new FormData();
      formData.append("file", finalFile);
      formData.append("public", JSON.stringify(true));
      attachmentsClientV2
        .postAttachment(formData)
        .then((response: APIResponse) => {
          if (response.success) {
            getImageURL(response?.data?.file_url);
            onClickCloseModal();
          } else {
            getImageURL("");
          }
        })
        .catch((err: any) => {
          console.error(err);
          getImageURL("");
        })
        .finally(() => {
          setFinalFileUploading(false);
        });
    }
  };

  const onUploadSetImage = (file: string, fileSize: number | undefined) => {
    setSelectedImageBlobURL(file);
    setSelectedImageSize(Utils.calculateFileSize(fileSize || DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) ?? "");
  };

  React.useEffect(() => {
    if (enableFakeLoader && selectedImageBlobURL?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setImageLoadingLocal(true);
      let progressCounter = 1;
      const timer = setInterval(() => {
        if (progressCounter === NUMERIC_VALUES.CONSTANT_TEN + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
          clearInterval(timer);
          setTimeout(() => {
            setImageLoadingLocal(false);
            setImageUploadSuccess(true);
          }, NUMERIC_TIMEOUTS.HALF_SECOND);
        } else {
          setImageLoadingLocalPercent(progressCounter * NUMERIC_VALUES.CONSTANT_TEN);
          progressCounter += DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
        }
      }, NUMERIC_TIMEOUTS.TENTH_OF_SECOND);
    } else if (!enableFakeLoader && selectedImageBlobURL?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setImageUploadSuccess(true);
    }
  }, [selectedImageBlobURL]);

  return (
    <UploadComponent
      cropperProps={cropperProps}
      enableFakeLoader={enableFakeLoader}
      finalFileUploading={finalFileUploading}
      imageCropSuccess={imageCropSuccess}
      imageLoadingLocal={imageLoadingLocal}
      imageLoadingLocalPercent={imageLoadingLocalPercent}
      imageUploadSuccess={imageUploadSuccess}
      inputRef={inputRef}
      labelProps={labelProps}
      onClickCloseModal={onClickCloseModal}
      onClickResetStates={onClickResetStates}
      onClickUploadButton={onClickUploadButton}
      onCompleteCropping={onCompleteCropping}
      onCompleteSave={onCompleteSave}
      open={open}
      onUploadSetImage={onUploadSetImage}
      selectedImageBlobURL={selectedImageBlobURL}
      selectedImageSize={selectedImageSize}
    />
  );
};

export default UploadContainer;
