import React, { useState, MouseEvent } from "react";
import Loading from "../../../library/Loading/Loading";
// Imports from react-router-dom
import { useHistory, useRouteMatch } from "react-router-dom";
// Import context
import { DashboardContext } from "../../../../contexts/DashboardContext";
// Import assets
import "./TopCompanies.scss";
import NoData from "../../../library/NoData/NoData";
import { Tooltip, IconButton } from "@mui/material";
import { Ellipses, SortUpTransparent, TooltipIcon } from "../../../library/Icons/Icons";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, TRIM_COUNT_CONSTANTS } from "../../../../constants/NumericConstants";
import MenuItem from "../../../library/MenuItem/MenuItem";
import { makeStyles } from "@mui/styles";
import DropMenu from "../../../library/DropMenu/DropMenu";
import Menu from "../../../library/Menu/Menu";
import { FallbackTypes, TopCompaniesBy } from "../../../../types/enums";
import { commaSeparatedCurrency } from "../../../../db/utils/currency";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import Utils from "../../../../utils/utils";
import Button from "../../../library/Button/Button";

/**
 * * Defines the Top 5 Customers with Invoices Past Due Widget
 */

interface TopCompaniesInterface {
  disableInfoTooltip?: boolean;
  configs: any;
}

interface TopCompaniesMenu {
  label: string;
  url: (id: string) => string;
}

const useStyles = makeStyles(() => ({
  tooltip: {
    background: "white !important",
    margin: "0 !important",
    padding: "0 !important",
    borderRadius: "0.125rem",
    boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
  },
}));

export default function TopCompanies({ disableInfoTooltip, configs }: TopCompaniesInterface): React.ReactElement {
  const { getARTopRisksData, getAPTopRisksData } = React.useContext(DashboardContext) as DashboardType;
  const { path } = useRouteMatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<CustomerSummaryModelInterface[] | VendorSummaryModelInterface[] | Array<RecentConnections> | null>(null);
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const sortMenuIsOpen = Boolean(sortMenuAnchorEl);
  const [selectedIndex, setSelectedIndex] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const [sortByAsc, setSortByAsc] = React.useState<boolean>(true);
  const filterChecks = React.useRef<Array<string>>([
    TopCompaniesBy.Open_Invoices,
    TopCompaniesBy.Past_Due_Balance,
    TopCompaniesBy.Past_Due_Invoices,
    TopCompaniesBy.Total_Balance,
    TopCompaniesBy.Open_Bills,
    TopCompaniesBy.Coming_Due,
  ]);

  const SORTMENU = configs.menu.sortMenu.map((menuItem: { [key: string]: string }) => ({
    ...menuItem,
    route: (sortByASC: boolean) =>
      `${menuItem.routePath}${configs.connections_filter.seeAllConnectionsURLFilters[menuItem.label].filter}${sortByASC ? "" : "+DESC"}${
        configs.connections_filter.seeAllConnectionsURLFilters[menuItem.label].additionalFilters
      }&page=1&pageSize=25`,
  }));

  const fetchData = async (filter: string, sortOrder: boolean) => {
    setErrorMessage("");
    setLoading(true);
    try {
      if (filterChecks.current.includes(SORTMENU[selectedIndex].label) && configs.fetchConnectionsType === "company") {
        const res = (await getARTopRisksData(
          filter,
          sortOrder,
          selectedWorkspace?.id ?? FallbackTypes.Id,
          configs.fetchConnectionsType,
          configs.connections_filter.sortingValues
        )) as CustomerSummaryModelInterface[];
        setData(res);
      } else if (filterChecks.current.includes(SORTMENU[selectedIndex].label) && configs.fetchConnectionsType === "vendor") {
        const res = (await getAPTopRisksData(
          filter,
          sortOrder,
          selectedWorkspace?.id ?? FallbackTypes.Id,
          configs.fetchConnectionsType,
          configs.connections_filter.sortingValues
        )) as VendorSummaryModelInterface[];
        setData(res);
      } else {
        const res = (await getARTopRisksData(
          filter,
          sortOrder,
          selectedWorkspace?.id ?? FallbackTypes.Id,
          configs.fetchConnectionsType,
          configs.connections_filter.sortingValues
        )) as APIResponse;
        setData(res.data as Array<RecentConnections>);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMessage("Error Loading Data");
    }
    setLoading(false);
  };

  const fetchDataBySorting = (sortOrder: boolean) => {
    setSortByAsc(sortOrder);
    fetchData(SORTMENU[selectedIndex].label, sortOrder);
  };

  React.useEffect(() => {
    fetchDataBySorting(SORTMENU[selectedIndex].defaultSortOrderByASC);
  }, [selectedIndex]);

  React.useEffect(() => {
    if (selectedIndex !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setSelectedIndex(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    } else {
      fetchDataBySorting(SORTMENU[selectedIndex].defaultSortOrderByASC);
    }
  }, [selectedWorkspace]);

  const menu: Array<TopCompaniesMenu> = configs.menu.ellipsis.map((menuItem: { [key: string]: string }) => ({
    label: menuItem.label,
    url: (id: string) =>
      `${path
        .split("/")
        .slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.split("/").length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)
        .join("/")}/${menuItem.connectionType}${id ? `/${id}` : `/invalid-${configs.fetchConnectionsType}`}${menuItem.urlPath}${menuItem.urlSort}`,
  }));

  const handleOnClick = (e: MouseEvent<HTMLParagraphElement>) => {
    e.preventDefault();
    history.push(`${path}/top_customers?sort=AMOUNTPASTDUE+DESC%2C+TOTALINVOICESPASTDUE+DESC`);
  };

  const generateListItem = (menuList: Array<TopCompaniesMenu>, id: string) => {
    return menuList.map((item, index) => {
      return (
        <MenuItem
          key={index}
          onClick={() => {
            history.push(item.url(id));
          }}
          id={item.label}
        >
          {item.label}
        </MenuItem>
      );
    });
  };

  const classes = useStyles();

  const getDataValue = (val: CustomerSummaryModelInterface | VendorSummaryModelInterface) => {
    if (SORTMENU[selectedIndex].label === TopCompaniesBy.Total_Balance) {
      return commaSeparatedCurrency(val?.[configs.platform.dataLabels.connection_outstanding_amount]?.toString());
    } else if ([TopCompaniesBy.Past_Due_Balance, TopCompaniesBy.Coming_Due].includes(SORTMENU[selectedIndex].label)) {
      return commaSeparatedCurrency(val?.[configs.platform.dataLabels.connection_amount_due]?.toString());
    } else if (SORTMENU[selectedIndex].label === TopCompaniesBy.Past_Due_Invoices) {
      return val?.[configs.platform.dataLabels.connection_total_receipts];
    } else if ([TopCompaniesBy.Open_Invoices, TopCompaniesBy.Open_Bills].includes(SORTMENU[selectedIndex].label)) {
      return val?.[configs.platform.dataLabels.connection_open_receipts];
    }
    return null;
  };

  const getDataLabel = (label: string) => {
    if (label === TopCompaniesBy.Recent_Activity) {
      return "Last Activity";
    } else if (label === TopCompaniesBy.Coming_Due) {
      return "Coming Due Next 30 Days";
    }
    return label;
  };

  return (
    <div className={`tr-wrapper`}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={`tr-head`}>
            <span className={`title`} onClick={(e: MouseEvent<HTMLParagraphElement>) => handleOnClick(e)}>
              {configs.title}
            </span>
            {!disableInfoTooltip && (
              <Tooltip title="The top 5 customers with the largest past due balance." placement="right" arrow disableInteractive>
                <IconButton size="small">
                  <TooltipIcon />
                </IconButton>
              </Tooltip>
            )}
            <div className={`drop-down-wrapper ${sortByAsc ? "drop-down-wrapper-active" : ""}`}>
              <DropMenu
                title={SORTMENU[selectedIndex].label}
                isActive={sortMenuIsOpen}
                onClick={(e: MouseEvent<HTMLDivElement>) => setSortMenuAnchorEl(e.currentTarget)}
                iconAlign="right"
                className="drop-menu"
              />
              <Menu
                open={sortMenuIsOpen}
                anchorEl={sortMenuAnchorEl}
                onClose={() => setSortMenuAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {SORTMENU.map((value: { label: string }, index: number) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        setSelectedIndex(index);
                        setSortMenuAnchorEl(null);
                      }}
                    >
                      {value.label}
                    </MenuItem>
                  );
                })}
              </Menu>
              <span className="sort-up-container" onClick={() => fetchDataBySorting(!sortByAsc)}>
                <SortUpTransparent />
              </span>
            </div>
          </div>

          {errorMessage ? (
            <div className="error-container">
              <p className="error">{errorMessage}</p>
            </div>
          ) : (
            <div className="tr-container">
              {data && data?.length !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
                // TODO: Talk with API team about why pageSize doesn't where here. This is a hot-fix until the issue with the endpoint is resolved
                data
                  ?.slice?.(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, TRIM_COUNT_CONSTANTS.TOP_CUSTOMERS)
                  ?.map?.((val: CustomerSummaryModelInterface | VendorSummaryModelInterface | RecentConnections, idx: number) => {
                    if (filterChecks.current.includes(SORTMENU[selectedIndex].label)) {
                      const value = val as CustomerSummaryModelInterface | VendorSummaryModelInterface;
                      return (
                        <div className="customer-row" key={`${value?.[configs.platform.dataLabels.connection_name]}-${idx}`}>
                          <div className="company-header">
                            {value?.[configs.platform.dataLabels.connection_name] &&
                              (value?.[configs.platform.dataLabels.connection_name] as string)?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
                                <div className="company-chip">
                                  {(value?.[configs.platform.dataLabels.connection_name] as string)?.substring(
                                    DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
                                    DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                                  )}
                                </div>
                              )}
                            <p className="company-name">{value?.[configs.platform.dataLabels.connection_name] ?? "N/A"}</p>
                          </div>
                          <div className="company-details">
                            <div className="action-value">{getDataValue(value) ?? "N/A"}</div>
                            <div className="action-label">{getDataLabel(SORTMENU[selectedIndex].label)}</div>
                          </div>
                          <div className="customer-menu">
                            <Tooltip
                              title={generateListItem(menu, value?.[configs.platform.dataLabels.connection_id] as string)}
                              classes={{ tooltip: classes.tooltip }}
                              placement="bottom-start"
                            >
                              <div className="action-handler">
                                <Ellipses />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    }
                    const value = val as RecentConnections;
                    return (
                      <div className="customer-row" key={`${value?.id}-${idx}`}>
                        <div className="company-header">
                          {value?.name && value.name?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
                            <div className="company-chip">
                              {value.name?.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)}
                            </div>
                          )}
                          <p className="company-name">{value.name ?? "N/A"}</p>
                        </div>
                        <div className="company-details">
                          <div className="action-value">{`${Utils.getDateDifferenceFromNow(
                            (value?.last_activity_at ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) * NUMERIC_VALUES.CONSTANT_THOUSAND
                          )}`}</div>
                          <div className="action-label">Last Activity</div>
                        </div>
                        <div className="customer-menu">
                          <Tooltip title={generateListItem(menu, value?.id ?? "")} classes={{ tooltip: classes.tooltip }} placement="bottom-start">
                            <div className="action-handler">
                              <Ellipses />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <NoData />
              )}
            </div>
          )}
          <div className="see-more">
            <Button className="see-more-button" type="button" variant="grey" onClick={() => history.push(SORTMENU[selectedIndex].route(sortByAsc))}>
              {`See ${configs.title} By ${SORTMENU[selectedIndex].label}`}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
