import React from "react";
import ContactHoverBarComponent from "./HoverBarComponent";
export interface ContactFormContainerProps {
  barriers?: boolean;
  data: TableData;
  moveContact: (contactId: string) => void;
  editContact: (contactId: string) => void;
  deleteContact: (contactId: string[]) => void;
  markPrimaryContact: (data: ContactDataType) => void;
}

export const ContactHoverBarContainer = (props: ContactFormContainerProps) => {
  return (
    <ContactHoverBarComponent
      barriers={props.barriers}
      data={props.data ?? {}}
      moveContact={props.moveContact}
      editContact={props.editContact}
      deleteContact={props.deleteContact}
      markPrimaryContact={props.markPrimaryContact}
    />
  );
};
