import React from "react";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";
import { AutocompleteRenderGetTagProps, AutocompleteRenderInputParams, Chip, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PrimaryContact, UserPic } from "../../../library/Icons/Icons";

interface ActivityToProps {
  options: To[];
  to: To[];
  setTo: (e: React.SyntheticEvent, value: To[]) => void;
  typeAheadAction?: (val: string) => void;
}

interface IRenderOptions {
  pictureUrl: string;
  label: string;
  id: string;
  primary: boolean;
}

export default function ActivityTo(props: ActivityToProps): React.ReactElement {
  /**
   * Function used to render the input field.
   *
   * @param {AutocompleteRenderInputParams} params The params which used to compose input field.
   * @returns
   */
  const onRenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      inputProps={{
        ...params.inputProps,
        style: {
          ...params.inputProps.style,
          padding: 0,
        },
      }}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
      }}
      variant="filled"
      autoComplete=""
      onChange={(event) => {
        if (props.typeAheadAction) {
          props.typeAheadAction(event.target.value);
        }
      }}
    />
  );

  /**
   * Function which used to create the contact suggestion list in activity dialog.
   *
   * @param {HTMLLIElement} props The props contains properties to create list.
   * @param {IRenderOptions} option The option which contains the optional properties.
   * @returns {HTMLLIElement} the contact list option.
   */
  const onRenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: IRenderOptions) => (
    <li key={`${option.id}`} className="contact-item-parent" {...props}>
      <div className="contact-item-profile-tag">
        {option.pictureUrl ? (
          <img loading="lazy" width="24" alt={option.label} src={option.pictureUrl} />
        ) : option.primary ? (
          <PrimaryContact />
        ) : (
          <UserPic />
        )}
      </div>
      <div className="contact-info-item">
        {option.id !== "" && option.label !== "" ? (
          <>
            <span className="contact-name">{option.label || "--"}</span>
            <span className="contact-email">{option.id || "--"}</span>
          </>
        ) : (
          <span className="contact-name">{option.label || "--"}</span>
        )}
      </div>
    </li>
  );

  /**
   * Function used to prepare the chip of selected contact.
   *
   * @param {To[]} value The value contains the recipient items.
   * @param {AutocompleteRenderGetTagProps} getTagProps
   * @returns
   */
  const renderTags = (value: To[], getTagProps: AutocompleteRenderGetTagProps) =>
    value?.map(
      (option: To, index: number) =>
        option && (
          <div key={index}>
            <Chip variant="outlined" label={option.label} deleteIcon={<CloseIcon />} {...getTagProps({ index })} />
          </div>
        )
    );
  /**
   * Event handler used to create a chip when user type or select the email address and press space key.
   *
   * @param {KeyboardEvent} event The event is keyDown event which triggered by user.
   */
  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === " " && (event.target as HTMLInputElement).value && !/\s/g.test((event.target as HTMLInputElement).value)) {
      event.preventDefault();
      props.setTo(event, [...props.to.filter((value) => value !== undefined), { id: "", label: (event.target as HTMLInputElement).value }]);
      if (props.typeAheadAction) {
        props.typeAheadAction("");
      }
    }
  };

  /**
   * This Function filters out the options when user types to search for contacts.
   *
   * @param {To} val After comparing return the label if Id is present else return the Id.
   */
  const filterOptions = (val: To) => {
    return val.label + val.id;
  };

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={props.options}
      freeSolo
      value={props.to}
      disablePortal
      clearOnBlur
      className="activity-to-autocomplete"
      onBlur={() => {
        // This is because we are using the clearOnBlur above prop
        if (props.typeAheadAction) {
          props.typeAheadAction("");
        }
      }}
      onKeyDown={onKeyDownHandler}
      onChange={props.setTo}
      autoSelect={true}
      getOptionLabel={filterOptions}
      style={{ border: "none" }}
      renderTags={renderTags}
      renderOption={onRenderOption}
      renderInput={onRenderInput}
      forcePopupIcon={false}
    />
  );
}
