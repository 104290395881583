import React, { useState, MouseEvent, useEffect, useContext, useRef } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import Table from "../../library/Table/Table";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { automationClientV2, companiesClientV2, emailsClientV2 } from "../../../db/version2Accessor";
import { Archive, Email, Export, Merge, NoteFill, Phone } from "../../library/Icons/Icons";
import { AlertContext } from "../../../contexts/AlertContext";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { FallbackTypes, ConnectionStatus, EmailAction, WorkspaceType } from "../../../types/enums";
import { ActivityContext } from "../../../contexts/ActivityContext";
import { CSVLink } from "react-csv";
import { formatDate } from "../../../db/utils/date";
import Utils from "../../../utils/utils";
import NewActivityPopup from "../../library/AddNewActivityDropdown/NewActivityPopup";
import { TemplateContext } from "../../../contexts/TemplateContext";
import { viewType } from "../../../app/Templates/TemplateTypes";
import { TemplateDefinitionProps } from "../../../app/Templates/TemplateFactory";
import { AppContext } from "../../../contexts/AppContext";
import { companiesClient } from "../../../db/accessor";
import { AxiosError } from "axios";
import { CONNECTION_STATUS } from "../../../constants/ConnectionConstants";
import { MergeFlyout } from "../CustomerActions/Merge/Index";
import { UseTableCellProps } from "react-table";
import TrackingUtils from "../../Tracking/Tracking.Utils";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import _ from "underscore";

const CustomerTable = (): React.ReactComponentElement<any> => {
  const { url, path } = useRouteMatch();
  const { configs } = useWorkspaceConfigurations();
  const params = new URLSearchParams(window.location.search);

  const pageNoFromUrl = params.get("page");
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "1");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
    } else {
      return pageNum;
    }
  });
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION_VALUES.PAGE_SIZE);
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const customerTableRef = useRef<TableHandle>(null);
  const { setToastOptions } = useContext(AlertContext) as AlertContextType;
  const { toggleConnectionUpdate, setCustomerId, viewDetails, setViewDetails, signature } = useContext(CustomerContext) as CustomerType;
  const { allRowsSelected, handleClose, setAllRowsSelected } = useContext(ActivityContext) as ActivityType;

  // New activity dropdown states
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [newActivityType, setNewActivityType] = useState<string>("");
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  // export states
  const [columnFilters, setColumnFilters] = useState<SearchFilter[]>([]);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [exportData, setExportData] = useState<any>([]);
  const csvInstance = useRef<any | null>(null);

  const { templateFactory, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { userStatus, getContactsOptions, allContactOptions, filterPrimaryContacts } = React.useContext(AppContext) as AppType;
  const [editorState, setEditorState] = useState<string>("");

  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [openMergeFlyout, setOpenMergeFlyout] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<TableData[]>([]);
  const [containERPConnection, setContainERPConnection] = useState<boolean>(false);
  const [companyList, setCompanyList] = React.useState<Partial<CompanyModel>[]>([]);
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [templateName, setTemplateName] = useState<string>("");

  const urlTokens = url.split("?")[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO].split("/");
  const isOtherConnection = path.includes("connections");
  const tableConfigs = configs[isOtherConnection ? "otherConnections" : "connections"];

  const userView = useRef<CustomerTableView>({
    view: urlTokens[NUMERIC_VALUES.CONSTANT_THREE],
    status: urlTokens[NUMERIC_VALUES.CONSTANT_FOUR] as ConnectionStatus,
  });

  const customerTable = tableConfigs.view.all;
  const status = (function getStatusForView() {
    if (viewDetails?.view !== userView.current.view) {
      return ConnectionStatus.ACTIVE;
    }
    return userView.current.status;
  })();

  useEffect(() => {
    setViewDetails({ view: userView.current.view, status });
  }, [userView.current]);

  const { updateBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;

  const fetchCompanyInfo = (companyId: UUID) => {
    if (!companyId) {
      return;
    }
    companiesClient.getCompany(companyId, "Classification").then((companyModel: CompanyModel) => {
      setCompanyData(companyModel);
    });
  };

  const fetchParser = (fetchResult: ConnectionsSummaryModelFetchResultV2, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: CustomerSummaryModelV2 | VendorSummaryModelV2) => {
        const response_keys = customerTable.column_response_key as ColumnResponseKey;
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.[response_keys.connection_id] as string,
          }),
          ...((variant === "export" || variant === "all") && {
            connection_name: record?.[response_keys.connection_name] as string,
            vendor_name: record?.vendor_name as string,
            primary_contact: record?.[response_keys.connection_primary_contact] as string,
            outstanding_receipt: record?.[response_keys.connections_outstanding_receipt_count] as number,
            outstanding_amount: record?.[response_keys.connections_outstanding_amount] as number,
            amount_dues: record?.[response_keys.connections_predicted_due] as number,
            last_activity_at: (record?.[response_keys.connections_last_activity] as number) || null,
            app_enrollment_id: record?.app_enrollment_id || null,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const getFilters = (exportConnection = false, ids?: string[]) => {
    const additionalFilters: { searchlightFilter: string }[] = [
      {
        searchlightFilter: `status=${status}`,
      },
      {
        searchlightFilter: `workspace_id=${selectedWorkspace?.id || FallbackTypes.Id}`,
      },
      {
        searchlightFilter: `qa[app_enrollment_id${isOtherConnection ? "" : "_not"}_null]=1`,
      },
    ];
    // evaluate export filters - consider ids only if all rows are not selected
    if (exportConnection && !allRowsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      ids.forEach((id: string) => {
        additionalFilters.push({
          searchlightFilter: selectedWorkspace?.workspace_type_route === WorkspaceType.AR ? `qa[company_id_in][]=${id}` : `qa[vendor_id_in][]=${id}`,
        });
      });
    }
    const filterQuery = TableUtils.getFilterFromURL(params, customerTable.columns);
    return TableUtils.columnFilterParser(filterQuery, true, additionalFilters ? [...additionalFilters] : undefined);
  };

  const fetchConnections = () => {
    const defaultSort = `${tableConfigs.defaultSortKey} ${tableConfigs.defaultSortToken}`;
    let sortQuery = TableUtils.getSortFromURL(params);
    if (!sortQuery || sortQuery === "") {
      sortQuery = defaultSort;
    }
    return companiesClientV2
      .getCustomerSummaries(getFilters(), TableUtils.sortQueryParser(sortQuery ?? "", true), pageSize, pageNumber)
      .then((fetchResult: any) => fetchResult);
  };

  // fetch
  const fetchCompanyList = async () => {
    try {
      const response = await companiesClientV2.getCustomerSummaries(
        `status=${ConnectionStatus.ACTIVE}&workspace_id=${selectedWorkspace?.id || FallbackTypes.Id}`,
        `qs[s]=${customerTable.column_response_key.connection_name}`,
        NUMERIC_VALUES.CONSTANT_TEN_THOUSAND,
        DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
      );
      setCompanyList(
        response.records?.map((company: CompanyModel) => {
          return {
            companyName: company[customerTable.column_response_key.connection_name as keyof typeof company],
            companyId: company[customerTable.column_response_key.connection_id as keyof typeof company],
          } as Partial<CompanyModel>;
        }) ?? []
      );
    } catch (error: unknown) {
      setToastOptions({ open: true, severity: "error", message: "Failed to fetch companies" });
    }
  };

  const handleRowClick = (
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) => {
    sessionStorage.setItem("lastPath", history.location.pathname);
    sessionStorage.setItem("lastPage", pageNumber.toString());
    history.push(`${path}/${row.original.id ?? ""}`);
    updateBaseRoute(`${path}/${row.original.id ?? ""}`);
    setCustomerId(row.original?.id ?? "");
  };

  useEffect(() => {
    fetchCompanyInfo(userStatus.account_company_id as string);
    fetchCompanyList();
    return () => {
      setAllRowsSelected(false);
      setTotalCount(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    };
  }, []);

  useEffect(() => {
    if (
      (exportData?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setExportData("");
      });
    }
  }, [exportData]);

  /**
   * triggers on row select or de-select, checks whether selected records has
   * any ERP connection in them. if yes, set flag for ERP connection to true.
   * This flag is used to enable or disable the merge icon and update it's tooltip
   * text.
   */
  useEffect(() => {
    const erpConnections = selectedRowData?.filter((item) => item.app_enrollment_id && item.app_enrollment_id !== null);
    setContainERPConnection(erpConnections.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  }, [selectedRowData]);

  const getColumnFilterPayloadObject = (filters: SearchFilter[]) => {
    const filterPayload = { qa: {} } as Record<string, Record<string, string>>;
    if (filters && filters.length) {
      filters.forEach((filter: SearchFilter) => {
        filterPayload["qa"][`${filter.filterKey}_${filter.filterToken}`] = `${filter.filterValue}`;
      });
    }
    return filterPayload;
  };

  const attachSelectedAllPayload = (payload: UpdateCustomerStatusPayload) => {
    let bulkPayload = {
      ...payload,
      select_all: true,
      filter_status: status,
    };
    bulkPayload.connection_ids = []; // make ids array blank
    bulkPayload.qa = {
      [`app_enrollment_id${isOtherConnection ? "" : "_not"}_null`]: 1,
    };
    if (columnFilters && columnFilters.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      bulkPayload = {
        ...bulkPayload,
        ...getColumnFilterPayloadObject(columnFilters),
      };
    }
    return bulkPayload;
  };

  const handleConnectionStatusUpdate = async (ids: string[], action: string) => {
    let response: APIResponse = {} as APIResponse;
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "" };

    const createMessage = (actionTaken: string, noOfRecords: number) => {
      return noOfRecords > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? `${noOfRecords} connections are marked as ${actionTaken} successfully`
        : `Connection is marked as ${actionTaken} successfully`;
    };

    let payload = {
      connection_ids: ids,
      status: action,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      filter_status: status,
    };
    payload = allRowsSelected ? attachSelectedAllPayload(payload) : payload;
    try {
      if (action === ConnectionStatus.ARCHIVED) {
        response = await companiesClientV2.createArchiveConnection(payload);
        toastOptions = {
          ...toastOptions,
          message: createMessage(ConnectionStatus.ARCHIVED, ids.length),
        };
      } else {
        response = await companiesClientV2.updateCustomer(payload);
        toastOptions = {
          ...toastOptions,
          message: createMessage(action, ids.length),
        };
      }
    } catch (error) {
      console.log("error:: ", error);
    } finally {
      if (!response.success) {
        toastOptions = { ...toastOptions, severity: "error", message: "Something went wrong!" };
      }
      setToastOptions(toastOptions);
      await customerTableRef.current?.softRefresh();
      if (response.success) {
        toggleConnectionUpdate();
      }
    }
  };

  const handleExportCustomer = async (ids: string[]) => {
    let response: APIResponse = {} as APIResponse;
    try {
      setExportLoading(true);
      response = await companiesClientV2.exportConnections(getFilters(true, ids));
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setExportData(response);
      setExportLoading(false);
      customerTableRef.current?.softRefresh();
    }
  };

  const handleConnectionMerge = async (ids: string[]) => {
    setSelectedRowIds(ids);
    setOpenMergeFlyout(true);
  };

  const markArchiveRowButton = {
    icon: <Archive />,
    variant: "solo-icon",
    alignIcon: "left",
    tooltip: "Archive Connection",
    style: { border: "0.0625rem black" },
    callback: async (ids: string[]) => {
      await handleConnectionStatusUpdate(ids, ConnectionStatus.ARCHIVED);
    },
  } as RowSelectButtons;

  const markActiveRowButton = {
    variant: "secondary",
    children: (function getLabel() {
      return status === ConnectionStatus.ARCHIVED ? "Unarchive" : status === ConnectionStatus.FRAUD ? "Not Fraud" : "Not Spam";
    })(),
    callback: async (ids: string[]) => {
      await handleConnectionStatusUpdate(ids, ConnectionStatus.ACTIVE);
    },
  } as RowSelectButtons;

  const rowSelectHeaderBtn = [
    {
      variant: "primary",
      children: "Export",
      icon: <Export />,
      alignIcon: "left",
      type: "button",
      loading: exportLoading,
      callback: (ids: string[]) => {
        handleExportCustomer(ids);
      },
    },
  ] as RowSelectButtons[];

  const mergeConnectionButton = {
    icon: <Merge />,
    variant: "solo-icon",
    alignIcon: "left",
    tooltip: containERPConnection ? "ERP-created Connections cannot be merged" : "Merge Connection",
    style: { border: "0.0625rem black" },
    disabled: containERPConnection,
    size: "lg",
    callback: async (ids: string[]) => {
      await handleConnectionMerge(ids);
    },
  } as RowSelectButtons;

  const isNotActive = [ConnectionStatus.ARCHIVED, ConnectionStatus.FRAUD, ConnectionStatus.SPAM].includes(status as ConnectionStatus);

  const rowSelectBtns: RowSelectButtons[] = [
    ...(isNotActive ? [markActiveRowButton] : ["divider", markArchiveRowButton, ...(isOtherConnection ? [mergeConnectionButton] : [])]),
  ];

  /**
   * @function getCompanyIdForFirstTo
   * A helper function to get the company id for the first contact selected
   * @param to - Contacts
   * @returns
   */
  const getCompanyIdForFirstTo = (to: To[]) => {
    if (to && to.length) {
      return to[0].companyId;
    }
    return "";
  };

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[],
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "Messages Sent" };
    const payload: Partial<ActivityItemEmailModel> = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      contact_company_id: getCompanyIdForFirstTo(to),
      content_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "input_email"
          : newActivityType && newActivityType === "email"
          ? "input_email"
          : newActivityType,
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "email"
          : newActivityType,
      activity_transactions: inboxAttachments,
      primary_connection_id: selectedCompany.id,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, inboxAttachments),
    };

    return new Promise((resolve) => {
      emailsClientV2
        .post(payload)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;

            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(
            to,
            "New Activity Stream",
            "New",
            isSendAndClose ?? false,
            allContactOptions,
            tableConfigs.view.all.tableConfig.displayName,
            path,
            newActivityType ?? ""
          );
          setShowActivity(false);
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: "Activity Closed" };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: "Activity was not closed",
          };
        }
        setToastOptions(toastOptions);
      }
    }
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = (templateID: string | null, setEditorState: React.Dispatch<React.SetStateAction<string>>) => {
    if (templateID) {
      setTemplateName(templateID);
      const templateObj = templateData.get(selectedWorkspace?.id).get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: selectedCompany?.connection_name || "",
          pastDueAmount: Utils.formatValueAsCurrency(
            selectedCompany.amount_dues ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          accountTotalBalance: Utils.formatValueAsCurrency(
            selectedCompany.outstanding_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          signature: signature?.email_signature || "",
        })
      );
    }
  };

  /**
   *
   * @param customerId
   */
  const fetchCompanyContactOptions = async (customerId: string) => {
    setCompanyContactOptions(await getContactsOptions(customerId));
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (row: any): TemplateDefinitionProps[] => {
    // assign number outside the viewType enum range
    let view: viewType = 100;

    if (userView.current.view === "customers") {
      view = viewType.AR_ALL_CUSTOMER_CONNECTIONS;
    } else if (userView.current.view === "vendors") {
      view = viewType.AP_ALL_VENDOR_CONNECTIONS;
    }
    return templateFactory.getTemplateDefinitionsByView(view, { invoiceNumber: row.invoice_number, customer: row.connection_name });
  };

  /**
   * Event handler which triggered when user select the 'email', 'note' or 'phone call'
   * context menu item. It will open the new activity dialog based to the context menu item
   * selected by the user.
   *
   * @param {Object} data The data of selected row from the table.
   * @param {String} buttonId The buttonId indicate which button has been selected by the user.
   */
  const onClickActionButton = async (data: any, buttonId: string) => {
    setSelectedCompany(data);
    if (buttonId === "email") {
      setSupportedTemplateList(getTemplateDefinitionsByView(data));
    }

    if (buttonId !== "note") {
      await fetchCompanyContactOptions(data.id);
    }
    setNewActivityType(buttonId);
    setShowActivity(true);
  };

  const options = {
    spam: [
      {
        value: "Not Spam",
        id: "notSpam",
        clickFunction: (data: TableData) => {
          handleConnectionStatusUpdate([data.id], ConnectionStatus.ACTIVE);
        },
      },
    ],
    fraud: [
      {
        value: "Not Fraud",
        id: "notFraud",
        clickFunction: (data: TableData) => {
          handleConnectionStatusUpdate([data.id], ConnectionStatus.ACTIVE);
        },
      },
    ],
    archived: [
      {
        value: "Unarchive",
        id: "new",
        clickFunction: (data: TableData) => {
          handleConnectionStatusUpdate([data.id], ConnectionStatus.ACTIVE);
        },
      },
    ],
    active: [
      {
        value: "New Email",
        id: "email",
        clickFunction: onClickActionButton,
      },
      {
        value: "New Note",
        id: "note",
        clickFunction: onClickActionButton,
      },
      {
        value: "New Phone Call",
        id: "phone_call",
        clickFunction: onClickActionButton,
      },
    ],
  };

  const actionItems = options[status as keyof typeof options];

  // table configuration
  const cellRendererMapping: CellRendererMappingObject = {
    "table.formatConnectionType": (data: UseTableCellProps<TableData>) => TableUtils.formatString(data.value, false),
    "table.formatString": (data: { value: string }) => TableUtils.formatString(data.value, false),
    "table.formatNumber": (data: { value: number | null }) => TableUtils.formatNumber(data.value, true),
    "table.formatCurrency": (data: { value: number | null }) =>
      TableUtils.formatCurrency(data.value, true, userStatus?.currency?.locale, userStatus?.currency?.code),
    "table.formatAction": (data: { value: string | null; row: { original: { status: string } } }) => {
      return TableUtils.formatAction(data.row.original, actionItems);
    },
    "table.formatDate": (data: { value: number | null }) => (
      <p className="last_activity_data">{data.value ? formatDate(Utils.evaluateDate(data.value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)) : "N/A"}</p>
    ),
  };

  const primaryContact = filterPrimaryContacts(companyContactOptions);

  const COLUMNS = customerTable.columns.map((cell: ColumnHeaderType) => ({ ...cell, Cell: cellRendererMapping[cell.CellRenderer] }));
  return (
    <>
      <Table
        ref={customerTableRef}
        dataSets={[
          {
            id: customerTable.tableConfig.displayName,
            displayName: customerTable.tableConfig.displayName,
            rowSelectToken: customerTable.tableConfig.rowSelectToken,
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: fetchConnections,
              fetchParser: fetchParser,
            },
            columns: COLUMNS,
            export: {
              exportFileName: customerTable.tableConfig.exportFileName,
            },
            handleRowClick: handleRowClick,
            setSelectedRowData,
          },
        ]}
        states={{
          isLoading: isLoading,
          setLoading: setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: totalCount,
          setTotalCount: setTotalCount,
          startingIndex: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
          setPageSize: setPageSize,
        }}
        toggles={{
          showSearchbar: false,
          showExportBtn: false,
          showCaption: true,
          showRowSelect: true,
          showNavigation: true,
          showSelectAllBar: true,
        }}
        rowSelectHeaderBtns={rowSelectHeaderBtn}
        rowSelectBtns={rowSelectBtns}
        connectionStatus={status}
        useV2ColumnFilters={true}
        isColumnFixed
        setColumnFilters={setColumnFilters}
      />
      {showActivity && (
        <NewActivityPopup
          key={`key-add-activity-${newActivityType}`}
          title={"New Activity"}
          open={showActivity}
          contactOptions={allContactOptions}
          defaultTo={_.isEmpty(primaryContact) ? [companyContactOptions[0]] : primaryContact}
          handleSend={handleSend}
          isTemplateSupport={newActivityType === "email" && supportedTemplateList.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}
          supportedTemplateList={supportedTemplateList}
          editorState={editorState}
          handleSendMarkClosed={handleSendAndMarkClosed}
          onClose={() => {
            setShowActivity(false);
            setEditorState("");
          }}
          activityType={newActivityType}
          setNewActivityType={(type) => {
            setNewActivityType(type);
          }}
          addActivityDropdown={[
            { displayName: "Email", icon: <Email /> },
            { displayName: "Note", icon: <NoteFill /> },
            { displayName: "Phone Call", icon: <Phone /> },
          ]}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
        />
      )}
      {(exportData?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
        <CSVLink data={exportData} filename={customerTable.tableConfig.exportFileName} ref={csvInstance} style={{ display: "none" }} />
      ) : undefined}
      {openMergeFlyout && (
        <MergeFlyout
          connectionIds={selectedRowIds}
          companyList={companyList}
          open={openMergeFlyout}
          setConnectionIds={setSelectedRowIds}
          setOpen={setOpenMergeFlyout}
          softRefresh={customerTableRef.current?.softRefresh}
        />
      )}
    </>
  );
};

export default CustomerTable;
