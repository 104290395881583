import React, { useState } from "react";
import { AssignTo, MarkAsUnread, Close, Spam, MoveTo, Snoozed, SnoozedToast, Ellipses, Calendar } from "../../../library/Icons/Icons";
import Button from "../../../library/Button/Button";
import { Row } from "react-table";
import ActivityReassign from "../../ActivityDetail/ActivityFeed/ActivityFeedActions/ActivityReassign";
import "./Hoverbar.scss";
import { AlertContext } from "../../../../contexts/AlertContext";
import { useRouteMatch } from "react-router-dom";
import { ActivityActionTypes, ActivityStatus, FallbackTypes } from "../../../../types/enums";
import { activitiesClientV2 } from "./../../../../db/version2Accessor";
import { ClickAwayListener, Tooltip } from "@mui/material";
import MenuItem from "../../../library/MenuItem/MenuItem";
import { makeStyles } from "@mui/styles";
import { SNOOZE_LIST } from "../../../../constants/ListConstants";
import ActivityMove from "../../ActivityDetail/ActivityFeed/ActivityFeedActions/ActivityMove";
import { NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { ActivityActionPerformStatus, ActivityContext } from "../../../../contexts/ActivityContext";

type hoverBarProps = {
  isSnoozed?: boolean;
  barriers?: boolean;
  row: Row<TableData>;
  softRefresh: () => Promise<TableData[] | undefined>;
  activityHandler?: (row: Row<TableData>, requireTemplates?: boolean) => void;
};

export default function HoverBar(props: hoverBarProps): React.ReactElement {
  const { path } = useRouteMatch();
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const [moveDrawerOpen, setMoveDrawerOpen] = useState<boolean>(false);
  const [reassignDrawerOpen, setReassignDrawerOpen] = useState<boolean>(false);
  const [snoozeMenuOpen, setSnoozeMenuOpen] = useState<boolean>(false);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { activityPerformInProgress, activityActionInProgress, activityCloseAction } = React.useContext(ActivityContext) as ActivityType;

  const TooltipToggle = () => {
    setSnoozeMenuOpen(!snoozeMenuOpen);
  };

  const useStyles = makeStyles(() => ({
    tooltip: {
      background: "white !important",
      margin: "0 !important",
      padding: "0 !important",
      borderRadius: "0.125rem",
      boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
    },
  }));

  const generateMenuList = (menuList: Array<any>) => {
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "Activity marked as unread" };
    return menuList.map((item, index) => {
      return (
        <MenuItem
          key={index}
          onClick={async (e) => {
            TooltipToggle();
            e.stopPropagation();
            let response = {} as APIResponse;
            const now = Math.floor(Date.now() / NUMERIC_VALUES.CONSTANT_THOUSAND);
            try {
              response = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, props.row.id, ActivityActionTypes.Status, {
                status: ActivityStatus.Snoozed.toLocaleLowerCase() as Status,
                snooze_until: now + item.valueInEpoch,
              });
              if (response.success) {
                setToastOptions({
                  ...toastOptions,
                  message: `Activity Snoozed for ${item.id}`,
                  icon: <SnoozedToast />,
                });
                props.softRefresh();
              }
            } catch (error) {
              response.success = false;
              setToastOptions({
                ...toastOptions,
                severity: "error",
                message: "Activity was not Snoozed",
                icon: <SnoozedToast />,
              });
              console.log(error);
            }
          }}
          id={item.id}
        >
          {item.value}
        </MenuItem>
      );
    });
  };

  const handleToggleUnread = async () => {
    const originalReadStatus = props.row.original.read;
    let toastOptions: ToastOptions = { open: true, severity: "success", message: `Activity ${!originalReadStatus ? "Read" : "Unread"}` };
    let response = {} as APIResponse;
    try {
      response = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, props.row.id, ActivityActionTypes.Read, {
        read: !originalReadStatus,
      });
    } catch {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = { ...toastOptions, severity: "error", message: `Activity was not ${!originalReadStatus ? "read" : "unread"}` };
      }
      setToastOptions(toastOptions);
      if (response.success) props.softRefresh();
    }
  };

  const handleSpam = async () => {
    let toastOptions: ToastOptions = { open: true, severity: "success", message: `Activity status is changed to Spam` };
    let response = {} as APIResponse;
    try {
      response = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, props.row.id, ActivityActionTypes.Spam, { spam: true });
    } catch {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = { ...toastOptions, severity: "error", message: `Something went wrong` };
      }
      setToastOptions(toastOptions);
      if (response.success) props.softRefresh();
    }
  };

  const classes = useStyles();
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={props.barriers ? "hoverbar-container" : "hoverbar-container in-table"}
      style={{ width: props?.barriers ? "22.5rem" : "20.625rem" }}
    >
      <Button
        icon={<AssignTo />}
        variant="transparent"
        alignIcon="left"
        onClick={(e) => {
          setSnoozeMenuOpen(false);
          e.stopPropagation();
          setReassignDrawerOpen(true);
        }}
        className="btn-icon-only"
        tooltip="Assign"
        disabled={activityActionInProgress}
        loading={(activityPerformInProgress as ActivityActionPerformStatus).Assign}
      />
      {!path.toLowerCase().includes("/closed") && (
        <Button
          icon={<Close />}
          variant="transparent"
          alignIcon="left"
          onClick={(e) => {
            setSnoozeMenuOpen(false);
            e.stopPropagation();
            activityCloseAction([props.row.id], props.softRefresh);
          }}
          className="btn-icon-only"
          tooltip="Close"
          disabled={activityActionInProgress}
          loading={(activityPerformInProgress as ActivityActionPerformStatus).Close}
        />
      )}
      <Button
        icon={<MarkAsUnread />}
        variant="transparent"
        alignIcon="left"
        onClick={(e) => {
          setSnoozeMenuOpen(false);
          e.stopPropagation();
          handleToggleUnread();
        }}
        className="btn-icon-only"
        tooltip={!props.row.original.read ? "Mark as read" : "Mark as unread"}
        disabled={activityActionInProgress}
        loading={(activityPerformInProgress as ActivityActionPerformStatus).Read}
      />

      {!path.toLowerCase().includes("/snoozed") && !props.isSnoozed && (
        <ClickAwayListener onClickAway={() => setSnoozeMenuOpen(false)}>
          <Tooltip
            title={generateMenuList(SNOOZE_LIST)}
            classes={{ tooltip: classes.tooltip }}
            placement="bottom-start"
            open={snoozeMenuOpen}
            disableHoverListener
          >
            <span>
              <Button
                icon={<Snoozed />}
                variant={"transparent"}
                alignIcon={"left"}
                tooltip="Snooze"
                className={"btn-icon-only"}
                onClick={(e) => {
                  e.stopPropagation();
                  TooltipToggle();
                }}
                disabled={activityActionInProgress}
                loading={(activityPerformInProgress as ActivityActionPerformStatus).Snooze}
              />
            </span>
          </Tooltip>
        </ClickAwayListener>
      )}

      <Button
        icon={<MoveTo />}
        variant="transparent"
        alignIcon="left"
        onClick={(e) => {
          setSnoozeMenuOpen(false);
          e.stopPropagation();
          setMoveDrawerOpen(true);
        }}
        className="btn-icon-only"
        tooltip="Move"
        disabled={activityActionInProgress}
        loading={(activityPerformInProgress as ActivityActionPerformStatus).Move}
      />
      {path.includes("/waitingForResponse") ? null : props.row.original.spam ? null : (
        <Button
          icon={<Spam />}
          variant="transparent"
          alignIcon="left"
          onClick={(e) => {
            setSnoozeMenuOpen(false);
            e.stopPropagation();
            handleSpam();
          }}
          className="btn-icon-only"
          tooltip={"Mark as spam"}
          disabled={activityActionInProgress}
          loading={(activityPerformInProgress as ActivityActionPerformStatus).Spam}
        />
      )}

      {path.includes("/waitingForResponse") ? (
        <Button
          variant="primary"
          alignIcon="left"
          onClick={(e) => {
            setSnoozeMenuOpen(false);
            e.stopPropagation();
            if (props.activityHandler) {
              props.activityHandler(props.row, false);
            }
          }}
          className="btn-icon-only"
          tooltip={"Nudge Activity"}
          disabled={activityActionInProgress}
        >
          <Calendar></Calendar>
        </Button>
      ) : null}

      {(path.includes("/customers") || path.includes("/vendors")) && (
        <Button
          variant="primary"
          alignIcon="left"
          onClick={(e) => {
            setSnoozeMenuOpen(false);
            e.stopPropagation();
            if (props.activityHandler) {
              props.activityHandler(props.row, true);
            }
          }}
          className="btn-icon-only"
          tooltip={"New Activity"}
          disabled={activityActionInProgress}
        >
          <Ellipses></Ellipses>
        </Button>
      )}
      <ActivityReassign
        open={reassignDrawerOpen}
        onClose={() => setReassignDrawerOpen(false)}
        activityIds={[props.row.id]}
        onCallback={() => props.softRefresh()}
        disablePortal={false}
        onClick={(e) => e.stopPropagation()}
      />
      {props.barriers ? <div className="barrier">|</div> : null}

      {props.barriers ? <div className="barrier">|</div> : null}

      <ActivityMove
        open={moveDrawerOpen}
        onClose={() => setMoveDrawerOpen(false)}
        activityIds={[props.row.id]}
        onCallback={() => props.softRefresh()}
        disablePortal={false}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  );
}
