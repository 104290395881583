import React from "react";
import Utils from "../../../../../utils/utils";
import { AlertContext } from "../../../../../contexts/AlertContext";
import { ContactFormComponent } from "./ContactFormComponent";
import { DataMode } from "../../../../../types/enums";
import { contactsClientV2 } from "../../../../../db/version2Accessor";
import { WorkspaceContext } from "../../../../../contexts/WorkspaceContext";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { AppContext } from "../../../../../contexts/AppContext";

type newContactProps = {
  dataMode: DataMode;
  customerId: string;
  setAddContactFlyout: React.Dispatch<React.SetStateAction<boolean>>;
  flyoutState: boolean;
  tableData: TableData[];
  isLoadingRoleList: boolean;
  roleList: CodeDefinitionModel[];
  contactEditData: ContactDataType | null;
  setDataMode: React.Dispatch<React.SetStateAction<DataMode>>;
  markPrimaryContact?: (row: ContactDataType) => void;
  isContactAlreadyPrimary?: boolean;
};
export const ContactFormContainer = (props: newContactProps) => {
  // form states
  const [newContactName, setNewContactName] = React.useState<string>("");
  const [newContactRole, setNewContactRole] = React.useState<string>("");
  const [newContactEmail, setNewContactEmail] = React.useState<string>("");
  const [newContactPhone, setNewContactPhone] = React.useState<string>("");
  const [newContactPrimary, setNewContactPrimary] = React.useState<boolean>(false);
  const [addContactNameError, setAddContactNameError] = React.useState<string>("");
  const [addContactEmailError, setAddContactEmailError] = React.useState<string>("");
  const [addContactRoleError, setAddContactRoleError] = React.useState<string>("");
  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { getContactsOptions } = React.useContext(AppContext) as AppType;

  // handlers
  const resetFormStates = () => {
    setAddContactEmailError("");
    setAddContactNameError("");
    setNewContactName("");
    setNewContactEmail("");
    setNewContactPhone("");
    setNewContactRole("Unknown");
    setNewContactPrimary(false);
    props.setAddContactFlyout(false);
    props.setDataMode(DataMode.View);
  };

  const validateFormInput = () => {
    let isValid = true;
    isValid = !!newContactName;
    setAddContactNameError(!newContactName ? "NO NAME GIVEN" : "");
    isValid = !!newContactRole;
    setAddContactRoleError(!newContactRole ? "NO ROLE GIVEN" : "");
    if (!newContactEmail) {
      isValid = false;
      setAddContactEmailError("NO EMAIL GIVEN");
    } else if (!Utils.validEmail(newContactEmail)) {
      isValid = false;
      setAddContactEmailError("INVALID EMAIL GIVEN");
    } else if (
      props.dataMode === DataMode.View &&
      props.tableData.some((elem: TableData) => elem.email?.toLowerCase?.() === newContactEmail?.toLowerCase?.())
    ) {
      isValid = false;
      setAddContactEmailError("THERE IS A CONTACT WITH THIS EMAIL ADDRESS");
    } else if (
      props.dataMode === DataMode.Edit &&
      props.tableData
        .filter((item: TableData) => {
          if (item.id !== props.contactEditData?.contactId) {
            return item;
          }
        })
        .some((elem: TableData) => elem.email?.toLowerCase?.() === newContactEmail?.toLowerCase?.())
    ) {
      isValid = false;
      setAddContactEmailError("THERE IS A CONTACT WITH THIS EMAIL ADDRESS");
    } else {
      setAddContactEmailError("");
    }
    return isValid;
  };

  const handleAddContact = async () => {
    if (!validateFormInput()) {
      return;
    }
    setShowLoader(true);
    let toastOptions: ToastOptions = { severity: "error", message: "Something Went Wrong", open: true };
    try {
      const response = await contactsClientV2.createContact(
        {
          items: [
            {
              company_id: props.customerId,
              contact_name: newContactName,
              role_code: newContactRole,
              email_address: newContactEmail,
              phone: newContactPhone,
              is_active: true,
              title: "",
            },
          ],
        },
        selectedWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
      );
      // show toast that contact is created
      toastOptions = { ...toastOptions, severity: "success", message: "Contact is created successfully" };
      // make call to mark the contact as primary
      if (newContactPrimary && props.markPrimaryContact) {
        props.markPrimaryContact({ ...response.data[0], contactId: response.data[0].contact_id, contactName: response.data[0].contact_name });
      }
    } catch (error: any) {
      const errorResponse = error.response as AxiosErrorResponseData;
      toastOptions = { ...toastOptions, severity: "error", message: errorResponse?.data?.messages?.errors[0] ?? "Something Went Wrong", open: true };
    } finally {
      // reset-states
      resetFormStates();
      setToastOptions(toastOptions);
      setShowLoader(false);
      if (!newContactPrimary) {
        getContactsOptions();
      }
    }
  };

  const handleEditContact = async () => {
    if (!validateFormInput()) {
      return;
    }
    setShowLoader(true);
    let toastOptions: ToastOptions = { severity: "error", message: "Something Went Wrong", open: true };
    try {
      await contactsClientV2.updateContacts(
        {
          items: [
            {
              contact_name: newContactName,
              role_code: newContactRole,
              email_address: newContactEmail,
              phone: newContactPhone,
              is_primary: false,
              contact_id: props.contactEditData?.contactId ?? "",
              title: "",
            },
          ],
        },
        selectedWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
      );
      toastOptions = { ...toastOptions, severity: "success", message: "Contact is updated successfully" };
      // make call to mark the contact as primary
      if (newContactPrimary && !props.contactEditData?.contactIsPrimary && props.markPrimaryContact) {
        props.markPrimaryContact(props.contactEditData ?? ({} as ContactDataType));
      }
    } catch (error: any) {
      const errorResponse = error.response as AxiosErrorResponseData;
      toastOptions = { ...toastOptions, severity: "error", message: errorResponse?.data?.messages?.errors[0] ?? "Something Went Wrong", open: true };
    } finally {
      // reset-states
      resetFormStates();
      setShowLoader(false);
      setToastOptions(toastOptions);
      if (!newContactPrimary || props.isContactAlreadyPrimary) {
        getContactsOptions();
      }
    }
  };

  // reset form state once flyout closes
  React.useEffect(() => {
    if (!props.flyoutState) {
      resetFormStates();
    }
    if (props.dataMode === DataMode.Edit) {
      setNewContactName(props.contactEditData?.contactName ?? "");
      setNewContactRole(props.contactEditData?.contactRole ?? "");
      setNewContactEmail(props.contactEditData?.contactEmail ?? "");
      setNewContactPhone(props.contactEditData?.contactPhone ?? "");
      setNewContactPrimary(props.contactEditData?.contactIsPrimary ?? false);
    }
  }, [props.flyoutState, props.dataMode]);

  return (
    <ContactFormComponent
      newContactName={newContactName}
      newContactRole={newContactRole}
      newContactEmail={newContactEmail}
      newContactPhone={newContactPhone}
      newContactPrimary={newContactPrimary}
      addContactNameError={addContactNameError}
      addContactEmailError={addContactEmailError}
      addContactRoleError={addContactRoleError}
      setNewContactName={setNewContactName}
      setNewContactRole={setNewContactRole}
      setNewContactEmail={setNewContactEmail}
      setNewContactPhone={setNewContactPhone}
      setNewContactPrimary={setNewContactPrimary}
      handleAddContact={handleAddContact}
      handleEditContact={handleEditContact}
      isLoadingRoleList={props.isLoadingRoleList}
      roleList={props.roleList}
      resetFormStates={resetFormStates}
      dataMode={props.dataMode}
      showLoader={showLoader}
    />
  );
};
