import React from "react";
import RelatedTransactionItem from "../../Activities/ActivityDetail/ActivityTab/RelatedTransactions/RelatedTransactionItem";
import { TransactionType } from "../../../types/enums";
import { Invoices, Payments } from "../../library/Icons/Icons";
import { RelatedBillItem, RelatedInvoiceItem, RelatedPaymentItem } from "../../Activities/ActivityDetail/ActivityTab/ActivityTabInterfaces";
import "./../../Activities/ActivityDetail/ActivityTab/RelatedTransactions/RelatedTransactions.scss";
import { ApprovalRelatedTransactions as ApprovalRelatedTransactionsProps } from "../ApprovalsInterfaces";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const ApprovalRelatedTransactions: React.FC<ApprovalRelatedTransactionsProps> = ({ invoices, payments, bills }) => {
  const transactionInfoMeta = {
    invoices: {
      due_date: { label: "Due Date", type: "date" },
      total_amount: { label: "Total Amount", type: "currency" },
      outstanding_balance: { label: "Outstanding Balance", type: "currency" },
    },
    payments: {
      payment_date: { label: "Payment Date", type: "date" },
      total_amount: { label: "Total Amount", type: "currency" },
      unapplied_amount: { label: "Unapplied Amount", type: "currency" },
      type: { label: "Type", type: "string" },
    },
    bills: {
      due_date: { label: "Due Date", type: "date" },
      total_amount: { label: "Total Amount", type: "currency" },
      outstanding_balance: { label: "Outstanding Balance", type: "currency" },
    },
  };

  return (
    <div className="rt-container">
      {invoices && payments && bills ? <div className="no-approval-data-wrapper">
            <h4 className="text">No Available Transactions</h4>
          </div>: 
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: true, overflowBehavior: { x: "hidden" } }}>
        {invoices?.map((data: RelatedInvoiceItem) => {
          return (
            <RelatedTransactionItem
              readonly
              item={{ ...data, transactionType: TransactionType.Invoice, transactionId: data.invoice_id }}
              key={data.invoice_number}
              label={`Invoice #${data.invoice_number}`}
              meta={transactionInfoMeta.invoices}
              icon={<Invoices />}
              buttonLabel={"View Invoice"}
            />
          );
        })}
        {bills?.map((data: RelatedBillItem) => {
          return (
            <RelatedTransactionItem
              readonly
              item={{ ...data, transactionType: TransactionType.Invoice, transactionId: data.bill_id }}
              key={data.bill_id}
              label={`Bill #${data.bill_number}`}
              meta={transactionInfoMeta.invoices}
              icon={<Invoices />}
              buttonLabel={"View Bill"}
            />
          );
        })}
        {payments?.map((data: RelatedPaymentItem) => {
          return (
            <RelatedTransactionItem
              readonly
              item={{ ...data, transactionType: TransactionType.Payment, transactionId: data.payment_id }}
              key={data.payment_id}
              label={`Payment ${data.payment_number}`}
              meta={transactionInfoMeta.payments}
              icon={<Payments />}
              buttonLabel={"View Payment"}
            />
          );
        })}
      </OverlayScrollbarsComponent>
}
    </div>
  );
};

export default ApprovalRelatedTransactions;
