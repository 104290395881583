import React, { useState, MouseEvent, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router";
import Table from "../../../../library/Table/Table";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import { FallbackTypes, InvoiceStatusType, WorkspaceType } from "../../../../../types/enums";
import { DAYS_CONSTANTS, DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES, NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { getFormattedDate } from "../../../../../db/utils/date";
import { DocumentSwitchContext } from "../../../../../contexts/DocumentSwitchContext";
import { DateTime } from "luxon";
import { ActivityContext } from "../../../../../contexts/ActivityContext";
import { companiesClientV2, invoicesClientV2, PaymentsClientV2 } from "../../../../../db/version2Accessor";
import { WorkspaceContext } from "../../../../../contexts/WorkspaceContext";
import { AppContext } from "../../../../../contexts/AppContext";
import Utils from "../../../../../utils/utils";

type PaidComponentType = {
  reportDate: string;
};

export default function Paid({ reportDate }: PaidComponentType): React.ReactElement<PaidComponentType> {
  const { setEnableDocumentSwitch, setSelectedActivityStreamId, setActivityStreamIds, setCurrentPaginationState, setDocumentType } = React.useContext(
    DocumentSwitchContext
  ) as DocumentSwitchType;
  const history = useHistory();
  const { allRowsSelected } = React.useContext(ActivityContext) as ActivityType;
  const { path } = useRouteMatch();
  const params = new URLSearchParams(window.location.search);
  const today = new Date();
  const past30Date = new Date(new Date().setDate(today.getDate() - DAYS_CONSTANTS.MONTH));
  const pageNoFromUrl = params.get("page");
  const pageSizeFromUrl = params.get("pageSize");
  const dataSetIndex = parseInt(params.get("dataSetIndex") ?? "0", 10);
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "1");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
    } else {
      return pageNum;
    }
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const { setPlatformPageFetchParams } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION_VALUES.PAGE_SIZE);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { userStatus } = React.useContext(AppContext) as AppType;

  const VENDORCOLUMNS = useMemo(
    () => [
      {
        Header: "Vendor Name",
        accessor: "vendor_name",
        width: "19.5%",
        showFilter: true,
        popupPlaceholder: "ex. Blushing Blooms",
        showSort: true,
        searchlightField: "vendor_name",
        sortField: "VENDORNAME",
        searchlightToken: "contains",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Primary Contact",
        accessor: "primary_contact",
        width: "18.75%",
        showFilter: true,
        popupPlaceholder: "ex. John Doe",
        showSort: true,
        searchlightField: "primary_contact_name",
        sortField: "PRIMARYCONTACTNAME",
        searchlightToken: "contains",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        width: "15%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "amount_paid_past_thirty_days",
        sortField: "AMOUNTPAIDPASTTHIRTYDAYS",
        searchlightToken: "lteq",
        alignment: "right",
        filterAnchorPosition: "center",
        Cell: (props: { value: number | null }) =>
          TableUtils.formatCurrency(props.value, true, userStatus?.currency?.locale, userStatus?.currency?.code),
      },
      {
        Header: "Advance Pmt Amt",
        accessor: "advance_payment_amount",
        width: "18.75%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "advance_pay_past_thirty_days",
        sortField: "ADVANCEPAYPASTTHIRTYDAYS",
        searchlightToken: "lteq",
        alignment: "right",
        filterAnchorPosition: "center",
        Cell: (props: { value: number | null }) =>
          TableUtils.formatCurrency(props.value, true, userStatus?.currency?.locale, userStatus?.currency?.code),
      },
      {
        Header: "Bills Paid",
        accessor: "bills_paid",
        width: "15%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "paid_bill_count_past_thirty_days",
        sortField: "PAIDBILLCOUNTPASTTHIRTYDAYS",
        searchlightToken: "eq",
        alignment: "right",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, true),
      },
      {
        Header: "Last Activity",
        accessor: "last_activity",
        width: "15%",
        showFilter: false,
        popupPlaceholder: "ex. yyyy-mm-dd",
        showSort: false,
        searchlightField: "NEWESTACTIVITY",
        sortField: "NEWESTACTIVITY",
        searchlightToken: "CONTAINS",
        alignment: "left",
        filterAnchorPosition: "left",
        Cell: (props: { value: string | null }) => TableUtils.formatLastActivity(props.value, false),
      },
    ],
    [userStatus]
  );

  const PAYMENTSCOLUMNS = useMemo(
    () => [
      {
        Header: "Payment Number",
        accessor: "payment_number",
        width: "22.5%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "reference_code",
        sortField: "reference_code",
        searchlightToken: "cont_any",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Vendor Name",
        accessor: "vendor_name",
        width: "17.5%",
        showFilter: true,
        popupPlaceholder: "ex. John Doe",
        showSort: true,
        searchlightField: "payment_company_name",
        sortField: "payment_company_name",
        searchlightToken: "cont_any",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Type",
        accessor: "type",
        width: "12.5%",
        showFilter: true,
        popupPlaceholder: "ex. Check, Cash, Credit",
        showSort: true,
        searchlightField: "tender_type",
        sortField: "tender_type",
        searchlightToken: "eq",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatPaymentType(props.value, true),
      },
      {
        Header: "Payment Date",
        accessor: "payment_date",
        width: "15%",
        showFilter: true,
        popupPlaceholder: "ex. yyyy-mm-dd",
        showSort: true,
        searchlightField: "payment_date",
        sortField: "payment_date",
        searchlightToken: "eq",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, true),
      },
      {
        Header: "Payment Amount",
        accessor: "payment_amount",
        width: "17.5%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "payment_amount",
        sortField: "payment_amount",
        searchlightToken: "lteq",
        alignment: "right",
        filterAnchorPosition: "center",
        Cell: (props: { value: number | null }) =>
          TableUtils.formatCurrency(props.value, false, userStatus?.currency?.locale, userStatus?.currency?.code),
      },
      {
        Header: "Bills",
        accessor: "bills",
        width: "15%",
        showFilter: false,
        popupPlaceholder: "ex. 100",
        showSort: false,
        // TODO: Change this to the appropriate field, Link up with API team
        searchlightField: "invoice_list",
        sortField: "invoice_list",
        searchlightToken: "eq",
        alignment: "left",
        filterAnchorPosition: "left",
        Cell: (props: { value: string[] | null }) => TableUtils.formatList(props.value, false),
      },
    ],
    [userStatus]
  );

  const BILLSCOLUMNS = useMemo(
    () => [
      {
        Header: "Bill Number",
        accessor: "bill_number",
        width: "20%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "invoice_number",
        sortField: "invoice_number",
        searchlightToken: "cont_any",
        alignment: "left",
        filterAnchorPosition: "right",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Vendor Name",
        accessor: "vendor_name",
        width: "17.5%",
        showFilter: true,
        popupPlaceholder: "ex. John Doe",
        showSort: true,
        searchlightField: "customer_name",
        sortField: "customer_name",
        searchlightToken: "cont_any",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Status",
        accessor: "status",
        width: "17.5%",
        showFilter: false,
        popupPlaceholder: "ex. Open, Closed, Past Due",
        showSort: false,
        searchlightField: "DAYSPASTDUE",
        sortField: "STATUS, DAYSPASTDUE, INVOICENUMBER",
        searchlightToken: "lt",
        upperBoundToken: "ge",
        dropdownOptions: [
          { label: "OPEN", value: InvoiceStatusType["OPEN"], id: "open" },
          { label: "PAST DUE 1-30", value: "0", id: "late1" },
          { label: "PAST DUE 31-60", value: InvoiceStatusType["PAST DUE 31-60"], id: "late31" },
          { label: "PAST DUE 61-90", value: InvoiceStatusType["PAST DUE 61-90"], id: "late61" },
          { label: "PAST DUE 91+", value: InvoiceStatusType["PAST DUE 91+"], id: "late91" },
          { label: "CLOSED", value: InvoiceStatusType["CLOSED"], id: "closed" },
        ],
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null; row: { original: { days_past_due?: number } } }) =>
          TableUtils.formatStatus(props.value, props.row.original.days_past_due, false),
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        width: "15%",
        showFilter: true,
        popupPlaceholder: "ex. yyyy-mm-dd",
        showSort: true,
        searchlightField: "payment_due_date",
        sortField: "payment_due_date",
        searchlightToken: "eq",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, true),
      },
      {
        Header: "Bill Amount",
        accessor: "bill_amount",
        width: "17.5%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "invoice_amount",
        sortField: "invoice_amount",
        searchlightToken: "lteq",
        alignment: "right",
        filterAnchorPosition: "center",
        Cell: (props: { value: number | null }) =>
          TableUtils.formatCurrency(props.value, false, userStatus?.currency?.locale, userStatus?.currency?.code),
      },
      {
        Header: "Payments",
        accessor: "payments",
        width: "15%",
        showFilter: false,
        popupPlaceholder: "ex. 100",
        showSort: false,
        // TODO: Ask API team for an appropriate sort Field, placeholder
        searchlightField: "DAYSPASTDUE",
        sortField: "DAYSPASTDUE, INVOICENUMBER",
        searchlightToken: "EQ",
        alignment: "left",
        filterAnchorPosition: "left",
        Cell: (props: { value: string[] | null }) => TableUtils.formatList(props.value, false),
      },
    ],
    [userStatus]
  );

  const vendorFetchParser = (fetchResult: ConnectionsSummaryModelFetchResultV2, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: CustomerSummaryModelV2 | VendorSummaryModelV2) => {
        const recordData = record as VendorSummaryModelV2;
        return {
          ...((variant === "id" || variant === "all") && {
            id: recordData?.vendor_id,
          }),
          ...((variant === "export" || variant === "all") && {
            vendor_name: recordData?.vendor_name || "N/A",
            primary_contact: recordData?.primary_contact_name || "N/A",
            amount_paid: recordData?.amount_paid_past_thirty_days,
            advance_payment_amount: recordData?.advance_pay_past_thirty_days,
            bills_paid: recordData?.paid_bill_count_past_thirty_days,
            last_activity: recordData?.last_activity_at ? DateTime.fromFormat(Utils.evaluateDate(recordData?.last_activity_at).split("T")[0], "yyyy-MM-dd").toFormat("yyyy-MM-dd") : null,
            customer_id: recordData?.vendor_id,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleCustomerRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(
      `${path.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/dashboard/payables_summary"))}/vendors/${row.original.id ?? ""}`
    );
  }

  const getFilters = (exportConnection = false, ids?: string[]) => {
    const additionalFilters: { searchlightFilter: string }[] = [
      {
        searchlightFilter: `status=all`,
      },
      {
        searchlightFilter: `qa[amount_paid_past_thirty_days_gteq]=0`,
      },
      {
        searchlightFilter: `qa[paid_bill_count_past_thirty_days_gteq]=0`,
      },
      {
        searchlightFilter: `qa[report_date]=${DateTime.fromFormat(reportDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy")}`,
      },
      {
        searchlightFilter: `workspace_id=${selectedWorkspace?.id || FallbackTypes.Id}`,
      },
    ];
    // evaluate export filters - consider ids only if all rows are not selected
    if (exportConnection && !allRowsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      ids.forEach((id: string) => {
        additionalFilters.push({
          searchlightFilter: selectedWorkspace?.workspace_type_route === WorkspaceType.AR ? `qa[company_id_in][]=${id}` : `qa[vendor_id_in][]=${id}`,
        });
      });
    }

    const defaultSort = `AMOUNTCOLLECTED DESC`;
    let sortQuery = TableUtils.getSortFromURL(params);
    if (!sortQuery || sortQuery === "") {
      sortQuery = defaultSort;
    }

    additionalFilters.push({
      searchlightFilter: `qs=${TableUtils.sortQueryParser(sortQuery ?? "", false)}`,
    });

    const filterQuery = TableUtils.getFilterFromURL(params, VENDORCOLUMNS);
    return TableUtils.columnFilterParser(filterQuery, true, additionalFilters ? [...additionalFilters] : undefined);
  };

  const getSummaryApi = () => {
    return companiesClientV2
      .getCustomerSummaries(getFilters(), undefined, pageSize, pageNumber - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)
      .then((fetchResult: any) => fetchResult);
  };

  const getFiltersForInvoices = (exportInvoice = false, ids?: string[]) => {
    const additionalFilters: Array<Record<string, string>> = [
      {
        searchlightFilter: `qa[invoice_type_code_eq]= AP Invoice`,
      },
      {
        searchlightFilter: "qa[payment_ids_not_null]=true",
      },
      {
        searchlightFilter: `qa[invoice_closed_date_lteq]=${DateTime.fromFormat(reportDate, "yyyy-MM-dd").toFormat("yyyy-MM-dd")}`,
      },
      {
        searchlightFilter: `qa[invoice_closed_date_gteq]=${DateTime.fromFormat(reportDate, "yyyy-MM-dd")
          .minus({ months: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE })
          .plus({ day: 1 })
          .toFormat("yyyy-MM-dd")}`,
      },
    ];

    // evaluate select filter on export
    if (exportInvoice && !allRowsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      ids.forEach((id: string) => {
        additionalFilters.push({
          searchlightFilter: `qa[invoice_id_in][]=${id}`,
        });
      });
    }
    const filterQuery = TableUtils.getFilterFromURL(params, BILLSCOLUMNS);

    return TableUtils.columnFilterParser(filterQuery, true, additionalFilters ? [...additionalFilters] : undefined);
  };

  const invoiceFetchParser = (fetchResult: InvoiceModelFetchResultV2, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: Partial<InvoiceModelV2>) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.invoice_id,
          }),
          ...((variant === "export" || variant === "all") && {
            bill_number: record?.invoice_number,
            vendor_name: record?.customer_name || "N/A",
            status: record?.status,
            due_date: record?.payment_due_date,
            bill_amount: record?.invoice_amount,
            payments: record?.payment_numbers?.[0] ?? "",
            invoice_id: record?.invoice_id,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleInvoiceRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    if (dataSetIndex === NUMERIC_VALUES.CONSTANT_TWO) {
      /**
       * Set the following properties into ActivityStreamSwitchContext
       * to facilitate activity stream switching.
       * @param selectedActivityStreamId - currently selected activity stream id
       * @param activityStreamIds - list of activity streams in the index
       * @param paginationState - current pagination state for reference
       */
      setEnableDocumentSwitch(true);
      setSelectedActivityStreamId({ id: row.original.id } as ActivityStreamId);
      setActivityStreamIds(tableData.map((item) => ({ id: item.id })));
      setCurrentPaginationState({ page: pageNumber, pageCount, pageSize: parseInt(pageSizeFromUrl || "0"), totalRecords: totalCount });
      setDocumentType("invoice");
    }
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(
      `${path.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/dashboard/payables_summary"))}/bills/${row.original.id ?? ""}`
    );
  }

  const fetchInvoices = async () => {
    const defaultSort = `payment_due_date+DESC`;
    let sortQuery = TableUtils.getSortFromURL(params);
    if (!sortQuery) {
      sortQuery = defaultSort;
    }
    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    const fetchParams = { pageSize, page: pageNumber, order: TableUtils.sortQueryParser(sortQuery ?? "", true), filter: getFiltersForInvoices() };
    setPlatformPageFetchParams(fetchParams as PlatformPageFetchParams);
    return invoicesClientV2
      .getInvoices(pageSize, pageNumber, TableUtils.sortQueryParser(sortQuery ?? "", true), getFiltersForInvoices())
      .then((fetchResult: any) => fetchResult);
  };

  const paymentFetchParser = (fetchResult: FetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: PaymentSummaryModelV2) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.payment_id,
          }),
          ...((variant === "export" || variant === "all") && {
            payment_number: record?.reference_code,
            vendor_name: record?.payment_company_name || "N/A",
            type: record?.tender_type || "N/A",
            payment_date: record?.payment_date,
            payment_amount: record?.payment_amount,
            bills: record?.invoice_list,
            payment_id: record?.payment_company_id,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const paymentFilterQuery = TableUtils.getFilterFromURL(params, PAYMENTSCOLUMNS);

  const fetchPayments = () => {
    const defaultSort = `payment_date DESC`;
    let sortQuery = TableUtils.getSortFromURL(params);
    if (!sortQuery) {
      sortQuery = defaultSort;
    }
    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    const fetchParams = {
      pageSize,
      page: pageNumber,
      order: TableUtils.sortQueryParser(sortQuery ?? "", true),
      filter: TableUtils.columnFilterParser(paymentFilterQuery, true, [
        {
          searchlightFilter: "qa[payment_type_eq]=AP Payment",
        },
        {
          searchlightFilter: "qa[invoice_list_not_null]=true",
        },
        {
          searchlightFilter: `qa[payment_date_lteq]=${DateTime.fromFormat(reportDate, "yyyy-MM-dd").toFormat("yyyy-MM-dd")}`,
        },
        {
          searchlightFilter: `qa[payment_date_gteq]=${DateTime.fromFormat(reportDate, "yyyy-MM-dd")
            .minus({ months: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE })
            .plus({ day: 1 })
            .toFormat("yyyy-MM-dd")}`,
        },
      ]),
      spamFraudTab: false,
    };
    setPlatformPageFetchParams(fetchParams as PlatformPageFetchParams);
    return PaymentsClientV2.querySummaries(fetchParams.filter, fetchParams.order, pageSize, pageNumber, false).then((fetchResult: any) => {
      return fetchResult;
    });
  };

  function handlePaymentRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    if (dataSetIndex === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      /**
       * Set the following properties into ActivityStreamSwitchContext
       * to facilitate activity stream switching.
       * @param selectedActivityStreamId - currently selected activity stream id
       * @param activityStreamIds - list of activity streams in the index
       * @param paginationState - current pagination state for reference
       */
      setEnableDocumentSwitch(true);
      setSelectedActivityStreamId({ id: row.original.id } as ActivityStreamId);
      setActivityStreamIds(tableData.map((item) => ({ id: item.id })));
      setCurrentPaginationState({ page: pageNumber, pageCount, pageSize: parseInt(pageSizeFromUrl || "0"), totalRecords: totalCount });
      setDocumentType("payment");
    }
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(
      `${path.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/dashboard/payables_summary"))}/payments/${row.original.id ?? ""}`
    );
  }

  return (
    <Table
      dataSets={[
        {
          id: "By Vendors",
          displayName: "By Vendors",
          rowSelectToken: "vendor_id",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: getSummaryApi,
            fetchParser: vendorFetchParser,
          },
          columns: VENDORCOLUMNS,
          export: {
            exportFileName: "Payables Summary Drilldown - Paid - By Vendors",
          },
          defaultSort: "AMOUNTPAIDLAST30, VENDORNAME",
          defaultSortToken: "DESC",
          handleRowClick: handleCustomerRowClick,
          predefinedFilters: [
            {
              route: "payables_summary",
              searchlightFilter: "(AMOUNTPAIDLAST30 NE 0)",
            },
          ],
        },
        {
          id: "By Payments",
          displayName: "By Payments",
          rowSelectToken: "payment_id",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: fetchPayments,
            fetchParser: paymentFetchParser,
          },
          columns: PAYMENTSCOLUMNS,
          export: {
            exportFileName: "Payables Summary Drilldown - Paid - By Payments",
          },
          defaultSort: "PAYMENTDATE, REFERENCECODE",
          defaultSortToken: "DESC",
          handleRowClick: handlePaymentRowClick,
          predefinedFilters: [
            {
              route: "payables_summary",
              searchlightFilter: `paymentType eq 'AP Payment' AND (paymentDate >= ${getFormattedDate(
                past30Date
              )}) AND (paymentDate <= ${getFormattedDate(today)})`,
            },
          ],
        },
        {
          id: "By Bills",
          displayName: "By Bills",
          rowSelectToken: "invoice_id",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: fetchInvoices,
            fetchParser: invoiceFetchParser,
          },
          columns: BILLSCOLUMNS,
          export: {
            exportFileName: "Payables Summary Drilldown - Paid - By Bills",
          },
          defaultSort: "payment_due_date, invoice_closed_date",
          defaultSortToken: "DESC",
          handleRowClick: handleInvoiceRowClick,
        },
      ]}
      states={{
        isLoading: isLoading,
        setLoading: setLoading,
        isError: isError,
        setError: setError,
        errorMessage: errorMessage,
        setErrorMessage: setErrorMessage,
      }}
      pagination={{
        pageCount: pageCount,
        setPageCount: setPageCount,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
        totalCount: totalCount,
        setTotalCount: setTotalCount,
        setPageSize: setPageSize,
        startingIndex: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
      }}
      toggles={{
        showRowSelect: true,
        showExportBtn: true,
        showCaption: true,
        showNavigation: true,
        showSearchbar: false,
      }}
    />
  );
}
