import APIClient from "../APIClient";

export class TemplatesClient {
  getTemplates(workspaceId: number): Promise<APIResponse> {
    const url = `/inbox//api/v2/workspaces/${workspaceId}/email_templates`;
    return APIClient.get(url).then((response) => response.data);
  }

  saveTemplate(workspaceId: number, templateCode: number, body: any): Promise<APIResponse> {
    const url = `/inbox//api/v2/workspaces/${workspaceId}/email_templates/${templateCode}`;
    return APIClient.patch(url, body).then((response) => response.data);
  }
}
