import { Liquid } from "liquidjs";
import { IData, TemplateTypes, viewType } from "./TemplateTypes";
import InvoiceCopy from "./InvoiceCopy";
import InvoicePastDueReminder from "./InvoicePastDueReminder";
import InvoiceSecondReminder from "./InvoiceSecondReminder";
import RequestInvoicePaymentStatus from "./RequestInvoicePaymentStatus";
import AccountPastDueReminder from "./AccountPastDueReminder";
import AccountPastDueSecondReminder from "./AccountPastDueSecondReminder";
import RequestAccountPaymentStatus from "./RequestAccountPaymentStatus";
import PaymentConfirmation from "./PaymentConfirmation";
import APTaxExemptCertificate from "./APTaxExemptCertificate";
import ARTaxExemptCertificate from "./ARTaxExemptCertificate";
import W9Form from "./W9Form";
import BillReceiptNotification from "./BillReceiptNotification";
import RequestCopyBill from "./RequestCopyBill";
import ActionRequiredForBill from "./ActionRequiredForBill";
import BillPaymentScheduled from "./BillPaymentScheduled";
//import AccountPaymentScheduled from "./AccountPaymentScheduled";
//import PaymentConfirmationRequest from "./PaymentConfirmationRequest";
import PaymentRemittanceInfo from "./PaymentRemittanceInfo";
import PaymentApplicationDiscrepancy from "./PaymentApplicationDiscrepancy";
import SupportDocuments from "./SupportDocuments";
import RequestForW9 from "./RequestForW9";
import RequestForBankInfo from "./RequestForBankInfo";
import ARApprovalRequest from "./ARApprovalRequest";
import APApprovalRequest from "./APApprovalRequest";
import VendorProfileRequest from "./VendorProfileRequest";

export type TemplateDefinitionProps = {
  label: string;
  displayText: string;
  id: string;
};

/**
 * The template factory pattern which is used to create
 * and instance of template.
 */
class TemplateFactory {
  /**
   * The instance of liquid template engine
   */
  protected liquid;

  /**
   * The Map of all templates
   */
  protected templateObjectsMap;

  /**
   * Constructor of TemplateFactory
   */
  constructor(templateMap: Map<TemplateTypes, any>) {
    this.templateObjectsMap = templateMap;
    this.liquid = new Liquid({ cache: true });
  }

  setTemplateObjectsMap(templateMap: Map<TemplateTypes, any>) {
    this.templateObjectsMap = templateMap;
  }

  /**
   * Concrete function which used to create the
   * instance of template class base on give template type.
   *
   * @param {String} templateType The type of template is going to initiated.
   * @param {String} workSpace The work space which is currently active.
   * @returns {Class} return the instance of the template class.
   */
  createTemplateObjects(templateList: Array<any>, workSpace: string): Array<any> {
    const templateObjectsList = templateList.map((template) => {
      switch (template.template_code) {
        case TemplateTypes.INVOICE_COPY:
          return new InvoiceCopy(workSpace, template, this.liquid);
        case TemplateTypes.INVOICE_PAST_DUE_REMINDER:
          return new InvoicePastDueReminder(workSpace, template, this.liquid);
        case TemplateTypes.INVOICE_SECOND_REMINDER:
          return new InvoiceSecondReminder(workSpace, template, this.liquid);
        case TemplateTypes.REQUEST_FOR_INVOICE_PAYMENT_STATUS:
          return new RequestInvoicePaymentStatus(workSpace, template, this.liquid);
        case TemplateTypes.ACCOUNT_PAST_DUE_REMINDER:
          return new AccountPastDueReminder(workSpace, template, this.liquid);
        case TemplateTypes.ACCOUNT_PAST_DUE_SECOND_REMINDER:
          return new AccountPastDueSecondReminder(workSpace, template, this.liquid);
        case TemplateTypes.REQUEST_FOR_ACCOUNT_PAYMENT_STATUS:
          return new RequestAccountPaymentStatus(workSpace, template, this.liquid);
        case TemplateTypes.PAYMENT_CONFIRMATION:
          return new PaymentConfirmation(workSpace, template, this.liquid);
        case TemplateTypes.AR_TAX_EXEMPT_CERTIFICATE:
          return new ARTaxExemptCertificate(workSpace, template, this.liquid);
        case TemplateTypes.AP_TAX_EXEMPT_CERTIFICATE:
          return new APTaxExemptCertificate(workSpace, template, this.liquid);
        case TemplateTypes.W9_FORM:
          return new W9Form(workSpace, template, this.liquid);
        case TemplateTypes.BILL_RECEIPT_NOTIFICATION:
          return new BillReceiptNotification(workSpace, template, this.liquid);
        case TemplateTypes.REQUEST_COPY_BILL:
          return new RequestCopyBill(workSpace, template, this.liquid);
        case TemplateTypes.ACTION_REQUIRED_BILL:
          return new ActionRequiredForBill(workSpace, template, this.liquid);
        case TemplateTypes.BILL_PAYMENT_SCHEDULED:
          return new BillPaymentScheduled(workSpace, template, this.liquid);
        //Disabling as Product needs more refinement
        // case TemplateTypes.ACCOUNT_PAYMENT_SCHEDULED:
        //   return new AccountPaymentScheduled(workSpace, template , this.liquid);
        // case TemplateTypes.PAYMENT_CONFIRMATION_REQUEST:
        //   return new PaymentConfirmationRequest(workSpace, template , this.liquid);
        case TemplateTypes.PAYMENT_REMITTANCE_INFO:
          return new PaymentRemittanceInfo(workSpace, template, this.liquid);
        case TemplateTypes.PAYMENT_APPLICATION_DISCREPANCY:
          return new PaymentApplicationDiscrepancy(workSpace, template, this.liquid);
        case TemplateTypes.SUPPORTING_DOCUMENTS:
          return new SupportDocuments(workSpace, template, this.liquid);
        case TemplateTypes.REQUEST_W9:
          return new RequestForW9(workSpace, template, this.liquid);
        case TemplateTypes.REQUEST_BANK_INFO:
          return new RequestForBankInfo(workSpace, template, this.liquid);
        case TemplateTypes.AR_APPROVAL_REQUEST:
          return new ARApprovalRequest(workSpace, template, this.liquid);
        case TemplateTypes.AP_APPROVAL_REQUEST:
          return new APApprovalRequest(workSpace, template, this.liquid);
        case TemplateTypes.AP_VENDOR_PROFILE_REQUEST:
          return new VendorProfileRequest(workSpace, template, this.liquid);
        default:
          return null;
      }
    });
    return templateObjectsList;
  }

  /**
   * Function which used to provide the template list of view supported by.
   *
   * @param {viewType} view The view from which we generate the template.
   * @param {IData} props The props which used to parse the template.
   * @returns {TemplateDefinitionProps[]}
   */
  getTemplateDefinitionsByView(view: viewType, props: IData): TemplateDefinitionProps[] {
    type ITemplateList = {
      label: string;
      requiredParse?: boolean;
      subjectTpl?: string;
      displayText: string;
      visible?: boolean;
      id: string;
    };

    let templateItems: ITemplateList[] = [];
    switch (view) {
      case viewType.AR_DASHBOARD_AGING:
      case viewType.AR_DASHBOARD_RECEIVABLES_RISK:
      case viewType.AR_TRANSACTION_INVOICES:
      case viewType.AR_CONNECTION_CUSTOMER_OPEN_INVOICES:
        templateItems = [
          {
            label: "Invoice Copy",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.INVOICE_COPY).templateSubject,
            displayText: "",
            id: TemplateTypes.INVOICE_COPY,
          },
          {
            label: "Invoice Past Due Reminder",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.INVOICE_PAST_DUE_REMINDER).templateSubject,
            displayText: "",
            id: TemplateTypes.INVOICE_PAST_DUE_REMINDER,
          },
          {
            label: "Invoice Second Reminder",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.INVOICE_SECOND_REMINDER).templateSubject,
            displayText: "",
            id: TemplateTypes.INVOICE_SECOND_REMINDER,
          },
          {
            label: "Request For Invoice Payment Status",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.REQUEST_FOR_INVOICE_PAYMENT_STATUS).templateSubject,
            displayText: `Request For Invoice(s) Payment Status`,
            id: TemplateTypes.REQUEST_FOR_INVOICE_PAYMENT_STATUS,
          },
        ];
        break;
      case viewType.AR_DASHBOARD_DAYS_SALES_OUTSTANDING:
      case viewType.AR_ALL_CUSTOMER_CONNECTIONS:
        templateItems = [
          {
            label: "Account Past Due Reminder",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.ACCOUNT_PAST_DUE_REMINDER).templateSubject,
            displayText: "",
            id: TemplateTypes.ACCOUNT_PAST_DUE_REMINDER,
          },
          {
            label: "Account Past Due Second Reminder",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.ACCOUNT_PAST_DUE_SECOND_REMINDER).templateSubject,
            displayText: "",
            id: TemplateTypes.ACCOUNT_PAST_DUE_SECOND_REMINDER,
          },
          {
            label: "Request For Account Payment Status",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.REQUEST_FOR_ACCOUNT_PAYMENT_STATUS).templateSubject,
            displayText: "",
            id: TemplateTypes.REQUEST_FOR_ACCOUNT_PAYMENT_STATUS,
          },
        ];
        break;
      case viewType.AR_DASHBOARD_RECEIVABLES_SUMMARY_COLLECTED:
      case viewType.AR_TRANSACTION_PAYMENTS:
      case viewType.AR_CONNECTION_CUSTOMER_PAYMENTS:
        templateItems = [
          {
            label: "Payment Confirmation",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.PAYMENT_CONFIRMATION).templateSubject,
            displayText: "",
            id: TemplateTypes.PAYMENT_CONFIRMATION,
          },
        ];
        break;
      case viewType.AR_DASHBOARD_RECEIVABLES_SUMMARY_INVOICED:
        templateItems = [
          {
            label: "Invoice Copy",
            requiredParse: true,
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.INVOICE_COPY).templateSubject,
            displayText: "",
            id: TemplateTypes.INVOICE_COPY,
          },
        ];
        break;
      case viewType.AR_CONNECTION_CUSTOMER_ACTIVITIES:
        templateItems = [
          {
            label: "Tax Exempt Certificate",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.AR_TAX_EXEMPT_CERTIFICATE).templateSubject,
            displayText: "",
            id: TemplateTypes.AR_TAX_EXEMPT_CERTIFICATE,
          },
          {
            label: "W-9 Form",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.W9_FORM).templateSubject,
            displayText: "",
            id: TemplateTypes.W9_FORM,
          },
        ];
        break;
      case viewType.AR_CONNECTION_CUSTOMER_CLOSE_INVOICES:
      case viewType.AR_TRANSACTION_INVOICES_CLOSED:
        templateItems = [
          {
            label: "Invoice Copy",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.INVOICE_COPY).templateSubject,
            displayText: "",
            id: TemplateTypes.INVOICE_COPY,
          },
        ];
        break;
      case viewType.AP_DASHBOARD_AGING:
      case viewType.AP_TRANSACTION_BILLS:
      case viewType.AP_CONNECTION_VENDOR_OPEN_BILLS:
        templateItems = [
          {
            label: "Bill Receipt Notification",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.BILL_RECEIPT_NOTIFICATION).templateSubject,
            displayText: "",
            id: TemplateTypes.BILL_RECEIPT_NOTIFICATION,
          },
          {
            label: "Request Copy of Bill",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.REQUEST_COPY_BILL).templateSubject,
            displayText: "",
            id: TemplateTypes.REQUEST_COPY_BILL,
          },
          {
            label: "Action Required For Bill",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.ACTION_REQUIRED_BILL).templateSubject,
            displayText: "",
            visible: false,
            id: TemplateTypes.ACTION_REQUIRED_BILL,
          },
          {
            label: "Bill Payment Scheduled",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.BILL_PAYMENT_SCHEDULED).templateSubject,
            displayText: "",
            id: TemplateTypes.BILL_PAYMENT_SCHEDULED,
          },
        ];

        if (view === viewType.AP_TRANSACTION_BILLS || view === viewType.AP_CONNECTION_VENDOR_OPEN_BILLS) {
          templateItems = [
            ...templateItems,
            {
              label: "Internal Request - Supporting Documents",
              subjectTpl: this.templateObjectsMap.get(TemplateTypes.SUPPORTING_DOCUMENTS).templateSubject,
              displayText: "",
              id: TemplateTypes.SUPPORTING_DOCUMENTS,
            },
          ];
        }
        break;
      //Currently Disabled as Product wants more refinement on this
      // case viewType.AP_DASHBOARD_SUMMARY_BILLED:
      // case viewType.AP_DASHBOARD_DAYS_PAYABLE_OUTSTANDING:
      // case viewType.AP_DASHBOARD_PAYABLE_COMING_DUE:
      // case viewType.AP_ALL_VENDOR_CONNECTIONS:
      //   templateItems = [
      //     {
      //       label: "Account Payment Scheduled",
      //       subjectTpl: this.templateObjectsMap.get(TemplateTypes.ACCOUNT_PAYMENT_SCHEDULED).templateSubject,
      //       displayText: "",
      //       id: TemplateTypes.ACCOUNT_PAYMENT_SCHEDULED,
      //     },
      //   ];
      //   break;
      case viewType.AP_DASHBOARD_SUMMARY_PAID:
      case viewType.AP_TRANSACTION_PAYMENTS:
      case viewType.AP_CONNECTION_VENDOR_PAYMENTS:
        templateItems = [
          //Currently Disabled as Product wants more refinement on this
          // {
          //   label: "Payment Confirmation Request",
          //   subjectTpl: this.templateObjectsMap.get(TemplateTypes.PAYMENT_CONFIRMATION_REQUEST).templateSubject,
          //   displayText: "",
          //   id: TemplateTypes.PAYMENT_CONFIRMATION_REQUEST,
          // },
          {
            label: "Payment Remittance Info",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.PAYMENT_REMITTANCE_INFO).templateSubject,
            displayText: "",
            id: TemplateTypes.PAYMENT_REMITTANCE_INFO,
          },
          {
            label: "Payment Application Discrepancy",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.PAYMENT_APPLICATION_DISCREPANCY).templateSubject,
            displayText: "",
            id: TemplateTypes.PAYMENT_APPLICATION_DISCREPANCY,
          },
        ];
        break;
      //Currently Disabled as Product wants more refinement on this
      // case viewType.AP_TRANSACTION_ADVANCE_PAYMENTS:
      //   templateItems = [
      //     {
      //       label: "Payment Confirmation Request",
      //       subjectTpl: this.templateObjectsMap.get(TemplateTypes.PAYMENT_CONFIRMATION_REQUEST).templateSubject,
      //       displayText: "",
      //       id: TemplateTypes.PAYMENT_CONFIRMATION_REQUEST,
      //     },
      //   ];
      //   break;
      case viewType.AP_CONNECTION_VENDOR_ACTIVITY:
        templateItems = [
          {
            label: "Request For W-9",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.REQUEST_W9).templateSubject,
            displayText: "",
            id: TemplateTypes.REQUEST_W9,
          },
          {
            label: "Request For Bank Info",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.REQUEST_BANK_INFO).templateSubject,
            displayText: "",
            id: TemplateTypes.REQUEST_BANK_INFO,
          },
          {
            label: "Tax Exempt Certificate",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.AP_TAX_EXEMPT_CERTIFICATE).templateSubject,
            displayText: "",
            id: TemplateTypes.AP_TAX_EXEMPT_CERTIFICATE,
          },
        ];
        break;
      case viewType.AP_CONNECTION_VENDOR_CLOSED_BILLS:
      case viewType.AP_TRANSACTION_BILLS_CLOSED:
        templateItems = [
          {
            label: "Request Copy of Bill",
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.REQUEST_COPY_BILL).templateSubject,
            displayText: "",
            id: TemplateTypes.REQUEST_COPY_BILL,
          },
        ];
        break;
      case viewType.AR_APPROVAL_REQUEST_ACTIVITY:
        templateItems = [
          {
            label: "Approval Request",
            requiredParse: false,
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.AR_APPROVAL_REQUEST).templateSubject,
            displayText: "",
            id: TemplateTypes.AR_APPROVAL_REQUEST,
          },
        ];
        break;
      case viewType.AP_APPROVAL_REQUEST_ACTIVITY:
        templateItems = [
          {
            label: "Approval Request",
            requiredParse: false,
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.AP_APPROVAL_REQUEST).templateSubject,
            displayText: "",
            id: TemplateTypes.AP_APPROVAL_REQUEST,
          },
        ];
        break;
      case viewType.VENDOR_ONBOARD_REQUEST_ACTIVITY:
        templateItems = [
          {
            label: "New Profile Request",
            requiredParse: false,
            subjectTpl: this.templateObjectsMap.get(TemplateTypes.AP_VENDOR_PROFILE_REQUEST).templateSubject,
            displayText: "",
            id: TemplateTypes.AP_VENDOR_PROFILE_REQUEST,
          },
        ];
    }

    const tplList: TemplateDefinitionProps[] = [];

    for (let i = 0; i < templateItems.length; i++) {
      const templateItem = templateItems[i];
      const displayText: string = this.liquid.parseAndRenderSync(templateItem.subjectTpl as string, props);

      // TODO: Added check to hide the 'Action Required for Bill'. For more check Bug: #18015
      if (templateItem.visible === false) {
        continue;
      }

      tplList.push({
        label: templateItem.label,
        displayText: displayText,
        id: templateItem.id,
      });
    }
    return tplList;
  }
}

export default TemplateFactory;
