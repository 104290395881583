import React from "react";

import { LargeLoading } from "../../library/Icons/Icons";
import "./WaitScreen.scss";

interface Props {
  loadingMessage?: string;
}

const WaitScreen: React.FC<Props> = ({ loadingMessage = "Hold tight, your dashboard is loading." }: Props) => {
  return (
    <div className={"ws-wrapper"}>
      <div className={"ws-card"}>
        <div data-testid="WaitscreenLoader" className={"ws-loading-wrapper"}>
          <LargeLoading />
        </div>

        <p className={"text"}>{loadingMessage}</p>
      </div>
    </div>
  );
};

export default WaitScreen;
