import { BUCKET_CONSTANTS, DEFAULT_NUMERIC_VALUES } from "../constants/NumericConstants";

export enum KeyPressEventKeys {
  "arrowDown" = "ArrowDown",
  "arrowUp" = "ArrowUp",
  "enter" = "Enter",
}

export enum GlobalSearchCategories {
  "all" = "all",
  "activity_streams" = "activity_streams",
  "connections" = "connections",
  "contacts" = "contacts",
  "attachments" = "attachments",
  "invoices" = "invoices",
  "payments" = "payments",
}

export enum StatusBackendNumberMap {
  "active" = "0",
  "open" = "0",
  "closed" = "1",
  "resolved" = "1",
  "waiting_for_response" = "2",
  "pending" = "2",
  "snoozed" = "3",
}

export enum DataMode {
  View,
  Edit,
}

export enum InvoiceStatusType {
  "OPEN" = BUCKET_CONSTANTS.OPEN,
  "PAST DUE 1-30" = BUCKET_CONSTANTS.FIRST_PAST_DUE_BUCKET,
  "PAST DUE 31-60" = BUCKET_CONSTANTS.SECOND_PAST_DUE_BUCKET,
  "PAST DUE 61-90" = BUCKET_CONSTANTS.THIRD_PAST_DUE_BUCKET,
  "PAST DUE 91+" = BUCKET_CONSTANTS.FOURTH_PAST_DUE_BUCKET,
  "CLOSED" = BUCKET_CONSTANTS.CLOSED,
}

export enum InvoiceStatusAging {
  "FUTURE DUE" = "Future Due",
  "LATE 1-30 DAYS" = "1+ Late",
  "LATE 31-60 DAYS" = "31+ Late",
  "LATE 61-90 DAYS" = "61+ Late",
  "LATE 91+ DAYS" = "91+ Late",
}

export enum EntityClassification {
  corporation = "Corporation",
  partnership = "Partnership",
  "sole-proprietor" = "Sole-proprietor",
}

/**
 * Contains the possible roles that a user can have.
 */
export enum UserRoles {
  GroupOwner = "Group Owner",
  GroupAdmin = "Group Admin",
  Member = "Member",
  ReadOnly = "Read-Only",
}

/**
 * Holds all actions that will be only allowed for some users
 */
export enum UserActions {
  AddNote = "addActivity",
  InvitationsRemind = "invRemind",
  InvitationsRemove = "invRemove",
  InvitationsCheckbox = "invCheckbox",
  RemovedMembersInvite = "rmvedMembersInv",
  RemovedMembersCheckbox = "rmvCheckbox",
  InviteMembers = "invMembers",
  InvitationsChangeRole = "invChangeRoles",
  ActiveMembersCheckbox = "actvCheckbox",
  ActiveMembersRemove = "actvRemove",
  ChangeRoles = "changeRoles",
  EditMyProfile = "editMyProfile",
  EditMyCompany = "editMyCompany",
  AddEmailClient = "addEmailClient",
  RunSync = "runSync",
  EditAccountingSoftware = "editAccountingSoftware",
  EditWorkspace = "editWorkspace",
}

/**
 * The possible statuses an activity can have.
 */
export enum ActivityStatus {
  Active = "Active",
  Closed = "Closed",
  NotStarted = "Not Started",
  WaitingForResponse = "Waiting_For_Response",
  Spam = "Spam",
  Snoozed = "Snoozed",
}

/**
 * The possible statuses an activity can have.
 */
export enum ActivityActionTypes {
  Status = "Status",
  Read = "Read",
  Spam = "Spam",
  Assign = "Assign",
  Unassign = "Unassign",
  Move = "Move",
}

/**
 * Types of fallback
 */
export enum FallbackTypes {
  Id = DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE,
}

/**
 * Types of activities in a Activity Feed
 */
export enum ActivityType {
  Note = "note",
  Move = "move",
  PhoneCall = "call_log",
  Email = "input_email",
  IncomingEmail = "incoming_email",
  BouncedEmail = "bounced_email",
  AuditLog = "audit_log",
  Approval = "approval",
  Forward = "forward", // if stream item is input_email but has original_activity_id
  ApprovalRedirect = "approval_redirect",
  ApprovalRequest = "approval_request",
  ApprovalReviewed = "approval_reviewed",
  ApprovalResponse = "approval_response",
  ApprovalAccessRevoked = "access_revoked",
  ForwardApAutomation = "ap_automation",
  Nudge = "nudge",
  APAutomation = "ap_automation",
  VendorOnboarding = "started_vendor_onboarding",
}

export enum EmailType {
  bounce = "bounce",
  standard = "standard",
  outOfOffice = "out_of_office",
  nudge = "nudge",
}

export enum ActivityOptions {
  PHONE_CALL = "phone_call",
  APPROVAL_REQUEST = "approval_request",
  EMAIL = "email",
  ONBOARD_VENDOR = "request_new_company_profile",
}

export enum AuditLogType {
  Assign = "assign",
  Unassign = "unassign",
  Close = "closed",
  Spam = "spam",
  Forward = "forward",
  Snoozed = "snoozed",
  Unsnoozed = "unsnoozed",
  SpamConnection = "connection_spam",
  UnspamConnection = "connection_unspam",
  FraudConnection = "connection_fraud",
  UnfraudConnection = "connection_unfraud",
  Move = "move",
  MergeConnection = "connection_merge",
  OnboardRequest = "onboard_request",
}

export enum InlinePrompts {
  ONBOARD_VENDOR = "onboard_vendor",
}

export enum TopCompaniesBy {
  Recent_Activity = "Recent Activity",
  Past_Due_Balance = "Past Due Balance",
  Past_Due_Invoices = "Past Due Invoices",
  Total_Balance = "Total Balance",
  Open_Invoices = "Open Invoices",
  Coming_Due = "Coming Due",
  Open_Bills = "Open Bills",
}

/**
 * Types of Searchlight Date Types most commonly used with our Table Component
 */
export enum SearchlightDataTypes {
  String = "String",
  Number = "Number",
  Date = "Date", // "YYYY-MM-DD"
  DateTime = "DateTime", // "YYYY-MM-DDTHH:MM:SS"
}
/**
 * Types of Searchlight Tokens most commonly used with our Table Component
 */
export enum SearchlightTokens {
  EQ = "EQ",
  GT = "GT",
  GE = "GE",
  NE = "NE",
  LT = "LT",
  LE = "LE",
  STARTSWITH = "STARTSWITH",
  CONTAINS = "CONTAINS",
  ENDSWITH = "ENDSWITH",
}

export enum EmailConnectorStatus {
  disconnected = "disconnected",
  active = "active",
}

export enum ChannelAction {
  connectToWorkspace = "connect_to_workspace",
  createWorkspace = "create_workspace",
  reconnect = "reconnect",
}

export enum ConnectorName {
  "Microsoft Outlook" = "microsoft_office",
  "Gmail" = "gmail",
}

/**
 * Mapping from Searchlight Tokens to Human Readable text
 */
export const SearchlightTokenToHumanReadableTextMap = new Map([
  [SearchlightTokens.EQ, "Equals"],
  [SearchlightTokens.GT, "Greater Than"],
  [SearchlightTokens.GE, "Greater Than or Equal To"],
  [SearchlightTokens.NE, "Not Equal To"],
  [SearchlightTokens.LT, "Less Than"],
  [SearchlightTokens.LE, "Less Than or Equal To"],
  [SearchlightTokens.STARTSWITH, "Starts With"],
  [SearchlightTokens.CONTAINS, "Contains"],
  [SearchlightTokens.ENDSWITH, "Ends With"],
]);

/**
 * Mapping from Searchlight Data Types to Searchlight Tokens acceptable with Type
 */
export const SearchlightDataTypeToTokenMap = new Map([
  [
    SearchlightDataTypes.String,
    [
      SearchlightTokens.EQ,
      SearchlightTokens.GT,
      SearchlightTokens.GE,
      SearchlightTokens.NE,
      SearchlightTokens.LT,
      SearchlightTokens.LE,
      SearchlightTokens.STARTSWITH,
      SearchlightTokens.CONTAINS,
      SearchlightTokens.ENDSWITH,
    ],
  ],
  [
    SearchlightDataTypes.Number,
    [SearchlightTokens.EQ, SearchlightTokens.GT, SearchlightTokens.GE, SearchlightTokens.NE, SearchlightTokens.LT, SearchlightTokens.LE],
  ],
  [
    SearchlightDataTypes.Date,
    [SearchlightTokens.EQ, SearchlightTokens.GT, SearchlightTokens.GE, SearchlightTokens.NE, SearchlightTokens.LT, SearchlightTokens.LE],
  ],
  [
    SearchlightDataTypes.DateTime,
    [SearchlightTokens.EQ, SearchlightTokens.GT, SearchlightTokens.GE, SearchlightTokens.NE, SearchlightTokens.LT, SearchlightTokens.LE],
  ],
]);

/**
 * Searchlight Data Type to Regex Validation object used for Input Validation
 */
export const SearchlightDataTypeToRegexValidation = new Map([
  [SearchlightDataTypes.String, /.*/],
  [SearchlightDataTypes.Number, /^[+-]?(\d+\.?\d*|\.\d+)$/],
  [SearchlightDataTypes.Date, /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12]\d|3[01])$/],
  [SearchlightDataTypes.DateTime, /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?$/],
]);

export enum AdvancedQueryFilter {
  TEXT = "0",
  INPUT_TEXT = "1",
  INPUT_TEXTAREA = "2",
  INPUT_EMAIL = "3",
  INPUT_SELECT = "4",
  CARDS = "5",
  FORM = "6",
  ARTICLE = "7",
  INCOMING_EMAIL = "8",
  INPUT_CSAT = "9",
  NOTE = "10",
  CALL_LOG = "11",
}

export enum EmailAction {
  NEW = "new",
  REPLY = "reply",
  REPLY_ALL = "reply_all",
  FORWARD = "forward",
  VIEW_ORIGINAL = "view_original",
  NUDGE = "nudge",
  FORWARD_AP_AUTOMATION = "forward_ap_automation",
}

export enum ConnectionStatus {
  ACTIVE = "active",
  ARCHIVED = "archived",
  FRAUD = "fraud",
  SPAM = "spam",
}

export enum ContactType {
  "EMAIL" = "Email",
  "ACCOUNTING_SYSTEM" = "Accounting System",
}

export enum WorkspaceType {
  AR = "ar",
  AP = "ap",
}

/**
 * Tracking Event Names
 */
export enum TrackEventType {
  EMAIL_ACTIVITY = "Email Activity",
}

export enum ActivityTabType {
  Profile,
  Transactions,
  Attachments,
}

export enum TransactionType {
  Invoice = "invoice",
  Payment = "payment",
}

export enum AttachmentType {
  INVOICE = "invoice",
  PAYMENT = "payment",
  DOCUMENT = "document",
  BILL = "bill",
}

export enum FilterType {
  Search = "search",
  Select = "select",
}

export enum Position {
  Left = "left",
  Right = "right",
  Center = "center",
}

export enum FilterToken {
  Equal = "eq",
  NotEqual = "ne",
  LessThan = "lt",
  GreaterThan = "gt",
  LessThanOrEqual = "le",
  GreaterThanOrEqual = "ge",
  ContainsAny = "cont_any",
  Contains = "cont",
}

export enum Order {
  DESC = "desc",
  ASC = "asc",
}
