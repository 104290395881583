import React from "react";
import { FullStoryAPI } from "react-fullstory";
import { useLDClient } from "launchdarkly-react-client-sdk";
import Utils from "../../utils/utils";

type UserInfo = {
  id: string;
  username: string;
  custom: {
    groupKey: string;
    accountName: string;
    companyId: string;
    userRole: string;
  };
};
interface TrackingProps {
  identity: UserInfo;
  profileData: UserAccountModelV2;
}

const FullStoryHandler = (props: TrackingProps) => {
  if (Utils.getFullStoryConfig().forceEnableFullStory || Utils.isProduction()) {
    FullStoryAPI("identify", props.profileData?.user_id, {
      inbox_user_role_str: props.profileData.user_role ?? "",
      inbox_company_name_str: props.profileData.account_name,
      inbox_company_id_str: props.profileData.account_id,
      is_developer: Utils.isLocal(),
      inbox_user_title_str: props.profileData.user_title,
      inbox_user_id_str: props.profileData.user_id,
      inbox_user_type_str: props.profileData.account_type,
      inbox_erp_type_str: props.profileData.erp_type,
      inbox_channel_type_str: props.profileData?.default_workspace?.channel_type,
    });
  }
  return null;
};

const LaunchDarklyHandler = (props: TrackingProps) => {
  const ldClient = useLDClient();
  if (ldClient && props.identity.id && props.identity.custom.groupKey) {
    ldClient.identify({
      key: props.identity.id,
      name: props.identity.username,
      custom: props.identity.custom,
    });
  }
  return null;
};

//Pendo Handler to intialise Pendo
const PendoHandler = (props: TrackingProps) => {
  if (props.profileData.user_id && Utils.isProduction()) {
    (window as any).pendo.initialize({
      visitor: {
        id: props.profileData.user_id,
      },
      account: {
        id: props.profileData.group_key || "",
        name: props.profileData.account_name || undefined,
        companyId: props.profileData.account_id || undefined,
      },
    });
  }
  return null;
};

const Tracking = (props: TrackingProps): React.ReactElement => {
  return (
    <React.Fragment>
      <FullStoryHandler identity={props.identity} profileData={props.profileData} />
      <LaunchDarklyHandler identity={props.identity} profileData={props.profileData} />
      <PendoHandler identity={props.identity} profileData={props.profileData} />
    </React.Fragment>
  );
};

export default Tracking;
