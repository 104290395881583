import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { AssignProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

const Assign: React.FC<AssignProps> = ({ assigneeNote, assigneeName, creator }) => {
  creator = { ...creator, message: `${Labels.HEADER_ASSIGNED_ACTIVITY_STREAM}${assigneeName ? ` to ${assigneeName}` : ""}` };

  return assigneeNote ? (
    <SecondaryActivity variant={SecondaryType.Accordion} creator={creator}>
      <p>{assigneeNote}</p>
    </SecondaryActivity>
  ) : (
    <SecondaryActivity creator={creator} variant={SecondaryType.Base} />
  );
};

export default Assign;
