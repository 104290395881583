import React, { useEffect } from "react";
import { workspaceClientV2 } from "../db/version2Accessor";
import { useHistory } from "react-router-dom";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../constants/NumericConstants";
import { Alert, Snackbar } from "@mui/material";
import { WorkspaceType } from "../types/enums";

interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

export const WorkspaceContext = React.createContext<WorkspaceDataType | null>(null);

const WorkspaceProvider: React.FC<Props> = ({ children }: Props) => {
  const [fetchingWorkspace, setFetchingWorkspace] = React.useState<boolean>(false);
  const [workspaceData, setWorkspaceData] = React.useState<Array<UserItem>>([]);
  const [workspaceHomePath, setWorkspaceHomePath] = React.useState<string>("");
  const [workspaceConfigChangeInProgress, setWorkspaceConfigChangeInProgress] = React.useState<boolean>(false);
  const [userGroupChanged, setUserGroupChanged] = React.useState<boolean>(false);
  const [toastOptions, setToastOptions] = React.useState<ToastOptions>({
    open: false,
    severity: "success",
    message: "",
  });

  const history = useHistory();

  /**
   * store workspace config when user switches or take the default one or first item in workspace data array
   */
  const [selectedWorkspace, setSelectedWorkspace] = React.useState<UserItem>(workspaceData[0]);

  const setCurrentWorkspace = (workspace: UserItem) => {
    setSelectedWorkspace(workspace);
    sessionStorage.setItem("selectedWorkspaceId", `${workspace.id}`);
  };

  /**
   * Setting Home Path
   * @param workspace_type : workspace workspace_type
   * @param id : workspace id
   */

  const setWorkspacePath = (workspace_type: string, id: number) => {
    const path = `/${workspace_type?.toUpperCase()}/${id}`;
    setWorkspaceHomePath(path);
    return path;
  };

  /**
   * filter out selected workspace config and set it as selected
   * @param workspaceId
   */
  const switchActiveWorkspace = (workspaceId: number | null) => {
    if (workspaceId && selectedWorkspace?.active_at?.toString?.() !== workspaceId?.toString()) {
      const temp = workspaceData.filter((item) => item.id === workspaceId)[0];
      setWorkspacePath(temp?.workspace_type_route as string, temp.id as number);
      setCurrentWorkspace(temp);
      history.push(`/${temp?.workspace_type_route?.toUpperCase?.()}/${temp?.id}/dashboard`);
    }
  };

  const startFetchingWorkspace = async (fetchAfterWorkspaceConfigChange = false, configChangeMessage = "Successfully removed workspace") => {
    setFetchingWorkspace(true);
    let response = {} as APIResponse;
    try {
      response = await workspaceClientV2.getAllWorkspaces();
    } catch (e: unknown) {
      response.success = false;
    } finally {
      if (response.success) {
        const workspaces = [
          ...response.data?.map((element: UserItem) => ({
            ...element,
            workspace_type_route:
              element?.workspace_type
                ?.split?.("_")
                ?.map?.((e: string) => e[0])
                .join("") ?? WorkspaceType.AR,
          })),
        ];
        setWorkspaceData(workspaces);

        // Required If we have multiple workspaces and we refresh on any of them
        // Finding if the url is having any workspace ids
        const getWorkspaceArray: Array<UserItem> = workspaces.filter(
          (element: UserItem) => element.id === parseInt(history?.location?.pathname?.split?.("/")[2], 10)
        );
        // If Yes getting the workspace details
        const getWorkspace: UserItem | undefined = getWorkspaceArray[0];
        if (getWorkspace) {
          // Setting the details based on workspaces
          const newPath = setWorkspacePath(getWorkspace.workspace_type_route ?? "", getWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
          setCurrentWorkspace({ ...(getWorkspace as UserItem) });
          history.push(
            newPath + "/" + history?.location?.pathname?.split("/")?.slice(NUMERIC_VALUES.CONSTANT_THREE)?.join("/") + history?.location?.search
          );
        } else {
          //If Url has no workspace like settings and profile we take the last selected workspace from session and keep the url
          const prevSelectedWorkspace: UserItem = workspaces.filter(
            (element: UserItem) => element.id === parseInt(sessionStorage.getItem("selectedWorkspaceId") ?? "1", 10)
          )[0];
          // If no workspace id in url or session that is a fresh app load take the default workspace
          const defaultWorkspace = workspaces?.filter?.((item: UserItem) => item?.default)[0] ?? workspaces[0];
          setWorkspacePath(
            prevSelectedWorkspace?.workspace_type_route ?? (defaultWorkspace?.workspace_type_route as string),
            prevSelectedWorkspace?.id ?? (defaultWorkspace.id as number)
          );
          setCurrentWorkspace({ ...(prevSelectedWorkspace ?? defaultWorkspace) });
          history.push(history?.location?.pathname + history?.location?.search);
        }
      }

      setFetchingWorkspace(false);
      if (fetchAfterWorkspaceConfigChange) {
        setTimeout(() => {
          setToastOptions({ open: true, severity: "success", message: configChangeMessage });
        }, NUMERIC_VALUES.CONSTANT_THOUSAND);
        setWorkspaceConfigChangeInProgress(false);
      }
    }
    return response.success || false;
  };

  const createNewWorkspace = async (accountConfig: WorkspaceCreate) => {
    setFetchingWorkspace(true);
    let response = {} as APIResponse;
    const toastOptions: ToastOptions = {
      open: true,
      severity: "error",
      message: "Workspace Creation Failed!",
    };
    try {
      response = await workspaceClientV2.createWorkspace(accountConfig as WorkspaceCreate, undefined, false);
    } catch (err: unknown) {
      response.success = false;
    } finally {
      if (response.success && response.data) {
        const setNewWorkspaceData: Array<UserItem> = [
          ...workspaceData,
          {
            ...response.data,
            name: (response.data as UserItem)?.workspace_name,
            user_count: (response.data as UserItem)?.user_count ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
            workspace_type: (response.data as UserItem)?.workspace_type,
            workspace_type_route:
              (response.data as UserItem)?.workspace_type
                ?.split?.("_")
                ?.map?.((e: string) => e[0])
                .join("") ?? WorkspaceType.AR,
          },
        ];

        setWorkspaceData(setNewWorkspaceData);
        const selectWorkspace = setNewWorkspaceData[setNewWorkspaceData.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE] as UserItem;
        const newPath = setWorkspacePath(selectWorkspace.workspace_type_route ?? "", selectWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
        setCurrentWorkspace(selectWorkspace);
        // history.push(newPath + "/" + history?.location?.pathname?.split("/")?.slice(3)?.join("/") + history?.location?.search);
        history.push(`${newPath}/dashboard`);
      } else {
        setToastOptions(toastOptions);
      }
      setFetchingWorkspace(false);
    }
    return response.success || false;
  };

  const removeWorkspace = async (workspace: UserItem) => {
    setWorkspaceConfigChangeInProgress(true);
    let res = {} as APIResponse;
    let toastOptions: ToastOptions = {
      open: true,
      severity: "error",
      message: "Unable to remove workspace at the moment",
    };
    if (!workspace.id) {
      setToastOptions(toastOptions);
      return;
    }
    try {
      res = await workspaceClientV2.deleteWorkspace(workspace.id, true);
    } catch (err: any) {
      toastOptions = {
        open: true,
        severity: "error",
        message: `${err?.response?.data?.messages?.errors?.[0] ?? "Unable to remove workspace at the moment"}`,
      };
    } finally {
      if (res.success) {
        startFetchingWorkspace(true);
      } else {
        setToastOptions(toastOptions);
        setWorkspaceConfigChangeInProgress(false);
      }
    }
  };

  const updateWorkspaceConfig = async (workspace: UserItem, newWorkspaceName: string, defaultMarker: boolean) => {
    setWorkspaceConfigChangeInProgress(true);
    let res = {} as APIResponse;
    let toastOptions: ToastOptions = {
      open: true,
      severity: "error",
      message: "Unable to edit workspace at the moment",
    };
    if (!workspace.id) {
      setToastOptions(toastOptions);
      return;
    }
    try {
      res = await workspaceClientV2.updateWorkspace(workspace?.id, newWorkspaceName, defaultMarker);
    } catch (error: any) {
      toastOptions = {
        open: true,
        severity: "error",
        message: `${error?.response?.data?.messages?.errors?.[0] ?? "Unable to edit workspace at the moment"}`,
      };
    } finally {
      if (res.success) {
        startFetchingWorkspace(true, "Successfully updated workspace");
      } else {
        setToastOptions(toastOptions);
        setWorkspaceConfigChangeInProgress(false);
      }
    }
  };

  /**
   * This effect will check if the workspace data has been updated by the AppContext
   * after it fetches the status information when user group has been changed, and sets the default workspace.
   */
  useEffect(() => {
    const defaultWorkspace = workspaceData.filter((item) => item.default)[0];
    if (defaultWorkspace && userGroupChanged) {
      switchActiveWorkspace(defaultWorkspace?.id);
    }
  }, [workspaceData, userGroupChanged]);

  return (
    <WorkspaceContext.Provider
      value={{
        createNewWorkspace,
        fetchingWorkspace,
        setFetchingWorkspace,
        workspaceData,
        setWorkspaceData,
        startFetchingWorkspace,
        switchActiveWorkspace,
        selectedWorkspace,
        workspaceHomePath,
        removeWorkspace,
        workspaceConfigChangeInProgress,
        setWorkspaceConfigChangeInProgress,
        updateWorkspaceConfig,
        userGroupChanged,
        setUserGroupChanged,
      }}
    >
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toastOptions.open}
        onClose={() => setToastOptions((prev) => ({ ...prev, open: false }))}
        autoHideDuration={3000}
      >
        <Alert
          icon={toastOptions.icon ?? ""}
          onClose={() => setToastOptions((prev) => ({ ...prev, open: false }))}
          severity={toastOptions.severity}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setToastOptions((prev) => ({ ...prev, open: false }))}>
              CLOSE
            </p>
          }
        >
          {toastOptions.message}
        </Alert>
      </Snackbar>
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;
