import React, { useState, useEffect } from "react";
import Loading from "../../../library/Loading/Loading";
import Error from "../../../library/Error/Error";
import NoData from "../../../library/NoData/NoData";
import BarChart, { calculateLeftMargin } from "../../../library/Charts/BarChart";
import { DashboardContext } from "../../../../contexts/DashboardContext";
import { getCurrencySuffix, shortenCurrency } from "../../../../db/utils/currency";
import { useHistory, useRouteMatch } from "react-router-dom";
import "./ReceivablesRisk.scss";
import { DateTime } from "luxon";
import { Tooltip, IconButton } from "@mui/material";
import { TooltipIcon } from "../../../library/Icons/Icons";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, TRIM_COUNT_CONSTANTS } from "../../../../constants/NumericConstants";
import { ComponentConfigContext } from "../../../../contexts/ComponentConfigContext";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { WorkspaceType } from "../../../../types/enums";
import { DRILLDOWN_NAVIGATION_CONSTANTS } from "../../../../constants/NavigationConstants";
import { AppContext } from "../../../../contexts/AppContext";

/**
 * * Defines the Risk Rate Widget
 */

interface ReceivableRiskInterface {
  disableInfoTooltip?: boolean;
}

export default function ReceivableRisk({ disableInfoTooltip }: ReceivableRiskInterface): React.ReactElement {
  // Get GET Method for DSO Report from Dashboard Context
  const { getRiskRateData } = React.useContext(DashboardContext) as DashboardType;
  const { userStatus } = React.useContext(AppContext) as AppType;
  // Loading state from GET API Call for RiskRate Report
  const [isLoading, setLoading] = useState<boolean>(false);
  // Error Message from GET API Call for RiskRate Report
  const [errorMessage, setErrorMessage] = useState<string>("");
  // Data from GET API Call for RiskRate Report
  const [data, setData] = useState<RiskRateModel[]>([]);
  // Data parsed into Graph Data from API Call
  const [graphData, setGraphData] = useState<GraphData[]>([]);
  // The latest month's atRiskAmount
  const [riskAmount, setRiskAmount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const history = useHistory();
  const { path } = useRouteMatch();
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const {
    dashboard: { barColors },
  } = getConfig(selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR) as any;

  // GET on /api/v1/Reports/riskrates
  const fetchData = async () => {
    // Default error message
    setErrorMessage("");
    // API call loading...
    setLoading(true);
    try {
      // On success, set data
      const res = await getRiskRateData();
      return res ?? [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error.message);
      setErrorMessage("Error Loading Data");
    }
    setLoading(false);
  };

  const handleOnClick = (id: string) => {
    const route =
      id === ""
        ? ""
        : id === DateTime.now().toFormat("MMM").toUpperCase()
        ? ""
        : `/${id.charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) + id.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE).toLowerCase()}_${DateTime.now().toFormat(
            "yy"
          )}`;
    history.push(`${path}/receivables_risk${route}?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.RECEIVABLES_RISK.SORT}`);
  };

  const handleResponse = (response: RiskRateModel[]) => {
    if (response) {
      // Set data from API
      setData(response);
      // Parse and set data from API into Graph Data
      setGraphData(() => {
        return response.map((val: RiskRateModel, index: number) => {
          return {
            id: val?.invoiceMonthName?.toUpperCase().substr(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, TRIM_COUNT_CONSTANTS.GRAPH_DATA) ?? "N/A",
            color: index === response.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? barColors.dark : barColors.light,
            value: val.atRiskPercentage ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          };
        });
      });
      setRiskAmount(
        response && response.length >= DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
          ? response[response.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE].atRiskAmount
          : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
      );
    }
  };

  // On mount, call API to get data for widget
  useEffect(() => {
    let cancelled = false;
    fetchData().then((response) => {
      if (!cancelled) {
        handleResponse(response ?? []);
        // API call finalized
        setLoading(false);
      }
    });
    return () => {
      cancelled = true;
    };
  }, []);

  return isLoading ? (
    <div className={`cr-wrapper`}>
      <Loading />
    </div>
  ) : errorMessage ? (
    <div className={`cr-wrapper`}>
      <Error>{errorMessage}</Error>
    </div>
  ) : data.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
    <div className={`cr-wrapper`}>
      <div className={`cr-top`}>
        <p className={`title`}>Receivables Risk</p>
        {!disableInfoTooltip && (
          <Tooltip title="The percentage of total AR balance left unpaid after 90 days." placement="right" arrow disableInteractive>
            <IconButton size="small">
              <TooltipIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <NoData />
    </div>
  ) : (
    <div className={`cr-wrapper`}>
      <div className={`cr-top`}>
        <p className={`title`} onClick={() => handleOnClick("")}>
          Receivables Risk
        </p>
        {!disableInfoTooltip && (
          <Tooltip title="The percentage of total AR balance left unpaid after 90 days." placement="right" arrow disableInteractive>
            <IconButton size="small">
              <TooltipIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={`cr-mid`}>
        <div className={`riskrate-wrapper`}>
          <p onClick={() => handleOnClick("")} className={`riskrate`}>
            {data &&
              data.length >= DEFAULT_NUMERIC_VALUES.DEFAULT_ONE &&
              data[data.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE]?.atRiskPercentage?.toPrecision(TRIM_COUNT_CONSTANTS.GRAPH_DATA)}
          </p>
          <p className={`riskrate-percent`}>%</p>
        </div>
        <div className={`improvement-wrapper`}>
          <div className={`improvement-amount-wrapper`}>
            <p className={`improvement-amount`}>{shortenCurrency(riskAmount, true)}</p>
            <p className={`improvement-currency`}>
              &nbsp;({userStatus?.currency?.symbol ?? "$"}
              {getCurrencySuffix(riskAmount)})&nbsp;
            </p>
          </div>
          <p className={`improvement-percentage`}>
            {data && data.length >= NUMERIC_VALUES.CONSTANT_TWO
              ? (
                  data[data.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE].atRiskPercentage -
                  data[data.length - NUMERIC_VALUES.CONSTANT_TWO]?.atRiskPercentage
                )?.toFixed(NUMERIC_VALUES.CONSTANT_TWO) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
              : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}
            % monthly improvement
          </p>
        </div>
      </div>
      <div className={`cr-bot`}>
        <BarChart
          hoverSquare={false}
          data={graphData}
          hoverFormat="percent"
          onClick={(id) => handleOnClick(id)}
          padding={15}
          margin={
            graphData.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
              ? {
                  top: 15,
                  right: 0,
                  bottom: 15,
                  left: calculateLeftMargin(graphData),
                }
              : graphData.length === NUMERIC_VALUES.CONSTANT_TWO
              ? {
                  top: 12,
                  right: 0,
                  bottom: 12,
                  left: calculateLeftMargin(graphData),
                }
              : graphData.length === TRIM_COUNT_CONSTANTS.GRAPH_DATA
              ? {
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: calculateLeftMargin(graphData),
                }
              : undefined
          }
          currency={userStatus?.currency}
        />
      </div>
    </div>
  );
}
