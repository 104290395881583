import React from "react";
import _ from "underscore";
import { formatDate } from "../../../../../../../db/utils/date";
import { Ellipse } from "../../../../../../library/Icons/Icons";
import { SmartText } from "../../../../../../library/SmartText";
import { BaseActivityProps } from "../SecondaryActivityTypes";
import "./BaseActivity.scss";

const BaseActivity: React.FC<BaseActivityProps> = ({ creator }) => {
  return (
    <div className="details-wrapper">
      <div className="left">
        <SmartText title={creator.name ?? ""} className="creator" />
        {!_.isNull(creator.message) && (
          <>
            <div className="separator">
              <Ellipse />
            </div>
            <SmartText title={creator.message} className="message" />
          </>
        )}
      </div>
      <div className="right">
        <div className="date">{formatDate(creator.createdOn)}</div>
      </div>
    </div>
  );
};

export default BaseActivity;
