import React from "react";
import { RequestApproval } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { ApprovalReviewedProps } from "../ActivityItemVariantTypes";

const Reviewed: React.FC<ApprovalReviewedProps> = (props) => {
  return <PrimaryActivity {...{ ...props, icon: <RequestApproval />, FLAGS: { REPLY_OPTIONS: true, TIME_SAVING_INDICATOR: true } }} />;
};

export default Reviewed;
