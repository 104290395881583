import React from "react";
import { RequestDetailsType } from "../../components/Approvals/ApprovalsInterfaces";
import { ApprovalActivityAccessor } from "../../db/unauthAccessors";

export const ApprovalDetailsContext = React.createContext<any>(null);

export type ApprovalsProviderType = {
  fetchRequestData: (id: string) => void;
  requestData: RequestDetailsType;
  setRequestData: React.Dispatch<React.SetStateAction<RequestDetailsType>>;
  loading: boolean;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};

const ApprovalDetailsProvider: React.FC<any> = ({ children }) => {
  // approval request data
  const [requestData, setRequestData] = React.useState<RequestDetailsType>({} as RequestDetailsType);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  /**
   * fetches approval request details and saves the raw response
   * in requestData state
   * @param id magic link uuid
   */
  const fetchRequestData = async (id: string) => {
    setLoading(true);
    let response = {} as any;
    try {
      response = await ApprovalActivityAccessor.getRequestDetails(id);
      setError(false);
    } catch {
      setError(true);
    }
    setRequestData({ ...response.data, success: response.success } as RequestDetailsType);
    setLoading(false);
  };

  return (
    <ApprovalDetailsContext.Provider
      value={{
        fetchRequestData,
        requestData,
        setRequestData,
        loading,
        error,
        setError,
      }}
    >
      {children}
    </ApprovalDetailsContext.Provider>
  );
};

export default ApprovalDetailsProvider;
