import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { DateTime } from "luxon";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { AppContext } from "../../../contexts/AppContext";
import "./APHeader.scss";
import { AP_HEADER_SKELETON_CONSTANTS } from "../../../constants/StyleConstants";
import Utils from "../../../utils/utils";

type HeaderType = {
  type: string;
  customLabel?: string;
};

export interface Props {
  list: HeaderType[];
  reportDate: string;
  reportType?: string;
}

export default function APHeader(props: Props): React.ReactElement {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [APHeader, setAPHeader] = useState<ApHeaderInfoModel>({} as ApHeaderInfoModel);
  const [DPOHeader, setDPOHeader] = useState<ApDPOInfoHeader>({} as ApDPOInfoHeader);
  const { getAPHeader, getDPOHeader } = React.useContext(AppContext) as AppType;
  const { userStatus } = React.useContext(AppContext) as AppType;

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(false);
      try {
        if (props.reportType && props?.reportType === "days_payables_outstanding") {
          getDPOHeader(props.reportDate).then((data) => {
            setDPOHeader(data);
          });
        }
        getAPHeader(props.reportDate).then((data) => {
          setAPHeader(data);
        });
      } catch (error: any) {
        setError(true);
        console.log(error);
      }
      setLoading(false);
    })();
  }, [props.reportDate]);

  return (
    <div className="APHeader-wrapper">
      {isLoading ? (
        props.list.map((_, index: number) => {
          return (
            <Skeleton
              key={`APHeader-${index}`}
              variant="text"
              width={Utils.getConvertedSizeInRem(AP_HEADER_SKELETON_CONSTANTS.WIDTH)}
              height={Utils.getConvertedSizeInRem(AP_HEADER_SKELETON_CONSTANTS.HEIGHT)}
              animation="wave"
            />
          );
        })
      ) : isError ? (
        <span className="text-span">
          <span className="text-label">Oops! Something Went Wrong... </span>
        </span>
      ) : (
        props.list.map((item: HeaderType, index: number) => {
          let label = "";
          let value: string | number = "";
          switch (item.type) {
            case "reportPeriod":
              label = item.customLabel ?? "Report Period";
              value = APHeader.reportPeriod ?? "N/A";
              break;
            case "totalVendors":
              label = item.customLabel ?? "Vendors";
              value = APHeader?.totalVendors ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "vendorsPaidPastThirtyDays":
              label = item.customLabel ?? "Vendors";
              value = APHeader?.vendorsPaidPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalBills":
              label = item.customLabel ?? "Bills";
              value = APHeader?.totalBills ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalBilledAmountPast30Days":
              label = item.customLabel ?? "Amount Billed";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(APHeader.amountBilledPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalPaidPast30Days":
              label = item.customLabel ?? "Amount Paid";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(APHeader.amountPaidPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalBilledAmount":
              label = item.customLabel ?? "Amount Billed";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(APHeader.totalBilledAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalAdvancePayments":
              label = item.customLabel ?? "Advance Payment Amount";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(APHeader.totalAdvancePayments ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "advancePaymentAmountPastThirtyDays":
              label = item.customLabel ?? "Advance Payment Amount";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(APHeader.advancePaymentAmountPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalPaid":
              label = item.customLabel ?? "Amount Paid";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(APHeader.totalPaid ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalArAmount":
              label = item.customLabel ?? "Total Balance";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(APHeader.totalApAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalBillsOpen":
              label = item.customLabel ?? "Bills";
              value = isNaN(APHeader.totalBills - APHeader.totalBillsPaid)
                ? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
                : APHeader.totalBills - APHeader.totalBillsPaid;
              break;
            case "totalBillsPaid":
              label = item.customLabel ?? "Bills Paid";
              value = APHeader?.totalBillsPaid ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "billsPaidPastThirtyDays":
              label = item.customLabel ?? "Bills Paid";
              value = APHeader?.billsPaidPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalBillsPastDue":
              label = item.customLabel ?? "Past Due Bills";
              value = APHeader?.totalBillsPastDue ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalBills90DaysPastDue":
              label = item.customLabel ?? "Past Due Bills";
              value = APHeader?.totalBills90DaysPastDue ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "billingVendorsPastThirtyDays":
              label = item.customLabel ?? "Vendors";
              value = APHeader?.billingVendorsPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "amountBilledPastThirtyDays":
              label = item.customLabel ?? "Amount Billed";
              value = APHeader?.amountBilledPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "billsPastThirtyDays":
              label = item.customLabel ?? "Bills";
              value = APHeader?.billsPastThirtyDays ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            case "totalPastDueAmount":
              label = item.customLabel ?? "Past Due Amount";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(APHeader.totalPastDueAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalPastDueAmount90Days":
              label = item.customLabel ?? "Past Due Amount";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(APHeader.totalPastDueAmount90Days ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "percentageOfTotalAp":
              label = item.customLabel ?? "Percentage of Total Ap";
              value = `${APHeader.percentageOfTotalAp ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}%`;
              break;
            case "percentageOfTotalAp90DaysPastDue":
              label = item.customLabel ?? "Percentage of Total Ap";
              value = `${APHeader.percentageOfTotalAp90DaysPastDue ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}%`;
              break;
            // case "dso":
            //   label = item.customLabel ?? "Days Sales Outstanding";
            //   value = APHeader?.dso ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
            //   break;
            case "totalBillAmountCurrentYeAp":
              label = item.customLabel ?? `Total Billed in ${DateTime.now().toFormat("yyyy")}`;
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(APHeader.totalBilledAmountCurrentYear ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "totalBilledAmountPreviousYear":
              label = item.customLabel ?? `Total Billed in ${DateTime.now().minus({ years: 1 }).toFormat("yyyy")}`;
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(APHeader.totalBilledAmountPreviousYear ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "vendors":
              label = item.customLabel ?? "Vendors";
              value = DPOHeader.vendors ?? "N/A";
              break;
            case "bills":
              label = item.customLabel ?? "Bills";
              value = DPOHeader.bills ?? "N/A";
              break;
            case "amountOutstanding":
              label = item.customLabel ?? "Amount Outstanding";
              value = new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: userStatus?.currency?.code ?? "USD",
              }).format(DPOHeader.amountOutstanding ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
              break;
            case "daysPayableOutstanding":
              label = item.customLabel ?? "Days Payable Outstanding";
              value = Utils.roundToPrecisionPoint(DPOHeader.dpo, DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
              break;
            default:
              label = "INVALID TYPE";
              break;
          }
          return (
            <span className="text-span" key={`APHeader-${index}`}>
              {value} <span className="text-label">{label}</span>
            </span>
          );
        })
      )}
    </div>
  );
}
