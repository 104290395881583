import React from "react";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import Navbar from "../../library/Navbar/Navbar";
import SettingsNavbar from "../../library/SettingsNavbar/SettingsNavbar";
import "./Settings.scss";
import Loading from "../../library/Loading/Loading";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";

const UserPermissions = React.lazy(() => import("../UserPermissions/UserPermissions"));
const EmailSettings = React.lazy(() => import("../EmailSettings/EmailSettings"));
const WorkspaceSettings = React.lazy(() => import("../WorkspaceSettings/WorkspaceSettings"));
const TemplateSettings = React.lazy(() => import("../TemplateSettings/TemplateSettings"));
const FourOFourError = React.lazy(() => import("../../../routes/FourOFourError/FourOFourError"));
const NotificationsSettings = React.lazy(() => import("../NotificationsSettings/NotificationsSettings"));
const ApAutomation = React.lazy(() => import("../ApAutomation/ApAutomation"));
const InboxSettings = React.lazy(() => import("../InboxSettings/InboxSettings"));
import SignatureSettings from "../SignatureSettings/SignatureSettings";

const userPermissionsRoutes = [
  {
    displayName: "Active Members",
    routeName: "userPermissions?sort=USERNAME+DESC",
  },
  {
    displayName: "Invitations",
    routeName: "userPermissions/invitations?sort=EMAIL+DESC",
  },
  {
    displayName: "Removed Members",
    routeName: "userPermissions/removed?sort=USERNAME+DESC",
  },
];
interface EmailRouteTypes {
  displayName: string;
  routeName: string;
}

const Settings = (): React.ReactElement => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { workspaceData, selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const [emailRoutes] = React.useState<Array<EmailRouteTypes>>(
    workspaceData.map((data: UserItem) => {
      return {
        displayName: `${data?.workspace_type_route?.toUpperCase()} Workspace`,
        routeName: `emailSettings/workspace/${data?.id}`,
      };
    })
  );
  const [templateSettingsRoutes] = React.useState<Array<EmailRouteTypes>>(
    workspaceData.map((data: UserItem) => {
      return {
        displayName: data?.workspace_type_route?.toUpperCase() === "AR" ? "Accounts Receivable" : "Accounts Payables",
        routeName: `templateSettings/workspace/${data?.id}`,
      };
    })
  );
  const isInboxSettings = location.pathname === `${path}/inboxSettings`;

  return (
    <div
      className={`settings-wrapper ${location.pathname.slice(location.pathname.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)} ${
        isInboxSettings ? "settings-wrapper-fullpage" : "settings-wrapper-tab"
      }`}
    >
      {isInboxSettings ? (
        <></>
      ) : (
        <div className={"head"}>
          <Route
            exact
            path={emailRoutes.map((val) => `${path}/${val.routeName}`)}
            component={() => {
              return (
                <Navbar
                  tabs={emailRoutes}
                  rootPath={`${path}`}
                  dropdownRootPath={path}
                  dropdown={[
                    {
                      displayName: "Users and Permissions",
                      routeName: "userPermissions?sort=USERNAME+DESC",
                    },
                    {
                      displayName: "Workspace Settings",
                      routeName: "workspaceSettings",
                    },
                    {
                      displayName: "Notifications Settings",
                      routeName: "notificationsSettings",
                    },
                  ]}
                  dropdownDefault="Email Settings"
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}/inboxSettings`}
                  handleBackRoute={true}
                />
              );
            }}
          />
          <Route
            exact
            path={userPermissionsRoutes.map((val) => `${path}/${val.routeName.split("?")[0]}`)}
            component={() => {
              return (
                <Navbar
                  tabs={userPermissionsRoutes}
                  rootPath={`${path}`}
                  dropdown={[
                    {
                      displayName: "Email Settings",
                      routeName: `emailSettings/workspace/${selectedWorkspace?.id}`,
                    },
                    {
                      displayName: "Workspace Settings",
                      routeName: "workspaceSettings",
                    },
                    {
                      displayName: "Notifications Settings",
                      routeName: "notificationsSettings",
                    },
                  ]}
                  dropdownRootPath={path}
                  dropdownDefault="Users and Permissions"
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}/inboxSettings`}
                  tooltip="Settings to manage the users on Insights."
                  handleBackRoute={true}
                />
              );
            }}
          />
          <Route
            exact
            path={`${path}/workspaceSettings`}
            component={() => {
              return (
                <Navbar
                  tabs={[
                    {
                      displayName: "Workspace Settings",
                      routeName: "workspaceSettings",
                    },
                  ]}
                  rootPath={`${path}`}
                  dropdown={[
                    {
                      displayName: "Email Settings",
                      routeName: `emailSettings/workspace/${selectedWorkspace?.id}`,
                    },
                    {
                      displayName: "Users and Permissions",
                      routeName: "userPermissions?sort=USERNAME+DESC",
                    },
                    {
                      displayName: "Notifications Settings",
                      routeName: "notificationsSettings",
                    },
                  ]}
                  dropdownRootPath={path}
                  dropdownDefault="Workspace Settings"
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}/inboxSettings`}
                  tooltip="Settings to manage workspaces."
                  handleBackRoute={true}
                />
              );
            }}
          />
          <Route
            exact
            path={templateSettingsRoutes.map((val) => `${path}/${val.routeName}`)}
            component={() => {
              return (
                <Navbar
                  tabs={templateSettingsRoutes}
                  rootPath={`${path}`}
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}/inboxSettings`}
                  handleBackRoute={true}
                />
              );
            }}
          />
          <Route
            exact
            path={`${path}/notificationsSettings`}
            component={() => {
              return (
                <Navbar
                  tabs={[
                    {
                      displayName: "Notifications Settings",
                      routeName: "notificationsSettings",
                    },
                  ]}
                  rootPath={`${path}`}
                  dropdown={[
                    {
                      displayName: "Email Settings",
                      routeName: `emailSettings/workspace/${selectedWorkspace?.id}`,
                    },
                    {
                      displayName: "Users and Permissions",
                      routeName: "userPermissions?sort=USERNAME+DESC",
                    },
                    {
                      displayName: "Workspace Settings",
                      routeName: "workspaceSettings",
                    },
                  ]}
                  dropdownRootPath={path}
                  dropdownDefault="Notifications Settings"
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}/inboxSettings`}
                  tooltip="Settings to manage notifications."
                  handleBackRoute={true}
                />
              );
            }}
          />
          <Route
            exact
            path={`${path}/signatureSettings`}
            component={() => {
              return <SettingsNavbar backRoute={`${path}/inboxSettings`} />;
            }}
          />
          <Route
            exact
            path={`${path}/apAutomation`}
            component={() => {
              return (
                <Navbar
                  tabs={[]}
                  rootPath={`${path}`}
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}/inboxSettings`}
                  handleBackRoute={true}
                  title="Forward to AP Automation Settings"
                  subtitle="Set-up and manage auto forward to AP automation tool."
                />
              );
            }}
          />
        </div>
      )}
      <div className={"body"}>
        <React.Suspense fallback={<Loading isRelative />}>
          <Switch>
            <Route exact path={`${path}/inboxSettings`} component={InboxSettings} />
            <Route path={`${path}/emailSettings/workspace/:workspaceId`} component={EmailSettings} />
            <Route path={`${path}/userPermissions`} component={UserPermissions} />
            <Route path={`${path}/workspaceSettings`} component={WorkspaceSettings} />
            <Route path={`${path}/notificationsSettings`} component={NotificationsSettings} />
            <Route path={`${path}/templateSettings/workspace/:workspaceId`} component={TemplateSettings} />
            <Route path={`${path}/apAutomation`} component={ApAutomation} />
            <Route path={`${path}/signatureSettings`} component={SignatureSettings} />
            <Route component={FourOFourError} />
          </Switch>
        </React.Suspense>
      </div>
    </div>
  );
};

export default Settings;
