import { ConnectionStatus } from "../../types/enums";
import APIClient from "../APIClient";

export class ActivitiesClient {
  /**
   * * GET on /api/v2/Activities/{id}
   * @param id - The unique Lockstep Platform ID number of this Activity
   * @param include - To fetch additional data on this object, specify the list of elements to retrieve.
   * @returns Promise of type ActivityModel or Exception
   */
  get(workspaceId: number, id: string, include?: string): Promise<APIResponse> {
    const url = `inbox/api/v2/workspaces/${workspaceId}/activity_streams/${id}`;
    const options = {
      params: {
        include,
      },
    };
    return APIClient.get(url, options).then((res) => res.data);
  }

  /**
   * * PATCH on /api/v2/Activities/{id}
   * @param id - The unique Lockstep Platform ID number of this Activity
   * @returns Promise of type ActivityModel or Exception
   */
  patch(
    workspaceId: number,
    id: string,
    action_type: ActionTypes,
    body: Partial<ActivitiesStreamUpdateBody>,
    bulk_update = false
  ): Promise<APIResponse> {
    const url = bulk_update
      ? `inbox/api/v2/workspaces/${workspaceId}/activity_streams/${action_type?.toLocaleLowerCase?.()}`
      : `inbox/api/v2/workspaces/${workspaceId}/activity_streams/${id}/${action_type?.toLocaleLowerCase?.()}`;
    return APIClient.patch(url, body).then((res) => res.data);
  }

  /**
   * * DELETE on /api/v2/Activities/{id}
   * @param id - The unique Lockstep Platform ID number of this Activity
   * @returns Promise of type ActivityModel or Exception
   */
  delete(workspaceId: number, id: string): Promise<ActivityModel> {
    const url = `inbox/api/v2/workspaces/${workspaceId}/Activities/${id}`;
    return APIClient.delete(url).then((res) => res.data);
  }

  /**
   * * POST on /api/v2/Activities
   * @param body - Array of ActivityModels to be posted
   * @returns Promise of type ActivityModel[] or Exception
   */
  post(workspaceId: number, body: Partial<ActivityModel>[]): Promise<ActivityModel[]> {
    const url = `inbox/api/v2/workspaces/${workspaceId}/activity_streams`;
    return APIClient.post(url, body).then((res) => res.data);
  }

  /**
   * * POST on /api/v2/activity_streams/${id}/activities/
   * @param body - contains details about Call Log/Note/Reply to Mail
   * @returns Promise of type APIRespons[] or Exception
   */
  postActivities(id: string, body: Partial<ActivityItemEmailModel>): Promise<APIResponse> {
    const url = `inbox/api/v2/workspaces/${body.workspace_id}/activity_streams/${id}/activities/`;
    return APIClient.post(url, body).then((res) => {
      return res.data;
    });
  }

  markAsRead(workspaceId: number, id: string): Promise<APIResponse> {
    const url = `inbox/api/v2/workspaces/${workspaceId}/activity_streams/${id}/activities/mark_as_read`;
    return APIClient.patch(url).then((res) => {
      return res.data;
    });
  }

  fetchTableRecords(
    assigneeType: string,
    workspaceId: number,
    status?: string,
    page?: number,
    page_size?: number,
    customerId?: string,
    sortQuery?: string,
    searchFilter?: SearchFilter[],
    spamFraudArchivedTab?: boolean,
    others?: string
  ): Promise<any> {
    //add a check to include &connection_status_not_in only on activities table not uder spam and fraud tabs and fetch attachments on index calls
    let url = `inbox/api/v2/workspaces/${workspaceId}/activity_streams/?assignee_type=${assigneeType}&page=${page}&page_size=${page_size}&attachments=true`;
    if (!spamFraudArchivedTab) {
      url = `${url}&connection_status_not_in=${ConnectionStatus.SPAM},${ConnectionStatus.FRAUD},${ConnectionStatus.ARCHIVED}`;
    }
    if (status) {
      url = `${url}&status=${status}`;
    }
    if (customerId) {
      url = `${url}&qa[primary_connection_id_eq]=${customerId}`;
    }
    if (sortQuery) {
      sortQuery = sortQuery && !sortQuery.includes("DESC") ? `${sortQuery} ASC` : sortQuery;
      url = `${url}&qs[s]=${sortQuery.toLowerCase()}`;
    }
    if (searchFilter && searchFilter.length) {
      let constructedFilter = "";
      searchFilter.map((filter: SearchFilter) => {
        constructedFilter = constructedFilter + `&qa[${filter.filterKey}_${filter.filterToken}]=${filter.filterValue}`;
      });
      url = url + constructedFilter;
    }
    if (others) {
      url = `${url}${others}`;
    }
    return APIClient.get(url).then((res) => {
      return res.data;
    });
  }

  getActivityStreamMeta(workspaceId: number): Promise<ActivityStreamMeta> {
    const url = `inbox/api/v2/workspaces/${workspaceId}/activity_streams/meta?connection_status_not_in=${ConnectionStatus.SPAM},${ConnectionStatus.FRAUD},${ConnectionStatus.ARCHIVED}`;
    return APIClient.get(url).then((res) => {
      return res.data;
    });
  }

  /**
   * * GET on /api/v2/Activities/query
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - Specify options
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type ActivityModelFetchResult or Exception
   */
  query(
    workspaceId: number,
    filter?: string,
    include?: string,
    order?: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<ActivityModelFetchResult> {
    const url = `inbox/api/v2/workspaces/${workspaceId}/activity_streams/query`;
    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };
    return APIClient.get(url, options).then((res) => res.data);
  }

  /**
   * GET on /api/v2/Activities/{id}/stream
   * @param id - The id of the activity to retrieve the stream for
   * @returns Promise of type ActivityItemModel[] or Exception
   */
  getStream(workspaceId: number, id: string, params?: any): Promise<ActivityStreamFetchResponse> {
    const queryString = new URLSearchParams(params).toString();
    const url = `inbox/api/v2/workspaces/${workspaceId}/activity_streams/${id}/activities?${queryString}`;
    return APIClient.get(url).then((res) => res.data);
  }

  getStreamWithFilter(workspaceId: number, id: string, include?: any): Promise<ActivityStreamFetchResponse> {
    const url = `inbox/api/v2/workspaces/${workspaceId}/activity_streams/${id}/activities`;
    const options = {
      params: {
        "qa[content_type_eq]": include,
      },
    };
    return APIClient.get(url, options).then((res) => res.data);
  }
  /**
   * * POST on /api/v2/Activities/{activityId}/forward/{userId}
   * @param activityId - Id of the activity to forward
   * @param userId - Id of the user to forward the activity to
   * @returns Promise of type ActivityModel or Exception
   */
  forward(workspaceId: number, activityId: string, userId: string): Promise<ActivityModel> {
    const url = `inbox/api/v2/workspaces/${workspaceId}/activity_streams/${activityId}/forward/${userId}`;
    return APIClient.post(url).then((res) => res.data);
  }

  /**
   * GET on inbox/api/v2/activity_streams
   * @param workspaceId - The id of the workspace to retrieve the data for
   * @param id - The id of the payment/invoice type to retrieve the stream for
   * @param type - The type of the request to retrieve the stream for
   * @returns Promise of type ActivityItemModel[] or Exception
   */
  fetchRelatedStreams(workspaceId: number, id: string, type: string, page?: number, pageSize?: number): Promise<APIResponse> {
    const url = `inbox/api/v2/workspaces/${workspaceId}/activity_streams?page=${page}&page_size=${pageSize}`;
    const options = {
      params: {
        "qa[activity_transactions_transaction_id_eq]": id,
        "qa[activity_transactions_transaction_type_eq]": type,
        assignee_type: "all",
      },
    };

    return APIClient.get(url, options).then((res) => res.data);
  }

  reviewActivityRequest(workspaceId: number, activityId: UUID, requestId: string, reviewAction: string, reviewNote: string): Promise<APIResponse> {
    const url = `inbox/api/v2/workspaces/${workspaceId}/activity_streams/${activityId}/activities/${requestId}/review`;
    const payload = {
      review_action: reviewAction,
      note: reviewNote,
    };
    return APIClient.patch(url, payload).then((res) => res.data);
  }
}
