/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports from react
import React, { useMemo, useEffect, MouseEvent, useState, forwardRef, useImperativeHandle, SetStateAction, Dispatch } from "react";
// Imports from react-table
import { useTable, usePagination, useRowSelect, TableState, Row, HeaderProps, CellProps } from "react-table";
// Import reusable styled-components
import { InputWithIcon } from "../Input/Input";
import IndeterminateCheckbox from "./Checkbox/Checkbox";
import { FilterButton } from "./FilterButton/FilterButton";
import { SortButton } from "./SortButton/SortButton";
import { ExportButton } from "./ExportButton/ExportButton";
import Loading from "../Loading/Loading";
import { TooltipIcon, LeftArrow, RightArrow } from "../Icons/Icons";
// Imports from react-router-dom
import { useHistory, useRouteMatch, useParams, useLocation } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import NoData from "../NoData/NoData";
import Button, { ButtonProps } from "../Button/Button";
import SoftAlert from "../SoftAlert/SoftAlert";
import { useContext } from "react";
import { InvoiceContext } from "../../../contexts/InvoiceContext";
import { ActivityStatus, FallbackTypes, InvoiceStatusType } from "../../../types/enums";
import { Tooltip, IconButton, AutocompleteRenderInputParams, TextField } from "@mui/material";
import { DateTime } from "luxon";
import HoverBar from "../../Activities/Mine/HoverBar/Hoverbar";
import { getConditionalAllRowsSelectedProps } from "./getConditionalAllRowsSelectedProps";
import APIClientUtils from "../../../utils/APIClientUtils/APIClientUtils";
import "./Table.scss";
import Autocomplete from "../Autocomplete/Autocomplete";
import Menu from "../Menu/Menu";
import MenuItem from "../MenuItem/MenuItem";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { BUCKET_CONSTANTS, DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { ActivityContext } from "../../../contexts/ActivityContext";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import _ from "underscore";

const INTERVAL_SIZE = 40;

const PAGINATION_DROPDOWN = Object.keys(DEFAULT_PAGINATION_VALUES.PAGE_SIZE_OPTIONS).map(
  (e: string) => DEFAULT_PAGINATION_VALUES.PAGE_SIZE_OPTIONS[e]
);

// Defines a Type for Column Headers
interface ColumnHeaderType {
  Header: string;
  accessor: string;
  searchlightField?: string;
  searchlightToken?: string;
  sortField?: string;
  upperBoundToken?: string;
}
export interface HeaderButton {
  component: React.ReactElement;
  hideOnRowSelect?: boolean;
}

export interface RowSelectButton extends Omit<ButtonProps, "onClick"> {
  callback: (ids: string[]) => void;
}

export type RowSelectButtons = RowSelectButton | "divider";

type dataSet = {
  id: string;
  displayName: string;
  rowSelectToken?: string;
  data: {
    tableData: TableData[];
    setTableData: Dispatch<SetStateAction<TableData[]>>;
    fetchCall: (
      assigneeType: string,
      workspace_id: number,
      status?: string,
      page?: number,
      page_size?: number,
      customerId?: string,
      sortQuery?: string,
      searchFilter?: SearchFilter[],
      spamFraudArchivedTab?: boolean,
      others?: string
    ) => Promise<any>;
    fetchParser: (response: FetchResult, variant?: FetchVariant) => Partial<TableData>[];
    includeOption?: string;
    selectAllFilter?: string;
    disableCheckboxMessage?: string;
  };
  columns: ColumnHeaderType[] | null;
  columnDeps?: any;
  hiddenColumns?: string[];
  export?: {
    exportFileName: string;
  };
  defaultSort?: string;
  defaultSortToken?: string;
  handleRowClick: (e: MouseEvent<HTMLTableRowElement>, row: { original: { [key: string]: unknown } }) => void;
  predefinedFilters?: tabFilter[];
  assigneeType?: string;
  route?: string;
};

const defaultProps = {
  toggles: {
    showCaption: true as boolean,
    showRowSelect: true as boolean,
    showNavigation: true as boolean,
    showExportBtn: true as boolean,
    showSearchbar: true as boolean,
    showSelectAllBar: true as boolean,
  },
};

// Defines the Table Props
type Props = {
  title?: string;
  dataSets: dataSet[];
  states: {
    isLoading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    isError: boolean;
    setError: Dispatch<SetStateAction<boolean>>;
    errorMessage: string;
    setErrorMessage: Dispatch<SetStateAction<string>>;
  };
  pagination: {
    pageCount: number;
    setPageCount: Dispatch<SetStateAction<number>>;
    pageNumber: number;
    setPageNumber: Dispatch<SetStateAction<number>>;
    totalCount: number;
    setTotalCount: Dispatch<SetStateAction<number>>;
    setPageSize: Dispatch<SetStateAction<number>>;
  };
  toggles?: {
    showCaption?: boolean;
    showNavigation?: boolean;
    showRowSelect?: boolean;
    showExportBtn?: boolean;
    showSearchbar?: boolean;
    showSelectAllBar?: boolean;
  };
  hoverBar?: boolean;
  activityHandler?: (row: Row<TableData>) => void;
  email?: boolean;
  headerBtns?: HeaderButton[];
  rowSelectBtns?: RowSelectButtons[];
  onRowSelect?: (val: any) => void;
} & Partial<typeof defaultProps>;

/**
 * * Defines a reusable Table from react-table
 */
// eslint-disable-next-line react/display-name
const TableForActivityStreams = forwardRef<TableHandle, Props>((props: Props, ref) => {
  const params = new URLSearchParams(window.location.search);
  const pageSizeFromUrl = params.get("pageSize");
  const { customerId } = useParams<{ customerId: string }>();
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { setPageFetchParams } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { url } = useRouteMatch();
  const history = useHistory();
  const [pageSize, setPageSize] = useState<number>(parseInt(pageSizeFromUrl ?? "25"));
  const { lastFilterItem, setLastFilterItem } = useContext(InvoiceContext) as InvoiceType;
  const [dataSetIndex, setDataSetIndex] = useState<number>(() => {
    const dataSetIndexQueryString = parseInt(params.get("dataSetIndex") ?? "0", 10);
    if (isNaN(dataSetIndexQueryString)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    } else {
      return dataSetIndexQueryString <= DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
        ? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
        : dataSetIndexQueryString >= props.dataSets.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? props.dataSets.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        : dataSetIndexQueryString;
    }
  });
  const disableCheckboxMessage = props.dataSets[dataSetIndex].data.disableCheckboxMessage;
  const data = useMemo<TableData[]>(() => props.dataSets[dataSetIndex].data.tableData, [props.dataSets[dataSetIndex].data.tableData]);
  const columns = useMemo<any>(
    () => props.dataSets[dataSetIndex].columns,
    [dataSetIndex, props.pagination.totalCount, ...(props?.dataSets[dataSetIndex]?.columnDeps ? props?.dataSets[dataSetIndex]?.columnDeps : [])]
  );
  // Row-select states
  const [currentSelectedRows, setCurrentSelectedRows] = useState({});
  const { allRowsSelected, setAllRowsSelected, setActivityStatus, setUserView, setTableColumnFilters } = useContext(ActivityContext) as ActivityType;

  // const [] = useState<boolean>(false);
  const [showSelectAll, setShowSelectAll] = useState<boolean>(false);
  const location = useLocation();

  const setViewAndStatus = (url: string) => {
    const status = url.slice(url.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
    setUserView(props.dataSets[dataSetIndex].assigneeType ?? "");
    if (status === props.dataSets[dataSetIndex].assigneeType || status === "mine") {
      setActivityStatus("active");
    } else if (status === "waitingForResponse") {
      setActivityStatus("waiting_for_response");
    } else {
      setActivityStatus(status);
    }
  };

  // Pagination Menu
  const [paginationMenuAnchorEl, setPaginationMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const paginationMenuOpen = Boolean(paginationMenuAnchorEl);

  const addInvoiceDaysPastDueFilters = (
    value: string | null,
    searchlightField: string | null,
    searchlightToken: string | null,
    filters: any[],
    upperBoundToken?: string | null
  ) => {
    const valueInt = parseInt(value ?? "0", 10);
    const status = InvoiceStatusType[valueInt];
    const today = DateTime.utc(DateTime.utc().year, DateTime.utc().month, DateTime.utc().day);
    if (!value || !searchlightField || !searchlightToken) {
      return;
    }

    if (status === "CLOSED") {
      filters.push({ field: "STATUS", token: "eq", value: "CLOSED" });
    } else if (status === "OPEN") {
      filters.push({ field: "STATUS", token: "eq", value: "OPEN" });
    } else {
      filters.push({ field: "STATUS", token: "ne", value: "CLOSED" });
      filters.push({
        field: "PAYMENTDUEDATE",
        token: searchlightToken,
        value: today.minus({ days: valueInt }),
      });
    }

    if (upperBoundToken && valueInt >= DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && valueInt < BUCKET_CONSTANTS.FOURTH_PAST_DUE_BUCKET) {
      filters.push({
        field: "PAYMENTDUEDATE",
        token: upperBoundToken,
        value: today.minus({ days: valueInt + BUCKET_CONSTANTS.SECOND_PAST_DUE_BUCKET }),
      });
    }
  };

  // * Get Searchlight formatted order from URL Query param 'sort'
  function getSortFromURL(): string | undefined {
    return params.get("sort") ?? undefined;
  }

  // * Get Searchlight formatted filters from URL Query params (Defined in Columns)
  function getFilterFromURL(): SearchFilter[] {
    const ret: SearchFilter[] = [];
    const filters: any[] = [];

    columns.map((column: ColumnHeaderType) => {
      const value = params.get(column.accessor)?.replaceAll(/'/g, "''") ?? null;
      filters.push({ field: column.searchlightField ?? null, token: column.searchlightToken ?? null, value: value ?? null });
    });
    filters.forEach((filter: { field: string; token: string; value: string | null }) => {
      if (filter.value !== null) {
        ret.push({ filterKey: filter.field, filterToken: filter.token, filterValue: filter.value });
      }
    });
    setTableColumnFilters(ret);
    return ret;
  }

  const checkIfSpamFraudArchivedTab = () => {
    return url.toLowerCase().includes("/spam")
      ? true
      : url.toLowerCase().includes("/fraud")
      ? true
      : url.toLowerCase().includes("/archived")
      ? true
      : false;
  };

  /**
   * * Helper function for current data set's fetch call. Calls to fetchParser (on onlyIds = true).
   * @param onlyIds - If true, return only ids. Otherwise, returns parsed Table Data Array.
   * @returns Generic Fetch result with records replaced by parsed Table Data Array
   */
  const fetch = async (variant?: FetchVariant, additionalFilters?: string): Promise<TableFetchResult> => {
    const statusArray = Object.keys(ActivityStatus).map((e) => Object(ActivityStatus)[e]?.toLocaleLowerCase?.());
    let searchStatus = url.slice(url.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
    let others = url.slice(url.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
    if (searchStatus === "waitingForResponse") {
      searchStatus = "waiting_for_response";
    } else if (!statusArray.includes(searchStatus)) {
      searchStatus = "active";
    } else if (props.dataSets[dataSetIndex].assigneeType === "spam") {
      searchStatus = "";
    }
    searchStatus = url.includes("customers") || url.includes("vendors") || url.includes("connections") ? "" : searchStatus;
    others = url.includes("customers") || url.includes("vendors") || url.includes("connections") ? "&qa[status_not_eq]=4" : "";
    const fetchParams = {
      assigneeType: props.dataSets[dataSetIndex].assigneeType ?? "me",
      workspaceId: selectedWorkspace?.id || FallbackTypes.Id,
      status: searchStatus,
      page: props.pagination.pageNumber,
      page_size: pageSize,
      customerId: customerId,
      sortQuery: getSortFromURL(),
      searchFilter: getFilterFromURL(),
      spamFraudArchivedTab: checkIfSpamFraudArchivedTab(),
      others: others,
    };
    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    setPageFetchParams(fetchParams as PageFetchParams);

    return await props.dataSets[dataSetIndex].data
      .fetchCall(
        props.dataSets[dataSetIndex].assigneeType ?? "me",
        selectedWorkspace?.id || FallbackTypes.Id,
        searchStatus,
        props.pagination.pageNumber,
        pageSize,
        customerId,
        getSortFromURL(),
        getFilterFromURL(),
        checkIfSpamFraudArchivedTab(),
        others
      )
      .then((fetchResult: any) => {
        return { ...fetchResult, records: props.dataSets[dataSetIndex].data.fetchParser(fetchResult, variant) };
      });
  };

  /**
   * * Fetches parsed Table Data and sets loading, error, and pagintion states
   * @param disableLoad - If true, disables loading animation. Otherwise, show loading animation.
   */
  const fetchTableData = async (disableLoad?: boolean) => {
    props.states.setError(false);
    props.states.setErrorMessage("");
    props.states.setLoading(!disableLoad);
    try {
      const fetchResult = await fetch("all");
      props.dataSets[dataSetIndex].data.setTableData(fetchResult?.records ?? []);
      props.pagination.setPageCount(
        Math.ceil((fetchResult.total_records ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) / (fetchResult.page_size ?? NUMERIC_VALUES.CONSTANT_TWELVE))
      );
      props.pagination.setTotalCount(fetchResult.total_records ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
      return fetchResult?.data ?? [];
    } catch (err: any) {
      props.states.setError(true);
      props.states.setErrorMessage(APIClientUtils.buildErrorMessage(err));
    } finally {
      props.states.setLoading(false);
    }
  };

  /**
   * * Fetches all ids of rows, excluding those which are disabled
   * @returns Array of Table Data consisting only of ids
   */
  const fetchAllRowIds = async (additionalFilters?: string): Promise<TableData[]> => {
    try {
      return (await fetch("id", additionalFilters))?.data ?? [];
    } catch (err: any) {
      props.states.setError(true);
      props.states.setErrorMessage(APIClientUtils.buildErrorMessage(err));
      return [];
    }
  };

  const checkAllRows = (data: any) => {
    if (allRowsSelected) {
      return data.map((row: any) => ({
        id: row.id,
        value: true,
      }));
    } else {
      return currentSelectedRows;
    }
  };

  const { dispatch, getTableBodyProps, headerGroups, prepareRow, page, visibleColumns, state } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: props.pagination.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
        pageSize: pageSize,
        hiddenColumns: props.dataSets[dataSetIndex].hiddenColumns ?? [],
        selectedRowIds: currentSelectedRows,
      },
      autoResetSelectedRows: false,
      stateReducer: (newState, action, prevState) => {
        newState = { ...newState, totalCount: props.pagination.totalCount } as TableState;
        if (action.type === "toggleAllRowsAllPagesSelected") {
          setCurrentSelectedRows(action.payload.currentSelectedRows);
          setAllRowsSelected(action.payload.allRowsSelected);
          return {
            ...newState,
            selectedRowIds: action.payload.currentSelectedRows,
          };
        } else if (action.type === "clearRowSelection") {
          if (!_.isEmpty(currentSelectedRows) || showSelectAll) {
            setCurrentSelectedRows({});
            setShowSelectAll(false);
            setAllRowsSelected(false);
          }
          return { ...newState, selectedRowIds: {} };
        } else if (action.type === "toggleRowSelected") {
          if (!_.isEmpty(newState.selectedRowIds)) {
            setCurrentSelectedRows(newState.selectedRowIds);
            setAllRowsSelected(Object.keys(newState.selectedRowIds).length === props.pagination.totalCount);
          } else {
            setCurrentSelectedRows({});
          }
        }
        return newState;
      },
      manualPagination: true,
      pageCount: props.pagination.pageCount,
      getRowId: (row) => row.id,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      props.toggles?.showRowSelect
        ? hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              // eslint-disable-next-line react/display-name
              Header: (headerProps: HeaderProps<any>) => {
                const checkboxProps = getConditionalAllRowsSelectedProps({
                  headerProps: { ...headerProps, totalCount: props.pagination.totalCount },
                  isRowSelectable: (row) => !row.original.disableCheckbox,
                });

                useEffect(() => {
                  if (checkboxProps.indeterminate ?? false) {
                    setShowSelectAll(true);
                  } else if ((checkboxProps.indeterminate ?? false) === false && checkboxProps.checked === false) {
                    setShowSelectAll(false);
                  }
                }, [checkboxProps.indeterminate, checkboxProps.checked]);

                return (
                  <div className={`selection-wrapper`}>
                    <IndeterminateCheckbox {...checkboxProps} />
                  </div>
                );
              },
              // eslint-disable-next-line react/display-name
              Cell: (props: CellProps<any>) => {
                return (
                  <>
                    {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      <Tooltip
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        title={props.row.original.disableCheckbox ? disableCheckboxMessage : ""}
                        placement="top-start"
                        componentsProps={{ tooltip: { sx: { maxWidth: "none" } } }}
                        disableInteractive
                      >
                        <div className={`selection-wrapper`}>
                          {/*
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore */}
                          <IndeterminateCheckbox {...props.row.getToggleRowSelectedProps()} disabled={props.row.original.disableCheckbox} />
                        </div>
                      </Tooltip>
                    }
                  </>
                );
              },
            },
            ...columns,
          ])
        : null;
    }
  );

  function handleOnChangeDataSetIndex(index: number) {
    params.forEach((_val, key: string) => {
      if (key !== "dataSetIndex") {
        params.delete(key);
      }
    });
    params.set("dataSetIndex", String(index));
    history.push({ search: params.toString().replace(/[?&]$/, "") });
  }

  const appendPageSizeParams = (pageSize: number) => {
    if (pageSize > props.pagination.totalCount || pageSize * props.pagination.pageNumber > props.pagination.totalCount) {
      params.set("page", "1");
      window.history.replaceState(null, "", location.search.replace(/page=[0-9]/, `page=${DEFAULT_NUMERIC_VALUES.DEFAULT_ONE}`));
    }
    params.set("pageSize", `${pageSize}`);
    window.history.replaceState(null, "", location.search.replace(/pageSize=[0-9]+/, `pageSize=${pageSize}`));
  };

  const handleDropdownSelect = (value: number) => {
    if (
      props.pagination.totalCount <
        value * ((props.pagination.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) -
          (value - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) &&
      props.pagination.setPageNumber
    ) {
      props.pagination.setPageNumber(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
    }
    appendPageSizeParams(value);
    setPageSize(value);
    if (props.pagination?.setPageSize) {
      props.pagination.setPageSize(value);
    }
  };

  const handleRowRead = (row: any) => {
    return row.isUnread ? "" : " read";
  };

  /**
   * * Exposes useful functions to parent components via refs
   */
  useImperativeHandle(ref, () => ({
    async hardRefresh() {
      const data = await fetchTableData();
      dispatch({ type: "clearRowSelection" });
      return data;
    },
    async softRefresh() {
      const data = await fetchTableData(true);
      dispatch({ type: "clearRowSelection" });
      return data;
    },
    getCurrentSelectedRows() {
      const selectedRows = Object.keys(currentSelectedRows) as Array<string>;
      return props.dataSets[dataSetIndex].data.tableData.filter((val) => selectedRows.includes(val.id));
    },
  }));

  const handleClearSelection = () => {
    dispatch({ type: "clearRowSelection" });
  };

  const handleSelectAllPages = () => {
    fetchAllRowIds(props.dataSets[dataSetIndex].data.selectAllFilter).then((data: any) => {
      const dataFormatted = data.reduce((obj: any, item: any) => Object.assign(obj, { [item.id as string]: true }), {});
      setShowSelectAll(true);
      dispatch({
        type: "toggleAllRowsAllPagesSelected",
        payload: {
          currentSelectedRows: dataFormatted,
          allRowsSelected: true,
        },
      });
    });
  };

  /**
   * * Handles exporting selected row data in batches, returning an array of Table Data elements
   * @returns Table Data Array
   */
  const handleExport = async (): Promise<{ [key: string]: any }[]> => {
    let idList: string[] = [];
    // If no rows selected, then export all rows
    if (Object.keys(currentSelectedRows).length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      await fetchAllRowIds().then((data: TableData[]) => {
        idList = data.map((elem: TableData) => elem.id);
      });
    }
    // Otherwise, export selected rows
    else {
      idList = Object.keys(state.selectedRowIds) as Array<string>;
    }
    // Handle fetching row data for idList in batches
    const promises: Promise<FetchResult>[] = [];
    // for (let i = 0; i < Math.ceil(idList.length / INTERVAL_SIZE); i++) {
    //   const idListBatch = idList.slice(i * INTERVAL_SIZE, i * INTERVAL_SIZE + INTERVAL_SIZE);
    //   promises.push(fetch("export", `${props.dataSets[dataSetIndex].rowSelectToken} in (${idListBatch.join(", ")})`));
    // }
    // Flatten FetchResult[] to string[] of ids
    return await Promise.all(promises).then((values) =>
      values.reduce((prev: { [key: string]: any }[], curr: FetchResult) => {
        return [...prev, ...(curr?.records ?? [])];
      }, [])
    );
  };

  /**
   * * Removes filter causing Searchlight Error
   */
  const removeBadFilter = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete(lastFilterItem);
    history.push({ search: params.toString().replace(/[?&]$/, "") });
    setLastFilterItem("");
  };

  // * On mount, handle default sort and fetching table data
  useEffect(() => {
    // Handle default sort
    if (props?.dataSets[dataSetIndex]?.defaultSort && params.get("sort") === null) {
      params.delete("sort");
      const parsedDefaultSort =
        props?.dataSets[dataSetIndex]?.defaultSort
          ?.split(", ")
          .map(
            (field: string) => field + (props?.dataSets[dataSetIndex]?.defaultSortToken ? ` ${props?.dataSets[dataSetIndex]?.defaultSortToken}` : "")
          )
          .join(", ") ?? "";
      params.append("sort", parsedDefaultSort);
      history.push({ search: params.toString().replace(/[?&]$/, "") });
    }
    // Handle fetch table data
    fetchTableData();
  }, [props.pagination.pageNumber, pageSize, dataSetIndex]);

  // * Handle onRowSelect callback
  useEffect(() => {
    if (props.onRowSelect) {
      const selectedRowIds = Object.keys(currentSelectedRows) as Array<string>;
      props.onRowSelect(props.dataSets[dataSetIndex].data.tableData.filter((val) => selectedRowIds.includes(val.id)));
    }
  }, [currentSelectedRows]);

  const calcTableHead = (index: number, headerLength: number) => {
    if (index === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && props.toggles?.showRowSelect) {
      return "head-col-first";
    } else if (index === headerLength) {
      return "head-col-last";
    } else if (index === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      return "head-col-no-checkbox-first";
    } else {
      return "";
    }
  };

  const calcTableBody = (index: any, rowLength: number) => {
    if (index === rowLength) {
      return "col-last ";
    } else if (index === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && props.toggles?.showRowSelect) {
      return "col-first";
    } else if (index === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      return "col-no-checkbox-first";
    } else {
      return "";
    }
  };

  const appendPaginationParams = (page: number) => {
    params.set("page", `${page}`);
    window.history.replaceState(null, "", location.search.replace(/page=[0-9]/, `page=${page}`));
  };

  const showSelectAllBar = props?.toggles?.showSelectAllBar ? props.toggles.showSelectAllBar : false;

  useEffect(() => {
    if (allRowsSelected) {
      dispatch({
        type: "toggleAllRowsAllPagesSelected",
        payload: {
          currentSelectedRows: checkAllRows(data).reduce((acc: any, item: any) => ({ ...acc, [item.id]: item.value }), {}),
          allRowsSelected: true,
        },
      });
      setShowSelectAll(true);
    }
  }, [data]);

  useEffect(() => {
    dispatch({ type: "clearRowSelection" });
  }, [props.pagination.totalCount]);

  /**
   * fetch table data when sort params changes or filter set changes,
   * for customer activities
   */

  useEffect(() => {
    if (url.includes("customers") || url.includes("vendors")) {
      fetchTableData();
    }
  }, [location.search]);

  useEffect(() => {
    setViewAndStatus(url);
  }, [url]);

  return (
    <div
      className={`main-table-wrapper`}
      style={{
        gridTemplateRows:
          props.toggles?.showCaption && props.toggles?.showNavigation
            ? "5.625rem auto 3rem"
            : props.toggles?.showCaption
            ? "5.625rem auto"
            : props.toggles?.showNavigation
            ? "auto 3rem"
            : "auto",
      }}
    >
      {/* Table Caption */}
      {props.toggles?.showCaption && (
        <div className={`table-caption ${!props.title ? "table-caption-no-title" : ""}`}>
          {props.title && <p className={`title`}>{props.title}</p>}
          {props.toggles?.showExportBtn && (
            <ExportButton
              getExportData={() => handleExport()}
              exportFileName={props.dataSets[dataSetIndex].export?.exportFileName ?? ""}
              alignIcon="left"
              disabled={
                props.states.isLoading ||
                props.states.isError ||
                props.dataSets[dataSetIndex].data.tableData.length <= DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
              }
            />
          )}
          {props.headerBtns &&
            props.headerBtns.map((headerBtn: HeaderButton, index: number) => {
              return (
                <div key={index.toLocaleString()}>
                  {(Object.keys(currentSelectedRows).length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO || !headerBtn.hideOnRowSelect) &&
                    headerBtn.component}
                </div>
              );
            })}
          {props.rowSelectBtns &&
            Object.keys(currentSelectedRows).length !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
            props.rowSelectBtns.map((elem, index: number) => {
              if (elem === "divider") {
                return (
                  <div key={index} className="table-button-divider">
                    |
                  </div>
                );
              } else {
                return (
                  <Button
                    key={`rowSelectButton-${index}`}
                    {...elem}
                    onClick={() => elem.callback(Object.keys(currentSelectedRows) as Array<string>)}
                  />
                );
              }
            })}
          <div className="dataset-dropdown-wrapper">
            {props.dataSets.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && (
              <Autocomplete
                options={props.dataSets.map((set: dataSet, index: number) => {
                  return {
                    label: set.displayName,
                    id: index,
                  };
                })}
                onChange={(_event, value: { label: string; id: number }) => handleOnChangeDataSetIndex(value.id)}
                value={props.dataSets[dataSetIndex].displayName}
                disableClearable
                isOptionEqualToValue={(option: { label: string; id: number }, value: string) => option.label === value}
                renderInput={(inputParams: AutocompleteRenderInputParams) => (
                  <TextField {...inputParams} InputProps={{ ...inputParams.InputProps, disableUnderline: true }} />
                )}
              />
            )}
            {/* TODO: Add onChange handler for Filtering by Input */}
            {props.toggles?.showSearchbar ? <InputWithIcon onChange={(e) => null} /> : null}
          </div>
        </div>
      )}
      <OverlayScrollbarsComponent className={`os-root`} options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: false }}>
        <div className={`table-grid ${props.states.isLoading || props.states.isError || !page?.length ? "table-grid-full-height" : ""}`}>
          {/* Table Head */}
          <div className="table-head">
            {headerGroups.map((headerGroup, index) => (
              <div className={`row`} {...headerGroup.getHeaderGroupProps()} key={`headerGroup-${index}`}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    <div
                      className={`head-col ${calcTableHead(index, headerGroup.headers.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)}`}
                      {...column.getHeaderProps({
                        style: { width: column.width },
                      })}
                      key={`column-${index}`}
                    >
                      <div className={`cell cell-align-${(headerGroup.headers[index] as any)?.alignment ?? "center"}`}>
                        {/* Column Content */}
                        {index == DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && props.toggles?.showRowSelect ? (
                          column.render("Header")
                        ) : (
                          <>
                            <SortButton
                              headerProps={headerGroup.headers[index]}
                              label={column.render("Header")}
                              disabled={props.states.isLoading || (column.Header === "Status" && url.includes("closed"))}
                              isDefault={
                                (headerGroup.headers[index] as any).sortField === props.dataSets[dataSetIndex].defaultSort ||
                                (headerGroup.headers[index] as any).searchlightField === props.dataSets[dataSetIndex].defaultSort
                              }
                            />
                            {!url.includes("closed") || (url.includes("closed") && column.Header !== "Status") ? (
                              <FilterButton
                                variant="transparent"
                                size="sm"
                                anchor={(headerGroup.headers[index] as any).filterAnchorPosition ?? "center"}
                                disabled={props.states.isLoading}
                                headerProps={headerGroup.headers[index]}
                              />
                            ) : (
                              <></>
                            )}
                            {(headerGroup.headers[index] as any).HeaderTooltip && (
                              <Tooltip
                                title={(headerGroup.headers[index] as any).HeaderTooltip}
                                placement={(headerGroup.headers[index] as any).HeaderTooltipDirection ?? "top-start"}
                              >
                                <IconButton size="small">
                                  <TooltipIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          {/* Table Body */}
          <div className="table-body" {...getTableBodyProps()} style={{ position: "relative" }}>
            {props.states.isLoading ? (
              <Loading isRelative />
            ) : props.states.isError ? (
              <SoftAlert
                alertMessage={props.states.errorMessage}
                onClick={() => removeBadFilter()}
                buttonText={"Clear Filter"}
                badParam={lastFilterItem}
              ></SoftAlert>
            ) : page?.length ? (
              page.map((row, index) => {
                prepareRow(row);
                const snoozed = row.values.status === ActivityStatus.Snoozed.toLowerCase();
                return (
                  <div
                    className={`row${handleRowRead(props.dataSets[dataSetIndex].data.tableData[index])}`}
                    {...row.getRowProps()}
                    key={`row-${index}`}
                    onClick={(e: MouseEvent<HTMLTableRowElement>) => props.dataSets[dataSetIndex].handleRowClick(e, row)}
                  >
                    {row.cells.map((cell, index) => {
                      const sortIsActive = (visibleColumns[index] as any)?.sortField
                        ? params.get("sort") === (visibleColumns[index] as any)?.sortField ||
                          params.get("sort") ===
                            (visibleColumns[index] as any)?.sortField
                              .split(", ")
                              .map((field: string) => field + " DESC")
                              .join(", ")
                        : params.get("sort")?.includes((visibleColumns[index] as any)?.searchlightField);
                      return (
                        <div
                          style={{
                            width:
                              props.toggles?.showRowSelect && index == DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? "auto" : visibleColumns[index]?.width,
                          }}
                          className={`col col-align-${(visibleColumns[index] as any)?.alignment ?? "center"} ${calcTableBody(
                            index,
                            row.cells.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                          )}`}
                          {...cell.getCellProps()}
                          key={`cell-${index}`}
                        >
                          {cell.render("Cell")}
                          {index !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && sortIsActive && <div className="sort-placeholder"></div>}
                          {index !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (visibleColumns[index] as any)?.showFilter && (
                            <div className="filter-placeholder"></div>
                          )}
                        </div>
                      );
                    })}
                    {props.hoverBar && (
                      <HoverBar
                        activityHandler={props.activityHandler}
                        isSnoozed={snoozed}
                        barriers={false}
                        row={row}
                        softRefresh={() => ((ref as any)?.current as any)?.softRefresh()}
                      />
                    )}
                  </div>
                );
              })
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </OverlayScrollbarsComponent>
      {props.pagination.totalCount !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && props.toggles?.showNavigation && (
        <div className={`table-navigation`}>
          <div className={`table-navigation-grp`}>
            <div
              className={`pagination-button ${
                props.states.isLoading || props.pagination.totalCount <= DEFAULT_PAGINATION_VALUES.PAGE_SIZE ? "pagination-button-disabled" : ""
              }`}
              onClick={(e: MouseEvent<HTMLDivElement>) => setPaginationMenuAnchorEl(e.currentTarget)}
            >
              <p>
                {/* {pageSize * (props.pagination.pageNumber ?? 0) + 1}- */}
                {(props.pagination.pageNumber - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) * pageSize + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE}-
                {/* {Math.min(pageSize * ((props.pagination.pageNumber ?? 0) + 1), props.pagination.totalCount)} of {props.pagination.totalCount} */}
                {Math.min(
                  (props.pagination.pageNumber - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) * pageSize + pageSize,
                  props.pagination.totalCount
                )} of {props.pagination.totalCount}
              </p>
            </div>
            <Menu
              open={paginationMenuOpen}
              anchorEl={paginationMenuAnchorEl}
              onClose={() => setPaginationMenuAnchorEl(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {PAGINATION_DROPDOWN.map((value: number, index: number) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleDropdownSelect(value);
                      setPaginationMenuAnchorEl(null);
                    }}
                    selected={pageSize === value}
                  >
                    {value} items per page
                  </MenuItem>
                );
              })}
            </Menu>
            <div className={`navigation-btn-grp`}>
              <button
                className={`navigation-btn`}
                onClick={() => {
                  props.pagination.setPageNumber &&
                    props.pagination.setPageNumber((currPage: number) => {
                      appendPaginationParams(
                        currPage === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                          ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                          : currPage - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                      );
                      return currPage === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                        ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                        : currPage - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
                    });
                }}
                disabled={props.states.isLoading || props.pagination.pageNumber === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE}
              >
                <LeftArrow />
              </button>
              <button
                className={`navigation-btn`}
                onClick={() => {
                  props.pagination.setPageNumber &&
                    props.pagination.setPageNumber((currPage: number) => {
                      appendPaginationParams(
                        (currPage + DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) * pageSize >= props.pagination.totalCount
                          ? currPage
                          : currPage + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                      );
                      return (currPage + DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) * pageSize >= props.pagination.totalCount
                        ? currPage
                        : currPage + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
                    });
                }}
                disabled={props.states.isLoading || props.pagination.pageNumber === props.pagination.pageCount}
              >
                <RightArrow />
              </button>
            </div>
          </div>
          {showSelectAll && showSelectAllBar ? (
            <div className={"table-select-all"}>
              {allRowsSelected ? (
                <p>
                  All <span>{props.pagination.totalCount}</span> items have been selected.
                </p>
              ) : (
                <p>
                  All <span>{Object.keys(currentSelectedRows).length}</span> items on this page have been selected.
                </p>
              )}
              <p className={"select-all-button"} onClick={allRowsSelected ? handleClearSelection : handleSelectAllPages}>
                {allRowsSelected ? "Clear Selection" : `Select all ${props.pagination.totalCount} items`}
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
TableForActivityStreams.defaultProps = defaultProps;

export default TableForActivityStreams;
