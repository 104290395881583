import React, { useEffect } from "react";
import _ from "underscore";
import { MagicAuthContext, MagicAuthContextType } from "../contexts/external/MagicAuthContext";

const useMagicAuth = (id: UUID, recurring = true) => {
  // BE refreshes the token every 30 seconds
  const TOKEN_REFRESH_INTERVAL = 20000;

  const { getToken, refreshIntervalId, setRefreshIntervalId } = React.useContext(MagicAuthContext) as MagicAuthContextType;

  const doesTokenRefreshCallExist = !_.isUndefined(refreshIntervalId);
  let refreshTimerId: NodeJS.Timeout | undefined;

  useEffect(() => {
    // we do not need to use return from fetchToken call
    const fetchToken = () => {
      return getToken(id).then((res: any) => res);
    };

    /**
     * initial call + recurring calls at after each token refresh
     * interval period
     */
    fetchToken();

    if (recurring && !doesTokenRefreshCallExist) {
      refreshTimerId = setInterval(fetchToken, TOKEN_REFRESH_INTERVAL);
      setRefreshIntervalId(refreshTimerId);
    }

    /**
     * removes the interval call
     */
    return () => {
      clearInterval(doesTokenRefreshCallExist ? refreshIntervalId : refreshTimerId);
      setRefreshIntervalId(undefined);
    };
  }, []);
};

export default useMagicAuth;
