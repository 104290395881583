import React, { ReactElement } from "react";

const SetupMessage = (): ReactElement => {
  return (
    <>
      <div className="help-text-header">Set-up your Signature</div>
      <div className="help-text-body">
        Hurray! you can now configure and set a pre-defined signature which will be included in all the email activities initiated on Inbox. The
        signature will also auto-populate in your templates - you can always manually edit them on the go or update here. This will save 1-2 minutes
        per email.
      </div>
    </>
  );
};

export default SetupMessage;
