import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import React from "react";
import TableUtils from "../../../../../../../../utils/TableUtils/TableUtils";
import { Ellipses, ReplyFilled } from "../../../../../../../library/Icons/Icons";
import * as configValue from "../../../../../../../../constants/config";
import { EmailAction } from "../../../../../../../../types/enums";
import methods from "../../../../ActivityFeedMethods";
import { ReplyOptionsProps } from "../HelperTypes";

const ReplyOptions: React.FC<ReplyOptionsProps> = ({ onClickForward, onClickReplyAll, onClickViewOriginalEmail, onClickReply }) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

  /* EVENT HANDLERS */
  const onClickAwayTooltip = () => {
    setIsTooltipOpen(false);
  };

  const onClickTooltipAction = (action: string) => {
    switch (action) {
      case EmailAction.REPLY_ALL:
        onClickReplyAll?.();
        break;
      case EmailAction.FORWARD:
        onClickForward?.();
        break;
      case EmailAction.VIEW_ORIGINAL:
        onClickViewOriginalEmail?.();
        break;
      default:
        throw new Error("Email action does not exist.");
    }
    onClickAwayTooltip();
  };

  /* ELLIPSES-TOOLTIP CONFIGURATIONS */
  const ellipsesActionConfigs = TableUtils.generateMenuList([
    {
      value: configValue.LBL_REPLAY_ALL,
      id: EmailAction.REPLY_ALL,
      clickFunction: () => onClickTooltipAction(EmailAction.REPLY_ALL),
    },
    {
      value: configValue.LBL_FORWARD,
      id: EmailAction.FORWARD,
      clickFunction: () => onClickTooltipAction(EmailAction.FORWARD),
    },
    {
      value: configValue.LBL_VIEW_ORIGINAL,
      id: "something bad is gere",
      clickFunction: () => onClickTooltipAction(EmailAction.VIEW_ORIGINAL),
    },
  ]);

  return (
    <>
      <div className="reply-action">
        <IconButton disableRipple title="Reply" onClick={(e) => {
          //To suppress event bubbling up to feed component and triggering "Read More" toggle
          e.stopPropagation();
          onClickReply?.(e)
        }
        }>
          <ReplyFilled />
        </IconButton>
      </div>
      <ClickAwayListener onClickAway={onClickAwayTooltip}>
        <div className="tooltip-actions" onClick={(e) => {
          //To suppress event bubbling up to feed component and triggering "Read More" toggle
          e.stopPropagation();
          setIsTooltipOpen(!isTooltipOpen)
        }}>
          <Tooltip title={ellipsesActionConfigs} open={isTooltipOpen} classes={methods.useTooltipStyles()} placement="top-end">
            <IconButton disableRipple>
              <Ellipses />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default ReplyOptions;
