import React from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Detail from "../../../library/Detail/Detail";
import Userpic from "../../../library/Sidebar/Userpic/Userpic";
import Button from "../../../library/Button/Button";
import { Delete } from "../../../library/Icons/Icons";
import { DataMode } from "../../../../types/enums";
import { formatAddress } from "../../../../db/utils/address";
import { AppContext } from "../../../../contexts/AppContext";
import { Skeleton } from "@mui/material";
import { ImageUploader } from "../../../library/ImageUploader";
import { MY_PROFILE_SKELETON_CONSTANTS } from "../../../../constants/StyleConstants";
import Utils from "../../../../utils/utils";

type ViewMyProfileProps = {
  setDataMode: (prev: DataMode) => void;
  data: UserAccountModel;
  hasEditPermission: boolean;
  isSelf: boolean;
  isLoading: boolean;
  handleImageUpload: (imageURL: string) => Promise<UserAccountModel>;
  showUploadModal: boolean;
  setShowUploadModal: (showUploadModal: boolean) => void;
  imageUrl: string;
  setImageUrl: (imageUrl: string) => void;
};

const ViewMyProfile = (props: ViewMyProfileProps): React.ReactElement => {
  const { initialLoad } = React.useContext(AppContext) as AppType;
  const skeletonStyles = {
    titleWidth: 60,
    textWidth: 100,
  };

  const userRole = props.data?.accountingRoleCodeDefinition?.codeDescription ? props.data?.accountingRoleCodeDefinition.codeDescription : "Employee";

  return (
    <div className="profile-detail">
      <div className="profile-edit-bar">
        <div className="info-row">
          <h2 className="info-header">Personal Information</h2>
          <p>Confirm or edit your photo and personal details here.</p>
        </div>
      </div>
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true }}>
        <div className={`container ${!props.isSelf ? "container-split" : ""}`}>
          <div>
            <div className="user-avatar-info">
              <p className="avatar-title">Your Photo</p>
              <div className="user-avatar-container">
                {initialLoad || props.isLoading ? (
                  <>
                    <Skeleton
                      variant="circular"
                      width={Utils.getConvertedSizeInRem(MY_PROFILE_SKELETON_CONSTANTS.PROFILE_IMG.RADIUS)}
                      height={Utils.getConvertedSizeInRem(MY_PROFILE_SKELETON_CONSTANTS.PROFILE_IMG.RADIUS)}
                      animation="wave"
                    />
                    <div className="btns-grp">
                      <Skeleton
                        variant="text"
                        width={Utils.getConvertedSizeInRem(MY_PROFILE_SKELETON_CONSTANTS.PROFILE_UPLOAD.WIDTH)}
                        height={Utils.getConvertedSizeInRem(MY_PROFILE_SKELETON_CONSTANTS.PROFILE_UPLOAD.HEIGHT)}
                        animation="wave"
                        className="profile-edit-btn"
                      />
                      <Skeleton
                        variant="text"
                        width={Utils.getConvertedSizeInRem(MY_PROFILE_SKELETON_CONSTANTS.PROFILE_REMOVE.WIDTH)}
                        height={Utils.getConvertedSizeInRem(MY_PROFILE_SKELETON_CONSTANTS.PROFILE_REMOVE.HEIGHT)}
                        animation="wave"
                        className="profile-edit-btn"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <Userpic className="avatar-view" imageUrl={props.imageUrl ?? ""} />
                    <div className="btns-grp">
                      <Button
                        type="button"
                        variant="primary"
                        size="sm"
                        className="profile-edit-btn primary"
                        onClick={() => props.setShowUploadModal(true)}
                      >
                        Upload A Photo
                      </Button>
                      <Button
                        type="button"
                        variant="secondary"
                        size="sm"
                        alignIcon="right"
                        icon={<Delete />}
                        className="profile-edit-btn secondary-border"
                        onClick={() => {
                          props.setImageUrl("");
                          props.handleImageUpload("");
                        }}
                      >
                        Delete This Photo
                      </Button>
                    </div>
                    <ImageUploader
                      open={props.showUploadModal}
                      toggleFunction={(bool: boolean) => {
                        props.setShowUploadModal(bool);
                      }}
                      getImageURL={(url: string) => {
                        props.handleImageUpload(url).then(() => {
                          props.setImageUrl(url);
                        });
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="user-info-row">
              <Detail
                columns={{
                  title: "Your Name",
                  value: props.data?.userName ?? "N/A",
                }}
                isLoading={initialLoad || props.isLoading}
                skeletonStyles={skeletonStyles}
              />
              <Detail
                columns={{
                  title: "Your Title",
                  value: props.data?.title ?? "N/A",
                }}
                isLoading={initialLoad || props.isLoading}
                skeletonStyles={skeletonStyles}
              />
              <Detail
                columns={{ title: "Your Email", value: props.data?.email ?? "N/A", format: "email" }}
                isLoading={initialLoad || props.isLoading}
                skeletonStyles={skeletonStyles}
              />
              <Detail
                columns={{
                  title: "Your Role",
                  value: userRole,
                }}
                isLoading={initialLoad || props.isLoading}
                skeletonStyles={skeletonStyles}
              />
            </div>
            <div className="user-info-row">
              <Detail
                columns={{ title: "Your Phone", value: props.data?.phoneNumber ?? "N/A", format: "tel" }}
                isLoading={initialLoad || props.isLoading}
                skeletonStyles={skeletonStyles}
              />
              <Detail
                columns={{ title: "Your Fax", value: props.data?.faxNumber ?? "N/A", format: "tel" }}
                isLoading={initialLoad || props.isLoading}
                skeletonStyles={skeletonStyles}
              />
            </div>
            <div className="user-info-row">
              <Detail
                columns={{
                  title: "Your Address",
                  value: formatAddress(
                    props.data?.address1 ?? "N/A",
                    props.data?.address2 ?? "N/A",
                    "",
                    props.data?.city ?? "N/A",
                    props.data?.stateRegion ?? "N/A",
                    props.data?.postalCode ?? "N/A",
                    props.data?.country ?? "N/A"
                  ),
                }}
                isLoading={initialLoad || props.isLoading}
                skeletonStyles={skeletonStyles}
              />
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
};

export default ViewMyProfile;
