export interface ActivityCreator {
  name: string | null;
  message: string | null;
  createdOn: string;
}
export enum SecondaryType {
  Base,
  Accordion,
}
export interface SecondaryActivityProps {
  variant: SecondaryType;
  creator: ActivityCreator;
  readonly?: boolean;
  children?: React.ReactNode;
}

export type AccordionActivityProps = Omit<SecondaryActivityProps, "variant">;
export type BaseActivityProps = Omit<AccordionActivityProps, "children" | "readonly">;
