import React from "react";
import Button from "../../../../../../library/Button/Button";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { SpamProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

const Spam: React.FC<SpamProps> = ({ onClickNotSpam, creator, readonly, enableNotSpam }) => {
  creator = { ...creator, message: Labels.HEADER_MARKED_AS_SPAM };
  return readonly || !enableNotSpam ? (
    <SecondaryActivity variant={SecondaryType.Base} creator={creator} />
  ) : (
    <SecondaryActivity variant={SecondaryType.Accordion} creator={creator}>
      <Button variant="primary" size="sm" onClick={onClickNotSpam}>
        {Labels.BTN_NOT_SPAM}
      </Button>
    </SecondaryActivity>
  );
};

export default Spam;
