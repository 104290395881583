// Imports from react
import React, { useState, useRef, useEffect, KeyboardEvent } from "react";
// Import assets
import { Close } from "../../Icons/Icons";
// Import reusable styled-components
import Button, { ButtonProps } from "../../Button/Button";
import { Input } from "../../Input/Input";
// Imports from react-router-dom
import { useHistory } from "react-router-dom";
import { Filter } from "../../Icons/Icons";
import "./FilterButton.scss";
import { InvoiceContext } from "../../../../contexts/InvoiceContext";
import Autocomplete from "../../Autocomplete/Autocomplete";
import { AutocompleteRenderInputParams, TextField } from "@mui/material";

// Defines Filter Button Props
interface FilterButtonProps extends ButtonProps {
  // TODO: Find type for headerProps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerProps: any;
  anchor: "left" | "right" | "center";
}

/**
 * * Defines the Filter Button on a Table
 */
export function FilterButton(props: FilterButtonProps): React.ReactElement {
  // References the Filter Button's Popup
  const popupRef = useRef<HTMLDivElement>(null);
  // Indicates hover state
  const [isHover, setIsHover] = useState<boolean>(false);
  // Indicates whether popup is shown
  const [isPopup, setIsPopup] = useState<boolean>(false);
  // Indicates whether a filter has been set
  const [isFilter, setIsFilter] = useState<boolean>(false);
  // Holds the input value from the input bar, defaults to query string
  const [input, setInput] = useState<string | null>(() => {
    const params = new URLSearchParams(window.location.search);
    const value = params.get(props.headerProps.id);
    if (value) setIsFilter(true);
    return value;
  });
  const { setLastFilterItem } = React.useContext(InvoiceContext) as InvoiceType;

  // Get History handler from react-router-dom
  const history = useHistory();

  // Trigger reload data with new filter (Add current filter)
  function handleSetFilter(value: string | null) {
    // Set filter on and close popup
    setIsFilter(true);
    setIsPopup(false);
    setIsHover(false);
    setLastFilterItem(props.headerProps.id);

    // Add filter to query string
    const params = new URLSearchParams(window.location.search);
    params.delete(props.headerProps.id);
    if (value) params.append(props.headerProps.id, value);
    history.push({ search: params.toString().replace(/[?&]$/, "") });
  }

  // Trigger reload data with new filter (Delete current filter)
  function handleClearFilter() {
    // Set filter off and close popup
    setIsFilter(false);
    setIsPopup(false);
    setIsHover(false);
    setInput(null);

    // Delete filter from query string
    const params = new URLSearchParams(window.location.search);
    params.delete(props.headerProps.id);
    history.push({ search: params.toString().replace(/[?&]$/, "") });
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && input) handleSetFilter(input);
    else if (e.key === "Escape") {
      setIsPopup(false);
      setIsHover(false);
    }
  }

  // Handles closing popup when clicking outside
  useEffect(() => {
    // Toggle off popup if clicking outside of the wrapper (TODO: remove any)
    function handleClickOutside(e: Event) {
      if (popupRef.current && !popupRef.current.contains(e.target as unknown as Node)) {
        setIsPopup(false);
      }
    }
    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    // Clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  if (!props.headerProps.showFilter) {
    return <></>;
  }

  return (
    <div className={`filter-wrapper`}>
      <div
        className={`filter-btn-wrapper`}
        onClick={() => setIsPopup((prev) => !prev)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Filter
          className={`filter-icon ${isPopup || isHover ? "filter-icon-hover" : ""} ${isFilter ? "filter-icon-active" : ""} `}
          isFilter={isFilter}
        />
      </div>
      {isPopup && (
        <div className={`filter-btn-popup ${props.anchor ? `filter-btn-popup-${props.anchor}` : ""}`} ref={popupRef}>
          <button
            className={`filter-btn-popup-close`}
            onClick={() => {
              setIsPopup(false);
              setIsHover(false);
            }}
          >
            <Close />
          </button>
          <p className={`filter-btn-popup-title`}>Filter By {props.headerProps.Header}</p>
          {!props.headerProps.dropdownOptions ? (
            <Input
              placeholder={props.headerProps.popupPlaceholder}
              defaultValue={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
            />
          ) : (
            <Autocomplete
              options={props.headerProps.dropdownOptions}
              onChange={(_event, val: { label: string; value: string; id: string }) => setInput(val.value)}
              value={input !== null ? props.headerProps.dropdownOptions.filter((value: any) => value.value === input ?? "")[0]?.label : null}
              disableClearable
              disablePortal
              getOptionLabel={(option) => option.label ?? option}
              isOptionEqualToValue={(option, value) => option.label === value}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField {...params} InputProps={{ ...params.InputProps, disableUnderline: true }} placeholder="Select" />
              )}
            />
          )}
          <div className={`filter-btn-popup-grp`}>
            <Button
              className={`filter-btn-popup-btns`}
              size="sm"
              variant="grey"
              disabled={!isFilter || props.disabled}
              onClick={() => handleClearFilter()}
            >
              Clear Filters
            </Button>
            <Button size="sm" className={`filter-btn-popup-btns`} disabled={!input || props.disabled} onClick={() => handleSetFilter(input)}>
              Filter
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
