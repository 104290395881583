import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import Navbar from "../../../library/Navbar/Navbar";
import AgingTable from "./AgingTable/AgingTable";
// TODO: Add back AgingHeader once API work has finalized
import ArAgingHeader from "./ArAgingHeader/ArAgingHeader";
import ApAgingHeader from "./ApAgingHeader/ApAgingHeader";
import { WorkspaceType } from "../../../../types/enums";

import "../Drilldowns.scss";
import FourOFourError from "../../../../routes/FourOFourError/FourOFourError";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { ComponentConfigContext } from "../../../../contexts/ComponentConfigContext";
import { DRILLDOWN_NAVIGATION_CONSTANTS } from "../../../../constants/NavigationConstants";

const routes = [
  {
    displayName: "Late 91+ Days",
    routeName: `?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.AGING.SORT}`,
  },
  {
    displayName: "Late 61-90 Days",
    routeName: `late_61_90?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.AGING.SORT}`,
  },
  {
    displayName: "Late 31-60 Days",
    routeName: `late_31_60?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.AGING.SORT}`,
  },
  {
    displayName: "Late 1-30 Days",
    routeName: `late_1_30?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.AGING.SORT}`,
  },
  {
    displayName: "Future Due",
    routeName: `future_due?sort=${DRILLDOWN_NAVIGATION_CONSTANTS.AGING.SORT}`,
  },
];

export default function Aging(): React.ReactElement {
  const { path } = useRouteMatch();
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const config = getConfig(selectedWorkspace?.workspace_type_route ?? WorkspaceType.AR)["dashboard"]["drilldowns"]["agingModule"];

  return (
    <div className="drilldown-wrapper">
      <div className="head">
        <Navbar
          tabs={routes}
          rootPath={path}
          dropdownRootPath={path.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/"))}
          dropdown={config.drillDownNavbarDropdown}
          dropdownDefault={config.defaultSelectedDrillDown}
          backDisplayName="Dashboard"
          backRoute={`${path.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/"))}`}
          persistQueryParams
          tooltip={config.widgetTooltip}
          handleBackRoute
        />
      </div>
      <div className="body">
        <div className="content">
          {/* TODO: Add back AgingHeader after API work has been done */}
          <div className="content-card">{selectedWorkspace.workspace_type_route == WorkspaceType.AR ? <ArAgingHeader /> : <ApAgingHeader />}</div>
          <div className="content-table">
            <Switch>
              <Route
                exact
                path={routes.map((route) => {
                  return `${path}/${route.routeName.replace(/\?.+$/, "")}`;
                })}
                component={() => {
                  return <AgingTable />;
                }}
              />
              <Route
                component={() => {
                  return <FourOFourError />;
                }}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
