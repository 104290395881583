import APIClient from "../APIClient";

export class GlobalSearchClient {
  getSearchSuggestions(
    query: string,
    category: string | undefined,
    workspace_id: number,
    pageCount?: number,
    pageSize?: number
  ): Promise<APIResponse> {
    const url = `inbox/api/v2/workspaces/${workspace_id ?? ""}/search`;
    const options = {
      params: {
        query,
        category,
        page_size: pageSize,
        page: pageCount,
      },
    };
    return APIClient.get(url, options).then((res) => res.data);
  }
}
