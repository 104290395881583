import React from "react";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { Carousel } from "../../library/Carousel";
import InsightsCard from "./InsightsCard";
import { InsightsCarouselComponentInterface } from "./InsightsInterface/InsightsInterface";
import "./InsightsStyles/InsightsStyles.scss";

const InsightsCarouselComponent: React.FC<InsightsCarouselComponentInterface> = ({
  summary,
  loading,
  error_message,
}: InsightsCarouselComponentInterface) => {
  const CAROUSEL_DIMENSION = { height: "14rem", width: "100%" };

  return (
    <>
      {summary && Object.keys(summary)?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
        <Carousel dimension={CAROUSEL_DIMENSION}>
          {Object.keys(summary)
            .filter((item) => summary?.[item]?.enable)
            .map((item, index) => (
              <InsightsCard key={index} summary={summary[item]} loading={loading} />
            ))}
        </Carousel>
      )}
      {summary && Object.keys(summary)?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
        <Carousel dimension={CAROUSEL_DIMENSION} hideControls>
          <InsightsCard data_error error_message={error_message} loading={loading} />
        </Carousel>
      )}
    </>
  );
};

export default InsightsCarouselComponent;
