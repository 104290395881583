import React from "react";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import PaymentDetail from "./PaymentDetail/PaymentDetail";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import UnappliedPayments from "./UnappliedPayments/UnappliedPayments";
import AllPayments from "./AllPayments/AllPayments";
import "./Payments.scss";
import FourOFourError from "../../routes/FourOFourError/FourOFourError";
import AdvancedPayments from "./AdvancedPayments/AdvancedPayments";
import PaymentNavbar from "./PaymentsNavbar";

type PaymentsProps = {
  configs: any;
};

/**
 * * Defines the Payments Tab (Top-level route)
 */
export default function Payments(props: PaymentsProps): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <div className="payments-wrapper">
      <PaymentNavbar />
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, overflowBehavior: { x: "hidden" } }}>
        <div className={`body`}>
          <Switch>
            <Route
              exact
              path={`${path}`}
              component={() => {
                return <AllPayments configs={props.configs} />;
              }}
            />
            <Route
              exact
              path={`${path}/unappliedPayments`}
              component={() => {
                return <UnappliedPayments configs={props.configs} />;
              }}
            />
            <Route
              exact
              path={`${path}/advancedPayments`}
              component={() => {
                return <AdvancedPayments configs={props.configs} />;
              }}
            />
            <Route
              path={`${path}/:paymentId`}
              render={() => {
                return <PaymentDetail />;
              }}
            />
            <Route
              component={() => {
                return <FourOFourError />;
              }}
            />
          </Switch>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}
