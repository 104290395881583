import React, { useEffect } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { default as CustomerProfile } from "../../Customers/CustomerDetail/Profile/Profile";
import { default as CustomerHeader } from "../../Customers/CustomerDetail/Header/Header";
import ContactDetail from "../../Customers/CustomerDetail/Contacts/ContactDetail/ContactDetail";
import Details from "./Details/Details";
import { InvoiceContext } from "../../../contexts/InvoiceContext";
import { CustomerContext } from "../../../contexts/CustomerContext";
import FourOFourError from "../../../routes/FourOFourError/FourOFourError";
import ActivityDetail from "../../Activities/ActivityDetail/ActivityDetail";
import Loading from "../../library/Loading/Loading";
import PaymentDetail from "../../Payments/PaymentDetail/PaymentDetail";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";

/**
 * * Defines the Invoices Details - Default Page
 */
export default function InvoiceDetail(): React.ReactElement {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { path } = useRouteMatch();
  const { isInvoiceDataLoading, setInvoiceDataLoading, get, setInvoiceData } = React.useContext(InvoiceContext) as InvoiceType;
  const { getCompanyDetails } = React.useContext(CustomerContext) as CustomerType;
  const { configs } = useWorkspaceConfigurations();

  const fetchData = async () => {
    let res = {} as InvoiceModel;
    setInvoiceDataLoading(true);
    try {
      res = await get(invoiceId);
      getCompanyDetails(res.customerId);
    } catch (error: any) {
      console.error(error);
    } finally {
      setInvoiceData(res);
      setInvoiceDataLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [invoiceId]);

  return isInvoiceDataLoading ? (
    <Loading isRelative />
  ) : (
    <Switch>
      <Route
        exact
        path={[`${path}`, `${path}/${configs.invoices.routes.resource.invoices}/:invoiceId`]}
        render={() => {
          return <Details configs={configs.invoices} />;
        }}
      />
      <Route
        exact
        path={`${path}/activities/:activityId`}
        component={() => {
          return <ActivityDetail />;
        }}
      />
      <Route exact path={`${path}/payments/:paymentId`} render={() => <PaymentDetail />} />
      <Route
        exact
        path={`${path}/${configs.invoices.routes.resource.customer}/active/:customerId`}
        render={() => {
          // TODO: check which component to render here
          return (
            <div className="customer-detail-wrapper">
              <div className="head">
                <CustomerHeader />
              </div>
              <div className="body">
                <CustomerProfile />
              </div>
            </div>
          );
        }}
      />
      <Route
        exact
        path={`${path}/contactProfile/:contactId`}
        component={() => {
          return (
            <div>
              <ContactDetail />
            </div>
          );
        }}
      />
      <Route
        component={() => {
          return <FourOFourError />;
        }}
      />
    </Switch>
  );
}
