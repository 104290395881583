import { Drawer } from "@mui/material";
import React from "react";
import { AlertContext } from "../../../../../contexts/AlertContext";
import { WorkspaceContext } from "../../../../../contexts/WorkspaceContext";
import { activitiesClientV2 } from "../../../../../db/version2Accessor";
import Button from "../../../../library/Button/Button";
import { TextArea } from "../../../../library/TextArea/TextArea";
import "./ActivityFeedActions.scss";

interface ApprovalRequestReviewProps {
  open: boolean;
  onClose: () => void;
  activityId: UUID;
  requestId: string;
  reviewAction: string;
  getActivityStream: (withLoadingAnimation: boolean, onActionPerform?: boolean, explicitlySetPageSize?: number, refreshStream?: boolean) => void;
}

const ApprovalRequestReview: React.FC<ApprovalRequestReviewProps> = ({ open, activityId, requestId, reviewAction, onClose, getActivityStream }) => {
  const [note, setNote] = React.useState<string>("");
  const [loader, setLoader] = React.useState<boolean>(false);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;

  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const onClickUpdate = async () => {
    setLoader(true);
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: "Thanks for taking care of the request!",
    };
    let response = {} as APIResponse;
    try {
      response = await activitiesClientV2.reviewActivityRequest(selectedWorkspace.id, activityId, requestId, reviewAction, note);
    } catch (e: unknown) {
      response.success = false;
      console.error("error: ", e);
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: "Something went wrong",
        };
      }
      setNote("");
      onClose();
      setToastOptions(toastOptions);
      // refresh activity
      await getActivityStream(true, false, undefined, true);
    }
    setLoader(false);
  };

  return (
    <Drawer className="activity-feed-action-drawer" open={open} anchor="right" disablePortal onClose={onClose}>
      <div className="activity-flyout">
        <h3 className="header">Add Note</h3>
        <p className="subheader body1">This will shown with your approval request response</p>
        <TextArea placeholder="Add a note" onChange={onChangeTextArea} />
        <div className="btn-grp">
          <div></div>
          <Button size="lg" onClick={onClickUpdate} variant="primary" loading={loader}>
            Update
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default ApprovalRequestReview;
