import { ConnectionStatus } from "../../types/enums";
import APIClient from "../APIClient";

export class PaymentsClient {
  querySummaries(filters?: string, order?: string, pageSize?: number, pageNumber?: number, spamFraudArchivedTab = false): Promise<FetchResult> {
    let url = `inbox/api/v2/payments?page=${pageNumber}&page_size=${pageSize}`;
    if (filters) {
      url = `${url}&${filters}`;
    }
    if (order) {
      url = `${url}&${order}`;
    }
    const options = {
      params: {
        connection_status_not_in: spamFraudArchivedTab ? "" : `${ConnectionStatus.SPAM},${ConnectionStatus.FRAUD},${ConnectionStatus.ARCHIVED}`,
      },
    };
    return APIClient.get(url, options).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        records: response.data.data,
      } as PaymentSummaryModelFetchResultV2;
    });
  }

  getPaymentsSummary(filter?: string, spamFraudArchivedTab = false): Promise<APIResponse> {
    let url = `inbox/api/v2/payments/summary`;
    if (filter) {
      url = url + `?${filter}`;
    }
    const options = {
      params: {
        connection_status_not_in: spamFraudArchivedTab ? "" : `${ConnectionStatus.SPAM},${ConnectionStatus.FRAUD},${ConnectionStatus.ARCHIVED}`,
      },
    };
    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }
}
