import React from "react";
import { Route, useRouteMatch, Switch } from "react-router";
import Mine from "./Mine/Mine";
import Unassigned from "./Unassigned/Unassigned";
import All from "./AllActivities/AllActivities";
import ActivityDetail from "./ActivityDetail/ActivityDetail";
import FourOFourError from "../../routes/FourOFourError/FourOFourError";
import "./Activities.scss";
import Spam from "./Spam/Spam";
import ActivitiesNavbar from "./ActivitiesNavbar";
import { default as CustomerHeader } from "../Customers/CustomerDetail/Header/Header";
import { default as CustomerProfile } from "../Customers/CustomerDetail/Profile/Profile";
import ContactDetail from "../Customers/CustomerDetail/Contacts/ContactDetail/ContactDetail";
import useWorkspaceConfigurations from "../../hooks/useWorkspaceConfigurations";
import InvoiceDetail from "../Invoices/InvoiceDetail/InvoiceDetail";
import PaymentDetail from "../Payments/PaymentDetail/PaymentDetail";

export default function Activities(): React.ReactElement {
  const { path } = useRouteMatch();
  const { configs } = useWorkspaceConfigurations();

  return (
    <div className={"activities-wrapper"}>
      <ActivitiesNavbar />
      <div className={"body"}>
        <Switch>
          {/* Mine -> Table -> Detail -> Contact, Customer */}
          <Route
            exact
            path={[`${path}/mine`, `${path}/mine/closed`, `${path}/mine/waitingForResponse`, `${path}/mine/snoozed`]}
            component={() => {
              return <Mine />;
            }}
          />
          <Route
            exact
            path={[`${path}/mine/:activityId`, `${path}/mine/:activityId/activities/:activityId`]}
            component={() => {
              return <ActivityDetail userView="mine" />;
            }}
          />
          <Route
            exact
            path={`${path}/mine/:activityId/${configs.activities.detail.resources.connections}/:customerId`}
            render={() => {
              return (
                <div className="customer-detail-wrapper">
                  <div className="head">
                    <CustomerHeader />
                  </div>
                  <div className="body">
                    <CustomerProfile />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/mine/:activityId/${configs.activities.detail.resources.invoices}/:invoiceId`}
            component={() => <InvoiceDetail />}
          />
          <Route exact path={`${path}/mine/:activityId/payments/:paymentId`} component={() => <PaymentDetail />} />
          <Route exact path={`${path}/mine/:activityId/contactProfile/:contactId`} component={() => <ContactDetail />} />
          {/* Unassigned */}
          <Route
            exact
            path={[`${path}/unassigned`, `${path}/unassigned/closed`]}
            component={() => {
              return <Unassigned />;
            }}
          />
          <Route
            exact
            path={[`${path}/unassigned/:activityId`, `${path}/unassigned/:activityId/activities/:activityId`]}
            component={() => {
              return <ActivityDetail userView="unassigned" />;
            }}
          />
          <Route
            exact
            path={`${path}/unassigned/:activityId/${configs.activities.detail.resources.connections}/:customerId`}
            render={() => {
              return (
                <div className="customer-detail-wrapper">
                  <div className="head">
                    <CustomerHeader />
                  </div>
                  <div className="body">
                    <CustomerProfile />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/unassigned/:activityId/${configs.activities.detail.resources.invoices}/:invoiceId`}
            component={() => <InvoiceDetail />}
          />
          <Route exact path={`${path}/unassigned/:activityId/payments/:paymentId`} component={() => <PaymentDetail />} />
          <Route exact path={`${path}/unassigned/:activityId/contactProfile/:contactId`} component={() => <ContactDetail />} />
          {/* All */}
          <Route
            exact
            path={[`${path}/all`, `${path}/all/waitingForResponse`, `${path}/all/closed`, `${path}/all/snoozed`]}
            component={() => {
              return <All />;
            }}
          />
          <Route
            exact
            path={[`${path}/all/:activityId`, `${path}/all/:activityId/activities/:activityId`]}
            component={() => {
              return <ActivityDetail userView="all" />;
            }}
          />
          <Route
            exact
            path={`${path}/all/:activityId/${configs.activities.detail.resources.connections}/:customerId`}
            render={() => {
              return (
                <div className="customer-detail-wrapper">
                  <div className="head">
                    <CustomerHeader />
                  </div>
                  <div className="body">
                    <CustomerProfile />
                  </div>
                </div>
              );
            }}
          />
          <Route exact path={`${path}/all/:activityId/contactProfile/:contactId`} component={() => <ContactDetail />} />
          <Route
            exact
            path={`${path}/all/:activityId/${configs.activities.detail.resources.invoices}/:invoiceId`}
            component={() => <InvoiceDetail />}
          />
          <Route exact path={`${path}/all/:activityId/payments/:paymentId`} component={() => <PaymentDetail />} />
          {/* Spam */}
          <Route
            exact
            path={[`${path}/spam`]}
            component={() => {
              return <Spam />;
            }}
          />
          <Route
            exact
            path={[`${path}/spam/:activityId`, `${path}/spam/:activityId/activities/:activityId`]}
            component={() => {
              return <ActivityDetail userView="spam" />;
            }}
          />
          <Route
            exact
            path={`${path}/spam/:activityId/${configs.activities.detail.resources.connections}/:customerId`}
            render={() => {
              return (
                <div className="customer-detail-wrapper">
                  <div className="head">
                    <CustomerHeader />
                  </div>
                  <div className="body">
                    <CustomerProfile />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/spam/:activityId/${configs.activities.detail.resources.invoices}/:invoiceId`}
            component={() => <InvoiceDetail />}
          />
          <Route exact path={`${path}/spam/:activityId/payments/:paymentId`} component={() => <PaymentDetail />} />
          <Route exact path={`${path}/spam/:activityId/contactProfile/:contactId`} component={() => <ContactDetail />} />
          <Route
            component={() => {
              return <FourOFourError />;
            }}
          />
        </Switch>
      </div>
    </div>
  );
}
