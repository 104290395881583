import APIClient from "../APIClient";

export class UserAccountsClient {
  /**
   * * GET on  /api/v2/workspaces/{workspace_id}/members
   * @param workspace_id - Workspace ID associated with the users
   * @returns Promise of type APIResponse model or Exception
   */
  getAllUserAccounts(workspace_id: number): Promise<APIResponse> {
    const url = `inbox/api/v2/workspaces/${workspace_id}/members`;

    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }

  /**
   * PATCH on /api/v2/user_accounts/{id}
   * @param id - Database ID associated with a user
   * @param body: The fields to change
   * @returns Promise of type UserAccountModel or Exception
   */
  patchUserAccount(id: string, body: Partial<UserAccountsModelV2>): Promise<APIResponse> {
    const url = `inbox/api/v2/user_accounts/${id}`;
    return APIClient.patch(url, body).then((res) => res.data);
  }

  /**
   * @function queryUserAccounts
   * A helper method to query user accounts based on various parameters.
   * @param filter
   * @param order
   * @param page_size
   * @param page
   * @returns Promise<APIResponse>
   */
  queryUserAccounts(filter?: string, include?: string, order?: string, page_size?: number, page?: number): Promise<FetchResult & APIResponse> {
    const url = `inbox/api/v2/user_accounts?filter=${filter}&order=${order}&page_size=${page_size}&page=${page}`;
    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }
}
