import React, { MouseEvent, useContext, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES, NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { reportsClientV2 } from "../../../../../db/version2Accessor";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import Table from "../../../../library/Table/Table";
import { WorkspaceContext } from "../../../../../contexts/WorkspaceContext";
import { FallbackTypes } from "../../../../../types/enums";
import { AppContext } from "../../../../../contexts/AppContext";

export default function PayablesComingDueTable(): React.ReactElement {
  const history = useHistory();
  const { path } = useRouteMatch();
  const params = new URLSearchParams(window.location.search);
  const { userStatus } = useContext(AppContext) as AppType;

  const INVOICESCOLUMNS = useMemo(
    () => [
      {
        Header: "Vendor Name",
        accessor: "vendor_name",
        width: "15%",
        showFilter: true,
        popupPlaceholder: "ex. Blushing Blooms",
        showSort: true,
        searchlightField: "VENDORNAME",
        sortField: "VENDORNAME",
        searchlightToken: "CONTAINS",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Primary Contact",
        accessor: "primary_contact",
        width: "18.75%",
        showFilter: true,
        popupPlaceholder: "ex. John Doe",
        showSort: true,
        searchlightField: "PRIMARYCONTACT",
        sortField: "PRIMARYCONTACT",
        searchlightToken: "CONTAINS",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
      },
      {
        Header: "Bills",
        accessor: "number_of_bills",
        width: "15%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "NUMBEROFBILLSDUE",
        sortField: "NUMBEROFBILLSDUE",
        searchlightToken: "EQ",
        alignment: "right",
        filterAnchorPosition: "center",
        Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, true),
      },
      {
        Header: "Amount",
        accessor: "amount_due",
        width: "12.5%",
        showFilter: true,
        popupPlaceholder: "ex. yyyy-mm-dd",
        showSort: true,
        searchlightField: "TOTALAMOUNTDUE",
        sortField: "TOTALAMOUNTDUE",
        searchlightToken: "LE",
        alignment: "left",
        filterAnchorPosition: "center",
        Cell: (props: { value: number | null }) =>
          TableUtils.formatCurrency(props.value, true, userStatus?.currency?.locale, userStatus?.currency?.code),
      },
      {
        Header: "% of Total",
        accessor: "of_total",
        width: "12.5%",
        showFilter: true,
        popupPlaceholder: "ex. 100",
        showSort: true,
        searchlightField: "PERCENTAGEOFTOTAL",
        sortField: "PERCENTAGEOFTOTAL",
        searchlightToken: "LE",
        alignment: "right",
        filterAnchorPosition: "left",
        Cell: (props: { value: number | null }) => {
          return props?.value?.toFixed(NUMERIC_VALUES.CONSTANT_TWO) + "%";
        },
      },
      {
        Header: "Last Activity",
        accessor: "last_activity",
        width: "15%",
        showFilter: true,
        popupPlaceholder: "ex. yyyy-mm-dd",
        showSort: true,
        searchlightField: "last_activity_at",
        sortField: "last_activity_at",
        searchlightToken: "eq",
        alignment: "left",
        Cell: (props: { value: string | null }) => {
          return TableUtils.formatDate(props.value, false);
        },
      },
    ],
    [userStatus]
  );

  const pageNoFromUrl = params.get("page");
  const [pageNumber, setPageNumber] = useState<number>(() => {
    const pageNum = parseInt(pageNoFromUrl ?? "0");
    if (isNaN(pageNum)) {
      return DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    } else {
      return pageNum;
    }
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;

  const getPayableComingDueSummary = async (filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number) => {
    return reportsClientV2
      .getPayablesComingDueSummary(
        selectedWorkspace?.id || FallbackTypes.Id,
        order,
        filter,
        pageNumber ?? pageCount,
        pageSize ?? DEFAULT_PAGINATION_VALUES.PAGE_SIZE
      )
      .then((fetchResult: any) => fetchResult);
  };

  const fetchParser = (fetchResult: any, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.data?.map((record: PayablesComingDueSummaryRecordsV2) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.vendor_id,
          }),
          ...((variant === "export" || variant === "all") && {
            vendor_name: record?.vendor_name || "N/A",
            primary_contact: record?.primary_contact,
            number_of_bills: record?.number_of_bills_due,
            amount_due: record?.total_amount_due,
            of_total: record?.percentage_of_total,
            last_activity: record?.last_activity_at,
            vendor_id: record?.vendor_id,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleInvoiceRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(
      `${path.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/dashboard/payables_coming_due"))}/vendors/${row.original.id ?? ""}`
    );
  }

  return (
    <Table
      dataSets={[
        {
          id: "By Bills",
          displayName: "By Bills",
          rowSelectToken: "VENDORID",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: getPayableComingDueSummary,
            fetchParser: fetchParser,
          },
          columns: INVOICESCOLUMNS,
          export: {
            exportFileName: "Bills - Coming Due",
          },
          defaultSort: "TOTALAMOUNTDUE",
          defaultSortToken: "DESC",
          handleRowClick: handleInvoiceRowClick,
          predefinedFilters: [
            { route: "due_now", searchlightFilter: "dueDate EQ TODAY" },
            { route: "next_7_days", searchlightFilter: "dueDate BETWEEN TODAY and TODAY + 7" },
            { route: "next_14_days", searchlightFilter: "dueDate BETWEEN TODAY and TODAY + 14" },
            { route: "next_30_days", searchlightFilter: "dueDate BETWEEN TODAY and TODAY + 30" },
          ],
        },
      ]}
      states={{
        isLoading: isLoading,
        setLoading: setLoading,
        isError: isError,
        setError: setError,
        errorMessage: errorMessage,
        setErrorMessage: setErrorMessage,
      }}
      pagination={{
        pageCount: pageCount,
        setPageCount: setPageCount,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
        totalCount: totalCount,
        setTotalCount: setTotalCount,
        startingIndex: DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      }}
      toggles={{
        showRowSelect: true,
        showExportBtn: true,
        showCaption: true,
        showNavigation: true,
        showSearchbar: false,
      }}
    />
  );
}
