import { event } from "react-fullstory";
import { DEFAULT_NUMERIC_VALUES } from "../../constants/NumericConstants";
import { TrackEventType } from "../../types/enums";
import Utils from "../../utils/utils";

const TrackingFS = {
  trackFSData: (
    to: To[],
    callToAction: string,
    action: string,
    sendAndClose: boolean,
    contactOptions: To[],
    pageType: string,
    path: string,
    activityType?: string
  ) => {
    if (!(activityType === "payment_reminder" || activityType === "email")) {
      return;
    }
    const containsNewContact = (to: To[], contacts: To[]): boolean => {
      const contactIdsList = contacts.map((item) => {
        return item.id;
      });
      const filteredList = to?.filter((contact) => {
        if (contact && contactIdsList.includes(contact.id ?? "")) {
          return contact;
        }
      });
      return filteredList.length === to.length ? false : true;
    };

    const getPageType = (page: string): string => {
      let pageType = "";
      if (page.includes("profiles")) {
        pageType = "User Profile";
      } else if (page.includes("invoices")) {
        pageType = "Invoices";
      } else if (page.includes("payments")) {
        pageType = "Payments";
      } else if (page.includes("contactProfile")) {
        pageType = "Contact Profile";
      } else if (page.includes("profile") && page.includes("customers")) {
        pageType = "Customer Profile";
      } else if (page === "me") {
        pageType = "Mine";
      } else {
        pageType = page;
      }
      return pageType;
    };

    const getCurrentScreen = (path: string): string => {
      if (
        path.substring(path.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) === "mine" ||
        path.substring(path.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) === "unassigned" ||
        path.substring(path.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) === "all"
      ) {
        return "active";
      } else if (path.substring(path.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) === "payments") {
        return "All Payments";
      } else if (path.substring(path.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) === "aging") {
        return "Late 91+ Days";
      } else if (path.substring(path.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) === ":customerId") {
        return "Activity Stream";
      } else {
        return path.substring(path.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
      }
    };

    const containsExistingContact = (to: To[], contacts: To[]): boolean => {
      const contactIdsList = contacts.map((item) => {
        return item.id;
      });
      const filteredList = to.filter((contact) => {
        if (contact && contactIdsList.includes(contact.id ?? "")) {
          return contact;
        }
      });
      return filteredList.length ? true : false;
    };

    //Tracking Events in Full Story
    event(TrackEventType.EMAIL_ACTIVITY, {
      inbox_CTA_str: callToAction,
      inbox_activity_type_str: callToAction === "Ellipsis" ? "New Email" : "Email",
      inbox_record_action_str: action,
      inbox_action_str: sendAndClose ? "Send & Close" : "Send",
      inbox_page_type_str: getPageType(pageType ?? ""),
      inbox_screen_str: getCurrentScreen(path),
      inbox_contains_new_contact_str: containsNewContact(to, contactOptions).toString(),
      inbox_contains_existing_contact_str: containsExistingContact(to, contactOptions).toString(),
    });

    //Tracking Events in Pendo
    if (Utils.isProduction()) {
      (window as any).pendo.track(TrackEventType.EMAIL_ACTIVITY, {
        inbox_cta: callToAction,
        inbox_activity_type: callToAction === "Ellipsis" ? "New Email" : "Email",
        inbox_record_action: action,
        inbox_action: sendAndClose ? "Send & Close" : "Send",
        inbox_page_type: getPageType(pageType ?? ""),
        inbox_screen: getCurrentScreen(path),
        inbox_contains_new_contact: containsNewContact(to, contactOptions).toString(),
        inbox_contains_existing_contact: containsExistingContact(to, contactOptions).toString(),
      });
    }

    //Track Email event in Segment
    if (Utils.isProduction()) {
      (window as any).analytics.track(TrackEventType.EMAIL_ACTIVITY, {
        inbox_cta: callToAction,
        inbox_activity_type: callToAction === "Ellipsis" ? "New Email" : "Email",
        inbox_record_action: action,
        inbox_action: sendAndClose ? "Send & Close" : "Send",
        inbox_page_type: getPageType(pageType ?? ""),
        inbox_screen: getCurrentScreen(path),
        inbox_contains_new_contact: containsNewContact(to, contactOptions).toString(),
        inbox_contains_existing_contact: containsExistingContact(to, contactOptions).toString(),
      });
    }
  },
};

export default TrackingFS;
