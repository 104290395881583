import React, { useEffect } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import AccordionNav, { AccordionNavItem } from "../../library/AccordionNav/AccordionNav";
import { AppContext } from "../../../contexts/AppContext";
import { User, Unassigned, AllActivities, Payments, Invoices, Dashboard, Spam, CustomersDirectory, Connections } from "../../library/Icons/Icons";
import "./MainContentSidebar.scss";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { WorkspaceType } from "../../../types/enums";
import { ComponentConfigContext } from "../../../contexts/ComponentConfigContext";
import { MetaContext } from "../../../contexts/MetaContext";
import { useRouteMatch } from "react-router";

const iconProvider = (key: string) => {
  return {
    invoices: <Invoices />,
    payments: <Payments />,
  }[key];
};

export default function MainContentSidebar(): React.ReactElement {
  const { syncError } = React.useContext(AppContext) as AppType;
  const { workspaceHomePath, selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  /**
   * Meta data to show counts of activity streams and their respective sub tabs
   */
  const { metaDetailed, clearTimer } = React.useContext(MetaContext) as MetaType;
  const componentConfig = getConfig(selectedWorkspace?.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR) as any;
  const { path } = useRouteMatch();

  /**
   * Clear instances of Meta polling before initiating detailed meta.
   * When moving out of detailed meta, clear the polling.
   */
  useEffect(() => {
    clearTimer();
    return () => clearTimer();
  }, [path]);

  return (
    <>
      <AccordionNavItem className="dashboard-btn" to={`${workspaceHomePath}/dashboard`} label="Dashboard" icon={<Dashboard />} />

      {!syncError && (
        <>
          <div className="accordion-list">
            <OverlayScrollbarsComponent options={{ autoUpdate: true, paddingAbsolute: true }}>
              <AccordionNav
                title="Activity Streams"
                listItems={[
                  {
                    label: "mine",
                    id: "activities/mine?sort=last_activity_at+DESC&page=1&pageSize=25",
                    icon: <User />,
                    count: metaDetailed["mine_count"].total,
                  },
                  {
                    label: "unassigned",
                    id: "activities/unassigned?sort=last_activity_at+DESC&page=1&pageSize=25",
                    icon: <Unassigned />,
                    count: metaDetailed["unassigned_count"].total,
                  },
                  {
                    label: "all",
                    id: "activities/all?sort=last_activity_at+DESC&page=1&pageSize=25",
                    icon: <AllActivities />,
                    count: metaDetailed["all_count"].total,
                  },
                  {
                    label: "spam",
                    id: "activities/spam?sort=last_activity_at+DESC&page=1&pageSize=25",
                    icon: <Spam />,
                  },
                ]}
                toParser={(value: { id: string; label: string }) => {
                  return `${workspaceHomePath}/${value.id}`;
                }}
              />
              <AccordionNav
                title="Transactions"
                listItems={componentConfig.transactions.listItems.map((item: any) => {
                  return {
                    label: item.label,
                    id: item.id,
                    icon: iconProvider(item.icon),
                  };
                })}
                toParser={(value: { id: string; label: string }) => {
                  return `${workspaceHomePath}/${value.id}`;
                }}
              />
              <AccordionNav
                className="accordion-connections"
                title="Connections"
                listItems={[
                  {
                    label: componentConfig.connections.title,
                    id: `${componentConfig.connections.routeType}?sort=${componentConfig.connections.defaultSortKey}+DESC&page=1&pageSize=25`,
                    icon: <CustomersDirectory />,
                    exact: false,
                  },
                  {
                    label: "Other Connections",
                    id: `connections?sort=${componentConfig.otherConnections.defaultSortKey}+DESC&page=1&pageSize=25`,
                    icon: <Connections />,
                    exact: false,
                  },
                ]}
                toParser={(value: { id: string; label: string }) => {
                  return `${workspaceHomePath}/${value.id}`;
                }}
              />
            </OverlayScrollbarsComponent>
          </div>
        </>
      )}
    </>
  );
}
