import { AxiosError } from "axios";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import React, { useState, useEffect, ReactElement, useRef } from "react";
import _ from "underscore";
import { ProfileRequest, SnoozedToast } from "../../../library/Icons/Icons";
import { useHistory, useParams, useLocation, useRouteMatch } from "react-router";
import {
  ActivityActionTypes,
  ActivityType as ActivityTypeEnum,
  ActivityStatus,
  FallbackTypes,
  AuditLogType,
  EmailAction,
  ConnectionStatus,
  EmailType,
  ActivityOptions,
  AttachmentType,
  InlinePrompts,
} from "../../../../types/enums";

import { CONNECTION_STATUS } from "../../../../constants/ConnectionConstants";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_TIMEOUTS, TEMPLATE_TIMING_CONSTANTS } from "../../../../constants/NumericConstants";
import { CUSTOM_ACTIVITY_FEED_CONSTANTS } from "../../../../constants/StyleConstants";
import { AlertContext } from "../../../../contexts/AlertContext";
import { AppContext } from "../../../../contexts/AppContext";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { activitiesClientV2, emailsClientV2, companiesClientV2, automationClientV2 } from "../../../../db/version2Accessor";
import Utils from "../../../../utils/utils";
import "./ActivityFeed.scss";
import { ActivityFeedItem, ActivityFeedItemProps } from "./ActivityFeedItem/ActivityFeedItemContainer";
import NewActivityDropdown from "../../../library/AddNewActivityDropdown/AddNewActivityDropdown";
import NewActivityPopup, { NewActivityPopupProps } from "../../../library/AddNewActivityDropdown/NewActivityPopup";
import { ActivityContext } from "../../../../contexts/ActivityContext";
import SpamFraudModal from "../../SpamFraudModal/SpamFraudModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { TemplateTypes, viewType } from "../../../../app/Templates/TemplateTypes";
import { TemplateDefinitionProps } from "../../../../app/Templates/TemplateFactory";
import { TemplateContext } from "../../../../contexts/TemplateContext";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import TrackingUtils from "../../../Tracking/Tracking.Utils";
import methods from "./ActivityFeedMethods";
import FeedActionHeader from "./ActivityFeedHeader/FeedActionHeader";
import { companiesClient } from "../../../../db/accessor";
import ActivityFeedItemSkeleton from "./ActivityFeedItem/ActivityItemSkeleton/ActivityFeedItemSkeleton";
import useRefreshApprover from "../../../../hooks/useRefreshActivityPopupContent";

type ActivityDropdownItem = {
  displayName: string;
  icon: ReactElement;
};

type ActivityFeedProps = {
  activityData: ActivityStreamItem;
  refreshActivity: () => void;
  addActivityDropdown: ActivityDropdownItem[];
  connectionStatus: string;
  onboardingInitiated: boolean | null | undefined;
  userView?: string;
};

export default function ActivityFeed(props: ActivityFeedProps): React.ReactElement {
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { userStatus, getContactsOptions, allContactOptions } = React.useContext(AppContext) as AppType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { handleClose, attachSelectedAllPayload } = React.useContext(ActivityContext) as ActivityType;
  const [enableTypeAheadSuggestions, setEnableTypeAheadSuggestions] = React.useState(false);
  const { customerId, activityId } = useParams<{ customerId: string; activityId: string }>();
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [retryEmailPopup, setRetryEmailPopup] = useState<boolean>(false);
  const [retryPopupValues, setRetryPopupValues] = useState<{ reply: To[]; replyAll: To[]; replyAllCC: To[]; content: string }>();
  // reply stream
  const [NAComponentOptions, setNAComponentOptions] = useState<{ reply: To[]; replyAll: To[]; replyAllCC: To[]; replyAllBCC: To[]; nudgeTo: To[] }>();
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [feed, setFeed] = useState<ActivityFeedItemProps[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [approvalReviewDrawer, setApprovalReviewDrawer] = useState<boolean>(false);
  const [newActivityType, setNewActivityType] = useState<ActivityOptions | string | null>(null);
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [showAddActivity, setShowAddActivity] = useState<boolean>(false);
  const [isNudgeActivity, setIsNudgeActivity] = useState<boolean>(false);
  const [showNewActivity, setNewActivityVisibility] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>("");
  const [activityItemId, setActivityItemId] = useState<string>();
  // reply stream item
  const [activityReplyOptions, setActivityReplyOptions] = useState<{ reply: To[]; replyAll: To[]; replyAllCC: To[] }>();
  const [forwardActivityBody, setForwardActivityBody] = useState<string>("");
  const [forwardAttachments, setForwardAttachments] = useState<Array<AttachmentItem>>([]);
  const [templateName, setTemplateName] = useState<string>("");
  const [defaultTemplateId, setDefaultTemplateId] = useState<TemplateTypes | string>();
  const scrollRef = useRef<OverlayScrollbarsComponent>(null);
  const [isEmailPresent, setEmailPresent] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [connectionSpam, setConnectionSpam] = useState<boolean>(false);
  const [activityPageDetails, setActivityPageDetails] = React.useState({
    total_records: 0,
    page: 1,
    page_size: 20,
  });
  const [reviewAction, setReviewAction] = useState<ApprovalReviewAction | undefined>();
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const { templateFactory, prepareTemplateDataObject, handleTemplateAttachment, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const { loginUserCompany, signature } = React.useContext(CustomerContext) as CustomerType;
  const [isSenderOpen, setIsSenderOpen] = React.useState<boolean>(false);
  const [company, setCompany] = useState<CompanyModel | null>(null);
  const [trackActivityTransactions, setTrackActivityTransactions] = useState<TransactionItemType[]>([]);
  const [approvalAttachments, setApprovalAttachments] = useState<AttachmentItem[]>([]);

  // approval request, approver auto population & template update setup
  const { toRef, popupRef, approver, setApprover } = useRefreshApprover();

  const lastApprover = props.activityData.primary_connection?.last_approver;
  const isLastApproverEligible =
    newActivityType === ActivityOptions.APPROVAL_REQUEST && !_.isUndefined(lastApprover) && !_.isEmpty(lastApprover) && _.has(lastApprover, "email");
  const lastApproverContact = {
    id: lastApprover?.email ?? "",
    label: lastApprover?.name ?? lastApprover?.email ?? "",
  };

  /**
   * update approver if either activity type or new activity component
   * options are changed.
   */
  useEffect(() => {
    setApprover(isLastApproverEligible ? lastApproverContact : NAComponentOptions?.reply[0] ?? ({} as To));
  }, [newActivityType, NAComponentOptions]);

  const handleRead = async () => {
    let response = {} as APIResponse;
    try {
      response = await activitiesClientV2.markAsRead(selectedWorkspace?.id || FallbackTypes.Id, activityId);
    } catch (error: unknown) {
      response.success = false;
      console.log(error);
    }
  };

  const retryEmail = async (to: ContactItem[], content: string) => {
    const recipients = to.map((contact: ContactItem) => {
      return {
        id: contact.email_address,
        label: contact?.name || contact.email_address,
      };
    }) as To[];
    await setRetryPopupValues({
      reply: recipients,
      replyAll: recipients,
      replyAllCC: recipients,
      content,
    });
    setRetryEmailPopup(true);
    setNewActivityType(ActivityOptions.EMAIL);
  };

  const handleUnsnoozeStream = () => {
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "", icon: <SnoozedToast /> };
    const reqBody: ActivitiesStreamUpdateBody = {
      status: ActivityStatus.Active.toLocaleLowerCase() as Status,
    };

    activitiesClientV2
      .patch(selectedWorkspace?.id || FallbackTypes.Id, activityId, ActivityActionTypes.Status, reqBody)
      .then((response) => {
        if (response.success) {
          setToastOptions({
            ...toastOptions,
            message: "Activity Unsnoozed",
          });
          props.refreshActivity();
          //eslint-disable-next-line
          getActivityStream(false, true);
        }
      })
      .catch((error) => {
        setToastOptions({
          ...toastOptions,
          severity: "error",
          message: "Something went wrong!",
        });
        console.log(error);
      });
  };

  const handleApprovalReview = (reviewAction: ApprovalReviewAction) => {
    // open drawer
    setApprovalReviewDrawer(true);
    // pass the details of request and action taken
    setReviewAction(reviewAction);
  };

  /**
   * @function getEnhancedFeed
   * A helper function to enhance an activity feed with nudge feature if available but only for the first occurence
   * @param feed
   * @returns ActivityFeedItemProps[]
   */
  const getEnhancedFeed = (feed: ActivityFeedItemProps[]) => {
    if (feed.length && props?.activityData.status?.toLowerCase() === ActivityStatus.WaitingForResponse.toLowerCase()) {
      let nudgeActivityOccurence: number = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
      return feed.map((feedItem: ActivityFeedItemProps, order: number) => {
        nudgeActivityOccurence =
          feedItem.emailType === "nudge" ? nudgeActivityOccurence + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : nudgeActivityOccurence;
        return {
          ...feedItem,
          isNudgeActivity: nudgeActivityOccurence === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
          order,
        };
      });
    }
    return feed.map((feedItem: ActivityFeedItemProps, order: number) => {
      return {
        ...feedItem,
        order,
      };
    });
  };

  const handleNotSpam = async () => {
    let toastOptions: ToastOptions = { open: true, severity: "success", message: `Activity is marked as not spam` };
    let response = {} as APIResponse;
    try {
      let reqBody: ActivitiesStreamUpdateBody = { spam: false };
      reqBody = attachSelectedAllPayload(reqBody);

      response = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, activityId, ActivityActionTypes.Spam, reqBody);

      props.refreshActivity();
      //eslint-disable-next-line
      getActivityStream(false, true);
    } catch {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: "An error occurred while marking activity as not Spam",
        };
      }
      setToastOptions(toastOptions);
    }
  };

  const getOriginalEmail = async (itemId: string) => {
    return await emailsClientV2.getActivity(selectedWorkspace?.id || FallbackTypes.Id, activityId, itemId);
  };

  const getStreams = (fetchResults: ActivityStreamFetchResponse): ActivityFeedItemProps[] => {
    const results = fetchResults?.data ?? [];
    return results.reduce((previousValue: any[], feedItem: ActivityItemModel & AuditLogItemModel, order) => {
      const activityStreamType = methods.evaluateVariant(feedItem);
      const to = methods.evaluateTo(feedItem);
      const from = methods.evaluateFrom(feedItem, userStatus);
      const content = methods.evaluateContent(feedItem) ?? "";
      const status = methods.evaluateStatus(feedItem) ?? ";";
      const header = methods.evaluateHeader(feedItem, activityStreamType) ?? "";
      const useFrom = [
        ActivityTypeEnum.Note,
        ActivityTypeEnum.Move,
        ActivityTypeEnum.PhoneCall,
        AuditLogType.Snoozed,
        AuditLogType.Assign,
        AuditLogType.Close,
        AuditLogType.Spam,
        AuditLogType.FraudConnection,
        AuditLogType.SpamConnection,
        AuditLogType.UnspamConnection,
        AuditLogType.UnfraudConnection,
      ].includes(activityStreamType as ActivityTypeEnum | AuditLogType);
      /** Insert an activity item if type is new vendor; to render onboard vendor */
      if (feedItem.auto_detected_flag === "new_vendor" && !props.onboardingInitiated) {
        previousValue.push({
          flag: feedItem.auto_detected_flag ?? "",
          variant: InlinePrompts.ONBOARD_VENDOR,
          setActionType,
          setNewActivityType,
          setActivityItemId,
        });
      }
      return previousValue.concat({
        ...header,
        primaryConnection: feedItem?.activity_stream?.primary_connection,
        to: useFrom ? from : to,
        date: Utils.evaluateDate(feedItem?.created_at ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO),
        content: feedItem?.content ?? "",
        isRead: feedItem?.is_read,
        isStreamSpam: feedItem?.activity_stream?.spam,
        variant: activityStreamType,
        creator:
          (feedItem?.type === ActivityTypeEnum.AuditLog && feedItem?.action_type === AuditLogType.Forward) ||
          feedItem?.action_type === AuditLogType.Snoozed ||
          feedItem?.action_type === AuditLogType.Unsnoozed ||
          feedItem?.action_type === AuditLogType.Assign ||
          feedItem?.action_type === AuditLogType.Unassign ||
          feedItem?.action_type === AuditLogType.MergeConnection ||
          feedItem?.action_type === AuditLogType.Move
            ? feedItem?.action_by
            : feedItem?.creator,
        attachments: feedItem?.attachments,
        emailType: feedItem?.email_type,
        originMessageContent: feedItem?.origin_message_content,
        id: feedItem?.id,
        cc: feedItem?.cc ?? feedItem?.cc,
        bcc: feedItem?.bcc ?? feedItem?.bcc,
        from: feedItem?.from,
        workspace: feedItem?.workspace,
        originalStreamId: feedItem?.origin_activity_stream_id,
        originalStreamItemId: feedItem?.origin_activity_id,
        preview:
          feedItem?.type === ActivityTypeEnum.AuditLog && feedItem?.action_type === AuditLogType.MergeConnection ? content : feedItem?.preview ?? "",
        handleUnsnoozeStream: handleUnsnoozeStream,
        snoozedUntil: feedItem?.snooze_until ?? null,
        simple_content:
          feedItem?.type === ActivityTypeEnum.AuditLog && feedItem?.action_type === AuditLogType.MergeConnection
            ? content
            : feedItem?.simple_content ?? "",
        activityId: activityId,
        status: status,
        type: feedItem?.type,
        order,
        magicLink: feedItem?.magic_link_url,
        flag: feedItem?.auto_detected_flag,
        timeSaved: feedItem?.time_saved,
        handleApprovalReview: handleApprovalReview,
        getOriginalEmail: getOriginalEmail,
        handleNotSpam: handleNotSpam,
        assigneeName: feedItem?.to_user?.name ?? feedItem?.to_user?.email,
      });
    }, []);
  };

  /**
   * This function fetches and sets the activity feed.
   * To refresh stream with page 1, refreshStream should be provided
   * as true.
   *
   * @param withLoadingAnimation shows loading animation if true
   * @param onActionPerform shows whetherany activity action has been performed
   * @param explicitlySetPageSize defines the page size for the fetch
   * @param refreshStream refreshes the stream entirely
   */
  const getActivityStream = async (
    withLoadingAnimation: boolean,
    onActionPerform = false,
    explicitlySetPageSize: number = DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
    refreshStream = false
  ) => {
    setLoading(onActionPerform || withLoadingAnimation);
    try {
      const fetchResults = await activitiesClientV2.getStream(selectedWorkspace?.id || FallbackTypes.Id, activityId, {
        page:
          refreshStream || feed.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO || onActionPerform
            ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
            : activityPageDetails.page,
        page_size: refreshStream ? activityPageDetails.page_size : onActionPerform ? explicitlySetPageSize : activityPageDetails.page_size,
        "qs[s]": "id desc",
      });
      const results = fetchResults?.data ?? [];
      if (results.length < DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) return;
      let setPageNumber = refreshStream ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : activityPageDetails.page;
      if (!onActionPerform) {
        /**
         * replaces the existing feed with new
         */
        if (refreshStream) {
          setFeed([...(getEnhancedFeed([...getStreams(fetchResults)]) as ActivityFeedItemProps[])]);
        } else {
          setFeed([...(getEnhancedFeed([...feed, ...getStreams(fetchResults)]) as ActivityFeedItemProps[])]);
        }
      } else {
        if (explicitlySetPageSize >= activityPageDetails.page_size) {
          setPageNumber = DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
          setFeed([...(getEnhancedFeed([...getStreams(fetchResults)]) as ActivityFeedItemProps[])]);
        } else {
          setFeed([...(getEnhancedFeed([...getStreams(fetchResults), ...feed]) as ActivityFeedItemProps[])]);
        }
      }
      setActivityPageDetails({
        ...activityPageDetails,
        page: onActionPerform ? setPageNumber : setPageNumber + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
        total_records: fetchResults?.total_records ?? activityPageDetails.total_records,
      });
      const latestEmail = results.filter((feedItem: any) => {
        return (
          feedItem.type === ActivityTypeEnum.Email ||
          feedItem.type === ActivityTypeEnum.IncomingEmail ||
          feedItem.type === ActivityTypeEnum.PhoneCall ||
          feedItem.type === ActivityTypeEnum.Approval
        );
      })[0];

      const isEmailPresent = results.filter(
        (feedItem: any) => feedItem.type === ActivityTypeEnum.Email || feedItem.type === ActivityTypeEnum.IncomingEmail
      );

      if (isEmailPresent.length) {
        setEmailPresent(true);
      }

      const replyOptions: any = methods.getReplyOptions(latestEmail);
      setApprovalAttachments(results.filter((feedItem: any) => feedItem.type === ActivityTypeEnum.Email)?.[0]?.attachments ?? []);

      setNAComponentOptions({
        reply: replyOptions.reply,
        replyAll: replyOptions.replyAll,
        replyAllCC: replyOptions.replyAllCC,
        replyAllBCC: replyOptions.replyAllBCC,
        nudgeTo: replyOptions.nudgeTo,
      });
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setLoading(false);
      const params = history.location.state as any;
      if (params && params.itemId) {
        // if only itemId is present
        setTimeout(() => {
          const element = scrollRef.current;
          const instance = element?.osInstance();
          const ele = document.getElementById(params?.itemId);
          if (ele) {
            instance?.scroll(ele, NUMERIC_TIMEOUTS.ONE_SECOND);
          }
        }, NUMERIC_TIMEOUTS.ONE_SECOND);
      }
    }
  };

  const onCloseApprovalReview = async () => {
    // close drawer
    setApprovalReviewDrawer(false);
    // reset details of request and action taken
    setReviewAction(undefined);
    setActivityItemId(undefined);
  };

  const resetData = () => {
    setFeed([]);
    setActivityPageDetails({
      total_records: 0,
      page: 1,
      page_size: 20,
    });
  };

  const openModal = (e: React.SyntheticEvent) => {
    e.currentTarget.textContent === "Mark Sender As Spam" ? setConnectionSpam(true) : setConnectionSpam(false);
    setShowModal(true);
  };

  const handleSpamFraud = async () => {
    const toastOptions: ToastOptions = { open: true, severity: "success", message: "" };

    let response = {} as APIResponse;
    try {
      const reqBody: UpdateCustomerStatusPayload = {
        connection_ids: props.activityData?.primary_connection?.id ? [props.activityData?.primary_connection?.id] : [],
        status: connectionSpam ? ConnectionStatus.SPAM : ConnectionStatus.FRAUD,
        workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
        filter_status: ConnectionStatus.ACTIVE,
      };
      response = await companiesClientV2.updateCustomer(reqBody);
      if (response.success) {
        setToastOptions({
          ...toastOptions,
          message: `Successfully marked sender as ${connectionSpam ? "spam" : "fraud"}`,
        });
        props.refreshActivity();
        getActivityStream(false, true);
      }
    } catch (error) {
      response.success = false;
      setToastOptions({
        ...toastOptions,
        severity: "error",
        message: response.messages.errors[0],
      });
    }
    setIsSenderOpen(false);
    setShowModal(false);
  };

  const getActivityTypePayload = () =>
    isNudgeActivity
      ? EmailType.nudge
      : newActivityType && newActivityType === ActivityOptions.PHONE_CALL
      ? ActivityTypeEnum.PhoneCall
      : newActivityType === ActivityOptions.APPROVAL_REQUEST
      ? ActivityTypeEnum.Approval
      : newActivityType === ActivityOptions.ONBOARD_VENDOR
      ? ActivityTypeEnum.VendorOnboarding
      : newActivityType;

  const getActivityContentType = () =>
    newActivityType == ActivityOptions.PHONE_CALL
      ? ActivityTypeEnum.PhoneCall
      : [ActivityOptions.EMAIL, ActivityOptions.APPROVAL_REQUEST, ActivityOptions.ONBOARD_VENDOR].includes(newActivityType as ActivityOptions)
      ? ActivityTypeEnum.Email
      : newActivityType;

  /**
   * @function getCompanyIdForFirstTo
   * A helper function to get the company id for the first contact selected
   * @param to - Contacts
   * @returns
   */
  const getCompanyIdForFirstTo = (to: To[]) => {
    if (to && to.length) {
      return to[0].companyId;
    }
    return "";
  };

  const handleReplySend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    disableSoftRefresh?: boolean,
    isSendAndClose?: boolean
  ) => {
    setLoading(true);
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let responseData: any = {};
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "Messages Sent" };
    const transactionSelected = _.uniq([...inboxAttachments, ...trackActivityTransactions], "transaction_id");

    let payload: Partial<ActivityItemEmailModel> = {
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: actionType ? actionType : isEmailPresent ? EmailAction.REPLY : EmailAction.NEW,
      content_type: getActivityContentType(),
      contact_company_id: getCompanyIdForFirstTo(to),
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_type: getActivityTypePayload(),
      activity_transactions: transactionSelected.filter(
        (item) => attachmentIds.indexOf(item.transaction_id) !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
      ),
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, transactionSelected),
    };
    if (actionType === "forward") {
      payload = {
        origin_message_id: activityItemId,
        ...payload,
      };
    }
    await emailsClientV2
      .postActivities(selectedWorkspace?.id || FallbackTypes.Id, activityId, payload)
      .then(async (response: ActivityStream) => {
        //Only call Automation API when email is sent successfully and Template is selected
        if ((templateName || newActivityType === EmailAction.FORWARD_AP_AUTOMATION) && response.success) {
          let reqBody = {
            to_time: newActivityType === EmailAction.FORWARD_AP_AUTOMATION ? TEMPLATE_TIMING_CONSTANTS.ONE_MIN : toTime,
            automation_type: "email_templates",
            automation_sub_type: newActivityType === EmailAction.FORWARD_AP_AUTOMATION ? actionType : templateName,
            resource_type: "Activity::Email",
            resource_id: response.data.id,
          } as AutomateTimeModel;
          if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO || newActivityType === EmailAction.FORWARD_AP_AUTOMATION) {
            reqBody = {
              ...{ from_time: newActivityType === EmailAction.FORWARD_AP_AUTOMATION ? TEMPLATE_TIMING_CONSTANTS.THIRTY_SEC : fromTime },
              ...reqBody,
            };
          }
          setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
          automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
        }
        TrackingUtils.trackFSData(
          to,
          "Add Activity",
          actionType ? actionType : "Reply",
          isSendAndClose ?? false,
          allContactOptions,
          customerId ? "Customer" : props.userView ?? "",
          `${path}/${customerId ? "Customer" : props.userView ?? ""}`,
          newActivityType ?? ""
        );
        setToastOptions({
          open: true,
          severity: "success",
          message: newActivityType === ActivityOptions.ONBOARD_VENDOR ? "Successfully sent the onboarding request to vendor" : "Messages Sent",
        });
        setShowAddActivity(false);
        setNewActivityVisibility(false);
        setActionType("");
        setActivityItemId("");
        setIsNudgeActivity(false);
        if (retryEmailPopup) {
          setRetryEmailPopup(false);
        }
        if (!disableSoftRefresh) {
          props.refreshActivity();
          await getActivityStream(true, true);
          setLoading(false);
        }
        setNewActivityType("");
        responseData = response;
      })
      .catch((err: AxiosError) => {
        setLoading(false);
        toastOptions.severity = "error";

        if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
          toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
        } else {
          const errorResponse = err.response as AxiosErrorResponseData;
          toastOptions = {
            ...toastOptions,
            message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent.",
          };
        }
        setToastOptions(toastOptions);
      });

    return responseData;
  };

  const handleReplySendClose = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: any
  ) => {
    const replyResponseData = await handleReplySend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true, true);
    if (replyResponseData.success) {
      setNewActivityVisibility(false);
      setShowAddActivity(false);
      setIsNudgeActivity(false);
      setActivityItemId("");
      setActionType("");
      let toastOptions: ToastOptions = { open: true, severity: "success", message: "Activity Closed" };
      let response = {} as APIResponse;

      try {
        response = await handleClose(activityId, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: "Activity was not closed",
          };
        }

        /**
         * Set specific behaviour on closing an activity stream.
         * history.goBack() is used to navigate back the index from which activity stream has been opened.
         * Alternatively props.refreshActivity(); & await getActivityStream(true, true, NUMERIC_VALUES.CONSTANT_TWO);
         * can be used to refresh the current activity stream.
         */
        setToastOptions(toastOptions);
        setLoading(false);
        history.goBack();
      }
    }
  };

  const fectCompanyContacts = async (customerId: string) => {
    setCompanyContactOptions(await getContactsOptions(customerId));
  };

  useEffect(() => {
    if (props?.activityData?.status) {
      resetData();
      /**
       * since reset data won't udpate the states immediately,
       * feed refresh is handled inside getActivityStream with
       * refreshActivity.
       */
      getActivityStream(true, undefined, undefined, true);
      handleRead();
    }
  }, [props?.activityData?.status]);

  /**
   * if onboarding is initiated, prompt will be
   * removed from the activity stream.
   */
  useEffect(() => {
    if (props?.onboardingInitiated) {
      const filteredStream = feed.filter((item: any) => item.variant !== InlinePrompts.ONBOARD_VENDOR);
      setFeed(filteredStream);
    }
  }, [props?.onboardingInitiated]);

  /**
   * Clear out pagination state and feed data
   */
  useEffect(() => {
    return () => {
      resetData();
    };
  }, []);

  useEffect(() => {
    if (customerId) {
      fectCompanyContacts(customerId);
    }
  }, [location.pathname, activityId]);

  const setReplyOptions = async (item: ActivityFeedItemProps) => {
    try {
      const getReplyOptionsResult = methods.getReplyOptions(item);
      const replyOptions = { ...getReplyOptionsResult };
      // populate replyOptions
      setActivityReplyOptions({
        reply: replyOptions.reply,
        replyAll: replyOptions.replyAll,
        replyAllCC: replyOptions.replyAllCC,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setNewActivityVisibility(true);
      setShowAddActivity(false);
    }
  };

  useEffect(() => {
    const currentItem = feed.filter((item) => item.id === activityItemId)[0];
    if (currentItem) {
      setReplyOptions(currentItem);
      // set forward email body

      setForwardActivityBody(currentItem.content);
      setForwardAttachments(currentItem.attachments as Array<AttachmentItem>);
    }
  }, [activityItemId]);

  /**
   * Only show contacts relevant to the connection
   */
  let filteredContactOptions = allContactOptions;

  if (customerId) {
    filteredContactOptions = enableTypeAheadSuggestions ? [...companyContactOptions, ...allContactOptions] : companyContactOptions;
  }

  const typeAheadAction = (val: string) => {
    if (val.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setEnableTypeAheadSuggestions(true);
    } else {
      setEnableTypeAheadSuggestions(false);
    }
  };

  const getLoadingSkeleton = () => (
    <>
      {[
        ...Array(
          Math.max(
            DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
            Math.floor(
              (window.innerHeight - CUSTOM_ACTIVITY_FEED_CONSTANTS.SKELETON_INNER_HEIGHT) / CUSTOM_ACTIVITY_FEED_CONSTANTS.SKELETON_INNER_DIVIDER
            )
          )
        ),
      ].map((_val, index: number) => (
        <ActivityFeedItemSkeleton key={index} />
      ))}
    </>
  );

  const handleNudge = () => {
    setNewActivityType(ActivityOptions.EMAIL);
    setShowAddActivity(true);
    setIsNudgeActivity(true);
  };

  const getCompanyData = async (): Promise<CompanyModel> => {
    let companyToReturn = company;
    if (!company) {
      const companyModel: CompanyModel = await companiesClient.getCompany(userStatus.account_company_id as string, "Attachments");
      setCompany(companyModel);
      companyToReturn = companyModel;
    }
    return new Promise((resolve) => resolve(companyToReturn as CompanyModel));
  };

  /**
   * default contact options precedence:
   * If it's approval request activity -> check for last approver
   * If it's nudge activity -> check for nudge to contact
   * If respective email contacts are not present -> populate reply option
   * from activity feed reply options
   */
  const defaultToContacts = isLastApproverEligible
    ? [lastApproverContact]
    : isNudgeActivity
    ? NAComponentOptions?.nudgeTo
    : NAComponentOptions?.reply ?? [];

  const handleTemplateAttachments = async (templateObj: any, setFileCallback: FunctionCall) => {
    const comapnyData = await getCompanyData();
    const allW9Documents: AttachmentModel[] = (comapnyData.attachments as AttachmentModel[]).filter(
      (attachment: AttachmentModel) => attachment.attachmentType === "W-9"
    );
    const allDcoumentsMeta = allW9Documents.map((document: AttachmentModel) => {
      const documentMetaForTemplate = { name: `${document.fileName ?? "N/A"}`, id: document.attachmentId };
      return documentMetaForTemplate;
    });
    setTrackActivityTransactions([
      ...allDcoumentsMeta.map((document) => {
        return { transaction_id: document.id, transaction_type: AttachmentType.DOCUMENT };
      }),
    ]);
    const templateDataMapper = prepareTemplateDataObject(templateObj, null, allDcoumentsMeta, null);
    handleTemplateAttachment(templateObj, setFileCallback, templateDataMapper);
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = async (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    setFileCallback?: FunctionCall
  ) => {
    if (templateID) {
      setTemplateName(templateID);
      const { primary_connection } = props.activityData;
      const templateObj = templateData.get(selectedWorkspace?.id).get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: primary_connection?.name || "{customer}",
          workspaceCompanyName: loginUserCompany?.companyName || "{AR Company Name}",
          emailAddress: loginUserCompany?.emailAddress || "",
          companyName: loginUserCompany?.companyName || "",
          phone: loginUserCompany?.phoneNumber || "",
          userName: `${userStatus?.user_name ?? "{Your Name}"}`,
          workspaceName: `${selectedWorkspace?.name}`,
          contactName: `${approver?.label ?? "{Recipient's Name}"}`,
          signature: signature?.email_signature || "",
          // map invoiceId data once new/existing bill flags are developed
          invoiceId: `{Bill Number}`,
        })
      );
      if (templateObj.templateAttachment) {
        handleTemplateAttachments(templateObj, setFileCallback as FunctionCall);
      }
    }
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (): TemplateDefinitionProps[] => {
    const view =
      selectedWorkspace.workspace_type === "accounts_payable" ? viewType.AP_CONNECTION_VENDOR_ACTIVITY : viewType.AR_CONNECTION_CUSTOMER_ACTIVITIES;
    const { primary_connection } = props.activityData;
    return templateFactory.getTemplateDefinitionsByView(view, { customer: primary_connection?.name });
  };

  const evaluateNewActivityProps = () => {
    const replyOptionStartMap = new Map();
    replyOptionStartMap.set(EmailAction.REPLY, "Reply");
    replyOptionStartMap.set(EmailAction.REPLY_ALL, "Reply All");
    let newActivityProps = {
      activityType: "email",
      open: showNewActivity,
      onClose: () => {
        setNewActivityVisibility(false);
        setActivityItemId("");
        setActionType("");
        setForwardActivityBody("");
        setForwardAttachments([]);
        setNewActivityType("");
      },
      addActivityDropdown: props.addActivityDropdown,
    } as NewActivityPopupProps;

    if (actionType === EmailAction.REPLY || actionType === EmailAction.REPLY_ALL) {
      newActivityProps = {
        ...newActivityProps,
        title: `Reply to ${props.activityData?.subject ?? ""}`,
        defaultTitle: `${props.activityData?.subject ?? ""}`,
        contactOptions: allContactOptions?.filter((v, i, a) => a.findIndex((t) => t?.label === v?.label) === i),
        defaultTo: activityReplyOptions?.reply ?? [],
        replyOptions: {
          replyButton: false,
          start: replyOptionStartMap?.get(actionType),
          reply: activityReplyOptions?.reply ?? [],
          replyAll: activityReplyOptions?.replyAll ?? [],
          replyAllCC: activityReplyOptions?.replyAllCC ?? [],
        },
        handleSend: handleReplySend,
        handleSendMarkClosed: handleReplySendClose,
      };
    } else if (actionType === EmailAction.FORWARD) {
      // forward
      newActivityProps = {
        ...newActivityProps,
        title: `Forward: ${props.activityData.subject ?? ""}`,
        defaultTitle: `Forward: ${props.activityData.subject ?? ""}`,
        editorState: forwardActivityBody ?? "",
        defaultFiles: (forwardAttachments ?? [])
          .map((attachment: AttachmentItem) => attachment.file_name ?? "")
          .filter((element: string) => element !== ""),

        defaultAttachmentsList: forwardAttachments ?? [],
        contactOptions: allContactOptions,
        handleSend: handleReplySend,
        handleSendMarkClosed: handleReplySendClose,
        forwarding: true,
      };
    }
    return newActivityProps;
  };

  useEffect(() => {
    if (newActivityType === ActivityOptions.EMAIL) {
      setSupportedTemplateList(getTemplateDefinitionsByView());
      setDefaultTemplateId("");
    } else if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      setSupportedTemplateList(
        templateFactory.getTemplateDefinitionsByView(
          selectedWorkspace.workspace_type === "accounts_payable" ? viewType.AP_APPROVAL_REQUEST_ACTIVITY : viewType.AR_APPROVAL_REQUEST_ACTIVITY
        )
      );
      setDefaultTemplateId(
        selectedWorkspace.workspace_type === "accounts_payable" ? TemplateTypes.AP_APPROVAL_REQUEST : TemplateTypes.AR_APPROVAL_REQUEST
      );
    } else if (newActivityType === ActivityOptions.ONBOARD_VENDOR) {
      setSupportedTemplateList(templateFactory.getTemplateDefinitionsByView(viewType.VENDOR_ONBOARD_REQUEST_ACTIVITY));
      setDefaultTemplateId(TemplateTypes.AP_VENDOR_PROFILE_REQUEST);
      setNewActivityVisibility(false);
      setShowAddActivity(true);
    }
    // reset template states
    else {
      setSupportedTemplateList([]);
      setDefaultTemplateId("");
    }
  }, [newActivityType]);

  return (
    <div className="activity-feed-wrapper">
      <div className="head">
        <div className="head-grp">
          <NewActivityDropdown
            handleReplySend={handleReplySend}
            handleReplySendClose={handleReplySendClose}
            addActivityDropdown={
              newActivityType === ActivityOptions.ONBOARD_VENDOR
                ? [...props.addActivityDropdown, { icon: <ProfileRequest />, displayName: "Request New Company Profile" }]
                : props.addActivityDropdown
            }
            replyToOptions={filteredContactOptions}
            newActivityType={newActivityType}
            setNewActivityType={setNewActivityType}
            showAddActivity={showAddActivity}
            setShowAddActivity={setShowAddActivity}
            setNewActivityVisibility={setNewActivityVisibility}
            setActivityItemId={setActivityItemId}
            isTemplateSupport={
              (newActivityType === ActivityOptions.EMAIL || ActivityOptions.ONBOARD_VENDOR || newActivityType === ActivityOptions.APPROVAL_REQUEST) &&
              !isNudgeActivity
            }
            defaultTemplateId={defaultTemplateId ?? ""}
            prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
            supportedTemplateList={supportedTemplateList}
            defaultTo={defaultToContacts as To[]}
            defaultCc={isNudgeActivity ? NAComponentOptions?.replyAllCC : []}
            defaultBcc={isNudgeActivity ? NAComponentOptions?.replyAllBCC : []}
            defaultTitle={`${isNudgeActivity ? "Following up on " : ""}${props.activityData.subject ?? ""}`}
            typeAheadAction={typeAheadAction}
            resetFunction={() => {
              setEnableTypeAheadSuggestions(false);
              setIsNudgeActivity(false);
            }}
            disableTitle={newActivityType !== ActivityOptions.EMAIL}
            isNudgeActivity={isNudgeActivity}
            fromTime={fromTime}
            toTime={toTime}
            setToTime={setToTime}
            disableSecondaryAction={isNudgeActivity || newActivityType === ActivityOptions.APPROVAL_REQUEST}
            defaultAttachmentsList={newActivityType === ActivityOptions.APPROVAL_REQUEST ? approvalAttachments : []}
            refs={{ toRef, popupRef }}
          />
        </div>
        <FeedActionHeader
          getActivityStream={getActivityStream}
          details={{
            refreshActivity: props.refreshActivity,
            connectionStatus: props.connectionStatus,
          }}
          reassign={{ handlers: { resetData } }}
          nudge={{ handlers: { handleNudge } }}
          spamFraud={{ isSenderOpen, handlers: { openModal, setIsSenderOpen } }}
          approval={{ approvalReviewDrawer, activityItemId, reviewAction, handlers: { onCloseApprovalReview } }}
        />
      </div>
      <div id="infinite-scroll" className="body">
        {isLoading ? (
          getLoadingSkeleton()
        ) : (
          <InfiniteScroll
            dataLength={feed.length}
            next={() => getActivityStream(false)}
            hasMore={feed.length < activityPageDetails.total_records}
            loader={getLoadingSkeleton()}
            scrollableTarget="infinite-scroll"
          >
            {feed.map((feedValue: ActivityFeedItemProps, index: number) => (
              <ActivityFeedItem
                key={index}
                {...feedValue}
                retryEmail={retryEmail}
                setActionType={setActionType}
                setActivityItemId={setActivityItemId}
                setNewActivityType={setNewActivityType}
                handleUnsnoozeStream={handleUnsnoozeStream}
                handleNudge={handleNudge}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
      {retryEmailPopup ? (
        <NewActivityPopup
          key={`retry-${retryPopupValues?.reply[0].label ?? ""}`}
          title={`Email Activity`}
          open={retryEmailPopup}
          contactOptions={allContactOptions}
          defaultTo={retryPopupValues?.reply ?? []}
          replyOptions={{
            replyButton: true,
            start: "Reply",
            reply: retryPopupValues?.reply ?? [],
            replyAll: retryPopupValues?.replyAll ?? [],
            replyAllCC: retryPopupValues?.replyAllCC ?? [],
          }}
          editorState={retryPopupValues?.content ?? ""}
          handleSend={handleReplySend}
          handleSendMarkClosed={handleReplySendClose}
          onClose={() => setRetryEmailPopup(false)}
          defaultTitle={`${props.activityData.subject ?? ""}`}
        />
      ) : null}
      {showNewActivity && actionType !== "" && (
        // reply, reply all & forward
        <NewActivityPopup {...evaluateNewActivityProps()} />
      )}
      <SpamFraudModal showModal={showModal} setShowModal={setShowModal} connectionSpam={connectionSpam} handleSpamFraud={handleSpamFraud} />
    </div>
  );
}
