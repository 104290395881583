import APIClient from "../APIClient";

export class AutomationClient {
  /**
   * * POST on inbox/api/v2/workspaces/${workspaceId}/automation_time_savings
   * @param body - Object of AUtomation timings to be posted
   * @returns Promise of type AutomationModel or Exception
   */
  post(workspaceId: number, body: Partial<AutomateTimeModel>): Promise<AutomateTimeModel> {
    const url = `inbox/api/v2/workspaces/${workspaceId}/automation_time_savings`;
    return APIClient.post(url, body).then((res) => res.data);
  }
}
