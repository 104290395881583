import React from "react";
import Button from "../../../../../../library/Button/Button";
import { Player } from "@lottiefiles/react-lottie-player";
import ThumbsUp from "../../../../../../../assets/lotties/thumbs-up.json";
import "./OnboardVendor.scss";
import { OnboardVendorProps } from "../ActivityItemVariantTypes";

const OnboardVendor: React.FC<OnboardVendorProps> = ({ onClickOnboard }) => {
  return (
    <div className="onboard-activity activity-feed-item-card">
      <div className="onboard-activity-icon">
        <Player src={ThumbsUp} autoplay style={{ width: "3.5rem", height: "3.5rem", margin: "auto" }} />
      </div>
      <div className="onboard-activity-body">
        <h3 className="onboard-activity-body-header">Brilliant!</h3>
        <p className="onboard-activity-body-text">
          A <span className="purple-highlight">new vendor</span> had sent their bills and transactions.
          <br />
          Go ahead and add them in the ERP system.
        </p>
      </div>
      <div className="onboard-activity-action">
        <Button className="request-onboard-btn" onClick={onClickOnboard}>
          Start Onboarding
        </Button>
      </div>
    </div>
  );
};

export default OnboardVendor;
